import { PropTypes } from 'base/react-libs';
import { Icon, Button } from '@evertrue/et-components';
import { useEffect, useState } from 'react';
import {
  fetchContactInfoById,
  fetchContactInteractionAndDpsDetails,
  fetchContactInteractionAndMeetingDetails,
} from 'apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests';
import RecommendedActionRow from './recommended-action-row';
import EmptyRecommendedSection from './empty-recommended-section';
import { useIdentity } from 'base/identity-resolver';
import Loading from 'components/elements/loading';

const RecommendedActionSection = ({
  title,
  subtitle,
  recommendedActions = [],
  icon,
  emailButtonLabel,
  secondaryActionLabel,
  iconKit = 'gt2',
  type = 'gift',
  loading,
}) => {
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const { user } = useIdentity();

  const handleViewClick = (recommendation) => {
    const recommendationsCopy = [...recommendations];
    const filtered = recommendationsCopy.filter((rec) => rec.id !== recommendation.id);
    setRecommendations(filtered);
  };

  useEffect(() => {
    const fetchAllContacts = async () => {
      if (!recommendedActions.length) return;
      try {
        setLoadingContacts(true);
        const sliceLimit = showAll ? recommendedActions.length : 3;
        const actionsWithContacts = await Promise.all(
          recommendedActions.slice(0, sliceLimit).map(async (action) => {
            const contact = await fetchContactInfoById(action?.contact_id, false);
            action.contact = contact || {};
            if (type === 'trips') {
              const trips_interactions = await fetchContactInteractionAndMeetingDetails(action?.contact_id, user.id);
              action.contact.trips_interactions = trips_interactions || {};
            }
            if (type === 'dps') {
              const dps_details = await fetchContactInteractionAndDpsDetails(action?.contact_id, user.id);
              action.contact.dps_details = dps_details || {};
            }
            return action;
          })
        );
        //filter out the ones with null contacts
        const filteredRecs = actionsWithContacts.filter((action) => !!action?.contact?.id);
        // sort recommendations by reverse chronological order
        // added a check for scenarios where giving is null
        const sortedRecs = filteredRecs.sort((a, b) => {
          const dateA = a.contact && a.contact.giving ? a.contact.giving.last_gift_date : null;
          const dateB = b.contact && b.contact.giving ? b.contact.giving.last_gift_date : null;

          if (dateA && dateB) {
            return new Date(dateB) - new Date(dateA);
          } else if (!dateA && dateB) {
            return 1; // If dateA is null and dateB is not, sort b before a
          } else if (dateA && !dateB) {
            return -1; // If dateB is null and dateA is not, sort a before b
          } else {
            return 0; // If both are null or both exist, maintain original order
          }
        });
        setRecommendations(sortedRecs);
      } finally {
        setLoadingContacts(false);
      }
    };
    fetchAllContacts();
  }, [recommendedActions, type, user.id, showAll]);

  return (
    <>
      <div className="homepage-recommendations-section-title">
        <Icon icon={icon} iconKit={iconKit} />
        <h6>{title}</h6>
      </div>
      <div>
        {loadingContacts || loading ? (
          <div style={{ paddingTop: '18px', height: '42px', marginBottom: '10px', marginTop: '10px' }}>
            <Loading spinner_size={'small'} className={'loader-icon'} />
          </div>
        ) : (
          <>
            {recommendations?.length > 0 ? (
              <>
                {recommendations?.slice(0, showAll ? recommendations?.length : 3).map((recommendation, index) => (
                  <RecommendedActionRow
                    recommendation={recommendation}
                    key={recommendation.id}
                    type={type}
                    first={index === 0}
                    subtitle={subtitle}
                    emailButtonLabel={emailButtonLabel}
                    secondaryActionLabel={secondaryActionLabel}
                    handleViewClick={handleViewClick}
                    icon={icon}
                    iconKit={iconKit}
                  />
                ))}
                {recommendedActions?.length > 3 && (
                  <div style={{ paddingBottom: '8px' }}>
                    <Button type="simple" onClick={() => setShowAll(!showAll)}>
                      {showAll ? 'View Less' : 'View All'}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <EmptyRecommendedSection subtitle={subtitle} />
            )}
          </>
        )}
      </div>
    </>
  );
};

RecommendedActionSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  recommendedActions: PropTypes.array,
  icon: PropTypes.string,
  emailButtonLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  iconKit: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export default RecommendedActionSection;
