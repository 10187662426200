module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   _ = require("underscore").default
   {div, h2} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   ScheduledExportsSource = require("apps/settings/export-settings/sources/scheduled-exports-source")
   ScheduledExportsStore = require("apps/settings/export-settings/stores/scheduled-exports-store")
   ExportSource = require("apps/export/sources/export-source")
   ExportStore = require("apps/export/stores/export-store")
   ExportItem = require("apps/settings/export-settings/components/export-item")
   ExportEnrichmentItem = createFactory require("apps/settings/export-settings/components/export-enrichment-item").default
   CareerUpdateExports = createFactory require("apps/settings/export-settings/components/career-update-exports").default
   Gated = createFactory require("components/gated")
   Link = createFactory(require("@evertrue/et-components").Link)
   Api = require("entities/helpers/api")
   withLDConsumer = require("launchdarkly-react-client-sdk").withLDConsumer
   


   ExportTypeConfig = require("apps/export/export-type-config").default
   EXPORT_TYPES = _.keys(ExportTypeConfig)

   CORE_WEALTH_META = "CORE_WEALTH_META"
   CORE_WEALTH = "CORE_WEALTH"

   ExportDataController = createComponent "ExportDataController",
      getInitialState: ->
         careerExports: []

      registerStores: ->
         @on ScheduledExportsStore, ->
            exports: ScheduledExportsStore.getExports()

         @on ExportStore, ->
            last_prospect_status_export: ExportStore.getLastExport("prospect_status")
            last_interaction_export: ExportStore.getLastExport("interaction")
            last_gifts_export: ExportStore.getLastExport("gifts")
            last_assignment_export: ExportStore.getLastExport("assignment")
            last_assignment_team_export: ExportStore.getLastExport("assignment_team")
            last_volunteer_interaction_export: ExportStore.getLastExport("volunteer_interaction")
            last_proposal_export: ExportStore.getLastExport("proposal")

      componentDidMount: ->
         _.each EXPORT_TYPES, (type) -> ExportSource.fetchLastExportDate(type)
         ScheduledExportsSource.fetchOrgJobs()
         self = this;
         Api.EXPORTS.CORE_ENRICHMENT_CAREER.get({
            disableAlerts: true,
            success: (response) ->
               if response.length
                  self.setState {
                     careerExports: response
                  }
         });

      handleSchedule: ->
         ScheduledExportsSource.createJob()

      handleCancel: ->
         ScheduledExportsSource.cancelJob()

      render: ->
         div null,
            div className: "export-data--downloads",
               div className: "export-data-section-header",
                  h2 className: "export-data-section-title", "Downloads"
               div className: "export-data-table-header",
                  div null, "Data"
                  div null, "Update Frequency"
                  div null, "File Date"
                  div null, "Download"
               Module
                  className: "export-data"
                  noMargin: true

                  ExportEnrichmentItem
                     gate: "windfall"
                     label: "Wealth Enrichment"
                     exportKey: CORE_WEALTH
                     exportMetaKey: CORE_WEALTH_META
                     trackingKey: "wealth_enrichment"

                  CareerUpdateExports
                     careerType : "salesintel_top_confidence"
                     careerExports: @state.careerExports
                     trackingKey: "career_moves_export"

                  CareerUpdateExports
                     careerType : "salesintel_all_matches"
                     careerExports: @state.careerExports
                     trackingKey: "career_moves_export"


            div className: "export-data--scheduled",
               div className: "export-data-section-header",
                  h2 className: "export-data-section-title", "Scheduled Exports"
                  Link
                     title: "view recent exports"
                     className: "export-data--link"
                     href: "/data/exports"
                     "View Recent Exports"
               div className: "export-data-table-header",
                  div null, "Data"
                  div null, "Schedule"
                  div null, "Last Export"
                  div null, "Actions"
               Module
                  className: "export-data"
                  noMargin: true
                  _.map ExportTypeConfig, (config, key) =>
                     item = ExportItem
                        key: key
                        exportType: key
                        label: config.label
                        item: @state.exports[key]
                        lastExport: @state["last_#{key}_export"]

                     if _.isFunction(config.gate)
                        if config.gate() then item
                     else if config.gate
                        Gated key: key, feature: config.gate, item
                     else if config.label == "Tasks"
                        if @props.flags.taskExports then item else null
                     else item

   withLDConsumer()(ExportDataController)
