import _ from "underscore";
import moment from "moment";
import numeral from "numeral";
import get from "lodash.get";
import { Link } from "@evertrue/et-components";
import EllipsisOverflow from "components/formatting/ellipsis-overflow";
import CellContactName from "apps/dashboard/components/cell-contact-name";
import classNames from "classnames";

const formatData = (data, type) => {
  switch (type) {
    case "string":
      return data;
    case "currency":
      return numeral(data).format("$0,0[.]00");
    case "date":
      return data ? moment(data).format("MM-DD-YYYY") : "-";
    case "contact_id":
      return <CellContactName id={data} />;
    case "number":
      return data || 0;
    case "array_count":
      return _.isArray(data) && !_.isEmpty(data) ? `+${data.length}` : "-";
    default:
      return null;
  }
};

const formatLink = (props) => {
   
  const { type, data, prop, link_prop, pool_id } = props;
  const link_label = get(data, prop);
  const link_id = get(data, link_prop);
  switch (type) {
    case "contact":
      return (
        <Link title={`Go to ${link_label}'s profile`} href={`/contact/${link_prop ? link_id : data.contact_id}`}>
          <EllipsisOverflow className="display-widget-results-overflow-width--contact">{link_label}</EllipsisOverflow>
        </Link>
      );
    case "solicitor_assignment_contact":
      return (
        <Link title={`Go to ${link_label}'s profile`} href={`/contact/${data.prospect_contact_id}`}>
          <EllipsisOverflow className="display-widget-results-overflow-width--contact">{link_label}</EllipsisOverflow>
        </Link>
      );
    case "proposal":
      return (
        <Link title={`Go to ${link_label}'s detail page`} href={`/proposal/${data.id}`}>
          <EllipsisOverflow className="display-widget-results-overflow-width--proposal">{link_label}</EllipsisOverflow>
        </Link>
      );
    case "interaction":
      return (
        <Link title={"Go to interaction's detail page"} href={`/interaction/${data.id}`}>
          <EllipsisOverflow className="display-widget-results-overflow-width--interaction">
            {link_label}
          </EllipsisOverflow>
        </Link>
      );
    case "trip":
      return (
        <Link title={`Go to trip ${link_label}`} href={`/trips/${data.id}`}>
          <EllipsisOverflow className="display-widget-results-overflow-width--trip">{link_label}</EllipsisOverflow>
        </Link>
      );
    case "solicitor_portfolio":
      return (
        <Link
          title={`Go to portfolio for ${link_label}`}
          href={`/portfolio-performance/portfolios/${pool_id}/solicitor/${link_id}`}
        >
          <EllipsisOverflow className="display-widget-results-overflow-width--trip">{link_label}</EllipsisOverflow>
        </Link>
      );
    default:
      return null;
  }
};

// id is either the solicitor id or pool id
const DisplayWidgetModalColumns = (column_info, pool_id = "", headerStyle = {}) => {
  const cols = [];

  column_info.forEach((col) => {
    const colClassName = classNames({
      "display-widget-results--small": col.size === "small",
      "display-widget-results-col--right": ["number", "percent", "currency"].includes(col.data_type),
    });

    cols.push({
      header: col.label,
      headerClassName: colClassName,
      headerStyle: headerStyle,
      cellClassName: colClassName,
      accessor: (data = {}) => {
        if (col.link_type) {
          return formatLink({
            type: col.link_type,
            data: data,
            prop: col.prop,
            link_prop: col.link_prop,
            pool_id: pool_id,
          });
        } else {
          return formatData(get(data, col.prop), col.data_type);
        }
      },
    });
  });
  return cols;
};

export default DisplayWidgetModalColumns;
