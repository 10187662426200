import { createStore } from '@evertrue/et-flux';
import RecentContactSource from 'apps/contact/sources/recent-contact-source';
import PaginationUtils from 'mixins/pagination-utils';

export default createStore('RecentContactStore', {
  getInitialState() {
    return {
      contacts: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(RecentContactSource.actions.loading, this.respondToLoading);
    this.on(RecentContactSource.actions.changedPage, this.respondToChangedPage);
    this.on(RecentContactSource.actions.fetchedRecentContacts, this.respondToFetchedRecentContacts);
  },

  respondToLoading(loading) {
    this.setState({ loading });
  },

  respondToChangedPage(page) {
    const contacts = this.getState('contacts');
    const paramObj = {
      limit: contacts.limit,
      total: contacts.total,
      page,
    };
    RecentContactSource.fetch(paramObj);
  },

  respondToFetchedRecentContacts(contacts) {
    const updatedContacts = {
      ...contacts,
      page: PaginationUtils.getPage(contacts),
    };
    this.setState({ contacts: updatedContacts });
  },

  api: {
    getContacts() {
      return this.getState('contacts');
    },
    getLoading() {
      return this.getState('loading');
    },
  },
});
