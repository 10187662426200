import PropTypes from "prop-types";

const SuggestedSearchListItem = ({ searchItem, handleSuggestedSearchClick }) => (
  <li className="suggested-search-item" onClick={() => handleSuggestedSearchClick(searchItem)}>
    <div className="suggested-search-item-box">{searchItem}</div>
  </li>
);

SuggestedSearchListItem.propTypes = {
  searchItem: PropTypes.string,
  handleSuggestedSearchClick: PropTypes.func,
};

export default SuggestedSearchListItem;