const gql = String.raw;

const journeysQueries = {
  /*
  ---------------------
  JOURNEY INDEX QUERIES
  ---------------------
  */

  fetchJourneyGroups: gql`
    query ($journeyGroupType: String, $includeInactive: Boolean!) {
      journeyGroups(journeyGroupType: $journeyGroupType, includeInactive: $includeInactive) {
        id
        group_type
        name
      }
    }
  `,

  // Tab 1: Your Favorites
  fetchFavoriteJourneys: gql`
    query ($taskOwnerUserId: BigInt!, $selectedDate: String!) {
      favoriteJourneys(userId: $taskOwnerUserId) {
        id
        name
        journey_type
        total_steps_due_today(selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            id
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,

  // Tab 2: Created by You
  fetchJourneysCreatedByUser: gql`
    query ($taskOwnerUserId: BigInt!, $selectedDate: String!) {
      journeysCreatedByUser(userId: $taskOwnerUserId) {
        id
        name
        journey_type
        total_steps_due_today(selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            id
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,

  // the only difference between this and fetchJourneysWithDueDatesForGroup is the "total_steps_due_today" field
  // separated the queries because the "total_steps_due_today" field is not needed in some cases and requires args
  fetchJourneysForGroup: gql`
    query ($journeyGroupId: BigInt!, $userId: BigInt) {
      journeysForJourneyGroup(journeyGroupId: $journeyGroupId, userId: $userId) {
        id
        name
        journey_type
        active_constituents(userId: $userId)
        duration_in_days
        number_of_steps
        journey_group {
          id
          name
        }
      }
    }
  `,

  // Tab 3: All Cadences
  fetchJourneysWithDueDatesForGroup: gql`
    query ($journeyGroupId: BigInt!, $taskOwnerUserId: BigInt!, $selectedDate: String!) {
      journeysForJourneyGroup(journeyGroupId: $journeyGroupId) {
        id
        name
        journey_type
        total_steps_due_today(selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            id
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,

  /*
  ---------------------
  JOURNEY DETAILS QUERIES
  ---------------------
  */

  fetchJourneyDetails: gql`
    query ($journeyId: Int!) {
      journey(journeyId: $journeyId) {
        id
        name
        journey_type
        creator_user_id
        objective_description
        journey_group_id
        active_constituents
        owner {
          name
        }
      }
    }
  `,

  fetchStepsByJourneyId: gql`
    query (
      $journeyId: BigInt!
      $stepTaskStatuses: [String!]
      $taskOwnerUserId: BigInt!
      $selectedDate: Date!
      $actionTypeFilter: [String]
    ) {
      journeyTasksByJourneyId(journeyId: $journeyId, actionTypeFilter: $actionTypeFilter) {
        id
        journey_id
        step_number
        day_number
        description
        default_action_type
        active
        title
        tasks(ownerUserId: $taskOwnerUserId, statuses: $stepTaskStatuses, selectedDate: $selectedDate) {
          id
          title
          current_due_date
          completed_at
          action_type
          target_contact_id
          contact {
            profile_image {
              url
            }
            id
            contact_attributes {
              name_full
              name_first
              name_last
              year
            }
            constituencies {
              status
            }
            household_members {
              id
              contact_attributes {
                name_full
              }
              profile_image {
                url
              }
            }
            emails {
              email
              primary
            }
            phones {
              primary
              phone
              type
            }
            addresses {
              primary
              time_zone {
                time_zone
                zip_code
                observes_dst
                offset
              }
            }
          }
        }
      }
    }
  `,
  fetchPaginatedActiveContactJourneys: gql`
    query ($page: Int!, $perPage: Int!, $filter: PaginatedContactJourneyFilter, $constituentsTaskStatuses: [String!]) {
      paginatedContactJourneys(page: $page, perPage: $perPage, filter: $filter) {
        items {
          id
          user_id
          contact_id
          user {
            name
          }
          tasks(statuses: $constituentsTaskStatuses) {
            title
            current_due_date
            status
            target_contact_id
            journey_task {
              step_number
              day_number
            }
          }
          contact {
            profile_image {
              url
            }
            id
            contact_attributes {
              name_full
              year
            }
            constituencies {
              status
            }
            phones {
              primary
              phone
              type
            }
          }
        }
        count
      }
    }
  `,
};

export default journeysQueries;
