import { useState, useEffect } from 'react';
import Api from 'entities/helpers/api';
import OrgStore from 'base/org/org-store';
import _ from 'underscore';

const useFetchTasksEmailAlertPrefs = (isModalOpen) => {
  const [alertPrefs, setAlertPrefs] = useState({});

  useEffect(() => {
    const fetchPrefs = async () => {
      const resp = await Api.EMS.PREFS.get({
        params: {
          key_pattern: `journeys:tasks:oid-${OrgStore.getCurrentOrg().id}:`,
        },
      });
      const alertPref = resp.preferences.find((pref) => pref.oid === OrgStore.getCurrentOrg().id);
      if (!_.isEmpty(alertPref)) setAlertPrefs(alertPref);
    };
    fetchPrefs();
     
  }, [isModalOpen]);

  return { alertPrefs };
};

export default useFetchTasksEmailAlertPrefs;
