import EverTrue from 'app';
import { Icon } from '@evertrue/et-components';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalOutreachProvider } from 'apps/outreach/contexts/global-outreach-context';
import { createContext, useContext, useState, useCallback } from 'react';
import Drawer from '../drawer';
import Dialer from 'components/dialer/dialer';
import PropTypes from 'prop-types';
import AiProfileChat from 'apps/constituents/ai-profile-chat/components/ai-profile-chat';
import EmailerController from 'components/emailer/emailer-controller';

const GlobalDrawerContext = createContext(() => {});

/**
 * this context is responsible for managing ALL drawers on the application.
 * It is accessed in the DrawerContainer component, which is rendered in the LayoutController
 * "drawers" state is an array of objects (see details in the newDrawer function).
 * the <Drawer /> component should NEVER used outside of this context. It is important that all Drawer state be managed here.
 * The way to do that is by using the addDrawer and removeDrawer functions.
 */
const GlobalDrawerProvider = ({ children }) => {
  const [drawers, setDrawers] = useState([]);
  const [disableDragAndDrop, setDisableDragAndDrop] = useState(false);
  const { openQuickInteraction, activeCall } = useGlobalOutreachProvider();
  //State to manage the visibility of the confirmation modal defind inside the Drawer component
  const [modalConfirmState, setModalConfirmState] = useState({});

  // Disable Drawer drag and drop
  const disableDrawerDragAndDrop = useCallback((isDisabled) => {
    setDisableDragAndDrop(isDisabled);
  }, []);

  // update the state of the Modal Confirm Drawer by id because Drawer component props are not updated when the state changes as its in callback function
  const updateModalConfirmState = (id, value, type) => {
    setModalConfirmState((prevState) => ({
      ...prevState,
      [id]: { value, type },
    }));
  };

  // Remove Drawer from Drawers Array
  const removeDrawer = useCallback((id) => {
    setDrawers((previous) => previous.filter((drawer) => drawer.id !== id));
  }, []);

  // Remove Drawer from Drawers Array AND Open the Quick Interaction Modal
  // for detailed docs about Quick Interactions, go look at the QuickInteractionModal
  const closeDrawerAndOpenQuickInteractionModal = useCallback(
    (drawerId, drawerType, args) => {
      //  close the drawer
      removeDrawer(drawerId);
      // Open Quick Interaction Modal
      openQuickInteraction(drawerType, args);
    },
    [removeDrawer, openQuickInteraction]
  );

  // Create an object to add to drawers array
  // Object contains a unique uuid, type, and a rendering function
  // newDrawer should never be called on its own and should not be exported from this context.
  // always use addDrawer, because it puts the drawer inside the array.
  const newDrawer = useCallback(
    (type, properties = {}) => {
      const id = uuidv4();
      const { drawerTitle = 'New Message', closeModalProps, scrollable = false, ...childProps } = properties; // destructure props that go to the Drawer from the rest
      let headerIcon;
      if (type === 'emailer') {
        headerIcon = <Icon icon="email-drawer-icon" iconKit="gt2" />;
      }
      return {
        id,
        type,
        childProps,
        drawer: () => (
          <Drawer
            id={id}
            key={id}
            type={type}
            scrollable={scrollable}
            drawerTitle={drawerTitle}
            onClose={() => removeDrawer(id)}
            onDrawerFullScreen={disableDrawerDragAndDrop}
            drawerClassName={`${type}-drawer`}
            headerIcon={headerIcon}
            closeModalProps={closeModalProps}
            modalConfirmClassName={`${type}-modal-confirm`}
          >
            {type === 'dialer' && (
              <Dialer
                {...childProps}
                handleCallEnd={(quickInteractionData) =>
                  closeDrawerAndOpenQuickInteractionModal(id, type, quickInteractionData)
                }
              />
            )}
            {type === 'emailer' && (
              <EmailerController
                {...childProps}
                closeDrawer={() => removeDrawer(id)}
                handleClose={(quickInteractionData) =>
                  closeDrawerAndOpenQuickInteractionModal(id, type, quickInteractionData)
                }
              />
            )}
            {type === 'ai' && (
              <AiProfileChat
                {...childProps}
                disableModalConfirm={(value) => updateModalConfirmState(id, value, type)}
              />
            )}
          </Drawer>
        ),
      };
    },
    [removeDrawer, disableDrawerDragAndDrop, closeDrawerAndOpenQuickInteractionModal]
  );

  // Insert new Drawer into Drawers Array with checks for drawer limits
  const addDrawer = useCallback(
    (type, props = {}) => {
      setDrawers((previous) => {
        const dialerDrawer = previous.find((drawer) => drawer.type === 'dialer');
        const emailerCount = previous.filter((drawer) => drawer.type === 'emailer').length;

        // Check for dialer logic
        if (type === 'dialer') {
          if (dialerDrawer) {
            if (activeCall) {
              // Alert if there's an active call and a dialer is already open
              EverTrue.Alert.warn("Can't open another dialer during an active call.");
              return previous;
            } else {
              // Replace the current dialer if there's no active call
              return previous.map((drawer) => (drawer.type === 'dialer' ? newDrawer(type, props) : drawer));
            }
          } else {
            // Add new dialer if no dialer is currently open
            return [...previous, newDrawer(type, props)];
          }
        }

        // Emailer logic (up to 3 open at a time)
        if (type === 'emailer' && emailerCount >= 3) {
          EverTrue.Alert.warn('You can only have 3 email drawers open at a time.');
          return previous;
        }

        // Add new drawer for types other than "dialer"
        return [...previous, newDrawer(type, props)];
      });
    },
    [newDrawer, activeCall]
  );

  // Clear all drawers from the array.
  // (this is not presently used anywhere)
  const resetDrawers = () => {
    setDrawers([]);
  };

  return (
    <GlobalDrawerContext.Provider
      value={{
        drawers,
        addDrawer,
        removeDrawer,
        resetDrawers,
        disableDragAndDrop,
        modalConfirmState,
      }}
    >
      {children}
    </GlobalDrawerContext.Provider>
  );
};

const useGlobalDrawerProvider = () => {
  const context = useContext(GlobalDrawerContext);
  if (!context) {
    throw new Error('useGlobalDrawerProvider must be used within a GlobalDrawerProvider');
  }
  return context;
};

GlobalDrawerProvider.propTypes = { children: PropTypes.any };

export { GlobalDrawerProvider, GlobalDrawerContext, useGlobalDrawerProvider };
