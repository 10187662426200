import { Fragment, useState } from 'react';
import classNames from 'classnames';
import FormField from 'components/forms/form-field';
import TimeBlockForm from 'apps/my-trips/components/timeblock-form';
import MeetingForm from 'apps/my-trips/components/meeting-form';
import { ModalConfirm, Icon, Radio } from '@evertrue/et-components';
import CharacterCounter from 'apps/interactions/components/character-counter';

const AddEventModal = ({ event = {}, tripId = null, remove = () => {} }) => {
  const [editMeetingDetails, setEditMeetingDetail] = useState(false);
  const [selectedType, setSelectedType] = useState('meeting');
  const [title, setTitle] = useState('');

  const onSubmit = () => {
    setEditMeetingDetail(true);
  };

  const disableSave = () => {
    if (title.length > 120) {
      return true;
    }
    if (title.replace(/\s/g, '').length === 0) {
      return true;
    }
    return false;
  };

  if (editMeetingDetails) {
    if (selectedType === 'timeblock') {
      return <TimeBlockForm title={title} headerVerb="Add" timeblock={event} tripId={tripId} remove={remove} />;
    } else {
      return <MeetingForm title={title} headerVerb="Create" meeting={event} tripId={tripId} remove={remove} />;
    }
  } else {
    return (
      <ModalConfirm
        header="Add Event"
        buttonLabel="Next"
        onSubmit={onSubmit}
        onCancel={remove}
        disableSave={disableSave()}
        hasMargin
      >
        <Fragment>
          <FormField label="Title*">
            <input
              type="text"
              placeholder="Enter event name..."
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
            <CharacterCounter limit={120} count={title.length} />
          </FormField>
          <div className="add-event-subtitle">Select your event type</div>
          <fieldset>
            <div className="event-type-row">
              <Radio
                checked={selectedType === 'meeting'}
                onChange={() => setSelectedType('meeting')}
                className="event-type-row--radio"
                name="addEvent"
                value={selectedType}
              >
                <div className="event-type-row--content">
                  <Icon
                    icon="meeting"
                    className={classNames('event-type-row--icon', {
                      'is-selected': selectedType === 'meeting',
                    })}
                  />
                  <div className="event-type-row--text">
                    <div className="event-type-row--text-title">Meeting</div>
                    <div>Schedule a Meeting</div>
                  </div>
                </div>
              </Radio>
            </div>
            <div className="event-type-row">
              <Radio
                checked={selectedType === 'timeblock'}
                onChange={() => setSelectedType('timeblock')}
                className="event-type-row--radio"
                name="addEvent"
                value={selectedType}
              >
                <div className="event-type-row--content">
                  <Icon
                    icon="timeblock"
                    className={classNames('event-type-row--icon', {
                      'is-selected': selectedType === 'timeblock',
                    })}
                  />
                  <div className="event-type-row--text">
                    <div className="event-type-row--text-title">Time Block</div>
                    <div>Block out trip time for travel/flight/hotel/etc</div>
                  </div>
                </div>
              </Radio>
            </div>
          </fieldset>
        </Fragment>
      </ModalConfirm>
    );
  }
};

AddEventModal.propTypes = {
  event: ReactLibs.PropTypes.object,
  tripId: ReactLibs.PropTypes.number,
  remove: ReactLibs.PropTypes.func,
};

export default AddEventModal;
