import { PropTypes } from 'base/react-libs';
import { getEmptySectionName } from 'apps/home/utils/helpers';

const EmptyRecommendedSection = ({ subtitle }) => {
  const section = getEmptySectionName(subtitle);

  return (
    <>
      {section === 'contact reports' ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className="homepage-recommendations-empty">
            You're up to date! You have no recent trips without a subsequent contact report. Great work.
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className="homepage-recommendations-empty">No {section} related recommendations.</div>
        </div>
      )}
    </>
  );
};

EmptyRecommendedSection.propTypes = {
  subtitle: PropTypes.string,
};

export default EmptyRecommendedSection;
