import { trackOutreachAction } from 'apps/outreach/utils/utils';

export const getOutreachOptions = (outreachType, discard, contact, outreach, drawers, addDrawer) => [
  {
    id: 1,
    icon: 'edit',
    label: `Edit ${outreachType}`,
    onClick: () => {
      const drawer = drawers.find((d) => {
        return d.type === 'emailer' && d.childProps?.outreach?.id === outreach?.id;
      });
      if (!drawer) trackOutreachAction('open_emailer');
      addDrawer('emailer', {
        drawerTitle: `Edit - ${outreachType}`,
        contact,
        outreach,
        outreachType,
      });
    },
  },
  {
    id: 2,
    icon: 'trash',
    label: `Discard ${outreachType}`,
    onClick: discard,
  },
];
