import PropTypes from 'prop-types';
import classNames from 'classnames';
import EngagementIcon from 'apps/profile/components/engagement-icon';

const EngagementIconList = ({ size = '24px', icons = [], className, disabled = false }) => {
  const zIndex = icons.length;

  return (
    <div className={classNames('engagement-icon-list clearfix', className)}>
      {icons.map((icon, index) => {
        const classes = classNames('engagement-icon-list--icon', {
          'is-disabled': disabled,
        });

        return <EngagementIcon className={classes} zIndex={zIndex - index} key={icon} icon={icon} size={size} />;
      })}
    </div>
  );
};

EngagementIconList.propTypes = {
  className: PropTypes.string,
  icons: PropTypes.array,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

export default EngagementIconList;
