/* eslint-disable react/prop-types */
import { useState } from "react";
import EverTrue from "app";
import _ from "underscore";
import { Formik, Field, ErrorMessage } from "formik";
import Layout from "components/layout";
import Module from "components/module";
import FormField from "components/forms/form-field";
import PageHeader from "apps/layout/components/page-header";
import { Button, Icon, Modal2, AdvancedCombobox } from "@evertrue/et-components";
import { useIdentity } from "base/identity-resolver";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";
import BulkCSVInviteModal from "apps/users/components/bulk-csv-invite-modal";
import { USER_VALIDATION_SCHEMA, SSO_USER_VALIDATION_SCHEMA } from "components/helpers/form-validation-schemas.js";

const InvitationController = () => {
  const USER_ROLES = [
    { label: "User", value: "user" },
    { label: "Owner", value: "owner" },
  ];

  const InitialValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    jobTitle: "",
    userRole: "",
    classification: "",
    uid: "",
    department: null,
  };

  const [showCSVModal, setShowCSVModal] = useState(false);

  const { org } = useIdentity();
  const departments = usePromiseResolver(() => API.AUTH.DEPARTMENTS.get({ urlArgs: { oid: org.id } }), [org.id])[0];
  const classifications = usePromiseResolver(() => API.AUTH.PERSONAS.get({ urlArgs: { oid: org.id } }), [])[0];

  const showUIDField = org.sso_method !== "saml";

  const department_options =
    departments &&
    departments.map((dep) => {
      return { label: dep.value, value: dep.id };
    });

  const classification_options =
    classifications &&
    classifications.map((cl) => {
      return { label: cl.value, value: cl.value };
    });

  const onSubmit = (values, resetForm) => {
    API.AUTH.AFFIL_INVITES.post({
      urlArgs: { oid: org.id },
      data: _.jsonStringify(
        _.compactObject({
          affiliation_invitation: {
            email: values.emailAddress,
            role_name: values.userRole.value,
            first_name: values.firstName,
            last_name: values.lastName,
            school_division_department_id: values.department && values.department.value,
            persona: values.classification.value,
            title: values.jobTitle,
            saml_user_id: values.uid,
          },
        })
      ),
    })
      .then(() => {
        EverTrue.Alert.success("Invitation Sent");
        resetForm();
      })
      .catch(() => {
        EverTrue.Alert.error("There was a problem sending the invitation");
      });
  };

  const disableButton = (values) => {
    if (showUIDField) {
      return (
        !values.firstName.length &&
        !values.lastName.length &&
        !values.jobTitle.length &&
        !values.classification.value &&
        !values.userRole.value &&
        !values.uid
      );
    }
    return (
      !values.firstName.length &&
      !values.lastName.length &&
      !values.jobTitle.length &&
      !values.classification.value &&
      !values.userRole.value
    );
  };

  const renderCSVInviteModal = () => {
    return (
      <Modal2 isOpen={showCSVModal}>
        <BulkCSVInviteModal onClose={() => setShowCSVModal(false)} />
      </Modal2>
    );
  };

  const renderNonSSOFields = ({ values, errors, touched, handleChange }) => {
    return (
      <div className="field duel-fields">
        <FormField className="single-field first" label="UID" inlineLabel={false}>
          <Field
            name="uid"
            type="text"
            className={errors.uid && touched.uid ? "error" : ""}
            placeholder="e.g. 12345678910"
          />
          <ErrorMessage name="uid" component="div" className="error-feedback" />
        </FormField>
        <FormField className="single-field" label="Role" inlineLabel={false}>
          <AdvancedCombobox
            title="Role"
            tabIndex="0"
            className={
              errors.userRole && touched.userRole
                ? "advanced-combobox-secondary error"
                : "advanced-combobox-secondary combo-box-height-adjustment"
            }
            options={USER_ROLES}
            value={values.userRole}
            onChange={handleChange("userRole")}
          />
          {errors.userRole && touched.userRole && <p className="error-feedback">{errors.userRole.label}</p>}
        </FormField>
      </div>
    );
  };

  const renderSSOFields = ({ values, errors, touched, handleChange }) => {
    return (
      <FormField className="field" label="Role" inlineLabel={false}>
        <AdvancedCombobox
          title="Role"
          tabIndex="0"
          className={
            errors.userRole && touched.userRole
              ? "advanced-combobox-secondary error"
              : "advanced-combobox-secondary combo-box-height-adjustment"
          }
          options={USER_ROLES}
          value={values.userRole}
          onChange={handleChange("userRole")}
        />
        {errors.userRole && touched.userRole && <p className="error-feedback">{errors.userRole.label}</p>}
      </FormField>
    );
  };

  return (
    <Layout
      overflowYValue="visible"
      header={
        <PageHeader title="Invite Team Members" showBackButton={true}>
          <div className="invitation-header--csv">
            <div>Adding the whole team?</div>
            <Button type="action" className="invitation-header--csv-button" onClick={() => setShowCSVModal(true)}>
              <Icon icon="add" />
              Bulk Invite via CSV
            </Button>
          </div>
        </PageHeader>
      }
    >
      {renderCSVInviteModal()}
      <div className="invitation-body">
        <Module header="Send invite by email">
          <Formik
            initialValues={InitialValues}
            validationSchema={showUIDField ? USER_VALIDATION_SCHEMA : SSO_USER_VALIDATION_SCHEMA}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values, resetForm);
            }}
          >
            {(props) => {
               
              const { errors, touched, values, handleChange, handleReset, handleSubmit, isSubmitting } = props;
              return (
                <form className="invitation-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="field duel-fields">
                      <FormField className="single-field first" label="First Name" inlineLabel={false}>
                        <Field
                          name="firstName"
                          type="text"
                          className={errors.firstName && touched.firstName ? "error" : ""}
                          placeholder="First"
                        />
                        <ErrorMessage name="firstName" component="div" className="error-feedback" />
                      </FormField>
                      <FormField className="single-field" label="Last Name" inlineLabel={false}>
                        <Field
                          name="lastName"
                          type="text"
                          className={errors.lastName && touched.lastName ? "error" : ""}
                          placeholder="Last"
                        />
                        <ErrorMessage name="lastName" component="div" className="error-feedback" />
                      </FormField>
                    </div>
                    <FormField className="field" label="Email Address" inlineLabel={false}>
                      <Field
                        name="emailAddress"
                        type="text"
                        className={errors.emailAddress && touched.emailAddress ? "error" : ""}
                        placeholder="e.g. email@email.com"
                      />
                      <ErrorMessage name="emailAddress" component="div" className="error-feedback" />
                    </FormField>
                    <FormField className="field" label="Title" inlineLabel={false}>
                      <Field
                        name="jobTitle"
                        type="text"
                        className={errors.jobTitle && touched.jobTitle ? "error" : ""}
                        placeholder="Job Title"
                      />
                      <ErrorMessage name="jobTitle" component="div" className="error-feedback" />
                    </FormField>
                  </div>
                  <div className="row">
                    {showUIDField ? renderSSOFields(props) : renderNonSSOFields(props)}
                    <FormField className="field" label="Classification" inlineLabel={false}>
                      <AdvancedCombobox
                        title="Classification"
                        tabIndex="0"
                        className={
                          errors.classification && touched.classification
                            ? "advanced-combobox-secondary error"
                            : "advanced-combobox-secondary combo-box-height-adjustment"
                        }
                        options={classification_options}
                        label={values.classification}
                        value={values.classification}
                        onChange={handleChange("classification")}
                      />
                      {errors.classification && touched.classification && (
                        <p className="error-feedback">{errors.classification.label}</p>
                      )}
                    </FormField>
                    <FormField className="field" label="Department (optional)" inlineLabel={false}>
                      <AdvancedCombobox
                        title="Department"
                        tabIndex="0"
                        className="advanced-combobox-secondary combo-box-height-adjustment"
                        options={department_options}
                        label={department_options && department_options.label}
                        value={values.department}
                        onChange={handleChange("department")}
                      />
                    </FormField>
                  </div>
                  <div className="invitation-button-group">
                    <Button
                      type="simple"
                      className="cancel"
                      disabled={disableButton(values)}
                      buttonType="reset"
                      onClick={handleReset}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="invitation-button--invite"
                      disabled={isSubmitting || !!Object.keys(errors).length || disableButton(values)}
                      buttonType="submit"
                    >
                      Invite Users
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Module>
      </div>
    </Layout>
  );
};

export default InvitationController;
