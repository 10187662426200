import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFluxStore } from '@evertrue/et-flux';
import CollaboratorStore from 'apps/lists/stores/collaborator-store';
import AllUsersStore from 'apps/users/stores/all-users-store';
import CollaboratorUserList from './collaborator-user-list';
import CollaboratorInvitation from './collaborator-invitation';
import Module from 'components/module';

const ListShareSettings = ({ listId }) => {
  const [notifyEnabled, setNotifyEnabled] = useState(true);
  const [shareQuery, setShareQuery] = useState(undefined);
  const [tags, setTags] = useState([]);

  const usersToShare = useFluxStore(AllUsersStore, () => ({
    usersToShare: AllUsersStore.getAllGivingTreeUsers(),
    loadingUsersToShare: AllUsersStore.getLoading(),
  }));

  const collaborators = useFluxStore(CollaboratorStore, () => ({
    collaborators: CollaboratorStore.getCollaborators(listId),
  }));

  // Convert object to array before filtering
  const usersArray =
    usersToShare.usersToShare && typeof usersToShare.usersToShare === 'object'
      ? Object.values(usersToShare.usersToShare)
      : [];

  const filteredUsersToShare = usersArray.filter((user) => {
    const alreadyCollaborator = collaborators.collaborators.some((collab) => collab.collaborator_user_id === user.id);
    if (alreadyCollaborator) return false;
    if (!shareQuery) return true;
    const queryLower = shareQuery.toLowerCase();
    return user.name.toLowerCase().includes(queryLower) || user.email.toLowerCase().includes(queryLower);
  });

  return (
    <div className="share-settings">
      <CollaboratorUserList listId={listId} />
      <Module header="Add Collaborators" icon="fa fa-fw fa-envelope" noMargin>
        <CollaboratorInvitation
          listId={listId}
          tags={tags}
          setTags={setTags}
          shareQuery={shareQuery}
          setShareQuery={setShareQuery}
          notifyEnabled={notifyEnabled}
          setNotifyEnabled={setNotifyEnabled}
          filteredUsersToShare={filteredUsersToShare}
        />
      </Module>
    </div>
  );
};

ListShareSettings.propTypes = {
  listId: PropTypes.number.isRequired,
};

export default ListShareSettings;
