import { PropTypes } from 'base/react-libs';
import { BarChart, Bar, XAxis, Legend, Tooltip, LabelList } from 'recharts';
import CustomLegend from './custom-legend';
import CustomTooltip from './custom-tooltip';
import colors from 'base/colors';

const DaysBarChart = ({ nextFiveDaysCounts = [] }) => {
  return (
    <BarChart
      width={500}
      height={300}
      data={nextFiveDaysCounts}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 30,
      }}
    >
      {nextFiveDaysCounts && nextFiveDaysCounts.length && (
        <XAxis dataKey="name" tickLine={false} tick={{ fill: '#454545' }} />
      )}
      <Tooltip content={<CustomTooltip />} />
      <Legend
        iconSize={0}
        layout="horizontal"
        verticalAlign="top"
        align="center"
        wrapperStyle={{ padding: '10px' }}
        formatter={CustomLegend}
      />
      <Bar dataKey="Emails" fill={colors.blue300} name="Emails" />
      <Bar dataKey="Calls" fill={colors.gray300} name="Calls">
        <LabelList dataKey="Total" position="bottom" style={{ fontWeight: 'bold', transform: 'translateY(35px)' }} />
      </Bar>
      <Bar dataKey="Other" fill={colors.gray900} name="Other" />
    </BarChart>
  );
};

DaysBarChart.propTypes = {
  nextFiveDaysCounts: PropTypes.array,
};

export default DaysBarChart;
