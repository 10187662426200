import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';
import useCallSettings from 'apps/settings/phone-settings/use-call-settings';
import TwilioCallButton from '../components/twilio-call-button';
import { useIdentity } from 'base/identity-resolver';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { useState, useEffect } from 'react';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import { validateNumber, formattedUSPhoneNumber } from 'components/dialer/utils/dialer-utils';
import { establishTwilioDevice, createActionFromCall } from '../utils/twilio-utils';
import useTimer from 'hooks/use-timer';
import { getVoipOrgSettings } from 'apps/settings/phone-settings/use-call-settings-requests';

const useTwilio = () => {
  const [device, setDevice] = useState(null);
  const [activeCall, setActiveCall] = useState(null);
  const [activeCallNotes, setActiveCallNotes] = useState('');
  const { session } = useIdentity();
  const { callerIdNumber } = useCallSettings();
  const { startTimer, stopTimer, Timer } = useTimer();
  const { flagEnabled: hasSignal, loaded: loadedHasSignal } = useFeatureFlag('signal');

  const isSessionExpired = (session) => {
    if (!session || !session.expire_at) {
      return true;
    }
    return Date.now() > session.expire_at;
  };

  const resetDialer = () => {
    stopTimer();
    setActiveCall(null);
    setActiveCallNotes('');
  };

  useEffect(() => {
    const setupVoip = async () => {
      if (!loadedHasSignal) return;

      let hasVoipEnabled = false;

      if (hasSignal) {
        const settings = await getVoipOrgSettings();
        hasVoipEnabled = !!settings;
      }

      if (!hasVoipEnabled) {
        if (EverTrue.config.is_development) console.log('[VOIP] Organization does not have VoIP enabled.');
        return;
      }

      if (hasVoipEnabled && !device) {
        const twilioDevice = await establishTwilioDevice();
        setDevice(twilioDevice);
        if (EverTrue.config.is_development) console.log('[VOIP] Establishing VoIP Device');
      }
    };

    setupVoip();

    return () => {
      if (device) device.destroy();
    };
  }, [hasSignal, loadedHasSignal, device]);

  const startCall = async (phoneNumber, extension, contactId, onCallDisconnect) => {
    // validations

    if (activeCall) {
      EverTrue.Alert.error('You are already on an active call. You may only have one open call at a time.');
      return;
    }
    if (isSessionExpired(session)) {
      EverTrue.Alert.warn(
        'Unable to connect the call as your session has expired. Please refresh the page or log back in.'
      );
      return;
    }

    if (callerIdNumber) {
      trackOutreachAction('make_phone_call');
      if (device) {
        const { oid, app_key, token } = session;
        const isValid = validateNumber(phoneNumber, true);
        if (isValid) {
          const call = await device.connect({
            params: {
              oid: String(oid),
              app_key,
              auth: token,
              To: formattedUSPhoneNumber(phoneNumber),
              ext: extension,
            },
          });

          // event listeners
          // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#events
          const startTime = new Date().getTime();
          call.on('ringing', () => setActiveCall(call));

          call.on('accept', (callObject) => {
            // this happens when the outgoing call has been established
            setActiveCall(call);
            startTimer(startTime);
          });

          call.on('cancel', () => {
            // this happens when the user cancels the call BEFORE anyone answers.
            resetDialer();
          });

          call.on('disconnect', (callObject) => {
            // this happens when a call ends.
            onCallDisconnect(); // Function created by the Global Outreach Context. Used to open Quick Interaction Modal
            createActionFromCall(callObject, startTime, contactId, activeCallNotes);
            stopTimer();
            setActiveCall(null);
          });

          call.on('reconnecting', (TwilioError) => {
            //this happens when the connectivity was lost and that the SDK is trying to reconnect
            EverTrue.Alert.warn(TwilioError.causes[0], { id: TwilioError.code, timeout: 10000 });
            ErrorLogger.error('Twilio.Call Error', {
              extra: {
                error: TwilioError.code + ':' + TwilioError.description + ' ' + TwilioError.causes[0],
              },
            });
          });

          call.on('reconnected', () => {
            //this happens when the connection was established.
            EverTrue.Alert.success('The call has regained connectivity');
          });

          call.on('warning', function (warningName, warningData) {
            if (warningName === 'low-mos') {
              EverTrue.Alert.warn(
                'We have detected poor call quality conditions. You may experience degraded call quality.'
              );
            }
          });

          call.on('warning-cleared', function (warningName) {
            if (warningName === 'low-mos') {
              EverTrue.Alert.success('Call quality conditions restored');
            }
          });

          call.on('error', (TwilioError) => {
            // this happens when there is an error with the call.
            // needs more details
            // needs to create action from error
            EverTrue.Alert.error(TwilioError.causes[0]);
            ErrorLogger.error('Twilio.Call Error', {
              extra: {
                error: TwilioError.code + ':' + TwilioError.description + ' ' + TwilioError.causes[0],
              },
            });
            resetDialer();
          });
        } else EverTrue.Alert.error('Invalid Phone Number');
      } else {
        EverTrue.Alert.error('Sorry, there was an issue with our Caller integration. Please try again.');
      }
    } else {
      EverTrue.Alert.error(
        'Error: Caller ID is required to make this call. Please set up your caller ID number in settings.',
        {
          actionLabel: 'Set Up Caller ID',
          onAction: () => (window.location.href = '/settings/outreach'),
        }
      );
    }
  };

  const endCall = () => {
    if (device) device.disconnectAll();
    setActiveCall(null);
  };

  const TwilioCallButtonWithContext = (props) => (
    <TwilioCallButton device={device} activeCall={activeCall} startCall={startCall} endCall={endCall} {...props} />
  );

  return {
    hasVoip: !!device,
    onCall: !!activeCall,
    activeCall,
    DialerButton: TwilioCallButtonWithContext,
    CallTimer: Timer,
    activeCallNotes,
    setActiveCallNotes,
    endCall,
  };
};

export default useTwilio;

//

// IF WE WANT INCOMING CALLS:
// 1. we need to update the outreach service to enable incoming calls (currently disabled).
// 2. Register Device (Only necessary for incoming calls) (twilioDevice.register();)
// 3. add event listener for registered (optional) twilioDevice.on("registered", () => {
//   EverTrue.Alert.success("Your Voice Device is ready to use!");
// });
// 4. add event listener for receiving incoming calls (twilioDevice.on("incoming", handleIncomingCall);)

// IF WE WANT TO ALLOW DIFFERENT AUDIO DEVICES
// twilioDevice.audio.on("deviceChange", updateAllAudioDevices.bind(device));

// // Show audio selection UI if it is supported by the browser.
// if (twilioDevice.audio.isOutputSelectionSupported) {
//   audioSelectionDiv.classList.remove("hide");
// }
