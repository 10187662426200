import { Button } from '@evertrue/et-components';
import { useFluxStore } from '@evertrue/et-flux';
import { useState, useEffect } from 'react';
import { prevFocusWithWrap, nextFocusWithWrap, moveFocusToMain, containsActiveElement } from 'base/accessibility-utils';
import Icon from 'components/elements/icon';
import IsGated from 'components/is-gated';
import EverTrue from 'app';
import classNames from 'classnames';
import UrlSource from 'apps/layout/sources/url-source';
import ListSource from 'apps/lists/sources/list-source';
import ImportStore from 'apps/layout/stores/import-store';
import ImportSource from 'apps/layout/sources/import-source';
import LayoutStore from 'apps/layout/stores/layout-store';
import LayoutSource from 'apps/layout/sources/layout-source';
import FeatureStore from 'apps/layout/stores/feature-store';
import SegmentStore from 'apps/filters/stores/segment-store';
import FilterSource from 'apps/filters/sources/filter-source';
import ContactSource from 'apps/contact/sources/contact-source';
import StatusIndicator from 'apps/layout/components/status-indicator';
import KeyPressManager from 'components/helpers/KeyPressManager';
import NavigationSubItem from 'apps/layout/components/navigation-sub-item';
import NavigationTaskLink from 'apps/tasks/components/tasks-link';
import NavigationTableauLink from 'apps/layout/components/navigation-tableau-link';
import NavigationJourneysLink from 'apps/layout/components/navigation-journeys-link';
import NavigationOutreachLink from 'apps/layout/components/navigation-outreach-link';
import NavigationHomepageLink from 'apps/layout/components/navigation-homepage-link';
import NavigationDashboardLink from 'apps/layout/components/navigation-dashboard-link';

// TODO: Eventually, we'd like to stop using Flux Stores.
const segmentStoreMap = () => ({
  selectedSegment: SegmentStore.getSelectedSegment(),
});

const layoutStoreMap = () => ({
  navIsCollapsed: LayoutStore.getNavState(),
});

const importStoreMap = () => ({
  lastDataUpdate: ImportStore.getLastUpdate(),
});

const featureStoreMap = () => ({
  hasRmAssignment: FeatureStore.hasFeature('rm_assignments'),
  hasMajorGifts: FeatureStore.hasFeature('major_gifts'),
  hasUnreleasedFeature: FeatureStore.hasFeature('unreleased_feature'),
});

// CONSTANTS and REGEX
const RAISE_GATES = ['volunteers', 'rm_assignments', 'my_trips', 'events', 'my_team'];
const REGEX_CONTACT_RECENT = new RegExp(`^\\/contact/recent`);
const REGEX_CONTACT = new RegExp(`^\\/contact`);
const REGEX_SEGMENTS = new RegExp(`^\\/segments`);
const REGEX_LIST = new RegExp(`^\\/list*`);
const REGEX_INTERACTION = new RegExp(`^\\/interaction`);
const REGEX_PROPOSAL = new RegExp(`^\\/proposal`);
const REGEX_EVENTS = new RegExp(`^\\/events.*`);
const REGEX_FACEBOOK = new RegExp(`^\\/facebook\\/.*`);
const REGEX_TRIPS = new RegExp(`^\\/trips.*`);
const REGEX_PORTFOLIO_PERFORMANCE = new RegExp(`portfolio-performance.*`);

const Navigation = () => {
  const [activePath, setActivePath] = useState('');

  const { selectedSegment } = useFluxStore(SegmentStore, segmentStoreMap);
  const { navIsCollapsed } = useFluxStore(LayoutStore, layoutStoreMap);
  const { lastDataUpdate } = useFluxStore(ImportStore, importStoreMap);
  const { hasRmAssignment, hasMajorGifts } = useFluxStore(FeatureStore, featureStoreMap);

  const updateActivePath = () => setActivePath(window.location.pathname);

  useEffect(() => {
    // On Mount
    ListSource.fetchViewed();
    ImportSource.fetchLastImport();
    EverTrue.vent.on('route:change', updateActivePath);
    updateActivePath();

    // On Unmount
    return () => {
      LayoutSource.fetchNavState();
      EverTrue.vent.off('route:change', updateActivePath);
    };
  }, []);

  const handleResetBrowse = () => {
    EverTrue.track.set('tab_view', { type: 'browse' });
    EverTrue.Navigator('/contact', true);
    UrlSource.updateSegment();
    FilterSource.set();
    ContactSource.query();
  };

  const handleNavCollapse = () => LayoutSource.toggleNav();

  const handleNavSubItemClick = (path, type, key = 'tab_view') => {
    EverTrue.track.set(key, { type });
    EverTrue.Navigator(path, true);
  };

  const path = activePath || '';

  const browseIsActive = !selectedSegment && !path.match(REGEX_CONTACT_RECENT) && !!path.match(REGEX_CONTACT);
  const browseSegment = selectedSegment;

  const hasRaiseSection = RAISE_GATES.some((gate) => FeatureStore.hasFeature(gate));

  return (
    <div id="base-nav" className={EverTrue.classSet({ 'is-collapsed': navIsCollapsed })}>
      {/* Header w/ Logo */}
      <div className="base-nav--header">
        <Button type="simple" className="base-nav--logo" onClick={handleResetBrowse} title="Reset to Browse" />
        {navIsCollapsed ? (
          <Button type="simple" className="base-nav--pyramid-logo" onClick={handleNavCollapse} title="Toggle Nav Bar" />
        ) : (
          <Icon icon="collapse" onClick={handleNavCollapse} />
        )}
      </div>
      <KeyPressManager
        onArrowUp={(node, event) => prevFocusWithWrap(node)}
        onArrowDown={(node, event) => nextFocusWithWrap(node)}
        onArrowRight={moveFocusToMain}
        onEnter={(node, event) => {
          setTimeout(() => {
            const main = document.getElementsByTagName('main')[0];
            if (!containsActiveElement(main)) {
              setTimeout(moveFocusToMain, 100);
            }
          }, 0);
        }}
        component="div"
      >
        {/* Main Content */}
        <div className="base-nav--content">
          <div className="base-nav--main">
            <div className="base-nav--main-group">
              <NavigationHomepageLink active_path={path} />
              <NavigationJourneysLink active_path={path} isCollapsed={navIsCollapsed} />
              <NavigationOutreachLink active_path={path} />
              <NavigationDashboardLink active_path={path} />
              <NavigationTaskLink active_path={path} isCollapsed={navIsCollapsed} />
              <NavigationSubItem
                icon="segment"
                label="Saved Searches"
                isActive={!!path.match(REGEX_SEGMENTS) || browseSegment ? true : false}
                onClick={() => handleNavSubItemClick('/segments', 'saved_searches')}
              />
              <NavigationSubItem
                icon="list-view"
                label="Lists"
                isActive={!!path.match(REGEX_LIST)}
                onClick={() => handleNavSubItemClick('/lists', 'lists')}
              />
              <NavigationSubItem
                icon="recently-viewed"
                label="Recently Viewed"
                isActive={!!path.match(REGEX_CONTACT_RECENT)}
                onClick={() => handleNavSubItemClick('/contact/recent', 'recently_viewed')}
              />
            </div>
            <div className="base-nav--line" />
            <div className="base-nav--main-group">
              <div className={classNames('base-nav--main-group-title', { 'is-collapsed': navIsCollapsed })} />
              <NavigationSubItem
                icon="person"
                label="Constituents"
                isActive={browseIsActive}
                onClick={() => handleNavSubItemClick('/contact', 'browse')}
              />
              <NavigationSubItem
                icon="interactions"
                label="Interactions"
                isActive={!!path.match(REGEX_INTERACTION)}
                onClick={() => handleNavSubItemClick('/interaction', 'interactions')}
              />
              <NavigationSubItem
                icon="other-interaction"
                label="Proposals"
                isActive={!!path.match(REGEX_PROPOSAL)}
                onClick={() => handleNavSubItemClick('/proposal', 'trips')}
              />
              <IsGated feature="events">
                <NavigationSubItem
                  icon="event"
                  label="Events"
                  isActive={!!path.match(REGEX_EVENTS)}
                  onClick={() => handleNavSubItemClick('/events', 'events')}
                />
              </IsGated>
              <NavigationSubItem
                icon="facebook"
                label="Facebook"
                isActive={!!path.match(REGEX_FACEBOOK)}
                onClick={() => handleNavSubItemClick('/facebook/pages', 'facebook')}
              />
            </div>
            <div className="base-nav--line" />
            {hasRaiseSection && (
              <div className="base-nav--main-group">
                <div className={classNames('base-nav--main-group-title', { 'is-collapsed': navIsCollapsed })} />
                <IsGated feature="my_trips">
                  <NavigationSubItem
                    icon="meeting"
                    label="Trips & Meetings"
                    isActive={!!path.match(REGEX_TRIPS)}
                    onClick={() => handleNavSubItemClick(`/trips?trip_user=${EverTrue.store.user.get('id')}`, 'trips')}
                  />
                </IsGated>
                {hasMajorGifts && !hasRmAssignment && (
                  <NavigationSubItem
                    icon="work"
                    label="My Portfolio"
                    isActive={!!path.match(new RegExp(`my-portfolio\\/.*`))}
                    onClick={() => handleNavSubItemClick(`/my-portfolio/prospects`, 'portfolio')}
                  />
                )}
                <IsGated feature="rm_assignments">
                  <NavigationSubItem
                    icon="insert-chart"
                    label="Portfolios"
                    isActive={!!path.match(REGEX_PORTFOLIO_PERFORMANCE)}
                    onClick={() => handleNavSubItemClick('/portfolio-performance', 'portfolio')}
                  />
                </IsGated>
                <IsGated feature="volunteers">
                  <NavigationSubItem
                    icon="group"
                    label="Volunteers"
                    isActive={!!path.match('volunteers')}
                    onClick={() => handleNavSubItemClick('/volunteers/pools', 'volunteers')}
                  />
                </IsGated>
                <NavigationTableauLink active_path={path} />
              </div>
            )}
          </div>
        </div>
        {/* Footer */}
        <div className={EverTrue.classSet('base-nav--footer', { 'is-collapsed': navIsCollapsed })}>
          <div>
            {EverTrue.config.environment_data && (
              <div className="base-nav--db-data-tag">
                <span className="badge-warning">{EverTrue.config.environment_data}</span>
              </div>
            )}
            <NavigationSubItem
              className="base-nav--footer-item"
              isFooter={true}
              label={`Last Data Update: ${lastDataUpdate}`}
            >
              <div>
                <Icon icon="access-time" />
                <strong className="base-nav--footer-text">Last Data Update</strong>
              </div>
              <div className="base-nav--data-update">{lastDataUpdate || 'Unavailable'}</div>
            </NavigationSubItem>
            <NavigationSubItem className="base-nav--footer-item" isFooter={true} label="EverTrue Status">
              <StatusIndicator />
            </NavigationSubItem>
          </div>
        </div>
      </KeyPressManager>
    </div>
  );
};

export default Navigation;
