module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   EverTrue = require("app")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   ChildDoc = require("entities/search/query-parts/child-doc-query")
   FacetUtils = require("apps/filters/utils/facet-utils")
   {div, span, i} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Range = createFactory require("components/forms/range")
   ScoreProgress = createFactory require("apps/contact/components/contacts/score-progress").default
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   Icon = createFactory (require("components/elements/icon").default)
   FilterParser = require("apps/filters/utils/filter-parser-utils")
   FacebookPagesDropdown = require("apps/facebook/components/facebook-pages-dropdown")
   NewResultsFilterDisplay = require("apps/filters/components/new-results-filter-display")
   StringSearchDisplay = require("components/formatting/string-search-display")
   InteractionUtils = require("apps/interactions/interaction-utils")
   FiscalGivingFilter = require("apps/filters/components/fiscal-giving-filter")
   FacebookReactionsDropdown = require("apps/facebook/components/facebook-reactions-dropdown")
   InteractionLabelFilter = createFactory(require("apps/interactions/components/interaction-label-filter").default)
   InteractionSolicitorFilter = createFactory(require("apps/interactions/components/interaction-solicitor-filter").default)
   ProposalSolicitorFilter = createFactory(require("apps/proposals/components/proposal-solicitor-filter").default)
   ProspectStatusDropdown = require("apps/major-gifts/components/prospect-status-dropdown")
   VolunteerPoolDropdown = createFactory(require("apps/volunteers/components/volunteer-pool-dropdown").default)
   VolunteerDropdown = require("apps/volunteers/components/volunteer-dropdown")
   SolicitorDropdown = createFactory require("apps/portfolio-performance/components/solicitor-dropdown").default
   RmStatusDropdown = createFactory require("apps/portfolio-performance/components/stage-dropdown").default
   EventEngagementFiltersBlock = require("apps/filters/components/event-engagement-filters-block")
   EventEngagementParamSelector = require("apps/filters/components/event-engagement-param-selector")
   EventFiltersUtils = require("apps/filters/utils/event-filters-utils")
   FeatureStore = require("apps/layout/stores/feature-store").default
   GiftTransactionConfig = require("apps/filters/gift-transaction-filter/gift-transaction-filter-config").default
   CategorizedGivingConfig = require("apps/filters/categorized-giving-filter/categorized-giving-filter-config")
   GivingPatternFilter = createFactory require("apps/filters/components/filter-giving-pattern").default

   # Query Value Function Helpers
   _getAddressTypes = ->
      types = EverTrue.store.facets?["addresses.type"]
      home_types = _.compact _.map types, (type) ->
         if !!type?.value?.match(/(home)/i) then type.value

      if !_.isEmpty(home_types)
         Property("type", home_types, {type: "contains", parent: "addresses", parent_type: "instance"})

   _getQueryObjectForDate = (object) ->
      # expects object == {parameter: "...", value: "...", range: {}}
      if object.value == "range" && object.range # range of dates
         val = _.clone(object.range)
         val.gte = moment(val.gte).format("YYYY-MM-DD") if val.gte
         val.lte = moment(val.lte).format("YYYY-MM-DD") if val.lte
      else
         val = {gte: object?.value}
      val

   _getQueryObjectForDateOperator = (object) ->
      # expects object == {parameter: {}, from: "...", to: "...", in: "...", date: "..."}
      current_date = new Date()
      DATE_FORMAT = "YYYY-MM-DD"
      CURRENT_DATE = moment(current_date).format(DATE_FORMAT)
      FROM = moment(object.from).format(DATE_FORMAT)
      TO = moment(object.to).format(DATE_FORMAT)
      if object.in
         NUMBER = Number(object.in.split("-")[0])
         STRING = object.in.split("-")[1]
         IN_LAST_DATE = moment(current_date).subtract(NUMBER,STRING).format(DATE_FORMAT)
      if object.date then OBJECT_DATE = moment(object.date).format(DATE_FORMAT) else OBJECT_DATE = CURRENT_DATE
      if object.parameter?.label?.includes("between")
         val = {gte: FROM, lte: TO}
      else if  object.parameter?.label?.includes("last")
         if object.parameter?.value?.split(":")[0] == "must" then val = {gte : IN_LAST_DATE} else val = {gt: IN_LAST_DATE}
      else if object.parameter?.value?.includes("match")  # is on/is not on
         val = {gte: OBJECT_DATE, lte: OBJECT_DATE}
      else
         if object.parameter?.label?.includes("after") then val = {gt: OBJECT_DATE} else val = {lt: OBJECT_DATE}
   
   _getQueryObjectForCurrencyOperator = (object, property) ->
      if object?.gte && !object?.lte
         val = { gte: object.gte, "coerce": 0 }
         parameter = "must"
         return { val, parameter, property }
      
      if object?.lte && !object?.gte
         val = { lte: object.lte, "coerce": 0 }
         parameter = "must"
         return { val, parameter, property }
   
      return null unless object?.parameter?.label and object?.parameter?.value?

      label = object?.parameter?.label
      val = null
      parameter = object?.parameter?.value?.split(":")[0]

      switch label
         when 'Is between', 'Is not between'
            if object?.gte and object?.lte then val = { gte: object.gte, lte: object.lte } else val = null
         when 'Is greater than'
            if object?.value then val = { gt: object.value} else val = null
         when 'Is less than'
            if object?.value then val = { lt: object.value} else val = null
         when 'Is equal to', 'Is not equal to'
            if object?.value then val = { match: object.value} else val = null
         when 'Is set', 'Is not set'
            val = { exists: label is 'Is set' }
            parameter = "must"
      { label, val, parameter, property }   

   _getQueryObjectForYearOperator = (object, property) ->
      if object?.gte and object?.lte
         val = { gte: object.gte, lte: object.lte }
         parameter = "must"
         return { val, parameter, property }
      
      if object?.gte
         val = { gte: object.gte, "coerce": 0 }
         parameter = "must"
         return { val, parameter, property }
      
      if object?.lte
         val = { lte: object.lte, "coerce": 0 }
         parameter = "must"
         return { val, parameter, property }
      
      return null unless object?.parameter?.label and object?.parameter?.value?

      label = object?.parameter?.label
      val = null
      parameter = object?.parameter?.value?.split(":")[0]

      switch label
         when "Is between", "Is not between"
            if object?.gte and object?.lte then val = { gte: object.gte, lte: object.lte } else val = null
         when "Is greater than"
            if object?.value then val = { gt: object.value } else val = null
         when "Is less than"
            if object?.value then val = { lt: object.value } else val = null
         when "Is equal to", "Is not equal to"
            if object?.value then val = { match: object.value } else val = null
      { label, val, parameter, property }

   

   _getListValues = (value) ->
      _.compact _.map value, (val) -> val?.value

   _getListValuesV2 = (value) ->
      result = { types: [], primary: false }
      _.each value, (val) ->
         if val?.value == "Primary"
            result.primary = true
         else
            result.types.push(val?.value)
      result
   
   _getListValuesPrimary = (value) ->
      groups = { customer: [], primary: false}
      if value?.primary_address
         groups.primary = value.primary_address

      groups.customer = _.compact _.map value?.value, (val) -> val?.value
      groups

   _getGroupedListValues = (value) ->
      groups = {customer: [], enrichment: [],}
      _.each value, (obj) ->
         if FacetUtils.isEnriched(obj?.value)
            groups.enrichment.push FacetUtils.parseValueToString(obj.value)
         else
            groups.customer.push obj.value
      groups

   _getGroupedComponentOperatorValues = (value) ->
      groups = {enrichment: [], customer: [], primary: false}
      if value?.primary_address
         groups.primary = value.primary_address

      if _.isObject(value) && !_.isArray(value)
         groups.condition = value?.parameter?.value
         _.each value?.value, (obj) ->
            if FacetUtils.isEnriched(obj?.value)
               groups.enrichment.push FacetUtils.parseValueToString(obj.value)
            else
               groups.customer.push obj.value
      else if _.isArray(value)
         groups.condition = "must"
         _.each value, (obj) ->
            if FacetUtils.isEnriched(obj?.value)
               groups.enrichment.push FacetUtils.parseValueToString(obj.value)
            else
               groups.customer.push obj.value
      groups

   _formatOrProperties = (prop_values) ->
      properties = _.compact _.map prop_values, (val, key) ->
         unless _.isEmpty(val)
            Property(key, val, {type: "contains"})

      # Default property check require for parsing saved searches
      query_prop = properties.shift() || Property(_.keys(properties), null, {type: "contains"})
      if properties.length && query_prop
         query_prop.orProperties(properties)
      query_prop

   _formatAndProperties = (property, values) ->
      _.map values, (value) ->
         Property property, value, {type: "equals"}

   LIVE_GIFT_PROPERTIES = [
      Property "type", "completed", {type: "equals"},
      Property "livemode", true, {type: "equals"}
   ]

   _liveGiftify = (properties) ->
      LIVE_GIFT_PROPERTIES.concat(properties)

   oneMil = 1000000

   # =========================================================================================================
   # Config Format & Available Options
   # ** FilterConfig object key should match legacy filter key if applicable
   #
   # 'filter_id': REQUIRED string - mapping to key stored in "contacts/v1/lists/filters" (https://github.com/evertrue/contacts#create-a-list-filter)
   # 'order': float number - manage order and grouping of components 4.1 (group 4, first item) (required, except for read-only filters that don't appear in the selector)
   # 'component_type': string - to map to React component setup in segment-filter-row
   # 'component': function - use in place of component_type for non-standard component
   # 'facets': object - add 'type' to reference diffierent ES documents and `fields` for ES keys to grab facets for.
   # 'gated': array of objects - [{hasFeature: "volunteers"}, {doesntHaveFeature: "volunteers"}]
   # 'help': string - string key that maps to et-help-helper.coffee
   # 'parse_fn': function(value) - required if not standard filter query / component, parse from shared searches
   # 'query_fn': REQUIRED function(value) - format Query from values, return Query object or undefined
   # =========================================================================================================

   # Config for List Filters, extending what is stored in the database
   # with facets keys, ui component type, and a query function
   FilterConfig =
      map:
         filter_id: "0.0"
         label: "Map"
         component_type: "read_only"
         component: (input, state, onChange) ->
            if radius = input.value?.radius
               div className: "segment-filter-row--read-only",
                  Icon icon: "map-marker-radius"
                  "#{radius} Map Radius"
            else
               div className: "segment-filter-row--read-only",
                  Icon icon: "map"
                  "Current Map View"
         parse_fn: FilterParser.parsePassThrough
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "location", value, {type: "object", parent: "addresses", parent_type: "instance"}
               ]
            ]

      full_name:
         filter_id: "0.01"
         label: "Constituent Name"
         component_type: "read_only"
         component: (input, state, onChange) ->
            div className: "segment-filter-row--read-only",
               Icon icon: "group"
               "Name: #{input.value}"
         parse_fn: (criteria) ->
            words = _.map criteria.must, (word_query) ->
               word_query.name_first?.wildcard?.replace("*", "")
            words.join(" ")
         query_fn: (query_string) ->
            NAME_KEYS = ["name_first", "name_last", "name_nick", "name_maiden", "name_suffix", "name_prefix"]
            names = if query_string then query_string?.split?(" ") else [query_string]

            properties =
               if _.size(names) > 1
                  _.map names, (name) ->
                     Property NAME_KEYS, name, {type: "wildcard"}
               else
                  remote_ids = _.uniq [query_string, _.trimRemoteId(query_string)]
                  name_property = Property NAME_KEYS, query_string, {type: "wildcard"}
                  remote_id_property = Property "identities.value", remote_ids, {type: "contains"}
                  remote_id_property_untouched = Property "identities.value.untouched", remote_ids, {type: "contains"}
                  wildcard_remote_id_property = Property "identities.value", remote_ids, {type: "wildcard"}
                  wildcard_remote_id_property_untouched = Property "identities.value.untouched", remote_ids, {type: "wildcard"}
                  [name_property.orProperties([remote_id_property, remote_id_property_untouched, wildcard_remote_id_property, wildcard_remote_id_property_untouched])]

            Query [Parameter("must", properties)]

      global_search:
         filter_id: "0.015"
         label: "Keyword Search"
         label_fn: (value) ->
            span null,
               "Search for "
               StringSearchDisplay string: value?.value
         component_type: "read_only"
         parse_fn: (criteria) ->
            FilterParser.parseKeywords(criteria.getByOperator("wildcard"))
         component: (input, state, onChange) ->
            div className: "segment-filter-row--read-only inline",
               Icon icon: "search"
               "Keyword search for "
               StringSearchDisplay string: input?.value?.value
         query_fn: (value) ->
            # Expects value = {type: "constituents", value: "search"}
            switch value.type
               when "interactions"
                  FilterConfig.interaction_keyword.query_fn(value)
               else FilterConfig.full_name.query_fn(value?.value)

      # old version of saved search alerter
      segment_results:
         filter_id: "0.02"
         label: "New Results"
         component_type: "read_only"
         component: (input, state, onChange) ->
            NewResultsFilterDisplay
               className: "segment-filter-row--read-only"
               value: input?.value
         parse_fn: (criteria) ->
            must_query = criteria.getByParameter("must")
            _.eachWithObject must_query, (obj, value) ->
               obj[value.property] = value?.value
         query_fn: (value) ->
            # When this comes back from a URL, the sub-values aren't parsed correctly,
            # Breaking the query building check. This parses each value
            if value
               value = _.mapObject value, (val) ->
                  if _.isObject(val) then _.mapObject(val, _.jsonParse)
                  else _.jsonParse(val)

            Query [
               Parameter "must", _.map value, (val, key) ->
                  if _.isBoolean(val) then type = "exists"
                  else if _.isNumber(val) then type = "equals"
                  else type = "object"
                  Property(key, val, {type: type, parent: "list_memberships", parent_type: "instance"})
            ]

      # new version of saved search alerter
      segment_results_v2:
         filter_id: "0.03"
         label: "New Results"
         component_type: "read_only"
         component: (input, state, onChange) ->
            NewResultsFilterDisplay
               className: "segment-filter-row--read_only"
               value: input?.value
         parse_fn: (criteria) ->
            must_query = criteria.getByParameter("must")
            _.eachWithObject must_query, (obj, value) ->
               obj[value.property] = value?.value
         query_fn: (value) ->
            # When this comes back from a URL, the sub-values aren't parsed correctly,
            # Breaking the query building check. This parses each value
            child_doc_type = value.document_type
            if value
               value = _.mapObject value, (val) ->
                  if _.isObject(val) then _.mapObject(val, _.jsonParse)
                  else _.jsonParse(val)
            delete value.document_type
            Query [
               ChildDoc child_doc_type, Query [
                  Parameter "must", _.map value, (val, key) ->
                     if _.isBoolean(val) then type = "exists"
                     else if _.isNumber(val) then type = "equals"
                     else type = "object"
                     Property(key, val, {type: type})
               ]
            ]

      # GROUP: Capacity
      capacity_score:
         filter_id: "1.1"
         order: 1.5
         label: "Your Capacity Score"
         component_type: "operator_component"
         facets:
            fields: [
               {
                  value: "giving.capacity_score.untouched"
               }
            ]
         help: "capacity_rating"
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "giving.capacity_score.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "giving.capacity_score.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      unrated: # is now inverse "Rated?", this key is used for converting legacy filters
         filter_id: "1.2"
         label: "Rated?"
         order: 1.4
         component_type: "boolean"
         query_fn: (value) ->
            query_value = if value and value == "true" || value is true then true else false
            Query [
               Parameter "must", [
                  Property "giving.capacity_score", query_value, {type: "exists"}
               ]
            ]

      wealthy_neighborhood:
         filter_id: "1.3"
         label: "Lives in a Wealthy Neighborhood"
         order: 1.1
         component_type: "boolean"
         help: "wealthy_neighborhood_v2"
         query_fn: (value) ->
            param_type = if value and value == "true" || value is true then "must" else "must_not"
            address_prop = _getAddressTypes()

            properties = [Property("house_value_median", {gte: oneMil}, {type: "coerce", parent: "addresses", parent_type: "instance"})]
            properties.push(address_prop) if !_.isEmpty(address_prop)

            Query [
               Parameter param_type, properties
            ]

      median_home_value:
         filter_id: "1.4"
         label: "Neighborhood Median Home Value"
         order: 1.2
         component_type: "currency_range"
         help: "median_home_value"
         query_fn: (value) ->
            address_prop = _getAddressTypes()

            properties = [Property("house_value_median", value, {type: "coerce", parent: "addresses", parent_type: "instance"})]
            properties.push(address_prop) if !_.isEmpty(address_prop)


            Query [
               Parameter "must", properties
            ]

      median_income:
         filter_id: "1.5"
         label: "Neighborhood Median Income"
         order: 1.3
         component_type: "currency_range"
         help: "median_income"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "income_median", value, {type: "coerce", parent: "addresses", parent_type: "instance"}
               ]
            ]

      # GROUP: Engagement
      engagement_score:
         filter_id: "2.1"
         label: "Your Engagement Score"
         order: 2.91
         facets:
            fields: [
               {
                  value: "giving.engagement_score.untouched"
               },
            ]
         help: "engagement_score_v2"
         component_type: "list"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "giving.engagement_score.untouched", _getListValues(value), {type: "contains"}
               ]
            ]

      facebook_engagement:
         filter_id: "2.2"
         label: "Engaged with Facebook Posts?"
         order: 2.1
         component_type: "boolean"
         help: "engagement_fb_v2"
         query_fn: (value) ->
            property = if value and value == "true" || value is true then "must" else "must_not"
            Query [
               Parameter property, [
                  Property "facebook.total_engagement_count", {gt: 0}, {type: "coerce"}
               ]
            ]

      last_fb_engagement_date:
         filter_id: "2.3"
         label: "Last Facebook Post Engagement Date"
         order: 2.7
         component_type: "date_range"
         facets:
            type: "local"
            fields: [
               {value: "last_fb_engagement_date_control", type: "control"}
               {value: "last_fb_engagement_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter value.parameter?.value || "must", [
                  Property "facebook.last_engagement_date", val, {type: "object"}
               ]
            ]

      facebook_likes:
         filter_id: "2.4"
         label: "Total Facebook Post Likes"
         order: 2.9
         component_type: "range"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "facebook.like_count", value, {type: "coerce"}
               ]
            ]

      facebook_comments:
         filter_id: "2.5"
         label: "Total Facebook Post Comments"
         order: 2.8
         component_type: "range"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "facebook.comment_count", value, {type: "coerce"}
               ]
            ]

      community_app_engagement:
         filter_id: "2.7"
         order: 2.0
         label: "Community App User"
         component_type: "boolean"
         help: "community_app_user_v2"
         gated: [{hasCommunity: true}]
         parse_fn: (criteria) ->
            !!criteria.hasParameter("must")
         query_fn: (value) ->
            property = if value in [ "true", true ] then "must" else "must_not"
            Query [
               Parameter property, [
                  Property "identities.type", 4, {type: "equals"}
               ]
            ]

      facebook_reactions:
         filter_id: "2.9"
         order: 2.6
         label: "Facebook Post Engagement: Type"
         component: (input, state, onChange) ->
            FacebookReactionsDropdown
               value: input.value
               onChange: (val) ->
                  onChange(null, val)
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            Query [
               ChildDoc "social", Query [
                  Parameter "must", [
                     Property "engagement.type", _getListValues(value), {type: "contains"},
                     Property "network", "facebook", {type: "equals"}
                  ]
               ]
            ]

      facebook_pages:
         filter_id: "2.80"
         order: 2.5
         label: "Facebook Post Engagement: Page"
         component: (input, state, onChange) ->
            div className: "segment-filter-row--select-width",
               FacebookPagesDropdown
                  value: input.value
                  onChange: (val) ->
                     onChange(null, val)
         help: "facebook_page"
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            Query [
               ChildDoc "social", Query [
                  Parameter "must", [
                     Property "content.page_remote_id", _getListValues(value), {type: "contains"}
                  ]
               ]
            ]

      facebook_content:
         filter_id: "2.81"
         order: 2.3
         label: "Facebook Post Content"
         component_type: "text_input"
         help: "facebook_content"
         parse_fn: (criteria) ->
            FilterParser.parseKeywords(criteria.getByProperty("content.contents"))
         query_fn: (value) ->
            word_array = _.filter value?.value?.split(" "), (word) -> !!word
            properties = _formatAndProperties("content.contents", word_array)

            Query [
               ChildDoc "social", Query [
                  Parameter "must",
                     _.push properties, (Property "network", "facebook", {type: "equals"})
               ]
            ]

      facebook_engagement_date:
         filter_id: "2.82"
         order: 2.4
         label: "Facebook Post Engagement: Date"
         component_type: "date_range"
         facets:
            type: "local"
            fields: [
               {value: "facebook_engagement_date_control", type: "control"}
               {value: "facebook_engagement_date_time", type: "time"}
            ]
         help: "facebook_date"
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            param = value.parameter?.value

            network_property = Property "network", "facebook", {type: "equals"}
            date_property = Property "engagement.created_at", val, {type: "object"}

            params_list =
               switch param
                  when "must"
                     [
                        Parameter "must", [network_property, date_property]
                     ]
                  when "must_not"
                     [
                        Parameter("must", [network_property]),
                        Parameter("must_not", [date_property])
                     ]

            Query [ChildDoc "social", Query(params_list)]

      event_engagement:
         filter_id: "2.90"
         order: 2.2
         label: "Event Engagement"
         gated: [{hasFeature: "events"}]
         help: "event_engagement_filters"
         component: (input, state, onChange) ->
            EventEngagementParamSelector
               input: input
               state: state
               onChange: onChange
         sub_component: (input, state, onChange) ->
            param = input?.value?.param
            if param? and param in ["must", "must_not"]
               EventEngagementFiltersBlock
                  input: input
                  state: state
                  onChange: onChange
         parse_fn: (criteria) ->
            EventFiltersUtils.parseCriteria(criteria)
         query_fn: (value) ->
            features =
               hasFacebook: FeatureStore.hasFeature("events_facebook")
               hasEventbrite: FeatureStore.hasFeature("events_eventbrite")
            EventFiltersUtils.getQuery(value, features)

      # GROUP: Giving History
      giving_history:
         filter_id: "3.1"
         order: 3.2
         label: "Donor?"
         component_type: "boolean"
         query_fn: (value) ->
            property = if value and value == "true" || value is true then "must" else "must_not"
            Query [
               Parameter property, [
                  Property "giving.lifetime_amount", {gt: 0}, {type: "coerce"}
               ]
            ]

      lifetime_giving:
         filter_id: "3.3"
         order: 3.9
         label: "Lifetime Giving"
         component_type: "currency_operator"
         query_fn: (value) ->
            result = _getQueryObjectForCurrencyOperator(value, "giving.lifetime_amount")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object"}
               ]
            ]

      pledge_balance:
         filter_id: "3.31"
         order: 3.91
         component_type: "currency_range"
         gated: [{hasFeature: "imported_pledge_balance"}]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "giving.total_pledge_balance", value, {type: "coerce"}
               ]
            ]

      largest_gift:
         filter_id: "3.6"
         order: 3.6
         label: "Largest Gift"
         component_type: "currency_operator"
         query_fn: (value) ->
            result = _getQueryObjectForCurrencyOperator(value, "giving.largest_gift_amount")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object"}
               ]
            ]

      last_gift:
         filter_id: "3.4"
         order: 3.7
         label: "Last Gift"
         component_type: "currency_operator"
         query_fn: (value) ->
            result = _getQueryObjectForCurrencyOperator(value,"giving.last_gift_amount")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object"}
               ]
            ]

      last_gift_date:
         filter_id: "3.5"
         order: 3.8
         label: "Last Gift Date"
         component_type: "date_operator"
         query_fn: (value) ->
            val = _getQueryObjectForDateOperator(value)
            Query [
               Parameter value.parameter.value.split(":")[0], [
                  Property "giving.last_gift_date", val, {type: "object"}
               ]
            ]

      giving_pattern:
         filter_id: "3.7"
         order: 3.5
         label: "Giving Pattern"
         component: (input, state, onChange) ->
            GivingPatternFilter
               value: input.value
               onChange:  (val) -> onChange(null, val)
         help: "giving_pattern"
         meta_search: "lybunt sybunt"
         parse_fn: (criteria) ->
            param = if criteria.hasParameter("must") then "must" else "must_not"
            key = criteria.getOneByParameter("must")?.property
            {parameter: {value: param}, value: {value: "#{key}:" + criteria.getValueByOperator("match")}}
         query_fn: (value) ->
            valuesArray = _.pluck(value, "value")
            properties =
               if _.contains(valuesArray, null) || _.contains(valuesArray, "")
                  propertiesList = [Property("giving.giving_pattern", false, {type: "exists"})]
                  if _.compact(valuesArray).length > 0
                     propertiesList.push(Property("giving.giving_pattern", _.compact(valuesArray), {type: "contains"}))
                  propertiesList
               else
                  [Property "giving.giving_pattern", valuesArray, {type: "contains"}]
            Query [Parameter "should", properties]


      has_recurring_payment:
         filter_id: "3.71"
         order: 3.92
         label: "Recurring Payment?"
         component_type: "boolean"
         gated:[{hasFeature: "recurring_payment_field"}]
         query_fn: (value) ->
            property = if value and value == "true" || value is true then "must" else "must_not"
            Query [
               Parameter property, [
                  Property "giving.has_recurring_payments", true, {type: "equals"}
               ]
            ]

      total_pledge_balance:
         filter_id: "3.69"
         order: 3.91
         label: "Pledge Balance"
         component_type: "currency_operator"
         gated:[{hasFeature: "imported_pledge_balance"}]
         query_fn: (value) ->
            result = _getQueryObjectForCurrencyOperator(value, "giving.total_pledge_balance")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object"}
               ]
            ]

      dps_score:
         filter_id: "5.1b"
         label: "Donor Potential Score"
         order: 3.3
         component_type: "dps_range"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "et_score.dds_score", value, {type: "coerce"}
               ]
            ]

      gift_transaction:
         _.extend {}, GiftTransactionConfig,
            filter_id: "3.90",
            order: 3.4
            label: "Gift Transaction"
            gated: [{hasFeature: "hybrid_giving"}]
            

      categorized_giving:
         _.extend {}, CategorizedGivingConfig,
            filter_id: "3.91",
            order: 3.1
            label: "Categorized Giving"
            gated: [{hasFeature: "categorized_giving"}]

      # GROUP: Career
      industry:
         filter_id: "4.1"
         order: 4.3
         label: "Industry"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "employments.industry.untouched"
               },
            ]
         help: "industry_v2"
         parse_fn: FilterParser.parseGroupedList
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "employments.industry.untouched": values.customer

            Query [
               Parameter "must", [properties]
            ]

      company:
         filter_id: "4.2"
         order: 4.1
         label: "Company"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "employments.company.untouched"
               },
            ]
         help: "company_v2"
         parse_fn: FilterParser.parseGroupedList
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "employments.company.untouched": values.customer

            Query [
               Parameter "must", [properties]
            ]

      title:
         filter_id: "4.4"
         order: 4.4
         label: "Title"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "employments.title.untouched"
               }
            ]
         parse_fn: FilterParser.parseGroupedList
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "employments.title.untouched": values.customer
            Query [
               Parameter "must", [properties]
            ]

      function:
         filter_id: "4.5"
         order: 4.2
         label: "Function"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "employments.function.untouched"
               }
            ]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "employments.function.untouched", _getListValues(value), {type: "contains"}
               ]
            ]

      # GROUP: Donor Status
      score_et:
         filter_id: "5.1a"
         order: 5.5
         label: "EverTrue Score"
         component_type: "slider_range"
         help: "evertrue_score_v2"
         gated: [{hasFeature: "scores"}]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "score.score", value, {type: "coerce"}
               ]
            ]

      donor_score:
         filter_id: "5.2"
         order: 5.4
         label: "Donor Score"
         component_type: "operator_component"
         facets:
            fields: [
               {
                  value: "giving.donor_score.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "giving.donor_score.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "giving.donor_score.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      assignee:
         filter_id: "5.3"
         order: 5.2
         label: "Assigned To"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "assignees.name.untouched"
               }
            ]
         gated: [{doesntHaveFeature: "rm_assignments"}]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "name.untouched", _getListValues(value), {type: "contains", parent: "assignees", parent_type: "instance"}
               ]
            ]

      rm_assignee:
         filter_id: "12.6"
         order: 5.21
         label: "Assigned To"
         component: (input, state, onChange) ->
            div style: {width: "275px"},
               SolicitorDropdown
                  label: "Assigned to"
                  value: input.value
                  onChange: (val) -> onChange(null, val)
                  multiple: true
                  searchable: true
                  allowChangeAll: true
                  allowUnselectAll: true
         gated: [{hasFeature: "rm_assignments"}]
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            Query [
               ChildDoc "assignment",
                  Query [
                     Parameter "must", [
                        Property("pool_type", "TEAM", {type: "equals"})
                        Property("solicitor_contact_id", _getListValues(value), {type: "contains"}),
                        Property("parent_role", "prospect", {type: "equals"})
                     ]
                  ]
            ]


      unassigned: # is now inverse Assigned?, this key is used for converting legacy filters
         filter_id: "5.4"
         order: 5.1
         label: "Assigned?"
         component_type: "boolean"
         gated: [{doesntHaveFeature: "rm_assignments"}]
         query_fn: (value) ->
            param_type = if value and value == "true" || value is true then "must" else "must_not"
            Query [
               Parameter param_type, [
                  Property "name", true, {type: "exists", parent: "assignees", parent_type: "instance"}
               ]
            ]

      rm_assigned:
         filter_id: "12.5"
         order: 5.11
         label: "Assigned?"
         component_type: "boolean"
         gated: [{hasFeature: "rm_assignments"}]
         query_fn: (value) ->
            coerce_value = if value and value == "true" || value is true then true else false
            if coerce_value
               Query [
                  ChildDoc "assignment",
                     Query [
                        Parameter "must", [
                           Property("pool_type", "TEAM", {type: "equals"})
                           Property("parent_role", "prospect", {type: "equals"})
                           Property("solicitor_contact_id", coerce_value,  {type: "exists"})
                        ]
                     ]
               ]
            else
               Query [
                  Parameter "must_not", [
                     ChildDoc "assignment",
                        Query [
                           Parameter "must", [
                              Property("pool_type", "TEAM", {type: "equals"})
                              Property("parent_role", "prospect", {type: "equals"})
                              Property("solicitor_contact_id", true,  {type: "exists"})
                           ]
                        ]
                  ]
               ]

      assignee_status: # Labeled as "Prospect Status"
         filter_id: "5.6"
         order: 5.7
         label: "Prospect Status"
         component: (input, state, handleChange) ->
            div null,
               AdvancedCombobox
                  className: "segment-filter-row--col segment-filter-row--operator-value"
                  options:  [
                     {label: "is", value: "must"}
                     {label: "is not", value: "must_not"}
                  ]
                  value: input.value?.parameter || {value: "must"}
                  onChange: (val) ->
                     handleChange(null, _.extend({}, input.value, {parameter: val}))
               ProspectStatusDropdown
                  className: "segment-filter-row--min-width segment-filter-row--sub-value"
                  value: input.value?.value
                  multiple: true
                  includeNoStatus: false
                  onChange: (val) ->
                     handleChange(null, _.extend({}, input.value, {value: val}))
         gated: [{hasFeature: "multiple_assignee"}, {doesntHaveFeature: "rm_assignments"}]
         parse_fn: FilterParser.parseListWithNot
         query_fn: (val) ->
            value = if value?.parameter then val?.value?.value else val?.value
            Query [
               Parameter val?.parameter?.value || "must", [
                  Property "status.untouched", _getListValues(value), {type: "contains", parent: "assignees", parent_type: "instance"}
               ]
            ]

      prospect_status:
         filter_id: "5.9"
         order: 5.71
         label: "Prospect Status"
         component_type: "multi_select_with_not"
         facets:
            fields: [
               {
                  value: "prospect_statuses.status.untouched"
               }
            ]
         gated: [{doesntHaveFeature: "multiple_assignee"}, {doesntHaveFeature: "rm_assignments"}]
         query_fn: (value) ->
            param = value?.parameter?.value
            Query [
               Parameter (param || "must"), [
                  Property "prospect_statuses.status.untouched", _getListValues(value?.value), {type: "contains"}
               ]
            ]

      rm_assignee_status: # Labeled as "Prospect Status"
         filter_id: "12.7"
         order: 5.72
         label: "Prospect Status"
         component: (input, state, handleChange) ->
            div style: width: "100%",
               AdvancedCombobox
                  className: "segment-filter-row--col segment-filter-row--operator-value"
                  options:  [
                     {label: "is", value: "must"}
                     {label: "is not", value: "must_not"}
                  ]
                  value: input.value?.parameter || {value: "must"}
                  onChange: (val) ->
                     handleChange(null, _.extend({}, input.value, {parameter: val}))
               RmStatusDropdown
                  className: "segment-filter-row--min-width segment-filter-row--sub-value"
                  value: input.value?.value
                  multiple: true
                  includeNoStatus: false
                  onChange: (val) ->
                     handleChange(null, _.extend({}, input.value, {value: val}))
         gated: [{hasFeature: "rm_assignments"}]
         parse_fn: (criteria) ->
            values = criteria.getValueByOperator("in")
            {value: [{item: values[0], value: values[0]}]}
         query_fn: (val) ->
            value = if value?.parameter then val?.value?.value else val?.value
            Query [
               ChildDoc "assignment",
                  Query [
                     Parameter val?.parameter?.value || "must", [
                        Property("pool_type", "TEAM", {type: "equals"})
                        Property("parent_role", "prospect", {type: "equals"})
                        Property("assignment_stage.untouched", _getListValues(value),  {type: "contains"})
                     ]
                  ]
            ]

      solicitation_code:
         filter_id: "5.5"
         order: 5.8
         label: "Solicitation Code"
         component_type: "multi_select_with_not"
         facets:
            fields: [
               {
                  value: "solicitation_codes.code.untouched"
               }
            ]
         query_fn: (value) ->
            param = value?.parameter?.value
            Query [
               Parameter (param || "must"), [
                  Property "solicitation_codes.code.untouched", _getListValues(value?.value), {type: "contains"}
               ]
            ]

      deceased:
         filter_id: "1.6"
         order: 5.3
         label: "Deceased?"
         help: "deceased"
         component_default: false
         component_type: "boolean"
         parse_fn: (criteria) ->
            !!criteria.getValueByOperator("match")
         query_fn: (value) ->
            query_value = if value and value == "true" || value is true then true else false
            Query [
               Parameter "must", [
                  Property "deceased", query_value, {type: "equals"}
               ]
            ]

      has_primary_email:
         filter_id: "5.8"
         order: 5.6
         label: "Has Primary Email Address?"
         component_type: "boolean"
         help: "has_primary_email"
         query_fn: (value) ->
            param = if value and value == "true" || value is true then "must" else "must_not"
            Query [
               Parameter param, [
                  Property "emails.primary", true, {type: "equals"}
               ]
            ]

      # GROUP: Education
      year:
         filter_id: "6.1"
         order: 6.6
         label: "Graduation Year"
         component_type: "year_operator" 
         query_fn: (value) ->
            result = _getQueryObjectForYearOperator(value, "year")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object"}
               ]
            ]

      reunion_year:
         filter_id: "6.2"
         order: 6.7
         label: "Reunion Year Group"
         component_type: "list"
         facets:
            type: "local"
            fields: [{value: "reunion_year"}]
         help: "reunion_year_group_v2"
         parse_fn: (criteria) ->
            year_ends = _.uniq _.map criteria.getValue("year", "in"), (year) ->
               _.toNumber(_.last(year.toString()))
            half_length = Math.ceil(year_ends.length / 2)
            _.compact _.map year_ends.sort(), (end, index) ->
               if index < half_length
                  value: "#{end}and#{year_ends[index + half_length]}"
                  label: "#{end}'s and #{year_ends[index + half_length]}'s"
         query_fn: (values) ->
            current_year = moment(new Date()).year()
            first_year = current_year - 150
            groups = _.flatten _.map _getListValues(values), (value) -> value?.split?("and") || []
            reunions = _.compact _.map [first_year..current_year], (year) ->
               if (_.last(year.toString()) in groups) then parseInt(year)

            Query [
               Parameter "must", [
                  Property "year", reunions, {type: "contains"}
               ]
            ]

      education:
         filter_id: "6.3"
         order: 6.8
         label: "School Name"
         help: "education_school"
         facets:
            fields: [
               {
                  value: "educations.school_name.untouched"
               }
            ]
         component_type: "operator_component"
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "educations.school_name.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "educations.school_name.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      degree:
         filter_id: "6.4"
         order: 6.3
         label: "Degree"
         component_type: "operator_component"
         help: "education_degree"
         facets:
            fields: [
               {
                  value: "educations.degree_key.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "educations.degree_key.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "educations.degree_key.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      major:
         filter_id: "6.5"
         order: 6.6
         label: "Major"
         component_type: "operator_component"
         help: "education_major"
         facets:
            fields: [
               {
                  value: "educations.majors.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                        Property("educations.majors.untouched", query_value, {type: "exists"})
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "educations.majors.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      education_year:
         filter_id: "6.51"
         order: 6.5
         label: "Education Year"
         help: "education_year"
         component_type: "year_operator"
         query_fn: (value) ->
            result = _getQueryObjectForYearOperator(value, "year")
            Query [
               Parameter result.parameter, [
                  Property result.property, result.val, {type: "object", parent: "educations", parent_type: "instance"}
               ]
            ]

      athletics:
         filter_id: "6.6"
         order: 6.2
         label: "Athletics"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "sports.name.untouched"
               }
            ]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "sports.name.untouched", _getListValues(value), {type: "contains"}
               ]
            ]

      activities:
         filter_id: "6.7"
         order: 6.1
         label: "Activities"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "extracurricular_activities.name.untouched"
               },
            ]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "extracurricular_activities.name.untouched", _getListValues(value), {type: "contains"}
               ]
            ]


      # GROUP: Location
      address_city:
         filter_id: "7.2"
         order: 7.3
         label: "City"
         component_type: "operator_component_with_checkbox"
         facets:
            fields: [
               {
                  value: "addresses.city.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "addresses.city.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               if values.primary
                  Query [
                     Parameter values.condition, [
                        Property "city.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                        Property "primary", true, {type: "equals", parent: "addresses", parent_type: "instance"}
                     ]
                  ]
               else
                  Query [
                     Parameter values.condition, [
                        Property "city.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                     ]
                  ]

      address_state:
         filter_id: "7.3"
         order: 7.6
         label: "State"
         component_type: "operator_component_with_checkbox"
         facets:
            fields: [
               {
                  value: "addresses.state.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "addresses.state.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               if values.primary
                  Query [
                     Parameter values.condition, [
                        Property "state.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                        Property "primary", true, {type: "equals", parent: "addresses", parent_type: "instance"}
                     ]
                  ]
               else
                  Query [
                     Parameter values.condition, [
                        Property "state.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                     ]
                  ]


      address_country:
         filter_id: "7.6"
         order: 7.4
         label: "Country"
         component_type: "operator_component_with_checkbox"
         facets:
            fields: [
               {
                  value: "addresses.country.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "addresses.country.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               if values.primary
                  Query [
                     Parameter values.condition, [
                        Property "country.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                        Property "primary", true, {type: "equals", parent: "addresses", parent_type: "instance"}
                     ]
                  ]
               else
                  Query [
                     Parameter values.condition, [
                        Property "country.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                     ]
                  ]


      address_type:
         filter_id: "7.4"
         order: 7.1
         label: "Address Type"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "addresses.type"
               }
            ]
         help: "address_type"
         query_fn: (value) ->
            values = _getListValuesV2(value)
            if values.primary
               Query [
                  Parameter "must", [
                        Property "type", values.types, {type: "contains", parent: "addresses", parent_type: "instance"}
                        Property "primary", true, {type: "equals", parent: "addresses", parent_type: "instance"}
                  ]
               ]
            else
               Query [
                  Parameter "must", [
                     Property "type", values.types, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

      zipcode:
         filter_id: "7.5"
         order: 7.6
         label: "Zip Code"
         component_type: "list_with_checkbox"
         facets:
            fields: [
               {
                  value: "addresses.zip_code.untouched"
               }
            ]
         query_fn: (value) ->
            values = _getListValuesPrimary(value)
            if values.primary
               Query [
                  Parameter "must", [
                        Property "zip_code.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                        Property "primary", true, {type: "equals", parent: "addresses", parent_type: "instance"}
                  ]
               ]
            else
               Query [
                  Parameter "must", [
                     Property "zip_code.untouched", values.customer, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

      # GROUP: Donor Info
      gender:
         filter_id: "8.0"
         order: 8.3
         label: "Gender"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "gender.untouched"
               }
            ]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "gender", _getListValues(value), {type: "contains"}
               ]
            ]

      ethnicity:
         filter_id: "8.1"
         order: 8.2
         label: "Ethnicity"
         component_type: "list"
         facets:
            fields: [
               {
                  value: "ethnicity.untouched"
               }
            ]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "ethnicity.untouched", _getListValues(value), {type: "contains"}
               ]
            ]

      constituency:
         filter_id: "8.2"
         order: 8.1
         label: "Constituency"
         component_type: "constituency_operator_component"
         facets:
            fields: [
               {
                  value: "constituencies.status.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               param_type = if value.parameter.value == "is_set" then "must" else "must_not"
               Query [
                  Parameter param_type, [
                     Property "constituencies.status.untouched", true, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties "constituencies.status.untouched": values.customer
               Query [
                  Parameter values.condition, [properties]
               ]

      interests:
         filter_id: "8.4"
         order: 8.4
         label: "Interests"
         component_type: "list"
         gated: [{hasFeature: "enrichment_data"}]
         facets:
            fields: [
               {value: "enrichment.contact_interests.interest_name.untouched"}
            ]
         parse_fn: (criteria) ->
            _.map FilterParser.parseList(criteria), (item) ->
               _.extend {}, item, {value: FacetUtils.wrapEnrichedValue(item.value)}
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            Query [
               Parameter "must", [
                  Property("interest_name.untouched", values.enrichment, {
                     type: "contains",
                     parent: "enrichment.contact_interests",
                     parent_type: "instance"
                  })
               ]
            ]

      #GROUP: Interactions
      interaction_type:
         filter_id: "9.1"
         order: 9.6
         label: "Interaction Type"
         component_type: "list"
         help: "interaction_type"
         facets:
            type: "contact_note"
            fields: [
               {
                  value: "interaction_type.untouched"
               }
            ]
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         query_fn: (value) ->
            categories = _.pluck(value, "value") || []
            Query [
               ChildDoc "contact_note", Query [
                  Parameter "must", InteractionUtils.getPropertiesForCategoryQuery(categories)
               ]
            ]

      interaction_keyword:
         filter_id: "9.2"
         order: 9.2
         label: "Interaction Content"
         component_type: "text_input"
         help: "interaction_content"
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         parse_fn: (criteria) ->
            data = _.filter criteria.getByParameter("must"), (param) -> param.property == "text"
            FilterParser.parseKeywords(data)
         query_fn: (value) ->
            word_array = _.filter value?.value?.split(" "), (word) -> !!word
            Query [
               ChildDoc "contact_note", Query [
                  Parameter "must", _.map(word_array, (val) ->
                     Property(["text", "summary"], val, type: "wildcard")
                  )
                  Parameter "highlight", [
                     Property "fields", {text: {pre_tags: [""], post_tags: [""]}}, {type: "object"}
                  ]
               ]
            ]

      interaction_date_range:
         filter_id: "9.3"
         order: 9.3
         label: "Interaction Date"
         component_type: "date_operator_v2"
         help: "interaction_date"
         facets:
            type: "contact_note"
            fields: [
               {
                  value: "date_occurred"
               }
            ]
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         query_fn: (value) ->
            val = _getQueryObjectForDateOperator(value)
            Query [
               ChildDoc "contact_note", Query [
                  Parameter value.parameter.value.split(":")[0], [
                     Property "date_occurred", val, {type: "object"},
                  ]
               ]
            ]

      interaction_author:
         filter_id: "9.4"
         order: 9.1
         label: "Interaction Author"
         component_type: "list"
         facets:
            type: "contact_note"
            fields: [
               {
                  value: "author.name.untouched"
               }
            ]
         help: "interaction_author"
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         query_fn: (value) ->
            list_values = _getListValues(value)
            Query [
               ChildDoc "contact_note", Query [
                  Parameter "must", [
                     Property "author.name.untouched", list_values, {type: "contains"}
                  ]
               ]
            ]

      interaction_solicitor:
         filter_id: "9.45"
         order: 9.5
         label: "Interaction Solicitor"
         component: (input, state, onChange) ->
            div className: "segment-filter-row--select-width",
               InteractionSolicitorFilter
                  value: input.value
                  facets: state.facets[_.first(_.keys(state.facets))]
                  onChange: (val) -> onChange(null, val)
         facets:
            type: "contact_note"
            fields: [
               {
                  value: "solicitor.name.untouched"
               }
            ]
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            list_values = _getListValues(value)
            Query [
               ChildDoc "contact_note", Query [
                  Parameter "must", [
                     Property "solicitor.name.untouched", list_values, {type: "contains"}
                  ]
               ]
            ]

      # These labels are the same as old interaction custom fields
      # but also contain Volunteer Contact ID and Volunteer Pool ID
      interaction_label:
         filter_id: "9.5"
         order: 9.4
         label: "Interaction Label"
         component: (input, state, onChange) ->
            InteractionLabelFilter
               value: input.value
               onChange: (val) -> onChange(null, val)
         gated: [{hasFeature: "ugc_show_imported_notes"}]
         parse_fn: (criteria) ->
            name_value = criteria.getValue("label.name.untouched", "match")
            label: {value: name_value, label: name_value}
            values: _.map criteria.getValue("label.value.untouched", "in"), (val) ->
               {label: val, value: val}
         query_fn: (value) ->
            name = value?.label?.value
            list_values = _getListValues(value?.values)
            Query [
               ChildDoc "contact_note", Query [
                  Parameter "must", [
                     Property "name.untouched", name, {type: "equals", parent: "label", parent_type: "instance"}
                     Property "value.untouched", list_values, {type: "contains", parent: "label", parent_type: "instance"}
                  ]
               ]
            ]



      # Proposals
      proposal_ask_amount:
         filter_id: "11.1"
         order: 11.2
         label: "Proposal Ask Amount"
         help: "proposal_filters"
         component_type: "currency_range"
         gated: [{hasFeature: "proposal_reads"}]
         query_fn: (value) ->
            Query [
               ChildDoc "proposal",
                  Query [
                     Parameter "must", [
                        Property "ask_amount", value, {type: "coerce"}
                     ]
                  ]
            ]

      proposal_ask_date:
         filter_id: "11.2"
         order: 11.3
         label: "Proposal Ask Date"
         help: "proposal_filters"
         component_type: "date_range"
         facets:
            type: "local"
            fields: [
               {value: "proposal_ask_date_control", type: "control"}
               {value: "proposal_ask_date_time", type: "time"}
            ]
         gated: [{hasFeature: "proposal_reads"}]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            unless value.value == "range" && value.range
               val = {lte: val.gte, gte: "now"}
            Query [
               ChildDoc "proposal",
                  Query [
                     Parameter "must", [
                        Property "ask_date", val, {type: "object"}
                     ]
                  ]
            ]

      proposal_status:
         filter_id: "11.3"
         order: 11.5
         label: "Proposal Status"
         help: "proposal_filters"
         component_type: "list"
         facets:
            type: "proposal"
            fields: [{value: "status.untouched"}]
         gated: [{hasFeature: "proposal_reads"}]
         query_fn: (value) ->
            Query [
               ChildDoc "proposal",
                  Query [
                     Parameter "must", [
                        Property "status.untouched", _getListValues(value), {type: "contains"}
                     ]
                  ]
            ]

      proposal_solicitors:
         filter_id: "11.4"
         order: 11.4
         label: "Proposal Solicitors"
         help: "proposal_filters"
         component: (input, state, onChange) ->
            ProposalSolicitorFilter
               value: input.value
               facets: state.facets[_.first(_.keys(state.facets))]
               onChange: (val) -> onChange(null, val)
         parse_fn: FilterParser.parseList
         facets:
            type: "proposal"
            fields: [
               {
                  value: "solicitors.name.untouched"
               }
            ]
         gated: [{hasFeature: "proposal_reads"}]
         query_fn: (value) ->
            Query [
               ChildDoc "proposal",
                  Query [
                     Parameter "must", [
                        Property "solicitors.name.untouched", _getListValues(value), {type: "contains"}
                     ]
                  ]
            ]

      proposal_active:
         filter_id: "11.5"
         order: 11.1
         label: "Has Active Proposal?"
         component_type: "boolean"
         help: "proposal_filters"
         gated: [{hasFeature: "proposal_reads"}]
         parse_fn: (criteria) ->
            !!criteria.getValueByOperator("match")
         query_fn: (value) ->
            query_value = if value and value == "true" || value is true then true else false
            Query [
               ChildDoc "proposal",
                  Query [
                     Parameter "must", [
                        Property "active", query_value, {type: "equals"}
                     ]
                  ]
            ]

      volunteer:
         filter_id: "12.1"
         order: 12.2
         label: "Is a volunteer?"
         component_type: "boolean"
         gated: [{hasFeature: "volunteers"}]
         query_fn: (value) ->
            coerce_value = if value and value == "true" || value is true then true else false
            if coerce_value
               Query [
                  ChildDoc "assignment",
                     Query [
                        Parameter "must", _.compact([
                           Property("pool_type", "POOL", {type: "equals"})
                           Property("parent_role", "solicitor", {type: "equals"})
                        ])
                     ]
               ]
            else
               Query [
                  Parameter "must_not", [
                     ChildDoc "assignment",
                        Query [
                           Parameter "must", [
                              Property("pool_type", "POOL", {type: "equals"})
                              Property("parent_role", "solicitor", {type: "equals"})
                           ]
                        ]
                  ]
               ]


      volunteer_pool:
         filter_id: "12.2"
         order: 12.3
         label: "Pool"
         component: (input, state, onChange) ->
            VolunteerPoolDropdown
               value: input.value
               allowAll: false
               onChange: (val) ->
                  onChange(null, val)
         gated: [{hasFeature: "volunteers"}]
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            Query [
               ChildDoc "assignment",
                  Query [
                     Parameter "must", [
                        Property "pool_id", _getListValues(value), {type: "contains"}
                     ]
                  ]
            ]

      volunteer_assignee:
         filter_id: "12.3"
         order: 12.1
         label: "Assigned to Volunteer"
         component: (input, state, onChange) ->
            VolunteerDropdown
               value: input.value
               onChange: (val) -> onChange(null, val)
         gated: [{hasFeature: "volunteers"}]
         parse_fn: FilterParser.parseList
         query_fn: (value) ->
            Query [
               ChildDoc "assignment",
                  Query [
                     Parameter "must", [
                        Property("solicitor_contact_id", _getListValues(value), {type: "contains"}),
                        Property("parent_role", "prospect", {type: "equals"})
                     ]
                  ]
            ]

      volunteer_prospect_assigned:
         filter_id: "12.4"
         order: 12.4
         label: "Prospect is Assigned?"
         component_type: "boolean"
         gated: [{hasFeature: "volunteers"}]
         query_fn: (value) ->
            coerce_value = if value and value == "true" || value is true then true else false
            if coerce_value
               Query [
                  ChildDoc "assignment",
                     Query [
                        Parameter "must", [
                           Property("pool_type", "POOL", {type: "equals"})
                           Property("parent_role", "prospect", {type: "equals"})
                           Property("solicitor_contact_id", coerce_value,  {type: "exists"})
                        ]
                     ]
               ]
            else
               Query [
                  Parameter "must_not", [
                     ChildDoc "assignment",
                        Query [
                           Parameter "must", [
                              Property("pool_type", "POOL", {type: "equals"})
                              Property("parent_role", "prospect", {type: "equals"})
                              Property("solicitor_contact_id", true,  {type: "exists"})
                           ]
                        ]
                  ]
               ]

      volunteer_stage:
         filter_id: "12.9"
         order: 12.6
         label: "Volunteer Stage"
         component_type: "operator_component"
         gated: [{hasFeature: "volunteers"}]
         facets:
            type: "assignment"
            fields: [
               {
                  value: "assignment_stage.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  ChildDoc "assignment",
                     Query [
                        Parameter "must", [
                           Property("pool_type", "POOL", {type: "equals"})
                           Property("parent_role", "prospect", {type: "equals"})
                           Property("assignment_stage.untouched", query_value,  {type: "exists"})
                        ]
                     ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "assignment_stage.untouched": values.customer
               Query [
                  ChildDoc "assignment",
                     Query [
                        Parameter "must", [
                           Property("pool_type", "POOL", {type: "equals"})
                           Property("parent_role", "prospect", {type: "equals"})
                        ]
                        Parameter values.condition, [properties]
                     ]
               ]

      email:
         filter_id: "11.6"
         order: 11.6
         component_type: "read_only"
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "emails.email", value, {type: "wildcard"}
               ]
            ]

      # Graduway
      graduway_registered_user:
         filter_id: "13.1"
         order: 13.2
         label: "Registered User"
         component_type: "boolean"
         gated: [{hasFeature: "graduway"}]
         query_fn: (value) ->
            query_value = if value and value == "true" || value is true then true else false
            Query [
               Parameter "must", [
                  Property "graduway.is_graduway_user", query_value, {type: "exists"}
               ]
            ]

      graduway_last_engagement_date:
         filter_id: "13.2"
         label: "Last Engagement Date"
         order: 13.1
         component_type: "date_range"
         gated: [{hasFeature: "graduway"}]
         facets:
            type: "local"
            fields: [
               {value: "graduway_engagement_date_control", type: "control"}
               {value: "graduway_engagement_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter value.parameter?.value || "must", [
                  Property "graduway.last_graduway_session_at", val, {type: "object"}
               ]
            ]

      # GROUP: General Enrichment Data
      metro:
         filter_id: "7.1"
         order: 14.3
         label: "Constituent Metro Area"
         component_type: "operator_component"
         gated: ["enrichment_data"]
         facets:
            fields: [
               {
                  value: "enrichment.contact_locations.metro_area.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "enrichment.contact_locations.metro_area.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.contact_locations.metro_area.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
                  Parameter "must", [Property "enrichment.contact_locations", true, {type: "exists"}]
               ]

      enrichment_address_city:
         filter_id: "16.2"
         order: 14.1
         label: "Constituent City"
         component_type: "operator_component"
         gated: ["enrichment_data"]
         facets:
            fields: [
               {
                  value: "enrichment.contact_locations.city.untouched"
               }
            ]
         help: "city"
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               if !query_value
                  Query [
                     Parameter "must", [
                        Property("enrichment", query_value, {type: "exists"}).orProperties([
                           Property("enrichment.contact_locations.city.untouched", query_value, {type: "exists"})
                       ])
                     ]
                  ]
               else
                  Query [
                     Parameter "must", [
                        Property "enrichment.contact_locations.city.untouched", query_value, {type: "exists"}
                     ]
                  ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.contact_locations.city.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]

      enrichment_address_state:
         filter_id: "16.3"
         order: 14.4
         label: "Constituent State"
         component_type: "operator_component"
         gated: ["enrichment_data"]
         facets:
            fields: [
               {
                  value: "enrichment.contact_locations.state_code.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               if !query_value
                  Query [
                     Parameter "must", [
                        Property("enrichment", query_value, {type: "exists"}).orProperties([
                           Property("enrichment.contact_locations.state_code.untouched", query_value, {type: "exists"})
                        ])
                     ]
                  ]
               else
                  Query [
                     Parameter "must", [
                        Property "enrichment.contact_locations.state_code.untouched", query_value, {type: "exists"}
                     ]
                  ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.contact_locations.state_code.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]

      enrichment_address_country:
         filter_id: "16.4"
         order: 14.2
         label: "Constituent Country"
         component_type: "operator_component"
         gated: ["enrichment_data"]
         facets:
            fields: [
               {
                  value: "enrichment.contact_locations.country.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               if !query_value
                  Query [
                     Parameter "must", [
                        Property("enrichment", query_value, {type: "exists"}).orProperties([
                           Property("enrichment.contact_locations.country.untouched", query_value, {type: "exists"})
                        ])
                     ]
                  ]
               else
                  Query [
                     Parameter "must", [
                        Property "enrichment.contact_locations.country.untouched", query_value, {type: "exists"}
                     ]
                  ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.contact_locations.country.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]

      # GROUP: Wealth Enrichment
      net_worth_wealth_plus:
         filter_id: "14.1"
         order: 15.1
         label: "Net Worth"
         component_type: "currency_range"
         gated: [{hasFeature: "windfall"}, {hasFeature: "windfall_wealth_plus"}]
         query_fn: (value) ->
            Query [
               Parameter "must", [
                  Property "enrichment.wealth_attributes.net_worth", value, {type: "coerce"}
               ]
            ]

      net_worth:
         filter_id: "14.1.1"
         order: 15.1
         label: "Net Worth"
         component_type: "currency_range"
         gated: [{hasFeature: "windfall"}, {doesntHaveFeature: "windfall_wealth_plus"}]
         query_fn: (value) ->
            if value && value.gte < oneMil && value.lte < oneMil
               value = {gte: 0, lte: 0};
            else if value && value.gte < oneMil && value.lte == oneMil
               value = {gte: oneMil, lte: oneMil};
            else if value && value.gte < oneMil && value.lte > oneMil
               value = {gte: oneMil, lte: value.lte};
            Query [
               Parameter "must", [
                  Property "enrichment.wealth_attributes.net_worth", value, {type: "coerce"}
                  Property "enrichment.wealth_attributes.source", "windfall", {type: "equals"}
               ]
            ]

      wealth_indicators:
         filter_id: "14.2"
         order: 15.4
         label: "Wealth Indicators"
         facets:
            fields: [
               {
                  value: "enrichment.wealth_indicators.type.untouched"
               },
            ]
         component_type: "list"
         gated: [{hasFeature: "windfall"}]
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "enrichment.wealth_indicators.type.untouched": values.enrichment
            Query [
               Parameter "must", [
                  properties
               ]
            ]


      physical_assets:
         filter_id: "14.3"
         order: 15.2
         label: "Physical Assets"
         facets:
            fields: [
               {
                  value: "enrichment.wealth_physical_assets.type.untouched"
               },
            ]
         component_type: "list"
         gated: [{hasFeature: "windfall"}]
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "enrichment.wealth_physical_assets.type.untouched": values.enrichment
            Query [
               Parameter "must", [
                  properties
               ]
            ]

      wealth_life_events:
         filter_id: "14.4"
         order: 15.6
         label: "Wealth Life Events"
         facets:
            fields: [
               {
                  value: "enrichment.wealth_life_events.type.untouched"
               },
            ]
         component_type: "list"
         gated: [{hasFeature: "windfall"}]
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "enrichment.wealth_life_events.type.untouched": values.enrichment
            Query [
              Parameter "must", [properties]
            ]

      wealth_life_event_date:
         filter_id: "14.5"
         order: 15.5
         label: "Wealth Life Event Date"
         component_type: "date_range"
         gated: [{hasFeature: "windfall"}]
         facets:
            type: "local"
            fields: [
               {value: "custom_date_control", type: "control"}
               {value: "custom_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter value.parameter?.value || "must", [
                  Property "enrichment.wealth_life_events.created_at", val, {type: "object"}
               ]
            ]

      wealth_attributes_updated_at:
         filter_id: "14.7"
         order: 15.3
         label: "Wealth Enrichment Last Update Date"
         component_type: "date_range"
         gated: [{hasFeature: "windfall"}]
         facets:
            type: "local"
            fields: [
               {value: "custom_date_control", type: "control"}
               {value: "custom_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter value.parameter?.value || "must", [
                  Property "enrichment.wealth_attributes.updated_at", val, {type: "object"}
               ]
            ]

      career_moves_type:
         filter_id: "15.1"
         order: 16.1
         label: "Career Move Type"
         facets:
            fields: [
               {
                  value: "enrichment.employment_events.type"
               },
            ]
         component_type: "list"
         gated: [{hasFeature: "career_updates"}]
         parse_fn: (criteria) ->
            _.map FilterParser.parseList(criteria), (item) ->
               _.extend {}, item, {value: FacetUtils.wrapEnrichedValue(item.value)}
         query_fn: (value) ->
            values = _getGroupedListValues(value)
            properties = _formatOrProperties
               "enrichment.employment_events.type": values.enrichment
            Query [
               Parameter "must", [properties
                  Property("enrichment.employment_events.confidence_score", {gte:93}).orProperties([
                     Property("enrichment.employment_events.source", "salesintel", {type: "equals"})
                  ])
                  Property("enrichment.employment_events.confidence_score", {gte:80})

               ]
            ]

      career_moves_date:
         filter_id: "15.2"
         order: 16.2
         label: "Career Moves Last File Date"
         component_type: "date_range"
         gated: [{hasFeature: "career_updates"}]
         facets:
            type: "local"
            fields: [
               {value: "custom_date_control", type: "control"}
               {value: "custom_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter "must", [
                  Property("enrichment.employment_events.confidence_score", {gte:93}).orProperties([
                     Property("enrichment.employment_events.source", "salesintel", {type: "equals"})
                  ])
                  Property("enrichment.employment_events.confidence_score", {gte:80})

               ]
               Parameter value.parameter?.value || "must", [
                  Property "enrichment.employment_events.updated_at", val, {type: "object"}
               ]
            ]

      career_moves_start_date:
         filter_id: "15.3"
         order: 16.3
         label: "Career Moves Start Date"
         component_type: "date_range"
         gated: [{hasFeature: "career_updates"}]
         facets:
            type: "local"
            fields: [
               {value: "custom_date_control", type: "control"}
               {value: "custom_date_time", type: "time"}
            ]
         query_fn: (value) ->
            val = _getQueryObjectForDate(value)
            Query [
               Parameter "must", [
                  Property("enrichment.employment_events.confidence_score", {gte:93}).orProperties([
                     Property("enrichment.employment_events.source", "salesintel", {type: "equals"})
                  ])
                  Property("enrichment.employment_events.confidence_score", {gte:80})

               ]
               Parameter value.parameter?.value || "must", [
                  Property "enrichment.employments.start_date", val, {type: "object"}
               ]
            ]

      company_name:
         filter_id: "15.4"
         order: 16.4
         label: "Company Name"
         component_type: "operator_component"
         gated: [{hasFeature: "enrichment_data"}]
         facets:
            fields: [
               {
                  value: "enrichment.employments.company_name.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "enrichment.employments.company_name.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.employments.company_name.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]

      industry_enriched:
         filter_id: "15.5"
         order: 16.5
         label: "Industry"
         component_type: "operator_component"
         gated: [{hasFeature: "enrichment_data"}]
         facets: 
            fields: [
               {
                  value: "enrichment.employments.industry.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "enrichment.employments.industry.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.employments.industry.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]

      job_title:
         filter_id: "15.6"
         order: 16.6
         label: "Job Title"
         component_type: "operator_component"
         gated: [{hasFeature: "enrichment_data"}]
         facets:
            fields: [
               {
                  value: "enrichment.employments.title.untouched"
               }
            ]
         query_fn: (value) ->
            if value and value.parameter and value.parameter.value.includes("is")
               query_value = value.parameter.value == "is_set" ? true : false
               Query [
                  Parameter "must", [
                     Property "enrichment.employments.title.untouched", query_value, {type: "exists"}
                  ]
               ]
            else
               values = _getGroupedComponentOperatorValues(value)
               properties = _formatOrProperties
                  "enrichment.employments.title.untouched": values.enrichment
               Query [
                  Parameter values.condition, [properties]
               ]
