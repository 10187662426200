module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   _ = require("underscore").default
   moment = require("moment")
   InteractionStore = require("apps/interactions/stores/interaction-store").default
   InteractionSource = require("apps/interactions/sources/interaction-source").default
   FeatureStore = require("apps/layout/stores/feature-store").default
   UrlSource = require("apps/layout/sources/url-source")
   {createFactory} = require("base/new-utils")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Icon = createFactory (require("components/elements/icon").default)
   InteractionActions = createFactory require("apps/interactions/components/interaction-actions").default
   InteractionDetails = require("apps/interactions/components/interaction-details")
   AdditionalDetails = createFactory require("apps/interactions/components/additional-details").default
   InteractionIcon = createFactory(require("apps/interactions/components/interaction-icon").default)
   ThankViewInteractions = createFactory(require("apps/interactions/components/interaction-thankview").default)
   ThankViewInteractionsReply = createFactory(require("apps/interactions/components/interaction-thankview-reply").default)
   CommentText = createFactory(require("apps/interactions/components/lazy-comment-text").default)
   InteractionSubstantiveLabel = createFactory(require("apps/interactions/components/interaction-substantive-label").default)
   ActionMenu = createFactory(require("components/controls/action-menu"))
   InteractionUtils = require("apps/interactions/interaction-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   {div, span, strong} = ReactLibs.DOMFactories
   Link = createFactory(require("@evertrue/et-components").Link)
   Loading = createFactory(require("@evertrue/et-components").Loading)
   EventSourceIcon = createFactory(require("apps/events/components/event-source-icon").default)
   withLDConsumer = require("launchdarkly-react-client-sdk").withLDConsumer

   InteractionDetailController = createComponent "InteractionDetailController",
      propTypes:
         id: ReactLibs.PropTypes.number
         flags: ReactLibs.PropTypes.object

      getInitialState: ->
         {highlight: EverTrue.UrlManager.get("highlight")}

      registerStores: ->
         @on InteractionStore, ->
            interaction: InteractionStore.getInteraction(@props.id)
            loading: InteractionStore.getLoading()

         @on FeatureStore, ->
            has_interaction_custom_fields: FeatureStore.getFeature("interaction_custom_fields")
            has_interaction_writes: FeatureStore.hasInteractionWrites()

      componentDidMount: ->
         @downloadInteraction(@props.id)
         EverTrue.track.setReferrer("interaction_detail")

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.id != @props.id
            @downloadInteraction(newProps.id)
            @setState
               interaction: InteractionStore.getInteraction(newProps.id)

      componentWillUnmount: ->
         UrlSource.clear("highlight")

      downloadInteraction: (id) ->
         if !InteractionStore.getInteraction(id) && id
            InteractionSource.fetchInteraction(id)

      setLoading: (loading_val) ->
         @setState loading: loading_val

      handleBack: ->
         window.history.back()

      render: ->
         {
            date_occurred,
            created_at,
            author_name,
            contact,
            contact_id,
            interaction_type,
            summary,
            mentions,
            custom_field,
            substantive,
         } = @state.interaction || {}

         interaction_date = moment(date_occurred || created_at).format("MMMM Do YYYY")
         is_gt_comment = interaction_type == "EverTrue Comment"
         is_tv_interaction = interaction_type == "ThankView - Video"
         full_name = Decorator.Contacts.getFullName(contact)

         if @state.loading
            Loading null
         else if !@state.interaction
            div className: "interaction-detail",
               EmptyStateMessage
                  text: "Interaction Not Found."
                  size: "page"
                  "We're sorry, we were unable to locate this interaction."
                  div null,
                     Link href: "/interaction", title: "search interactions", "Search Interactions"
         else
            div className: "interaction-detail",
               div className: "interaction-detail--header",
                  LinkButton title: "Go back", className: "interaction-detail--header-back", onClick: @handleBack,
                     Icon icon: "chevron-left", size: 2

                  div className: "interaction-meta",
                     div className: "interaction-meta--header-name",
                        author_name
                        if !_.isEmpty(contact)
                           span null,
                              strong className: "text-muted", " / "
                              Link href: "/contact/#{contact_id}", title: "#{full_name}'s profile",
                                 full_name

                  if contact_id
                     div className: "pull-right",
                        InteractionActions 
                           interaction: @state.interaction 
                           setLoading: @setLoading
                           hasInteractionWrites: @state.has_interaction_writes
                           enableInteractionSolicitorType: @props.flags.enableInteractionSolicitorType
                           interactionDescriptionRichTextEditor: @props.flags.interactionDescriptionRichTextEditor

               div
                  className: if is_tv_interaction then "interaction-detail--body-tv" else "interaction-detail--body",
                  div className: "interaction--image",
                     InteractionIcon interaction: @state.interaction
                  div className: "interaction-detail--container",
                     div className: "interaction-detail--top",
                        if substantive
                           InteractionSubstantiveLabel null
                        if @state.interaction.pinned 
                           div className: "interaction-pinned-tag",
                              Icon icon: "pin-icon"
                              span null, "  Pinned to the profile"
                        div className: "interaction-detail--type",
                           interaction_type
                        div className: "interaction-detail--meta",
                           if author_name
                              span null,
                                 span className: "interaction-detail--light-text", "By "
                                 span className: "interaction-detail--dark-text", author_name
                           if interaction_date && interaction_date != "Invalid date"
                              span null,
                                 span className: "interaction-detail--light-text", " on "
                                 span className: "interaction-detail--dark-text", interaction_date

                     div className: "interaction-detail--main",
                        div className: "interaction-detail--summary-text",
                           if summary
                              div className: "interaction-detail--summary", summary
                           div className: "interaction--text",
                              if is_gt_comment
                                 CommentText
                                    text: InteractionUtils.renderText(@state.interaction, true, undefined, @props.flags.interactionDescriptionRichTextEditor)
                                    highlight: @state.highlight
                                    mentions: mentions
                              else if is_tv_interaction
                                 div className: "thankview-detail-reply",
                                    ThankViewInteractions
                                       interaction: @state.interaction
                              else
                                 if @props.flags.interactionDescriptionRichTextEditor
                                    div className: "interaction--rich-text-view",
                                       InteractionUtils.renderText(@state.interaction, true, undefined, @props.flags.interactionDescriptionRichTextEditor)
                                 else
                                    InteractionUtils.renderText(@state.interaction, true, undefined, @props.flags.interactionDescriptionRichTextEditor)

                        div className: "interaction-detail--details",
                           InteractionDetails
                              interaction: @state.interaction
                              hasInteractionCustomFields: @state.has_interaction_custom_fields
                              enableInteractionSolicitorType: @props.flags.enable_interaction_solicitor_type


                           if @state.has_interaction_custom_fields && !_.isEmpty(custom_field)
                              AdditionalDetails({customFields: custom_field})
               if is_tv_interaction && @state.interaction.hasOwnProperty("custom_field") && @state.interaction.custom_field.some((obj) -> obj.displayName is "Reply")
                  div className: "interaction-detail--replytv",
                     div className: "interaction--image",
                        EventSourceIcon
                           size: 40
                           source: "reply"

                     div className: "interaction-detail--container",
                        div className: "interaction-detail--top",
                           if substantive
                              InteractionSubstantiveLabel null
                           div className: "interaction-detail--type",
                              "ThankView Video - Reply"

                        div className: "interaction-detail--main",
                           div className: "interaction-detail--summary-text",
                              if summary
                                 div className: "interaction-detail--summary", summary
                              div className: "interaction--text",
                                 if is_tv_interaction
                                    div className: "thankview-detail-reply",
                                       ThankViewInteractionsReply
                                          interaction: @state.interaction

   withLDConsumer()(InteractionDetailController)