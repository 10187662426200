import PropTypes from 'prop-types';
import Readmore from 'components/formatting/readmore';
import { formatDateFromTimestampShortDate } from 'apps/journeys/utils/helpers/dates';

const EngagementCard = ({ title = '', contents = '', comment = '', date, icon = '' }) => {
  return (
    <div className="engagement-card">
      <div className="engagement-card--icon-wrapper">
        <div className="engagement-card--icon">{icon}</div>
      </div>
      <div className="engagement-card--wrapper">
        <div className="engagement-card--title">{title}</div>

        {contents && <div className="engagement-card--contents ellipsis-overflow">{contents}</div>}

        {comment && (
          <div className="engagement-card--comment">
            <Readmore height={20}>{comment}</Readmore>
          </div>
        )}

        <div className="engagement-card--date">{formatDateFromTimestampShortDate(date)}</div>

        {!contents && <div style={{ marginBottom: '20px' }} />}
      </div>
    </div>
  );
};

EngagementCard.propTypes = {
  title: PropTypes.element.isRequired,
  contents: PropTypes.element,
  comment: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.element.isRequired,
};

export default EngagementCard;
