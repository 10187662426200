export const formatDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};
export const formatDateFromTimestampShortDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const formatDateToLocaleTimeString = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' }).toLowerCase();
};

export const setTimestampToMidnightAndRemoveMilliseconds = (timestamp) => {
  let newDate = new Date(timestamp); // create date object
  newDate.setHours(0, 0, 0, 0); // set times to midnight

  return Math.floor(timestamp / 1000); // remove milliseconds
};

export const formatGivingDate = (dateString) => {
  const date = new Date(dateString + 'T00:00');

  if (isNaN(date)) {
    return;
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const givingTimeAgo = (pastDate) => {
  const currentDate = new Date();
  const past = new Date(pastDate);
  const diffInSeconds = Math.floor((currentDate - past) / 1000);

  if (diffInSeconds < 86400) {
    return `(Less than a day ago)`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `(${days} day${days > 1 ? 's' : ''} ago)`;
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    return `(${months} month${months > 1 ? 's' : ''} ago)`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return `(${years} year${years > 1 ? 's' : ''} ago)`;
  }
};
