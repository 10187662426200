import PropTypes from 'prop-types';
import classNames from 'classnames';
import colors from 'base/colors';

const GiftTooltip = ({ value, valueColor = colors.green300, amount, subText, isEmpty, canClick, className }) => {
  return (
    <div className={classNames('giving-module--tooltip', className)}>
      {isEmpty ? (
        <div className="giving-module--tooltip-empty">No gifts</div>
      ) : (
        <div>
          {/* yValue and Series */}
          <div className="giving-module--tooltip-value" style={{ color: valueColor }}>
            <strong>{amount}</strong>
          </div>
          {/* xValue and subtext */}
          <div>
            {value}
            {subText && ` (${subText})`}
          </div>
          {canClick && <div className="text-label">Click for details</div>}
        </div>
      )}
    </div>
  );
};

GiftTooltip.propTypes = {
  value: PropTypes.any,
  valueColor: PropTypes.string,
  amount: PropTypes.any,
  subText: PropTypes.string,
  isEmpty: PropTypes.bool,
  canClick: PropTypes.bool,
  className: PropTypes.string,
};

export default GiftTooltip;
