import { PropTypes } from 'base/react-libs';
import { ThreeDotActionsMenu } from '@evertrue/et-components';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import DeleteStepModal from '../../../../modals/delete-step-modal';
import JourneyTaskModal from '../../../../modals/journey-task-modal';

const StepRowActionButton = ({ existingJourneyTask = {} }) => {
  const { optionalStateManagementFunction, journey = {} } = useJourneyDetailProvider();
  const editStep = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <JourneyTaskModal
        isOpen={is_open}
        onClose={close}
        existingJourneyTask={existingJourneyTask}
        journeyId={journey?.id}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    icon: 'edit',
    label: 'Edit Step',
  };
  const deleteStep = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <DeleteStepModal
        isOpen={is_open}
        onClose={close}
        journeyTask={existingJourneyTask}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    icon: 'trash',
    label: 'Delete Step',
  };

  return (
    <>
      <ThreeDotActionsMenu options={[editStep, deleteStep]} />
    </>
  );
};

StepRowActionButton.propTypes = {
  existingJourneyTask: PropTypes.object,
};

export default StepRowActionButton;
