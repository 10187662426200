import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/elements/icon';
import { LinkButton } from '@evertrue/et-components';
import KeyPressManager from 'components/helpers/KeyPressManager';

const InteractionPagination = ({ label, loading, offset, onPage, className }) => {
  return (
    <div className={classNames('interaction-pagination', className)}>
      {loading ? (
        <Icon icon="spinner" spin />
      ) : offset >= 450 ? ( // allows for 500 interactions loaded
        <span className="text-muted">
          EverTrue can display a maximum of 500 interactions at a time. Use the filters above to further refine your
          results.
        </span>
      ) : (
        <KeyPressManager onEnter={onPage}>
          {({ onKeyDown, ref }) => (
            <LinkButton title="View More" onClick={onPage} ref={ref} onKeyDown={onKeyDown}>
              {label || 'View More'}
            </LinkButton>
          )}
        </KeyPressManager>
      )}
    </div>
  );
};

InteractionPagination.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  onPage: PropTypes.func,
  className: PropTypes.string,
};

export default InteractionPagination;
