import moment from 'moment';
import { createStore } from '@evertrue/et-flux';
import ImportSource from 'apps/layout/sources/import-source';

const ImportStore = createStore('ImportStore', {
  getInitialState() {
    return {
      last_update_timestamp: undefined,
      loading: false,
    };
  },

  registerActions() {
    this.on(ImportSource.actions.loading, (is_loading) => {
      this.setState({ loading: is_loading });
    });
    this.on(ImportSource.actions.fetchedLastImport, (timestamp) => {
      this.setState({ last_update_timestamp: timestamp });
    });
  },

  api: {
    getLastUpdate() {
      const timestamp = this.getState('last_update_timestamp');
      if (timestamp) {
        return moment(timestamp).format('MM/DD/YYYY @ h:mm A');
      }
    },

    getLastUpdateTimeStamp() {
      return this.getState('last_update_timestamp');
    },

    getLoading() {
      return this.getState('loading');
    },
  },
});

export default ImportStore;
