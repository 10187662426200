import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import CharacterCounter from 'apps/interactions/components/character-counter';
import {
  Modal,
  ModalHeaderWithActions,
  ModalBody,
  TextInput,
  Radio,
  ListboxMultiselect,
} from '@evertrue/et-components';
import { AddToFolderSchema } from './schema/add-to-folder-schema';

const AddToFolderModal = ({
  isOpen,
  onClose = () => {},
  onSubmit = () => {},
  existingFolders = [],
  label = 'Add to Existing Folder(s)',
  folderType = 'STATIC',
}) => {
  const folderOptions = existingFolders.map(({ folder }) => ({
    label: folder.title,
    value: folder.id,
  }));

  const [filteredOptions, setFilteredOptions] = useState(folderOptions);
  const searchTimeoutRef = useRef(null);

  const handleSearch = (search) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (search === '') {
        setFilteredOptions(folderOptions);
      } else {
        const filtered = folderOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        setFilteredOptions(filtered);
      }
    }, 500);
  };

  const handleSubmit = (values) => {
    if (values.action === 'create') {
      onSubmit({ type: 'create', folderName: values.folderName });
    } else {
      onSubmit({ type: 'existing', folders: values.selectedFolders });
    }
  };

  return (
    <Modal size="small" isOpen={isOpen} closeModal={onClose} closeOnOutsideClick={true}>
      <Formik
        initialValues={{
          action: 'existing',
          folderName: '',
          selectedFolders: [],
        }}
        validationSchema={AddToFolderSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors, touched, isValid, dirty }) => (
          <>
            <ModalHeaderWithActions
              title={folderType === 'DYNAMIC' ? 'Add Saved Search to Folder(s)' : 'Add List to Folder(s)'}
              closeModal={onClose}
              onSubmit={handleSubmit}
              disableSubmit={!isValid || !dirty}
            />
            <ModalBody>
              <div style={{ marginBottom: '16px' }}>
                <Radio
                  name="action"
                  value="existing"
                  label={label}
                  checked={values.action === 'existing'}
                  onChange={() => setFieldValue('action', 'existing')}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <ListboxMultiselect
                    size="medium"
                    emptyValue="Select Folder(s)"
                    label="Select Folder(s)"
                    options={filteredOptions}
                    onChange={(selected) => setFieldValue('selectedFolders', selected)}
                    values={values.selectedFolders}
                    disabled={values.action !== 'existing'}
                    searchable={true}
                    onSearch={handleSearch}
                    showInfoBar={true}
                    error={touched.selectedFolders && errors.selectedFolders}
                  />
                </div>

                <Radio
                  name="action"
                  value="create"
                  label="Create New Folder"
                  checked={values.action === 'create'}
                  onChange={() => setFieldValue('action', 'create')}
                />
                <div style={{ marginLeft: '24px' }}>
                  <TextInput
                    placeholder="Name Your Folder"
                    name="folderName"
                    value={values.folderName}
                    onChange={handleChange('folderName')}
                    maxLength={30}
                    required={true}
                    disabled={values.action !== 'create'}
                    error={touched.folderName && errors.folderName}
                  />
                  <CharacterCounter limit={30} count={(values.folderName || '').length} />
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

AddToFolderModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  existingFolders: PropTypes.arrayOf(
    PropTypes.shape({
      folder: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    })
  ),
  folderType: PropTypes.string,
};

export default AddToFolderModal;
