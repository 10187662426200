import EverTrue from "app";
import PropTypes from "prop-types";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";
import { useContext } from "react";
import DotNotificationBadge from "components/notifications/dot-notification-badge.js";
import { TasksNotificationContext } from "apps/tasks/hooks/tasks-notification-context.js";
import { useFeatureFlag } from "entities/helpers/use-feature-flag";

const NavigationJourneysLink = ({ active_path = "", isCollapsed }) => {
  const { flagEnabled, loaded } = useFeatureFlag("signal");

  const { tasksDueCount = {} } = useContext(TasksNotificationContext);
  const { journeys = 0 } = tasksDueCount;

  if (!loaded) return null;
  if (flagEnabled) {
    return (
      <NavigationSubItem
        icon="cadences"
        label="Cadences"
        isActive={!!active_path.match(new RegExp(`cadences.*`))}
        onClick={() => {
          EverTrue.track.set("tab_view", { type: "journeys" });
          EverTrue.Navigator("/cadences", true);
        }}
        iconKit="gt2"
      >
        <DotNotificationBadge
          popoverText={"Incomplete Cadence Steps Due Today"}
          showBadge={!!journeys}
          style={{ marginLeft: isCollapsed ? "5px" : "50px" }}
        />
      </NavigationSubItem>
    );
  } else {
    return null;
  }
};

NavigationJourneysLink.propTypes = {
  active_path: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default NavigationJourneysLink;
