import { PropTypes } from 'base/react-libs';
import { useEffect, useState } from 'react';
import TaskCount from '../../../journeys/components/task-count/task-count';
import checkIcon from 'images/check.svg';
import { consolidateOtherTaskCounts } from 'apps/home/utils/helpers';

const OtherTaskCounter = ({ todayTaskCount, loadingTasksTodayCount }) => {
  const [title, setTitle] = useState('');
  const [showIcon, setShowIcon] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const { COMPLETE, ...rest } = todayTaskCount;
    const { TOTAL } = todayTaskCount;
    let newTitle = '';
    if (TOTAL === 0 && COMPLETE === 0) {
      newTitle = 'You have no other tasks due';
      setShowIcon(false);
    } else if (TOTAL > 0) {
      newTitle = null;
      setShowIcon(false);
    } else {
      newTitle = 'You completed all of your other tasks!';
      setShowIcon(true);
    }
    setTitle(newTitle);
    setData(consolidateOtherTaskCounts(rest));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayTaskCount.TOTAL, todayTaskCount.COMPLETED]);

  return (
    <TaskCount
      title={'Tasks'}
      subTitle={title}
      icon={checkIcon}
      showIcon={showIcon}
      data={data}
      loadingCounts={loadingTasksTodayCount}
    />
  );
};

OtherTaskCounter.propTypes = {
  todayTaskCount: PropTypes.object,
  loadingTasksTodayCount: PropTypes.bool,
};

export default OtherTaskCounter;
