import { PropTypes } from 'base/react-libs';
import { PageHeader } from '@evertrue/et-components';
import Resources from './resources';
import Upcoming from './upcoming';
import NextSteps from './next-steps/next-steps';
import JourneyPauseContainer from 'apps/journeys/components/top-nav/journeys-pause-container';
import SignalAnalytics from './signal-analytics';

const getTitle = (user = {}) => {
  if (!user || !user?.name) return 'Welcome back!';
  const { name = '' } = user;
  const firstName = name.split(' ')[0];
  return `Welcome, ${firstName}!`;
};

const HomepageMainContent = ({ org = {}, user = {} }) => {
  return (
    <>
      <div className="homepage-title-containter">
        <PageHeader title={getTitle(user)} className="homepage-title" />
      </div>
      <JourneyPauseContainer />
      <div className="homepage-main-content">
        <Upcoming user={user} />
        <NextSteps user={user} org={org} />
        <SignalAnalytics oid={org?.id} />
        <Resources />
      </div>
    </>
  );
};

HomepageMainContent.propTypes = {
  org: PropTypes.object,
  user: PropTypes.object,
};

export default HomepageMainContent;
