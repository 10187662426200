import { useEffect, useState } from 'react';
import { fetchTrips } from '../utils/api-requests/trips-meetings-requests';

const useTripsAndMeetings = (userId) => {
  const [tripsAndMeetingDetails, setTripsAndMeetingDetails] = useState({});
  const [tripsAndMeetingDetailsForNextFive, setTripsAndMeetingDetailsForNextFive] = useState({});

  //TRIPS AND MEETINGS FOR TODAY AND NEXT FIVE DAYS
  useEffect(() => {
    const getAndSetTripsAndMeetings = async () => {
      const tripAndMeetingDetails = await fetchTrips(userId, 0);
      setTripsAndMeetingDetails(tripAndMeetingDetails);
      const tripAndMeetingDetailsForNextFive = await fetchTrips(userId, 4);
      setTripsAndMeetingDetailsForNextFive(tripAndMeetingDetailsForNextFive);
    };

    getAndSetTripsAndMeetings();
  }, [userId]);

  return { tripsAndMeetingDetails, tripsAndMeetingDetailsForNextFive };
};
export default useTripsAndMeetings;
