import { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeaderWithActions, Radio } from '@evertrue/et-components';
import TimeDropdown from 'components/forms/time-dropdown';
import TimezoneDropdown from 'components/forms/timezone-dropdown';
import FormField from 'components/forms/form-field';
import DNAStore from 'base/dna/dna-store';
import TimezoneHelper from 'entities/helpers/timezone-helper';
import useFetchTasksEmailAlertPrefs from 'apps/tasks/hooks/use-fetch-tasks-email-alert-prefs';
import { updateEmailAlerts } from 'apps/tasks/utils/tasks-actions';

const TaskEmailAlertsModal = ({ isModalOpen, closeModal }) => {
  const { alertPrefs } = useFetchTasksEmailAlertPrefs(isModalOpen);
  const [submitting, setSubmitting] = useState(false);
  const [email_frequency, setEmailFrequency] = useState('NEVER');
  const [starts_on, setStartsOn] = useState(moment.now());
  const [time, setTime] = useState({
    label: moment('09:00:00', ['HH:mm']).format('hh:mm a'),
    value: moment('09:00:00', ['HH:mm']).format('HH:mm'),
  });
  const [notification_timezone, setTimeZone] = useState(
    TimezoneHelper.findSetValue(DNAStore.getByKey('ET.Importer.TimeZone') || moment.tz.guess())
  );

  useEffect(() => {
    const { email_enabled, notification_timezone, starts_on } = alertPrefs;
    if (email_enabled) {
      const time = moment(starts_on.split(' ')[1], ['HH:mm']);
      setEmailFrequency('DAILY');
      setStartsOn(moment(starts_on).unix() * 1000);
      setTimeZone(TimezoneHelper.findSetValue(notification_timezone));
      setTime({
        label: time.format('hh:mm a'),
        value: time.format('HH:mm:ss'),
      });
    } else {
      setEmailFrequency('NEVER');
      setStartsOn(moment.now());
      setTime({
        label: moment('09:00:00', ['HH:mm']).format('hh:mm a'),
        value: moment('09:00:00', ['HH:mm']).format('HH:mm'),
      });
      setTimeZone(TimezoneHelper.findSetValue(DNAStore.getByKey('ET.Importer.TimeZone') || moment.tz.guess()));
    }
  }, [alertPrefs, isModalOpen]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      let prefs;

      if (email_frequency === 'NEVER') {
        prefs = { email_enabled: false };
      } else {
        prefs = {
          email_frequency: email_frequency,
          starts_on: `${moment(starts_on).format('YYYY-MM-DD')} ${moment(time.label, 'hh:mm a').format('HH:mm')}`,
          notification_timezone: notification_timezone.value,
          email_enabled: true,
        };
      }
      await updateEmailAlerts(prefs);
      closeModal();
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen} closeModal={closeModal}>
      <ModalHeaderWithActions
        title={'Email Alerts'}
        closeModal={closeModal}
        onSubmit={handleSubmit}
        disableSubmit={submitting}
      />
      <ModalBody scrollable={false}>
        <p className="alerts-description">
          Get a daily email with your tasks due for the day. We'll only notify you on days when you have tasks due.
        </p>
        <div className="alerts-container tasks-alert">
          <FormField label="Frequency :" inlineLabel={true}>
            <Radio
              checked={email_frequency === 'NEVER'}
              value="NEVER"
              label="Never"
              onChange={() => setEmailFrequency('NEVER')}
            />
            <Radio
              checked={email_frequency === 'DAILY'}
              value="DAILY"
              label="Daily"
              onChange={() => setEmailFrequency('DAILY')}
            />
          </FormField>
          <FormField label="Time :" inlineLabel={true}>
            <TimeDropdown
              className="time-dropdown"
              increment={60}
              onChange={(time) => setTime(time)}
              value={time}
              disabled={email_frequency === 'NEVER' ? true : false}
            />
            <TimezoneDropdown
              value={notification_timezone}
              onChange={(tz) => setTimeZone(tz)}
              disabled={email_frequency === 'NEVER' ? true : false}
            />
          </FormField>
        </div>
      </ModalBody>
    </Modal>
  );
};

TaskEmailAlertsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};
export default TaskEmailAlertsModal;
