import numeral from "numeral";

export const simplifyNetWorths = (netWorths, hasWindfallPlus) => {
  const oneMil = 1000000;
  return netWorths.map(({ id, rejected, updated_at, source, net_worth }) => {
    let simplifiedVal;
    if (net_worth > oneMil || (source === "windfall_wealth_plus" && hasWindfallPlus)) {
      simplifiedVal = numeral(net_worth).format("$0.00a");
    } else {
      simplifiedVal = "Less than $1M";
    }
    return {
      value: simplifiedVal,
      id,
      rejected,
      updated_at,
    };
  });
};
