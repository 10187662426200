import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const useProgressBar = (processingVideoState) => {
  const [loadingProgress, setLoadingProgress] = useState(1);

  useEffect(() => {
    let progressInterval;
    if (processingVideoState === 'uploading') {
      setLoadingProgress(1);

      progressInterval = setInterval(() => {
        setLoadingProgress((prev) => {
          const increment = Math.max(0.1, (75 - prev) / 20);
          const newProgress = prev + increment;
          return newProgress >= 75 ? 75 : newProgress;
        });
      }, 200);
    } else if (processingVideoState === 'generating_thumbnail') {
      clearInterval(progressInterval);

      progressInterval = setInterval(() => {
        setLoadingProgress((prev) => {
          const currentProgress = Math.max(prev, 75);
          const increment = Math.max(0.65, (95 - currentProgress) / 10);
          const newProgress = currentProgress + increment;
          return newProgress >= 95 ? 95 : newProgress;
        });
      }, 200);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [processingVideoState]);

  return { loadingProgress };
};

const ProgressBar = ({ processingVideoState }) => {
  const { loadingProgress } = useProgressBar(processingVideoState);
  return (
    <div className="processing-video-container ">
      <i className="fa fa-spinner fa-spin spinner-tv" style={{ marginBottom: '30px' }}></i>

      <div className="tv-loading-bar-container">
        <div className="tv-loading-bar" style={{ width: `${loadingProgress}%` }}></div>
      </div>
      <p>{processingVideoState === 'uploading' ? 'Processing Video...' : 'Generating Thumbnail...'}</p>
    </div>
  );
};

ProgressBar.propTypes = {
  processingVideoState: PropTypes.string.isRequired,
};

export { ProgressBar };
export default useProgressBar;
