import PropTypes from "prop-types";
import { useFeatureFlag } from "entities/helpers/use-feature-flag.js";

const FlaggedComponent = ({ feature, children, inverse, render }) => {
  const { flagEnabled } = useFeatureFlag(feature);

  const show = inverse ? !flagEnabled : flagEnabled;

  const obj = { show };

  if (render) return render(obj);
  if (typeof children === "function") return children(obj);

  return show ? children : null;
};

FlaggedComponent.propTypes = {
  feature: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.any,
  render: PropTypes.func,
};

FlaggedComponent.defaultProps = {
  inverse: false,
  render: undefined,
};

export default FlaggedComponent;
