import PropTypes from 'prop-types';
import EverTrue from 'app';
import { gqlCall } from 'entities/helpers/gqlCall';
import KpiWithPercentWheelCard from './cards/kpi-with-percent-wheel-card';
import KpiMetricCard from './cards/kpi-metric-card';
import KpiTableCard from './cards/kpi-table-card';
import WidgetKpiCard from './cards/widget-kpi-card';
import { LinkButton, Modal, Card, useOpenClose } from '@evertrue/et-components';
import DisplayWidgetPagedResultsModal from 'apps/dashboard/components/display-widget-paged-results-modal';

const fetchStat = (query, variables) => {
  return gqlCall(query, variables);
};

const WidgetCard = ({ widget = {}, variables, fiscalYear, canReorder }) => {
  const { theme_color, value_results_display, num_rows, requires_single_team, icon } = widget.template_json;
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const headerStyle = theme_color ? { background: `#${theme_color}10`, border: '0' } : {};
  const isInvalidTeamOption = requires_single_team && variables.poolId === null;
  const hasDetailModal = !isInvalidTeamOption && value_results_display;

  const card = () => {
    if (isInvalidTeamOption) {
      return (
        <WidgetKpiCard title={widget.title} fiscal_year={fiscalYear} icon={icon} color={theme_color}>
          <div className="dashboard-widget--disabled">Select a single team to see {widget.title.toLowerCase()}</div>
        </WidgetKpiCard>
      );
    }

    switch (widget.display_type) {
      case 'kpicard':
        return (
          <KpiWithPercentWheelCard
            {...widget}
            key={widget.id}
            variables={variables}
            fetchStat={fetchStat}
            fiscal_year={fiscalYear}
          />
        );
      case 'goalcard':
        return (
          <KpiMetricCard
            {...widget}
            key={widget.id}
            variables={variables}
            fetchStat={fetchStat}
            fiscal_year={fiscalYear}
          />
        );
      case 'tablecard':
        return (
          <KpiTableCard
            {...widget}
            key={widget.id}
            variables={variables}
            fetchStat={fetchStat}
            fiscal_year={fiscalYear}
            headerStyle={headerStyle}
            num_rows={num_rows}
          />
        );
      default:
        console.warn(`${widget.display_type} not defined`);
        return null;
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal} closeOnOutsideClick={true}>
        <DisplayWidgetPagedResultsModal
          template={widget.template_json}
          variables={variables}
          title={widget.title}
          headerStyle={headerStyle}
          close={closeModal}
        />
      </Modal>
      <Card
        className="dashboard-widget"
        hoverTransform={canReorder ? 'draggable' : hasDetailModal ? 'clickable' : null}
      >
        {hasDetailModal ? (
          <LinkButton
            title={'view more widget details'}
            onClick={() => {
              EverTrue.track.set('launchpad_action', { type: 'widget_detail_view', referrer: widget.title });
              if (!canReorder) openModal();
            }}
          >
            {card()}
          </LinkButton>
        ) : (
          <>{card()}</>
        )}
      </Card>
    </>
  );
};

WidgetCard.propTypes = {
  widget: PropTypes.object,
  variables: PropTypes.object,
  fiscalYear: PropTypes.number,
  canReorder: PropTypes.bool,
};

export default WidgetCard;
