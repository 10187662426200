import { Checkbox, FlexTableSortHeaderCell, Icon, LinkButton, Avatar, ButtonTooltip } from '@evertrue/et-components';
import moment from 'moment';
import Backbone from 'backbone';

const ListColumns = (
  buttonlabel,
  handleSort,
  sortReverse,
  users,
  user,
  selectedLists,
  handleListCheck,
  handleCheckAllLists,
  totalLists = [],
  onSelectedListsChange,
  openDeleteModal,
  userIsOwner = true,
  hasFolders = true
) => {
  const fullyChecked = totalLists.length > 0 && selectedLists.length === totalLists.length;
  const partiallyChecked = selectedLists.length > 0 && selectedLists.length < totalLists.length;

  const handleListSelectionChange = (list, isChecked) => {
    const updatedSelectedLists = isChecked ? [...selectedLists, list] : selectedLists.filter((l) => l.id !== list.id);
    handleListCheck(list, isChecked);
    onSelectedListsChange(updatedSelectedLists);
  };

  return [
    ...(hasFolders
      ? [
          {
            key: 0,
            header: (
              <>
                {userIsOwner ? (
                  <Checkbox
                    checked={fullyChecked || partiallyChecked}
                    partial={partiallyChecked}
                    onChange={(checked) => handleCheckAllLists(checked)}
                    className="header-checkbox"
                    label="Select All Lists"
                  />
                ) : (
                  <span></span>
                )}
              </>
            ),
            accessor: (list) => {
              return userIsOwner ? (
                <Checkbox
                  checked={selectedLists.some((l) => l.id === list.id)}
                  onChange={(checked) => handleListSelectionChange(list, checked)}
                  className={`task-checkbox ${selectedLists.length > 0 ? 'visible' : ''}`}
                  label="Select List"
                />
              ) : (
                <span></span>
              );
            },
            headerStyle: { maxWidth: '50px', textAlign: 'left' },
            cellClassName: 'task-checkbox-cell',
          },
        ]
      : []),
    {
      key: 1,
      header: (
        <FlexTableSortHeaderCell onClick={() => handleSort(!sortReverse)} sortOrder={sortReverse} isSorted={true}>
          List Name
        </FlexTableSortHeaderCell>
      ),
      accessor: (i) => (
        <>
          {i.private ? (
            <Icon icon="lock" className="lists-page--icon" />
          ) : (
            <Icon icon="group" className="lists-page--icon" />
          )}
          <LinkButton title={i.name} onClick={() => Backbone.history.navigate(`list/${i.id}`, true)}>
            {i.name}
          </LinkButton>
        </>
      ),
    },
    {
      key: 2,
      header: 'Owner',
      accessor: (i) => {
        const user = users[i.user_id];
        return (
          <div className="lists-page--avatar">
            <Avatar name={user?.name ?? "User's avatar"} width={30} url={user && user.avatar} />
            <div>{user && user.name}</div>
          </div>
        );
      },
    },
    {
      key: 3,
      header: 'Last Updated',
      accessor: (i) => moment(i.updated_at).format('MMMM Do, YYYY'),
    },
    {
      key: 4,
      headerStyle: { flexGrow: 1 },
      header: '',
      cellClassName: 'task-icon-col',
      accessor: (data) => {
        if (data.user_id === user.id)
          return (
            <ButtonTooltip
              children={buttonlabel}
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" onClick={() => openDeleteModal(data.id)} isReachable={true} />}
            />
          );
        else
          return (
            <ButtonTooltip
              children="Delete option is disabled because you are not the owner of this list"
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" style={{ color: 'grey' }} />}
            />
          );
      },
    },
  ];
};

export default ListColumns;
