import React from 'react';
import ErrorLogger from 'entities/helpers/error-log-helper';
import moment from 'moment';

export const isValidURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
};

export const isHTTPSURL = (str) => {
  const a = document.createElement('a');
  a.href = str;
  return a.protocol === 'https:';
};

export const isValidHTTPSURL = (str) => {
  return [isHTTPSURL(str), isValidURL(str)].every(Boolean);
};

export const formatDateToUnixWithCurrentTime = (date) => {
  if (date === 'Invalid date') {
    return null;
  }

  const time = moment().format('HH:mm:ss');
  return moment(`${date} ${time}`).valueOf();
};

/**
 * Takes in a date string and returns the date in "yyyy-MM-dd" format
 * if no date is provided, it returns the current date
 * @param {String || Number} date -- either a date string or a timestamp
 * @returns "YYYY-M-D" formatted date string eg. "2025-01-02"
 */
export const getDateString = (date) => {
  if (date) {
    return moment(date).tz(getBrowserTimezone()).format('YYYY-MM-DD');
  } else {
    return moment().tz(getBrowserTimezone()).format('YYYY-MM-DD');
  }
};

/**
 * Replacement for deprecated createFactory which is used by Coffeescript components
 * https://reactjs.org/blog/2020/02/26/react-v16.13.0.html#deprecating-reactcreatefactory
 */
export const createFactory = (type) => React.createElement.bind(null, type);

export const getBrowserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    ErrorLogger.error("Error retrieving browser's timezone", { extra: { error: e } });
    return 'UTC'; // Default to UTC if unable to get the timezone
  }
};

/**
 * Returns the date in the timezone of the browser
 *
 * @param {String | Number | Date} date -- Date String or Timestamp or Date Object
 * @returns Javascript Date Object
 */
export const getDateInTimezone = (date) => {
  if (date) {
    try {
      return moment(date).tz(getBrowserTimezone()).toDate();
    } catch (e) {
      ErrorLogger.error('Error getting date in timezone', { extra: { error: e } });
      return;
    }
  } else {
    try {
      return moment().tz(getBrowserTimezone()).toDate();
    } catch (e) {
      ErrorLogger.error('Error getting date in timezone', { extra: { error: e } });
      return;
    }
  }
};

/**
 * Returns moment object in the timezone of the browser
 *
 * @param {String | Number | Date} date -- Date String or Timestamp or Date Object
 * @returns Moment Object
 */
export const getMomentObjectInTimezone = (date) => {
  if (date) {
    try {
      return moment(date).tz(getBrowserTimezone());
    } catch (e) {
      ErrorLogger.error('Error getting date in timezone', { extra: { error: e } });
      return;
    }
  } else {
    try {
      return moment().tz(getBrowserTimezone());
    } catch (e) {
      ErrorLogger.error('Error getting date in timezone', { extra: { error: e } });
      return;
    }
  }
};

/**
 * Returns the number of days between two date strings without accounting for timezones or time in any way.
 * a POSITIVE number means date2 is after date1
 * a NEGATIVE number means date2 is before date1
 *
 * @param {Date String} dateStr1 -- formatted as "YYYY-MM-DD"
 * @param {Date String} dateStr2  -- formatted as "YYYY-MM-DD"
 * @returns {Number} -- the number of days between the two dates
 */
export const calculateDaysBetweenDates = (dateStr1, dateStr2) => {
  if (!dateStr1 || !dateStr2) return 0;

  // Parse the date strings into Date objects
  let start = new Date(dateStr1 + 'T00:00:00Z'); // Append a time to avoid timezone issues
  let end = new Date(dateStr2 + 'T00:00:00Z');

  // Remove the time part (set hours, minutes, seconds, milliseconds to 0)
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = end - start;

  // Convert milliseconds to days
  const daysDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return daysDifference; // Return the difference (CAN be negative)
};
