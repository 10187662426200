import EverTrue from 'app';
import { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Loading, ModalTrigger, Icon, useOpenClose, OutsideClick, Button } from '@evertrue/et-components';
import RichTextEditor from './rich-text-editor';
import Api from 'entities/helpers/api';
import { MultiRecipientsInput } from './multi-recipients-input';
import EmailTemplatesModalController from 'apps/outreach/email/templates/controllers/email-templates-modal-controller';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import EmailSignaturesModal from 'apps/outreach/email/signatures/components/email-signatures-modal';
import ThankviewCTA from '../thankview/thankview-recorder-cta';
import FileUpload from './components/file-upload';
import UploadedFiles from './components/uploaded-files';
import useFileUploader from './hooks/use-file-uploader';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from './utils/upload-file';
import { EmailTemplatesContext } from 'apps/outreach/email/templates/hooks/email-templates-context';
import { LinkButton } from '@evertrue/et-components';
import { updateEmailBody } from './utils/helpers/emai-body-helper';
import useDynamicFields from './hooks/use-dynamic-fields';
import MissingInfoModal from 'apps/outreach/email/common/missing-information-modal';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import SplitButton from './email-send-split-button.js';
import { createScheduledSend, updateScheduledSend } from 'apps/outreach/utils/scheduled-email-api';
import { useGlobalOutreachProvider } from 'apps/outreach/contexts/global-outreach-context';

export const EmailForm = ({
  taskId,
  recipients = [],
  handleClose = () => {},
  setDrawerTitle = () => {},
  contact,
  outreach,
  outreachType,
  handleSaveOrUpdateDraft = () => {},
  handleDeleteOutreachEmail = () => {},
  closeDrawer,
  isSavingDraftRef,
}) => {
  const defaultEmails =
    Array.isArray(recipients) && recipients.length > 0 && typeof recipients[0] === 'object'
      ? {
          to: recipients.filter((r) => r.recipient_type === 'TO').map((r) => r.email_address),
          cc: recipients.filter((r) => r.recipient_type === 'CC').map((r) => r.email_address),
          bcc: recipients.filter((r) => r.recipient_type === 'BCC').map((r) => r.email_address),
        }
      : {
          to: recipients,
          cc: [],
          bcc: [],
        };

  const [emailBody, setEmailBody] = useState(outreach?.body || '');
  const [isSending, setIsSending] = useState(false);
  const [defaultSignature, setDefaultSignature] = useState({ id: 0, name: 'No Signature', signature_body: '' });
  const [signaturesModalIsOpen, openSignaturesModal, closeSignaturesModal] = useOpenClose(false);
  const { files, setFiles, removeFile } = useFileUploader();
  const { total, showEditView, getAndSetTemplates, selectedTemplate, setSelectedTemplate, templates } =
    useContext(EmailTemplatesContext);
  const { getAllScheduled, getAllSent } = useGlobalOutreachProvider();
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const { flagEnabled: hasSignalVideo } = useFeatureFlag('signalVideo');
  const { flagEnabled: hasDraftsAndScheduled } = useFeatureFlag('outreachDraftsScheduled');

  const [email, setEmail] = useState({
    subject: outreach?.subject || '',
    to: defaultEmails.to,
    cc: defaultEmails.cc,
    bcc: defaultEmails.bcc,
    starred: outreach?.starred || false,
  });

  const quillRef = useRef();

  const { getMissingFields } = useDynamicFields(quillRef, contact, false, false);

  const formDataRef = useRef({
    message: {
      subject: email.subject,
      to: email.to,
      cc: email.cc,
      bcc: email.bcc,
      body: emailBody,
      attachment_urls: [],
      task_id: taskId,
      starred: email.starred,
      nylas_thread_id: outreach?.nylas_thread_id || null,
    },
    email_template_id: outreach?.email_template_id || null,
  });
  useEffect(() => {
    if (outreach?.email_template_id) {
      setSelectedTemplate(templates.find((template) => template.id === outreach.email_template_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreach, templates]);

  useEffect(() => {
    formDataRef.current = {
      message: {
        subject: email.subject,
        to: email.to.map((email) => ({ email })),
        cc: email.cc.map((email) => ({ email })),
        bcc: email.bcc.map((email) => ({ email })),
        body: emailBody,
        task_id: taskId,
        attachment_urls: [],
        nylas_thread_id: outreach?.nylas_thread_id || null,
        attachment_requests: files
          .filter((file) => file)
          .map(({ url, isInline, contentId }) => ({
            presigned_url: url,
            is_inline: !!isInline,
            content_id: contentId ? contentId : '',
            content_disposition: isInline ? 'inline' : "'attachment'",
          })),
        starred: email.starred,
      },
      email_template_id: selectedTemplate?.id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, emailBody, files, selectedTemplate, taskId]);

  useEffect(() => {
    getAndSetTemplates();

    const handleBeforeUnload = (event) => {
      if (isSavingDraftRef?.current && outreachType !== 'Scheduled') {
        handleSaveOrUpdateDraft(formDataRef?.current);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      if (hasDraftsAndScheduled) {
        if (isSavingDraftRef?.current && outreachType !== 'Scheduled') {
          handleSaveOrUpdateDraft(formDataRef?.current);
        }
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSubject = (newSub) => {
    setEmail({ ...email, subject: newSub });
    setDrawerTitle(newSub);
  };

  const handleSubmit = () => {
    const unavailablefields = getMissingFields(emailBody);
    setMissingFields(unavailablefields);

    if (unavailablefields.length === 0 || showReviewPopup) {
      trackOutreachAction('send_email');
      setIsSending(true);
      const quill = quillRef.current.getEditor();

      Api.OUTREACH.SEND.post({
        data: JSON.stringify({
          message: {
            to: email.to.map((email) => ({ email })),
            cc: email.cc.map((email) => ({ email })),
            bcc: email.bcc.map((email) => ({ email })),
            subject,
            body: updateEmailBody(emailBody),
            task_id: taskId,
            nylas_thread_id: outreach?.nylas_thread_id || null,
            tracking_options: {
              is_message_open_tracking_enabled: false,
              is_thread_reply_tracking_enabled: true,
              is_link_clicked_tracking_enabled: false,
            },
            attachment_requests: files
              .filter((file) => file)
              .map(({ url, isInline, contentId }) => ({
                presigned_url: url,
                is_inline: !!isInline,
                content_id: contentId ? contentId : '',
                content_disposition: isInline ? 'inline' : "'attachment'",
              })),
            starred: email.starred,
          },
          email_template_id: selectedTemplate?.id,
        }),
      })
        .then((res) => {
          isSavingDraftRef.current = false;
          EverTrue.Alert.success('Email Sent Successfully');
          getAllSent();
          setDrawerTitle('');
          if (outreach?.id) {
            handleDeleteOutreachEmail(outreach.id, outreachType, false);
          }
          // This function is provided by the Global Outreach Context and is responsible for opening the Quick Interaction Modal
          handleClose({
            contact,
            interaction: {
              summary: subject,
              text: `Sent the following email:\n${quill.getText()}`,
            },
            emailId: res.id,
            emailCreatorId: res.creator_user_id,
          });
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message || 
            'There was a problem sending your email. Please try again.';
          EverTrue.Alert.error(errorMessage);
          setIsSending(false);
        });
      setShowReviewPopup(false);
    } else {
      setShowReviewPopup(true);
    }
  };

  const handleScheduleSend = async (send_at, formattedDateTime) => {
    const quill = quillRef.current.getEditor();
    isSavingDraftRef.current = false;
    const data = {
      message: {
        to: email.to.map((email) => ({ email })),
        cc: email.cc.map((email) => ({ email })),
        bcc: email.bcc.map((email) => ({ email })),
        subject,
        body: updateEmailBody(emailBody),
        task_id: taskId,
        send_at: send_at,
        nylas_schedule_id: outreach?.nylas_schedule_id || null,
        nylas_thread_id: outreach?.nylas_thread_id || null,
        nylas_message_id: outreach?.nylas_message_id || null,
        nylas_draft_id: outreach?.nylas_draft_id || null,
        attachment_requests: files
          .filter((file) => file)
          .map(({ url, isInline, contentId }) => ({
            presigned_url: url,
            is_inline: !!isInline,
            content_id: contentId ? contentId : '',
            content_disposition: isInline ? 'inline' : "'attachment'",
          })),
        starred: email.starred,
      },
      email_template_id: selectedTemplate?.id,
    };
    closeDrawer();
    if (outreachType === 'Scheduled') {
      await updateScheduledSend(data, outreach.id);
      getAllScheduled();
    } else {
      await createScheduledSend(data, formattedDateTime);
      getAllScheduled();
      if (outreach?.id) {
        await handleDeleteOutreachEmail(outreach.id, outreachType, false);
      }
    }
    handleClose({
      contact,
      interaction: {
        summary: subject,
        text: `Scheduled the following email:\n${quill.getText()}`,
      },
    });
  };

  const getEmailBodyWithoutSignature = (lastSignatureIndex) => {
    let bodyWithoutSignature = emailBody.substring(0, lastSignatureIndex - 3);
    while (bodyWithoutSignature.endsWith('<p><br></p>')) {
      bodyWithoutSignature = bodyWithoutSignature.slice(0, bodyWithoutSignature.length - 11); // Remove the last 13 characters (length of "<p><br></p>")
    }
    return bodyWithoutSignature;
  };

  useEffect(() => {
    const getDefaultSignature = () => {
      if (defaultSignature.id !== 0) {
        setEmailBody((prev) => {
          let updatedEmailBody = '';
          const lastSignatureIndex = prev.lastIndexOf('--');
          if (lastSignatureIndex !== -1) {
            const bodyWithoutSignature = getEmailBodyWithoutSignature(lastSignatureIndex);
            updatedEmailBody = bodyWithoutSignature + `<br><br>--<br>${defaultSignature.signature_body} </p>`;
          } else {
            updatedEmailBody = prev + `<br><br>--<br>${defaultSignature.signature_body} </p>`;
          }
          return updatedEmailBody;
        });
      } else {
        setEmailBody((prev) => {
          let updatedEmailBody = '';
          const lastSignatureIndex = prev && prev.lastIndexOf('--');
          if (lastSignatureIndex !== -1) {
            updatedEmailBody = getEmailBodyWithoutSignature(lastSignatureIndex);
            return updatedEmailBody;
          } else {
            return prev;
          }
        });
      }
    };

    getDefaultSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSignature]);

  const handleFileUpload = async (files, inline = false) => {
    return await Promise.all(
      files.map(async (file) => {
        const newFile = { id: uuidv4(), file, url: '' };
        if (inline) {
          newFile.isInline = true;
          newFile.contentId = newFile.id;
        }
        setFiles((prevFiles) => [...prevFiles, newFile]);

        const res = await uploadFile(file);
        if (res.error) {
          EverTrue.Alert.error('There was a problem uploading your file. Please try again.');
          removeFile(newFile.id);

          return null;
        } else {
          newFile.url = res.url;
          setFiles((prevFiles) =>
            prevFiles.map((f) => {
              if (f.id === newFile.id) {
                return newFile;
              } else return f;
            })
          );
          return newFile;
        }
      })
    );
  };

  const { to, bcc, cc, subject } = email;
  return (
    <div className="email-form email-editor">
      <div className="email-form-header">
        <OutsideClick
          onOutsideClick={() => {
            setShowBcc(false);
            setShowCc(false);
          }}
        >
          <div className="email-form--recipients-container">
            <MultiRecipientsInput
              inputRecipients={email.to}
              setInputRecipients={setEmail}
              numCc={cc.length}
              numBcc={bcc.length}
              label="To"
              showCc={showCc}
              showBcc={showBcc}
            />
            <div className="email-form-header-cc">
              <LinkButton title="Cc" onClick={() => setShowCc(!showCc)}>
                Cc
              </LinkButton>
              <LinkButton title="Bcc" onClick={() => setShowBcc(!showBcc)}>
                Bcc
              </LinkButton>
            </div>
          </div>
          {showCc && <MultiRecipientsInput inputRecipients={cc} setInputRecipients={setEmail} label="Cc" />}
          {showBcc && <MultiRecipientsInput inputRecipients={bcc} setInputRecipients={setEmail} label="Bcc" />}
        </OutsideClick>
        <div className="email-form-header-field email-subject">
          <label className="space-between email-form--label">
            {email.subject.length === 0 && <strong>Subject:</strong>}
            <input
              className="email-form--input-field"
              style={{ marginLeft: '5px' }}
              value={email.subject}
              onChange={(e) => handleChangeSubject(e.target.value)}
              data-testid="subject-field"
              name="subject"
            />
          </label>
        </div>
      </div>
      <div className="email-form--body" data-testid="body-field">
        {isSending ? (
          <Loading />
        ) : (
          <RichTextEditor
            contact={contact}
            editorBody={emailBody}
            setEditorBody={setEmailBody}
            quillRef={quillRef}
            autoReplaceDynamicFields={true}
            enableDynamicFields={true}
            handleFileUpload={handleFileUpload}
          />
        )}
      </div>
      {files.length > 0 && (
        <div className="email-form--files" style={{ height: '78px', overflowY: 'auto', paddingTop: '10px' }}>
          <UploadedFiles files={files} setFiles={setFiles} removeFile={removeFile} />
        </div>
      )}
      <div className="email-form--footer space-between">
        <div className="space-between" style={{ width: '100%' }}>
          <div className="space-between">
            {!hasDraftsAndScheduled ? (
              <Button label="sendButton" type="default" onClick={handleSubmit} disabled={to.length === 0 || isSending}>
                Send
              </Button>
            ) : (
              <SplitButton
                onSend={handleSubmit}
                onSchedule={handleScheduleSend}
                disabled={to?.length === 0 || isSending}
                scheduledTime={outreach?.scheduled_at}
              />
            )}
            <div className="email-footer-btns">
              <FileUpload onFileChange={handleFileUpload} allowMultiple={true} />
              <Icon
                icon="email-signature-icon"
                className="email-signatures-btn"
                iconKit="gt2"
                onClick={openSignaturesModal}
                isReachable={true}
                onKeyUp={openSignaturesModal}
              ></Icon>
              <ModalTrigger
                size={total === 0 || showEditView ? '760px' : '1060px'}
                closeOnOutsideClick={true}
                buttonProps={{ type: 'simple' }}
                buttonText={<Icon icon="email-template" iconKit="gt2" className="email-signatures-btn"></Icon>}
              >
                {({ closeModal }) => (
                  <EmailTemplatesModalController
                    contact={contact}
                    closeModal={closeModal}
                    emailBodyRef={quillRef}
                    setSubject={handleChangeSubject}
                  />
                )}
              </ModalTrigger>
            </div>
          </div>
          {outreach && (
            <Icon
              icon="trash"
              className="delete-email-btn"
              onClick={() => handleDeleteOutreachEmail(outreach.id, outreachType)}
              isReachable={true}
            ></Icon>
          )}
          {hasSignalVideo && <ThankviewCTA />}
        </div>
        <EmailSignaturesModal
          isOpen={signaturesModalIsOpen}
          onClose={closeSignaturesModal}
          defaultSignature={defaultSignature}
          setDefaultSignature={setDefaultSignature}
          contact={contact}
        />
      </div>
      <MissingInfoModal
        title="Review Missing Information"
        secondaryButtonLabel="Send Anyway"
        missingFields={missingFields}
        handleSubmit={handleSubmit}
        isOpen={showReviewPopup}
        isSendEmail={true}
        close={() => setShowReviewPopup(false)}
      />
    </div>
  );
};

EmailForm.propTypes = {
  taskId: PropTypes.number,
  recipients: PropTypes.array,
  handleClose: PropTypes.func,
  setDrawerTitle: PropTypes.func,
  contact: PropTypes.object,
  outreach: PropTypes.object,
  outreachType: PropTypes.string,
  handleSaveOrUpdateDraft: PropTypes.func,
  handleDeleteOutreachEmail: PropTypes.func,
  closeDrawer: PropTypes.func,
  isSavingDraftRef: PropTypes.object,
};

EmailForm.defaultProps = {
  recipient: '',
  handleSubmit: () => {},
};

export default EmailForm;
