import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import OutreachController from './controllers/outreach-controller';
import IsFlaggedController from 'components/flags/is-flagged-controller';
import ScheduledController from './controllers/scheduled-controller';
import ThreadController from './controllers/thread-controller';

class OutreachRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'outreach/email': 'email',
      'outreach/email/:id': 'scheduledEmail',
      'outreach/email/sent/:id': 'sentEmail',
      'outreach/phone': 'phone',
    };
  }

  email() {
    EverTrue.execute('setBrowserTitle', 'Email Outreach');
    this.currentView = (
      <IsFlaggedController flag="outreachDraftsScheduled">
        <OutreachController view="email" />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }

  scheduledEmail(id) {
    EverTrue.execute('setBrowserTitle', 'Email Outreach');
    this.currentView = (
      <IsFlaggedController flag="outreachDraftsScheduled">
        <ScheduledController id={id} />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }

  sentEmail(id) {
    EverTrue.execute('setBrowserTitle', 'Email Thread');
    this.currentView = (
      <IsFlaggedController flag="outreachDraftsScheduled">
        <ThreadController id={id} />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }

  phone() {
    EverTrue.execute('setBrowserTitle', 'Phone Outreach');
    this.currentView = (
      <IsFlaggedController flag="outreachDraftsScheduled">
        <OutreachController view="phone" />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

OutreachRouter.initClass();
export default OutreachRouter;
