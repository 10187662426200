import $ from "jquery";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import EverTrue from "app";
import FeatureStore from "apps/layout/stores/feature-store";
import { PropTypes } from "base/react-libs";
import { useFluxStore } from "@evertrue/et-flux";
import { useFeatureFlag } from "entities/helpers/use-feature-flag";

const featureStoreMap = () => ({
  hasNotes: FeatureStore.hasFeature("ugc_show_imported_notes"),
  hasProposals: FeatureStore.hasFeature("proposal_reads"),
});

const ProfileNav = ({ id, tab }) => {
  const { hasNotes, hasProposals } = useFluxStore(FeatureStore, featureStoreMap);
  const { flagEnabled: hasSignal } = useFeatureFlag("signal");

  const handleChangeTab = (tab) => {
    EverTrue.Navigator(`/contact/${id}/${tab}`, { trigger: true, replace: true });
    $(".contact-profile--body").scrollTop(0);
  };

  return (
    <Nav className="profile-nav profile-nav-tabs" onSelect={handleChangeTab} active={tab || "summary"}>
      <NavItem activeWhen="summary" label="Summary" />
      <NavItem activeWhen="bio" label="Constituent Info" />
      <NavItem activeWhen="enrichment" label="Enrichment" icon="et-pyramid" />
      <NavItem activeWhen="posts" label="Engagement" icon="et-pyramid" />
      <NavItem activeWhen="giving" label="Giving" />
      <NavItem activeWhen="notes" label={hasNotes ? "Interactions" : "Comments"} />
      {hasProposals && <NavItem activeWhen="proposals" label="Proposals" />}
      {hasSignal && <NavItem activeWhen="task" label="Tasks" />}
    </Nav>
  );
};

ProfileNav.propTypes = {
  id: PropTypes.number,
  tab: PropTypes.string,
};

export default ProfileNav;
