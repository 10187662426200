import { useRecorder } from './hooks/recorder-context';
import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const UploadVideo = forwardRef(({ onFileSelect, uploadVideoFromFile }, ref) => {
  const fileInputRef = useRef(null);
  const { handleUploadVideoFromFile } = useRecorder();
  const { handleChangeModalView } = useSignalVideoModalContext();

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        reject('Error loading video file');
      };

      video.src = URL.createObjectURL(file);
    });
  };

  useImperativeHandle(ref, () => ({
    triggerFileInput: () => {
      return new Promise((resolve, reject) => {
        const handleChange = async (event) => {
          const file = event.target.files[0];
          const fileName = file.name;
          const duration = await getVideoDuration(file);
          if (file) {
            try {
              onFileSelect(file);
              await handleUploadVideoFromFile(file, fileName, duration, {}, uploadVideoFromFile, () =>
                handleChangeModalView('review')
              );
              resolve(file);
            } catch (error) {
              console.error('Error uploading video', error);
              reject(error);
            } finally {
              event.target.value = '';
              if (fileInputRef.current) {
                fileInputRef.current.removeEventListener('change', handleChange);
              }
            }
          }
        };

        fileInputRef.current.addEventListener('change', handleChange, { once: true });
        fileInputRef.current.click();
      });
    },
  }));

  return (
    <input
      type="file"
      name="file"
      ref={fileInputRef}
      accept="video/webm,video/mpg,video/mpeg,video/mp4,video/x-m4v,video/avi,video/x-matroska,video/quicktime,video/*;capture=camcorder"
      aria-label="Upload a Video"
      style={{ display: 'none' }}
    />
  );
});

UploadVideo.propTypes = {
  onFileSelect: PropTypes.func,
  uploadVideoFromFile: PropTypes.func,
};

export default UploadVideo;
