module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   LoadingMixin = require("mixins/loading-mixin")
   UserStore = require("apps/users/stores/user-store")
   InviteStore = require("apps/users/stores/invite-store")
   WindowSource = require("apps/layout/sources/window-source")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FixedTable = require("components/tables/fixed-table")
   TableHeaderDropdown = require("components/tables/table-header-dropdown")
   ManageUsersTableRows = require("apps/users/components/manage-users-table-rows")


   createComponent "ManageUsersTable",
      mixins: [LoadingMixin]
      propTypes:
         loading: ReactLibs.PropTypes.bool
         active_users: ReactLibs.PropTypes.object
         invited_users: ReactLibs.PropTypes.object
         clearSearchInput: ReactLibs.PropTypes.func

      getInitialState: ->
         sortData: {sortProp: "name", sortReverse: false}

      handleSort: (key, reverse) ->
         if @depracated_is_mounted
            @setState sortData:
               sortProp: key
               sortReverse: reverse
         UserStore.sort(key, reverse)
         InviteStore.sort(key, reverse)
         WindowSource.outsideClick()

      getFilteredUsers: ->
         total_users = _.union @props.active_users.items, @props.invited_users.items
         sort_prop = @state.sortData.sortProp

         # scroll to top
         $(@refs.table_body)?.scrollTop(0)

         # sort again once active users and invited users combined
         sorted_users = _.sortBy total_users, (user) ->
            if sort_prop is "name"
               if user.is_invite then user.email.toLowerCase() else user.name.toLowerCase()
            else
               user[sort_prop]
         sorted_users.reverse() if @state.sortData.sortReverse

         if @props.user_filter is "all"
            filtered_users = sorted_users
         else if @props.user_filter is "invited"
            filtered_users = @props.invited_users.items
         else if @props.user_filter is "active"
            filtered_users = @props.active_users.items
         filtered_users

      formattedConfig: ->
         headers = _.compact _.map @props.col_keys, (key) =>
            header = _.clone(ManageUsersTableRows[key])
            return unless header
            if header.key is "actions"
               _.extend {}, header, {row: (user) => ManageUsersTableRows[key].row(user, { clearSearchInput: @props.clearSearchInput })}   
            else
               _.extend {}, header, {label: @renderColumnDropdown(header)}
         _.sortBy(headers, "sort")

      renderColumnDropdown: (col) ->
         TableHeaderDropdown
            column: col
            sort: @state.sortData
            onSort: @handleSort

      render: ->
         div className: "manage-table--fixed-wrap loading-container",
            div className: "fixed-page-wrapper",
               @loading(@props.loading)

               FixedTable
                  className: "manage-users--table has-header-dropdown"
                  data: @getFilteredUsers()
                  config: @formattedConfig()
                  emptyMessage: "No Users Found"


