import numeral from 'numeral';
import _ from 'underscore';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import KpiCard from './widget-kpi-card';
import useFetchResults from 'apps/dashboard/hooks/use-fetch-results';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

const KpiPercentWheelCard = ({
  template_json = {},
  title,
  query = '',
  variables = {},
  optionVariableName,
  options = [],
  fetchStat = () => {},
  fiscal_year,
  selectedOption = {},
  onOptionChange = () => {},
  wheel_size = 200,
}) => {
  const { result, loading } = useFetchResults(
    query,
    optionVariableName,
    template_json.has_options,
    variables,
    selectedOption.value,
    fetchStat
  );

  const total = get(result, template_json.main_stat ? template_json.main_stat.prop : '', null);
  const total_label = template_json.main_stat ? template_json.main_stat.label : null;

  const goal = get(result, template_json.sub_stat ? template_json.sub_stat.prop : '', null);
  const goal_label = template_json.sub_stat ? template_json.sub_stat.label : null;

  // percentage for percentage wheel graphic
  const percentage = total >= goal ? 100 : (total / goal) * 100;

  return (
    <KpiCard
      loading={loading}
      title={title}
      fiscal_year={fiscal_year}
      icon={template_json.icon}
      color={template_json.theme_color}
      options={options}
      onOptionChange={onOptionChange}
      selectedOption={selectedOption}
    >
      <div className="hub-kpicard--content">
        <div className="hub-kpicard--sub-content">
          <div className="hub-kpicard--main-stat">{_.isNull(total) ? '-' : total}</div>
          <div className="hub-kpicard--main-stat-label">{total_label}</div>

          <div>
            <span className="hub-kpicard--sub-stat-label">{goal_label}</span>
            <span className="hub-kpicard--sub-stat">{_.isNull(goal) ? 'N/A' : goal}</span>
          </div>
        </div>
        <div className="hub-kpicard--sub-content">
          <div style={{ width: wheel_size, height: wheel_size }}>
            <CircularProgressbarWithChildren
              value={percentage}
              strokeWidth={6}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',

                // Text size
                // textSize: "16px",

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 1,

                // Colors
                pathColor: total && goal ? `#${template_json.theme_color}` : '#f1f3f5',
                trailColor: '#f1f3f5',
              })}
            >
              <div className="hub-kpicard--percentage">{total && goal ? numeral(total / goal).format('0%') : '-'}</div>
              <span className="hub-kpicard--percentage-label">Progress</span>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </KpiCard>
  );
};

KpiPercentWheelCard.propTypes = {
  title: PropTypes.string,
  variables: PropTypes.object,
  optionVariableName: PropTypes.string,
  options: PropTypes.array,
  fetchStat: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  template_json: PropTypes.object,
  fiscal_year: PropTypes.number,
  onOptionChange: PropTypes.func,
  selectedOption: PropTypes.object,
  wheel_size: PropTypes.number,
};

export default KpiPercentWheelCard;
