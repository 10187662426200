import { PropTypes } from 'base/react-libs';
import { Card, Button } from '@evertrue/et-components';
import { useEffect, useState } from 'react';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import StepRow from 'apps/journeys/components/detail/journey-detail-table/step-view/step-row/step-row';
import StepTableEmptyState from './step-table-empty-state';
import AddJourneyTaskButton from 'apps/journeys/components/top-nav/add-journey-task-button';
import StepTableCreatorEmptyState from './step-table-creator-empty-state';

const StepTableBody = ({ steps = [] }) => {
  const {
    journey = {},
    userIsCreator,
    actionTypeFilters,
    setSelectedTasks,
    inBulkSelectMode,
    setInBulkSelectMode,
  } = useJourneyDetailProvider();

  const [groupedSteps, setGroupedSteps] = useState([]);
  useEffect(() => {
    if (steps) {
      setGroupedSteps(Object.groupBy(steps, (step) => step.day_number));
    }
  }, [steps]);

  return (
    <Card className="journey-detail-table--body">
      {steps?.length ? (
        <>
          <div className="journeys-task-table-row--header">
            <Button
              type="simple"
              onClick={() => {
                if (inBulkSelectMode) {
                  setSelectedTasks([]);
                }
                setInBulkSelectMode(!inBulkSelectMode);
              }}
            >
              {inBulkSelectMode ? 'Cancel' : 'Select Tasks'}
            </Button>
          </div>
          {groupedSteps &&
            Object.entries(groupedSteps).map(([day, steps], i) => (
              <div key={`day-${day}`}>
                <div className="journeys-task-table-row--header">Day {day}</div>
                {steps &&
                  steps.map((step, subIndex) => (
                    <StepRow
                      key={`task-${step.id}`}
                      step={step}
                      isFinalStep={!!(i === Object.entries(groupedSteps).length - 1) && subIndex === steps.length - 1} // final step of final day
                    />
                  ))}
              </div>
            ))}
          {userIsCreator && <AddJourneyTaskButton steps={steps} journeyId={journey?.id} />}
        </>
      ) : (
        <>
          {userIsCreator && !actionTypeFilters?.length ? (
            <StepTableCreatorEmptyState steps={steps} journeyId={journey?.id} />
          ) : (
            <StepTableEmptyState hasFilters={actionTypeFilters?.length} />
          )}
        </>
      )}
    </Card>
  );
};

StepTableBody.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default StepTableBody;
