import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CharacterCounter from 'apps/interactions/components/character-counter';
import { Modal, ModalHeaderWithActions, ModalBody, TextInput } from '@evertrue/et-components';
import { FolderSchema } from './schema/folder-schema';

const NewFolderModal = ({ isOpen, onClose, onSubmit }) => {
  const handleSubmit = (values) => {
    onSubmit(values.folderName);
  };

  return (
    <Modal size="small" isOpen={isOpen} closeModal={onClose} closeOnOutsideClick={true}>
      <Formik initialValues={{ folderName: '' }} validationSchema={FolderSchema} onSubmit={handleSubmit}>
        {({ handleChange, handleSubmit, values, errors, touched, isValid, dirty }) => (
          <>
            <ModalHeaderWithActions
              title="Create Folder"
              closeModal={onClose}
              onSubmit={handleSubmit}
              disableSubmit={!isValid || !dirty}
            />
            <ModalBody>
              <TextInput
                placeholder="Name Your Folder"
                name="folderName"
                value={values.folderName}
                onChange={handleChange('folderName')}
                maxLength={30}
                required={true}
                error={touched.folderName && errors.folderName}
              />
              <CharacterCounter limit={30} count={(values.folderName || '').length} />
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

NewFolderModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default NewFolderModal;
