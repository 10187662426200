import { useEffect } from 'react';
import EverTrue from 'app';
import ExportToast from 'apps/export/components/export-toast';
import ExportStore from 'apps/export/stores/export-store';
import { useFluxStore } from '@evertrue/et-flux';

const mapStateToProps = () => ({
  toasts: ExportStore.getToasts(),
});

const ExportToastController = () => {
  const { toasts } = useFluxStore(ExportStore, mapStateToProps);

  useEffect(() => {
    if (toasts && toasts.length > 0) {
      toasts.forEach((exportData) => {
        EverTrue.Alert.info(<ExportToast export_id={exportData.id} />, {
          timeout: 0,
          id: `export_${exportData.id}`,
          icon: exportData.percent_complete < 1 ? 'gt-icon-access-time' : 'gt-icon-check',
        });
      });
    }
  }, [toasts]);

  return null;
};

export default ExportToastController;
