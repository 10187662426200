import PropTypes from 'prop-types';
import classNames from 'classnames';
import HelpTip from 'components/tooltip/helptip';

const KPICard = ({ title, value, width = 25, helpTip, className, children }) => {
  return (
    <div className={classNames('kpi-card', className)} style={{ width: `${width}%` }}>
      <span className="kpi-card--title">
        {title}
        {helpTip && <HelpTip help_key={helpTip} />}
      </span>

      <h3 className="kpi-card--value fs-exclude-data">{value}</h3>

      {children}
    </div>
  );
};

KPICard.propTypes = {
  title: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  width: PropTypes.number,
  helpTip: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default KPICard;
