import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";
import EverTrue from "app";

export const getEmailSignatures = async () => {
  try {
    const res = await Api.OUTREACH.EMAIL_SIGNATURES.get();
    return res;
  } catch (e) {
    ErrorLogger.error("Error getting email signatures", { extra: { e, page_name: "Task Signatures Modal" } });
    return EverTrue.Alert.error("There was a problem getting email signature.");
  }
};

export const addEmailSignature = async (payload) => {
  const res = await Api.OUTREACH.EMAIL_SIGNATURE.post({
    data: JSON.stringify(payload),
  });
  return res;
};

export const updateEmailSignature = async (id, updatedSignature) => {
  try {
    const res = await Api.OUTREACH.UPDATE_EMAIL_SIGNATURE.patch({
      urlArgs: { id },
      data: JSON.stringify({ ...updatedSignature }),
    });
    return res;
  } catch (e) {
    ErrorLogger.error("Error updating email signature", { extra: { e, page_name: "Email Signatures Modal" } });
  }
};

export const deleteEmailSignature = async (id) => {
  try {
    const res = await Api.OUTREACH.DELETE_EMAIL_SIGNATURE.delete({
      urlArgs: { id: id },
    });
    EverTrue.Alert.success("Email Signature deleted successfully");
    return res;
  } catch (e) {
    ErrorLogger.error("Error deleting email signature", { extra: { e, page_name: "Email Signatures Modal" } });
    return EverTrue.Alert.error("There was a problem deleting email signature.");
  }
};
