/* eslint-disable react/prop-types */
import { Fragment, Component } from 'react';
import { connect } from '@evertrue/et-flux';
import classNames from 'classnames';
import moment from 'moment';
import EverTrue from 'app';
import Composer from 'react-composer';
import Decorator from 'clientDecorator';
import FixedTable from 'components/tables/fixed-table';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import _ from 'underscore';
import ModalTrigger from 'components/modals/modal-trigger';
import AppInviteModal from 'apps/volunteers/components/app-invitation-modal';
import Icon from 'components/elements/icon';
import InvitesResolver from 'apps/volunteers/components/invites-resolver';
import PoolMembershipModal from 'apps/volunteers/components/pool-membership-modal';
import ResendInvite from 'apps/volunteers/components/resend-invite';
import Pagination from 'components/controls/pagination';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import VolunteerUserResolver from 'apps/volunteers/components/volunteer-user-resolver';
import VolunteerUserSource from 'apps/volunteers/sources/volunteer-user-source';
import EllipsisOverflow from 'components/formatting/ellipsis-overflow';
import EmptyStateMessage from 'components/lists/empty-state-message';
import { SessionResolver } from 'base/session/with-session';
import { OrgResolver } from 'base/org/with-org';
import SelectedContactsCheckbox from 'apps/contact/components/contacts/select-contact-checkbox';
import SearchLink from 'components/elements/search-link';
import { Link, Button, ModalTrigger2 } from '@evertrue/et-components';
import { VMS_MANAGE_TABLE_LIMIT } from 'apps/volunteers/constants';

const mapStateToProps = () => ({
  selectAllIndicatorVisible: SelectedContactsStore.getShowSelectedIndicator(),
});
const parse_date = (date) => moment(date).format('MMM DD, YYYY');

const CONFIG = (memberships) => [
  {
    label: 'Volunteers',
    key: 'volunteers',
    sticky: true,
    width: 350,
    row: (contact) => (
      <VolunteerUserResolver
        render={({ volunteer_users }) => {
          const { [contact.id]: { email, id: user_id } = {} } = volunteer_users;
          return (
            <div className="volunteer-management-table--contact">
              <SelectedContactsCheckbox name={contact.name} id={contact.id} />
              <ContactCard contact={contact} showClassyear>
                <EllipsisOverflow>
                  <span className="text-label">{email}</span>
                </EllipsisOverflow>
                <SessionResolver
                  render={({ isSuperUser }) =>
                    isSuperUser ? (
                      <div>
                        <span className="text-label"> User ID: </span>
                        <span>{user_id}</span>
                      </div>
                    ) : null
                  }
                />
              </ContactCard>
            </div>
          );
        }}
      />
    ),
  },
  {
    label: 'Pool Membership',
    key: 'pool_membership',
    width: 200,
    row: (contact) => {
      const contact_memberships = memberships[contact.id] || [];

      const first_membership = contact_memberships[0] || {};
      return contact_memberships.length < 3 ? (
        contact_memberships.map(({ pool_name, pool_id }, index, self) => {
          return (
            <div key={pool_id || _.randomKey()}>
              <Link title={`Link to pool ${pool_name}`} href={`volunteers/pool/${pool_id}`}>
                {pool_name}
              </Link>
              {index !== self.length - 1 ? ',' : ''}
            </div>
          );
        })
      ) : (
        <Fragment>
          <Link
            title={`Link to pool ${first_membership.pool_name}`}
            href={`volunteers/pool/${first_membership.pool_id}`}
          >
            {first_membership.pool_name}
          </Link>
          <div>
            (+
            {contact_memberships.length - 1} More)
            <ModalTrigger
              title="Pool membership dialog"
              modal={<PoolMembershipModal memberships={contact_memberships} contact={contact} />}
            >
              {' '}
              View
            </ModalTrigger>
          </div>
        </Fragment>
      );
    },
  },
  {
    label: 'Assignments',
    key: 'assignments',
    width: 150,
    row: (contact) => {
      const contact_memberships = memberships[contact.id] || [];
      const count = contact_memberships.reduce((acc, cur) => (acc += cur.assignment_count), 0);

      const filters = { volunteer_assignee: { value: { value: contact.id, label: contact.name } } };
      if (count === 0) {
        return <span>0 Assignments</span>;
      }
      return (
        <SearchLink filters={filters}>
          {count} Assignment
          {count === 1 ? '' : 's'}
        </SearchLink>
      );
    },
  },
  {
    label: 'Authentication',
    key: 'authentication',
    width: 250,
    row: (contact) => (
      // eslint-disable-next-line react/jsx-key
      <Composer renderPropName="render" components={[<InvitesResolver />, <VolunteerUserResolver />, <OrgResolver />]}>
        {([{ invites }, { volunteer_users }, { org }]) => {
          const { [contact.id]: invite_for_contact = {} } = invites;
          const { [contact.id]: user_for_contact = {} } = volunteer_users;
          const { affiliation_roles = [] } = Decorator.User.getAffiliationFor(user_for_contact, org.id) || {};
          // TODO: change this for vol lead role
          const vol_role =
            affiliation_roles.filter((role = {}) => role.role_name && role.role_name.match(/^Volunteer/))[0] || {};
          const roles_without_vol_role = affiliation_roles.filter((role) => role.role_id !== vol_role.role_id);
          const deleteUser = () => {
            EverTrue.Alert.confirm(
              {
                headline: 'Remove Volunteer User?',
                content: `This will revoke this user's access to their Volunteer by EverTrue account.
                  The constituent record will remain in their volunteer pools and their assignments will not be affected.`,
              },
              (did_confirm) => {
                if (did_confirm) {
                  VolunteerUserSource.removeAsVolunteer({
                    user: user_for_contact,
                    oid: org.id,
                    roles: roles_without_vol_role,
                    vol_role,
                  });
                }
              }
            );
          };

          if (user_for_contact.id) {
            return (
              <div className="volunteer-mangement-table--auth-section">
                <div className="volunteer-mangement-table--auth-icon-wrapper">
                  <Icon className="volunteer-mangement-table--authed-icon" icon="check-circle" size={1} />
                </div>
                <div>
                  <div>Account Created</div>
                  <div>{parse_date(vol_role.created_at)}</div>
                  <Button type="simple" title="Remove volunteer access" onClick={deleteUser}>
                    Remove Volunteer Access
                  </Button>
                </div>
              </div>
            );
          } else if (invite_for_contact.id) {
            return (
              <div className="volunteer-mangement-table--auth-section">
                <div className="volunteer-mangement-table--auth-icon-wrapper">
                  <Icon icon="check-circle" size={1} className="volunteer-management-table--invite-sent-icon" />
                </div>
                <div>
                  <span>Invite Sent</span>
                  <div>{parse_date(invite_for_contact.created_at)}</div>
                  {
                    // TODO: add role later
                  }
                  <ResendInvite
                    contact={contact}
                    invite={invite_for_contact}
                    hideHeadAgent={true}
                    render={({ handleResend }) => (
                      <Button type="simple" title="Resend invite" className="plain-button--link" onClick={handleResend}>
                        Resend Invite
                      </Button>
                    )}
                  />
                </div>
              </div>
            );
          }
          return (
            <div className="volunteer-mangement-table--auth-section">
              <div className="volunteer-mangement-table--auth-icon-wrapper">
                <Icon icon="new" size={1} />
              </div>
              <ModalTrigger2
                trigger={({ open }) => (
                  <Button type="simple" onClick={open}>
                    Send Invitation
                  </Button>
                )}
              >
                {({ close }) => (
                  <AppInviteModal
                    onClose={close}
                    hideHeadAgent={true}
                    contact={contact}
                    inviteId={invite_for_contact.id}
                  />
                )}
              </ModalTrigger2>
            </div>
          );
        }}
      </Composer>
    ),
  },
  {
    label: 'Status',
    key: 'status',
    width: 250,
    row: (contact) => (
      // eslint-disable-next-line react/jsx-key
      <Composer renderPropName="render" components={[<InvitesResolver />, <VolunteerUserResolver />]}>
        {([{ invites }, { volunteer_users }]) => {
          const user = volunteer_users[contact.id] || {};
          const invite = invites[contact.id] || {};

          const { session_count = 0, last_session_time } = user;
          if (_.isEmpty(user) && _.isEmpty(invite)) return <div>---</div>;
          return (
            <div>
              {session_count} sessions
              <div className="text-label">
                <div>Most recent:</div>
                <div>{last_session_time ? moment(last_session_time).format('MM/DD/YYYY [at] h:mm a') : '-'}</div>
              </div>
            </div>
          );
        }}
      </Composer>
    ),
  },
];

class VolunteerManagementTable extends Component {
  static propTypes = {
    data: ReactLibs.PropTypes.array,
    currentPage: ReactLibs.PropTypes.number,
    totalContacts: ReactLibs.PropTypes.number,
    handlePageChange: ReactLibs.PropTypes.func,
    handleTabChange: ReactLibs.PropTypes.func,
    selectAllIndicatorVisible: ReactLibs.PropTypes.bool,

    loading: ReactLibs.PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    memberships: {},
  };

  renderEmptyState() {
    return (
      <EmptyStateMessage className="volunteer-management-table--empty" text="No Volunteers Found">
        Add volunteers to your pools or try adjusting your search.
        <div>
          <Link
            title="Manage your volunteer pools"
            onClick={() => {
              this.props.handleTabChange('pools');
              EverTrue.UrlManager.navigate(`/volunteers/pools`, true, false);
            }}
          >
            Manage Your Pools
          </Link>
        </div>
      </EmptyStateMessage>
    );
  }

  render() {
    return (
      <FixedTable
        appendSpaceColumn={false}
        loading={this.props.loading}
        className={classNames('volunteer-management-table', {
          'all-selector-visible': this.props.selectAllIndicatorVisible,
        })}
        emptyMessage={this.renderEmptyState()}
        style={{ top: this.props.selectAllIndicatorVisible ? 230 : 195 }}
        data={this.props.data}
        config={CONFIG(this.props.memberships)}
        footer={
          <Pagination
            page={this.props.currentPage}
            total={Math.ceil(this.props.totalContacts / VMS_MANAGE_TABLE_LIMIT)}
            onChange={this.props.handlePageChange}
          />
        }
      />
    );
  }
}

export default connect(VolunteerManagementTable, [SelectedContactsStore], mapStateToProps);
