import EverTrue from 'app';
import SessionSource from 'base/session/session-source';
import OrgSelector from 'apps/login/components/org-selector';
import ModalConfirm from 'components/modals/modal-confirm';
import OrgSource from 'base/org/org-source';
import resetFiltersEnvironment from 'base/reset-filters-environment';
import PropTypes from 'prop-types';

const ChangeOrganizationView = ({ affiliations, requestHide }) => {
  const user = EverTrue?.store?.user?.toJSON();

  const handleCloseModal = () => {
    if (typeof requestHide === 'function') {
      requestHide();
    }
  };

  const handleConfirmOrgChange = (org) => {
    resetFiltersEnvironment();
    const oid = org.id;

    if (!oid) {
      return EverTrue.Alert.info('Please select an organization');
    }

    return SessionSource.promise.renewSession(null, { oid }).then((session) => {
      const { type, oid: sessionOid, organization } = session;

      if (type !== 'SCOPED' || !sessionOid) {
        return EverTrue.Alert.error('Problem selecting an organization. Please contact genius@evertrue.com', {
          timeout: 10000,
        });
      }

      OrgSource.change(organization);
      return handleCloseModal;
    });
  };

  return (
    <div className="change-org-modal loading-container">
      <ModalConfirm type="accent" onCancel={handleCloseModal} hideOnSubmit={true}>
        <div className="change-org--wrap">
          <OrgSelector user={user} onChange={handleConfirmOrgChange} />
        </div>
      </ModalConfirm>
    </div>
  );
};

ChangeOrganizationView.propTypes = {
  affiliations: PropTypes.array,
  requestHide: PropTypes.func,
};

export default ChangeOrganizationView;
