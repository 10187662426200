import PropTypes from 'prop-types';
import Decorator from 'clientDecorator';
import EngagementCard from 'apps/profile/components/engagement-card';
import EngagementIcon from 'apps/profile/components/engagement-icon';
import { getVerbByEngagementType } from 'apps/events/utils/engagement-config';
import { Link } from '@evertrue/et-components';

const EngagementCardEvent = ({ event_engagement = {}, contact = {}, show_contents }) => {
  const renderAction = () => {
    return getVerbByEngagementType(event_engagement?.engagement_action);
  };

  const renderName = () => {
    const profileLink = contact.social?.profile?.public_profile_url;
    const name = Decorator.Contacts.getFullName(contact);

    return (
      <Link className="fs-exclude-data" title={`profile for ${name}`} href={profileLink || '#'} target="_BLANK">
        {name}
      </Link>
    );
  };

  const renderEventName = () => {
    const id = event_engagement?.event?.id;
    const link = id ? '/events/' + id : '#';
    return (
      <Link title="event" className="engagement-card-event--name fs-exclude-data" href={link}>
        {event_engagement?.event?.name || 'An Event'}
      </Link>
    );
  };

  const renderTitle = () => {
    return (
      <div>
        {renderName()} {renderAction()} {renderEventName()}
      </div>
    );
  };

  const { event, engaged_at, engagement_action } = event_engagement || {};
  const { description, source } = event || {};
  const icon = <EngagementIcon size="40" icon={engagement_action} network={source} />;

  return (
    <EngagementCard
      title={renderTitle()}
      contents={show_contents ? <span className="fs-exclude-data">{description}</span> : undefined}
      date={engaged_at}
      icon={icon}
    />
  );
};

EngagementCardEvent.propTypes = {
  event_engagement: PropTypes.object,
  contact: PropTypes.object,
  show_contents: PropTypes.bool,
};

export default EngagementCardEvent;
