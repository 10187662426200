import EverTrue from 'app';
import PropTypes from 'prop-types';
import ErrorLogger from 'entities/helpers/error-log-helper';
import CalendarDatePicker from 'components/calendar/calendar-date-picker';
import BulkConflictedTasks from './bulk-conflicted-tasks';
import { useEffect, useState } from 'react';
import { bulkUpdateTaskDueDates } from 'apps/journeys/utils/api-requests/modal-actions';
import { mapTasksByContactJourneyId } from 'apps/tasks/utils/tasks-helpers';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { getDateString, getDateInTimezone, getMomentObjectInTimezone } from 'base/new-utils';

// The UpdateTaskDueDateModal is used to render a modal that allows users to bulk update the due date of tasks.
const UpdateTaskDueDateModal = ({
  isOpen,
  onClose,
  tasks = [],
  optionalStateManagementFunction = () => {},
  trackAction = () => {}, // mixpanel tracking function
  selectedDueDate,
  areAllSameDueDate = true,
}) => {
  const [newDueDate, setNewDueDate] = useState(null);
  const [hasConflictingTasks, setHasConflictingTasks] = useState(false);

  const conflictingTasksDetails = mapTasksByContactJourneyId(tasks);

  useEffect(() => {
    const hasConflictingTasks = Object.values(conflictingTasksDetails).some((tasks) => tasks.length > 1);
    setHasConflictingTasks(hasConflictingTasks || false);
  }, [conflictingTasksDetails]);

  const handleSubmit = async () => {
    if (!tasks || !tasks.length) return;

    const taskIds = tasks.map((taskObj) => taskObj.id);

    try {
      if (newDueDate && tasks.length > 0) {
        const payload = {
          task_ids: taskIds,
        };
        await bulkUpdateTaskDueDates(newDueDate, payload);

        const contactIds = tasks.map((taskObj) => taskObj?.target_contact_id || taskObj?.contact?.id);
        trackAction('bulk_update_due_date');
        optionalStateManagementFunction('bulkRemoveContacts', { contactIdsToRemove: contactIds });

        onClose();
        EverTrue.Alert.success('The task due dates have been updated. Please refresh the page to continue.');
      }
    } catch (error) {
      ErrorLogger.error('Error bulk updating tasks', {
        extra: { error, page_name: 'bulkUpdateTaskDueDates' },
        tags: { taskIds, newDueDate },
      });
    }
  };

  const isSubmitDisabled = !newDueDate || tasks.length === 0 || hasConflictingTasks;

  const handleClick = (date, details) => {
    if (details?.disabled) return;
    const dateString = getDateString(date);
    setNewDueDate(dateString);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size={hasConflictingTasks ? 'large' : 'medium'}>
      <ModalHeaderWithActions
        title={'Update Due Date'}
        closeModal={onClose}
        onSubmit={handleSubmit}
        disableSubmit={isSubmitDisabled}
      />
      <ModalBody scrollable={false}>
        <div className="update-due-date-container">
          <div className="date-picker-section">
            <span className="section-label">Select New Due Date</span>
            <div style={{ margin: '10px 0 0 16px' }}>
              <CalendarDatePicker
                className="custom-calendar"
                selectedDays={
                  newDueDate
                    ? getDateInTimezone(newDueDate)
                    : selectedDueDate
                    ? getDateInTimezone(selectedDueDate)
                    : getDateInTimezone()
                }
                onDayClick={(...args) => {
                  handleClick(...args);
                }}
                disabledDays={{ before: new Date() }}
              />
            </div>
          </div>
          <div className="due-date-info">
            {areAllSameDueDate && (
              <div className="current-date">
                <h4>Current Due Date</h4>
                <span>{getMomentObjectInTimezone(selectedDueDate)?.format('dddd, MMM DD, YYYY') || '-'}</span>
              </div>
            )}
            {newDueDate && (
              <div className="updated-date">
                <h4>
                  Updated Due Date {hasConflictingTasks && <span className="updated-date-disabled">(Disabled)</span>}
                </h4>
                <span>{getMomentObjectInTimezone(newDueDate)?.format('dddd, MMM DD, YYYY') || '-'}</span>
              </div>
            )}
            <BulkConflictedTasks
              conflictingTasksDetails={conflictingTasksDetails}
              hasConflictingTasks={hasConflictingTasks}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

UpdateTaskDueDateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  optionalStateManagementFunction: PropTypes.func,
  trackAction: PropTypes.func,
  selectedDueDate: PropTypes.string,
  areAllSameDueDate: PropTypes.bool,
};

export default UpdateTaskDueDateModal;
