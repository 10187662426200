import PropTypes from 'prop-types';
import { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';

import Api from 'entities/helpers/api';
import { useIdentity } from 'base/identity-resolver';

const TasksNotificationContext = createContext(() => {});

const TasksNotificationProvider = ({ children }) => {
  const { user = { id: '' } } = useIdentity();

  const { flagEnabled: enabled, loaded } = useFeatureFlag('signal');

  const [tasksDueCount, setTasksDueCount] = useState({ assigned: 0, owned: 0, journeys: 0 });

  const getAndSetGlobalTasksDueCount = useCallback(() => {
    if (enabled && user.id) {
      const gql = String.raw;
      const query = gql`
        query ($user_id: BigInt!) {
          dueTaskCountForUser(user_id: $user_id)
        }
      `;

      Api.GRAPHQL.GRAPHQL.post({
        data: JSON.stringify({
          operationName: null,
          query: String.raw`${query}`,
          variables: { user_id: user.id },
        }),
      })
        .then(({ data: { dueTaskCountForUser } }) => {
          setTasksDueCount(dueTaskCountForUser);
          return dueTaskCountForUser;
        })
        .catch((e) => {
          throw new Error(e);
        });
    }
  }, [enabled, user.id]);

  useEffect(() => {
    getAndSetGlobalTasksDueCount();
  }, [getAndSetGlobalTasksDueCount]);

  return (
    <TasksNotificationContext.Provider
      value={{
        enabled,
        loaded,
        tasksDueCount,
        getAndSetGlobalTasksDueCount,
      }}
    >
      {children}
    </TasksNotificationContext.Provider>
  );
};

TasksNotificationProvider.propTypes = {
  children: PropTypes.any,
};

export { TasksNotificationProvider, TasksNotificationContext };
export const useTasksNotificationContext = () => useContext(TasksNotificationContext);
