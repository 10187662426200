import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const MembershipSource = createSource('MembershipSource', {
  actions: {
    fetch: true,
    fetchedForContact: true,
    fetchedTripsForContact: true,
    loading: true,
  },

  api: {
    fetch(contact_id) {
      return this.actions.fetch(contact_id);
    },

    fetchListsForContact(contact_id) {
      this.actions.loading(true);

      return Api.CONTACTS.LISTS.get({
        urlExtend: `/contact/${contact_id}`,
        disableAlerts: true,
        error: () => this.actions.loading(false),
        success: (resp) => {
          this.actions.fetchedForContact(contact_id, resp);
          return this.actions.loading(false);
        },
      });
    },

    fetchTripsForContact(contact_id) {
      this.actions.loading(true);

      return Api.VOYAGER.TRIPS.get({
        params: { contact_id },
        disableAlerts: true,
        error: () => this.actions.loading(false),
        success: (resp) => {
          this.actions.fetchedTripsForContact(contact_id, resp);
          return this.actions.loading(false);
        },
      });
    },
  },
});

export default MembershipSource;
