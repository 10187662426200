import PropTypes from 'prop-types';
import _ from 'underscore';
import EverTrue from 'app';
import { LinkButton } from '@evertrue/et-components';

const PagedColumnTableHeader = (props) => {
  const { columns, columnWidth, visibleColumns, sortData, onSort, onPrev, onNext, resizeLastColumn, beginIndex } =
    props;

  const renderPrevious = (index) => {
    if (onPrev) {
      const numColumns = _.size(columns);
      const numVisible = _.size(visibleColumns);
      const sticky = _.filter(columns, (col) => col.sticky);
      if (numColumns !== numVisible && index === _.size(sticky) - 1) {
        const atTheStart = beginIndex === 0;
        const classes = EverTrue.classSet('paged-col-table--btn', { 'is-disabled': atTheStart });

        return (
          <LinkButton title="Go to previous" className={classes} onClick={onPrev}>
            <i className="fa fa-caret-left fa-2x" />
          </LinkButton>
        );
      }
    }
    return null;
  };

  const renderNext = (index) => {
    if (onNext) {
      const numColumns = _.size(columns);
      const numVisible = _.size(visibleColumns);
      if (numColumns !== numVisible && index === numVisible - 1) {
        const atTheEnd = beginIndex === numColumns - numVisible;
        const classes = EverTrue.classSet('paged-col-table--btn', { 'is-disabled': atTheEnd });

        return (
          <LinkButton title="Go to next" className={classes} onClick={onNext}>
            <i className="fa fa-caret-right fa-2x" />
          </LinkButton>
        );
      }
    }
    return null;
  };

  const renderColumns = () => {
    return _.map(visibleColumns, (config, index) => {
      const headerClass = EverTrue.classSet(config.className, { 'is-sticky': config.sticky });
      const last_item = resizeLastColumn && index === visibleColumns.length - 1;

      const size_styles = {
        width: last_item ? 'auto' : config.width,
        minWidth: last_item && config.width !== 'auto' ? columnWidth || 175 : undefined,
        maxWidth: config.width || columnWidth || 175,
      };

      const sort = sortData || {};
      const sort_classes = EverTrue.classSet({
        'fa table-sort--icon': true,
        'fa-sort-up': sort.sortProp === config.prop && !sort.sortReverse,
        'fa-sort-down': sort.sortProp === config.prop && sort.sortReverse,
        'fa-unsorted': sort.sortProp !== config.prop,
      });

      const sortable = _.isFunction(onSort) && config.prop;

      return (
        <th className={headerClass} style={size_styles} key={config.key || config.prop || index}>
          {sortable ? (
            <LinkButton title={`Sort on ${config.label}`} onClick={() => onSort(config.prop)}>
              <span className="paged-col-table--header-label">{config.label}</span>
              <i className={sort_classes} />
              {config.help_tip}
            </LinkButton>
          ) : (
            <span className="ellipsis-overflow">{config.label}</span>
          )}
          {renderPrevious(index)}
          {renderNext(index)}
        </th>
      );
    });
  };

  return (
    <thead className="paged-col-table--header table--header">
      <tr>{renderColumns()}</tr>
    </thead>
  );
};

PagedColumnTableHeader.propTypes = {
  columns: PropTypes.array,
  columnWidth: PropTypes.number,
  visibleColumns: PropTypes.array,
  sortData: PropTypes.object,
  onSort: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  resizeLastColumn: PropTypes.bool,
  beginIndex: PropTypes.number,
};

export default PagedColumnTableHeader;
