import { useState, useEffect, useCallback } from 'react';
import { Link } from '@evertrue/et-components';
import { setPersonalVideoLogo, updateVideo, updateVideoSettings } from '../../entities/helpers/thankview-helper';
import PropTypes from 'prop-types';
import { useRecorder } from './hooks/recorder-context';
import { AccordionItem } from 'components/thankview/shared/accordian';
import ToggleSwitch from 'components/thankview/shared/toggle';
import { FileUploadWithPreview } from 'components/thankview/shared/file-upload-with-preview';
import { useIdentity } from 'base/identity-resolver';
import { formatURL, validateUrl } from './utils/url-helpers';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const ThankviewSettings = ({ setCopyIsEnabled, createCaptions, deleteCaptions, settings, setSettings }) => {
  const { clientId, selectedVideo } = useRecorder();
  const { handleChangeModalView } = useSignalVideoModalContext();
  const personalVideo = selectedVideo?.personalVideo;
  const libraryVideo = selectedVideo?.libraryVideo;
  const { org } = useIdentity();
  const [hasCC, setHasCC] = useState(!!libraryVideo?.caption_url || !!libraryVideo?.caption_processing || false);

  useEffect(() => {
    setCopyIsEnabled(validateUrl(settings.button_url) && settings.button_enabled);
  }, [setCopyIsEnabled, settings.button_enabled, settings.button_url]);

  // disabling linting because eslint thinks useCallback needs an inline function, not a nested function (debounce in this case)
  // see here: https://stackoverflow.com/questions/69830440/react-hook-usecallback-received-a-function-whose-dependencies-are-unknown-pass
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveSettings = useCallback(
    debounce(async (updatedSettings) => {
      try {
        await updateVideo(personalVideo?.id, updatedSettings, clientId.data.client_id, clientId.data.client_secret);
      } catch (error) {
        console.error('Failed to update settings:', error);
      }
    }, 600),
    [clientId]
  );

  const handleFileSelect = async (file) => {
    try {
      if (file) {
        await setPersonalVideoLogo(file, personalVideo.id, clientId.data.client_id, clientId.data.client_secret);
        setSettings((prev) => {
          settings.logo_url = file ? URL.createObjectURL(file) : '';
          return { ...prev, logo_url: settings.logo_url };
        });
      } else {
        updateVideoSettings(personalVideo?.id, { logo: null }, clientId.data.client_id, clientId.data.client_secret);
      }
    } catch (error) {
      console.error('Failed to upload logo:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedSettings = { ...settings, [name]: value };
    setSettings((prev) => updatedSettings);
    if (name === 'button_url' && personalVideo.button_enabled) {
      if (validateUrl(value)) {
        debouncedSaveSettings({ ...updatedSettings, button_url: formatURL(value) });
      }
    } else {
      debouncedSaveSettings(updatedSettings);
    }
  };

  const handleToggle = (setting) => {
    const updatedSettings = { ...settings };
    if (setting === 'button_enabled' && settings.button_enabled) {
      updatedSettings.button_enabled = false;
      updatedSettings.show_in_email = false;
    } else {
      updatedSettings[setting] = !settings[setting];
    }
    setSettings(updatedSettings);
    debouncedSaveSettings(updatedSettings);
  };

  const handleHasCC = () => {
    setHasCC(!hasCC);
    if (hasCC) {
      deleteCaptions();
    } else {
      createCaptions();
    }
  };

  return (
    <div className="settings--accordion-body">
      <div className="settings--accordion-container">
        <div>
          <AccordionItem title="Landing Page Settings">
            <ToggleSwitch
              label="Show Logo on Landing Page"
              checked={settings.logo_enabled}
              onChange={() => handleToggle('logo_enabled')}
            />
            {settings?.logo_enabled && (
              <FileUploadWithPreview
                businessId={org.id}
                clientId={Number(clientId.data.client_id)}
                clientSecret={clientId.data.client_secret}
                onFileSelect={handleFileSelect}
                initialImageUrl={settings.logo_url}
              />
            )}
          </AccordionItem>

          <AccordionItem title="Call-To-Action Button Settings">
            <ToggleSwitch
              label="Toggle Call-To-Action Button"
              checked={settings.button_enabled}
              onChange={() => handleToggle('button_enabled')}
            />
            <input
              type="text"
              id="button_text"
              name="button_text"
              value={settings.button_text || ''}
              onChange={handleInputChange}
            />
            <input
              type="text"
              id="button_url"
              name="button_url"
              value={settings.button_url || ''}
              onChange={handleInputChange}
              placeholder="https://www.example.com"
            />
            <p
              className={
                !validateUrl(settings.button_url) && settings.button_enabled
                  ? 'url-warning'
                  : 'url-warning url-warning--hidden'
              }
            >
              Please enter a valid URL
            </p>
            <ToggleSwitch
              label="Show Button in Email"
              checked={settings.show_in_email}
              onChange={() => handleToggle('show_in_email')}
            />
          </AccordionItem>

          <AccordionItem title="Email Settings">
            <ToggleSwitch
              label="Animated Thumbnail"
              checked={!!settings.thumbnail_type}
              onChange={() => handleToggle('thumbnail_type')}
            />
          </AccordionItem>
          <AccordionItem title="Video Settings">
            <ToggleSwitch label="Enable Closed Captions on Video" checked={hasCC} onChange={handleHasCC} />
          </AccordionItem>
        </div>
        <div className="settings--change-defaults">
          <Link onClick={() => handleChangeModalView('settings')} title="change defaults">
            Change Defaults
          </Link>
        </div>
      </div>
    </div>
  );
};

ThankviewSettings.propTypes = {
  setCopyIsEnabled: PropTypes.func,
  createCaptions: PropTypes.func,
  deleteCaptions: PropTypes.func,
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};

export default ThankviewSettings;
