import { useState } from 'react';
import { useTasksNotificationContext } from 'apps/tasks/hooks/tasks-notification-context.js';

/**
 * This hook is used to manage the state of the steps table on the Journey Detail page.
 * The only reason we are making it a "hook" at all is:
 * 1. There is a lot of state to manage
 * 2. we need to be able to modify it from multiple components (Modals, Action Menus, etc.)
 *
 * The ONLY job of this hook is to manage the state of the steps table. This includes:
 * 1. what steps are displayed
 * 2. the tasks within those steps
 * 3. the bulk actions that can be taken on those tasks
 *
 * Generally speaking, this is not really what a "hook" is for, but it is a good way to abstract away a lot of this messy logic.
 * This hook is used in the Journey Detail Context.
 *
 */
const useSteps = () => {
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();

  // Steps
  const [steps, setSteps] = useState([]);
  const [loadingSteps, setLoadingSteps] = useState(false);

  // Bulk Task Selection
  const [selectedTasks, setSelectedTasks] = useState([]); // used for bulk actions
  const [inBulkSelectMode, setInBulkSelectMode] = useState(false);

  // STATE MANAGEMENT FUNCTIONS

  // Task
  const handleCompletionToggle = (step, task, completed_at) => {
    task.completed_at = completed_at;
    handleUpdateTask(task, step);
    getAndSetGlobalTasksDueCount();
  };

  const handleBulkTaskCompleToggle = (taskIds, isComplete) => {
    if (!taskIds || taskIds.length === 0) return;
    const stepsCopy = [...steps];
    stepsCopy.forEach((step) => {
      step.tasks.forEach((task) => {
        if (taskIds.includes(task.id)) {
          if (!isComplete) {
            task.completed_at = null;
          } else {
            const todayDate = new Date();
            task.completed_at = todayDate.getTime();
          }
        }
      });
    });

    setSteps(stepsCopy);
    setSelectedTasks([]);
    getAndSetGlobalTasksDueCount();
    setInBulkSelectMode(false);
  };

  const handleBulkRemoveTasks = (taskIds) => {
    const stepsCopy = [...steps];

    const newSteps = stepsCopy.map((step) => {
      const updatedTasks = step.tasks.filter((task) => !taskIds.includes(task.id));
      return { ...step, tasks: updatedTasks };
    });

    setSteps(newSteps);
    getAndSetGlobalTasksDueCount();
    setSelectedTasks([]);
    setInBulkSelectMode(false);
  };

  const handleRemoveTask = (task, step) => {
    const stepsCopy = [...steps];
    const stepCopy = stepsCopy.find((s) => s.id === step.id); // find correct step
    const replaceStepIndex = stepsCopy.findIndex((s) => s.id === step.id); // this is the step to update in-place
    const { tasks } = stepCopy;
    const filtered = tasks.filter((t) => !(t.id === task.id));
    stepCopy.tasks = filtered;
    steps[replaceStepIndex] = stepCopy;
    setSteps(stepsCopy);
    getAndSetGlobalTasksDueCount();
  };

  const handleUpdateTask = (updatedTask, step) => {
    const stepsCopy = [...steps];
    const stepCopy = stepsCopy.find((j) => j.id === step.id); // find correct step
    const replaceStepIndex = stepsCopy.findIndex((s) => s.id === step.id); // this is the step to update in-place
    const { tasks } = stepCopy;
    const replaceTaskIndex = tasks.findIndex((t) => t.id === updatedTask.id); // this is the task to update in-place
    tasks[replaceTaskIndex] = updatedTask;
    stepCopy.tasks = tasks;
    steps[replaceStepIndex] = stepCopy;
    setSteps(stepsCopy);
    getAndSetGlobalTasksDueCount();
  };

  const handleRemoveContact = (contactIdToRemove) => {
    const stepsCopy = [...steps];

    // Filter out Contact
    const filteredSteps = stepsCopy.map((step) => {
      const { tasks = [] } = step;
      const filtered = tasks.filter(({ contact }) => !(contact.id === contactIdToRemove));
      step.tasks = filtered;
      return step;
    });

    setSteps(filteredSteps);
    getAndSetGlobalTasksDueCount();
  };

  const handleBulkRemoveContacts = (contactIdsToRemove) => {
    if (!contactIdsToRemove || contactIdsToRemove.length === 0) return;
    const stepsCopy = [...steps];

    // Filter out Contacts
    const filteredSteps = stepsCopy.map((step) => {
      const { tasks = [] } = step;
      const filtered = tasks.filter(({ contact }) => !contactIdsToRemove.includes(contact.id));
      step.tasks = filtered;
      return step;
    });

    setSteps(filteredSteps);
    getAndSetGlobalTasksDueCount();
    setSelectedTasks([]);
    setInBulkSelectMode(false);
  };

  // Steps
  const createStep = (journeyTask) => {
    const stepsCopy = [...steps];
    stepsCopy.push(journeyTask);
    setSteps(stepsCopy.sort((a, b) => a.step_number - b.step_number));
  };

  const editStep = (journeyTask) => {
    const stepsCopy = [...steps];
    const indexToReplce = stepsCopy.findIndex((j) => j.id === journeyTask.id);
    const existingJourneyTask = stepsCopy[indexToReplce];
    journeyTask.tasks = existingJourneyTask.tasks || [];
    stepsCopy[indexToReplce] = journeyTask;
    setSteps(stepsCopy.sort((a, b) => a.step_number - b.step_number));
  };

  const deleteStep = (journeyTask) => {
    const stepsCopy = [...steps];
    const indexToDelete = stepsCopy.findIndex((j) => j.id === journeyTask.id);
    stepsCopy.splice(indexToDelete, 1);
    setSteps(stepsCopy);
  };

  // We pass this function as a prop to the table
  // it is used by the modals to update the state of the table
  // we flag it as optional, which allows us to use the modals elsewhere
  // without needing to worry about managing the state of a component.
  // All state management should pass through this table; we should not pass dozens of functions as props. just this one.
  const optionalStateManagementFunction = (type = '', args = {}) => {
    switch (type) {
      case 'removeContact':
        handleRemoveContact(args.contactIdToRemove);
        break;
      case 'removeTask':
        handleRemoveTask(args.task, args.step);
        break;
      case 'updateTask':
        handleUpdateTask(args.task, args.step);
        break;
      case 'completionToggle':
        handleCompletionToggle(args.step, args.task, args.completed_at);
        break;
      case 'createStep':
        createStep(args.journeyTask);
        break;
      case 'editStep':
        editStep(args.journeyTask);
        break;
      case 'deleteStep':
        deleteStep(args.journeyTask);
        break;
      case 'bulkRemoveTasks':
        handleBulkRemoveTasks(args.taskIds);
        break;
      case 'bulkCompleteTasks':
        handleBulkTaskCompleToggle(args.taskIds, args.isComplete);
        break;
      case 'bulkRemoveContacts':
        handleBulkRemoveContacts(args.contactIdsToRemove);
        break;
      default:
        break;
    }
  };

  return {
    steps,
    setSteps,
    loadingSteps,
    setLoadingSteps,
    selectedTasks,
    setSelectedTasks,
    inBulkSelectMode,
    setInBulkSelectMode,
    optionalStateManagementFunction,
  };
};

export default useSteps;
