import { PageHeader, Button, Icon, Link } from '@evertrue/et-components';
import { Loading } from '@evertrue/et-components';
import useEmailer from 'components/emailer/hooks/use-emailer';
import GoogleDisclosure from 'components/emailer/google-disclosure';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const ProfileEmailerController = () => {
  const { loading, emailerIsConnected, handleConnectEmail, handleDisconnectEmail } = useEmailer();
  const EMAIL_HELP_URL = getLinkUrl('everTrue', 'helpCenterUrl');

  if (loading) return <Loading spinner_size="small" />;
  return (
    <div style={{ maxWidth: '535px' }}>
      <PageHeader title="Email Outreach Account" className="outreach-settings-header" />
      <p>
        Connect your email to streamline interactions, centralize templates, and start with AI-generated email drafts
        for faster, personalized messaging. To learn more, visit the&nbsp;
        <Link href={EMAIL_HELP_URL} title="All About: Signal Email" target="_blank" rel="noopener noreferrer">
          All About: Signal Email
        </Link>
        &nbsp; page in our help center.
      </p>
      {emailerIsConnected ? (
        <div className="profile--email-status">
          <div>
            <Icon icon="check-circle" className="email-status-check" />
            <strong>Account connected!</strong>
          </div>
          <Button type="secondary" onClick={handleDisconnectEmail}>
            Disconnect
          </Button>
        </div>
      ) : emailerIsConnected !== null ? (
        <div className="profile--email-status">
          <em>Connect your account</em>
          <Button type="secondary" onClick={handleConnectEmail}>
            Connect
          </Button>
        </div>
      ) : (
        <h2>There was a problem fetching your credentials.</h2>
      )}
      <div className="email-disconnect-alert">
        Your Email account will be disconnected every 31 days for security reasons. Please reconnect to continue using
        your email in EverTrue.
      </div>
      <GoogleDisclosure style={{ marginTop: '20px' }} />
    </div>
  );
};

export default ProfileEmailerController;
