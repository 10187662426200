import HomepageCard from '../../homepage-card';
import useFeaturedLinks from 'apps/filters/hooks/use-featured-links';
import { HOME_PAGE_NEXT_STEP_FILTERS } from 'apps/filters/featured-segments-default';

const SavedSearches = () => {
  const featuredLinks = useFeaturedLinks(HOME_PAGE_NEXT_STEP_FILTERS);
  return (
    <>
      <h1 className="homepage-card-title">Build a Strong Portfolio</h1>
      <HomepageCard
        leftHeading={
          <span className="homepage-recommendations-card-left">
            Never miss a prospect with Saved Searches!
            <span className="homepage-recommendations-card-subtitle">
              These are some favorite lists from around the advancement organization to get you started. Save a search
              and sign up to receive email and push notifications when a new constiuent meets your criteria.
            </span>
          </span>
        }
        body={<div className="segments-page--empty-featured">{featuredLinks}</div>}
      />
    </>
  );
};

export default SavedSearches;
