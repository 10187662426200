import _ from "underscore";
import PropTypes from "prop-types";
import EverTrue from "app";
import { useContext, useEffect } from "react";
import { connect } from "@evertrue/et-flux";
import { Icon, Popover2, ModalTrigger2, AdvancedCombobox, Button } from "@evertrue/et-components";
import AddProspectsToTeamModal from "apps/portfolio-performance/components/add-prospects-to-team-modal";
import SortDropdown from "components/controls/sort-dropdown";
import SelectedItemsToolbar from "components/controls/selected-items-toolbar";
import ContactAddTo from "apps/contact/components/contacts/table/contact-add-to";
import BulkAddStore from "apps/layout/stores/bulk-add-store";
import { SORT_OPTIONS } from "apps/portfolio-performance/portfolio-utils";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import SolicitorDropdown from "apps/portfolio-performance/components/solicitor-dropdown";
import ContactAssignmentContext from "apps/portfolio-performance/contexts/contact-assignment-context";
import MapStore from "apps/map/stores/map-store";
import { useGate } from "components/is-gated";
import { useIdentity } from "base/identity-resolver";
import ProspectSelectionContext from "apps/portfolio-performance/contexts/prospect-selection-context";
import colors from "base/colors";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const mapStateToProps = () => ({
  bulkAddInProgress: BulkAddStore.getIsBulkAddProgress(),
  bounding_box: MapStore.getBoundingBox(),
  selected_contacts: SelectedContactsStore.getSelectedContacts(),
  allSelected: SelectedContactsStore.getIsAllSelected(),
});

const propTypes = {
  tab: PropTypes.string,
  onChangeTab: PropTypes.func,
  // from store
  bounding_box: PropTypes.object,
  bulkAddInProgress: PropTypes.bool,
  allSelected: PropTypes.bool,
  selected_contacts: PropTypes.array,
};

const PortfolioToolbar = (props) => {
  const { tab, onChangeTab } = props;
  const {
    team,
    teams,
    selectTeam,
    selectSolicitor,
    solicitor = {},
    solicitors,
    stages,
    total,
    sort_prop,
    sort_reverse,
    setSort,
  } = useContext(PortfolioMetaDataContext);
  const { fetchContactsByStage, clearProspectsWithAssignments, prospects_with_assignments_by_stage } =
    useContext(ContactAssignmentContext);
  const {
    selected_count,
    selection_query,
    areColumnsFullySelected,
    selected_columns,
    onSelectAll,
    clearSelectionData,
    selected_map_stages,
    setSelectedMapStages,
  } = useContext(ProspectSelectionContext);

  const contactIds = Object.values(prospects_with_assignments_by_stage).reduce((acc, stage) => {
    stage.forEach((prospect) => acc.push(prospect.id));
    return acc;
  }, []);

  useEffect(() => {
    setSelectedMapStages([]);
  }, [setSelectedMapStages, team]);

  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [can_view_other_portfolios] = useGate("rm_assignments_view_others");
  const [can_view_other_portfoliosDXO] = useGate("dxo_assignments_view_others");
  const { isOwner, affiliation, isSuperUser } = useIdentity();

  const user_contact_id = affiliation ? affiliation.contact_id : null;
  const isCurrentSolicitor = solicitor.id === user_contact_id;

  // selection values
  let column_has_selections = false;
  Object.values(selected_columns).forEach((selection_object) => {
    if (selection_object.all || !_.isEmpty(selection_object.ids)) {
      column_has_selections = true;
    }
  });

  // all_selected and partial_selected is different depending if on map view or stage view
  let all_selected = false;
  let partial_selected = false;
  if (tab === "stage") {
    all_selected = areColumnsFullySelected(selected_columns);
    partial_selected = !all_selected && column_has_selections;
  } else {
    // uses values from selectedcontatsstore
    all_selected = props.allSelected;
    partial_selected = !!props.selected_contacts.length && props.selected_contacts.length < total;
  }

  const isPopoverDisabled = (!partial_selected && !all_selected) || props.bulkAddInProgress;

  return (
    <div className="contact-table-toolbar--actions">
      <div className="portfolio-performance--toolbar">
        {/* Add to List */}
        <SelectedItemsToolbar
          checked={all_selected || partial_selected}
          partial={partial_selected}
          onChange={(is_selected) => {
            SelectedContactsSource.toggleSelection(contactIds, is_selected);
            onSelectAll(is_selected, tab, props.bounding_box);
          }}
          disabled={!total}
          style={{ padding: "8px" }}
        >
          <div className="addlist-popover">
            <Popover2
              borderColor={colors.blue300}
              wrapperClassName="add-list--popover-wrap"
              placement="bottom-start"
              disabled={isPopoverDisabled}
              trigger={({ openPopover }) => {
                return (
                  <Button
                    type="simple"
                    className={`selected-contacts-addto--btn ${isPopoverDisabled ? "is-disabled" : null}`}
                    onClick={!isPopoverDisabled ? openPopover : () => {}}
                  >
                    Add to...
                    <Icon icon="chevron-down" />
                  </Button>
                );
              }}
            >
              {({ closePopover }) => (
                <ContactAddTo
                  hideVMS={true}
                  hideTrips={false}
                  totalResultsCount={selected_count}
                  filterParams={selection_query}
                  allSelected
                  onlyUseQuery // force this component to always use the filter param
                  closePopover={closePopover}
                  contactIds={props.selected_contacts}
                />
              )}
            </Popover2>
          </div>
        </SelectedItemsToolbar>

        {/* Team Dropdown */}
        <AdvancedCombobox
          label="Team name"
          placeholder="Select team..."
          className="portfolio-performance--solicitor-dropdown"
          options={_.map(teams, (team) => {
            return { value: team.id, label: team.name };
          })}
          value={{ value: team.id }}
          onChange={(selection) => {
            clearProspectsWithAssignments();
            selectTeam(selection);
            EverTrue.UrlManager.navigate(`/portfolio-performance/portfolios/${selection.value}`, true, true);
          }}
          searchable={true}
          formatSelection={(selectionText) => {
            return (
              <span style={{ color: "#454545" }}>
                {selectionText.length > 20 ? selectionText.substring(0, 20).concat("....") : selectionText}
              </span>
            );
          }}
        />

        {/* Solicitor Dropdown */}
        {((can_view_other_portfolios && assignmentType === "TEAM") ||
          (can_view_other_portfoliosDXO && assignmentType === "DXO") ||
          isOwner ||
          isSuperUser) &&
        team.id ? (
          <div className="portfolio-performance--solicitor-dropdown">
            <SolicitorDropdown
              label="Solicitor name"
              placeholder={solicitor.name}
              value={{ value: solicitor.id }}
              onChange={(selection) => {
                SelectedContactsSource.clearAllSelectedState();

                selectSolicitor(selection);
                EverTrue.UrlManager.navigate(
                  `/portfolio-performance/portfolios/${team.id}/solicitor/${selection.value}`,
                  true,
                  true
                );
              }}
              multiple={false}
              allowChangeAll={false}
              allowUnselectAll={false}
              poolIds={[team.id]}
            />
          </div>
        ) : (
          <AdvancedCombobox
            label="Solicitor name"
            placeholder="Select a solicitor..."
            className="portfolio-performance--solicitor-dropdown"
            options={[{ value: solicitor.id, label: solicitor.name }]}
            value={{
              value: solicitor.id,
            }}
            onChange={_.noop}
            disabled={_.isEmpty(solicitor)}
            formatSelection={(selectionText) => {
              return (
                <span style={{ color: "#454545" }}>
                  {selectionText.length > 20 ? selectionText.substring(0, 20).concat("....") : selectionText}
                </span>
              );
            }}
          />
        )}

        {/* Add prospects to the portfolio 
          Can add if I'm an owner, or a super user, or self selection is on and it's my portfolio
          and there's some solicitors to assign to*
        */}
        {!_.isEmpty(solicitors) &&
        (isOwner || isSuperUser || (team.selection_mode === "SOLICITOR_SELECTION" && isCurrentSolicitor)) ? (
          <ModalTrigger2
            trigger={({ open }) => {
              return (
                <Button type="secondary" onClick={open} className="portfolio-performance--add-prospects">
                  <Icon title="add prospects" icon="group-add" size={1} />
                </Button>
              );
            }}
          >
            {({ close }) => {
              return (
                <AddProspectsToTeamModal
                  stages={stages}
                  close={close}
                  team={team}
                  solicitor={solicitor}
                  refreshData={(stage) => fetchContactsByStage({ stage: stage })}
                />
              );
            }}
          </ModalTrigger2>
        ) : null}

        {/* Sort dropdown only on stage view */}
        {tab === "stage" && (
          <div className="portfolio-performance--sort-section">
            <SortDropdown
              className="portfolio-performance--sort"
              options={SORT_OPTIONS}
              sortProp={sort_prop}
              sortReverse={sort_reverse}
              onSort={setSort}
            />
          </div>
        )}

        {/* Stage Filter on Map Viww */}
        {tab === "map" && (
          <div className="portfolio-performance--sort-section">
            <AdvancedCombobox
              label="Prospect stage"
              placeholder="Select stage..."
              options={_.map(stages, (stage) => ({
                value: stage,
                label: stage,
              }))}
              value={_.map(selected_map_stages, (stage) => ({
                value: stage,
                label: stage,
              }))}
              multiple
              onChange={(val) => {
                setSelectedMapStages(_.pluck(val, "value"));
              }}
              allowChangeAll
              allowUnselectAll
            />
          </div>
        )}

        {tab === "stage" ? (
          <Button
            className="portfolio-performance--map-toggle"
            type="simple"
            onClick={() => {
              onChangeTab("map");
              clearSelectionData();
            }}
          >
            <Icon icon="map gt-icon-lg" />
            Map
          </Button>
        ) : (
          <Button
            className="portfolio-performance--map-toggle"
            type="simple"
            onClick={() => {
              onChangeTab("stage");
              clearSelectionData();
            }}
          >
            <Icon icon="columns gt-icon-lg" />
            Stage
          </Button>
        )}
      </div>
    </div>
  );
};

PortfolioToolbar.propTypes = propTypes;

export default connect(PortfolioToolbar, [BulkAddStore, MapStore, SelectedContactsStore], mapStateToProps);
