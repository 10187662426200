import Gated from 'components/gated';
import BannerNotice from 'components/banner-notice';
import { Link } from '@evertrue/et-components';

const FacebookSodaMessage = () => {
  return (
    <Gated feature="facebook_soda_message">
      <BannerNotice icon="exclamation-circle" className="facebook-soda-notice">
        Facebook post collection and constituent matching is currently delayed by several days. We are working to remedy
        the situation now. Please check our{' '}
        <Link href="https://evertrue.statuspage.io" title="View Status Page" target="_blank">
          status page
        </Link>{' '}
        for updates.
      </BannerNotice>
    </Gated>
  );
};

export default FacebookSodaMessage;
