module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   CoreSearchConfig = require("apps/core-search/utils/core-search-config")
   GateSource = require("apps/layout/sources/gate-source")


   createStore "CoreSearchStore",
      getInitialState: ->
         active_type: "contacts"
         available_types: ["contacts"]
         active_result_counts: {}

      registerActions: ->
         @on CoreSearchSource.actions.setType, @respondToSetType
         @on CoreSearchSource.actions.setResultCount, @respondToSetResultCount
         @on GateSource.actions.fetchedGates, @respondToGates

      respondToSetType: (type) ->
         @setState {active_type: type}

      respondToSetResultCount: (type, count) ->
         cached_counts = @getState("active_result_counts")
         counts = _.extend {}, _.cloneData(cached_counts), _.toObject(type, count)
         if !_.isEqual(counts, cached_counts)
            @setState {active_result_counts: counts}

      respondToGates: ->
         available_types = []
         _.each CoreSearchConfig, (config, key) ->
            if !config.is_available || config.is_available()
               available_types.push key
         @setState {available_types: available_types}

      api:
         getConfig: ->
            _.cloneData(CoreSearchConfig)

         getActiveLabel: ->
            @getConfig()[@getActiveQueryType()]?.label || "constituents"

         getActiveQueryType: ->
            @getState("active_type")

         getActiveResultsCount: ->
            @getState("active_result_counts")[@getActiveQueryType()]

         getAvailableSearchTypes: ->
            @getState("available_types")

         getActiveTypeReferrer: ->
            @getConfig()[@getActiveQueryType()]?.referrer || "contact"
