import PropTypes from 'prop-types';
import { Icon, ActionsMenu, Button } from '@evertrue/et-components';
// import ThankviewRecorder from "./thankview-recorder";
// import { RecorderProvider } from "./hooks/recorder-context";
// import { ModalProvider, useModal } from "./hooks/modal-context";
import { useState } from 'react';
import { useRecorder } from '../hooks/recorder-context';
import VideoPreviewModal from './video-preview';
import handleCopy from '../utils/copy-video';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const VideoEntry = ({ video, libraryVideo, deleteVideoEntry, isSelected, onToggleSelection }) => {
  const { setSelectedVideo, stopAndResetRecorder } = useRecorder();
  const { handleCloseThankviewModal, handleChangeModalView } = useSignalVideoModalContext();
  const createdDateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const handleCopyAndClose = async (video, libraryVideo) => {
    handleCloseThankviewModal(stopAndResetRecorder);
    await handleCopy(video, libraryVideo);
  };
  const createdDate = new Date(video.created_at).toLocaleDateString('en-US', createdDateOptions);
  const [openModal, setOpenModal] = useState(false);

  const closePreviewModal = () => {
    setOpenModal(false);
  };

  const shareOption = [
    {
      id: 1,
      label: 'Copy Link',
      onClick: () => handleCopyAndClose(video, libraryVideo),
    },
  ];

  const renderThumbnail = () => {
    if (!libraryVideo) {
      return (
        <>
          <div className="tv-video--thumbnail tv-video--empty">
            <p className="tv-video--empty-text">No Video Attached</p>
          </div>
        </>
      );
    }

    // Format video length to MM:SS format
    const formatVideoLength = (seconds) => {
      if (seconds == null) return '00:00';
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const formattedVideoLength = formatVideoLength(libraryVideo.video_length);

    return (
      <>
        {libraryVideo.caption_url && (
          <Icon icon="closed-captioning" iconKit="tv" className="tv-video-preview--cc-icon" />
        )}
        <img src={libraryVideo.video_thumb} alt={`thumbnail for ${video.title}`} className="tv-video--thumbnail" />
        <Icon
          className="thumbnail-play-icon"
          icon="play-fa"
          iconKit="tv"
          onClick={() => {
            setOpenModal(true);
          }}
        />
        <div className="thumbnail-video-length">{formattedVideoLength}</div>
      </>
    );
  };

  return (
    <>
      {openModal && (
        <VideoPreviewModal
          isOpen={openModal}
          onClose={closePreviewModal}
          videoUrl={libraryVideo.video_path}
          videoName={video.title}
          captionsUrl={libraryVideo.caption_url}
        />
      )}
      {/* <input
        style={{ margin: "12px" }}
        type="checkbox"
        id={`video-checkbox-${video.id || video.title}`}
        name={`video-checkbox-${video.id || video.title}`}
        value="video"
        checked={isSelected}
        onChange={onToggleSelection}
      /> */}
      <div className="tv-video-thumbnail-container">{renderThumbnail()}</div>
      <div className="tv-video--info">
        <p className="tv-video--name">{video.name}</p>
        <p className="tv-video--metrics">{createdDate}</p>
        <p className="tv-video--metrics">
          {video.views}
          {' Views | '}
          {video.cta_clicks}
          {' CTA Interactions'}
        </p>
      </div>
      <div className="tv-video--action">
        <Button
          type="secondary"
          onClick={() => {
            setSelectedVideo({ personalVideo: video, libraryVideo });
            handleChangeModalView('review');
          }}
        >
          View
        </Button>
        <ActionsMenu
          options={shareOption}
          triggerClassName={'tv-video-action--icon'}
          trigger={() => <Icon icon="share" iconKit="gt" />}
        ></ActionsMenu>
        <Icon
          icon="trash"
          onClick={() => {
            deleteVideoEntry(video.id);
          }}
        ></Icon>
      </div>
    </>
  );
};

VideoEntry.propTypes = {
  video: PropTypes.object,
  libraryVideo: PropTypes.object,
  deleteVideoEntry: PropTypes.func,
  isSelected: PropTypes.bool,
  onToggleSelection: PropTypes.func,
};

export default VideoEntry;
