import GiftTransactionFiltersBlock from 'apps/filters/gift-transaction-filter/gift-transactions-filters-block';
import GiftTransactionParamSelector from 'apps/filters/gift-transaction-filter/gift-transactions-param-selector';
import GiftTransactionsFiltersUtils from 'apps/filters/gift-transaction-filter/gift-transactions-filters-utils';

const GiftTransactionConfig = {
  help: 'gift_transaction_filters',

  component(input, state, onChange) {
    return <GiftTransactionParamSelector input={input} state={state} onChange={onChange} />;
  },

  sub_component(input, state, onChange) {
    const param = input?.value?.param;
    if (param && ['must', 'must_not'].includes(param)) {
      return <GiftTransactionFiltersBlock input={input} state={state} onChange={onChange} />;
    }
    return null;
  },

  parse_fn(criteria) {
    return GiftTransactionsFiltersUtils.parseCriteria(criteria);
  },

  query_fn(value) {
    return GiftTransactionsFiltersUtils.getQuery(value);
  },
};

export default GiftTransactionConfig;
