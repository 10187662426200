import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import ExportsController from 'apps/export/controllers/exports-controller';

class ExportRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'data/exports': 'exports',
    };
  }

  exports() {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'Recent Exports');
    EverTrue.track.setReferrer('recent_exports');
    this.currentView = <ExportsController />;
    EverTrue.page.mount(this.currentView);
  }
}

ExportRouter.initClass();
export default ExportRouter;
