import { Link } from '@evertrue/et-components';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

export const getJourneyEmptyState = (onlyJourneysWithStepsDue, selectedTab, handleTab) => {
  if (onlyJourneysWithStepsDue) {
    return {
      text: "You've finished all your cadence steps due today! Need more ideas for outreach?",
      icon: 'check',
      subtext: (
        <>
          Check out the{' '}
          <Link href={getLinkUrl('everTrue', 'blogUrl')} title="evertrue-blog" target="_blank">
            EverTrue blog
          </Link>{' '}
          for the latest inspiration and best practices.
        </>
      ),
      hideIcon: false,
      iconClassName: 'todays-due-empty-state',
    };
  }

  switch (selectedTab.label) {
    case 'Your Cadences':
      return {
        text: 'You aren’t currently associated with any cadence categories.',
        icon: 'cadences',
        iconKit: 'gt2',
        subtext: (
          <>
            Ask your admin to add you to a team or check out the full list of available cadences{' '}
            <Link
              href="#"
              title="cadences-link"
              onClick={(e) => {
                e.preventDefault();
                handleTab(3);
              }}
            >
              here
            </Link>
            .
          </>
        ),
        hideIcon: false,
      };
    case 'Your Favorites':
      return {
        text: "You don't currently have any favorite cadences.",
        icon: 'cadences',
        iconKit: 'gt2',
        subtext: (
          <>
            Check out the full list{' '}
            <Link
              href="#"
              title="favorites-link"
              onClick={(e) => {
                e.preventDefault();
                handleTab(3);
              }}
            >
              {' '}
              here{' '}
            </Link>{' '}
            and star those for which you'd like easy access!
          </>
        ),
        hideIcon: false,
      };
    case 'All Cadences':
      return {
        text: 'Your organization does not have any cadences configured.',
        icon: 'cadences',
        iconKit: 'gt2',
        hideIcon: false,
      };
    default:
      return {
        text: 'No data available.',
        icon: 'search',
      };
  }
};
