import { useOpenClose, Button } from '@evertrue/et-components';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import PropTypes from 'prop-types';
import JourneyTaskModal from '../modals/journey-task-modal';

const AddJourneyTaskButton = ({ steps = [], journeyId }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const { optionalStateManagementFunction } = useJourneyDetailProvider();

  return (
    <>
      <Button type="simple" onClick={openModal} className="add-step-button">
        + Add Step
      </Button>
      <JourneyTaskModal
        isOpen={isModalOpen}
        onClose={closeModal}
        steps={steps}
        journeyId={journeyId}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    </>
  );
};

AddJourneyTaskButton.propTypes = {
  steps: PropTypes.array,
  journeyId: PropTypes.number,
};

export default AddJourneyTaskButton;
