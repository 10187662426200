module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div } = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory require("apps/contact/components/contact-card/contact-card")
   ContactBusiness = createFactory require("apps/contact/components/contact-card/contact-business").default
   OutsideClickMixin = require("mixins/outside-click-mixin")

   createComponent "ContactCell",
      mixins: [OutsideClickMixin]
      propTypes:
         contact: ReactLibs.PropTypes.object

      getInitialState: ->
         visible: false

      componentWillUnmount: ->
         clearTimeout(@outTimeout)
         clearTimeout(@inTimeout)

      handleOutsideClick: ->
         @handleMouseOut()

      handleMouseOver: ->
         clearTimeout(@outTimeout)
         unless @state.visible
            @inTimeout = _.delay =>
               @setState {visible: true} if @depracated_is_mounted
            , 200

      handleMouseOut: ->
         clearTimeout(@inTimeout)
         if @state.visible
            @outTimeout = _.delay =>
               @setState {visible: false} if @depracated_is_mounted
            , 200

      render: ->
         div
            className: "contact-cell"
            onMouseOver: @handleMouseOver
            onMouseOut: @handleMouseOut,

            ContactCard contact: @props.contact, showClassYear: true
