import { PropTypes } from 'base/react-libs';
import { Button, Icon } from '@evertrue/et-components';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';

const JourneyDetailTableToggle = ({ tableView, setTableView }) => (
  <div style={{ paddingLeft: '16px'}}>
    <Button
      type="simple"
      onClick={() => {
        if (tableView === 'steps') {
          trackJourneysAction('view_all_active_steps');
          setTableView('activeConstituents');
        } else setTableView('steps');
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {tableView === 'steps' ? <Icon icon="group" size={2} /> : <Icon icon="cadences" size={2} iconKit="gt2" />}
        {tableView === 'steps' ? 'View All Active' : 'View Steps Due'}
      </div>
    </Button>
  </div>
);

JourneyDetailTableToggle.propTypes = {
  tableView: PropTypes.string,
  setTableView: PropTypes.func,
};

export default JourneyDetailTableToggle;
