import moment from 'moment';
import colors from 'base/colors';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CalendarDatePicker from './calendar-date-picker';
import { Button, Popover2, Icon } from '@evertrue/et-components';
import { getMomentObjectInTimezone, getDateInTimezone } from 'base/new-utils';

/**
 * DatePickerDropdown component. This is a button that opens a dropdown that contains a calendar date picker.
 *
 * @param {Object} props - The component props.
 * @param {function} [props.onDayClick=() => {}] - Callback function to handle day click events.
 * @param {Date|number} [props.date=moment.now()] - The selected date.
 * @param {string} [props.triggerClassName] - Additional class name for the trigger button.
 * @param {string} [props.chevronClassName=''] - Additional class name for the chevron icon.
 * @param {string} [props.buttonType='secondary'] - The type of button to use.
 * @param {boolean} [props.showDay] - Whether to show the day of the week in the rendered date.
 * @param {boolean} [props.allowClear] - Whether to allow clearing the selected date.
 * @param {boolean} [props.disabled=false] - Whether the date picker is disabled.
 * @param {Array|Object} [props.disabledDays] - Days to be disabled in the date picker. can be an array of dates OR an object with before and after keys
 * @param {boolean} [props.disableCloseOnDayClick] - Whether to disable closing the popover on day click.
 * @param {boolean} [props.disableWeekends=false] - Whether to disable selecting weekends.
 * @returns {JSX.Element} The DatePickerDropdown component.
 */
const DatePickerDropdown = ({
  onDayClick = () => {},
  date = moment.now(),
  triggerClassName,
  chevronClassName = '',
  buttonType = 'secondary',
  showDay,
  allowClear,
  disabled = false,
  disabledDays,
  disableCloseOnDayClick,
  disableWeekends = false,
}) => {
  const handleClick = (date) => {
    const newDate = moment(date).valueOf();
    onDayClick(newDate);
  };

  const displayDate = () => {
    if (date) {
      const momentDate = getMomentObjectInTimezone(date);
      if (showDay) {
        if (momentDate.isSame(moment(), 'day')) {
          return momentDate.calendar(null, { sameDay: '[Today]' }) + ', ' + momentDate.format('MMM DD, YYYY');
        } else {
          return momentDate.format('dddd, MMM DD, YYYY');
        }
      } else {
        return momentDate.format('MMM DD, YYYY');
      }
    } else {
      return 'Select Date';
    }
  };

  const weekendsDisabledDays = disableWeekends
    ? { daysOfWeek: [0, 6] } // 0 for Sunday, 6 for Saturday
    : {};

  const combinedDisabledDays = Array.isArray(disabledDays)
    ? [...disabledDays, weekendsDisabledDays]
    : [disabledDays, weekendsDisabledDays];

  return (
    <div>
      <Popover2
        borderColor={colors.blue300}
        fillcolor="#fff"
        trigger={({ openPopover }) => {
          return (
            <Button
              type={buttonType}
              className={classNames('date-picker-dropdown--btn', triggerClassName)}
              onClick={openPopover}
              disabled={disabled}
            >
              <span>
                <Icon icon="date-range" className="date-picker-dropdown--cal-icon" size={1} />
                {displayDate()}
              </span>
              <Icon icon="chevron-down" size={1} style={{ opacity: '65%' }} className={chevronClassName} />
            </Button>
          );
        }}
      >
        {({ closePopover }) => (
          <div className="date-picker--dropdown">
            <CalendarDatePicker
              disabledDays={combinedDisabledDays}
              selectedDays={date ? getDateInTimezone(date) : new Date()}
              initialMonth={date ? getDateInTimezone(date) : new Date()}
              onDayClick={(date, { disabled, selected, today }, event) => {
                if (disabled) return; // this means the user selected a disabled date.
                if (!disableCloseOnDayClick) {
                  closePopover();
                }
                handleClick(date);
              }}
            />
            {allowClear && (
              <Button
                type="simple"
                onClick={() => {
                  closePopover();
                  onDayClick(null);
                }}
              >
                Clear
              </Button>
            )}
          </div>
        )}
      </Popover2>
    </div>
  );
};

DatePickerDropdown.propTypes = {
  onDayClick: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  triggerClassName: PropTypes.string,
  disableCloseOnDayClick: PropTypes.bool,
  disabled: PropTypes.bool,
  chevronClassName: PropTypes.string,
  allowClear: PropTypes.bool,
  buttonType: PropTypes.string,
  disabledDays: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showDay: PropTypes.bool,
  disableWeekends: PropTypes.bool,
};

export default DatePickerDropdown;
