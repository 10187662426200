import { useGlobalOutreachProvider } from "apps/outreach/contexts/global-outreach-context";
import DialerInputField from "./dialer-input-field";
import DialerNumbersDropdown from "./dialer-numbers-dropdown";
import { PropTypes } from "base/react-libs";

const DialerHeader = ({
  name,
  phones,
  handleNumberChange,
  value,
  phoneRef,
  extensionRef,
  phoneNumber,
  extension,
  onPhoneFocus,
  onExtFocus,
  handleInput,
}) => {
  const { CallTimer } = useGlobalOutreachProvider();
  return (
    <div className="dialer--header">
      <DialerNumbersDropdown name={name} phones={phones} handleNumberChange={handleNumberChange} value={phoneNumber} />
      <DialerInputField
        phoneRef={phoneRef}
        extensionRef={extensionRef}
        phoneNumber={phoneNumber}
        extension={extension}
        onPhoneFocus={onPhoneFocus}
        onExtFocus={onExtFocus}
        handleInput={handleInput}
      />
      <CallTimer className="dialer--timer" />
    </div>
  );
};

DialerHeader.propTypes = {
  name: PropTypes.string,
  phones: PropTypes.array,
  handleNumberChange: PropTypes.func,
  value: PropTypes.string,
  phoneRef: PropTypes.any,
  extensionRef: PropTypes.any,
  phoneNumber: PropTypes.string,
  extension: PropTypes.string,
  onPhoneFocus: PropTypes.func,
  onExtFocus: PropTypes.func,
  handleInput: PropTypes.func,
};

export default DialerHeader;
