module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   _ = require("underscore").default

   _generateTimes = (increment, selectedDate) ->
      dateTime = new Date(1970, 0, 1, 0, 0, 0, 0)
      time_arr = []
      currentTime = new Date()
      isToday = selectedDate && moment(selectedDate).isSame(currentTime, 'day')

      while dateTime.getDate() == 1
         time_arr.push({
            value: moment(dateTime).format()
            label: moment(dateTime).format("hh:mm a")
            disabled: selectedDate && isToday && (dateTime.getHours() < currentTime.getHours() || (dateTime.getHours() == currentTime.getHours() && dateTime.getMinutes() < currentTime.getMinutes()))
         })
         dateTime.setMinutes(dateTime.getMinutes() + increment)
      return time_arr

   createComponent "TimeDropdown",
      propTypes:
         increment: ReactLibs.PropTypes.number
         onChange: ReactLibs.PropTypes.func
         value: ReactLibs.PropTypes.any
         disabled: ReactLibs.PropTypes.bool
         selectedDate: ReactLibs.PropTypes.any

      getDefaultProps: ->
         increment: 30

      UNSAFE_componentWillMount: ->
         @times_arr = _generateTimes(@props.increment, @props.selectedDate)

      UNSAFE_componentWillReceiveProps: (nextProps) ->
         if nextProps.selectedDate != @props.selectedDate
            @times_arr = _generateTimes(nextProps.increment, nextProps.selectedDate)

      render: ->
         index = if @props.value
            _.findIndex(@times_arr, (time) => time.label == @props.value?.label?.toLowerCase())
         else 0

         AdvancedCombobox
            className: @props.className
            value:  @times_arr[index]
            options: @times_arr
            onChange: @props.onChange
            disabled: @props.disabled
