import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Modal, useOpenClose, Link, ModalConfirm } from '@evertrue/et-components';
import { useFluxStore } from '@evertrue/et-flux';
import WindowStore from 'apps/layout/stores/window-store';
import UserCard from 'apps/users/components/user-card';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import Decorator from 'clientDecorator';
import { v4 as uuidv4 } from 'uuid';

const useWindowStore = () =>
  useFluxStore(WindowStore, () => ({
    width: WindowStore.getWidth(),
  }));

const ActivityFacepile = ({ list = [], type = 'contacts', label, icon }) => {
  const [visible, setVisible] = useState(5);
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const facepileRef = useRef(null);
  const { width } = useWindowStore();

  useEffect(() => {
    const updateVisibleItems = () => {
      if (facepileRef.current) {
        const domProps = facepileRef.current.getBoundingClientRect();
        setVisible(Math.floor((domProps.width - 50) / 50) - 1);
      }
    };

    updateVisibleItems();
  }, [width]);

  const handleClick = () => {
    isModalOpen ? closeModal() : openModal();
  };

  return (
    <div className="activity-facepile" ref={facepileRef}>
      {list
        .slice(0, visible)
        .map((item) =>
          item ? (
            <Avatar
              className="activity-facepile--avatar"
              key={uuidv4()}
              url={item.avatar}
              name={Decorator.Contacts.getDetailedName(item)}
            />
          ) : null
        )}
      {list.length > visible && <div className="activity-facepile--more">+{list.length - visible}</div>}
      <Link title="See all Activities" onClick={handleClick} className="activity-facepile--link">
        see all
      </Link>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        closeOnOutsideClick={true}
        className="activity-facepile--modal"
      >
        <ModalConfirm header={label} closeModal={closeModal}>
          <div className="activity-facepile--table">
            {list.map((item) =>
              item ? (
                type === 'users' ? (
                  <UserCard user={item} key={uuidv4()} />
                ) : (
                  <ContactCard contact={item} key={uuidv4()} referrer="list_activity" showRemoteId includeMiddle />
                )
              ) : null
            )}
          </div>
        </ModalConfirm>
      </Modal>
    </div>
  );
};

ActivityFacepile.propTypes = {
  list: PropTypes.array,
  type: PropTypes.oneOf(['users', 'contacts']),
  label: PropTypes.string,
  icon: PropTypes.string,
};

export default ActivityFacepile;
