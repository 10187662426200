// Helper function to sort an array of objects by a nested key
export const keyIntoNested = (obj, keyPath) => {
  return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj) || 0;
};

// Helper function to sort an array of objects by a key and an order (ASC or DESC)
export const sortByKey = (array, key, order) =>
  array.sort((a, b) => {
    const valueA = keyIntoNested(a, key);
    const valueB = keyIntoNested(b, key);

    const valueALower = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
    const valueBLower = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;

    if (valueALower < valueBLower) {
      return order === 'ASC' ? -1 : 1;
    }
    if (valueALower > valueBLower) {
      return order === 'ASC' ? 1 : -1;
    }
    return 0;
  });
