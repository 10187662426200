import * as Yup from 'yup';

export const UserSettingsSchema = Yup.object().shape({
  enableGiftSection: Yup.boolean(),
  giftAmount: Yup.string()
    .nullable()
    .trim()
    .when('enableGiftSection', {
      is: true,
      then: Yup.string().required('Minimum Gift Amount cannot be empty').nullable(),
    }),
  daysSinceLastGift: Yup.string()
    .nullable()
    .trim()
    .when('enableGiftSection', {
      is: true,
      then: Yup.string().required('Number of days since last gift cannot be empty').nullable(),
    }),
  enableDPS: Yup.boolean(),
  monthsSinceLastInteraction: Yup.string()
    .nullable()
    .trim()
    .when('enableDPS', {
      is: true,
      then: Yup.string().required('Number of months since last recorded interaction cannot be empty').nullable(),
    }),
  enableTripMeeting: Yup.boolean(),
  enableCareerMovesSection: Yup.boolean(),
  enableAnniversarySection: Yup.boolean(),
});
