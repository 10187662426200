require("../styles/index.scss")
window.numeral = require("numeral")


_ = window._ = require("underscore").default
_.str = require("underscore.string")
EverTrue = require("app")
Setup = require("setup")
require("focus-visible")

startupRoutine = require("app-startup-routine").default

startupRoutine().then (auth_type) ->
   # Setup the Application event callbacks and global elements

   setup = new Setup()
   setup.init()

   # then start the application, which will run the initializers
   EverTrue.start()
   if auth_type
      EverTrue.track.set("auth_action", {type: auth_type})
