import { fetchNextFiveDays } from 'apps/home/utils/api-requests/home-page';
import { getMomentObjectInTimezone } from 'base/new-utils';
import { PropTypes } from 'base/react-libs';
import { useState, useEffect } from 'react';
import DaysBarChart from './bar-chart/days-bar-chart';
import ErrorLogger from 'entities/helpers/error-log-helper';
import HomepageCard from '../homepage-card';
import Loading from 'components/elements/loading';
import TripsAndMeetingsForNextFive from './trips-meetings-next-five';
import useJourneyTimeoutsApi from 'apps/journeys/hooks/use-journey-timeouts-api';

const NextFiveDays = ({ user = {} }) => {
  const { businessDaysUntilFutureTimeout, futureJourneyTimeout } = useJourneyTimeoutsApi();
  const [nextFiveDaysCounts, setNextFiveDaysCounts] = useState([]);
  const [loadingNextFiveCounts, setLoadingNextFiveCounts] = useState(false);

  // JOURNEY TASKS and OTHER TASK COUNTS FOR NEXT FIVE DATES
  useEffect(() => {
    const getAndSetNextFiveDayCounts = async () => {
      try {
        setLoadingNextFiveCounts(true);
        const nextFive = await fetchNextFiveDays(user.id, ['IN_PROGRESS'], true, 7);
        setNextFiveDaysCounts(nextFive);
      } catch (e) {
        ErrorLogger.log('Error fetching next five days data', e);
      } finally {
        setLoadingNextFiveCounts(false);
      }
    };

    getAndSetNextFiveDayCounts();
  }, [user.id]);

  return (
    <HomepageCard
      leftHeading={'Your Next 5 Days'}
      rightHeading={'Based on your scheduled cadence steps and tasks'}
      headingRow={false}
      body={
        <>
          {futureJourneyTimeout && (
            <span>
              Your upcoming cadence and task pause is starting in {businessDaysUntilFutureTimeout} business days on{' '}
              <strong>
                {getMomentObjectInTimezone(futureJourneyTimeout?.starts_on).format('dddd, MMM DD, YYYY')}.
              </strong>
            </span>
          )}
          {loadingNextFiveCounts ? (
            <div style={{ marginTop: '120px', height: '200px' }}>
              <Loading spinner_size={'small'} className={'loader-icon'} position="center" />
            </div>
          ) : (
            <div className="chart-wrapper">
              <DaysBarChart nextFiveDaysCounts={nextFiveDaysCounts} />
            </div>
          )}
          <hr></hr>
          <TripsAndMeetingsForNextFive />
        </>
      }
    />
  );
};

NextFiveDays.propTypes = {
  user: PropTypes.object,
};

export default NextFiveDays;
