import PropTypes from "prop-types";
import SuggestedSearchListItem from "./suggsted-search-list-item";

const SuggestedSearchList = ({ suggestedSearches, handleSuggestedSearchClick, showHeader = true, headerText = "Get started with these suggested prompts!" }) => (
  <ul className="suggested-search-list">
    {showHeader && (
      <li className="suggested-search-item-header">
        <div className="suggested-search-item-header-box">{headerText}</div>
      </li>
    )}
    {suggestedSearches.map((searchItem) => (
      <SuggestedSearchListItem
        key={searchItem}
        searchItem={searchItem}
        handleSuggestedSearchClick={handleSuggestedSearchClick}
      />
    ))}
  </ul>
);

SuggestedSearchList.propTypes = {
  suggestedSearches: PropTypes.array,
  handleSuggestedSearchClick: PropTypes.func,
  showHeader: PropTypes.bool,
  headerText: PropTypes.string,
};

export default SuggestedSearchList;