import { Checkbox, Icon, ActionsMenu, Avatar } from '@evertrue/et-components';
import { getMomentObjectInTimezone } from 'base/new-utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Decorator from 'clientDecorator';
import { getOutreachOptions } from '../utils/get-outreach-options';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import EverTrue from 'app';
import EmailRecipients from './email-recipients';
import OutreachStarToggle from './outreach-star-toggle';
import { useGlobalOutreachProvider } from '../contexts/global-outreach-context';
import { updateEmail } from '../utils/update-email';
import { updateDraft } from '../utils/draft-email-api';
import { updateScheduledSend } from '../utils/scheduled-email-api';
import { createOutreachPayload } from '../utils/create-outreach-payload';
import OutreachIndicatorGroup from './outreach-indicator-group';

const OutreachCard = ({
  outreachEmail,
  selectedOutreaches,
  setSelectedOutreaches,
  outreachType,
  discard,
  contact = {},
  onClick = () => {},
}) => {
  const {
    id,
    email_recipients,
    subject,
    updated_at,
    scheduled_at,
    sent_at,
    parsed_body,
    email_template_id,
    task_id,
    thankview_videos,
    starred,
    thread_count,
  } = outreachEmail;
  const [selectedOutreach, setSelectedOutreach] = useState(false);
  const { drawers, addDrawer } = useGlobalDrawerProvider();
  const options = getOutreachOptions(outreachType, discard, contact, outreachEmail, drawers, addDrawer);
  const { setDrafts, setScheduled, setSent } = useGlobalOutreachProvider();

  const handleOutreachEmailStarToggle = async () => {
    const starred = !outreachEmail.starred;
  
    try {
      const payload = createOutreachPayload(outreachEmail, outreachType, starred);
  
      let updateFunction;
      let setStateFunction;
  
      switch (outreachType) {
        case 'Draft':
          updateFunction = updateDraft;
          setStateFunction = setDrafts;
          break;
        case 'Scheduled':
          updateFunction = updateScheduledSend;
          setStateFunction = setScheduled;
          break;
        case 'Sent':
          updateFunction = updateEmail;
          setStateFunction = setSent;
          break;
        default:
          console.error('Unknown outreach type:', outreachType);
          return;
      }
  
      await updateFunction(payload, id);
      setStateFunction(prevItems =>
        prevItems.map(item => (item.id === id ? { ...item, starred } : item))
      );
    } catch (error) {
      console.error('Error updating star status', error);
    }
  };  

  const handleCheckboxChange = () => {
    setSelectedOutreach((prevSelectedOutreach) => {
      const newSelectedOutreach = !prevSelectedOutreach;
      if (newSelectedOutreach) {
        setSelectedOutreaches([...selectedOutreaches, id]);
      } else {
        setSelectedOutreaches(selectedOutreaches.filter((outreachId) => outreachId !== id));
      }
      return newSelectedOutreach;
    });
  };

  const handleScheduledClick = () => {
    if (outreachType === 'Scheduled') {
      EverTrue.UrlManager.navigate(`/outreach/email/${id}`, true, false);
    }
  };

  const formattedName = Decorator.Contacts.getDetailedName(contact) || contact.name;
  const avatar = Decorator.Profile.getAvatar(contact, contact.lids) || contact.avatar;

  return (
    <div className="outreach-card">
      {outreachType !== 'Sent' ? (
        <>
          <Checkbox
            className="outreach--checkbox-small"
            checked={selectedOutreach}
            label="select"
            onChange={handleCheckboxChange}
          />
          <OutreachStarToggle starred={starred} onClick={handleOutreachEmailStarToggle} />
        </>
      ) : (
        <span className="outreach-card-header">
          <OutreachStarToggle starred={starred} onClick={handleOutreachEmailStarToggle} />
          <Avatar
            className="new-contact-card--avatar fs-exclude-data"
            name={contact ? contact.name : formattedName}
            url={avatar}
            width={30}
            style={{ marginRight: '8px' }}
          />
          {thread_count > 0 && <span className="thread-indicator"></span>}
        </span>
      )}
      <div style={{ width: '95%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} key={id}>
          <div
            className="outreach--draft-card--header"
            onClick={outreachType === 'Scheduled' ? handleScheduledClick : onClick}
          >
            <EmailRecipients recipients={email_recipients} />
            <strong>
              <p>{subject}</p>
            </strong>
          </div>
          <div className="outreach-card-date-container">
            {options && outreachType !== 'Sent' && (
              <ActionsMenu trigger={({ isOpen }) => <Icon icon="more-horiz" size={1} />} options={options} />
            )}
            <p>
              {outreachType === 'Draft'
                ? getMomentObjectInTimezone(updated_at * 1000)?.format('dddd, MMM DD, YYYY')
                : outreachType === 'Sent'
                ? getMomentObjectInTimezone(sent_at * 1000)?.format('MMM D, YYYY [at] h:mm A')
                : getMomentObjectInTimezone(scheduled_at * 1000)?.format('MMM D, YYYY [at] h:mm A')}
            </p>
          </div>
        </div>
        <div
          onClick={outreachType === 'Scheduled' ? handleScheduledClick : onClick}
          style={{ marginBottom: 0 }}
          className="outreach-card-body"
        >
          <p>{parsed_body}</p>
        </div>
        {(thread_count > 0 || email_template_id || task_id || thankview_videos.length > 0) && (
          <OutreachIndicatorGroup
            threadCount={thread_count}
            emailTemplateId={email_template_id}
            taskId={task_id}
            thankviewVideos={thankview_videos}
          />
        )}
      </div>
    </div>
  );
};

OutreachCard.propTypes = {
  outreachEmail: PropTypes.object.isRequired,
  selectedOutreaches: PropTypes.array,
  setSelectedOutreaches: PropTypes.func,
  outreachType: PropTypes.string,
  discard: PropTypes.func,
  contact: PropTypes.object,
  onClick: PropTypes.func,
};

export default OutreachCard;
