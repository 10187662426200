import { useState, forwardRef, useImperativeHandle } from 'react';
import { Icon, Button, Loading, useOpenClose, Card, LinkButton } from '@evertrue/et-components';
import NewFolderModal from 'apps/folder/components/modal/new-folder-modal';
import PropTypes from 'prop-types';
import { useFolders } from '../../hooks/use-folders';
import EverTrue from 'app';
import AddToFolderModal from '../modal/add-to-folder-modal';

const FOLDER_LIMIT = 8;

const getListText = (count, folderType) => {
  if (count === 0) return '0 ' + (folderType === 'DYNAMIC' ? 'Saved Searches' : 'lists');
  if (count === 1) return '1 ' + (folderType === 'DYNAMIC' ? 'Saved Search' : 'list');
  return `${count} ${folderType === 'DYNAMIC' ? 'Saved Searches' : 'lists'}`;
};

const truncateFolderTitle = (text) => {
  const maxLength = 20;
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

const EMPTY_MESSAGE = "You don't have any folders yet. Click the + icon to create one to help stay organized.";

const FoldersCard = forwardRef(({ selectedLists, handleCheckAllLists = () => {}, folderType = 'STATIC' }, ref) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const [isAddToFolderModalOpen, openAddToFolderModal, closeAddToFolderModal] = useOpenClose();
  const {
    folders,
    loading,
    createFolder,
    createFolderAndAddToState,
    addListsToFolder,
    updateListCountState,
    addListsToFolderAndUpdateState,
  } = useFolders(folderType);
  const [showAllFolders, setShowAllFolders] = useState(false);

  // Expose modal methods to parent
  useImperativeHandle(ref, () => ({
    openCreateFolderModal: () => {
      openModal();
    },
    openAddListSavedSearchesToFolderModal: () => {
      openAddToFolderModal();
    },
  }));

  // Create new folder, add it to state, then update state and close the modal
  const handleCreateFolder = (folderName) => {
    createFolderAndAddToState(folderName).then(() => {
      EverTrue.Alert.success(`${folderName} created`);
      closeModal();
    });
  };

  // Either create a new folder and add lists to it, or add lists to existing folders, then update state and close modal
  const handleAddListsToFolders = (result) => {
    const listIds = selectedLists.map((list) => list.id);
    if (result.type === 'create') {
      createFolder(result.folderName)
        .then((folder) => addListsToFolderAndUpdateState(folder, listIds))
        .then(handleCheckAllLists(false))
        .then(() => {
          const successMessage =
            folderType === 'DYNAMIC'
              ? `Saved Search(s) added to ${result.folderName}`
              : `List(s) added to ${result.folderName}`;
          EverTrue.Alert.success(successMessage);
          closeAddToFolderModal();
        })
        .catch(() => {
          const errorMessage =
            folderType === 'DYNAMIC'
              ? 'Error creating Folder and adding Saved Search(s)'
              : 'Error creating Folder and adding List(s)';
          EverTrue.Alert.error(errorMessage);
        });
    } else if (result.type === 'existing') {
      const { folders } = result;
      const folderIds = folders.map((folder) => folder.value);
      addListsToFolder(folderIds, listIds)
        .then((res) => {
          if (!res || !Array.isArray(res)) return;
          const successMessage =
            folderType === 'DYNAMIC' ? 'Saved Search(es) added to Folder(s)' : 'List(s) added to Folder(s)';
          EverTrue.Alert.success(successMessage);
          updateListCountState(res.flat()); // API is returning nested array when adding to multiple folders
        })
        .then(handleCheckAllLists(false))
        .then(closeAddToFolderModal)
        .catch(() => {
          const errorMessage =
            folderType === 'DYNAMIC' ? 'Error adding Saved Search(s) to Folder(s)' : 'Error adding Lists to Folder(s)';
          EverTrue.Alert.error(errorMessage);
        });
    }
  };

  const visibleFolders = showAllFolders ? folders : folders.slice(0, FOLDER_LIMIT);

  return (
    <>
      <Card
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 className="title-subtitle-500-20">Your Folders</h1>
            <Icon icon="add" onClick={openModal} className="lists-page--side-folder-card-icon" />
          </div>
        }
        padding="16px"
      >
        <div className="lists-page--folders">
          {loading ? (
            <div style={{ paddingTop: '10px', height: '20px', marginBottom: '10px', marginTop: '10px' }}>
              <Loading spinner_size={'small'} className={'loader-icon'} />
            </div>
          ) : !folders || !folders?.length ? (
            <>{EMPTY_MESSAGE}</>
          ) : (
            <>
              {visibleFolders.map(({ folder, totalNumberOfLists }) => (
                <div key={folder.id} className="lists-page--folder-item">
                  <LinkButton
                    className="folder-name"
                    title={folder.title}
                    onClick={() => EverTrue.Navigator(`folder/${folder.id}`)}
                  >
                    {truncateFolderTitle(folder.title)}
                  </LinkButton>
                  <span className="folder-count">{getListText(totalNumberOfLists, folderType)}</span>
                </div>
              ))}
              {folders.length > FOLDER_LIMIT && (
                <div className="lists-page--show-all">
                  <Button type="simple" onClick={() => setShowAllFolders(!showAllFolders)}>
                    {showAllFolders ? 'Show Less' : 'Show All'}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Card>
      {isModalOpen && <NewFolderModal onSubmit={handleCreateFolder} isOpen={isModalOpen} onClose={closeModal} />}
      {isAddToFolderModalOpen && (
        <AddToFolderModal
          onSubmit={handleAddListsToFolders}
          isOpen={isAddToFolderModalOpen}
          onClose={closeAddToFolderModal}
          existingFolders={folders}
          folderType={folderType}
        />
      )}
    </>
  );
});

FoldersCard.propTypes = {
  selectedLists: PropTypes.array,
  handleCheckAllLists: PropTypes.func,
  folderType: PropTypes.string,
};

export default FoldersCard;
