import { useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import SelectedContactsAddTo from 'apps/contact/components/contacts/table/selected-contacts-addto';
import Pagination from 'components/controls/pagination';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import SelectedContactsSource from 'apps/contact/sources/selected-contacts-source';
import { useFluxStore } from '@evertrue/et-flux';
import SelectAllIndicator from 'apps/contact/components/contacts/select-all-indicator';

const selectedContactsStoreMap = () => ({
  showSelectedIndicator: SelectedContactsStore.getShowSelectedIndicator(),
  selectedContacts: SelectedContactsStore.getSelectedContacts(),
  allSelected: SelectedContactsStore.getIsAllSelected(),
});

const ContactTableToolbar = ({
  paginationClassName = '',
  contacts = {},
  totalResultsCount = null,
  className = '',
  hide_pagination = false,
  hideTrips = false,
  listActionLabel = 'Add to List',
  filterParams = {},
  allowAddAll = false,
  renderToolbarItems = undefined,
  onPage = () => {},
  children = null,
}) => {
  const { showSelectedIndicator, selectedContacts, allSelected } = useFluxStore(
    SelectedContactsStore,
    selectedContactsStoreMap
  );
  useEffect(() => {
    SelectedContactsSource.clearAllSelectedState();
  }, []);

  const isEmpty = !contacts.items || contacts.items.length === 0;
  const contact_ids = contacts.items ? contacts.items.map((contact) => contact.id) : [];
  const formated_total_results_count = numeral(totalResultsCount).format('0,0');

  return (
    <div className={`contact-table-toolbar ${className || ''}`}>
      <div className="contact-table-toolbar--actions">
        <SelectedContactsAddTo
          hideTrips={hideTrips}
          label={listActionLabel}
          contactIds={contact_ids}
          totalResultsCount={totalResultsCount}
          filterParams={filterParams}
          allowAddAll={allowAddAll}
          renderToolbarItems={renderToolbarItems}
        />
        {!isEmpty && !hide_pagination && (
          <Pagination
            className={`contact-table-toolbar--pagination ${paginationClassName || ''}`}
            page={Number(contacts.page)}
            total={contacts.total / contacts.limit}
            onChange={(page) => {
              onPage(page);
              SelectedContactsSource.clearAllSelectedState();
            }}
          />
        )}
        {children}
      </div>
      <SelectAllIndicator
        showSelectedIndicator={showSelectedIndicator}
        formatedTotalResultsCount={formated_total_results_count}
        numberSelectedContacts={selectedContacts.length}
        allSelected={allSelected}
      />
    </div>
  );
};

ContactTableToolbar.propTypes = {
  contacts: PropTypes.object,
  listActionLabel: PropTypes.string,
  className: PropTypes.string,
  allowAddAll: PropTypes.bool,
  hide_pagination: PropTypes.bool,
  totalResultsCount: PropTypes.number,
  onPage: PropTypes.func,
  children: PropTypes.any,
  filterParams: PropTypes.object,
  hideTrips: PropTypes.bool,
  renderToolbarItems: PropTypes.func,
  paginationClassName: PropTypes.string,
};

export default ContactTableToolbar;
