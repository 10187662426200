import { Button } from '@evertrue/et-components';
import PropTypes from 'prop-types';

const VideoControl = ({
  isRecording,
  webcamEnabled,
  onEnableWebcam,
  onStartRecording,
  onStopRecording,
  onRerecord,
  state,
  processingVideoState,
}) => {
  return (
    <>
      {isRecording && (
        <Button type="action" aria-label="Start Over" onClick={onRerecord}>
          Start Over
        </Button>
      )}
      {!webcamEnabled && !processingVideoState ? (
        <Button type="action" aria-label="Enable Webcam" onClick={onEnableWebcam}>
          Enable Webcam
        </Button>
      ) : isRecording && !processingVideoState ? (
        <Button type="action" aria-label="Stop Recording" onClick={onStopRecording} className="stop-recording">
          Stop Recording
        </Button>
      ) : (
        <Button type="action" aria-label="Start Recording" onClick={onStartRecording} className="start-recording">
          Start Recording
        </Button>
      )}{' '}
    </>
  );
};

VideoControl.propTypes = {
  state: PropTypes.object.isRequired,
  isRecording: PropTypes.bool.isRequired,
  webcamEnabled: PropTypes.bool.isRequired,
  onEnableWebcam: PropTypes.func.isRequired,
  onStartRecording: PropTypes.func.isRequired,
  onStopRecording: PropTypes.func.isRequired,
  onRerecord: PropTypes.func.isRequired,
  processingVideoState: PropTypes.string.isRequired,
};

export default VideoControl;
