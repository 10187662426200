import { PageHeader } from '@evertrue/et-components';
import PauseForm from './pause-form';
import HolidayTooltip from 'components/tooltip/holiday-tooltip';

const JourneySettingsController = () => (
  <>
    <PageHeader title="Pause Cadences and Tasks" className="pause-journey-header" />
    <p className="pause-journey-warning">
      Pausing cadences will freeze all your cadences and tasks until you return. You can use this feature when you are
      heading out on some well deserved time off, are out of office visiting donors, or have a busy week of meetings.
      Don’t forget to set a pause for any departmental holidays!
    </p>
    <p className="pause-journey-warning">
      You can resume your cadences at any time, or set a return date on any weekday to automatically resume your tasks
      when you log on for the day.
    </p>
    <p className="pause-journey-warning">
      <strong>
        Note: Cadence intervals will automatically account for weekends and U.S. federal holidays. No cadence steps will
        fall due on those days.
      </strong>
      <HolidayTooltip />
    </p>
    <PauseForm />
  </>
);

export default JourneySettingsController;
