import { Link } from '@evertrue/et-components';
import { convertFilterToUrl } from './filters-parser-util';

// Utility function to get today's date and date 6 months ago
const getSixMonthsDateRange = () => {
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  const formatDate = (date) => date.toISOString().split('T')[0];

  return {
    gte: formatDate(sixMonthsAgo),
    lte: formatDate(today),
  };
};

// The filter_id match the filters from the filter-config.coffee file
export const RecommendedActionsSearches = [
  {
    title: 'High Networth Prospects',
    id: 11.1,
    segments: [
      {
        id: 11.2,
        title: 'unassigned prospects with high net worth',
        windfall: true,
        default: false,
        filter: [
          { filter_row_id: '0', filter_id: '14.1', value: { gte: '1000000' } },
          { filter_row_id: '1', filter_id: '12.5', value: 'false' },
        ],
      },
    ],
  },
  {
    title: 'Unassigned LYBUNTs',
    id: 12.1,
    segments: [
      {
        id: 12.2,
        title: 'unassigned LYBUNTs with no recent interactions',
        windfall: true,
        default: true,
        filter: [
          { filter_row_id: 0, filter_id: '3.7', value: [{ label: 'LYBUNT', value: 'lybunt' }] },
          { filter_row_id: 1, filter_id: '12.5', value: false },
          {
            filter_row_id: 2,
            filter_id: '9.3',
            value: {
              parameter: { value: 'must_not', label: 'was not' },
              value: 'range',
              range: getSixMonthsDateRange(),
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Engaged on Facebook in last month',
    id: 13.1,
    segments: [
      {
        id: 13.2,
        title: 'Engaged on Facebook in last month',
        windfall: false,
        default: false,
        filter: [
          { filter_row_id: 0, filter_id: '12.5', value: false },
          { filter_row_id: 1, filter_id: '2.2', value: true },
          { filter_row_id: 2, filter_id: '2.82', value: { parameter: { value: 'must' }, value: 'now-1M/d' } },
        ],
      },
    ],
  },
];

export const NextStepSavedSearches = [];

/**
 * this function takes in an array of filter objects and converts them to a Link component which can be rendered
 *
 * @param {} filters -- array of Filter Objects (see above)
 * @param {*} windfallEnabled -- Boolean if user/org has Windfall enabled
 * @returns
 */
export const mapFiltersToLinks = (filters, windfallEnabled = false) => {
  if (!filters || !filters.length) return [];

  return filters
    .flatMap((section) =>
      section.segments.map((segment) => {
        const url = convertFilterToUrl(segment.filter, 'contact');

        if (windfallEnabled === segment.windfall || segment.default) {
          return (
            <Link key={segment.id} href={url} title={segment.title}>
              {segment.title}
            </Link>
          );
        }
        return null;
      })
    )
    .filter(Boolean); // Filter out any null values
};
