import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from '@evertrue/et-components';
import ContactAddTo from 'apps/contact/components/contacts/table/contact-add-to';
import { GlobalContactSearchContext } from 'apps/layout/controllers/global-contact-search-context';

const AddToModal = ({ isOpen, close, selected, ID, resultCount, scope, hideCadences }) => {
  const { dslQuery } = useContext(GlobalContactSearchContext);
  const [modal, setModal] = useState(null);

  if (scope) {
    dslQuery.scope = scope;
  }
  return (
    <>
      {modal !== null && modal}
      <Modal isOpen={isOpen} closeModal={close} size="small" closeOnOutsideClick={true}>
        <ModalHeader title={`Add to...`} closeModal={close} />
        <strong>
          <p className="add-list-trip-pool" style={{ margin: 0, padding: '10px 0 0 16px' }}>
            Add {selected.length ? selected.length : resultCount.toLocaleString()} constituents to...
          </p>
        </strong>
        <ContactAddTo
          describedById={ID}
          hideTrips={false}
          totalResultsCount={resultCount}
          filterParams={dslQuery || {}}
          contactIds={selected}
          allSelected={selected.length ? false : true}
          allowAddAll={true}
          closePopover={close}
          setModal={(modal) => setModal(modal)}
          isFiltersV2={true}
          hideCadences={true}
        />
      </Modal>
    </>
  );
};

AddToModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  ID: PropTypes.string,
  resultCount: PropTypes.number,
  scope: PropTypes.array,
  hideCadences: PropTypes.bool,
};

export default AddToModal;
