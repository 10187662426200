import { useContext } from 'react';
import { PropTypes } from 'base/react-libs';
import { TasksContext } from 'apps/tasks/hooks/tasks-context';
import { Pagination } from '@evertrue/et-components';

const TasksToolbar = ({ numTasks = 0, userType, filterStatus = {} }) => {
  const { tableSettings, updateTableSettings } = useContext(TasksContext);
  return (
    <div className="tasks-toolbar space-between">
      <strong style={{ margin: 0, alignSelf: 'end' }}>
        {numTasks} Total {numTasks === 1 ? 'Task' : 'Tasks'}
      </strong>

      <Pagination
        className="task-table-pagination"
        page={tableSettings.page}
        total={Math.ceil(numTasks / 50)}
        onChange={(page) => {
          updateTableSettings({ page });
        }}
      />
    </div>
  );
};

TasksToolbar.propTypes = {
  numTasks: PropTypes.number,
  userType: PropTypes.string,
  filterStatus: PropTypes.object,
};

export default TasksToolbar;
