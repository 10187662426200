import numeral from 'numeral';
import str from 'underscore.string';
import Api from 'entities/helpers/api';
import ContactFormatMixin from 'mixins/contact-format-mixin';
import AddressMixin from 'mixins/address-mixin';
import Popover from 'components/overlays/popover';
import ReadMore from 'components/formatting/readmore';
import SelectContactCheckbox from 'apps/contact/components/contacts/select-contact-checkbox';
import ColumnSelector from 'apps/contact/components/contacts/columns/column-selector';
import ContactCell from 'apps/contact/components/contacts/contact-cell';
import moment from 'moment';
import ScoreProgress from 'apps/contact/components/contacts/score-progress';
import ScoreBreakdown from 'apps/profile/components/score-breakdown';
import AnnualGivingScore from 'apps/contact/components/contacts/annual-giving-score';
import EngagementIcon from 'apps/profile/components/engagement-icon';
import { getTabByEngagementAction } from 'apps/events/utils/engagement-config';
import { PromiseResolver } from '@evertrue/promise-resolver';
import CachedContactsResolver from 'apps/contact/components/contacts/cached-contacts-resolver';
import IsGated from 'components/is-gated';
import Decorator from 'clientDecorator';
import { Link, Loading, Icon, ModalTrigger } from '@evertrue/et-components';
import FeatureStore from 'apps/layout/stores/feature-store';
import { v4 as uuidv4 } from 'uuid';
import { getDateInTimezone } from 'base/new-utils';

const WIDTHS = {
  XS: 128,
  S: 144,
  M: 176,
  L: 192,
  XL: 224,
};

const formatDollarAmount = (num) => {
  const format = typeof num === 'number' && num >= 1 ? '$0,00' : '$0.00';
  return numeral(num).format(format);
};

const formatBooleanDisplay = (check) => (check ? 'Y' : 'N');

const renderMultiple = (columnData, key) => {
  if (Array.isArray(columnData)) {
    if (columnData.length > 0) {
      return columnData.map((item, index) => (
        <div key={uuidv4()}>
          {str.capitalize(item?.[key]?.value || item?.[key])}
          {index !== columnData.length - 1 ? ',' : undefined}
        </div>
      ));
    } else {
      return <div className="text-centered">-</div>;
    }
  } else {
    return <div className="text-centered">{str.capitalize(columnData) || '-'}</div>;
  }
};

// Uses iso 8601 date format but should work fine with other formats
const renderMostRecent = (columnData, returnKey, timeKey = 'updated_at') => {
  if (columnData?.length) {
    const formattedData = columnData.map((data) => ({
      ...data,
      updated_at: getDateInTimezone(data?.[timeKey])?.getTime(),
    }));

    const mostRecent = Math.max(...formattedData.map((data) => data.updated_at));
    return formattedData.find((data) => data.updated_at === mostRecent)?.[returnKey];
  }
};

const renderMostRecentDate = (data, dateKey = 'updated_at') => {
  if (!data?.length) {
    return '-';
  }

  const mostRecent = data.reduce((acc, item) =>
    !acc || new Date(item?.[dateKey]) > new Date(acc?.[dateKey]) ? item : acc
  );

  // Transform from "YYYY-MM-DD" to "MM/DD/YYYY"
  return mostRecent?.[dateKey] ? moment(mostRecent?.[dateKey]).format('MM/DD/YYYY') : '-';
};

const oneMil = 1000000;

const ContactTableRows = {
  checkbox: {
    label: '',
    width: 45,
    prop: undefined,
    key: 'checkbox',
    sticky: true,
    row: (data) => {
      const contact_name = Decorator.Contacts.getDetailedName(data);
      return <SelectContactCheckbox id={data.id} name={contact_name} />;
    },
  },

  constituent: {
    label: 'Constituent',
    width: 285,
    prop: 'name_last',
    key: 'constituent',
    className: 'contact-fixed-table--contact-cell',
    sticky: true,
    row: (data) => <ContactCell contact={data} />,
  },

  primary_email: {
    label: 'Primary Email',
    width: 285,
    row: (data) => {
      const primaryEmails = (data?.emails || [])
        .map(({ email, primary }) => {
          if (primary?.value === true) {
            return email?.value?.toLowerCase();
          }
          return null;
        })
        .filter(Boolean);

      const num = primaryEmails.length;
      switch (num) {
        case 0:
          return (
            <span aria-label="Primary Email, No Primary Email" className="text-muted">
              No Primary Email
            </span>
          );
        case 1:
          return (
            <span aria-label={`Primary Email, ${primaryEmails[0]}`} className="fs-exclude-data">
              <span aria-hidden="true">{primaryEmails[0]}</span>
            </span>
          );
        default:
          return (
            <span aria-label={`Primary Email, ${num} Primary Emails`} className="text-muted">
              <span aria-hidden="true">{`${num} Primary Emails`}</span>
            </span>
          );
      }
    },
  },

  remote_id: {
    label: 'Remote ID',
    width: WIDTHS.S,
    row: (data) => {
      let remote_id = [];
      if (Array.isArray(data?.identities)) {
        remote_id = (data?.identities || []).find((identity) => identity.type === 'remote_id')?.value;
      }
      return (
        <div className="text-centered" aria-label={`Remote Id, ${remote_id || 'not provided'}`}>
          {remote_id ? remote_id : '-'}
        </div>
      );
    },
  },

  capacity_score: {
    width: WIDTHS.L,
    prop: 'giving.capacity_score.untouched',
    row: (data) => {
      const capacity_score = ContactFormatMixin.giving(data, 'capacity_score');
      return (
        <div className="text-centered" aria-label={`Capacity Score, ${capacity_score || 'not provided'}`}>
          <span aria-hidden="true">{capacity_score || '-'}</span>
        </div>
      );
    },
  },

  donor_score: {
    width: WIDTHS.M,
    row: (data) => {
      const donor_score = ContactFormatMixin.giving(data, 'donor_score');
      return (
        <div className="text-centered" aria-label={`Donor Score, ${donor_score || 'not provided'}`}>
          <span aria-hidden="true">{donor_score || '-'}</span>
        </div>
      );
    },
  },

  solicitation_codes: {
    width: WIDTHS.M,
    row: (data) => {
      let solicitations = [];
      if (Array.isArray(data?.solicitation_codes)) {
        solicitations = data?.solicitation_codes;
      }
      const solicitations_string = (solicitations || []).map(({ code }) => code.value);
      return (
        <span aria-label={`Solicitation codes, ${solicitations_string.join(', ') || 'None'}`}>
          <span aria-hidden="true">{renderMultiple(solicitations, 'code')}</span>
        </span>
      );
    },
  },

  is_unrated: {
    width: WIDTHS.XS,
    row: (data) => {
      const capacity_score = ContactFormatMixin.giving(data, 'capacity_score');
      const capacity_bool = formatBooleanDisplay(!capacity_score);
      return (
        <div className="text-centered" aria-label={`Is Rated, ${capacity_bool}`}>
          <span aria-hidden="true">{capacity_bool}</span>
        </div>
      );
    },
  },

  wealthy_neighborhood: {
    prop: 'addresses.house_value_median',
    width: WIDTHS.L,
    row: (data) => {
      const address = AddressMixin.getDefaultAddress(data);
      const is_wealthy_neighborhood = formatBooleanDisplay(address?.house_value_median?.value >= oneMil);
      return (
        <div className="text-centered" aria-label={`Wealthy Neighborhood, ${is_wealthy_neighborhood}`}>
          <span aria-hidden="true">{is_wealthy_neighborhood}</span>
        </div>
      );
    },
  },

  net_worth: {
    prop: 'enrichment.wealth_attributes.net_worth',
    width: WIDTHS.S,
    row: (data) => {
      const net_worth = data?.enrichment?.wealth_attributes[data.enrichment.wealth_attributes.length - 1]?.net_worth;
      return (
        <div
          aria-label={`Net Worth, ${
            data?.enrichment?.wealth_attributes[data.enrichment.wealth_attributes.length - 1]?.net_worth
          }`}
        >
          <span aria-hidden="true">
            {net_worth &&
            (net_worth >= oneMil || (net_worth < oneMil && FeatureStore.hasFeature('windfall_wealth_plus')))
              ? formatDollarAmount(net_worth)
              : '-'}
          </span>
        </div>
      );
    },
  },

  physical_assets: {
    width: WIDTHS.M,
    row: (data) => (
      <div aria-label="Physical Assets">
        <span aria-hidden="true">
          {data?.enrichment?.wealth_physical_assets?.length
            ? renderMultiple(data?.enrichment?.wealth_physical_assets, 'type')
            : '-'}
        </span>
      </div>
    ),
  },

  wealth_indicators: {
    width: WIDTHS.M,
    row: (data) => (
      <div aria-label="Wealth Indicators">
        <span aria-hidden="true">
          {data?.enrichment?.wealth_indicators?.length
            ? renderMultiple(data?.enrichment?.wealth_indicators, 'type')
            : '-'}
        </span>
      </div>
    ),
  },

  wealth_life_events: {
    width: WIDTHS.M,
    row: (data) => (
      <div aria-label="Life Events">
        <span aria-hidden="true">
          {data?.enrichment?.wealth_life_events?.length
            ? renderMultiple(data?.enrichment?.wealth_life_events, 'type')
            : '-'}
        </span>
      </div>
    ),
  },

  industry: {
    width: WIDTHS.XL,
    row: (data) => {
      const industry = ContactFormatMixin.industry(data);
      return (
        <span aria-label={`Industry, ${industry || 'Not Provided'}`}>
          <span aria-hidden="true">
            {industry ? (
              <Link
                className="fs-exclude-data"
                href={'industry' + ContactFormatMixin.industry_link(industry)}
                title={`${industry}`}
              >
                {industry}
              </Link>
            ) : (
              '-'
            )}
          </span>
        </span>
      );
    },
  },

  company: {
    width: WIDTHS.XL,
    row: (data) => {
      const val = ContactFormatMixin.company(data);
      return (
        <span aria-label={`Company, ${val || 'Not Provided'}`}>
          <span aria-hidden="true">
            {val ? (
              <Link
                className="fs-exclude-data"
                href={'company' + ContactFormatMixin.company_link(data)}
                title={`${val}`}
              >
                {val}
              </Link>
            ) : (
              '-'
            )}
          </span>
        </span>
      );
    },
  },

  title: {
    width: WIDTHS.XL,
    row: (data) => {
      const title = data?.employments?.[0]?.title?.value;

      return (
        <span aria-label={`Title, ${title || 'Not Provided'}`}>
          <span aria-hidden="true">{title ? <span className="fs-exclude-data">{title}</span> : '-'}</span>
        </span>
      );
    },
  },

  function: {
    width: WIDTHS.L,
    row: (data) => {
      const val = data?.employments?.[0]?.function?.value;

      return (
        <span aria-label={`Function, ${val || 'Not Provided'}`}>
          <span aria-hidden="true">{val ? <span className="fs-exclude-data">{val}</span> : '-'}</span>
        </span>
      );
    },
  },

  grad_year: {
    prop: 'year',
    width: WIDTHS.S,
    row: (data) => {
      const year = data?.properties?.year?.value;

      return (
        <div className="text-centered" aria-label={`Graduation Year, ${year || 'Not Provided'}`}>
          <span aria-hidden="true">{year ? <span className="fs-exclude-data">{year}</span> : '-'}</span>
        </div>
      );
    },
  },

  education: {
    width: WIDTHS.XL,
    row: (data) => {
      const val = data?.educations?.[0]?.school_name?.value;

      return (
        <span aria-label={`Education, ${val || 'Not Provided'}`}>
          <span aria-hidden="true">{val ? <span className="fs-exclude-data">{val}</span> : '-'}</span>
        </span>
      );
    },
  },

  athletics: {
    width: WIDTHS.M,
    row: (data) => {
      let sports = [];
      if (Array.isArray(data?.sports)) {
        sports = data?.sports || [];
      }
      const sports_string = sports.map((sport) => sport?.name?.value).filter(Boolean);
      return (
        <span aria-label={`Athletics, ${sports_string.join(', ') || 'None'}`}>
          <span aria-hidden="true">{renderMultiple(sports, 'name')}</span>
        </span>
      );
    },
  },

  extracurricular_activities: {
    width: WIDTHS.M,
    row: (data) => {
      let activities = [];
      if (Array.isArray(data?.extracurricular_activities)) {
        activities = data?.extracurricular_activities || [];
      }
      const activities_string = activities.map((activity) => activity?.name?.value).filter(Boolean);
      return (
        <span aria-label={`Activities, ${activities_string.join(', ') || 'None'}`}>
          <span aria-hidden="true">{renderMultiple(activities, 'name')}</span>
        </span>
      );
    },
  },

  major: {
    width: WIDTHS.XL,
    row: (data) => {
      const value = data?.educations?.[0]?.majors?.value;

      return (
        <span aria-label={`Major, ${value || 'Not Provided'}`}>
          <span aria-hidden="true">{value || '-'}</span>
        </span>
      );
    },
  },

  engagement_score: {
    prop: 'giving.engagement_score',
    width: WIDTHS.L,
    row: (data) => {
      const value = ContactFormatMixin.giving(data, 'engagement_score');
      return (
        <div className="text-centered" aria-label={`Engagement Score, ${value || 'Not Provided'}`}>
          <span aria-hidden="true">{value || '-'}</span>
        </div>
      );
    },
  },

  facebook_like_count: {
    prop: 'facebook.like_count',
    width: WIDTHS.S,
    row: (data) => {
      const value = ContactFormatMixin.facebook(data, 'like_count');
      return (
        <div className="text-right" aria-label={`Facebook Likes, ${value}`}>
          <span aria-hidden="true">{value || 0}</span>
        </div>
      );
    },
  },

  facebook_reaction_count: {
    width: WIDTHS.S,
    row: (data) => {
      const total_engagement = ContactFormatMixin.facebook(data, 'total_engagement_count');
      const total_comments = ContactFormatMixin.facebook(data, 'comment_count');
      const value = total_engagement - total_comments;
      return (
        <div className="text-right" aria-label={`Facebook Reactions, ${value}`}>
          <span aria-hidden="true">{value || 0}</span>
        </div>
      );
    },
  },

  facebook_engagement: {
    width: WIDTHS.L,
    row: (data) => {
      const engagement = ContactFormatMixin.facebook(data, 'total_engagement_count');
      const value = formatBooleanDisplay(engagement);
      return (
        <div className="text-centered" aria-label={`Facebook Engagement, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  last_fb_engagement: {
    prop: 'facebook.last_engagement_date',
    width: WIDTHS.L,
    row: (data) => {
      const value = data?.facebook?.last_engagement_date?.value;

      return (
        <span aria-label={`Last Facebook Engagement, ${value || 'Not Provided'}`}>
          <span aria-hidden="true">{value || '-'}</span>
        </span>
      );
    },
  },

  facebook_comments_count: {
    prop: 'facebook.comment_count',
    width: WIDTHS.S,
    row: (data) => {
      const value = numeral(ContactFormatMixin.facebook(data, 'comment_count')).format('0,0');
      return (
        <div className="text-right" aria-label={`Facebook Comments Count, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  facebook_match: {
    width: WIDTHS.M,
    row: (data) => {
      let facebook_uid = [];
      if (Array.isArray(data?.identities)) {
        facebook_uid = (data?.identities || []).find((identity) => identity.type === 'facebook_id');
      }
      return (
        <div className="text-centered">
          {formatBooleanDisplay(facebook_uid != null ? facebook_uid.value : undefined)}
        </div>
      );
    },
  },

  linkedin_connections: {
    width: WIDTHS.S,
    row: (data) => {
      let linkedin = {};
      if (Array.isArray(data?.enrichment?.social_profiles)) {
        linkedin = (data?.enrichment?.social_profiles || []).find((profile) => profile.type === 'linkedin');
      }
      return (
        <div aria-label="Linkedin Connections">
          <span aria-hidden="true">{linkedin != null ? linkedin.followers : '-'}</span>
        </div>
      );
    },
  },

  twitter_followers: {
    width: WIDTHS.S,
    row: (data) => {
      let twitter = {};
      if (Array.isArray(data?.enrichment?.social_profiles)) {
        twitter = (data?.enrichment?.social_profiles || []).find((profile) => profile.type === 'twitter');
      }
      return (
        <div aria-label="Twitter Followers">
          <span aria-hidden="true">{twitter?.followers ?? '-'}</span>
        </div>
      );
    },
  },

  lifetime_giving: {
    prop: 'giving.lifetime_amount',
    width: WIDTHS.M,
    row: (data) => {
      const value = formatDollarAmount(ContactFormatMixin.giving(data, 'lifetime_amount'));
      return (
        <div className="text-left fs-exclude-data" aria-label={`Lifetime giving, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  largest_gift: {
    prop: 'giving.largest_gift_amount',
    width: WIDTHS.M,
    row: (data) => {
      const value = formatDollarAmount(ContactFormatMixin.giving(data, 'largest_gift_amount'));
      return (
        <div className="text-right fs-exclude-data" aria-label={`Largest Gift Amount, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  last_gift: {
    prop: 'giving.last_gift_amount',
    width: WIDTHS.M,
    row: (data) => {
      const value = formatDollarAmount(ContactFormatMixin.giving(data, 'last_gift_amount'));
      return (
        <div className="text-left fs-exclude-data" aria-label={`Last Gift Amount, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  last_gift_date: {
    prop: 'giving.last_gift_date',
    width: WIDTHS.S,
    row: (data) => {
      let formatted_value;
      const value = ContactFormatMixin.giving(data, 'last_gift_date');
      if (value) {
        formatted_value = moment(new Date(value)).format('MM/DD/YYYY');
      }
      return (
        <span className="fs-exclude-data" aria-label={`Last Gift Date, ${formatted_value || 'None'}`}>
          <span aria-hidden="true">{formatted_value || '-'}</span>
        </span>
      );
    },
  },

  current_fiscal_giving: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[0]?.amount?.value || false;
      return (
        <span aria-label="Current Fiscal Year Giving">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  last_fiscal_giving: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[1]?.amount?.value || false;
      return (
        <span aria-label="Last Fiscal Year Giving">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  last_fiscal_giving_2: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[2]?.amount?.value || false;
      return (
        <span aria-label="Fiscal Year Giving - 2 years ago">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  last_fiscal_giving_3: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[3]?.amount?.value || false;
      return (
        <span aria-label="Fiscal Year Giving - 3 years ago">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  last_fiscal_giving_4: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[4]?.amount?.value || false;
      return (
        <span aria-label="Fiscal Year Giving - 4 years ago">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  last_fiscal_giving_5: {
    width: WIDTHS.S,
    row: (data) => {
      let sorted = [];
      if (Array.isArray(data?.giving_annual_donations)) {
        sorted = (data?.giving_annual_donations || []).sort(
          (a, b) => (b.fiscal_year?.value || 0) - (a.fiscal_year?.value || 0)
        );
      }
      const amount = sorted[5]?.amount?.value || false;
      return (
        <span aria-label="Fiscal Year Giving - 5 years ago">
          <span aria-hidden="true">{amount ? formatDollarAmount(amount) : '-'}</span>
        </span>
      );
    },
  },

  lifetime_recognition_amount: {
    prop: 'giving.lifetime_recognition_amount',
    width: WIDTHS.M,
    row: (data) => {
      const value = formatDollarAmount(ContactFormatMixin.giving(data, 'lifetime_recognition_amount'));
      return (
        <div className="text-right fs-exclude-data" aria-label={`Lifetime Recognition Amount, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  lifetime_pledge_balance: {
    prop: 'giving.total_pledge_balance',
    width: WIDTHS.M,
    row: (data) => {
      const value = formatDollarAmount(ContactFormatMixin.giving(data, 'total_pledge_balance'));
      return (
        <div className="text-right fs-exclude-data" aria-label={`Lifetime Pledge Balance, ${value}`}>
          <span aria-hidden="true">{value}</span>
        </div>
      );
    },
  },

  location: {
    width: WIDTHS.XL,
    row: (data) => {
      const city = AddressMixin.getDefaultAddress(data)?.city?.value;
      return (
        <span aria-label={`Location, ${city || 'Not Provided'}`}>
          {city ? (
            <span className="fs-exclude-data" aria-hidden="true">
              {city}
            </span>
          ) : (
            '-'
          )}
        </span>
      );
    },
  },

  city: {
    width: WIDTHS.XL,
    row: (data) => {
      const city = AddressMixin.getDefaultAddress(data)?.city?.value;
      return (
        <span aria-label={`Location, ${city || 'Not Provided'}`}>
          {city ? (
            <span className="fs-exclude-data" aria-hidden="true">
              {city}
            </span>
          ) : (
            '-'
          )}
        </span>
      );
    },
  },

  state: {
    width: WIDTHS.XS,
    row: (data) => {
      const address = AddressMixin.getDefaultAddress(data);
      const state = address?.state?.value;
      return (
        <div className="text-centered fs-exclude-data" aria-label={`State, ${state || 'Not Provided'}`}>
          <span aria-hidden="true">{state || '-'}</span>
        </div>
      );
    },
  },

  country: {
    width: WIDTHS.XS,
    row: (data) => {
      const address = AddressMixin.getDefaultAddress(data);
      const country = address?.country?.value;
      return (
        <div className="text-centered fs-exclude-data" aria-label={`Country, ${country || 'Not Provided'}`}>
          <span aria-hidden="true">{country || '-'}</span>
        </div>
      );
    },
  },

  donor_potential_score: {
    prop: 'et_score.dds_score',
    width: WIDTHS.M,
    row: (data) => {
      const score = data?.et_score?.dds_score?.value;
      return (
        <div className="text-left" aria-label={`Donor Potential Score, ${score || 'Not Available'}`}>
          <span aria-hidden="true">{score || '-'}</span>
        </div>
      );
    },
  },
  evertrue_score: {
    prop: 'score.score',
    width: WIDTHS.M,
    row: (data) => {
      const score = data?.score?.score?.value;
      return (
        <div aria-label={`EverTrue Score, ${score}`}>
          <Popover
            width={220}
            position="right"
            event="hover"
            trigger={<ScoreProgress score={score} className="contact-fixed-table--score-progress" />}
            render={() => (
              <div className="contact-fixed-table--score-progress-card rich-tooltip">
                <div className="rich-tooltip--header">EverTrue Score</div>
                <div className="rich-tooltip--body">
                  <ScoreBreakdown scores={data?.score} />
                </div>
              </div>
            )}
          />
        </div>
      );
    },
  },

  annual_giving_score: {
    prop: 'score.annual_fund',
    width: WIDTHS.M,
    row: (data) => {
      const score = data?.score?.annual_fund?.value;
      return (
        <span aria-label={`EverTrue Score, ${score}`}>
          <span aria-hidden="true">
            <AnnualGivingScore score={score} />
          </span>
        </span>
      );
    },
  },

  assignee: {
    width: WIDTHS.L,
    row: (data) => {
      return (
        <IsGated
          feature="rm_assignments"
          render={({ show }) => {
            if (show) {
              return (
                <PromiseResolver
                  args={data.id}
                  promise={() =>
                    Api.VOLUNTEER.MEMBERSHIP.get({
                      urlArgs: { contact_id: data.id, type: 'prospect' },
                      params: { pool_type: 'TEAM' },
                    })
                  }
                  render={({ data: result, loading }) => {
                    if (loading) {
                      return <Loading size="small" position="center" />;
                    } else {
                      if (Array.isArray(result)) {
                        const solicitorContactIds = [...new Set(result.map((item) => item.solicitor_contact_id))];
                        if (solicitorContactIds.length === 0) {
                          return (
                            <span aria-label="Assignee, Not provided">
                              <span aria-hidden="true">-</span>
                            </span>
                          );
                        } else {
                          return (
                            <CachedContactsResolver ids={solicitorContactIds}>
                              {({ contacts, loading }) => {
                                if (loading) {
                                  return <Loading size="small" position="center" />;
                                } else if (contacts && Object.keys(contacts).length === 0) {
                                  return (
                                    <span aria-label="Assignee, Not provided">
                                      <span aria-hidden="true">-</span>
                                    </span>
                                  );
                                } else if (contacts && Object.keys(contacts).length > 0) {
                                  const contactArray = Object.values(contacts);
                                  const names = contactArray.map((contact) => contact.name);
                                  const nameString = names.join(', ');
                                  return (
                                    <span aria-label={`Assignee ${nameString}`}>
                                      <span aria-hidden="true">{nameString}</span>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span aria-label="Assignee, Not provided">
                                      <span aria-hidden="true">-</span>
                                    </span>
                                  );
                                }
                              }}
                            </CachedContactsResolver>
                          );
                        }
                      } else {
                        return (
                          <span aria-label="Assignee, Not provided">
                            <span aria-hidden="true">-</span>
                          </span>
                        );
                      }
                    }
                  }}
                />
              );
            } else {
              const assignees = ContactFormatMixin.assignee(data).join(', ');
              return (
                <span aria-label={`Assignee, ${assignees}`}>
                  <span aria-hidden="true">{assignees.length === 0 ? '-' : assignees}</span>
                </span>
              );
            }
          }}
        />
      );
    },
  },
  unassigned: {
    width: WIDTHS.S,
    row: (data) => {
      return (
        <IsGated
          feature="rm_assignments"
          render={({ show }) => {
            if (show) {
              return (
                <PromiseResolver
                  args={data}
                  promise={() =>
                    Api.VOLUNTEER.MEMBERSHIP.get({
                      urlArgs: { contact_id: data.id, type: 'prospect' },
                      params: { pool_type: 'TEAM' },
                    })
                  }
                  render={({ data: result, loading }) => {
                    if (loading) {
                      return <Loading size="small" position="center" />;
                    } else {
                      const isEmpty = !result || result.length === 0;
                      return (
                        <span aria-label={`Unassigned, ${isEmpty}`}>
                          <span aria-hidden="true">{formatBooleanDisplay(isEmpty)}</span>
                        </span>
                      );
                    }
                  }}
                />
              );
            } else {
              const hasNewAssignees = (data?.assignees || []).length > 0;
              const hasOldAssignee = ContactFormatMixin.giving(data, 'assignee');
              const assigned = hasNewAssignees || hasOldAssignee;
              const assignedBool = formatBooleanDisplay(!assigned);
              return (
                <span aria-label={`Unassigned, ${assignedBool}`}>
                  <span aria-hidden="true">{formatBooleanDisplay(!assigned)}</span>
                </span>
              );
            }
          }}
        />
      );
    },
  },

  constituency: {
    width: WIDTHS.L,
    row: (data) => {
      const val = ContactFormatMixin.role(data);
      return (
        <div className="contact-fixed-table--constituency" aria-label={`Constituency, ${val || 'Not Provided'}`}>
          <span aria-hidden="true">{val ? <span>{val}</span> : '-'}</span>
        </div>
      );
    },
  },

  comment: {
    label: 'Comment',
    width: WIDTHS.XL,
    row: (data) => {
      const comment = data?.social?.comment || '';
      return (
        <span aria-label={`Comment, ${comment}`}>
          <span aria-hidden="true">
            <ReadMore height={45}>{comment}</ReadMore>
          </span>
        </span>
      );
    },
  },

  date_added: {
    label: 'Date Added',
    width: WIDTHS.S,
    row: (data) => {
      const date_added = data?.date_added ? moment(data.date_added).format('MMM Do YYYY') : '';
      return (
        <div aria-label={`Added on ${date_added || '-'}`}>
          <span aria-hidden="true">{date_added || '-'}</span>
        </div>
      );
    },
  },

  event_response: {
    label: 'Event Response',
    width: WIDTHS.L,
    row: (data) => {
      return (
        <div className="contact-fixed-table--event-responses">
          {(data?.event_engagement_actions || []).map((action) => (
            <div key={action} className="contact-fixed-table--event-response">
              <EngagementIcon className="contact-fixed-table--event-response-icon" size={18} icon={action} />
              <span className="contact-fixed-table--event-response-label">{getTabByEngagementAction(action)}</span>
            </div>
          ))}
        </div>
      );
    },
  },

  enriched_current_company: {
    width: WIDTHS.M,
    row: (data) => {
      const currentEnrichedEmployment = data?.enrichment?.employments.filter(
        (e) => e.status === 'current' || e.status === null
      );
      return (
        <div aria-label="Enriched Current Company">
          <span aria-hidden="true">
            {currentEnrichedEmployment?.length
              ? currentEnrichedEmployment.some((i) => i.source !== 'fullcontact')
                ? renderMostRecent(
                    currentEnrichedEmployment.filter((e) => e.source !== 'fullcontact'),
                    'company_name'
                  )
                : renderMostRecent(currentEnrichedEmployment, 'company_name')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_current_title: {
    width: WIDTHS.M,
    row: (data) => {
      const currentEnrichedEmployment = data?.enrichment?.employments.filter(
        (e) => e.status === 'current' || e.status === null
      );
      return (
        <div aria-label="Enriched Current Title">
          <span aria-hidden="true">
            {currentEnrichedEmployment?.length
              ? currentEnrichedEmployment.some((i) => i.source !== 'fullcontact')
                ? renderMostRecent(
                    currentEnrichedEmployment.filter((e) => e.source !== 'fullcontact'),
                    'title'
                  )
                : renderMostRecent(currentEnrichedEmployment, 'title')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_metro: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Enriched Metro Area">
          <span aria-hidden="true">
            {(data?.enrichment?.contact_locations || []).some((i) => i.metro_area)
              ? renderMostRecent(data?.enrichment?.contact_locations, 'metro_area')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_city: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Enriched City">
          <span aria-hidden="true">
            {(data?.enrichment?.contact_locations || []).some((i) => i.city)
              ? renderMostRecent(data?.enrichment?.contact_locations, 'city')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_state: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Enriched State">
          <span aria-hidden="true">
            {(data?.enrichment?.contact_locations || []).some((i) => i.state_code)
              ? renderMostRecent(data?.enrichment?.contact_locations, 'state_code')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_country: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Enriched Country">
          <span aria-hidden="true">
            {(data?.enrichment?.contact_locations || []).some((i) => i.country)
              ? renderMostRecent(data?.enrichment?.contact_locations, 'country')
              : '-'}
          </span>
        </div>
      );
    },
  },

  enriched_linkedin: {
    width: WIDTHS.M,
    row: (data) => {
      const url = (data?.enrichment?.social_profiles || []).find((profile) => profile.type === 'linkedin')?.url;
      return (
        <div aria-label="Enriched Linkedin URL">
          <span aria-hidden="true">
            {url ? (
              <Link className="fs-exclude-data" target="_blank" href={url} title="View Linkedin">
                View Linkedin
              </Link>
            ) : (
              '-'
            )}
          </span>
        </div>
      );
    },
  },

  enriched_facebook: {
    width: WIDTHS.M,
    row: (data) => {
      const url = (data?.enrichment?.social_profiles || []).find((profile) => profile.type === 'facebook')?.url;
      return (
        <div aria-label="Enriched Facebook URL">
          <span aria-hidden="true">
            {url ? (
              <Link className="fs-exclude-data" href={url} target="_blank" title="View Facebook">
                View Facebook
              </Link>
            ) : (
              '-'
            )}
          </span>
        </div>
      );
    },
  },

  enriched_twitter: {
    width: WIDTHS.M,
    row: (data) => {
      const url = (data?.enrichment?.social_profiles || []).find((profile) => profile.type === 'twitter')?.url;
      return (
        <div aria-label="Enriched Twitter URL">
          <span aria-hidden="true">
            {url ? (
              <Link className="fs-exclude-data" target="_blank" href={url} title="View Twitter">
                View Twitter
              </Link>
            ) : (
              '-'
            )}
          </span>
        </div>
      );
    },
  },

  career_moves_type: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Career Moves Type">
          <span aria-hidden="true">
            {data?.enrichment?.employment_events?.length
              ? renderMultiple(data?.enrichment?.employment_events, 'type')
              : '-'}
          </span>
        </div>
      );
    },
  },

  career_moves_date: {
    width: WIDTHS.M,
    row: (data) => {
      return (
        <div aria-label="Career Moves Last File Date">
          <span aria-hidden="true">{renderMostRecentDate(data?.enrichment?.employment_events, 'updated_at')}</span>
        </div>
      );
    },
  },

  career_moves_start_date: {
    width: WIDTHS.M,
    row: (data) => {
      const employments = data?.enrichment?.employments;
      return (
        <div aria-label="Career Moves Start Date">
          <span aria-hidden="true">{renderMostRecentDate(employments, 'start_date')}</span>
        </div>
      );
    },
  },

  spacer: {
    label: '',
    width: 'auto',
    className: 'contact-fixed-table--col-spacer',
    row: (data) => {
      return (
        <div className="contact-fixed-table--edit-button">
          <ModalTrigger
            closeOnOutsideClick={true}
            buttonProps={{ type: 'secondary' }}
            buttonText={
              <>
                <Icon icon="edit" />
                Edit Columns
              </>
            }
          >
            {({ closeModal }) => <ColumnSelector requestHide={closeModal} />}
          </ModalTrigger>
        </div>
      );
    },
  },
};

export default ContactTableRows;
