import { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover2 } from '@evertrue/et-components';

const TextQuery = ({ part, index, handleInputSubmit, setEditingField }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Popover2
      key={part.value.value}
      trigger={({ openPopover }) => (
        <span
          className="text-highlighted-field"
          onClick={() => {
            setEditingField(index);
            setInputValue(part.value.value);
            openPopover();
          }}
        >
          {part.value.value}
        </span>
      )}
    >
      {({ closePopover }) => (
        <div className="text-popover-content">
          <h3 className="input-title">Update Search</h3>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleInputSubmit(index, inputValue);
                closePopover();
              }
            }}
            placeholder="Enter new value"
          />
        </div>
      )}
    </Popover2>
  );
};

TextQuery.propTypes = {
  part: PropTypes.object,
  index: PropTypes.number,
  handleInputSubmit: PropTypes.func,
  setEditingField: PropTypes.func,
};

export default TextQuery;
