import { bulkRemoveConstituentsFromJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useIdentity } from 'base/identity-resolver';
import ErrorLogger from 'entities/helpers/error-log-helper';
import FormField from 'components/forms/form-field';

/**
 * The BulkRemoveModal component renders a modal for bulk removing selected constituents from a journey.
 *
 * @param {Object} props - The props for the component.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {Object} props.journey - The current journey object providing context for the constituents being removed.
 * @param {Array} props.selectedTasks - Array of selected task objects, each containing a `taskId` and `contactId`.
 * @param {function} [props.optionalStateManagementFunction] - Optional function for managing external state, such as updating the parent component after removal.
 */
const BulkRemoveModal = ({ onClose, isOpen, journey, selectedTasks, optionalStateManagementFunction }) => {
  const { user, org } = useIdentity();

  /* uncomment the below code when Journey moves changes integrated */
  // const RREASON_OPTIONS = [
  //   { label: "Adjusting cultivation strategy", value: 1 },
  //   { label: "Event/trip/giving day has concluded", value: 2 },
  //   { label: "Opted out", value: 3 },
  //   { label: "Unresponsive", value: 4 },
  //   { label: "Bad contact information", value: 5 },
  //   { label: "Transition to another portfolio", value: 6 },
  //   { label: "Other", value: 7 },
  // ];

  const handleBulkRemove = async () => {
    const contactIds = [...new Set(selectedTasks.map((taskObj) => taskObj?.target_contact_id || taskObj?.contact?.id))];

    try {
      await bulkRemoveConstituentsFromJourney(contactIds, user.id, org.id, journey.id, journey.name);
      optionalStateManagementFunction('bulkRemoveContacts', { contactIdsToRemove: contactIds });
      trackJourneysAction('bulk_remove');
      onClose();
    } catch (error) {
      ErrorLogger.error('Failed to bulk remove constituents from journey:', {
        extra: { error, page_name: 'BulkRemoveModal' },
        tags: { journeyId: journey.id, contactIds: JSON.stringify(contactIds) },
      });
    }
  };

  // const reasonLabel = (
  //   <span>
  //     Reason <span className="form-field--required">Required</span>
  //   </span>
  // );

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Remove From Cadence"
        closeModal={onClose}
        onSubmit={() => {
          handleBulkRemove(selectedTasks);
        }}
        disableSubmit={false}
      />
      <ModalBody>
        <FormField label={`${selectedTasks.length} Constituents (Tasks) Selected`} boldLabel={true} inlineLabel={false}>
          <div className="modal-alert--message">
            You are taking action on all&nbsp;<strong>{selectedTasks.length} Constituents (Tasks)</strong>&nbsp;selected
            within the same step.
          </div>
        </FormField>
        <FormField label={'Current Cadence'} boldLabel={true} inlineLabel={false}>
          <span>{journey.name}</span>
        </FormField>
        {/* uncomment the below code when Journey moves changes integrated */}
        {/* <FormField label={reasonLabel} boldLabel={true} inlineLabel={false}>
          <ListboxSelect
            options={RREASON_OPTIONS}
            size="medium"
            onChange={setSelected}
            value={selected}
            emptyValue="Select Reason..."
            label=""
          />
        </FormField> */}
      </ModalBody>
    </Modal>
  );
};

BulkRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  journey: PropTypes.object.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default BulkRemoveModal;
