/* eslint no-console:0 */
import _ from 'underscore';
import EverTrue from 'app';
import { createStore } from '@evertrue/et-flux';
import UserSource from 'base/user/user-source';
import OrgSource from 'base/org/org-source';
import GateSource from 'apps/layout/sources/gate-source';
import AdminGateSource from 'apps/admin/sources/admin-gate-source';

// Gating on a user level (currently stored in LocalStorage across sessions)
const _user_gates = { suggested_searches: 'suggested_searches' };

// Key/Value for references to keys
const _gate_groups = {
  gift_officer: {
    interactions: {
      interaction_custom_fields: 'interaction_custom_fields',
      interaction_writes: 'interaction_writes',
      interaction_exports: 'interaction_exports',
      ugc_show_imported_notes: 'ugc_show_imported_notes',
    },
    portfolio: {
      dxo_assignments_view_others: 'dxo_assignments_view_others',
      major_gifts: 'major_gifts',
      rm_assignments: 'rm_assignments',
      rm_assignments_view_others: 'rm_assignments_view_others',
      rm_key_prospect: 'rm_key_prospect',
      rm_gift_notifications: 'rm_gift_notifications',
      rm_portfolio_stage_writes: 'rm_portfolio_stage_writes',
    },
    proposals: {
      proposal_custom_fields: 'proposal_custom_fields',
      proposal_designation_dollars: 'proposal_designation_dollars',
      proposal_reads: 'proposal_reads',
      proposal_reported: 'proposal_reported',
      proposal_writes: 'proposal_writes',
    },
    prospect_writes: {
      write_assignee: 'profile_write_assignee',
    },
    // reporting: {
    //   my_team: "my_team",
    // },
    trips: {
      my_trips: 'my_trips',
      manage_calendar_sync: 'manage_calendar_sync',
    },
  },
  annual_fund: {
    data_science: {
      annual_giving_score: 'annual_giving_score',
    },
    online_giving: {
      give_reporting: 'give_reporting',
    },
    volunteers: {
      joint_assignment: 'joint_assignment',
      volunteer_gift_notifications: 'volunteer_gift_notifications',
      volunteers: 'volunteers',
      volunteer_sso: 'volunteer_sso',
      volunteers_stages: 'volunteers_stages',
      vms_cdc: 'vms_cdc',
      vms_committed_amounts: 'vms_committed_amounts',
      vms_head_agent: 'vms_head_agent',
      vms_head_agent_table: 'vms_head_agent_table',
      vms_head_agent_reporting_summary: 'vms_head_agent_reporting_summary',
      vms_insights: 'vms_insights',
    },
  },
  other: {
    community: {
      et_community: 'et_community',
      mentors: 'community_mentors',
      map: 'community_map',
    },
    giving: {
      hybrid_giving: 'hybrid_giving',
      categorized_giving: 'categorized_giving',
    },
    events: {
      events: 'events',
      events_eventbrite: 'events_eventbrite',
      events_facebook: 'events_facebook',
      everhub: 'everhub',
    },
    misc: {
      cdc_profile: 'cdc_profile',
      dxo: 'dxo',
      emma: 'emma',
      export_settings: 'export_settings',
      facebook_soda_message: 'facebook_soda_message',
      graduway: 'graduway',
      hide_a11y_compliance: 'hide_a11y_compliance',
      imported_pledge_balance: 'pledge_balance_field',
      linkedin_reauth: 'linkedin_reauth',
      multiple_assignee: 'multiple_assignees',
      recurring_payment_field: 'recurring_payment_field',
      scores: 'scores',
      tableau_enabled: 'tableau_enabled',
      two_factor_auth: 'two_factor_auth',
    },
    enrichment: {
      career_updates: 'career_updates',
      enrichment_data: 'enrichment_data',
      enrichment_export_active: 'enrichment_export_active',
      windfall: 'windfall',
      windfall_wealth_plus: 'windfall_wealth_plus',
    },
  },
  'DANGER: PRODUCT DEVELOPMENT ONLY': {
    product: {},
    engineering: {
      unreleased_feature: 'unreleased_feature',
      front_end_development: 'front_end_development',
    },
  },
};

const _gates = {};
_.each(_gate_groups, (product) =>
  _.each(product, (feature) =>
    _.each(feature, (dna_feature_key, gt_feature_key) => {
      _gates[gt_feature_key] = dna_feature_key;
    })
  )
);

export default createStore('FeatureStore', {
  getInitialState() {
    return {
      features: {},
      all_gates_loaded: false,
      user_features: [],
      defaults: undefined,
      error_loading_gates: false,
      org_only_gates: undefined,
      org_only_gate_loading: undefined,
      gates_loading: false,
    };
  },

  registerActions() {
    this.on(UserSource.actions.newUser, () => GateSource.fetch());
    this.on(OrgSource.actions.newOrg, () => GateSource.fetch());

    this.on(GateSource.actions.fetchedGates, this.respondToFetchedGates);
    this.on(GateSource.actions.clearedGates, this.respondToClearedGates);
    this.on(GateSource.actions.fetchedDefaults, this.respondToFetchedDefaults);
    this.on(GateSource.actions.errorFetchingGates, this.respondToGatesError);
    this.on(GateSource.actions.refreshUserGates, this.respondToUserGates);
    this.on(GateSource.actions.loadingGates, (gates_loading) => {
      this.setState({ gates_loading });
    });

    // For SuperUser Admin Updates
    this.on(AdminGateSource.actions.updatedUserGate, this.respondToUpdateAdminGate);
    this.on(AdminGateSource.actions.resetUserGates, this.respondToUserReset);
    this.on(AdminGateSource.actions.fetchedOrgGates, this.respondToFetchedOrgGates);
    this.on(AdminGateSource.actions.loadingOrgLevelGates, this.respondToLoadingOrgGates);
  },

  respondToFetchedGates(gates, key) {
    if (key) {
      this.setState({ features: _.extend({}, this.getState('features'), gates.features) });
      return; // stop here or get infinite request loop!
    }
    this.setState({ features: gates.features || {}, all_gates_loaded: true });
    if (
      ((EverTrue.config != null ? EverTrue.config.gate_warning : undefined) ||
        __guard__(EverTrue.store != null ? EverTrue.store.user : undefined, (x) => x.isSuperUser())) &&
      _.isUndefined(this.getState('defaults'))
    ) {
      GateSource.fetchDefaults();
    }
    // TODO: This is to support legacy events, but should no longer be used
    // instead use GateSource actions or another action
    EverTrue.vent.trigger('org:initialized');
  },

  respondToClearedGates() {
    this.setState({ features: {} });
  },

  respondToFetchedDefaults(gates) {
    const default_gates = _.compact(
      _.map(gates.features, (feature, key) => {
        if (feature.enabled) {
          return key;
        }
        return undefined;
      })
    );
    this.setState({ defaults: default_gates });

    const enabled = _.intersection(_.values(_gates), default_gates);
    if (!_.isEmpty(enabled)) {
      console.warn(
        `GATE DEPRECATION: Some gates (${enabled.join(', ')}) are enabled by default. Check if ready to remove.`
      );
    }
  },

  respondToGatesError() {
    // Make sure ui version is set, so UIVersionController always loads something
    this.setState({
      error_loading_gates: true,
    });
  },

  respondToUserGates() {
    if (!localStorage) {
      return;
    }
    const user_features = [];
    _.each(_user_gates, (gate, key) => {
      const { global_prefix } = EverTrue.store;
      const inStorage = _.jsonParse(localStorage.getItem(global_prefix + gate));

      // last timestamp to check when storage should expired,
      // updating this will clear the cache to show these gates again
      const expire = 1412272002038;

      if (inStorage) {
        if (inStorage.timestamp > expire || !inStorage.timestamp) {
          if (inStorage.state !== 'disabled') {
            user_features.push(key);
          }
        }
      } else {
        user_features.push(key);
      }
    });
    this.setState({ user_features });
  },

  respondToUpdateAdminGate(key, gate) {
    this.setState({ features: _.extend({}, this.getState('features'), gate.features) });
  },

  respondToUserReset() {
    GateSource.fetch();
  },

  respondToFetchedOrgGates(org_gates) {
    this.setState({ org_only_gates: org_gates.features });
  },

  respondToLoadingOrgGates(is_loading) {
    this.setState({ org_only_gate_loading: is_loading });
  },

  api: {
    hasFeature(key) {
      const user_gated = _.contains(this.getState('user_features'), _user_gates[key]);
      const oid_gated = _.contains(this.getFeatures(), _gates[key]);
      return oid_gated || user_gated;
    },

    getFeature(key) {
      // deprecated: do not use this.  use hasFeature() instead.
      // id speed is needed, implement localStorage caching.
      const gate = _gates[key];
      if (!gate) {
        console.warn(`Feature Store Warning: '${key}' was not a feature key`);
        return false;
      }
      const features = this.getState('features');
      const feature = features != null ? features[gate] : undefined;
      if (_.isUndefined(feature)) {
        GateSource.fetch(gate);
      }
      return feature != null ? feature.enabled : undefined;
    },

    getFeatures() {
      return _.compact(
        _.map(this.getState('features'), (feature, key) => {
          if (feature.enabled) {
            return key;
          }
          return undefined;
        })
      );
    },

    getUsedWebFeatures() {
      const web_features = _.pick(this.getState('features'), _.values(_gates));
      return _.compact(
        _.map(web_features, (feature, key) => {
          let grouping_data = {};
          _.find(_gate_groups, (product, product_key) =>
            _.find(product, (product_feature, feature_key) => {
              if (_.invert(product_feature)[key]) {
                grouping_data = { product: product_key, feature: feature_key };
              }
            })
          );
          return _.extend({}, feature, { key }, grouping_data);
        })
      );
    },

    getOrgLevelFeatures() {
      const web_features = _.pick(this.getState('org_only_gates'), _.values(_gates));
      return _.compact(_.map(web_features, (feature, key) => _.extend({}, feature, { key })));
    },

    getOrgOnlyGatesLoading() {
      return this.getState('org_only_gate_loading');
    },

    hasFeatureLoaded(key) {
      const features = _.keys(this.getState('features'));
      return _.contains(features, _gates[key]) || this.getState('error_loading_gates');
    },

    // Return true if all gates were loaded vs. just individual ones
    hasFeaturesLoaded() {
      return this.getState('all_gates_loaded');
    },

    hasInteractionWrites() {
      return this.getFeature('interaction_writes') && this.getFeature('ugc_show_imported_notes');
    },

    hasInteractionExports() {
      return this.getFeature('interaction_writes') || this.getFeature('interaction_exports');
    },

    getGatesLoading() {
      return this.state.gates_loading;
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
