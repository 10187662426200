import { ActionsMenu } from '@evertrue/et-components';
import { trackActionOnTask } from 'apps/journeys/utils/helpers/mixpanel';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import BulkDeleteModal from 'apps/journeys/components/modals/bulk-move/bulk-delete-modal';
import TaskEmailAlertsModal from './create/task-email-alerts-modal';
import BulkTaskCompleteModal from 'apps/journeys/components/modals/bulk-move/bulk-task-complete-modal';
import BulkTaskReassignModal from 'apps/journeys/components/modals/bulk-move/bulk-task-reassign-modal';
import UpdateTaskDueDateModal from 'apps/journeys/components/modals/bulk-move/update-task-due-date-modal';

const TasksActionMenu = ({
  selectedDueDate,
  bulkSelectedTasks,
  referrer,
  hasBulkTaskMoves,
  resetBulkSelect = () => {},
}) => {
  const handleNavigateToSettings = () => {
    EverTrue.UrlManager.navigate('settings/cadences', true, false);
  };

  const selectedTaskCount = bulkSelectedTasks?.length || 0;
  const isAllCadence = bulkSelectedTasks.every((t) => !!t.contact_journey_id && !!t.journey_task_id);
  const selectedTaskIds = bulkSelectedTasks.map((taskObj) => taskObj.id);
  const areAllSameDueDate = bulkSelectedTasks.every(
    (t) => t.current_due_date === bulkSelectedTasks[0]?.current_due_date
  );

  const generalOptions = [
    {
      id: 1,
      icon: 'email-alert',
      iconKit: 'gt2',
      label: 'Email Alerts',
      mountOnClick: ({ is_open, close }) => {
        return <TaskEmailAlertsModal isModalOpen={is_open} closeModal={close} />;
      },
    },
    {
      id: 2,
      icon: 'gear',
      label: 'Settings',
      onClick: handleNavigateToSettings,
    },
  ];

  const bulkMoveOptions = [
    {
      id: 3,
      label: 'Update Due Date',
      icon: 'update-due-date',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <UpdateTaskDueDateModal
            isOpen={is_open}
            onClose={() => {
              close();
              resetBulkSelect();
            }}
            tasks={bulkSelectedTasks}
            selectedDueDate={areAllSameDueDate ? bulkSelectedTasks[0]?.current_due_date : selectedDueDate}
            trackAction={trackActionOnTask}
            areAllSameDueDate={areAllSameDueDate}
          />
        );
      },
    },
    {
      id: 4,
      label: 'Mark as Complete',
      icon: 'success-circle',
      iconKit: 'gt',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkTaskCompleteModal
            isOpen={is_open}
            onClose={() => {
              close();
              resetBulkSelect();
            }}
            isComplete={true}
            tasks={bulkSelectedTasks}
            trackAction={trackActionOnTask}
          />
        );
      },
    },
    {
      id: 5,
      label: 'Mark as Incomplete',
      icon: 'incomplete-task',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkTaskCompleteModal
            isOpen={is_open}
            onClose={() => {
              close();
              resetBulkSelect();
            }}
            tasks={bulkSelectedTasks}
            trackAction={trackActionOnTask}
          />
        );
      },
    },
    {
      id: 6,
      label: 'Reassign Tasks',
      icon: 'reassign-tasks',
      iconKit: 'gt2',
      disabled: isAllCadence,
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkTaskReassignModal
            isOpen={is_open}
            onClose={() => {
              close();
              resetBulkSelect();
            }}
            allSelectedTasks={bulkSelectedTasks}
          />
        );
      },
    },
    {
      id: 7,
      label: 'Delete Tasks',
      icon: 'incomplete-task',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkDeleteModal
            isOpen={is_open}
            onClose={() => {
              close();
              resetBulkSelect();
            }}
            selectedTaskIds={selectedTaskIds}
            fromTasks={true}
          />
        );
      },
    },
  ];

  const getOptions = () => {
    const options = [];

    if (referrer !== 'contact') {
      options.push({
        id: 'general-options',
        items: generalOptions,
      });
    }

    if (hasBulkTaskMoves && selectedTaskCount > 0) {
      options.push({
        id: 'bulk-move-options',
        heading: `Action on ${selectedTaskCount} Task${selectedTaskCount > 1 ? 's' : ''}`,
        items: bulkMoveOptions,
      });
    }

    return options;
  };

  const options = getOptions();
  return options.length ? <ActionsMenu options={options} /> : null;
};

TasksActionMenu.propTypes = {
  selectedDueDate: PropTypes.string,
  bulkSelectedTasks: PropTypes.array,
  referrer: PropTypes.string,
  hasBulkTaskMoves: PropTypes.bool,
  resetBulkSelect: PropTypes.func,
};

export default TasksActionMenu;
