import ErrorLogger from 'entities/helpers/error-log-helper';

import {
  ANNUAL_GIVING_LABEL,
  ANNUAL_GIVING_PROGRESSION,
  ANNUAL_GIVING_SUMMARY,
  APP_USAGE_V2,
  APPEALS_IMPACT,
  CLARITY_PREVIEW,
  DONORS_BY_FISCAL_YEAR,
  DONORS_BY_YEAR,
  DXPM_REPORTING,
  EVERTRUE_APP_USAGE,
  FILE_AVAILABILITY,
  FUNDRAISER_INSIGHTS,
  FUNDRAISING_ANALYTICS,
  FUNDRAISING_TRENDS_LABEL,
  INCLUDED_WITH_EVERTRUE_LABEL,
  LEVEL_PROGRESSION,
  ONE_TEAM_LABEL,
  PORTFOLIO_CONTACTS,
  PORTFOLIO_ENGAGEMENT_CUSTOMERS,
  PORTFOLIO_ENGAGEMENT,
  PORTFOLIOS_BENCHMARKING,
  PROPOSAL_PIPELINE_CUSTOMERS,
  PROPOSAL_PIPELINE,
  PROSPECT_MANAGEMENT_LABEL,
  SIGNAL_ACTIVITY_LABEL,
  SIGNAL_ACTIVITY_YOY,
  SIGNAL_ACTIVITY,
  SIGNAL_DXO_MEETINGS_AND_ASKS,
  SIGNAL_INFLUENCE_DOLLARS,
  SIGNAL_TASK_TRACKER,
  SIGNAL_TOP_PROSPECTS,
  STANDARDIZATION,
  THANKVIEW,
  TOTAL_INTERACTIONS,
} from '../constants';

export function formatClarityDashboardResponse(data) {
  const clarityDashboardCollection = {
    [SIGNAL_ACTIVITY_LABEL]: [],
    [FUNDRAISING_TRENDS_LABEL]: [],
    [PROSPECT_MANAGEMENT_LABEL]: [],
    [ANNUAL_GIVING_LABEL]: [],
    [INCLUDED_WITH_EVERTRUE_LABEL]: [],
    [ONE_TEAM_LABEL]: [],
  };

  data.forEach((item) => {
    switch (item.name) {
      case FUNDRAISING_ANALYTICS:
        clarityDashboardCollection[FUNDRAISING_TRENDS_LABEL].push(item);
        break;

      case ANNUAL_GIVING_SUMMARY:
      case ANNUAL_GIVING_PROGRESSION:
      case APPEALS_IMPACT:
      case DONORS_BY_FISCAL_YEAR:
        clarityDashboardCollection[ANNUAL_GIVING_LABEL].push(item);
        break;

      case FUNDRAISER_INSIGHTS:
      case PORTFOLIO_ENGAGEMENT:
      case PORTFOLIO_CONTACTS:
      case SIGNAL_TOP_PROSPECTS:
      case PROPOSAL_PIPELINE:
      case PORTFOLIOS_BENCHMARKING:
        clarityDashboardCollection[PROSPECT_MANAGEMENT_LABEL].push(item);
        break;

      case SIGNAL_TASK_TRACKER:
      case SIGNAL_ACTIVITY:
      case SIGNAL_ACTIVITY_YOY:
      case SIGNAL_DXO_MEETINGS_AND_ASKS:
      case SIGNAL_INFLUENCE_DOLLARS:
        clarityDashboardCollection[SIGNAL_ACTIVITY_LABEL].push(item);
        break;

      case STANDARDIZATION:
      case APP_USAGE_V2:
      case CLARITY_PREVIEW:
        clarityDashboardCollection[INCLUDED_WITH_EVERTRUE_LABEL].push(item);
        break;

      case DONORS_BY_YEAR:
      case DXPM_REPORTING:
      case EVERTRUE_APP_USAGE:
      case FILE_AVAILABILITY:
      case LEVEL_PROGRESSION:
      case PORTFOLIO_ENGAGEMENT_CUSTOMERS:
      case PROPOSAL_PIPELINE_CUSTOMERS:
      case THANKVIEW:
      case TOTAL_INTERACTIONS:
        clarityDashboardCollection[ONE_TEAM_LABEL].push(item);
        break;

      default:
        ErrorLogger.warn('Dashboard does not match predefined categories', { extra: { name: item.name } });
    }
  });

  return clarityDashboardCollection;
}
