import { PropTypes } from "base/react-libs";
import { Button } from "@evertrue/et-components";

const TwilioCallButton = ({
  device,
  activeCall,
  startCall,
  endCall,
  phoneNumber,
  extension,
  contactId,
  onCallDisconnect = () => {},
}) => {
  return activeCall ? (
    <Button onClick={endCall} type="destroy" className="red-button">
      End Call
    </Button>
  ) : (
    <Button disabled={!!!device} onClick={() => startCall(phoneNumber, extension, contactId, onCallDisconnect)}>
      Call
    </Button>
  );
};

TwilioCallButton.propTypes = {
  device: PropTypes.object, // Twilio Caller Device provided via useTwilio hook.
  activeCall: PropTypes.object, // the active call (or Null, if no call is active). provided via useTwilio Hook
  startCall: PropTypes.func, // function to initiate a call. provided via useTwilio Hook
  endCall: PropTypes.func, // function to end a call. provided via useTwilio Hook
  phoneNumber: PropTypes.string, // Number to call. provided by the dialer.
  extension: PropTypes.string, // Extension to call. provided by the dialer.
  contactId: PropTypes.number, // Contact ID of the person we are calling. provided by the dialer
  onCallDisconnect: PropTypes.func, // Function to call when the call ends/disconnects. Provided by the dialer
};
export default TwilioCallButton;
