import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'underscore';
import Decorator from 'clientDecorator';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import ActivityFacepile from '../activity-facepile';

const ContactActivity = ({ item, currentUser }) => (
  <li className="list-activity--feed-item">
    <div className="list-activity--label">
      <strong
        className={classNames({
          'is-empty': _.isEmpty(item.user),
          'text-removed-user': item.user?.removed,
        })}
      >
        {Decorator.User.getName(item.user || {}, currentUser) || ' '}
      </strong>
      <span>
        {' '}
        {item.label} on {moment(item.created_at).format('MMM D')}
      </span>
    </div>
    <div>
      {item.activities.length < 3 ? (
        item.activities.map((activity) => (
          <div key={activity.target_id} className="list-activity--constituents">
            <ContactCard
              key={activity.target_id}
              contact={activity.target_data || {}}
              referrer="list_activity"
              showRemoteId
              includeMiddle
            />
          </div>
        ))
      ) : (
        <div className="list-activity--facepile">
          <ActivityFacepile
            list={item.activities.map((activity) => activity.target_data)}
            label={`${Decorator.User.getName(item.user || {}, currentUser)} ${item.label} on ${moment(
              item.created_at
            ).format('MMM D')}`}
          />
        </div>
      )}
    </div>
  </li>
);

ContactActivity.propTypes = {
  item: PropTypes.object.isRequired,
  currentUser: PropTypes.number.isRequired,
};

export default ContactActivity;
