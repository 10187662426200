import VideoEntry from "./video-entry";
import PropTypes from "prop-types";

const VideoList = ({ listings, libraryVideos, selectedVideos, onToggleSelection, onDeleteVideo }) => (
  <>
    {listings.map((video) => {
      const libraryVideo = libraryVideos.find((x) => x.id === video.library_video_id);
      return (
        <li key={video.id} className="video-library--entry">
          <VideoEntry
            video={video}
            libraryVideo={libraryVideo}
            deleteVideoEntry={onDeleteVideo}
            isSelected={selectedVideos.includes(video.id)}
            onToggleSelection={() => onToggleSelection(video.id)}
          />
        </li>
      );
    })}
  </>
);

VideoList.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  libraryVideos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  selectedVideos: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleSelection: PropTypes.func.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
};

export default VideoList;
