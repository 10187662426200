import PropTypes from 'prop-types';
import { useOpenClose, Button } from '@evertrue/et-components';
import JourneyModal from '../modals/journey-modal';

const AddJourneyButton = ({ handleCreateJourney = () => {} }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  return (
    <>
      <Button onClick={openModal}>Create New Cadence</Button>
      <JourneyModal isOpen={isModalOpen} onClose={closeModal} handleCreateJourney={handleCreateJourney} />
    </>
  );
};

AddJourneyButton.propTypes = {
  handleCreateJourney: PropTypes.func,
};

export default AddJourneyButton;
