_ = require("underscore").default
numeral = require("numeral")
Decorator = require("clientDecorator")

NOPE = "Not Available"

formatNumber = (val) ->
   if _.isFinite(val)
      sigFigs = parseFloat(val.toPrecision(2))
      numeral(sigFigs).format("$0[.]0a").toUpperCase()

formatRange = (lower_value, upper_value) ->
   lower = formatNumber(lower_value)
   upper = formatNumber(upper_value)

   if lower and upper then "#{lower} - #{upper}" else NOPE

AddressMixin =
      getAddresses: (contact) ->
         Decorator.Address.getAddresses(contact)

      getDefaultAddress: (contact) ->
         return unless contact
         Decorator.Address.getPrimary(contact)

      getAddressType: (contact) ->
         return unless contact
         Decorator.Address.getPrimary(contact)?.type?.value

      formatLastLn: (address) ->
         Decorator.Address.getLastLineFormat(address)

      formatAddressObject: (address = {}) ->
         parsed_address = _.mapObject address, (val) -> (val && val.value) || ""

         { address_1, city, state, type, house_value_lq, house_value_uq, state_prov, source} = parsed_address
         { income_median, house_value_median } = parsed_address

         stateVal = state || state_prov

         return {
            line_1: address_1
            city
            state: stateVal
            type
            city_state_zip: @formatLastLn(address)
            range: formatRange(house_value_lq, house_value_uq)
            median_income: formatNumber(income_median) || NOPE
            median_home: formatNumber(house_value_median) || NOPE
            source
         }

      isValidAddress: (address) ->
         Decorator.Address.isValidAddress(address)

      hasValidAddress: (contact) ->
         Decorator.Address.hasValidAddress(contact)

      formatMapsLink: (address) ->
         address_string = ""
         address_string += " " + address.address_1?.value if address.address_1?.value
         address_string += " " + address.address_2?.value if address.address_2?.value
         address_string += " " + address.address_3?.value if address.address_3?.value
         address_string += " " + AddressMixin.formatLastLn(address)
         "https://www.google.com/maps/search/?api=1&query=#{encodeURIComponent(address_string)}"

module.exports = AddressMixin
