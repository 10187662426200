import EverTrue from 'app';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';
import ErrorLogger from 'entities/helpers/error-log-helper';

const thankviewApiCall = async (method, endpoint, data = null, clientId, clientSecret, isFormData = false) => {
  const url = `https://thankview.com/api/${endpoint}`;
  let options = {
    method: method,
    headers: {
      'Signal-Client-Key': clientId,
      'Signal-Client-Secret': clientSecret,
    },
  };

  if (data) {
    if (isFormData) {
      if (!(data instanceof FormData)) {
        // If isFormData is true but data is not FormData, create a new FormData object
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            if (typeof value === 'boolean') {
              formData.append(key, value ? 'true' : 'false');
            } else if (value instanceof File) {
              formData.append(key, value, value.name);
            } else {
              formData.append(key, value.toString());
            }
          }
        });
        options.body = formData;
      } else {
        options.body = data;
      }
      // Don't set Content-Type header for FormData, let the browser handle it
    } else {
      // If not FormData, stringify the data and set Content-Type to JSON
      options.body = JSON.stringify(data);
      options.headers['Content-Type'] = 'application/json';
    }
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      let errorMessage;
      try {
        const errorJson = JSON.parse(errorText);
        errorMessage = errorJson.message || 'API request failed';
      } catch {
        errorMessage = errorText || 'API request failed';
      }
      EverTrue.Alert.error(errorMessage);
    }

    // Check if the response is JSON
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      // If not JSON, return the raw response
      return await response.text();
    }
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const fetchSignalIndex = async (data, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('POST', 'signal/personal/index', data, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};
const setBusinessLogo = async (id, file, clientId, clientSecret) => {
  const url = getLinkUrl('thankView', 'setBusinessLogoUrl');
  try {
    const formData = new FormData();
    formData.append('logo_image', file);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Signal-Client-Key': clientId,
        'Signal-Client-Secret': clientSecret,
      },
      redirect: 'error',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData));
    }

    return await response.json();
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const getBusinessLogo = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/business/${id}/logo`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const removeBusinessLogo = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('DELETE', `signal/business/${id}/logo`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const createAICaptions = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('POST', `signal/captions/${id}/ai`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error('There was an error creating your captions');
  }
};

const pollAiCaptions = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/captions/ai/poll/${id}`, null, clientId, clientSecret);
  } catch (error) {
    ErrorLogger.error('Error polling video captions', { extra: { error: error, page_name: 'SignalVideo' } });
  }
};

const updateCaptions = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('PUT', `signal/captions/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const updateCaptionsFile = async (id, data, clientId, clientSecret) => {
  try {
    if (!(data instanceof FormData)) {
      throw new Error('Data must be an instance of FormData');
    }
    const response = await thankviewApiCall('POST', `signal/captions/${id}`, data, clientId, clientSecret, true);
    return response;
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const setAutoplayCaptions = async (id, data, clientId, clientSecret) => {
  //{   "autoplay_captions": true }
  try {
    return await thankviewApiCall('PUT', `signal/captions/autoplay/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const destroyCaptions = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('DELETE', `signal/captions/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const setLandingPageColors = async (id, data, clientId, clientSecret) => {
  //{   "bkgd_color": "#ffffff",   "button_color": "#000000" }
  try {
    return await thankviewApiCall('POST', `signal/landing/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};
const pollVideoStatus = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/video/${id}/poll`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

// poll trim
const pollVideoTrimStatus = async (libraryVideoId, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/library/${libraryVideoId}/trim/poll`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error with polling for videoId ${libraryVideoId} `);
  }
};

const pollAnimatedThumbnail = async (id, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/animatedThumbnail/${id}/poll`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error with polling for videoId ${id} `);
  }
};

const pollAnimatedThumbnailUntilFinished = async (videoId, clientKey, clientSecret) => {
  const startTime = Date.now();
  const timeoutDuration = 15000; // 15 seconds timeout

  while (Date.now() - startTime < timeoutDuration) {
    const { data } = await pollAnimatedThumbnail(videoId, clientKey, clientSecret);

    if (data?.status === 'finished') {
      return data;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  console.warn('Animated thumbnail polling timed out after 15 seconds');
  return { status: 'failed', animatedThumbnailPath: '' };
};

const fetchVideos = async (clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', 'signal/video', null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const registerSignal = async (data, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('POST', 'signal/register', data, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const updateVideo = async (id, data, clientId, clientSecret) => {
  delete data.logo_url;
  const updateSettings = {
    personalVideo: JSON.stringify(data),
  };
  try {
    return await thankviewApiCall('POST', `signal/personal/update/${id}`, updateSettings, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const trimVideo = async (libraryVideoId, data, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('PUT', `signal/library/${libraryVideoId}/trim`, data, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const revertTrim = async (libraryVideoId, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('PUT', `signal/library/${libraryVideoId}/trim/revert`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const deleteVideo = async (id, clientId, clientSecret) => {
  try {
    await thankviewApiCall('DELETE', `signal/personal/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error deleting ${id}, ${error.message}`);
  }
};
const filterVideos = async (clientId, clientSecret) => {
  //{   "sort": "desc",   "order": "date",   "page": 1,   "limit": 10,   "filters": "{}" }
  try {
    await thankviewApiCall('POST', `signal/personal/index`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error('Error on getting filtered videos');
  }
};

const setDefaultLogoOptions = async (logoEnabled, clientId, clientSecret) => {
  const url = getLinkUrl('thankView', 'setDefaultLogoOptionsUrl');
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Signal-Client-Key': clientId,
        'Signal-Client-Secret': clientSecret,
      },
      redirect: 'error',
      body: JSON.stringify(logoEnabled),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData));
    }

    return await response.json();
  } catch (error) {
    EverTrue.Alert.error(`Error setting default logo options ${error}`);
  }
};

const setDefaultButtonOptions = async (buttonOptions, clientId, clientSecret) => {
  const url = getLinkUrl('thankView', 'setDefaultButtonOptionsUrl');

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Signal-Client-Key': clientId,
        'Signal-Client-Secret': clientSecret,
      },
      body: JSON.stringify(buttonOptions),
      redirect: 'error',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData));
    }
    return await response.json();
  } catch (error) {
    EverTrue.Alert.error(`Error setting default button options ${error}`);
  }
};
const setDefaultLandingPageOptions = async (id, clientId, clientSecret) => {
  //{   "landing_page_id": 1 }
  try {
    await thankviewApiCall('POST', 'signal/personal/setDefaultLandingPage', null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error setting default landing page options ${error.message}`);
  }
};
const setDefaultEnvelopeOptions = async (id, clientId, clientSecret) => {
  //{   "envelope_id": 1 }
  try {
    await thankviewApiCall('POST', 'signal/personal/setDefaultEnvelope', null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error setting default envelope options: ${error.message}`);
  }
};
const setLandingPageOptions = async (id, clientId, clientSecret) => {
  try {
    await thankviewApiCall('POST', `signal/personal/landing/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error setting landing page options for ${id}`);
  }
};
const setEnvelopeOptions = async (id, clientId, clientSecret) => {
  try {
    await thankviewApiCall('POST', `signal/personal/envelope/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error(`Error setting envelope options for ${id}`);
  }
};

const bulkDelete = async (data, clientId, clientSecret) => {
  const url = getLinkUrl('thankView', 'bulkDeleteUrl');
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Signal-Client-Key': clientId,
        'Signal-Client-Secret': clientSecret,
      },
      body: JSON.stringify(data),
      redirect: 'error',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData));
    }

    return await response.json();
  } catch (error) {
    EverTrue.Alert.error('Error bulk deleting videos');
  }
};

const getMetrics = async (id, clientId, clientSecret) => {
  try {
    await thankviewApiCall('GET', `signal/personal/metrics/${id}`, null, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error('Issue on retrieving metrics');
  }
};

const getUserDefaultSettings = async (clientId, clientSecret) => {
  try {
    return await thankviewApiCall('GET', `signal/personal/user/defaultSettings`, null, clientId, clientSecret);
  } catch (error) {
    console.log(error);
  }
};

const setPersonalVideoDefaultSettings = async (settings, clientId, clientSecret) => {
  try {
    return await thankviewApiCall('POST', 'signal/personal/user/setDefaultSettings', settings, clientId, clientSecret);
  } catch (error) {
    EverTrue.Alert.error('Error setting personal video default settings');
  }
};

const setPersonalVideoDefaultLogo = async (file, clientId, clientSecret, id) => {
  try {
    const formData = new FormData();
    formData.append('logo_image', file);
    if (id) {
      formData.append('video_id', id);
    }
    return await thankviewApiCall(
      'POST',
      'signal/personal/user/setDefaultLogo',
      formData,
      clientId,
      clientSecret,
      true
    );
  } catch (error) {
    EverTrue.Alert.error('Error setting default logo');
  }
};

const setPersonalVideoLogo = async (file, id, clientId, clientSecret) => {
  try {
    const formData = new FormData();
    formData.append('logo_image', file);
    formData.append('video_id', id);
    return await thankviewApiCall(
      'POST',
      `signal/personal/settings/logo/${id}`,
      formData,
      clientId,
      clientSecret,
      true
    );
  } catch (error) {
    EverTrue.Alert.error('Error setting personal video logo');
  }
};

const updateVideoSettings = async (id, data, clientId, clientSecret) => {
  const updateSettings = {
    personalVideoSettings: { logo: null },
  };
  try {
    return await thankviewApiCall(
      'PUT',
      `signal/personal/settings/update/${id}`,
      updateSettings,
      clientId,
      clientSecret
    );
  } catch (error) {
    EverTrue.Alert.error(error);
  }
};

const uploadVideoHelper = async (formData, clientId = null, clientSecret = null) => {
  const url = getLinkUrl('thankView', 'uploadVideo');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Signal-Client-Key': clientId,
      'Signal-Client-Secret': clientSecret,
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      `Failed to upload video: ${response.status} ${response.statusText}. Server response: ${JSON.stringify(errorData)}`
    );
  }
  const { data } = await response.json();
  return data;
};

export {
  thankviewApiCall,
  fetchSignalIndex,
  setBusinessLogo,
  getBusinessLogo,
  removeBusinessLogo,
  createAICaptions,
  updateCaptions,
  updateCaptionsFile,
  setAutoplayCaptions,
  pollVideoStatus,
  pollVideoTrimStatus,
  pollAnimatedThumbnailUntilFinished,
  pollAiCaptions,
  fetchVideos,
  registerSignal,
  updateVideo,
  trimVideo,
  revertTrim,
  deleteVideo,
  destroyCaptions,
  setLandingPageColors,
  setLandingPageOptions,
  filterVideos,
  setDefaultLogoOptions,
  setDefaultLandingPageOptions,
  setDefaultButtonOptions,
  setDefaultEnvelopeOptions,
  setEnvelopeOptions,
  bulkDelete,
  getMetrics,
  getUserDefaultSettings,
  setPersonalVideoDefaultSettings,
  setPersonalVideoDefaultLogo,
  setPersonalVideoLogo,
  updateVideoSettings,
  uploadVideoHelper,
};
