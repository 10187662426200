import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';

const OutreachStarToggle = ({ starred, onClick = () => {} }) => {
  return (
    <span style={{ marginTop: '2px' }}>
      <Icon
        icon={starred ? 'favorite-icon-filled' : 'favorite-star'}
        iconKit="gt2"
        className={starred ? `favorited favorite-star` : `favorite-star`}
        onClick={onClick}
      />
    </span>
  );
};

OutreachStarToggle.propTypes = {
  starred: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OutreachStarToggle;
