import { useState, useRef } from "react";
import { PropTypes } from "base/react-libs";
import DatePicker from "react-date-picker";
import DateInput from "components/forms/date-input";
import { Button } from "@evertrue/et-components";
import Icon from "components/elements/icon";
import useOutsideClick from "hooks/use-outside-click";

const DEFAULT_FORMAT = "YYYY-MM-DD";
const WEEK_DAY_NAMES = ["Su", "M", "T", "W", "Th", "F", "Sa"];
const DATE_REGEX = /\d{4}-\d{2}-\d{2}/;

const CalendarPicker = ({ value, label, minDate, maxDate, onChange, hideClear }) => {
  const wrapperRef = useRef(null);
  const [viewDate, setViewDate] = useState(value);

  const handleDateChange = (date) => {
    onChange(date);
  };

  useOutsideClick(wrapperRef, () => handleDateChange(viewDate));

  const handleInputDateChange = (dateString) => {
    if (minDate && dateString < minDate) return;
    if (maxDate && dateString > maxDate) return;
    handleDateChange(dateString);
  };

  const handleRenderSelectedDate = (dayProps, date_check) => {
    if (dayProps.date.format(DEFAULT_FORMAT) === date_check) {
      dayProps.className += " calendar-picker--selected-date";
    }
    return dayProps;
  };

  return (
    <div className="calendar-picker" ref={wrapperRef}>
      {label && <div className="calendar-picker--label">{label}</div>}
      <div className="calendar-picker--header">
        <Button type={"simple"} onClick={() => handleDateChange(viewDate)}>
          <Icon icon="close" />
        </Button>
        <DateInput
          value={viewDate}
          className="calendar-picker--input"
          onLeave={handleInputDateChange}
          onChange={setViewDate}
          disabled={true}
        />
        {!hideClear && (
          <Button
            type={"simple"}
            onClick={() => {
              setViewDate(null);
              handleDateChange(undefined);
            }}
          >
            Clear
          </Button>
        )}
      </div>
      <DatePicker
        viewDate={viewDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={DEFAULT_FORMAT}
        monthFormat="MMM"
        weekDayNames={WEEK_DAY_NAMES}
        todayText="Go to today"
        onChange={handleDateChange}
        onViewDateChange={setViewDate}
        onRenderDay={(day_props) => handleRenderSelectedDate(day_props, viewDate)}
      />
    </div>
  );
};

const datePropType = (props, propName, componentName) => {
  if (props[propName] && !DATE_REGEX.test(props[propName])) {
    new Error("#{componentName} date format is #{DEFAULT_FORMAT}.");
  }
};

CalendarPicker.propTypes = {
  value: datePropType,
  label: PropTypes.string,
  minDate: datePropType,
  maxDate: datePropType,
  onChange: PropTypes.func,
  hideClear: PropTypes.bool,
};

export default CalendarPicker;
