import PropTypes from 'prop-types';
import Nav from 'components/lists/nav';
import NavItem from 'components/lists/nav-item';

const AdminNav = ({ active }) => {
  return (
    <div>
      <Nav className="sub-nav" active={active}>
        <NavItem href="/admin/gates/active" activeWhen="active_gates" label="Your Feature Flags" />
        <NavItem href="/admin/gates/advanced" activeWhen="advanced_gates" label="Advanced Feature Managment" />
      </Nav>
    </div>
  );
};

AdminNav.propTypes = {
  active: PropTypes.string,
};

export default AdminNav;
