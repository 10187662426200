import { PropTypes } from 'base/react-libs';
import { getContactEmailAddress } from 'apps/journeys/utils/helpers/utils';
import DialerDrawerButton from 'components/drawer/components/dialer-drawer-button';
import EmailerDrawerButton from 'components/drawer/components/emailer-drawer-button';

const TaskRowOutreachButtons = ({ task = {} }) => {
  if (!task) return null;
  const { contact, action_type } = task || {};

  return (
    <>
      {task && contact && (
        <>
          {action_type === 'CALL' && <DialerDrawerButton contact={contact} phones={contact.phones || []} />}
          {action_type === 'EMAIL' && (
            <EmailerDrawerButton contact={contact} emailAddress={getContactEmailAddress(contact)} />
          )}
        </>
      )}
    </>
  );
};

TaskRowOutreachButtons.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskRowOutreachButtons;
