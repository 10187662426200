import journeysQueries from '../gql/queries';
import { fetchFromGraphql } from 'entities/helpers/gqlCall';
import EverTrue from 'app';
import Api from 'entities/helpers/api';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const fetchJourneyGroups = async (journeyGroupType) => {
  const query = journeysQueries.fetchJourneyGroups;
  const queryVars = { journeyGroupType, includeInactive: false };
  const { journeyGroups } = await fetchFromGraphql(query, queryVars, 'journeyGroups');

  return journeyGroups;
};

export const fetchJourneysForTable = async (tabId, dueDate, userId) => {
  if (tabId === 1) {
    return await fetchFavoriteJourneys(userId, dueDate);
  } else if (tabId === 2) {
    return await fetchJourneysCreatedByUser(userId, dueDate);
  } else if (tabId === 3) {
    return await fetchAllJourneysForAllJourneyGroups(userId, dueDate, true);
  }
};

export const fetchFavoriteJourneys = async (taskOwnerUserId, selectedDate) => {
  if (!taskOwnerUserId) return [];
  const query = journeysQueries.fetchFavoriteJourneys;
  const queryVars = {
    taskOwnerUserId,
    selectedDate,
  };

  const { favoriteJourneys } = await fetchFromGraphql(query, queryVars, 'favoriteJourneys');
  return favoriteJourneys;
};

export const fetchJourneysCreatedByUser = async (taskOwnerUserId, selectedDate) => {
  if (!taskOwnerUserId) return [];
  const query = journeysQueries.fetchJourneysCreatedByUser;
  const queryVars = {
    taskOwnerUserId,
    selectedDate,
  };

  const { journeysCreatedByUser } = await fetchFromGraphql(query, queryVars, 'journeysCreatedByUser');
  return journeysCreatedByUser;
};

// all args are optional
// if args are provided and includeTaskCounts is true, then the tasks_due_counts field will be included in the response
export const fetchAllJourneysForAllJourneyGroups = async (taskOwnerUserId, date, includeTaskCounts = false) => {
  const groups = await fetchJourneyGroups();

  return (
    await Promise.all(
      groups.map(async (group) => {
        if (includeTaskCounts) return await fetchJourneysWithDueDatesForGroup(group.id, taskOwnerUserId, date);
        else return await fetchJourneysForGroup(group.id, taskOwnerUserId);
      })
    )
  ).flat();
};

// returns an array of Journey objects for the given journeyGroupId
// the only difference is that this query does not include the tasks_due_counts field
export const fetchJourneysForGroup = async (journeyGroupId, userId) => {
  const query = journeysQueries.fetchJourneysForGroup;
  const queryVars = { journeyGroupId, userId };
  const { journeysForJourneyGroup } = await fetchFromGraphql(query, queryVars, 'journeysForJourneyGroup');

  return journeysForJourneyGroup;
};

// returns an array of Journey objects with the tasks_due_counts fields for the given date.
export const fetchJourneysWithDueDatesForGroup = async (journeyGroupId, taskOwnerUserId, selectedDate) => {
  if (!journeyGroupId) return [];

  const query = journeysQueries.fetchJourneysWithDueDatesForGroup;
  const queryVars = {
    journeyGroupId,
    taskOwnerUserId,
    selectedDate,
  };

  const { journeysForJourneyGroup } = await fetchFromGraphql(query, queryVars, 'journeysForJourneyGroup');
  return journeysForJourneyGroup;
};

//createFavoriteJourney need query param
export const createFavoriteJourney = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY_FAVORITES.post({
    params: { journey_id: journeyId },
    success: (response) => {
      EverTrue.Alert.success('Cadence has been favorited');
      return response;
    },
    error(e) {
      ErrorLogger.error('Favorite Error', { extra: { error: e, page_name: 'Cadence Favorite' } });
      return EverTrue.Alert.error('Error in favoriting Cadence, try again later');
    },
  });
};

//DeleteFavoriteJourney need path Variable
export const deleteFavoriteJourney = async (favoriteId) => {
  return await Api.JOURNEYS.JOURNEY_FAVORITE.delete({
    urlArgs: { id: favoriteId },
    success: (response) => {
      EverTrue.Alert.success('Cadence has been unfavorited');
      return response;
    },
    error(e) {
      ErrorLogger.error('Delete Favorite Error', { extra: { error: e, page_name: 'Cadence Favorite' } });
      return EverTrue.Alert.error('Error in unfavoriting Cadence, try again later');
    },
  });
};
