import { useState } from "react";
import EverTrue from "app";
import Api from "entities/helpers/api";
import PropTypes from "prop-types";
import { Icon, Link, Button } from "@evertrue/et-components";

const gql = String.raw;

const iconMap = {
  twitter: "twitter-square-2023",
  linkedin: "linkedin-2020",
  instagram: "instagram-2020",
  facebook: "facebook-circle",
};

const AvatarEditModal = ({ contact = {}, imgUrl = "", socialProfile = {}, onBlackList, requestHide }) => {
  const [step, setStep] = useState(1);
  const [deleteScope, setDeleteScope] = useState(null);

  const handleNextStep = (scope) => {
    setStep(2);
    setDeleteScope(scope);
  };

  const handleProfileRemove = () => {
    const profileMutation = {
      social_profiles: [{ id: socialProfile.id, rejected: true, updated_at: socialProfile.updated_at }],
    };

    const query = gql`
      mutation ($id: BigInt!, $enrichment: EnrichmentInput) {
        contact_mutation(id: $id, enrichment: $enrichment) {
          code
          enrichment_mutation {
            social_profiles_mutations {
              success
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contact.id, enrichment: profileMutation },
      }),
    })
      .then(() => {
        EverTrue.Alert.success("Profile and Image Unlinked");
        onBlackList();
        requestHide();
      })
      .catch(() => {
        EverTrue.Alert.error("Problem unlinking profile. Please try again later");
      });
  };

  const handleImageRemove = () => {
    const query = gql`
      mutation ($id: BigInt!, $profile_image_url_blacklist: [ProfileImageUrlBlacklistInput]) {
        contact_mutation(id: $id, profile_image_url_blacklist: $profile_image_url_blacklist) {
          code
          success
          message
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contact.id, profile_image_url_blacklist: { profile_image_url: imgUrl } },
      }),
    })
      .then(() => {
        EverTrue.Alert.success("Image Removed");
        onBlackList();
        requestHide();
      })
      .catch(() => {
        EverTrue.Alert.error("Problem removing Image. Please try again later");
      });
  };

  const renderStepOne = () => {
    return (
      <>
        This profile image is part of a TrueView social profile link:
        <Link
          href={socialProfile.url}
          target="_blank"
          title="view linkedin profile"
          className="avatar-edit-modal--link"
        >
          <Icon icon={iconMap[socialProfile.type] || "person"} iconKit={socialProfile.type === "twitter" && "gt2"} />
          {socialProfile.username}
        </Link>
        Do you only want to remove the profile image, or remove the image AND the social profile link?
        <div>
          <Button type="destroy" onClick={() => handleNextStep("image")}>
            Remove Image
          </Button>
          <Button type="destroy" onClick={() => handleNextStep("profile")}>
            Remove Both
          </Button>
        </div>
      </>
    );
  };

  const renderStepTwo = () => {
    return (
      <>
        You have requested removal of the the following information from this contact:
        <div>
          {deleteScope === "profile" ? (
            <div>
              <Icon icon="clear" className="avatar-edit-modal--icon" /> Enriched social profile link
            </div>
          ) : null}
          <div>
            <Icon icon="clear" className="avatar-edit-modal--icon" /> Profile image
          </div>
        </div>
        <div>
          <Button onClick={requestHide}>Cancel</Button>
          <Button
            type="destroy"
            onClick={() => (deleteScope === "profile" ? handleProfileRemove() : handleImageRemove())}
          >
            Remove
          </Button>
        </div>
      </>
    );
  };

  return <div className="avatar-edit-modal">{step === 1 ? renderStepOne() : renderStepTwo()}</div>;
};

AvatarEditModal.propTypes = {
  contact: PropTypes.object,
  imgUrl: PropTypes.string,
  socialProfile: PropTypes.object,
  onBlackList: PropTypes.func,
  requestHide: PropTypes.func,
};

export default AvatarEditModal;
