import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const CountdownTimer = ({ onCountdownEnd }) => {
  const [count, setCount] = useState(3);
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1);
  const startTimeRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const animate = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      const elapsed = timestamp - startTimeRef.current;
      const secondsElapsed = Math.floor(elapsed / 1000);
      const newCount = 3 - secondsElapsed;

      if (newCount >= 0) {
        setCount(newCount);
        const progress = (elapsed % 1000) / 1000;
        setOpacity(progress < 0.5 ? progress * 2 : 2 - progress * 2);
        setScale(1 + (1 - progress) * 0.3);

        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        onCountdownEnd();
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [onCountdownEnd]);

  return (
    <div className="recorder-overlay countdown fadeIn">
      <span
        className="countdown-number"
        style={{
          color: '#3367F2',
          fontSize: '14em',
          opacity: opacity,
          transform: `scale(${scale})`,
          transition: 'transform 0.3s ease-out',
        }}
      >
        {count || ''}
      </span>
    </div>
  );
};

CountdownTimer.propTypes = {
  onCountdownEnd: PropTypes.func.isRequired,
};

export default CountdownTimer;
