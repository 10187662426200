import { useState, useCallback, useEffect } from 'react';
import Api from 'entities/helpers/api';
import EverTrue from 'app';

export const useLists = () => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  // LIST METHODS

  // Retrieve lists from the API and update the state
  const getLists = useCallback(() => {
    setLoading(true);
    Api.CONTACTS.LISTS.get({
      params: { limit: 1000 },
    })
      .then((resp) => {
        const lists = resp.items.filter((item) => item.type === 'user_list');
        setLists(lists);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setLists([]);
      });
  }, []);

  // Fetch Initial Lists
  useEffect(() => {
    getLists();
  }, [getLists]);

  const updateListsAfterDeletion = (listIds) => {
    setLists((prevLists) => prevLists.filter((list) => !listIds.includes(list.id)));
  };

  const deleteList = (listId, showRefreshToast = false, hasFolders = false) => {
    const listName = lists.find((list) => list.id === listId)?.name || 'List';
    if (listId) {
      Api.CONTACTS.LIST.delete({
        urlArgs: { list_id: listId },
      })
        .then(() => {
          if (showRefreshToast && hasFolders)
            EverTrue.Alert.success(`${listName} deleted. Please refresh the page to continue.`);
          else EverTrue.Alert.success(`${listName} deleted`);
        })
        .catch(() => {
          EverTrue.Alert.error(`Error while deleting list ${listName}`);
        });
    }
  };

  const deleteLists = (listIds) => {
    const deletePromises = listIds.map((listId) =>
      Api.CONTACTS.LIST.delete({
        urlArgs: { list_id: listId },
      })
    );

    Promise.all(deletePromises)
      .then(() => {
        EverTrue.Alert.success(`Selected Lists deleted`);
      })
      .catch(() => {
        EverTrue.Alert.error('Error while deleting selected lists.');
      });
  };

  return {
    lists,
    loading,
    getLists,
    deleteList,
    deleteLists,
    updateListsAfterDeletion,
  };
};
