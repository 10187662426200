/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import Api from 'entities/helpers/api';

const gql = String.raw;
const EXECUTE_QUERY = gql`
  mutation ExecuteQueryMutation($query: String!) {
    executeQuery(
      input: { query: { query: $query, type: "natural_language_search", translationTargets: { target: "renderable" } } }
    ) {
      items
      total
      query {
        query
        id
        translations {
          id
          translation
          target
          version
        }
      }
      limit
      offset
      errors
      warnings
      suggestions
    }
  }
`;

const formatData = (items) => {
  return items.map((item) => ({
    id: item.id,
    name: `${item.name_first} ${item.name_last}`,
  }));
};

const extractTranslations = (translations, setRenderableQuery, setOriginalQuery) => {
  translations.forEach((translation) => {
    if (translation.target === 'renderable') {
      try {
        const parsedQuery = JSON.parse(translation.translation);
        setRenderableQuery(parsedQuery.renderable_query || []);
        setOriginalQuery(parsedQuery.renderable_query || []);
      } catch (error) {
        console.error('Error parsing renderable query:', error);
        setRenderableQuery([]);
      }
    }
  });
};

const useExecuteQuery = (searchQuery) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [suggestedSearches, setSuggestedSearches] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [renderableQuery, setRenderableQuery] = useState([]);
  const [originalQuery, setOriginalQuery] = useState([]);

  const executeQuery = useCallback((query) => {
    setLoading(true);
    setErrors([]);
    setSuggestedSearches([]);

    return Api.SEARCH.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${EXECUTE_QUERY}`,
        variables: { query },
      }),
    })
      .then((response) => {
        if (response.errors) {  
          throw new Error(response.errors[0].message);
        }
        if (!response.data) {
          throw new Error('No data found in response');
        }
        if (response.data.executeQuery) {
          const items = response.data.executeQuery?.items || [];
          setData(formatData(items));
          setTotalResults(response.data.executeQuery?.total || 0);

          const suggestions = response.data.executeQuery?.suggestions || [];
          setSuggestedSearches(suggestions);
          
          const warnings = response.data.executeQuery?.warnings || [];
          setWarnings(warnings);
          
          const errors = response.data.executeQuery?.errors || [];
          setErrors(errors);

          extractTranslations(response.data.executeQuery.query.translations, setRenderableQuery, setOriginalQuery);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrors([err]);
        console.error('Error occurred while executing ExecuteQueryMutation: ', err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    executeQuery(searchQuery);
  }, [searchQuery]);

  return {
    data,
    loading,
    errors,
    warnings,
    suggestedSearches,
    totalResults,
    renderableQuery,
    originalQuery,
    setRenderableQuery,
    executeQuery,
  };
};

export default useExecuteQuery;
