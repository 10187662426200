import PropTypes from 'prop-types';
import KpiCard from 'components/containers/kpi-card';
import numeral from 'numeral';

const CompanyGiving = ({ data = {} }) => {
  return (
    <div className="company-giving-cards fs-exclude-data">
      <KpiCard title="Average Lifetime Giving" value={numeral(data?.average || 0).format('$0,0')} width={33} />
      <KpiCard title="Median Lifetime Giving" value={numeral(data?.median || 0).format('$0,0')} width={33} />
      <KpiCard title="Total Lifetime Giving" value={numeral(data?.total || 0).format('$0,0')} width={33} />
    </div>
  );
};

CompanyGiving.propTypes = {
  data: PropTypes.object,
};

export default CompanyGiving;
