import { useEffect, useState } from 'react';
import { fetchAllJourneysForAllJourneyGroups } from 'apps/journeys/utils/api-requests/journey-index';
import ErrorLogger from 'entities/helpers/error-log-helper';

const useFetchJourneys = (hasCadences) => {
  const [journeys, setJourneys] = useState([]);
  const [loadingJourneys, setLoadingJourneys] = useState(true);

  useEffect(() => {
    const getAndSetJourneys = async () => {
      try {
        const journeysListResponse = await fetchAllJourneysForAllJourneyGroups(); // TO DO: Fetch journeys by journey group
        setJourneys(journeysListResponse.map((j) => ({ label: j.name, value: j.id })));
      } catch (e) {
        ErrorLogger.error('Error fetching Cadences', { extra: { errror: e, page_name: 'Add To Cadences Popover' } });
      } finally {
        setLoadingJourneys(false);
      }
    };
    if (hasCadences) {
      getAndSetJourneys();
    }
  }, [hasCadences]);

  return { journeys, loadingJourneys };
};

export default useFetchJourneys;
