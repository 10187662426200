import _ from 'underscore';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFluxStore } from '@evertrue/et-flux';
import EverTrue from 'app';
import FilterSource from 'apps/filters/sources/filter-source';
import FilterStore from 'apps/filters/stores/filter-store';
import MapStore from 'apps/map/stores/map-store';
import MapSource from 'apps/map/sources/map-source';
import { getClusterQuery, radiusToLatLng, getZoomForRadius, boundsToBox } from 'apps/map/map-utils';
import GeolocationStore from 'apps/contact/stores/geolocation-store';
import SegmentStore from 'apps/filters/stores/segment-store';
import MapWithClusters from 'apps/map/components/map-with-clusters';
import ClusterContactSource from 'apps/map/sources/cluster-contact-source';
import MapAddressFilter from 'apps/map/components/map-address-filter';
import MapRadiusDropdown from 'apps/map/components/map-radius-dropdown';
import { Button } from '@evertrue/et-components';

const INIT_ZOOM = 2;
const MIN_RADIUS_ZOOM = 8;
const google = window.google;

const _cluster_query = (box) => getClusterQuery(box);

const _radiusStringToNumber = (string = '') => {
  return _.toNumber(string.replace('mi', ''));
};

const _round = (num) => (num ? Number(num).toFixed(2) : NaN);

const _isEquivalentLatLon = (obj1 = {}, obj2 = {}) => {
  return !!(_round(obj1.lat) === _round(obj2.lat) && _round(obj1.lon) === _round(obj2.lon));
};

const MapState = () => ({
  zoom: MapStore.getZoom(),
  clusters: MapStore.getClusters(),
  loadingClusters: MapStore.getLoadingClusters(),
  map: MapStore.getMap(),
  mapCenter: MapStore.getLatLng(),
  boundingBox: MapStore.getBoundingBox(),
});
const GeolocationState = () => ({
  geolocation: GeolocationStore.getGeolocation(),
  center: GeolocationStore.getLocation(),
});
const SegmentState = () => ({ savedSegment: SegmentStore.getSelectedSegment() });
const FilterState = () => ({
  activeMapFilter: FilterStore.getActiveFilterByKey('map'),
  operator: FilterStore.getOperator(),
});

const MapController = ({ onSendContactsFocus, onMapChange }) => {
  // Store subscriptions

  const { zoom, clusters, loadingClusters, map, mapCenter, boundingBox } = useFluxStore(MapStore, MapState);
  const { activeMapFilter, operator } = useFluxStore(FilterStore, FilterState);
  const { geolocation, center } = useFluxStore(GeolocationStore, GeolocationState);
  const { savedSegment } = useFluxStore(SegmentStore, SegmentState);

  const [prevRadius, setPrevRadius] = useState(null);
  const [prevSavedSegment, setPrevSavedSegment] = useState(null);

  // State
  const [addressTypes, setAddressTypes] = useState();
  const [zoomState, setZoomState] = useState();
  const [boundsState, setBoundsState] = useState();
  const [centerState, setCenterState] = useState();
  const [locationMarkerState, setLocationMarkerState] = useState();
  const [circle, setCircle] = useState(null);

  // This useEffect is used to update the map filters when the boundsState changes
  useEffect(() => {
    if (boundsState && boundingBox && boundsState !== boundingBox) {
      if (boundsState.north && boundsState.east) {
        updateMapFilters(boundsState);
      }
    }
  }, [boundingBox, boundsState]);

  useEffect(() => {
    // Force the map to default to correct bounds from URL, needs to happen before mount
    const filters = EverTrue.UrlManager.get('filters');
    const mapFilter = _.findWhere(filters, { filter_id: '0.0' });
    if (mapFilter?.value) {
      setBoundsState(mapFilter.value);
    }
  }, []);

  //resets zoom state when map filter is removed
  useEffect(() => {
    if (!activeMapFilter) {
      setZoomState(INIT_ZOOM);
    }
  }, [activeMapFilter]);

  useEffect(() => {
    if (savedSegment?.id) {
      if (savedSegment?.id !== prevSavedSegment?.id) {
        updateForSavedSegment();
        setPrevSavedSegment(savedSegment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSegment]);

  //updates radius on map when it changes
  useEffect(() => {
    if (activeMapFilter?.radius) {
      if (prevRadius !== activeMapFilter.radius) {
        showRadiusOnMap();
        setPrevRadius(activeMapFilter.radius);
      }
    } else {
      handleUnsetRadius();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMapFilter?.radius, prevRadius]);

  const updateForSavedSegment = () => {
    const filterVal = savedSegment?.filters?.find((f) => f.filter_id === '0.0')?.value;
    if (filterVal?.radius && !_isEquivalentLatLon(filterVal, mapCenter)) {
      setCenterState(radiusToLatLng(filterVal));
    } else {
      setBoundsState(filterVal);
    }
  };

  const showRadiusOnMap = () => {
    const radius = _radiusStringToNumber(activeMapFilter?.radius);
    const zoom = getZoomForRadius(radius);
    setZoomState(zoom);

    if (circle) {
      circle.setMap(null);
    }

    setCircle(
      new google.maps.Circle({
        strokeColor: '#5598ab',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5598ab',
        fillOpacity: 0.35,
        map: map,
        center: radiusToLatLng(activeMapFilter),
        radius: radius * 1609.34,
      })
    );
  };

  const updateMapFilters = (bounds) => {
    FilterSource.updateMapFilter(bounds);
    FilterSource.fetchFilteredClusters();
  };

  const handleMapInit = (bounds) => {
    if (activeMapFilter?.radius) {
      showRadiusOnMap();
      FilterSource.fetchFilteredClusters();
      MapSource.setBoundingBox(bounds);
    } else {
      MapSource.setBoundingBox(bounds);
      FilterSource.fetchFilteredClusters();
    }
  };

  const handleMapChange = (zoom, bounds) => {
    // reset so if set again to same value, map component will detect change
    if (zoomState || centerState || boundsState || locationMarkerState) {
      setZoomState(undefined);
      setCenterState(undefined);
      setBoundsState(undefined);
      setLocationMarkerState(undefined);
    }

    if (activeMapFilter?.radius) {
      MapSource.setBoundingBox(bounds);
      FilterSource.fetchFilteredClusters();
    } else {
      MapSource.setBoundingBox(bounds);
      updateMapFilters(bounds);
    }
    onMapChange?.();
  };

  const handleClusterOpen = (key, cluster) => {
    let query;
    if (operator === 'or') {
      query = FilterStore.getActiveFiltersQuery();
      if (!query.must) query.must = [];
      query.must.push({ 'addresses.location': cluster.geobox });
    } else {
      query = FilterStore.getActiveFiltersQueryWithoutMap(_cluster_query(cluster.geobox));
    }
    return ClusterContactSource.fetch(key, query);
  };

  const handleSetRadius = (radius) => {
    const object = _.cloneData(mapCenter);
    object.radius = radius + 'mi';
    updateMapFilters(object);

    const googleFormattedCenter = { ...object, lng: object.lon };
    setLocationMarkerState(googleFormattedCenter);
    onMapChange?.();
  };

  const handleUnsetRadius = () => {
    if (circle) circle.setMap(null);
    const bounds = boundsToBox(map?.getBounds());
    updateMapFilters(bounds);
    onMapChange?.();
  };

  const isGeolocation = geolocation && _.isEqual(geolocation, center) && !savedSegment;
  const radiusFromFilter = activeMapFilter?.radius;
  const canSetRadius = (_.notEmpty(mapCenter) && zoom >= MIN_RADIUS_ZOOM) || radiusFromFilter;

  let defaultZoom;
  if (radiusFromFilter) {
    defaultZoom = getZoomForRadius(_radiusStringToNumber(radiusFromFilter));
  } else if (isGeolocation) {
    defaultZoom = 11;
  }

  return (
    <div className="map-search-controller">
      <Button
        type="simple"
        onClick={onSendContactsFocus}
        title="Map shows list of constituents in the map area, but is inaccessible. Go to list of constituents in the map"
      />

      <MapWithClusters
        clusters={clusters}
        loading={loadingClusters}
        defaultZoom={defaultZoom}
        defaultBounds={boundingBox}
        setBounds={boundsState}
        setCenter={centerState}
        setZoom={zoomState}
        setLocationMarker={locationMarkerState}
        onMapInit={handleMapInit}
        onMapChange={handleMapChange}
        onClusterOpen={handleClusterOpen}
        onLocationChange={handleUnsetRadius}
      >
        <div className="map-search-controller--filters">
          <MapRadiusDropdown
            value={radiusFromFilter}
            disabled={!canSetRadius}
            onChange={handleSetRadius}
            onClear={handleUnsetRadius}
          />

          <MapAddressFilter
            selected_types={addressTypes}
            onApply={(filters) => {
              FilterSource.set(filters);
              FilterSource.fetchFilteredClusters();
              onMapChange?.();
            }}
            onSelectType={(types) => setAddressTypes(types)}
          />
        </div>
      </MapWithClusters>
    </div>
  );
};

MapController.propTypes = {
  onSendContactsFocus: PropTypes.func,
  onMapChange: PropTypes.func,
};

export default MapController;
