import { useCallback, useState, useEffect, useRef } from 'react';
import Backbone from 'backbone';
import _ from 'underscore';
import AllUsersStore from 'apps/users/stores/all-users-store';
import { useFluxStore } from '@evertrue/et-flux';
import EverTrue from 'app';
import Api from 'entities/helpers/api';
import PageHeader from 'apps/layout/components/page-header';
import ActionMenuItem from 'components/controls/action-menu-item';
import { Loading, Card, SearchInput, LinkButton, FlexTable, useOpenClose } from '@evertrue/et-components';
import BulkListController from 'apps/lists/controllers/bulk-list-controller';
import ListContactSource from 'apps/lists/sources/list-contact-source.coffee';
import FoldersCard from 'apps/folder/components/detail/folders-card';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import ListColumns from 'apps/lists/components/list-columns';
import DeleteModal from 'apps/search/components/delete-modal';
import { useIdentity } from 'base/identity-resolver';
import { useLists } from '../hooks/use-lists';

const NUM_LISTS = 4;

/*
  Function for grouping list and featured Lists
  */
const groupLists = (lists) => {
  let listArray = _.map(lists, (seg) => ({
    label: seg.name,
    value: seg.id,
    type: 'saved_search',
  }));

  return listArray;
};

const ListsPageController = () => {
  const { lists, deleteList, getLists, updateListsAfterDeletion, loading } = useLists();
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableLists, setSelectableLists] = useState(lists);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const fromClarity = EverTrue.UrlManager.get('fromClarity');
  const listName = EverTrue.UrlManager.get('name');
  const [selectedLists, setSelectedLists] = useState([]);
  const { flagEnabled: hasFolders } = useFeatureFlag('folders');
  const folderCardRef = useRef();
  const anyListSelected = selectedLists?.length > 0;
  const [isDeleteModalOpen, openModal, closeModal] = useOpenClose();
  const [listToDelete, setListToDelete] = useState(null);

  useEffect(() => {
    if (fromClarity) {
      setShowAddModal(true);
    }
  }, [fromClarity]);

  const mapState = useCallback(() => {
    return {
      users: AllUsersStore.getAllGivingTreeUsers(),
    };
  }, []);

  const { users } = useFluxStore(AllUsersStore, mapState);
  const { user } = useIdentity();

  /*
   Effect for search change
   */
  useEffect(() => {
    _.debounce(setSelectableLists(_.filter(lists, (list) => _.searchCompare(list.name, searchText))), 300);
  }, [searchText, lists]);

  useEffect(() => {}, [sortOrder]);

  /*
   Gets recently viewed list
   */
  useEffect(() => {
    Api.DNA.SETTING.get({
      urlArgs: { setting_key: 'GT_recent_lists' },
      disableAlerts: true,
      params: { user_id: 'me', oid: null },
    }).then((resp) => {
      const recent = _.uniq(resp.settings.GT_recent_lists.value.lists);
      const groupedLists = groupLists(lists);
      let ordered;
      if (!_.isEmpty(recent)) {
        ordered = _.compact(
          _.map(recent, (id) => {
            return _.findWhere(groupedLists, { value: id });
          })
        );
        if (ordered.length < NUM_LISTS) {
          let remaining = _.filter(groupedLists, (seg) => {
            return !_.contains(recent, seg.value);
          });
          remaining = _.sortBy(remaining, 'label');
          remaining = _.first(remaining, NUM_LISTS - ordered.length);
          ordered = _.compact(ordered.concat(remaining));
        }
        setRecentlyViewed(ordered);
      } else setRecentlyViewed(_.sortBy(groupedLists, 'label'));
    });
  }, [lists]);

  useEffect(() => {
    ListContactSource.resetTablePagination();
  }, []);

  const listsPagesActions = () => (
    <>
      <ActionMenuItem icon="add" title="Create List" onClick={() => setShowAddModal(true)}>
        Create List
      </ActionMenuItem>
      {hasFolders && (
        <>
          <ActionMenuItem
            icon="add"
            title="Create Folder"
            onClick={() => folderCardRef.current?.openCreateFolderModal()}
          >
            Create Folder
          </ActionMenuItem>
          {anyListSelected && (
            <ActionMenuItem
              icon="folder"
              iconKit="gt2"
              title="Add List to Folder"
              onClick={() => folderCardRef.current?.openAddListSavedSearchesToFolderModal()}
            >
              Add List to Folder
            </ActionMenuItem>
          )}
        </>
      )}
    </>
  );

  let listsSorted = _.sortBy(selectableLists, (list) => list.name.toLowerCase());
  if (!sortOrder) {
    listsSorted = listsSorted.reverse();
  }

  const handleCheckAllLists = (isChecked) => {
    if (isChecked) {
      setSelectedLists([...listsSorted]);
    } else {
      setSelectedLists([]);
    }
  };

  const handleListCheck = (list, isChecked) => {
    const updatedLists = isChecked ? [...selectedLists, list] : selectedLists.filter((l) => l.id !== list.id);

    setSelectedLists(updatedLists);
  };

  const handleSelectedListsChange = (updatedSelectedLists) => {
    setSelectedLists(updatedSelectedLists);
  };

  const handleDelete = (listId) => {
    const showRefreshToast = true;
    deleteList(listId, showRefreshToast, hasFolders);
    updateListsAfterDeletion([listId]);
    setSelectedLists([]);
    closeModal();
  };

  const openDeleteModal = (listId) => {
    setListToDelete(listId);
    openModal();
  };

  const columns = ListColumns(
    'Delete',
    setSortOrder,
    sortOrder,
    users,
    user,
    selectedLists,
    handleListCheck,
    handleCheckAllLists,
    selectableLists,
    handleSelectedListsChange,
    openDeleteModal,
    true, //userIsOwner will always be true for Homepage of Lists. We filter collaborative permissions inside the component.
    hasFolders
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {showAddModal && (
            <BulkListController
              listName={listName}
              fromClarity={fromClarity}
              remove={() => setShowAddModal(false)}
              onComplete={() => _.delay(getLists, 2000)}
            />
          )}
          <PageHeader title="Lists" subtitle={`${listsSorted.length} Lists`} actions={listsPagesActions()} />
          <div className="lists-page-controller">
            <Card className="lists-page--table">
              <div className="lists-page--search">
                <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
              </div>
              <FlexTable caption="Lists" data={listsSorted} columns={columns} loading={loading} />
            </Card>
            <div className="lists-page--side">
              {hasFolders && (
                <FoldersCard
                  ref={folderCardRef}
                  selectedLists={selectedLists}
                  handleCheckAllLists={handleCheckAllLists}
                />
              )}
              <Card headingText="Recently Viewed Lists" className="lists-page--recent" padding="16px">
                <div className="lists-page--cardbody">
                  {recentlyViewed.map((list) => (
                    <LinkButton
                      className="lists-page--recent-item"
                      key={list.value}
                      onClick={() => Backbone.history.navigate(`list/${list.value}`, true)}
                      title={list.label}
                    >
                      {list.label}
                    </LinkButton>
                  ))}
                </div>
              </Card>
            </div>
          </div>
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={closeModal}
            handleDelete={handleDelete}
            title="Confirm Delete"
            body="Are you sure you want to delete this list?"
            list_id={listToDelete}
          />
        </>
      )}
    </>
  );
};

export default ListsPageController;
