import { useState, useEffect, useCallback } from 'react';
import EverTrue from 'app';
import Api from 'entities/helpers/api';
import { fetchFromGraphql } from 'entities/helpers/gqlCall';

const useProposalQueryBuilder = (
  limit,
  page,
  sortReverse,
  sortProp,
  prospectContactId,
  userContactId,
  filterYours,
  filterActive,
  filterStage,
  refetch,
  selectedSolicitors
) => {
  const gql = String.raw;
  const contactsQuery = gql`
    query ($ids: [BigInt!]) {
      contacts(ids: $ids) {
        id
        contact_attributes {
          name_full
        }
      }
    }
  `;

  const [proposalsData, setProposalsData] = useState({});
  const [loading, setLoading] = useState(true);

  const buildQuery = useCallback(() => {
    let filterQuery = [];

    if (prospectContactId) {
      filterQuery.push({ 'contacts.contact_id': { match: prospectContactId } });
    }

    if (filterYours === 'yours') {
      filterQuery.push({ 'solicitors.contact_id': { match: userContactId } });
    }
    if (selectedSolicitors?.length > 0 && filterYours === 'all') {
      const selectedValues = selectedSolicitors.map((option) => option.value);
      filterQuery.push({ 'solicitors.contact_id': { in: selectedValues } });
    } else if (filterYours === 'key') {
      filterQuery.push({
        solicitors: {
          instance: {
            contact_id: { match: userContactId },
            key_proposal: { match: true },
          },
        },
      });
    }

    if (filterActive !== 'all') {
      filterQuery.push({ active: { match: filterActive === 'active' } });
    }

    if (filterStage !== 'all') {
      filterQuery.push({ 'status.untouched': { match: filterStage } });
    }

    return filterQuery;
  }, [prospectContactId, userContactId, filterYours, filterActive, filterStage, selectedSolicitors]);

  const buildSort = useCallback(() => {
    const order = sortReverse ? 'desc' : 'asc';
    return [{ [sortProp]: { order } }];
  }, [sortProp, sortReverse]);

  useEffect(() => {
    setLoading(true);
    const query = buildQuery();
    const sort = buildSort();

    Api.SEARCH.PROPOSAL.post({
      params: {
        offset: (page - 1) * limit,
        limit: limit,
      },
      data: JSON.stringify({
        must: query,
        sort,
      }),
      success: async (resp) => {
        try {
          const allSolicitorIds = [];

          // Collect all solicitor contact IDs to fetch names in one GraphQL call
          resp.items.forEach((item) => {
            item.solicitors.forEach((solicitor) => {
              if (!solicitor.name && !allSolicitorIds.includes(solicitor.contact_id)) {
                allSolicitorIds.push(solicitor.contact_id);
              }
            });
          });

          // Perform a single GraphQL call for all solicitor IDs in the current page
          let solicitorNameMap = {};
          if (allSolicitorIds.length > 0) {
            const params = { ids: allSolicitorIds };
            const { contacts } = await fetchFromGraphql(contactsQuery, params);

            solicitorNameMap = contacts.reduce((map, contact) => {
              map[contact.id] = contact.contact_attributes.name_full;
              return map;
            }, {});
          }

          const itemsWithNames = resp.items.map((item) => {
            const solicitorsWithNames = item.solicitors.map((solicitor) => {
              if (!solicitor.name) {
                solicitor.name = solicitorNameMap[solicitor.contact_id] || null;
              }
              return solicitor;
            });
            return { ...item, solicitors: solicitorsWithNames };
          });

          setProposalsData({
            ...resp,
            items: itemsWithNames,
            total: resp.total,
          });
          setLoading(false);
        } catch (error) {
          console.error('Error fetching solicitor names:', error);
          setLoading(false);
        }
      },
      error: () => {
        EverTrue.Alert.error('There was an error loading proposals');
        setLoading(false);
      },
    });
  }, [
    page,
    sortReverse,
    sortProp,
    prospectContactId,
    buildQuery,
    buildSort,
    limit,
    filterYours,
    filterActive,
    filterStage,
    refetch,
    contactsQuery,
  ]);

  return { loading, proposalsData };
};

export default useProposalQueryBuilder;
