import { Icon } from "@evertrue/et-components";
import PropTypes from "prop-types";

export const DrawerHeader = ({
  type = "",
  onClose = () => {},
  drawerTitle = "New Message",
  isMinimized = false,
  onMinimize = () => {},
  onFullScreen = () => {},
  isFullScreen = false,
  wasFullScreenBeforeMinimize = false,
  headerIcon = null,
  onCall,
  CallTimer,
  endCall,
}) => {
  const isActiveDialer = onCall && type === "dialer";

  const handleClose = () => {
    if (isActiveDialer) {
      endCall();
    }
    onClose();
  };

  return (
    <div className="drawer--header">
      <div className="flex">
        {headerIcon && <div className="drawer--header-icon">{headerIcon}</div>}
        <div className={isMinimized ? "drawer--header-title drawer--header-title--minimized" : "drawer--header-title"}>
          {drawerTitle}
        </div>
      </div>
      <div className="drawer--header--button-group">
        {(type === "emailer" || type === "ai") && (
          <button onClick={onFullScreen} className="drawer--header-button">
            <Icon
              iconKit="gt2"
              icon={isFullScreen || wasFullScreenBeforeMinimize ? "minimize-drawer" : "maximize-drawer"}
            />
          </button>
        )}
        {isActiveDialer &&
          (isMinimized ? <CallTimer className="drawer-on-call--minimized" /> : <div className="drawer-on-call" />)}
        <button onClick={onMinimize} className="drawer--header-button">
          <Icon icon={isMinimized ? "chevron-up" : "minus"} />
        </button>
        <button onClick={handleClose} className="drawer--header-button">
          <Icon icon="close" />
        </button>
      </div>
    </div>
  );
};

DrawerHeader.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  drawerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isMinimized: PropTypes.bool,
  onMinimize: PropTypes.func,
  isFullScreen: PropTypes.bool,
  onFullScreen: PropTypes.func,
  wasFullScreenBeforeMinimize: PropTypes.bool,
  onCall: PropTypes.bool,
  CallTimer: PropTypes.func,
  headerIcon: PropTypes.node,
  endCall: PropTypes.func,
};

export default DrawerHeader;
