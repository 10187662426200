import { Icon } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';

const JourneyActiveSearchBar = ({ searchParam = '', setSearchParam = () => {} }) => (
  <div className="journey-search--container">
    <span className="search-box">
      <Icon icon={'search'} className={'search-box--search-icon'} />
      <input
        className="search-box--input"
        type="search"
        value={searchParam}
        placeholder={'Search Active Constituents'}
        onChange={(e) => setSearchParam(e.target.value)}
      />
    </span>
  </div>
);

JourneyActiveSearchBar.propTypes = {
  searchParam: PropTypes.string,
  setSearchParam: PropTypes.func,
};

export default JourneyActiveSearchBar;
