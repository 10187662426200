import PropTypes from 'prop-types';
import EverTrue from 'app';
import UserSource from 'base/user/user-source';
import Avatar from 'components/elements/avatar';
import { Button } from '@evertrue/et-components';

const ImpersonationHeader = ({ user = {} }) => {
  const { name, avatar } = user;

  const handleCancel = () => {
    UserSource.endImpersonation();
  };

  return (
    <div className="impersonation-header">
      <div className="impersonation-wrapper clearfix">
        <Button type="destroy" className="pull-left end-impersonation-btn btn" onClick={handleCancel}>
          End Impersonation
        </Button>

        <div className="pull-left vertical-line" />

        <Avatar url={avatar} width={40} className="main-nav--avatar impersonation-avatar pull-left" name={name} />

        <div className="impersonation-names pull-left">
          <div className="emphasis">{name}</div>
          <span className="impersonation-names--org">{EverTrue.store.org.get('name')}</span>
        </div>
      </div>

      <div className="pull-left impersonation-text">You are currently impersonating</div>
    </div>
  );
};

ImpersonationHeader.propTypes = {
  user: PropTypes.object,
};

export default ImpersonationHeader;
