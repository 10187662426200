import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';

const SavedSearchesfeatureLink = ({ featuredLinks }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (sectionKey) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  return (
    <div className="featured-links-container">
      {featuredLinks.map((section, index) => {
        const sectionKey = `section-${index}`;
        const title = section.props.children[0].props.children;
        const links = section.props.children[1].filter((item) => typeof item === 'object');

        return (
          <div key={sectionKey} className="section-container">
            <div className="section-header" onClick={() => toggleSection(sectionKey)}>
              <h1 className="section-title">{title}</h1>
              <Icon icon={expandedSections[sectionKey] ? 'chevron-up' : 'chevron-down'} className="section-icon" />
            </div>

            {expandedSections[sectionKey] && (
              <ul className="links-list">
                {links.map((link, linkIndex) => {
                  const spanChildren = link.props.children.props.children;

                  return (
                    <li key={`link-${index}-${linkIndex}`} className="list-item">
                      {/* Render all span children */}
                      {spanChildren.map((child, childIndex) => {
                        if (typeof child === 'string') {
                          return <span key={`text-${childIndex}`}>{child}</span>;
                        }
                        if (child.type === 'a') {
                          return (
                            <a key={`link-${childIndex}`} href={child.props.href}>
                              {child.props.children}
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
};

SavedSearchesfeatureLink.propTypes = {
  featuredLinks: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default SavedSearchesfeatureLink;
