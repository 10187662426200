module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   EverTrue = require("app")
   React = require("react")
   Decorator = require("clientDecorator")
   SortUtils = require("mixins/sort-utils")
   MyPortfolioStore = require("apps/major-gifts/stores/my-portfolio-store")
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   MapStore = require('apps/map/stores/map-store').default
   AllUsersStore = require("apps/users/stores/all-users-store").default
   ProspectColumnStore = require("apps/major-gifts/stores/prospect-column-store")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   {div, span, i, strong} = ReactLibs.DOMFactories
   MapContacts = createFactory require("apps/map/controllers/map-contacts-controller").default
   MyPortfolioColumns = require("apps/major-gifts/components/my-portfolio-columns")
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   ButtonSelect = require("components/controls/button-select")
   ProspectMapController = require("apps/major-gifts/components/prospect-map-controller")
   SortDropdown = require("components/controls/sort-dropdown")
   ProspectActions = require("apps/major-gifts/components/prospect-actions")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ContactsPageHeader = require("apps/contact/components/contacts/contacts-page-header.coffee")
   MyPortfolioConfig = require("apps/major-gifts/my-portfolio-config")
   Decorator = require("clientDecorator")


   _options = [
      {label: "Last Name", value: "name_last"}
      {label: "Lifetime Giving", value: "giving.lifetime_amount"}
      {label: "Last Gift Date", value: "giving.last_gift_date"}
      {label: "Last Contact Date", value: "last_contact_date"}
   ]

   createComponent "MyPorfolioProspects",
      propTypes:
         type: ReactLibs.PropTypes.string

      getDefaultProps: ->
         type: "stage"

      getInitialState: ->
         search_query: null

      registerStores: ->
         @on MyPortfolioStore, ->
            sort_param: MyPortfolioStore.getSortParam()
            user: MyPortfolioStore.getUser()
            total_prospects: MyPortfolioStore.getTotalProspects()
            total_median_age: MyPortfolioStore.getTotalMedianAge()
            total_count: MyPortfolioStore.getTotalCount()
            total_count_loading: MyPortfolioStore.getTotalCountLoading()
            mapped_prospects: MyPortfolioStore.getMappedProspects()
            map_loading: MyPortfolioStore.getMapLoading()
            loading_cols: MyPortfolioStore.getLoadingCols()
            address_types: MyPortfolioStore.getAddressTypes()
            show_aging: MyPortfolioStore.getShowAging()
            show_last_contact_date: MyPortfolioStore.getShowLastContactDate()

         @on SelectedContactsStore, ->
            selected_contacts: SelectedContactsStore.getSelectedContacts()

         @on ProspectColumnStore, ->
            active_columns: ProspectColumnStore.getActiveColumns()
            all_columns: ProspectColumnStore.getAllColumns()

         @on AllUsersStore, ->
            users: _.sortBy AllUsersStore.getAllGivingTreeUsers(), (user) -> user.name
            loading_users: AllUsersStore.getLoading()

      componentDidMount: ->
         MyPortfolioSource.fetchViewSlides()

         # set default user and sort
         MyPortfolioSource.changeUser(@state.user || EverTrue.store.user.toJSON())
         MyPortfolioSource.changeSortParam(SortUtils.getQuery("giving.lifetime_amount", true)[0])

         ListContactSource.setEvent("my_portfolio_action")
         @setMyPortfolioTracking(@props.type)

      componentWillUnmount: ->
         MyPortfolioSource.resetStore()

      handleSort: (prop, reverse) ->
         if prop is "proximity_sort" || prop is "addresses.location"
            sort_param = MapStore.getProximitySort(reverse)
         else
            sort_param = SortUtils.getQuery(prop, reverse)[0]
         MyPortfolioSource.changeSortParam(sort_param)

      handleMapPage: (page) ->
         offset = @state.mapped_prospects?.limit * (page - 1)
         MyPortfolioSource.paginateMappedProspects(offset)

      handleChangeTab: (value) ->
         route = if value == "map" then "/my-portfolio/prospects/map" else "/my-portfolio/prospects"
         @setMyPortfolioTracking(value)
         EverTrue.Navigator route, true
         return undefined

      handleChangeUser: (name, user_id) ->
         MyPortfolioSource.changeUser {name: name, id: user_id}

      handleSearch: (query) ->
         @setState {search_query: query}

      setMyPortfolioTracking: (type) ->
         if type == "map"
            EverTrue.track.setReferrer("portfolio_map")
            EverTrue.track.set "viewed", {type: "portfolio_all_map"}
         else
            EverTrue.track.setReferrer("portfolio_list")
            EverTrue.track.set "viewed", {type: "portfolio_all_list"}

      getUserOptions: (users) ->
         filtered_users = []
         _.each users, (user) ->
            current_affiliation = Decorator.User.getAffiliationFor(user, EverTrue.store.org.get("id"))
            if current_affiliation?.remote_user_id
               filtered_users.push {label: user.name, value: user.id}
         filtered_users

      getSearchedUsers: (user_options) ->
         _.filter user_options, (user_option) =>
            _.searchCompare(user_option.label, @state.search_query)

      getUserName: ->
         return unless @state.total_prospects.items?.length > 0
         contact = @state.total_prospects.items[0]
         assignee = _.find contact.assignees, (assignee) => assignee.user_id?.value == @state.user?.id
         if assignee then assignee.name?.value else null

      getSortOptions: ->
         if @props.type != "map" and @state.show_aging
            options = _.cloneData(_options)
            options.push {label: "Time in Stage", value: "time_in_stage"}
            options
         else _options

      renderTitle: ->
         users = @getUserOptions(@state.users)
         AdvancedCombobox
            searchable: true
            className: "my-portfolio--title-dropdown advanced-combobox-title"
            value: {value: @state.user?.id}
            options: if @state.search_query then @getSearchedUsers(users) else users
            loading: @state.loading_users
            onChange: (val) => @handleChangeUser(val.label, val.value)
            onSearch: (query) => @handleSearch(query)
            header: span null,
               span className: "advanced-combobox--selected-text", @state.user?.name
                  i className: "fa fa-fw fa-angle-down"

      render: ->
         sort_prop = _.keys(@state.sort_param)?[0]
         sort_reverse = if _.values(@state.sort_param)?[0]?.order == "desc" then true else false
         all_columns = _.pluck @state.all_columns, "label"
         active_columns = _.pluck @state.active_columns, "label"

         div className: "my-portfolio",
            div className: "segment-filters clearfix",
               div className: "segment-filters--header",
                  ContactsPageHeader
                     className: "my-portfolio--filters"
                     title: @renderTitle()
                     totalResults: if @props.type is "map" then @state.mapped_prospects?.total else @state.total_count
                     loading: if @props.type is "map" then @state.map_loading else @state.total_count_loading
                     additionalText:
                        if @state.total_median_age and @state.show_aging
                           span null, " with a median time in stage of ",
                              span className: "emphasis", @state.total_median_age
                        else null
                     actions:
                        ProspectActions
                           solicitor_id: @state.user?.id
                           total_count: @state.total_count
                           selected_contacts: @state.selected_contacts

            ContactTableToolbar
               className: "fixed-page-wrapper my-portfolio--toolbar"
               contacts: if @props.type is "map" then @state.mapped_prospects else @state.total_prospects
               hide_pagination: if @props.type is "map" then false else true
               onPage: @handleMapPage

               ButtonSelect
                  className: "button-select-v2"
                  selected: @props.type
                  buttons: [
                     {value: "stage", gt_icon: "columns gt-icon-lg", label: "Stage"}
                     {value: "map", gt_icon: "map gt-icon-lg", label: "Map"}
                  ]
                  onSelect: (value) => @handleChangeTab(value)

               if @props.type != "map"
                  div className: "my-portfolio--sort-section",
                     strong null, "Sort:"
                     SortDropdown
                        className: "my-portfolio--sort"
                        options: @getSortOptions()
                        sortProp: sort_prop
                        sortReverse: sort_reverse
                        onSort: @handleSort

            if @props.type != "map"
               MyPortfolioColumns
                  active_columns: active_columns
                  all_columns: all_columns
                  loading_cols: @state.loading_cols
                  user_id: @state.user?.id
                  user_remote_name: @getUserName()

            else
               map_options = _.clone(_options)
               map_options.unshift({label: "Distance from map center", value: "proximity_sort"})

               div className: classNames("fixed-page-wrapper my-portfolio--wrapper my-portfolio--map",
                  "show-last-contact-date": @state.show_last_contact_date
               ),
                  MapContacts
                     contacts: @state.mapped_prospects
                     loading: @state.map_loading
                     onSort: @handleSort
                     sort_options: map_options
                     sortProp: sort_prop
                     sortReverse: sort_reverse
                     showLastGiftDate: true
                     hideScores: true
                     contactCardChildren: (contact) ->
                        time = MyPortfolioConfig.getFormattedTimeInStage(contact.last_contact_date)
                        div className: "fs-exclude-data",
                           span className: "contact-card--label", "Last Contact: "
                           span className: "contact-card--value", if time then time + " ago" else "n/a"


                  ProspectMapController
                     mapped_prospects: @state.mapped_prospects
                     user_id: @state.user?.id
                     address_types: @state.address_types
                     all_columns: all_columns
                     active_columns: active_columns
