import { useFeatureFlags } from "entities/helpers/use-feature-flag";
import { cloneElement } from "react";
import PropTypes from "prop-types";

/**
 * This is a wrapper function currently only used in the settings router file.
 * It allows older CoffeeScript files to have access to LaunchDarkly flags via props.
 */
const WithFlag = ({ Component }) => {
  const { allFlags } = useFeatureFlags();

  return <>{cloneElement(Component, { ...allFlags })}</>;
};

export default WithFlag;

WithFlag.propTypes = {
  Component: PropTypes.node.isRequired,
};
