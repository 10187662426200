import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import reorder from "./reorder";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const DNDComponents = ({
  items,
  itemsClassName,
  direction = "horizontal",
  ignoreContainerClipping = false,
  isDragDisabled = false,
}) => {
  const [ordered, setOrdered] = useState([]);

  useEffect(() => {
    setOrdered(items);
  }, [items]);

  const manipulateMinimizeDOM = (minHeight, opacity) => {
    const minimizedElements = document.querySelectorAll(".drawer.minimized");
    minimizedElements.forEach((minimized) => {
      minimized.style.minHeight = minHeight;
      minimized.style.opacity = opacity;
    });
  };

  const manipulateContainerDOM = (height, width) => {
    const container = document.querySelector(".drawers-container");
    if (container) {
      container.style.height = height;
      container.style.width = width;
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Check if the item was dropped outside a droppable area
    if (!destination) {
      //resetting in case drag-drop fails
      manipulateMinimizeDOM("", "1");
      manipulateContainerDOM("", "");
      return; // No action needed
    }

    // Check if the item was dropped in the same position
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      //resetting in case drag-drop fails
      manipulateMinimizeDOM("", "1");
      manipulateContainerDOM("", "");
      return; // No movement, no action needed
    }

    // Reorder the items and update state
    const reorderedItems = reorder(ordered, source.index, destination.index);
    setOrdered(reorderedItems);

    manipulateMinimizeDOM("", "1");
    manipulateContainerDOM("", "");
  };

  const onBeforeCapture = (result) => {
    manipulateMinimizeDOM("550px", "0.8");
    manipulateContainerDOM("550px", "1296px");
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <Droppable droppableId="droppable" direction={direction} ignoreContainerClipping={ignoreContainerClipping}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={itemsClassName}>
            {ordered.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDragging ? "dnd-component--is-dragging" : ""}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {item.component}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DNDComponents.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      component: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.func.isRequired]),
    }).isRequired
  ).isRequired,
  itemsClassName: PropTypes.string,
  direction: PropTypes.string,
  ignoreContainerClipping: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
};

export default DNDComponents;
