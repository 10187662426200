import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EllipsisOverflow from 'components/formatting/ellipsis-overflow';
import { OutsideClick, Icon, Button } from '@evertrue/et-components';

const ClickToInput = ({
  value = '',
  onSave = () => {},
  disable = false,
  children,
  showFullText = false,
  maxLength,
}) => {
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);

  const handleLabelClick = () => {
    if (!disable) {
      setEditMode(true);
    }
  };

  const handleOutsideClick = () => {
    if (editMode) {
      onSave(inputRef.current.value);
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  return (
    <OutsideClick onOutsideClick={handleOutsideClick}>
      <div className={classNames('click-to-input', { 'is-edit': editMode, 'is-disabled': disable })}>
        {editMode ? (
          <div className="click-to-input--input-container">
            <input
              type="text"
              ref={inputRef}
              defaultValue={value}
              className="click-to-input--input"
              maxLength={maxLength}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onSave(e.target.value);
                  setEditMode(false);
                }
              }}
            />
            <div className="click-to-input--button-container">
              <Button
                onClick={() => {
                  onSave(inputRef.current.value);
                  setEditMode(false);
                }}
                type="simple"
                className="click-to-input--button click-to-input--save-button"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setEditMode(false);
                }}
                type="simple"
                className="click-to-input--button click-to-input--cancel-button"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="click-to-input--label"
            onClick={handleLabelClick}
            style={{ maxWidth: showFullText ? '100%' : '90%' }}
          >
            <EllipsisOverflow className="click-to-input--text" component="h1">
              {value}
            </EllipsisOverflow>
            <Icon className="click-to-input--pencil" icon="edit" />
          </div>
        )}
        {children}
      </div>
    </OutsideClick>
  );
};

ClickToInput.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func,
  disable: PropTypes.bool,
  children: PropTypes.node,
  showFullText: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default ClickToInput;
