import PropTypes from 'prop-types';

const TempTable = ({ data, columns }) => {
    /* This is just a temp component to render AI search results, will be removed in improvised version */
    return (
        <div className="ai-search-table-container">
          <table className="table">
            <thead>
              <tr>
                {columns.map((col) => (
                  <th key={col.accessor}>{col.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  {columns.map((col) => (
                    <td key={col.accessor}>{row[col.accessor]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
};

TempTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        accessor: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
    })).isRequired,
};

export default TempTable;
