 
import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { useDebounce } from "apps/portfolio-performance/portfolio-utils";
import { AdvancedCombobox } from "@evertrue/et-components";
import { TEAM_SOLICITORS_QUERY } from "apps/portfolio-performance/portfolio-queries";
import { useIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const LIMIT = 250;

const gql = String.raw;
const query = gql`
  query ($ids: [BigInt!]) {
    contacts(ids: $ids) {
      id
      contact_attributes {
        name_full
      }
    }
  }
`;

// This dropdown returns "team" solicitors - which are
// contacts that have a child assignment object of parent_type "solicitor" and pool type "team"
const SolicitorDropdown = ({
  label,
  onChange,
  multiple,
  allowChangeAll,
  allowUnselectAll,
  value,
  disabled,
  poolIds = [],
  defaultToUser = false,
  excludeIds = [],
  placeholder,
}) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [loading, setLoading] = useState(false);
  const [solicitorOptions, setSolicitorOptions] = useState([]);
  const { user, org } = useIdentity();
  const poolIdsString = JSON.stringify(poolIds);
  const options = solicitorOptions.reduce((result, contact) => {
    if (!excludeIds.includes(contact.id) && contact.contact_attributes) {
      return result.concat({
        value: contact.id,
        label: contact.contact_attributes.name_full,
      });
    }
    return result;
  }, []);

  // Load Teams
  useEffect(() => {
    const poolIds = JSON.parse(poolIdsString);

    setLoading(true);
    // get the contact ids from search
    Api.SEARCH.CONTACTS.post({
      params: { limit: LIMIT },
      data: JSON.stringify(TEAM_SOLICITORS_QUERY(debouncedSearchText, poolIds, assignmentType)),
      // This is in a success callback because if the session token expires while viewing portfolios
      // it will re-up correctly from the success callback
      success: (search_resp = {}) => {
        const contact_ids = search_resp.items || [];
        // set total if there's no search text
        if (!debouncedSearchText) {
          setTotal(search_resp.total);
        }
        // use the contact ids to retrieve the full name from graph ql without having to load the whole contact obj
        Api.GRAPHQL.GRAPHQL.post({
          data: JSON.stringify({
            operationName: null,
            query: String.raw`${query}`,
            variables: { ids: contact_ids },
          }),
        })
          .then((resp = {}) => {
            setSolicitorOptions(resp.data.contacts || []);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
    });
  }, [debouncedSearchText, poolIdsString, assignmentType]);

  // Default solicitor to logged in user if defaultToUser prop is true
  useEffect(() => {
    if (!defaultToUser || value) return;

    const contactId = Decorator.User.getContactId(user, org.id);
    const userSolicitorOption = options.find((solicitor) => solicitor.value === contactId);

    if (userSolicitorOption) onChange(userSolicitorOption);
  }, [options, user, org.id, onChange, value, defaultToUser]);

  //This was added to handle scenarios where the value was coming as String and causing the dropdown to not show checked options
  const transformValue = (inputValue) => {
    if (Array.isArray(inputValue)) {
      return inputValue.map((item) => {
        if (item?.value) {
          const numericValue = Number(item.value);
          return !isNaN(numericValue) ? { value: numericValue, label: item.label } : item;
        }
        return item;
      });
    }
    return inputValue;
  };

  const adjustedValue = transformValue(value);

  return (
    <AdvancedCombobox
      label={label}
      options={options}
      value={adjustedValue || value}
      onChange={onChange}
      onSearch={total > LIMIT ? (searchText) => setSearchText(searchText) : null}
      searchable
      loading={loading}
      multiple={multiple}
      allowChangeAll={allowChangeAll}
      allowUnselectAll={allowUnselectAll}
      placeholder={placeholder || "Select Solicitor..."}
      disabled={disabled}
      formatSelection={(selectionText) => {
        return (
          <span style={{ color: "#454545" }}>
            {selectionText.length > 20 ? selectionText.substring(0, 20).concat("....") : selectionText}
          </span>
        );
      }}
    />
  );
};

SolicitorDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  allowChangeAll: PropTypes.bool,
  allowUnselectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  poolIds: PropTypes.array,
  defaultToUser: PropTypes.bool,
  excludeIds: PropTypes.array,
  placeholder: PropTypes.string,
};

export default SolicitorDropdown;
