import _ from 'underscore';
import { connect } from '@evertrue/et-flux';
import { Component } from 'react';
import { LinkButton, Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LayoutSource from 'apps/layout/sources/layout-source';
import LayoutStore from 'apps/layout/stores/layout-store';
import WindowOverflowMixin from 'mixins/window-overflow-mixin';

const mapStateToProps = () => ({
  nav_is_collapsed: LayoutStore.getNavState(),
});

class NavigationSubItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    nav_is_collapsed: PropTypes.bool,
    isActive: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    iconKit: PropTypes.string,
    isFooter: PropTypes.bool,
  };

  static defaultProps = {
    referrer: 'default',
    iconKit: 'gt',
    onClick: _.noop,
    isFooter: false,
  };

  state = { tooltip_key: undefined };

  handleMouseEnter = (e) => {
    if (this.props.nav_is_collapsed) {
      const overlay = <div className="base-nav-sub-item--tooltip">{this.props.label}</div>;
      if (!this.state.tooltip_key) {
        const dom_props = _.extend(WindowOverflowMixin.getDOMProperties(this), { key: _.randomKey() });
        this.setState({ tooltip_key: dom_props.key });
        LayoutSource.overlay(overlay, dom_props);
      }
    }
  };

  handleMouseLeave = (e) => {
    if (this.props.nav_is_collapsed && this.state.tooltip_key) {
      LayoutSource.removeOverlay(this.state.tooltip_key);
      this.setState({ tooltip_key: undefined });
    }
  };

  handleKeyDown = (event) => {
    if (event.which === 13) {
      this.props.onClick();
    }
  };

  handleClick = (e) => {
    this.props.onClick(e);
  };

  render() {
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleMouseEnter}
        onBlur={this.handleMouseLeave}
      >
        {this.props.isFooter ? (
          <div className={this.props.className}>{this.props.children}</div>
        ) : (
          <LinkButton
            title={this.props.label}
            onClick={this.handleClick}
            value={this.props.label}
            className={classNames('base-nav-sub-item', this.props.className, { 'is-active': this.props.isActive })}
          >
            {this.props.icon && (
              <Icon className="base-nav-sub-item--icon" icon={this.props.icon} iconKit={this.props.iconKit} />
            )}
            <span className="base-nav-sub-item--label">{this.props.label}</span>
            {this.props.children}
          </LinkButton>
        )}
      </div>
    );
  }
}

export default connect(NavigationSubItem, [LayoutStore], mapStateToProps);
