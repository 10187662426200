import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const BulkListSource = createSource('BulkListSource', {
  actions: {
    procesingManualContacts() {
      return true;
    },

    manualContactsSuccess() {
      return true;
    },

    manualContactsError() {
      return true;
    },

    clearManualContacts() {
      return true;
    },

    newListId() {
      return true;
    },

    newName(name) {
      this.require(typeof name === 'string', 'name should be a string');
    },

    newManualInput(text) {
      this.require(typeof text === 'string', 'manual input text should be a string');
    },

    newSelectedContacts(contacts) {
      this.require(Array.isArray(contacts), 'selected contacts should be an array');
      this.require(
        contacts.every((contact) => contact.id),
        'contacts should have an id property'
      );
    },

    submit() {
      return true;
    },

    editManualContacts() {
      return true;
    },

    removeHit() {
      return true;
    },

    removeMiss() {
      return true;
    },
  },

  api: {
    setListId(id) {
      return this.actions.newListId(id);
    },

    setName(name) {
      return this.actions.newName(name);
    },

    setSelectedContacts(contacts) {
      return this.actions.newSelectedContacts(contacts);
    },

    setManualInput(text) {
      return this.actions.newManualInput(text);
    },

    editManualContacts() {
      return this.actions.editManualContacts();
    },

    removeHit(token) {
      return this.actions.removeHit(token);
    },

    removeMiss(token) {
      return this.actions.removeMiss(token);
    },

    processManualContacts(tokens, bulkSelector) {
      const processedTokens = bulkSelector === 'email' ? tokens.map((token) => token.toLowerCase()) : tokens;

      this.actions.procesingManualContacts();
      return Api.CONTACTS.IDENTITY_MATCH.post({
        params: { provider: bulkSelector },
        data: JSON.stringify(processedTokens),
        success: (resp) => {
          this.actions.manualContactsSuccess(resp);
        },
        error: (xhr) => {
          return this.actions.manualContactsError(xhr?.responseJSON);
        },
      });
    },

    submit(bulkSelector) {
      return this.actions.submit(bulkSelector);
    },

    clear() {
      return this.actions.clearManualContacts();
    },
  },
});

export default BulkListSource;
