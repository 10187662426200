import { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import EverTrue from "app";
import { Button, ContactCard } from "@evertrue/et-components";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import _ from "underscore";
import InteractionSnippetCard from "apps/interactions/components/interaction-snippet-card";
import InteractionUtils from "apps/interactions/interaction-utils";
import StringSearchDisplay from "components/formatting/string-search-display";
import AISearchCard from "apps/ai-search/components/ai-search-card";
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';

const GlobalAutoCompleteDropdown = ({
  query,
  open,
  handleInsideClick,
  hasMultipleTypes,
  results,
  windowHeight,
  loading,
  selectedIndex,
  handleRowHighlight,
  handleRowClick,
  handleGoToResultType,
  linkButtonRef,
  handleGlobalSearch,
}) => {
  const { setContactFilters } = useContext(GlobalContactSearchContext);
  const { flagEnabled: hasAI } = useFeatureFlag("aiGeneral");
  const { flagEnabled: hasNLS } = useFeatureFlag("nlsEarlyAdopter");

  const styles = {
    marginBottom: !hasMultipleTypes || _.isEmpty(results) ? 0 : null,
    maxHeight: windowHeight - 146, // account for header and padding and footer
  };

  //NOTE: This is a prototype to add name filters 2.0 functionality to the global search
  // This should eventually be powered by an API endpoint
  const updateContactFilter = () => {
    const filter = {
      quick_filters: [],
      filters: {
        blocks: [
          {
            condition: "and_all_of",
            rules: [
              {
                group: "Name",
                label: "Name",
                compound_id: EverTrue.config.environment === "production" ? "123" : "235",
                operator: "must:wildcard",
                value: query,
                ui_component: "SearchInput",
              },
            ],
          },
          {
            condition: "or_all_of",
            rules: [{}],
          },
        ],
      },
    };

    setContactFilters(filter);
  };

  const handleClick = (key) => {
    if (key === "contacts") {
      updateContactFilter(key);
    }
    handleGoToResultType(key);
  };

  const renderType = (result) => {
    const { type, label, key, total, id } = result;
    switch (type) {
      case "header":
        return (
          <div className="clearfix">
            <strong className="pull-left">{label}</strong>
            <Button
              type="simple"
              onKeyDown={(evnt) => {
                if (evnt.key === "Enter") handleClick(key);
              }}
              className="pull-right global-autocomplete--view-all"
              title="View results"
              onClick={() => handleClick(key)}
            >
              {total === 1 ? "View 1 result" : `View All results`}
            </Button>
          </div>
        );

      case "contacts":
        return (
          <div className="global-autocomplete--contact">
            <ContactCard contact={result} />
          </div>
        );

      case "interactions":
        return (
          <InteractionSnippetCard interactionId={id} highlight={InteractionUtils.getHighlightRegex(query)} limit={2} />
        );

      case 'ai-search':
        return (hasAI && hasNLS) ? <AISearchCard query={query} /> : null;

      default:
        break;
    }
  };

  const renderList = () => {
    if (hasAI && hasNLS) {
      /* default AI search option with label and key*/
      const aiSearchOption = {
        type: 'ai-search',
        label: `AI Search for "${query}"`,
        key: 'ai-search',
      };

      results = [aiSearchOption, ...results];
    }

    if (_.isEmpty(results)) {
      return <li className="global-autocomplete--empty">{loading ? 'Loading...' : 'No results found'}</li>;
    } else {
      results = results.filter(
        (result) => (result.type === 'header' && result.key === 'contacts') || result.type === 'contacts' || result.type === "ai-search"
      );

      return results.map((result, index) => {
        return (
          <li
            key={result.type + (result.id || result.label)}
            className={classNames({
              'global-autocomplete--item': result.type !== 'header',
              'global-autocomplete--group-header': result.type === 'header',
              'is-selected': selectedIndex === index,
            })}
            onMouseEnter={() => handleRowHighlight(index)}
            onClick={(evnt) => {
                handleRowClick(evnt, result);
              }
            }
          >
            {renderType(result)}
          </li>
        );
      });
    }
  };

  const renderViewAllButton = () => {
    if (hasMultipleTypes && !_.isEmpty(results)) {
      return (
        <Button
          type="simple"
          ref={linkButtonRef}
          title="View all results"
          className={classNames("global-autocomplete--footer", { "is-selected": selectedIndex === results.length })}
          onClick={handleGlobalSearch}
          onKeyDown={(evnt) => {
            if (evnt.key === "Enter") {
              handleGlobalSearch();
            }
          }}
        >
          View all results for {<StringSearchDisplay string={query} />}
        </Button>
      );
    }
  };

  return (
    <div className={classNames("global-autocomplete--body", { "is-open": open })} onClick={handleInsideClick}>
      <ul className="global-autocomplete--list" styles={styles}>
        {renderList()}
      </ul>
      {renderViewAllButton()}
    </div>
  );
};

GlobalAutoCompleteDropdown.propTypes = {
  query: PropTypes.string,
  open: PropTypes.func,
  handleInsideClick: PropTypes.func,
  hasMultipleTypes: PropTypes.bool,
  results: PropTypes.array,
  windowHeight: PropTypes.number,
  loading: PropTypes.bool,
  selectedIndex: PropTypes.number,
  handleRowHighlight: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleGoToResultType: PropTypes.func,
  linkButtonRef: PropTypes.any,
  handleGlobalSearch: PropTypes.func,
};

export default GlobalAutoCompleteDropdown;
