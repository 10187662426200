import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/elements/loading';
import { Checkbox, ModalConfirm, Radio } from '@evertrue/et-components';
import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import { updateStage, removeProspectFromPool } from 'apps/portfolio-performance/portfolio-requests';
import EverTrue from 'app';
import {
  checkContactIsSolicitor,
  fetchStageAndProspectDetails,
} from 'apps/journeys/utils/api-requests/prospect-requests';
import { useIdentity } from 'base/identity-resolver';
import { useGate } from 'components/is-gated';

const UpdateStageModal2 = ({ close, contact }) => {
  const [selectedStage, setSelectedStage] = useState();
  const [stages, setStages] = useState([]);
  const [assignmentId, setAssignmentId] = useState(null);
  const [selectionMode, setSelectionMode] = useState(null);
  const [removeProspect, setRemoveProspect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canUpdateStage, setCanUpdateStage] = useState(false);
  const [canRemoveProspect, setCanRemoveProspect] = useState(false);
  const { affiliation: { contact_id: solicitorContactId } = {}, isOwner, isSuperUser } = useIdentity();
  const { id: prospectContactId } = contact;
  const [stageWritesEnabled] = useGate('rm_portfolio_stage_writes');

  const fetchStages = useCallback(async () => {
    setLoading(true);
    try {
      const { assignment_id, selected_stage, stages, selection_mode } = await fetchStageAndProspectDetails(
        solicitorContactId,
        prospectContactId
      );
      setSelectedStage(selected_stage);
      setStages(stages);
      setAssignmentId(assignment_id);
      setSelectionMode(selection_mode);
    } catch {
      EverTrue.Alert.error('Error loading stages');
    } finally {
      setLoading(false);
    }
  }, [solicitorContactId, prospectContactId]);

  useEffect(() => {
    fetchStages();
  }, [fetchStages]);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const isCurrentSolicitor = await checkContactIsSolicitor(solicitorContactId, prospectContactId);
        const canUpdate = isOwner || isSuperUser || (stageWritesEnabled && isCurrentSolicitor);
        const canRemove = canUpdate || (selectionMode === 1 && isCurrentSolicitor);
        setCanRemoveProspect(canRemove);
        setCanUpdateStage(canUpdate);
      } catch {
        setCanRemoveProspect(false);
        setCanUpdateStage(false);
      }
    };
    checkPermissions();
  }, [solicitorContactId, prospectContactId, isOwner, isSuperUser, stageWritesEnabled, selectionMode]);

  const handleSubmit = async () => {
    try {
      if (removeProspect) {
        await removeProspectFromPool(assignmentId);
        EverTrue.Alert.success('Removed Prospect from Stage');
      } else {
        await updateStage(assignmentId, { stage: selectedStage });
        EverTrue.Alert.success('Updated Prospect Stage');
      }
      close();
    } catch {
      EverTrue.Alert.error('Failed to update stage or remove prospect.');
    }
  };

  const toggleRemoveProspect = () => setRemoveProspect((prev) => !prev);
  const isButtonDisabled = !selectedStage && !removeProspect;

  return (
    <ModalConfirm
      style={{ minWidth: '400px' }}
      buttonLabel="Save"
      disableSave={isButtonDisabled}
      onSubmit={handleSubmit}
      header="Update Stage"
      onCancel={close}
    >
      <div className="update-stage-modal--body">
        {loading ? (
          <Loading />
        ) : (
          <>
            <JourneyContactCard contact={contact} />
            {!canUpdateStage ? (
              <div>You cannot Update Stage since this Prospect is not assigned to your Portfolio.</div>
            ) : stages.length === 0 ? (
              <div>You cannot Update Stage since this Prospect is not a part of any Stage.</div>
            ) : (
              <>
                <div>Update Stage:</div>
                <fieldset style={{ paddingLeft: '15px' }}>
                  {stages
                    .filter(({ active }) => active === 1)
                    .map(({ stage }) => (
                      <div key={stage} className="update-stage-modal--radios">
                        <Radio
                          name="stages"
                          onChange={() => setSelectedStage(stage)}
                          checked={stage === selectedStage}
                          label={stage}
                          disabled={removeProspect}
                        />
                      </div>
                    ))}
                </fieldset>
                {canRemoveProspect && (
                  <>
                    <div className="remove-prospect-checkbox">
                      <Checkbox
                        checked={removeProspect}
                        label="Remove Prospect from Portfolio"
                        onChange={toggleRemoveProspect}
                      >
                        Remove Prospect from Portfolio
                      </Checkbox>
                    </div>
                    <div className="journey-modal--alert">
                      This prospect will no longer be assigned to you and will be removed from the current stage.
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ModalConfirm>
  );
};

UpdateStageModal2.propTypes = {
  close: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
};

export default UpdateStageModal2;
