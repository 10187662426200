import { Modal, LinkButton } from "@evertrue/et-components";
import EnrichmentModalHeader from "./enrichment-modal-header";
import { PropTypes } from "base/react-libs";
import EnrichmentList from "./enrichment-list";
import moment from "moment";
import { useState } from "react";

const ACCESSOR = "type";

const WealthIndicatorsModal = ({
  isOpen,
  closeModal,
  onDelete,
  physicalAssets,
  wealthIndicators,
  contactAttributes,
  simplifiedNetWorthsArray = [],
}) => {
  const lastChange =
    simplifiedNetWorthsArray[0] && simplifiedNetWorthsArray[0].updated_at
      ? `Last noteworthy change ${moment(new Date(simplifiedNetWorthsArray[0].updated_at)).format("MMM DD, YYYY")}`
      : "";
  const lastCalculated =
    simplifiedNetWorthsArray[0] && simplifiedNetWorthsArray[0].net_worth_last_updated
      ? `Net worth calculated ${moment(new Date(simplifiedNetWorthsArray[0].net_worth_last_updated)).format(
          "MMM DD, YYYY"
        )}`
      : "";

  const activeNetWorths = simplifiedNetWorthsArray.filter(({ rejected }) => !rejected);
  const rejectedNetWorths = simplifiedNetWorthsArray.filter(({ rejected }) => rejected);
  const activePhysicalAssets = physicalAssets.filter(({ rejected }) => !rejected);
  const rejectedPhysicalAssets = physicalAssets.filter(({ rejected }) => rejected);
  const activeWealthIndicators = wealthIndicators.filter(({ rejected }) => !rejected);
  const rejectedWealthIndicators = wealthIndicators.filter(({ rejected }) => rejected);
  const [showRejected, setShowRejected] = useState(false);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} size="500px">
      <EnrichmentModalHeader
        headerText={`${contactAttributes.contactName}'s Wealth and Assets`}
        closeModal={closeModal}
        subheader={`${lastChange} ${lastCalculated && lastChange && String.fromCharCode(183)} ${lastCalculated}`}
      />
      <div className="profile-enrichment--more-modal">
        <>
          {activeNetWorths.length > 0 && (
            <EnrichmentList
              title="Net Worth"
              list={activeNetWorths}
              accessor="value"
              limit={activeNetWorths.length}
              className="profile--enrichment-wealth--networth"
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
          {activePhysicalAssets.length > 0 && (
            <EnrichmentList
              title="Physical Assets"
              list={activePhysicalAssets}
              accessor={ACCESSOR}
              limit={activePhysicalAssets.length}
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
          {activeWealthIndicators.length > 0 && (
            <EnrichmentList
              title="Wealth Indicators"
              list={activeWealthIndicators}
              accessor={ACCESSOR}
              limit={activeWealthIndicators.length}
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
        </>

        {(rejectedNetWorths.length > 0 || rejectedPhysicalAssets.length > 0 || rejectedWealthIndicators.length > 0) && (
          <LinkButton onClick={() => setShowRejected(!showRejected)} title="">
            {showRejected ? "Hide deleted" : "See deleted"}
          </LinkButton>
        )}

        <div style={{ marginTop: "15px" }}>
          {showRejected && rejectedNetWorths.length > 0 && (
            <EnrichmentList
              title="Net Worth"
              list={rejectedNetWorths}
              accessor="value"
              className="profile--enrichment-wealth--networth"
              limit={rejectedNetWorths.length}
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
          {showRejected && rejectedPhysicalAssets.length > 0 && (
            <EnrichmentList
              title="Physical Assets"
              list={rejectedPhysicalAssets}
              accessor={ACCESSOR}
              limit={rejectedPhysicalAssets.length}
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
          {showRejected && rejectedWealthIndicators.length > 0 && (
            <EnrichmentList
              title="Wealth Indicators"
              list={rejectedWealthIndicators}
              accessor={ACCESSOR}
              limit={rejectedWealthIndicators.length}
              contactId={contactAttributes.contactId}
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

WealthIndicatorsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onDelete: PropTypes.func,
  physicalAssets: PropTypes.array,
  wealthIndicators: PropTypes.array,
  contactAttributes: PropTypes.object,
  simplifiedNetWorthsArray: PropTypes.array,
};

export default WealthIndicatorsModal;
