module.exports = do ->
   _ = require("underscore").default
   CriteriaParser = require("entities/search/query-parts/criteria")
   FacetUtils = require("apps/filters/utils/facet-utils")

   OPTIONS =
      multi_select_with_not: "parseListWithNot"
      basic_select: "parseListWithNot"
      list: "parseList"
      list_with_checkbox: "parseList"
      boolean: "parseBool"
      range: "parseRange"
      dps_range: "parseRange"
      currency_range: "parseRange"
      slider_range: "parseRange"
      date_range: "parseDate"
      text_input: "parseKeywords"
      operator_component: "parseOperatorComponent"
      operator_component_with_checkbox: "parseOperatorComponent"
      constituency_operator_component: "parseConstituencyOperatorComponent"
      date_operator: "parseDateOperatorComponent"
      date_operator_v2: "parseDateOperatorComponent"
      number_operator: "parseNumberAndCurrencyOperatorComponent"
      currency_operator: "parseNumberAndCurrencyOperatorComponent"
      year_operator: "parseYearOperatorComponent"


   # Parsing functions for New UI filters (formatted as shared searches)
   # 'criteria' expects a query for one filter, where `full_criteria` is the
   # entire search query object
   FilterParser =
      getComponentParser: (type) ->
         if func = OPTIONS[type] then @[func]
         else console.error("FilterParser: #{type} filter-parser doesn't exist. See filter-parser-utils.")

      parse: (filter_config, criteria) ->
         if filter_config.parse_fn
            filter_config.parse_fn(CriteriaParser(criteria))
         else if parser = @getComponentParser(filter_config.component_type)
            parser(CriteriaParser(criteria))
         else
            console.error("No Filter Parser: #{filter_config.filter_id}")

      parseList: (criteria) ->
         values = criteria.getValueByOperator("in")
         _.map values, (item) -> {label: item, value: item}

      parseGroupedList: (criteria) ->
         query = criteria.getByParameter("must")
         _.flatMap query, (query_part) ->
            wrapped_data = _.map query_part.value, (item) ->
               {label: item, value: FacetUtils.wrapValue(query_part.property, item)}
            _.uniq wrapped_data, false, (data) -> data.value
      
      parseOperatorComponent: (criteria) ->
         mustQuery = criteria.getByParameter("must")
         mustNotQuery = criteria.getByParameter("must_not")

         inOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "in")
         if inOperatorMustQuery
            wrappedData = _.map inOperatorMustQuery.value, (item) ->
               { label: item, value: FacetUtils.wrapValue(inOperatorMustQuery.property, item) }
            return {
               "parameter": {
                  "label": "equals",
                  "value": "must"
               },
               "value": _.uniq(wrappedData, false, (data) -> data.value)
            }

         inOperatorMustNotQuery = _.find(mustNotQuery, (item) -> item.operator == "in")
         if inOperatorMustNotQuery
            wrappedData = _.map inOperatorMustNotQuery.value, (item) ->
               { label: item, value: FacetUtils.wrapValue(inOperatorMustNotQuery.property, item) }
            return {
               "parameter": {
                  "label": "does not equal",
                  "value": "must_not"
               },
               "value": _.uniq(wrappedData, false, (data) -> data.value)
            }

         existsOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "exists")
         if existsOperatorMustQuery
            return {
               "parameter": {
                  "label": if existsOperatorMustQuery.value then "is set" else "is not set",
                  "value": if existsOperatorMustQuery.value then "is_set" else "is_not_set"
               }
            }

         return {}

      parseConstituencyOperatorComponent: (criteria) ->
         mustQuery = criteria.getByParameter("must")
         mustNotQuery = criteria.getByParameter("must_not")

         inOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "in")
         if inOperatorMustQuery
            wrappedData = _.map inOperatorMustQuery.value, (item) ->
               { label: item, value: FacetUtils.wrapValue(inOperatorMustQuery.property, item) }
            return {
               "parameter": {
                  "label": "equals",
                  "value": "must"
               },
               "value": _.uniq(wrappedData, false, (data) -> data.value)
            }

         inOperatorMustNotQuery = _.find(mustNotQuery, (item) -> item.operator == "in")
         if inOperatorMustNotQuery
            wrappedData = _.map inOperatorMustNotQuery.value, (item) ->
               { label: item, value: FacetUtils.wrapValue(inOperatorMustNotQuery.property, item) }
            return {
               "parameter": {
                  "label": "does not equal",
                  "value": "must_not"
               },
               "value": _.uniq(wrappedData, false, (data) -> data.value)
            }

         existsOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "exists" && item.value)
         if existsOperatorMustQuery
            return {
                  "parameter": {
                     "label": "is set",
                     "value": "is_set"
                  }
            }

         existsOperatorMustNotQuery = _.find(mustNotQuery, (item) -> item.operator == "exists" && item.value)
         if existsOperatorMustNotQuery
            return {
                  "parameter": {
                     "label": "is not set",
                     "value": "is_not_set"
                  }
            }
         
         return {}

      parseDateOperatorComponent: (criteria) ->
         mustQuery = criteria.getByParameter("must")
         mustNotQuery = criteria.getByParameter("must_not")

         onQuery = _.find(mustQuery, (item) -> item.value.gte && item.value.lte && item.value.gte == item.value.lte)
         if onQuery
            return {
               "parameter": {
                  "label": "Is on",
                  "value": "must:match"
               },
               "date": onQuery.value.gte
            }

         notOnQuery = _.find(mustNotQuery, (item) -> item.value.gte && item.value.lte && item.value.gte == item.value.lte)
         if notOnQuery
            return {
               "parameter": {
                  "label": "Is not on",
                  "value": "must_not:match"
               },
               "date": notOnQuery.value.gte
            }

         betweenQuery = _.find(mustQuery, (item) -> item.value.gte && item.value.lte)
         if betweenQuery
            return {
               "parameter": {
                  "label": "Is between",
                  "value": "must"
               },
               "from": betweenQuery.value.gte,
               "to": betweenQuery.value.lte
            }

         notBetweenQuery = _.find(mustNotQuery, (item) -> item.value.gte && item.value.lte)
         if notBetweenQuery
            return {
               "parameter": {
                  "label": "Is not between",
                  "value": "must_not"
               },
               "from": notBetweenQuery.value.gte,
               "to": notBetweenQuery.value.lte
            }

         inLastQuery = _.find(mustQuery, (item) -> item.value && item.operator == "in")
         if inLastQuery
            return {
               "parameter": {
                  "label": "Is in the last",
                  "value": "must:gte"
               },
               "in": inLastQuery.value
            }

         notInLastQuery = _.find(mustNotQuery, (item) -> item.value && item.operator == "in")
         if notInLastQuery
            return {
               "parameter": {
                  "label": "Is not in the last",
                  "value": "must_not:gt"
               },
               "in": notInLastQuery.value
            }

         beforeQuery = _.find(mustQuery, (item) -> item.value.lt)
         if beforeQuery
            return {
               "parameter": {
                  "label": "Is before",
                  "value": "must:lt"
               },
               "date": beforeQuery.value.lt
            }

         afterQuery = _.find(mustQuery, (item) -> item.value.gt)
         if afterQuery
            return {
               "parameter": {
                  "label": "Is after",
                  "value": "must:gt"
               },
               "date": afterQuery.value.gt
            }

         return {}

      parseNumberAndCurrencyOperatorComponent: (criteria) ->
         mustQuery = criteria.getByParameter("must")
         mustNotQuery = criteria.getByParameter("must_not")

         matchOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "match")
         if matchOperatorMustQuery
            return {
               "parameter": {
                  "label": "Is equal to",
                  "value": "must"
               },
               "value": matchOperatorMustQuery.value
            }

         matchOperatorMustNotQuery = _.find(mustNotQuery, (item) -> item.operator == "match")
         if matchOperatorMustNotQuery
            return {
               "parameter": {
                  "label": "Is not equal to",
                  "value": "must_not"
               },
               "value": matchOperatorMustNotQuery.value
            }

         greaterThanQuery = _.find(mustQuery, (item) -> item.value.gt)
         if greaterThanQuery
            return {
               "parameter": {
                  "label": "Is greater than",
                  "value": "must:gt"
               },
               "value": greaterThanQuery.value.gt
            }
         
         lessThanQuery = _.find(mustQuery, (item) -> item.value.lt)
         if lessThanQuery
            return {
               "parameter": {
                  "label": "Is less than",
                  "value": "must:lt"
               },
               "value": lessThanQuery.value.lt
            }

         betweenQuery = _.find(mustQuery, (item) -> item.value.gte || item.value.lte)
         if betweenQuery
            return {
               "parameter": {
                  "label": "Is between",
                  "value": "must"
               },
               "gte": betweenQuery.value.gte,
               "lte": betweenQuery.value.lte
            }

         notBetweenQuery = _.find(mustNotQuery, (item) -> item.value.gte || item.value.lte)
         if notBetweenQuery
            return {
               "parameter": {
                  "label": "Is not between",
                  "value": "must_not"
               },
               "gte": notBetweenQuery.value.gte,
               "lte": notBetweenQuery.value.lte
            }

         existsOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "exists")
         if existsOperatorMustQuery
            return {
               "parameter": {
                  "label": if existsOperatorMustQuery.value then "is set" else "is not set",
                  "value": if existsOperatorMustQuery.value then "is_set" else "is_not_set"
               }
            }

         return {}

      parseRange: (criteria) ->
         _.omit criteria.getValueByOperator(), "coerce"

      parseBool: (criteria) ->
         if criteria.hasParameter("must") then param = true
         else if criteria.hasParameter("must_not") then param = false

         if param && criteria.hasOperator("exists")
            criteria.getValueByOperator("exists")
         else if param && criteria.hasOperator("match")
            criteria.getValueByOperator("match")
         else param

      parseDate: (criteria) ->
         property = criteria.getValueByOperator()
         if property.gte?.match(/^now/) || property.lte?.match(/^now/)
            if property.gte == "now" then value = property.lte
            else value = property.gte
         else value = "range"

         parameter: {value: criteria.getOneParameter()}
         value: value
         range: (if value == "range" then property)

      parseListWithNot: (criteria) ->
         parameter: {value: criteria.getOneParameter()}
         value: _.map criteria.getValueByOperator("in"), (item) ->
            {label: item, value: item}

      parseKeywords: (criteria) ->
         if criteria.getByParameter
            criteria_data = criteria.getByParameter("must")
         else criteria_data = criteria
         value: _.uniq(_.pluck(criteria_data, "value"))?.join(" ").replace(/\*/g, "")

      parsePassThrough: (criteria) ->
         criteria.getValueByOperator()

      # TODO: Figure some better logic here, this is very fragile.
      parseSearchOperator: (full_criteria, filters) ->
         criteria = CriteriaParser(full_criteria)
         if _.isEmpty(filters) || !!full_criteria.must_not?.length then "and"
         else if full_criteria.has_child then "and"
         else if criteria.hasParent() then "and"
         else if (full_criteria.must?.length + full_criteria.should?.length) == filters.length then "and"
         else if full_criteria.must?.length < filters.length then "or"
         else "and"

      parseYearOperatorComponent: (criteria) ->
         mustQuery = criteria.getByParameter("must")
         mustNotQuery = criteria.getByParameter("must_not")

         matchOperatorMustQuery = _.find(mustQuery, (item) -> item.operator == "match")
         if matchOperatorMustQuery
            return {
               "parameter": {
                  "label": "Is equal to",
                  "value": "must"
               },
               "value": matchOperatorMustQuery.value
            }

         matchOperatorMustNotQuery = _.find(mustNotQuery, (item) -> item.operator == "match")
         if matchOperatorMustNotQuery
            return {
               "parameter": {
                  "label": "Is not equal to",
                  "value": "must_not"
               },
               "value": matchOperatorMustNotQuery.value
            }

         greaterThanQuery = _.find(mustQuery, (item) -> item.value.gt)
         if greaterThanQuery
            return {
               "parameter": {
                  "label": "Is greater than",
                  "value": "must:gt"
               },
               "value": greaterThanQuery.value.gt
            }
         
         lessThanQuery = _.find(mustQuery, (item) -> item.value.lt)
         if lessThanQuery
            return {
               "parameter": {
                  "label": "Is less than",
                  "value": "must:lt"
               },
               "value": lessThanQuery.value.lt
            }

         betweenQuery = _.find(mustQuery, (item) -> item.value.gte || item.value.lte)
         if betweenQuery
            return {
               "parameter": {
                  "label": "Is between",
                  "value": "must"
               },
               "gte": betweenQuery.value.gte,
               "lte": betweenQuery.value.lte
            }

         notBetweenQuery = _.find(mustNotQuery, (item) -> item.value.gte || item.value.lte)
         if notBetweenQuery
            return {
               "parameter": {
                  "label": "Is not between",
                  "value": "must_not"
               },
               "gte": notBetweenQuery.value.gte,
               "lte": notBetweenQuery.value.lte
            }

         return {}