import Api from 'entities/helpers/api';
import { useState, useEffect } from 'react';
import EverTrue from 'app';
import { TasksContext } from 'apps/tasks/hooks/tasks-context';
import { setTaskCompletion } from '../utils/tasks-actions';
import { useContext } from 'react';
import { useTasksNotificationContext } from 'apps/tasks/hooks/tasks-notification-context.js';
import { useIdentity } from 'base/identity-resolver';
import { trackTaskAction, arraysMatch } from 'apps/tasks/utils/utils';

const ALL_TASK_STATUSES = ['IN_PROGRESS', 'PAUSED', 'COMPLETED'];

const useTasks = () => {
  const [taskActionTypes, setTaskActionTypes] = useState([]);
  const { setTasks, tasks, removeTaskFromState, taskFilters, allTasksCount, setAllTasksCount } =
    useContext(TasksContext);
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();
  const {
    user: { id },
  } = useIdentity();

  useEffect(() => {
    const fetchData = async () => {
      const actionTypes = await fetchFormattedActionTypes();
      setTaskActionTypes(actionTypes);
    };
    fetchData();
  }, []);

  const fetchFormattedActionTypes = async () => {
    try {
      const actionTypes = await Api.JOURNEYS.TASK_ACTION_TYPES.get();
      // format hashmap -> { EMAIL: "email", ...} -> [{value: "EMAIL", label: "email"}, {...}]
      const formattedTaskActionTypes = Object.entries(actionTypes).map(([value, label]) => ({ value, label }));
      return formattedTaskActionTypes;
    } catch {
      return EverTrue.Alert.error('Action Types could not be retrieved');
    }
  };

  // Parse action type: PRINT_MAIL -> Print Mail (e.g. Pledgemine, other)
  const parseActionType = (actionType) => {
    for (const type of taskActionTypes) {
      if (type.value === actionType) {
        return type.label;
      }
    }
    return '';
  };

  const isValidEntry = (value, validations) => {
    if (typeof value === 'string') {
      value = value.trim();
    }
    return Object.entries(validations).every(([ruleName, ruleValue]) => {
      switch (ruleName) {
        case 'maxLength':
          return value.length <= ruleValue;
        case 'required':
          return ruleValue ? !!value : true;
        default:
          return true;
      }
    });
  };

  const submitTaskDisabled = (payload, isActionTypeRequired = true) => {
    // ADD VALIDATIONS FOR PAYLOAD HERE
    const FORM_VALIDATION_RULES = {
      title: {
        required: true,
        maxLength: 120,
      },
      owner_user_id: {
        required: true,
      },
      action_type: {
        required: isActionTypeRequired,
      },
      description: {
        maxLength: 250,
      },
    };

    if (!payload) return true;
    const validPayload = Object.keys(FORM_VALIDATION_RULES).every((entry) =>
      isValidEntry(payload[entry], FORM_VALIDATION_RULES[entry])
    );
    return !validPayload;
  };

  const createTask = async (payload, isAiTask = false) => {
    if (isAiTask) {
      trackTaskAction('create_suggested_task');
    } else {
      trackTaskAction('create_task_from_task_screen');
    }
    const { owner_user_id } = payload;
    return await Api.JOURNEYS.TASKS.request({
      type: 'POST',
      data: JSON.stringify(payload),
      success: (resp) => {
        if (allTasksCount === 0) {
          setAllTasksCount(1);
        }
        getAndSetGlobalTasksDueCount();
        if (id !== owner_user_id) trackTaskAction(id !== owner_user_id ? 'assigned_to_others' : 'assigned_to_creator');
        return resp;
      },
      error: (e) => {
        return EverTrue.Alert.error('Task creation unsuccessful. Please try again.');
      },
    });
  };

  const getTaskById = async (taskId) => {
    return await Api.JOURNEYS.TASK.get({
      urlArgs: { id: taskId },
      success: (resp) => {
        return resp;
      },
      error() {
        return EverTrue.Alert.error('Task could not be fetched. Please try again.');
      },
    });
  };

  const updateTask = async (payload, taskObj, reason = null) => {
    trackTaskAction('edit_task');
    const newPayload = Object.assign({}, taskObj, payload);
    // check either task is belong to cadence or not
    const updateSubsequentTasks = !!(taskObj.journey_task_id && taskObj.contact_journey_id);
    return await Api.JOURNEYS.TASK.request({
      urlArgs: { id: newPayload.id },
      params: { update_subsequent_tasks: updateSubsequentTasks, reason: reason },
      type: 'PUT',
      data: JSON.stringify(newPayload),
      success: (resp) => {
        if (
          payload.current_due_date !== taskObj.current_due_date ||
          (payload.owner_user_id !== taskObj.owner_user_id &&
            (payload.owner_user_id === id || taskObj.owner_user_id === id))
        ) {
          getAndSetGlobalTasksDueCount();
        }
        return resp;
      },
      error() {
        return EverTrue.Alert.error('Task update was unsuccessful. Please try again.');
      },
    });
  };

  const deleteTask = async (task, userType) => {
    return await Api.JOURNEYS.TASK.request({
      urlArgs: { id: task.id },
      type: 'DELETE',
      success: () => {
        EverTrue.Alert.success(
          `${task.title.length > 30 ? ` ${task.title.substring(0, 21).concat('....')} ` : task.title} task deleted!`
        );
        trackTaskAction('delete_task');
        removeTaskFromState(task);
      },
      error() {
        return EverTrue.Alert.error('Task could not be deleted. Please try again.');
      },
    });
  };

  const toggleCompletionStatus = async (task) => {
    let selectedTask = tasks.find((t) => task.id === t.id);
    if (task.completed_at) {
      await setTaskCompletion(task, false);
      selectedTask.completed_at = null;
    } else {
      trackTaskAction('complete_task');
      const updatedTask = await setTaskCompletion(task, true);
      selectedTask.completed_at = updatedTask.completed_at;
    }

    getAndSetGlobalTasksDueCount();

    if (!arraysMatch(taskFilters.statuses, ALL_TASK_STATUSES)) {
      removeTaskFromState(task);
    }
    if (arraysMatch(taskFilters.statuses, ALL_TASK_STATUSES)) {
      setTasks([...tasks]);
    }
  };

  return {
    fetchFormattedActionTypes,
    submitTaskDisabled,
    createTask,
    updateTask,
    deleteTask,
    toggleCompletionStatus,
    getTaskById,
    taskActionTypes,
    parseActionType,
  };
};

export default useTasks;
