import { useState, useRef, useEffect } from "react";
import { Icon } from "@evertrue/et-components";
import moment from "moment";
import PropTypes from "prop-types";

const options = [
  { label: "Today", value: "today" },
  { label: "Last Week", value: "last-week" },
  { label: "Last Month", value: "last-month" },
  { label: "Last Year", value: "last-year" },
  { label: "All Time", value: "all-time" },
  { label: "Custom Range", value: "custom-range" },
];

const DateRangeFilter = ({ onDateRangeChange }) => {
  const today = new Date();
  const [startDate, setStartDate] = useState("2000-01-01");
  const [endDate, setEndDate] = useState(today.toISOString().slice(0, 10));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[4]);
  const dropdownRef = useRef(null);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    onDateRangeChange(event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    onDateRangeChange(startDate, event.target.value);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    const dateRanges = {
      today: () => {
        const todayStr = today.toISOString().slice(0, 10);
        return [todayStr, todayStr];
      },
      "last-week": () => [
        moment().subtract(1, "week").startOf("week").toISOString().slice(0, 10),
        moment().subtract(1, "week").endOf("week").toISOString().slice(0, 10),
      ],
      "last-month": () => [
        moment().subtract(1, "month").startOf("month").toISOString().slice(0, 10),
        moment().subtract(1, "month").endOf("month").toISOString().slice(0, 10),
      ],
      "last-year": () => [
        moment().subtract(1, "year").startOf("year").toISOString().slice(0, 10),
        moment().subtract(1, "year").endOf("year").toISOString().slice(0, 10),
      ],
      "all-time": () => ["2000-01-01", today.toISOString().slice(0, 10)],
      "custom-range": () => [startDate, endDate],
    };

    const [newStartDate, newEndDate] = dateRanges[option.value]();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onDateRangeChange(newStartDate, newEndDate);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="date-range-filter" ref={dropdownRef}>
      <div className="date-range-filter__header" onClick={toggleDropdown}>
        <span className="date-range-filter__date-display">
          {selectedOption.value === "custom-range"
            ? startDate && endDate
              ? `${moment(startDate).format("M/D/YYYY")} - ${moment(endDate).format("M/D/YYYY")}`
              : "Select Date"
            : selectedOption.label}
        </span>
        <Icon icon={isDropdownOpen ? "chevron-up" : "chevron-down"} />
      </div>
      {isDropdownOpen && (
        <div className="date-range-filter__dropdown">
          <div className="date-range-filter__date-inputs">
            <div className="date-range-filter__input-group">
              <label htmlFor="start-date" className="date-range-filter__label">
                Start Date
              </label>
              <input
                id="start-date"
                type="date"
                className="date-range-filter__date-input"
                value={startDate || ""}
                onChange={handleStartDateChange}
              />
            </div>
            <span className="date-range-filter__separator"></span>
            <div className="date-range-filter__input-group">
              <label htmlFor="end-date" className="date-range-filter__label">
                End Date
              </label>
              <input
                id="end-date"
                type="date"
                className="date-range-filter__date-input"
                value={endDate || ""}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          {/* <AdvancedCombobox
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
            label="Select a date range"
            placeholder="Select a date range"
          /> */}
          <ul className="date-range-filter__options-list" role="listbox" aria-label="Date range options">
            {options.map((option) => (
              <li
                key={option.value}
                className={`date-range-filter__options-item ${selectedOption?.value === option.value ? "active" : ""}`}
                onClick={() => handleOptionChange(option)}
                role="option"
                aria-selected={selectedOption?.value === option.value}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleOptionChange(option);
                  }
                }}
              >
                <span className={selectedOption?.value === option.value ? "font-bold" : ""}>{option.label}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

DateRangeFilter.propTypes = {
  onDateRangeChange: PropTypes.func,
};

export default DateRangeFilter;
