import { PropTypes } from 'base/react-libs';
import NextFiveDays from './next-five-days/next-five-days';
import PausedNextFiveDays from './next-five-days/paused-next-five-days';
import TodayContent from './today-card/today-content';
import PausedToday from './today-card/paused-today.js';
import useJourneyTimeoutsApi from 'apps/journeys/hooks/use-journey-timeouts-api';

const Upcoming = ({ user }) => {
  const { inProgressJourneyTimeout } = useJourneyTimeoutsApi();

  return (
    <>
      <h1 className="resource-title-upcoming">Upcoming</h1>
      <div className="homepage-top">
        {inProgressJourneyTimeout ? (
          <>
            <PausedToday />
            <PausedNextFiveDays user={user} inProgressJourneyTimeout={inProgressJourneyTimeout} />
          </>
        ) : (
          <>
            <TodayContent user={user} />
            <NextFiveDays user={user} />
          </>
        )}
      </div>
    </>
  );
};

Upcoming.propTypes = {
  user: PropTypes.object,
};

export default Upcoming;
