import EverTrue from 'app';
import useListDataStream from 'entities/helpers/use-list-data-stream';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { useState, useEffect } from 'react';

const useSuggestedTasks = (contactId) => {
  const { flagEnabled: enabledTasks } = useFeatureFlag('signal');
  const { flagEnabled: enabledAI } = useFeatureFlag('aiGeneral');
  const { openListDataStream, response, isStreaming } = useListDataStream();

  const [aiTasks, setAiTasks] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const query = {
          prompt: {
            key: 'cadences/suggest_tasks',
            args: {
              solicitor_contact_id: contactId,
            },
          },
        };

        const options = {
          method: 'POST',
          endpoint: `ai/v2/completions/`,
          urlArgs: {},
          params: {
            append_request_id: 'false',
          },
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'ET-Request-ID': `suggest_tasks:${contactId}`,
          },
          data: JSON.stringify(query),
        };
        await openListDataStream(options);
      } catch (e) {
        EverTrue.Alert.error('Error fetching AI tasks');
      }
    };

    if (enabledTasks && enabledAI && contactId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(response).length > 0) {
      setAiTasks(response);
    }
  }, [isStreaming, response]);

  return { aiTasks, isStreaming, enabled: enabledAI && enabledTasks };
};

export default useSuggestedTasks;
