import PropTypes from 'prop-types';
import { PageHeader, Icon } from '@evertrue/et-components';
import AddTaskButton from './create/add-task-button';
import EverTrue from 'app';
import TasksActionMenu from './tasks-action-menu';

const TasksHeader = ({
  userType,
  bulkSelectedTasks,
  selectedDueDate,
  emptyState = false,
  hasBulkTaskMoves,
  resetBulkSelect = () => {},
}) => {
  return (
    <div className="task-header-container">
      <Icon
        icon="chevron-left"
        size={2}
        className="task-header-back"
        onClick={() => {
          EverTrue.UrlManager.navigate('/home', true, false);
        }}
      />
      <PageHeader
        className={'task-header'}
        title="Tasks"
        actions={
          !emptyState && (
            <>
              <AddTaskButton userType={userType} />
              <TasksActionMenu
                selectedDueDate={selectedDueDate}
                bulkSelectedTasks={bulkSelectedTasks}
                hasBulkTaskMoves={hasBulkTaskMoves}
                resetBulkSelect={resetBulkSelect}
              />
            </>
          )
        }
      />
    </div>
  );
};

TasksHeader.propTypes = {
  userType: PropTypes.string,
  bulkSelectedTasks: PropTypes.array,
  selectedDueDate: PropTypes.string,
  emptyState: PropTypes.bool,
  hasBulkTaskMoves: PropTypes.bool,
  resetBulkSelect: PropTypes.func,
};

export default TasksHeader;
