import { Switch } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';

const JourneysWithStepsDueToggle = ({ onlyJourneysWithStepsDue = false, setOnlyJourneysWithStepsDue = () => {} }) => {
  return (
    <Switch
      label="Due Today"
      showLabel={true}
      name="active"
      checked={onlyJourneysWithStepsDue}
      value={onlyJourneysWithStepsDue}
      onChange={(v) => setOnlyJourneysWithStepsDue(v)}
    />
  );
};

JourneysWithStepsDueToggle.propTypes = {
  onlyJourneysWithStepsDue: PropTypes.bool,
  setOnlyJourneysWithStepsDue: PropTypes.func,
};

export default JourneysWithStepsDueToggle;
