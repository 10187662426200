import { useState, useEffect } from 'react';

const useFetchResults = (query, optionVariableName, hasOptions, variables, selectedOption, fetchStat) => {
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const variablesString = JSON.stringify(variables);

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    const gqlVariables = { ...variables };

    hasOptions && (gqlVariables[optionVariableName] = selectedOption);

    if (hasOptions) {
      if (selectedOption) {
        fetchStat(query, gqlVariables)
          .then(({ data = {} }) => {
            if (!didCancel) {
              setResult(data);
              setLoading(false);
            }
          })
          .catch((err = {}) => {
            if (!didCancel) {
              setLoading(false);
            }
          });
      }
    } else {
      fetchStat(query, gqlVariables)
        .then(({ data = {} }) => {
          if (!didCancel) {
            setResult(data);
            setLoading(false);
          }
        })
        .catch((err = {}) => {
          if (!didCancel) {
            setLoading(false);
          }
        });
    }

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line
  }, [variablesString, selectedOption]);

  return { result, loading };
};

export default useFetchResults;
