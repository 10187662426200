import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const ImportSource = createSource('ImportSource', {
  actions: {
    loading: true,
    fetchedLastImport(timestamp) {
      return this.require(
        typeof timestamp === 'number' || timestamp === undefined,
        'timestamp should be millisecond time or undefined'
      );
    },
  },

  api: {
    fetchLastImport() {
      this.actions.loading(true);
      return Api.IMPORTER.LATEST.get({
        success: (response) => {
          this.actions.loading(false);
          return this.actions.fetchedLastImport(response != null ? response.status_started_at : undefined);
        },
        error: () => {
          return this.actions.loading(false);
        },
      });
    },
  },
});

export default ImportSource;
