import { useEffect } from 'react';
import EverTrue from 'app';
import { useGlobalOutreachProvider } from '../contexts/global-outreach-context';
import PropTypes from 'prop-types';
import { getMomentObjectInTimezone } from 'base/new-utils';
import { cancelScheduledEmail } from '../utils/scheduled-email-api';
import { Icon } from '@evertrue/et-components';

const ScheduledDetailedView = ({ id, setSubject }) => {
  const { scheduled, getAllScheduled, getAllDrafts } = useGlobalOutreachProvider();
  const scheduledItem = scheduled.find((item) => item.id === Number(id));

  useEffect(() => {
    if (scheduledItem) {
      setSubject(scheduledItem.subject);
    }
  }, [scheduledItem, setSubject]);

  if (!scheduledItem) {
    return;
  }

  const { email_recipients, scheduled_at, parsed_body } = scheduledItem;
  const recipientNames =
    email_recipients?.length > 0 ? email_recipients.map((recipient) => recipient.email_address).join(', ') : '';

  const cancelSend = async () => {
    const confirmed = await new Promise((resolve) => {
      EverTrue.Alert.confirm(
        {
          headline: 'Cancel Scheduled Send',
          content: `Are you sure you want to cancel the scheduled send?`,
          buttonLabel: 'Submit',
        },
        (confirmation) => resolve(confirmation)
      );
    });

    if (confirmed) {
      await cancelScheduledEmail(id);
      getAllDrafts();
      getAllScheduled();
      EverTrue.UrlManager.navigate('/outreach/email', true, false);
    }
  };

  return (
    <>
      <div className="scheduled-detail-view--header-container ">
        <span>
          <Icon icon="circle" iconKit="gt2" style={{ color: '#939393', marginRight: '3px', fontSize: '16px' }} />
          Scheduled Send for {getMomentObjectInTimezone(scheduled_at * 1000)?.format('MMM D, YYYY [at] h:mm A z')}
        </span>
        <span onClick={cancelSend} style={{ color: '#007c9e', cursor: 'pointer' }}>
          Cancel Send
        </span>
      </div>
      <div className="scheduled-detail-view--body">
        <span className="outreach-card-recipient-name" style={{ marginBottom: '13px' }}>
          <span style={{ color: '#2C2C2C', fontWeight: '500' }}>To: </span>
          {recipientNames}
        </span>
        <span>{parsed_body}</span>
      </div>
    </>
  );
};

ScheduledDetailedView.propTypes = {
  id: PropTypes.string,
  setSubject: PropTypes.func,
};

export default ScheduledDetailedView;
