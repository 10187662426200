import EverTrue from 'app';

export const trackJourneysAction = (type, condition) => {
  const track = (type) => {
    EverTrue.track.set('cadences', { type });
  };
  const { pathname } = window.location;
  switch (type) {
    case 'list-portfolio':
      if (pathname.includes('portfolio')) {
        track('add_cadence_from_portfolio');
      } else if (pathname.includes('list')) {
        track('add_cadence_from_list');
      } else if (pathname.includes('contacts')) {
        track('add_cadence_from_constituent');
      }
      break;
    case 'cadence-pinned-comment':
      if (pathname.includes('cadence')) {
        track('add_pinned_comment');
      }
      break;
    case 'cadence-pinned-interaction':
      if (pathname.includes('cadence')) {
        track('add_interaction');
      }
      break;
    case 'complete_toggle':
      track(condition);
      break;
    default:
      track(type);
      break;
  }
};

export const trackActionOnTask = (type) => {
  EverTrue.track.set('tasks', { type });
};
