import _ from 'underscore';
import { getQueryFromBox } from 'apps/map/map-utils';
import MapStore from 'apps/map/stores/map-store';

const CONTACT_QUERY = (pool_id, solicitor_id, stages) => ({
  // if stages are omitted, then we just want all the prospect
  // assignments regardless of stages
  has_child: [
    {
      type: 'assignment',
      query: {
        must: [
          { parent_role: { match: 'prospect' } },
          { pool_id: { match: pool_id } },
          { solicitor_contact_id: { match: solicitor_id } },
          ...(_.isEmpty(stages) ? [] : [{ 'assignment_stage.untouched': { in: stages } }]),
        ],
      },
    },
  ],
});

const BASE_QUERY = (pool_id, solicitor_id) => ({
  has_child: [
    {
      type: 'assignment',
      query: {
        must: [
          { parent_role: { match: 'prospect' } },
          { pool_id: { match: pool_id } },
          { solicitor_contact_id: { match: solicitor_id } },
        ],
      },
    },
  ],
});

const getDefaultSort = (sort_prop, sort_reverse) => {
  return [_.toObject(sort_prop, { order: sort_reverse ? 'desc' : 'asc' })];
};

const getNameSort = (sort_reverse) => {
  return [
    {
      name_last: { order: sort_reverse ? 'desc' : 'asc' },
    },
    {
      name_first: { order: sort_reverse ? 'desc' : 'asc' },
    },
  ];
};

const TEAM_SOLICITORS_QUERY = (search_text, pools, assignmentType = 'TEAM') => ({
  must: !search_text
    ? []
    : _.map(search_text ? search_text.split(' ') : undefined, (name) => ({
        name_first: { wildcard: `${name}*` },
        name_last: { wildcard: `${name}*` },
      })),
  sort: [{ name_first: { order: 'asc' } }, { name_last: { order: 'asc' } }],
  has_child: [
    {
      type: 'assignment',
      query: {
        must: [
          { parent_role: { match: 'solicitor' } },
          { pool_type: { match: assignmentType } },
          ...(_.isEmpty(pools) ? [] : [{ pool_id: { in: pools } }]),
        ],
      },
    },
  ],
});

const getContactIsSolicitor = (contactId) => ({
  must: [{ id: { match: contactId } }],
  has_child: [
    {
      type: 'assignment',
      query: {
        must: [{ parent_role: { match: 'solicitor' } }, { pool_type: { match: 'TEAM' } }],
      },
    },
  ],
});

const getContactsForPortfolioQuery = (
  sort_prop,
  sort_reverse,
  pool_id,
  solicitor_id,
  stages,
  bounds,
  filters,
  center,
  radius
) => {
  const contact_query = CONTACT_QUERY(pool_id, solicitor_id, stages);
  let query = {};
  if (bounds) {
    let query_bounds = getQueryFromBox(bounds);
    if (radius && center) {
      const { lon, lat } = center;
      query_bounds = { radius, lat, lon };
    }
    query = _.extend({ must: [{ addresses: { instance: { location: query_bounds } } }] }, contact_query);
  } else {
    query = contact_query;
  }

  switch (sort_prop) {
    case 'stage_start_date': {
      const should = [
        {
          has_child: [
            {
              type: 'assignment',
              query: {
                must: [{ stage_start_date: { gte: 0 } }, { solicitor_contact_id: { match: solicitor_id } }],
                sort: [{ stage_start_date: { operation: 'max' } }],
              },
            },
          ],
        },
      ];
      query.should = should;
      query.sort = getDefaultSort('_score', sort_reverse);
      break;
    }
    case 'last_contact_date': {
      const should = [
        {
          has_child: [
            {
              type: 'assignment',
              query: {
                must: [{ solicitor_contact_id: { match: solicitor_id } }],
                sort: [{ most_recent_contact_date: { operation: 'max' } }],
              },
            },
          ],
        },
      ];
      query.should = should;
      query.sort = getDefaultSort('_score', sort_reverse);
      break;
    }
    case 'name_last': {
      query.sort = getNameSort(sort_reverse);
      break;
    }
    case 'proximity_sort': {
      query.sort = [MapStore.getProximitySort(sort_reverse)];
      break;
    }
    default: {
      if (sort_prop) {
        query.sort = getDefaultSort(sort_prop, sort_reverse);
      } else {
        query.sort = getNameSort(sort_reverse);
      }
      break;
    }
  }

  //TODO this doesn't support multiple filters currently.
  //The filters key value pairs need to be turned into their own
  //individual objects and then appended to the must part of the query.
  if (!_.isEmpty(filters)) {
    const firstChild = query.has_child[0] || {};
    if (!firstChild.query.must) {
      firstChild.query = { must: [] };
    }

    if (!_.isEmpty(filters.key_prospect)) {
      firstChild.query.must.push({ key_prospect: { match: true } });
    }
    if (!_.isEmpty(filters.assignment_title)) {
      const titles = _.pluck(filters.assignment_title, 'value');
      firstChild.query.must.push({ assignment_title: { in: titles } });
    }
    if (!_.isEmpty(filters.name)) {
      const contact_ids = _.pluck(filters.name, 'value');
      query.must = [{ id: { in: contact_ids } }];
    }
  }

  return query;
};

// TODO - this doesn't take into account any filters that have been applied in portfolio
// performance, so if you apply a filter than select all I dont think the results will be correct
const getSelectionQueryAll = (team_id, solicitor_id, stages, bounds) => {
  let query = CONTACT_QUERY(team_id, solicitor_id, stages);
  if (bounds) {
    const query_bounds = getQueryFromBox(bounds);
    query = _.extend({ must: [{ addresses: { instance: { location: query_bounds } } }] }, query);
  }
  return query;
};

const getSelectionQueryFromColumns = (team_id, solicitor_id, selected_columns) => {
  let query = BASE_QUERY(team_id, solicitor_id);

  // get all fully selected columns, and individual ids
  const cols = [];
  let ids = [];
  _.each(selected_columns, (col_obj = {}, name) => {
    if (col_obj.all) {
      cols.push(name);
    } else if (!_.isEmpty(col_obj.ids)) {
      ids = [...ids, ...col_obj.ids];
    }
  });

  const assignment_child = query.has_child[0] || {};
  const assignment_child_query = assignment_child.query || {};
  const assignment_child_query_must = assignment_child_query.must || [];
  if (!_.isEmpty(ids)) {
    assignment_child_query_must.push({ prospect_contact_id: { in: ids } });
  }
  if (!_.isEmpty(cols)) {
    assignment_child_query_must.push({ 'assignment_stage.untouched': { in: cols } });
  }

  return query;
};

const getSelectionQueryFromIds = (team_id, solicitor_id, contact_ids) => {
  let query = BASE_QUERY(team_id, solicitor_id);

  const assignment_child = query.has_child[0] || {};
  const assignment_child_query = assignment_child.query || {};
  const assignment_child_query_must = assignment_child_query.must || [];
  if (!_.isEmpty(contact_ids)) {
    assignment_child_query_must.push({ prospect_contact_id: { in: contact_ids } });
  }

  return query;
};

const getProposalStatsQueryBySolicitorIds = (contactIds = []) => {
  return {
    must_not: [{ deleted: { match: 'true' } }],
    must: [
      {
        'solicitors.contact_id': { in: contactIds },
      },
    ],
    stats: [
      {
        id: {
          bucket_by: 'solicitors.contact_id',
        },
      },
    ],
  };
};

export {
  CONTACT_QUERY,
  BASE_QUERY,
  TEAM_SOLICITORS_QUERY,
  getContactsForPortfolioQuery,
  getSelectionQueryAll,
  getSelectionQueryFromColumns,
  getSelectionQueryFromIds,
  getProposalStatsQueryBySolicitorIds,
  getContactIsSolicitor,
};
