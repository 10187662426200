import { formatDateFromTimestamp } from 'apps/journeys/utils/helpers/dates';
import PropTypes from 'prop-types';

const TripsAndMeetingsRowDetails = ({ contact = {} }) => {
  const { trips_interactions: { last_meeting_date, last_interaction_date } = {} } = contact;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <div>Meeting scheduled: {last_meeting_date ? formatDateFromTimestamp(last_meeting_date) : 'NA'}</div>
      <div className="giving-date">
        Last recorded interaction: {last_interaction_date ? formatDateFromTimestamp(last_interaction_date) : 'NA'}
      </div>
    </div>
  );
};

TripsAndMeetingsRowDetails.propTypes = {
  contact: PropTypes.object,
};

export default TripsAndMeetingsRowDetails;
