import { useState, useEffect, useRef } from 'react';
import _ from 'underscore';
import { SAVED_SEARCH_SEGMENT_FILTERS } from 'apps/filters/featured-segments-default';
import SegmentSource from 'apps/filters/sources/segment-source';
import PageHeader from 'apps/layout/components/page-header';
import useFeaturedLinks from 'apps/filters/hooks/use-featured-links';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import SavedSearchesfeatureLink from 'apps/segments/saved-search-feature-link';
import { useSegments } from 'apps/segments/hooks/use-segments';

import { Card, SearchInput, FlexTable, Icon, LinkButton, Loading, Button, useOpenClose } from '@evertrue/et-components';
import colors from 'base/colors';
import FoldersCard from 'apps/folder/components/detail/folders-card';
import SavedSearchesActions from '../saved-searches-actions';
import SavedSearchesColumns from '../saved-searches-columns';
import { useIdentity } from 'base/identity-resolver';
import DeleteModal from 'apps/search/components/delete-modal';

const SegmentsPageController = () => {
  const {
    segments,
    loading,
    deleteSegment,
    updateSegementsAfterDeletion,
    recentlyViewed,
    handleValueChange,
    preferences,
  } = useSegments();
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableSegments, setSelectableSegments] = useState([]);
  const featuredLinks = useFeaturedLinks(SAVED_SEARCH_SEGMENT_FILTERS);
  const { flagEnabled: hasFolders } = useFeatureFlag('folders');
  const folderCardRef = useRef();
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedSavedSearches, setSelectedSavedSearches] = useState([]);
  const { user } = useIdentity();
  const [isDeleteModalOpen, openModal, closeModal] = useOpenClose();
  const [segmentToDelete, setSegmentToDelete] = useState(null);

  /*
  Effect for search change
  */
  useEffect(() => {
    setSelectableSegments(_.filter(segments, (seg) => _.searchCompare(seg.name, searchText)));
  }, [searchText, segments]);

  let segmentsSorted = _.sortBy(selectableSegments, (segment) => segment.name.toLowerCase());
  if (!sortOrder) {
    segmentsSorted = segmentsSorted.reverse();
  }

  const renderSubtitle = () => {
    const segmentCount = segments ? segments.length : 0; // Handle both null and undefined

    return (
      <div>
        <div>{`${segmentCount} Saved Search${segmentCount === 1 ? '' : 'es'}`}</div>
        <div className="segments-page--empty-subtitle">
          Set up notifications below to be alerted whenever a new prospect meets the criteria of your search. You can
          adjust the frequency of these notifications at any time.
        </div>
      </div>
    );
  };

  const handleCheckAllSavedSearches = (isChecked) => {
    if (isChecked) {
      setSelectedSavedSearches([...segmentsSorted]);
    } else {
      setSelectedSavedSearches([]);
    }
  };

  const handleSavedSearchCheck = (savedSearch, isChecked) => {
    const updatedSavedSearches = isChecked
      ? [...selectedSavedSearches, savedSearch]
      : selectedSavedSearches.filter((l) => l.id !== savedSearch.id);

    setSelectedSavedSearches(updatedSavedSearches);
  };

  const handleSelectedSavedSearchesChange = (updatedSelectedSavedSearches) => {
    setSelectedSavedSearches(updatedSelectedSavedSearches);
  };

  const handleDelete = () => {
    let showRefreshToast = true;
    //If there are no Saved Searches, there is no Folders Card to refresh.
    if (selectableSegments.length === 1) {
      showRefreshToast = false;
    }
    deleteSegment(segmentToDelete, showRefreshToast, hasFolders);
    updateSegementsAfterDeletion([segmentToDelete]);
    setSelectedSavedSearches([]);
    closeModal();
  };

  const openDeleteModal = (segmentId) => {
    setSegmentToDelete(segmentId);
    openModal();
  };

  const columns = SavedSearchesColumns(
    'Delete',
    setSortOrder,
    sortOrder,
    user,
    selectedSavedSearches,
    handleSavedSearchCheck,
    handleCheckAllSavedSearches,
    selectableSegments,
    handleSelectedSavedSearchesChange,
    openDeleteModal,
    handleValueChange,
    true, //userIsOwner will always be true for Homepage of Saved Searches. We filter collaborative permissions inside the component.
    hasFolders,
    preferences
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageHeader
            title="Saved Searches"
            subtitle={renderSubtitle()}
            actions={
              hasFolders && (
                <SavedSearchesActions
                  hasFolders={hasFolders}
                  onCreateFolder={() => folderCardRef.current?.openCreateFolderModal()}
                  anySavedSearchSelected={selectedSavedSearches?.length > 0}
                  onAddtoFolder={() => folderCardRef.current?.openAddListSavedSearchesToFolderModal()}
                />
              )
            }
          />
          {segments.length === 0 && !loading ? (
            <Card className="segments-page--empty">
              <Icon icon="segment" size={3} style={{ color: colors.blue500 }} />
              <h2 className="segments-page--empty-title">Never miss a prospect with Saved Searches!</h2>
              <div className="segments-page--empty-subtitle">
                Here are some favorites from around the advancement organization to get you started. Save a search and
                sign up to receive email and push notifications whenever a new constituent meets your criteria.
              </div>
              <div className="segments-page--empty-featured">{featuredLinks}</div>
            </Card>
          ) : (
            <div className="segments-page-controller">
              <Card className="segments-page--table">
                <div className="segments-page--search">
                  <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
                </div>
                <FlexTable caption="segments" data={segmentsSorted} columns={columns} />
              </Card>
              <div className="segments-page--side">
                {hasFolders && (
                  <FoldersCard selectedLists={selectedSavedSearches} ref={folderCardRef} folderType="DYNAMIC" />
                )}
                <Card headingText="Recently Viewed" className="segments-page--recent" padding="16px">
                  <div className="segments-page--cardbody">
                    {recentlyViewed.map((segment) => (
                      <LinkButton
                        className="segments-page--recent-item"
                        key={segment.value}
                        onClick={() => SegmentSource.applySegment(segment.value)}
                        title={segment.label}
                      >
                        {segment.label}
                      </LinkButton>
                    ))}
                  </div>
                </Card>
                <Card
                  header={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="clickable-header"
                    >
                      <h1 className="title-subtitle-500-20">Suggested Searches</h1>
                      <Button type="simple">
                        <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />
                      </Button>
                    </div>
                  }
                  padding="16px"
                >
                  {isExpanded && <SavedSearchesfeatureLink featuredLinks={featuredLinks} />}
                </Card>
              </div>
            </div>
          )}
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={closeModal}
            handleDelete={handleDelete}
            title="Delete Saved Search?"
            body="Deleted saved searches cannot be recovered. Are you sure you want to delete this saved search?"
            list_id={segmentToDelete}
          />
        </>
      )}
    </>
  );
};

export default SegmentsPageController;
