import { useClarityProvider } from '../controllers/clarity-context';
import { Loading } from '@evertrue/et-components';
import ClarityList from './clarity-list';

const ClarityDashboard = () => {
  const { loading } = useClarityProvider();

  return <>{loading ? <Loading className="clarity--loading" /> : <ClarityList />}</>;
};

export default ClarityDashboard;
