import _ from 'underscore';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from '@evertrue/et-flux';
import { radiusToLatLng, boxToLatLng, boxToLatLngBounds } from 'apps/map/map-utils';
import GeolocationSource from 'apps/contact/sources/geolocation-source';
import GeolocationStore from 'apps/contact/stores/geolocation-store';
import MapLocationSearch from 'apps/map/components/map-location-search';

const DEFAULT_LATLNG = { lat: 42.3513666, lng: -71.0492654 };
const MIN_ZOOM = 2;
const MAX_ZOOM = 17;
const google = window.google;

const mapStateToProps = () => ({
  center: GeolocationStore.getLocation(),
  loading_geolocation: GeolocationStore.getLoading(),
  changed_center: GeolocationStore.hasChangedLocation(),
  geolocation: GeolocationStore.getGeolocation(),
});

class Map extends Component {
  static propTypes = {
    defaultZoom: PropTypes.number,
    defaultBounds: PropTypes.object,
    setBounds: PropTypes.object,
    setCenter: PropTypes.object,
    setLocationMarker: PropTypes.object,
    setZoom: PropTypes.number,
    map_options: PropTypes.object,
    loading: PropTypes.bool,
    controls: PropTypes.element,
    zoomOnBoundChange: PropTypes.bool,
    onMapClick: PropTypes.func,
    onMapChange: PropTypes.func,
    onMapInit: PropTypes.func,
    onMapUnmount: PropTypes.func,
    onLocationChange: PropTypes.func,
    children: PropTypes.any,
    // props from store
    center: PropTypes.object,
    changed_center: PropTypes.bool,
    geolocation: PropTypes.object,
    loading_geolocation: PropTypes.bool,
  };

  static defaultProps = {
    defaultZoom: 2,
    icon: '/images/map_single',
    size: { width: 32, height: 32 },
    zoomOnBoundChange: true,
    onLocationChange: _.noop,
    onMapInit: _.noop,
    onMapChange: _.noop,
    onMapClick: _.noop,
    onMapUnmount: _.noop,
  };

  state = {
    initialized: false,
  };

  componentDidMount() {
    const mapDiv = document.getElementById('map-content');
    this.map = new google.maps.Map(
      mapDiv,
      _.extend(
        {},
        {
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControlOptions: {
            mapTypeIds: [],
          },
          mapTypeControl: false,
          scaleControl: true,
          center: this.getInitialCenter(),
          zoom: this.props.defaultZoom,
          panControl: true,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP,
          },
          streetViewControl: false,
          fullscreenControl: false,
          scrollwheel: false,
          maxZoom: MAX_ZOOM,
          minZoom: MIN_ZOOM,
          styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }],
        },
        this.props.map_options
      )
    );

    if (this.props.defaultBounds) {
      this.handleFitBounds(this.props.defaultBounds);
    }

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      // Initialize Map
      this.props.onMapInit(this.map);
      this.setState({ initialized: true });

      // Bind Map Change Event, After Initialization otherwise it fires again
      // Include timeout because it fires frequently
      google.maps.event.addListener(this.map, 'idle', () => {
        clearTimeout(this.timeout);
        this.timeout = _.delay(() => {
          this.props.onMapChange(this.map.getZoom(), this.map.getBounds());
        }, 200);
      });

      // Adding the title to the map iframe will supposedly allow us to pass some map
      // accessibility tests, but google maps puts an aria-hidden: true on it, so the
      // screen reader wont even read it
      const iframe = document.getElementsByTagName('iframe')[1];
      if (iframe) {
        iframe.title = 'This is a map of the constituents addresses';
      }
    });

    // Bind Map Click Event
    google.maps.event.addListener(this.map, 'click', () => {
      this.props.onMapClick(this.map);
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    google.maps.event.clearListeners(this.map);
    this.props.onMapUnmount(this.map);
  }

  componentDidUpdate(prevProps) {
    if (this.state.initialized) {
      if (this.props.center && (!_.isEqual(prevProps.center, this.props.center) || this.props.changed_center)) {
        this.map.setCenter(this.props.center);
        this.map.setZoom(11);
        GeolocationSource.changedLocation(false);
        // when user sets map to current location
        if (
          (this.props.geolocation != null ? this.props.geolocation.lat : undefined) === this.props.center.lat &&
          (this.props.geolocation != null ? this.props.geolocation.lng : undefined) === this.props.center.lng
        ) {
          this.createMarkerForSetLocation(this.props.center);
        }
      }
    }
    if (this.props.setBounds && !_.isEqual(this.props.setBounds, prevProps.setBounds)) {
      this.handleFitBounds(this.props.setBounds);
    }
    if (this.props.setCenter && !_.isEqual(this.props.setCenter, prevProps.setCenter)) {
      this.map.setCenter(this.props.setCenter);
    }
    if (this.props.setZoom && this.props.setZoom !== prevProps.setZoom) {
      this.map.setZoom(this.props.setZoom);
    }
    if (this.props.setLocationMarker && !_.isEqual(this.props.setLocationMarker, prevProps.setLocationMarker)) {
      this.createMarkerForSetLocation(this.props.setLocationMarker);
    }
  }

  getInitialCenter = () => {
    if (this.props.defaultBounds != null ? this.props.defaultBounds.radius : undefined) {
      return radiusToLatLng(this.props.defaultBounds);
    } else if (this.props.defaultBounds) {
      return boxToLatLng(this.props.defaultBounds);
    } else {
      return this.props.center || DEFAULT_LATLNG;
    }
  };

  createMarkerForSetLocation = (position) => {
    if (this.marker) {
      this.marker.setMap(null); // removes marker
    }
    const markerOptions = {
      map: this.map,
      position,
      icon: {
        url: '/images/map_pin_circle.png',
        scaledSize: new google.maps.Size(36, 36),
      },
    };
    this.marker = new google.maps.Marker(markerOptions);
  };

  handleFitBounds = (bounds) => {
    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      const current_zoom = this.map.getZoom();
      if (current_zoom !== MIN_ZOOM && this.props.zoomOnBoundChange) {
        this.map.setZoom(current_zoom + 1);
      }
    });
    this.map.fitBounds(boxToLatLngBounds(bounds));
  };

  handleLocationChange = (latLng) => {
    this.createMarkerForSetLocation(latLng);
    GeolocationSource.setLocation(latLng || this.props.center);
    EverTrue.track.set('map_action', { type: 'map_change_location' });
    this.props.onLocationChange();
  };

  handleGeolocation = () => {
    GeolocationSource.refetch();
    EverTrue.track.set('map_action', { type: 'map_geolocation' });
  };

  render() {
    return (
      <div className="map-container">
        <div className="map-container--controls">
          <MapLocationSearch onSelect={this.handleLocationChange} onGeolocation={this.handleGeolocation} />
        </div>
        {this.props.controls}
        <div className="map-container--map">
          <div
            className="map"
            tabIndex="0"
            title="This image contains a map of the selected constituents' addresses. Zoom in or apply filters to further narrow down your search."
          >
            <div id="map-content" style={{ width: '100%', height: '100%' }} aria-hidden="true" />
            {_.map(_.compact(_.flatten(_.makeArray(this.props.children))), (child, index) => {
              if (child) {
                return cloneElement(child, { map: this.map, key: index });
              }
              return null;
            })}
            {!this.props.loading && this.props.loading_geolocation && (
              <div className="map-container--feedback">
                <i className="fa fa-fw fa-spinner fa-pulse" />
                Getting your current location...
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(Map, [GeolocationStore], mapStateToProps);
