export const createOutreachPayload = (outreachEmail, outreachType, starred) => {
    const messagePayload = {
      to: outreachEmail.email_recipients?.map(({ email_address }) => ({ email: email_address })),
      cc: outreachEmail.cc?.map(email => ({ email })),
      bcc: outreachEmail.bcc?.map(email => ({ email })),
      subject: outreachEmail.subject,
      body: outreachEmail.body,
      task_id: outreachEmail.task_id,
      attachment_requests: outreachEmail.files?.map(({ url, isInline, contentId }) => ({
        presigned_url: url,
        is_inline: !!isInline,
        content_id: contentId || '',
        content_disposition: isInline ? 'inline' : 'attachment',
      })),
      starred,
    };
  
    switch (outreachType) {
      case 'Draft':
        return { message: messagePayload, email_template_id: outreachEmail.email_template_id };
      case 'Scheduled':
        return {
          message: {
            ...messagePayload,
            send_at: outreachEmail.scheduled_at,
            nylas_schedule_id: outreachEmail.nylas_schedule_id || null,
            nylas_thread_id: outreachEmail.nylas_thread_id || null,
            nylas_message_id: outreachEmail.nylas_message_id || null,
            nylas_draft_id: outreachEmail.nylas_draft_id || null,
          },
          email_template_id: outreachEmail.email_template_id,
        };
      case 'Sent':
        return {
          creator_user_id: outreachEmail.creator_user_id,
          unread: outreachEmail.unread,
          starred,
          nylas_message_id: outreachEmail.nylas_message_id,
        };
      default:
        throw new Error(`Unknown outreach type: ${outreachType}`);
    }
  };
  