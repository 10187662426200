import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import FormField from 'components/forms/form-field';
import { useGlobalOutreachProvider } from '../contexts/global-outreach-context';

const BulkDeleteEmailModal = ({ onClose = () => {}, isOpen, selected = [], setSelected = () => {}, outreachType }) => {
  const { deleteDrafts, deleteScheduledSends } = useGlobalOutreachProvider();

  const handleBulkDelete = async () => {
    if (outreachType === 'Drafts') {
      await deleteDrafts(selected);
      setSelected([]);
      onClose();
    } else if (outreachType === 'Scheduled') {
      await deleteScheduledSends(selected);
      setSelected([]);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={outreachType === 'Drafts' ? 'Delete Drafts' : 'Delete Scheduled Emails'}
        closeModal={onClose}
        submitLabel="Confirm"
        onSubmit={handleBulkDelete}
        disableSubmit={false}
      />
      <ModalBody>
        <FormField label={'Selected Emails'} boldLabel={true} inlineLabel={false}>
          <span>{`Are you sure you want to delete the selected ${
            outreachType === 'Drafts' ? 'email drafts' : 'scheduled emails'
          } ?`}</span>
        </FormField>
      </ModalBody>
    </Modal>
  );
};
BulkDeleteEmailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func,
  outreachType: PropTypes.string,
};

export default BulkDeleteEmailModal;
