import EverTrue from 'app';
import { createStore } from '@evertrue/et-flux';
import LoginSource from 'apps/login/sources/login-source';
import SessionSource from 'base/session/session-source';

export default createStore('LoginStore', {
  getInitialState() {
    return {
      invite_token: undefined,
      temp_linkedin_access_token: undefined,
      error: '',
    };
  },

  registerActions() {
    this.on(LoginSource.actions.fetchedAccessToken, this.respondToFetchedAccessToken);
    this.on(LoginSource.actions.activateToken, this.respondToAssociate);

    this.on(LoginSource.actions.setInviteToken, this.respondToSetInvite);
    this.on(LoginSource.actions.clearedInviteToken, this.respondToClearedInvite);

    this.on(LoginSource.actions.resetToken, this.respondToTokenReset);

    this.on(LoginSource.actions.error, (error) => this.setState({ error }));
  },

  respondToFetchedAccessToken(token) {
    EverTrue.auth.lids_token = token; // required for session
    this.setState({ temp_linkedin_access_token: token });
    LoginSource.activate(token);
  },

  respondToAssociate() {
    // case where user has to refresh li session
    // in an SSO org, shouldn't create new session
    if (EverTrue.store.org.get('sso_method') === 'saml') {
      EverTrue.Navigator('/');
    } else {
      this.createSession();
    }
  },

  respondToSetInvite(token) {
    this.setState({ invite_token: token });
    EverTrue.store.push('invite_token', token, { cache: true });
  },

  respondToClearedInvite() {
    this.setState({ invite_token: undefined });
  },

  respondToTokenReset() {
    this.setState({ temp_linkedin_access_token: undefined });
  },

  createSession(user_id) {
    const lids_token = this.getState('temp_linkedin_access_token');
    user_id ??= EverTrue.store.user?.get('id');
    SessionSource.createSessionWithLidsToken(user_id, lids_token);
  },

  api: {
    getInviteToken() {
      return this.getState('invite_token');
    },

    getError() {
      return this.getState('error');
    },
  },
});
