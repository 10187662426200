import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@evertrue/et-components';
import TagInput from 'components/controls/tag-input';
import UserCard from 'apps/users/components/user-card';
import CollaboratorSource from 'apps/lists/sources/collaborator-source';
import EverTrue from 'app';

const CollaboratorInvitation = ({
  listId,
  tags,
  setTags,
  shareQuery,
  setShareQuery,
  notifyEnabled,
  setNotifyEnabled,
  filteredUsersToShare,
}) => {
  const noteRef = useRef(null);

  const handleShare = (e) => {
    e.preventDefault();
    if (tags.length === 0) return;

    const userIds = tags.map((tag) => tag.id);
    CollaboratorSource.share(listId, {
      user_ids: userIds,
      message: noteRef.current?.value,
      skip_notification: !notifyEnabled,
    });

    EverTrue.track.set('list_action', { type: 'invite_collabs' });
    if (noteRef.current) noteRef.current.value = '';
    setTags([]);
  };

  return (
    <div className="share-settings--invite loading-container">
      <TagInput
        placeholder="Add Collaborators"
        selected={tags}
        options={filteredUsersToShare}
        onType={(query) => setShareQuery(query.trim())}
        onTag={(newTags) => {
          setTags(newTags);
          setShareQuery(undefined);
        }}
        itemContent={(user) => <UserCard user={user} />}
      />
      <div className="text-label">
        Collaborators can add or remove constituents from your list, and invite or remove other collaborators.
      </div>
      <Checkbox
        className="share-settings--notify"
        defaultChecked={notifyEnabled}
        onChange={() => setNotifyEnabled(!notifyEnabled)}
      >
        Notify people via email
      </Checkbox>
      {notifyEnabled && <textarea className="share-settings--note" placeholder="Add a note (optional)" ref={noteRef} />}
      <div className="clearfix">
        <Button disabled={tags.length === 0} onClick={handleShare} className="pull-right">
          Share
        </Button>
      </div>
    </div>
  );
};

CollaboratorInvitation.propTypes = {
  listId: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired,
  shareQuery: PropTypes.string,
  setShareQuery: PropTypes.func.isRequired,
  notifyEnabled: PropTypes.bool.isRequired,
  setNotifyEnabled: PropTypes.func.isRequired,
  filteredUsersToShare: PropTypes.array.isRequired,
};

export default CollaboratorInvitation;
