module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   SortSelector = require("components/controls/sort-selector/sort-selector")
   TopFieldsStore = require("apps/profile/stores/top-fields-store")
   TopFieldsSource = require("apps/profile/sources/top-fields-source").default


   createComponent "TopFieldsSelector",
      registerStores: ->
         @on TopFieldsStore, ->
            field_options: TopFieldsStore.getTopFieldOptions()
            selected_fields: TopFieldsStore.getSelectedTopFields()

      handleSave: (fields) ->
         field_keys = _.pluck(fields, "value")
         TopFieldsSource.updateTopFields(field_keys)

         EverTrue.track.set "contact_action",
            type: "profile_top_field_change"

         @handleClose()

      handleClose: ->
         @props.requestHide?()

      render: ->
         div className: "top-fields-selector",
            SortSelector
               className: "top-fields-selector--modal"
               options: @state.field_options
               value: @state.selected_fields
               header: "Customize Top Fields"
               action: "Save Top Fields"
               grouped: ["available"]
               maxActive: 5
               activeInstructionContent: div null, "Customize up to five top fields. Drag and drop to prioritize the order."
               onSave: @handleSave
               onCancel: @handleClose
