import { useCallback } from "react";
import { useFeatureFlag } from "entities/helpers/use-feature-flag";
import { getGqlContactName } from "apps/journeys/utils/helpers/utils";
import { useGlobalOutreachProvider } from "../contexts/global-outreach-context";
import useAiForDialer from "./use-ai-for-dialer";

/**
 * This hook exists for one purpose:
 * gather up the information from an outreach attempt (phone call or email) and prepare it to be put it into the Interaction Form
 *
 * the quickInteractionData is set in the GlobalOutreachProvider at the end of an outreach attempt. So when a call ends or an email is sent, we store the information about that call or email in the OutreachContext
 * This is an object with a number of optional values. based on what data is provided, this hook generates an object in the payload shape that can be sent to the InteractionForm.
 *
 * Possible values that will exist on th quickInteractionData:
 * -- contact: a contact object (either from the Contact API or GQL)
 * -- type: a string indicating which type of outreach ("dialer" or "emailer")
 * -- interaction: if, at the point of outreach, we just have an interaction already made, just return it.
 * -- task: can supply a task object to use in AI summarization of stuff
 * -- journey: can supply a journey object to use in AI summarization of stuff
 * (Add to this list if you add more stuff)
 */
const DATE_OCCURRED = new Date().valueOf();
const useQuickInteractionBuilder = () => {
  const { flagEnabled: hasAI } = useFeatureFlag("aiGeneral");
  const { activeCallNotes, quickInteractionData } = useGlobalOutreachProvider();

  const { contact = {}, type = "", interaction = {}, task = {}, journey = {} } = quickInteractionData;

  // If the user is making a phone call, has AI Enabled, AND has the requisite information for the AI Prompt, use it:
  const phoneCallWithAi = hasAI && type === "dialer" && task?.title && journey?.name && !!activeCallNotes;

  const { aiGeneratedDescription, isStreaming } = useAiForDialer(phoneCallWithAi, {
    ...quickInteractionData,
    activeCallNotes,
  });

  // Function to generate the name of the Interaction
  const getSummaryWithoutAi = useCallback(() => {
    const contactName = getGqlContactName(contact) || contact?.name;

    // if there is already an interaction provided, just use that.
    if (interaction?.summary) return interaction.summary;
    else if (task?.title) return task.title;
    else if (type === "dialer") {
      return contactName ? `Phone Call with ${contactName}` : "Phone Call";
    } else if (type === "emailer") {
      return contactName ? `Sent Email to ${contactName}` : "Email Sent";
    } else {
      return "Successful Outreach";
    }
  }, [contact, interaction, task, type]);

  // Function to generate the description of the interaction
  // will be called if "phoneCallWithAI" is false
  const getTextWithoutAi = useCallback(() => {
    if (interaction?.text) return interaction?.text;
    else if (type === "dialer") {
      return activeCallNotes && activeCallNotes.length
        ? `Notes from the phone call: \n${activeCallNotes}`
        : "Phone call made.";
    } else return "Successful outreach.";
  }, [activeCallNotes, interaction, type]);

  return {
    isStreaming,
    summary: getSummaryWithoutAi(),
    text: phoneCallWithAi ? aiGeneratedDescription : getTextWithoutAi(),
    date_occurred: DATE_OCCURRED,
  };
};

export default useQuickInteractionBuilder;
