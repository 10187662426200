import { PropTypes } from 'base/react-libs';
import { useState, useEffect } from 'react';
import checkIcon from 'images/check.svg';
import TaskCount from 'apps/journeys/components/task-count/task-count';
import { consolidateCadenceStepTaskCounts } from 'apps/home/utils/helpers';

const JourneyTaskCount = ({ todayJourneyCount = {}, loadingJourneysTodayCount }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [title, setTitle] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    const { COMPLETED, ...rest } = todayJourneyCount;
    const { TOTAL } = todayJourneyCount;

    let newTitle = '';
    if (TOTAL === 0 && COMPLETED === 0) {
      newTitle = 'You have no cadence steps due';
      setShowIcon(false);
    } else if (TOTAL > 0) {
      newTitle = null;
      setShowIcon(false);
    } else {
      newTitle = 'You completed all of your cadence steps!';
      setShowIcon(true);
    }
    setTitle(newTitle);
    setData(consolidateCadenceStepTaskCounts(rest));
  }, [todayJourneyCount]);

  return (
    <TaskCount
      title="Cadence Steps"
      subTitle={title}
      showIcon={showIcon}
      icon={checkIcon}
      data={data}
      loadingCounts={loadingJourneysTodayCount}
    />
  );
};

JourneyTaskCount.propTypes = {
  todayJourneyCount: PropTypes.object,
  loadingJourneysTodayCount: PropTypes.bool,
};

export default JourneyTaskCount;
