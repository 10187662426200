import { useEffect } from 'react';
import { useFluxStore } from '@evertrue/et-flux';
import GeolocationStore from 'apps/contact/stores/geolocation-store';
import GeolocationSource from 'apps/contact/sources/geolocation-source';
import Icon from 'components/elements/icon';

const GeolocationMapState = () => ({
  show_error: GeolocationStore.shouldShowError(),
});

const GeolocationError = () => {
  const { show_error } = useFluxStore(GeolocationStore, GeolocationMapState);

  const handleHide = () => {
    GeolocationSource.hideError();
  };

  useEffect(() => {
    if (show_error) {
      const timer = setTimeout(() => {
        GeolocationSource.hideError();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [show_error]);

  if (!show_error) {
    return null;
  }

  return (
    <div className="geolocation-error">
      <div className="geolocation-error--header">
        Location Permission Denied
        <Icon className="geolocation-error--close" icon="close" size={1} onClick={handleHide} />
      </div>

      <div className="geolocation-error--body">
        <p>
          EverTrue requires browser location in order to set the map to your current location. Permission is managed in
          your browser's settings.
        </p>

        <div>If you don't want to allow browser location, use the search box to find locations by name.</div>
      </div>
    </div>
  );
};

export default GeolocationError;
