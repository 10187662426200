import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

const GiftCard = ({ gift }) => {
  return (
    <div className="gift-card">
      {gift?.occurred_at && (
        <span className="gift-card--label fs-exclude-data">{moment(gift?.occurred_at).format('MMM DD, YYYY')}</span>
      )}

      <strong className="gift-card--amount fs-exclude-data">{numeral(gift?.amount).format('$0,0.00')}</strong>

      <div className="gift-card--designations">
        {gift?.labels?.map((label) => {
          if (label?.value?.trim()) {
            return (
              <span className="gift-card--tag fs-exclude-data" key={label.value}>
                {label.value}
              </span>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

GiftCard.propTypes = {
  gift: PropTypes.object,
};

export default GiftCard;
