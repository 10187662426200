import { useFluxStore } from '@evertrue/et-flux';
import { useEffect } from 'react';
import $ from 'jquery';
import _ from 'underscore';
import Icon from 'components/elements/icon';
import { Button, Checkbox } from '@evertrue/et-components';
import Popover from 'components/overlays/popover';
import FacetStore from 'apps/filters/stores/facet-store';
import FilterConfigStore from 'apps/filters/stores/filter-config-store';
import FilterStore from 'apps/filters/stores/filter-store';
import PropTypes from 'prop-types';

const MapAddressFilter = ({
  onApply = () => {},
  onSelectType = () => {},
  show_filter_text = '',
  selected_types = '',
}) => {
  const { addressTypes } = useFluxStore(FacetStore, () => ({
    addressTypes: FacetStore.getByKey('addresses.type'),
  }));

  const { type_filters, filters } = useFluxStore(FilterStore, () => ({
    type_filters: FilterStore.getActiveFilterByKey('address_type'),
    filters: FilterStore.getActiveFilters(),
  }));

  const getSelectedAddressTypes = () => {
    return _.map(type_filters, (filter) => filter.value);
  };

  useEffect(() => {
    if (addressTypes && !_.findWhere(addressTypes, { value: 'Primary' }))
      addressTypes.push({ value: 'Primary', count: 0 });
  }, [addressTypes]);

  const handleApply = () => {
    if (selected_types) {
      const config = FilterConfigStore.getConfigByKey('address_type');
      let newFilters = _.map(filters, (filter) => _.clone(filter));
      const active_filter = _.findWhere(newFilters, { filter_id: config.filter_id });

      if (active_filter) {
        if (_.isEmpty(selected_types)) {
          newFilters = _.reject(newFilters, (filter) => filter.filter_id === active_filter.filter_id);
        } else {
          active_filter.value = _.map(selected_types, (val) => ({
            label: val,
            value: val,
          }));
        }
      } else if (!_.isEmpty(selected_types)) {
        newFilters.push({
          filter_row_id: newFilters.length + 1,
          filter_id: config.filter_id,
          value: _.map(selected_types, (val) => ({
            label: val,
            value: val,
          })),
        });
      }

      onApply?.(newFilters);
    }

    handleCancel();
  };

  const handleCancel = () => {
    $('body').trigger('click');
  };

  const handleSelectType = (type, isChecked) => {
    let types = selected_types || getSelectedAddressTypes();
    if (isChecked) {
      types.push(type);
    } else {
      types = _.without(types, type);
    }
    onSelectType?.(types);
  };

  const selectedTypes = selected_types || getSelectedAddressTypes();

  if (_.isEmpty(addressTypes)) {
    return null;
  }

  return (
    <Popover
      className="map-address-filter"
      options={{ arrowColor: '#3F565E', arrowBorderColor: '#3F565E' }}
      trigger={
        <div>
          <Icon icon="filter" size={1} className={selectedTypes.length > 0 ? 'icon-highlight' : ''} />
          {!!show_filter_text && selectedTypes.length > 0 && (
            <span className="map-address-filter--text">
              {`${selectedTypes.length} Active `}
              {selectedTypes.length > 1 ? 'Filters' : 'Filter'}
            </span>
          )}
        </div>
      }
      render={({ close }) => (
        <div className="map-address-filter--content">
          <div className="map-address-filter--header">Filter by Address Type</div>

          <div className="map-address-filter--body">
            {_.map(addressTypes, (type, i) => {
              if (!_.isEmpty(type.value?.trim()) && type.value != 'Primary') {
                return (
                  <Checkbox
                    key={i}
                    checked={_.contains(selectedTypes, type.value)}
                    onChange={(isChecked) => handleSelectType(type.value, isChecked)}
                  >
                    {type.value}
                  </Checkbox>
                );
              }
              return null;
            })}
          </div>

          <div className="map-address-filter--header">Filter by Primary Designation</div>

          <div className="map-address-filter--body">
            <Checkbox
              key="primary-checkbox"
              checked={_.contains(selected_types, 'Primary')}
              onChange={(isChecked) => handleSelectType('Primary', isChecked)}
            >
              Primary (from CRM)
            </Checkbox>
          </div>

          <div className="map-address-filter--footer pull-right clearfix">
            <Button type="simple" title="Cancel filter" onClick={close}>
              Cancel
            </Button>
            <Button className="map-address-filter--apply" title="Apply filter" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </div>
      )}
    />
  );
};

MapAddressFilter.propTypes = {
  onApply: PropTypes.func,
  onSelectType: PropTypes.func,
  show_filter_text: PropTypes.bool,
  selected_types: PropTypes.array,
};

export default MapAddressFilter;
