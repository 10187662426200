import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
// import { useState } from "react";
import { Modal, ModalHeader, ModalBody } from '@evertrue/et-components';
import VideoPlayer from '../thankview-recorder-base';

const VideoPreviewModal = ({ videoUrl, isOpen, onClose, videoName, captionsUrl }) => {
  // const [isDownloading, setIsDownloading] = useState(false);

  if (!isOpen) return null;
  // Add back when CORS issue is fixed
  // const handleDownload = async () => {
  //   setIsDownloading(true);
  //   // can only open in new tab, cause download is not available via cors.
  //   try {
  //     window.open(videoUrl, "_blank");
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };
  return createPortal(
    <Modal style={{ alignSelf: 'center' }} isOpen={isOpen} closeOnOutsideClick={true}>
      <ModalHeader title="Video Preview" closeModal={onClose} />
      <ModalBody>
        <VideoPlayer src={videoUrl} controls width="100%" captionsUrl={captionsUrl} />
        {/* <div style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleDownload} disabled={isDownloading}>
            {isDownloading ? "Downloading..." : "Download Video"}
          </Button>
        </div> */}
      </ModalBody>
    </Modal>,
    document.body
  );
};

VideoPreviewModal.propTypes = {
  videoUrl: PropTypes.string,
  isOpen: PropTypes.string,
  onClose: PropTypes.func,
  videoName: PropTypes.string,
  captionsUrl: PropTypes.string,
};

export default VideoPreviewModal;
