module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   GeolocationSource = require("apps/contact/sources/geolocation-source")
   MapSource = require("apps/map/sources/map-source").default


   createStore "GeolocationStore",
      getInitialState: ->
         geolocation: EverTrue.store?.geolocation
         location: undefined
         error: false
         loading: false
         should_clear_location: false
         changed_location: false
         show_geolocation_error: false

      firstListenerDidMount: ->
         GeolocationSource.init()

      registerActions: ->
         @on GeolocationSource.actions.setLocation, @respondToSetLocation
         @on GeolocationSource.actions.fetchedGeolocation, @respondToGeoLocation
         @on GeolocationSource.actions.loadingGeolocation, @respondToLoading
         @on GeolocationSource.actions.errorGeolocation, @respondToError
         @on GeolocationSource.actions.clearGeolocation, @respondToClear

         @on GeolocationSource.actions.changedLocation, (has_changed) =>
            @setState {changed_location: has_changed}

         @on GeolocationSource.actions.hideGeolocationError, ->
            @setState {show_geolocation_error: false}

      respondToSetLocation: (location) ->
         @setState
            location: location
            loading: false
            changed_location: true

      respondToGeoLocation: (location) ->
         MapSource.zoom(11)
         @setState
            location: if @getState("should_clear_location") then undefined else @getState("location")
            should_clear_location: false
            geolocation: location
            loading: false

      respondToLoading: (isLoading) ->
         @setState {loading: isLoading}

      respondToError: ->
         @setState
            error: true
            loading: false
            should_clear_location: false
            show_geolocation_error: true

      respondToClear: ->
         # The next fetched geolocation should clear out current location
         # if location is not set we dont want to clear the map
         if @getState("location")
            @setState
               should_clear_location: true
               geolocation: undefined

      api:
         getLocation: ->
            @getState("location") || @getState("geolocation")

         getGeolocation: ->
            @getState("geolocation")

         getLoading: ->
            @getState("loading")

         getError: ->
            @getState("error")

         hasChangedLocation: ->
            @getState("changed_location")

         # Support for old UI, we can't store this in getInitialState
         # because it breaks shouldPrompt, need it in new UI for default sort
         getLocationOrDefault: ->
            @getLocation() || {lat: 42.3510249, lng: -71.04954409999999}

         shouldPrompt: ->
            !@getLoading() && !@getError() && !@getLocation()

         shouldShowError: ->
            @getState("show_geolocation_error")
