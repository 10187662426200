import { useState } from "react";
import EverTrue from "app";
import _ from "underscore";
import get from "lodash.get";
import PropTypes from "prop-types";
import { getAddresses } from "mixins/address-mixin";
import { Loading } from "@evertrue/et-components";
import ProfileCard from "../components/profile-card";
import BioEnrichment from "apps/profile/components/enrichment/bio-enrichment";
import CareerEnrichment from "apps/profile/components/enrichment/career-enrichment";
import SocialEnrichment from "../components/enrichment/social-enrichment";
import PhilanthropicInterest from "../components/enrichment/interests/philanthropic-interests";
import ContactInfoEnrichment from "../components/enrichment/interests/contact-info-enrichment";
import AddressSlideshow from "../components/enrichment/address-slideshow";
import WealthIndicators from "../components/enrichment/wealth-indicators";

import { gqlCall } from "entities/helpers/gqlCall";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import DonorPotentialScoreInfo from "../components/donor-potential-score-info";

const gql = String.raw;

const QUERY = gql`
  query ($id: BigInt!) {
    contact(id: $id) {
      enrichment {
        enrichment_attributes(include_rejected: true) {
          id
          name_full
          rejected
          created_at
          updated_at
          confidence_score
          source
          weight
        }
        addresses {
          address_1
          address_2
          address_3
          city
          state_prov
          country
          postal_code
          type
          source
        }
        phones {
          type
          phone
        }
        emails {
          type
          email
        }
        employments(include_rejected: true) {
          id
          status
          start_day
          start_month
          start_year
          end_day
          end_month
          end_year
          title
          company_name
          industry
          created_at
          updated_at
          rejected
          source
          confidence_score
          revenue_range
          size_range
          category
          weight
        }
        employment_events {
          id
          type
          confidence_score
          source
          recorded_at
          updated_at
        }
        contact_interests(include_rejected: true) {
          id
          interest_id
          interest_name
          confidence_score
          type
          updated_at
          rejected
        }
        social_profiles(include_rejected: true) {
          id
          type
          username
          bio
          url
          image_url
          followers
          following
          created_at
          updated_at
          confidence_score
          source
          rejected
          weight
        }
        wealth_attributes(include_rejected: true) {
          id
          net_worth
          net_worth_last_updated
          updated_at
          source
          rejected
        }
        wealth_indicators(include_rejected: true) {
          id
          type
          updated_at
          rejected
        }
        wealth_physical_assets(include_rejected: true) {
          id
          type
          updated_at
          rejected
        }
        contact_locations(include_rejected: true) {
          id
          city
          state_code
          metro_area
          country
          created_at
          updated_at
          rejected
          confidence_score
          source
          weight
        }
        wealth_life_events {
          id
          type
          updated_at
        }
      }
      et_score {
        dds_score
      }
    }
  }
`;

// This is for normalizing the FullContact data based on our contacts addresses model
// So I don't need to re-write a bunch of helper logic / lower level components
const formatAddresses = (addresses, contact) => {
  const contactAddresses = getAddresses(contact);
  const fullContactAddresses = addresses.map((address) => {
    return _.mapObject(address, (property, key) => {
      if (key === "postal_code") {
        key = "zip_code";
      } else if (key === "state_prov") {
        key = "state";
      }
      return { name: key, value: property };
    });
  });

  return contactAddresses.concat(fullContactAddresses);
};

const requestWithTracking = (id) => {
  return gqlCall(QUERY, { id: id }).then((results) => {
    const enrichmentData = get(results, "data.contact.enrichment");
    const availableDataKeys = _.compactMap(_.omit(enrichmentData, "id"), (value, key) => {
      if (!_.isEmpty(value)) {
        return key;
      }
    });
    EverTrue.track.set("enrichment_action", {
      type: "view",
      contact_id: id,
      matched: !_.isEmpty(availableDataKeys),
      available_data: availableDataKeys,
    });
    return results;
  });
};

const ProfileEnrichmentController = ({ contact = {} }) => {
  const [shouldFetch, setShouldFetch] = useState(true);

  const [data, fetching] = usePromiseResolver(() => requestWithTracking(contact.id), [contact.id, shouldFetch]);

  const enrichmentData = get(data, "data.contact.enrichment");

  const philInterests =
    enrichmentData && enrichmentData.contact_interests
      ? _.filter(enrichmentData.contact_interests, (interest) => interest.type === "philanthropic")
      : null;

  return (
    <div className="profile--enrichment">
      {fetching || !enrichmentData ? (
        <Loading />
      ) : (
        <>
          <div className="profile--enrichment-left">
            <ProfileCard className="profile--enrichment-bio">
              <BioEnrichment
                contact={contact}
                enrichmentData={enrichmentData}
                onDelete={() => setShouldFetch(!shouldFetch)}
              />
            </ProfileCard>
            <ProfileCard className="profile--enrichment-career">
              <CareerEnrichment
                contact={contact}
                careers={enrichmentData.employments}
                onDelete={() => setShouldFetch(!shouldFetch)}
              />
            </ProfileCard>
            <ProfileCard>
              <SocialEnrichment
                contact={contact}
                profiles={enrichmentData.social_profiles}
                onDelete={() => setShouldFetch(!shouldFetch)}
              />
            </ProfileCard>
            {enrichmentData.phones.length || enrichmentData.emails.length ? (
              <ProfileCard>
                <ContactInfoEnrichment phones={enrichmentData.phones} emails={data.emails} />
              </ProfileCard>
            ) : null}
          </div>

          <div className="profile--enrichment-right">
            <ProfileCard>
              <WealthIndicators
                netWorths={enrichmentData.wealth_attributes}
                physicalAssets={enrichmentData.wealth_physical_assets}
                wealthIndicators={enrichmentData.wealth_indicators}
                contactAttributes={{ contactId: contact.id, contactName: contact.name }}
                onDelete={() => setShouldFetch(!shouldFetch)}
              />
            </ProfileCard>
            <ProfileCard>
              <DonorPotentialScoreInfo id={contact.id} />
            </ProfileCard>
            <ProfileCard className="profile--enrichment--addresses">
              <AddressSlideshow addresses={formatAddresses(enrichmentData.addresses, contact)} />
            </ProfileCard>
            <ProfileCard>
              <PhilanthropicInterest
                philInterests={philInterests}
                contactAttributes={{ contactId: contact.id, contactName: contact.name }}
                onDelete={() => setShouldFetch(!shouldFetch)}
              />
            </ProfileCard>
          </div>
        </>
      )}
    </div>
  );
};

ProfileEnrichmentController.propTypes = {
  contact: PropTypes.object,
};

export default ProfileEnrichmentController;
