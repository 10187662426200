module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   InteractionSearchSource = require("apps/interactions/sources/interaction-search-source")
   FilterStore = require("apps/filters/stores/filter-store")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   SortUtils = require("mixins/sort-utils")
   QueryContext = require("entities/search/query-parts/query-context")
   ParentDoc = require("entities/search/query-parts/parent-doc-query")
   Query = require("entities/search/query-parts/query")
   PaginationUtils = require("mixins/pagination-utils")

   LIMIT = 50


   createStore "InteractionSearchStore",
      getInitialState: ->
         interactions: {}
         loading: false
         page: 1
         sort:
            prop: "date_occurred"
            reverse: true

      registerActions: ->
         @on InteractionSearchSource.actions.query, @respondToQuery
         @on InteractionSearchSource.actions.loading, @respondToLoading
         @on InteractionSearchSource.actions.fetchedInteractions, @respondToFetched
         @on InteractionSearchSource.actions.sort, @respondToSort
         @on InteractionSearchSource.actions.paginate, @respondToPaginate

         @on CoreSearchSource.actions.query, (type) ->
            if type == "interactions" then @respondToQuery()

      respondToQuery: ->
         @setState {page: 1, query_type: "filter"}
         @queryInteractions()

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetched: (interactions) ->
         cached = @getState("interactions")
         @setState
            interactions: PaginationUtils.mergePagedResults(cached, interactions)

      respondToSort: (prop, reverse) ->
         @setState
            query_type: "sort"
            sort: {prop: prop, reverse: reverse}
            page: 1
         @queryInteractions()

      respondToPaginate: ->
         @setState
            page: @getState("page") + 1
            query_type: "page"
         @queryInteractions()

      queryInteractions: ->
         unless FilterStore.getLoading()
            sort = @getState("sort")
            filters = FilterStore.getActiveFilters()
            operator = FilterStore.getOperator()
            if !_.isEmpty(filters)
               # This currently works because you cannot have a has_child and "or" search
               if operator == "or"
                  query = has_parent: [{
                     type: "contact"
                     query: FilterStore.getActiveFiltersQuery()
                  }]
               else
                  query = QueryContext "contact_note", Query [
                     ParentDoc "contact", FilterStore.getActiveFiltersQueryObject()
                  ]
                  query = query.toJSON()
            else query = {}
            query.sort = SortUtils.getQuery(sort.prop, sort.reverse)

            page_data = _.extend
               page: @getState("page")
               limit: LIMIT
            , @getState("interactions")

            InteractionSearchSource.fetch
               query: query
               params: PaginationUtils.getParams(page_data)

      api:
         getInteractions: ->
            @getState("interactions")

         getLoading: ->
            @getState("loading") && (@getState("page") == 1)

         getLoadingPage: ->
            @getState("loading") && (@getState("page") != 1)

         getSort: ->
            @getState("sort")

         hasMoreToLoad: ->
            interactions = @getState("interactions")
            interactions && (interactions.total > (interactions.offset + interactions.limit))
