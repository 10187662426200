import PropTypes from 'prop-types';
import { Link } from '@evertrue/et-components';

const EmailRecipients = ({ recipients }) => {
  return (
    <p className="outreach-draft-recipient-name">
      {recipients?.length
        ? recipients.map(({ id, name, email_address, contact_id }) => {
            const displayName = name || email_address;
            return (
              <span key={id}>
                {contact_id ? <Link to={`/contact/${contact_id}`}>{displayName}</Link> : displayName}
                {', '}
              </span>
            );
          })
        : ''}
    </p>
  );
};

EmailRecipients.propTypes = {
  recipients: PropTypes.array,
};

export default EmailRecipients;
