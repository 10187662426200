import PropTypes from 'prop-types';
import { useState } from 'react';
import ContactTagList from 'apps/contact/components/contact-tag-list';
import ModalConfirm from 'components/modals/modal-confirm';

const ContactPicker = ({ header, onSelect, description, requestHide }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleSubmit = () => {
    onSelect(selectedContacts);
    requestHide();
  };

  return (
    <ModalConfirm
      className="contact-picker"
      header={header || 'Select Constituents'}
      buttonLabel="Select"
      hasMargin
      disableButton={!selectedContacts || !selectedContacts?.length}
      onSubmit={handleSubmit}
      onCancel={requestHide}
    >
      <div>{description}</div>

      <div className="contact-picker--input clearfix">
        <ContactTagList selected={selectedContacts} onSelect={(selected) => setSelectedContacts(selected)} />
      </div>
    </ModalConfirm>
  );
};

ContactPicker.propTypes = {
  header: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  description: PropTypes.node,
  requestHide: PropTypes.func.isRequired,
};

export default ContactPicker;
