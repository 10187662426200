import { useIdentity } from 'base/identity-resolver';
import { useOpenClose } from '@evertrue/et-components';
import { fetchJourneyDetails } from 'apps/journeys/utils/api-requests/journey-details';
import { createContext, useContext, useState, useEffect } from 'react';
import Api from 'entities/helpers/api';
import PropTypes from 'prop-types';
import ErrorLogger from 'entities/helpers/error-log-helper';
import useSteps from '../hooks/use-steps';

const JourneyDetailContext = createContext(() => {});

/**
 * This context is used to track things that need to be shared on the Journey Detail page (/cadences/:id)
 * Add to it sparingly.
 *
 * NOTE: selectedDueDate was moved to the GLOBAL Journey Context because it is used in multiple places (Details and Index pages)
 *
 * @param {Object} children - The children components
 * @param {Number} journeyId - The ID of the journey from the URL
 * @returns {Object} - The context provider
 */
const JourneyDetailProvider = ({ children, journeyId }) => {
  const { user } = useIdentity();

  // Journey Metadata State
  const [journey, setJourney] = useState({});
  const [userIsCreator, setUserIsCreator] = useState(false);
  const [loadingJourney, setLoadingJourney] = useState(false);

  // Sidebar State -- Open and close a contact summary sidebar
  const [selectedContact, setSelectedContact] = useState(null);
  const [sidebarIsOpen, openSideBar, closeSideBar] = useOpenClose();

  // Action Type Filter State -- Users can filter what tasks appear based on their action type
  const [actionTypes, setActionTypes] = useState([]);
  const [actionTypeFilters, setActionTypeFilters] = useState([]);

  const stepState = useSteps();

  // Fetch Journey Details (this is JUST metadata, not the steps)
  useEffect(() => {
    const getAndSetJourney = async () => {
      try {
        setLoadingJourney(true);
        const journeyFromGql = await fetchJourneyDetails(Number(journeyId));
        setJourney(journeyFromGql);
      } finally {
        setLoadingJourney(false);
      }
    };

    getAndSetJourney();
    setSelectedContact(null);
  }, [journeyId]);

  // Boolean to determine if the current user is the creator of the journey
  useEffect(() => {
    if (journey?.creator_user_id && user?.id) {
      setUserIsCreator(user.id === journey.creator_user_id);
    }
  }, [journey, user]);

  // Fetch Action Types (for filtering)
  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const actionTypes = await Api.JOURNEYS.TASK_ACTION_TYPES.get();
        const otherActionTypes = Object.keys(actionTypes).filter((actionType) => {
          return actionType !== 'EMAIL' && actionType !== 'CALL';
        });
        setActionTypes(otherActionTypes);
      } catch {
        ErrorLogger.error('Failed to fetch actionTypes');
      }
    };
    getActionTypes();
  }, []);

  const openContactSummary = (contact) => {
    setSelectedContact(contact);
    openSideBar();
  };

  const updateJourneyState = (updatedJourney) => {
    const journeyCopy = { ...journey };
    setJourney({ ...journeyCopy, ...updatedJourney });
  };

  return (
    <JourneyDetailContext.Provider
      value={{
        // Journey Metadata
        journey,
        loadingJourney,
        userIsCreator,
        updateJourneyState,

        // Sidebar
        selectedContact,
        setSelectedContact,
        sidebarIsOpen,
        closeSideBar,
        openContactSummary,

        // Action Type Filters
        actionTypes,
        actionTypeFilters,
        setActionTypeFilters,

        // Steps Table
        ...stepState,
      }}
    >
      {children}
    </JourneyDetailContext.Provider>
  );
};

const useJourneyDetailProvider = () => {
  const context = useContext(JourneyDetailContext);
  if (!context) {
    throw new Error('useJourneyDetailProvider must be used within a JourneyDetailProvider');
  }
  return context;
};

JourneyDetailProvider.propTypes = {
  children: PropTypes.any,
  journeyId: PropTypes.string,
};

export { JourneyDetailContext, JourneyDetailProvider, useJourneyDetailProvider };
