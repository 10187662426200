module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   SegmentActivitySource = require("apps/filters/sources/segment-activity-source")
   SegmentSource = require("apps/filters/sources/segment-source").default
   Criteria = require("entities/search/query-parts/criteria")


   createStore "SegmentActivityStore",
      getInitialState: ->
         selected_activity: undefined
         activities: {}
         loading: false
         error: false

      registerActions: ->
         @on SegmentActivitySource.actions.selectActivity, @respondToSelectActivity
         @on SegmentActivitySource.actions.fetchedActivity, @respondToFetchedActivity
         @on SegmentActivitySource.actions.fetchedActivityError, @respondToFetchedError
         @on SegmentActivitySource.actions.loading, @respondToLoadingActivity

      respondToFetchedActivity: (activity) ->
         activities = _.cloneData @getState("activities")
         activities[activity.id] = activity
         @setState {activities: activities}

         if @getState("selected_activity") == activity?.id
            crit = Criteria(activity?.content?.search?.query)
            crit_must = crit.getByParameter("must")
            
            # new version of saved search alerter
            # where we get the results from are coming from a contact_tag child doc now
            if activity?.content?.search?.query?.has_child?
               segment_obj = filters: [
                  filter_id: "0.03"
                  filter_row_id: 0,
                  value: _.eachWithObject crit_must, (obj, value) ->
                     obj[value.property] = value?.value
                     obj.document_type = activity?.content?.search?.query?.has_child[0]?.type
               ]
               SegmentSource.applySegment(segment_obj)
            # old version of saved search alerter
            # where we get the results from are coming from the main contact document
            else
               segment_obj = filters: [
                  filter_id: "0.02"
                  filter_row_id: 0
                  value: _.eachWithObject crit_must, (obj, value) ->
                     obj[value.property] = value?.value
               ]
               SegmentSource.applySegment(segment_obj)

      respondToSelectActivity: (id) ->
         @setState {selected_activity: id}
         SegmentActivitySource.fetchActivity(id) if id

      respondToFetchedError: ->
         @setState {error: true}

      respondToLoadingActivity: (is_loading) ->
         @setState {loading: is_loading}

      api:
         getLoading: ->
            @getState("loading")

         getError: ->
            !@getLoading() && @getState("error")
