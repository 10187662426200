import { useEffect, useState } from 'react';
import { PropTypes } from 'base/react-libs';
import { Loading } from '@evertrue/et-components';
import { useFolderDetails } from '../hooks/use-folder-details';
import FolderListDetail from '../components/detail/folder-list-detail';
import FolderSegmentDetail from '../components/detail/folder-segment-detail';
import EverTrue from 'app';

const FolderController = ({ id }) => {
  const { folderType, folderOwner, loading } = useFolderDetails(id);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!loading && folderType && !['STATIC', 'DYNAMIC'].includes(folderType)) {
      setShowError(true);
      // Go back to previous page after showing error
      setTimeout(() => {
        EverTrue.Alert.error('Invalid folder type');
        window.history.back();
      }, 2000);
    }
  }, [loading, folderType]);

  if (loading) {
    return <Loading />;
  }

  if (showError) {
    return <div>Invalid folder type. Going back to previous page...</div>;
  }

  if (folderType === 'STATIC') {
    return <FolderListDetail id={id} folderOwner={folderOwner} />;
  }

  if (folderType === 'DYNAMIC') {
    return <FolderSegmentDetail id={id} folderOwner={folderOwner} />;
  }

  return null;
};

FolderController.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FolderController;
