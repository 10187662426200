import { PropTypes } from 'base/react-libs';
import { Modal, Icon } from '@evertrue/et-components';
import Decorator from 'clientDecorator';
import InteractionForm from 'apps/interactions/components/create/interaction-form';
import useQuickInteractionBuilder from '../hooks/use-quick-interaction-builder';

/**
 * This is THE Quick Interaction Modal. Anytime an Outreach Drawer is closed,
 * this modal opens with Interaction data supplied from the outreach (eg the email or phone call).
 *
 * "props" is a catch-all because there are many different props that can be provided.
 * Here are the ones that are always present:
 * type -- string, type of interaction/drawer ("emailer" or "dialer")
 * saveLabel -- string, what does that save button say ("Save")
 * cancelLabel -- string, what does the cancel button say ("Skip")
 * contact -- object, will vary across the org (maybe a "full" contact object or a graphql contact object)
 *
 * Here are the optional ones:
 * interaction -- object; some places (like the emailer) we don't need to generate anything new. this interaction is all we need to provide.
 * task -- object, Task associated with the outreach
 * journey -- object, Journey associated with the outreach
 *
 * You can provide whatever you want, as long as you account for it in the useQuickInteractionBuilder
 */
const QuickInteractionModal = ({ isOpen = false, closeModal = () => {}, ...props }) => {
  const { contact = {}, saveLabel = 'Save', cancelLabel = 'Skip', emailId, emailCreatorId } = props;
  const { isStreaming, ...quickInteraction } = useQuickInteractionBuilder(props);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      {isStreaming || !quickInteraction?.text ? (
        <div className="loading-modal">
          <Icon className="action-type-icon" icon="spinner" size={5} spin />
        </div>
      ) : (
        <InteractionForm
          emailId={emailId}
          emailCreatorId={emailCreatorId}
          contact={Decorator.Contacts.parse(contact)} // wrapping with Decorator is required for prop validation in the interaction form.
          saveLabel={saveLabel}
          cancelLabel={cancelLabel}
          interaction={quickInteraction}
          requestHide={closeModal}
        />
      )}
    </Modal>
  );
};

QuickInteractionModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  contact: PropTypes.object,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  emailId: PropTypes.number,
  emailCreatorId: PropTypes.number,
};

export default QuickInteractionModal;
