module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   UserSessionResolver = createFactory(require("apps/users/components/user-session-resolver").default)
   UserStore = require("apps/users/stores/user-store")
   Button = createFactory require("@evertrue/et-components").Button
   ButtonTooltip = createFactory require("@evertrue/et-components").ButtonTooltip
   InviteStore = require("apps/users/stores/invite-store")
   InviteStore = require("apps/users/stores/invite-store")
   RoleHelper = require("entities/helpers/role-helper")
   {div, span, i, strong} = ReactLibs.DOMFactories
   Modal = createFactory require("components/modals/modal")
   ModalTrigger2 = createFactory require("@evertrue/et-components").ModalTrigger2
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   UserCard = createFactory require("apps/users/components/user-card")
   ToolTip = require("components/overlays/tooltip")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Icon = createFactory (require("components/elements/icon").default)
   MatchContactModal = require("apps/users/components/match-contact-modal")
   DeleteUser = require("apps/users/components/delete-user")
   EditUser =  createFactory(require("apps/users/components/edit-user").default)
   UnlinkContact = createFactory(require("apps/users/components/unlink-contact").default)
   Decorator = require("clientDecorator")
   Loading = require("components/elements/loading")

   _getRoleList = ->
      [
         {label: "User", value: RoleHelper.getUserId()}
         {label: "Owner", value: RoleHelper.getOwnerId()}
      ]

   ManageUsersTableRows =
      user:
         label: "Team Member"
         width: 300
         prop: "name"
         key: "user"
         sticky: true
         sort: 1
         row: (user) ->
            UserCard user: user

      role:
         label: "Role"
         width: 185
         prop: "current_role_id"
         key: "role"
         sort: 2
         row: (user) ->
            isCurrentUser = user.id == EverTrue.store.user.get("id")
            role_data = _.findWhere(_getRoleList(), {value: user.current_role_id}) || {}

            div null,
               if user.is_invite || isCurrentUser
                  role_data.label
               else if !user.is_invite && !isCurrentUser
                  AdvancedCombobox
                     title: "User Role"
                     className: "manage-users--role"
                     value: role_data
                     options: _getRoleList()
                     placeholder: "Set Role"
                     onChange: (selected) ->
                        UserStore.updateRole(user.id, [selected?.value])

      status:
         label: "Authentication"
         width: 185
         prop: "current_status"
         key: "status"
         sort: 3
         row: (user) ->
            start_date = user.current_status
            verb = if user.is_invite then "Invited " else "Joined "
            div null,
               verb + moment(start_date).fromNow()

      sessions:
         label: "Sessions"
         width: 185
         prop: null
         key: "session"
         sort: 4
         row: (user) ->
            user_id = user.id
            org_id = user.contact?.oid
            if !user.is_invite && user_id
               UserSessionResolver
                  user_id: user_id
                  org_id: org_id
                  app_name: "givingtree"
                  render: ({ user_session, loading }) =>
                     if loading
                        Loading()
                     else if !loading && !_.isEmpty user_session
                        {count, last_session_time} = user_session
                        div null,
                           (count || "0") + " sessions"
                           div className: "text-label",
                              div null,
                                 "Most recent:"
                              div null,
                                 if last_session_time then moment(last_session_time).format("MM/DD/YYYY [at] h:mm a") else "-"
            else
               div null,
                  "---"

      mfaStatus:
         label: "2-Step Verification"
         width: 185
         prop: "mfa_enabled_at"
         key: "mfaStatus"
         sort: 5
         row: (user) ->
            date = user?.mfa_enabled_at
            if user.is_invite
               div null, "--"
            else
               if user.mfa_enabled_at?
                  div null,
                     Icon className: "text-dollar-value", icon: "check-circle", size: 2
                     div null,
                        if date then moment(date, "x").format("MMMM DD, YYYY") else "n/a"
               else
                  div className: "text-danger",
                     Icon icon: "alert-circle", size: 2

      matched_contact_id:
         label: "Contact Record"
         width: 185
         prop: "matched_contact_id"
         key: "matchedContactId"
         sort: 6
         row: (user) ->
            contact_id = user?.matched_contact_id
            name = Decorator.Contacts.getFullName(user?.contact)

            if user.is_invite
               div null, "--"
            else
               div className: "manage-users--contact",
                  if contact_id
                     ModalTrigger
                        title: "Unlink Contact"
                        className: "contact-id--remove"
                        modal: Modal(width: 450, UnlinkContact(user: user)),
                           strong className: "manage-users--contact-name", name
                           div className: "manage-users--contact-id", contact_id
                  else
                     ModalTrigger
                        modal: Modal(width: 600, MatchContactModal(user: user, contact_id: contact_id))
                        title: "Link Contact",
                           Button
                              type: "secondary"
                              "Link"

      actions:
         label: "Actions"
         width: 185
         prop: null
         key: "actions"
         sort: 7
         className: "manage-users--actions"
         row: (user, props) ->
            reset = -> InviteStore.resend(user)
            can_edit_users = EverTrue.store.user.isOwnerOrSuperUser()

            div className: "manage-users--actions-section",
               if user.is_invite
                  span className: "manage-users--action",
                     ToolTip
                        position: "top"
                        content: "Resend Invite"
                        i className: "gt-icon gt-icon-2x gt-icon-email text-muted", onClick: reset
               if can_edit_users && !user.is_invite
                  ModalTrigger2
                     title: "Edit User"
                     className: "manage-users--action"
                     trigger: ({ open }) ->
                        ButtonTooltip
                           onTriggerClick: open
                           trigger:
                              i className: "gt-icon gt-icon-2x gt-icon-edit text-muted "
                           children: "Edit User"
                     children:({close}) =>
                        EditUser
                           user: user
                           onClose: close
                           clearSearchInput: props.clearSearchInput 

               if user.id != EverTrue.store.user.get("id")
                  ModalTrigger2
                     title: "Remove User"
                     className: "manage-users--action"
                     trigger: ({ open }) ->
                        ButtonTooltip
                           onTriggerClick: open
                           trigger:
                              i className: "gt-icon gt-icon-2x gt-icon-delete text-muted "
                           children: "Remove User"
                     children:({close}) =>
                        DeleteUser
                           user: user
                           onClose: close
