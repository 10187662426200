import { useFluxStore } from '@evertrue/et-flux';
import EverTrue from 'app';
import MyPortfolioSource from 'apps/major-gifts/sources/my-portfolio-source';
import MyPortfolioStore from 'apps/major-gifts/stores/my-portfolio-store';
import Modal from 'components/modals/modal';
import Slideshow from 'components/slideshow';

const SLIDES = [
  {
    text: "View your assigned prospects by your organization's Prospect Status.",
    image: 'my_portfolio_slide_01.png',
  },
  {
    text: 'View your assigned prospects by location on the map.',
    image: 'my_portfolio_slide_02.png',
  },
  {
    text: 'My Portfolio is now your start page, accessible from the side navigation menu.',
    image: 'my_portfolio_slide_03.png',
  },
];

const MyPortfolioSlidesController = () => {
  const { show_modal } = useFluxStore(MyPortfolioStore, () => ({
    show_modal: MyPortfolioStore.getShouldViewSlides(),
  }));

  const handleViewed = () => {
    MyPortfolioSource.slidesViewed({ viewed: true });
  };

  const is_impersonating = EverTrue.request('isImpersonating?');

  if (!show_modal || is_impersonating) {
    return null;
  }

  return (
    <Modal width={640} keepOpen>
      <Slideshow
        title="Introducing My Portfolio"
        slides={SLIDES}
        doneButtonText="Ok, I got it!"
        onDone={handleViewed}
      />
    </Modal>
  );
};

export default MyPortfolioSlidesController;
