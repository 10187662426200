import PropTypes from 'prop-types';
import { useRef, useContext, useState } from 'react';
import { useIdentity } from 'base/identity-resolver';
import { isDynamicTemplate } from '../../../utils/utils';
import { EmailTemplatesContext } from '../hooks/email-templates-context';
import useDynamicFields from 'components/emailer/hooks/use-dynamic-fields';
import CreateOrUpdateEmailForm from './create-or-update-email-form';

const CreateOrUpdateEmailTemplateModal = ({ originalTemplate = {}, setShowEditView, setSelectedTemplate, contact }) => {
  const quillRef = useRef();
  const { getMissingFields } = useDynamicFields(quillRef, contact, false, false);
  const { getAndSetTemplates, createEmailTemplate, updateEmailTemplate } = useContext(EmailTemplatesContext);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [missingFields, setMissingFields] = useState([]);

  const {
    org: { id: oid },
    user: { id: userId },
  } = useIdentity();

  const handleSubmit = async (values) => {
    const unavailableFields = getMissingFields(values.templateBody);
    setMissingFields(unavailableFields);

    if (unavailableFields.length === 0 || showReviewPopup) {
      const quill = quillRef.current.getEditor();
      const quillBody = quill.root.innerHTML;
      const template = {
        ...values,
        templateBody: quillBody,
        dynamicTemplate: isDynamicTemplate(quillBody),
      };

      if (!Object.keys(originalTemplate).length) {
        await createEmailTemplate(template);
      } else {
        const { id } = originalTemplate;
        const updatedTemplate = await updateEmailTemplate(id, oid, userId, template);
        setSelectedTemplate(updatedTemplate);
      }
      setShowEditView(false);
      getAndSetTemplates(0);
      setShowReviewPopup(false);
    } else {
      setShowReviewPopup(true);
    }
  };

  return (
    <>
      <CreateOrUpdateEmailForm
        handleSubmit={handleSubmit}
        missingFields={missingFields}
        originalTemplate={originalTemplate}
        contact={contact}
        setShowEditView={setShowEditView}
        showReviewPopup={showReviewPopup}
        setShowReviewPopup={setShowReviewPopup}
        quillRef={quillRef}
      />
    </>
  );
};

CreateOrUpdateEmailTemplateModal.propTypes = {
  originalTemplate: PropTypes.object,
  setShowEditView: PropTypes.func,
  setSelectedTemplate: PropTypes.func,
  contact: PropTypes.object,
};

export default CreateOrUpdateEmailTemplateModal;
