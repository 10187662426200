import Module from 'components/module';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import { LinkButton } from '@evertrue/et-components';

const MatchingConstituents = ({
  link,
  linkText = 'View All Constituents',
  helpKey,
  helpPosition,
  header,
  limit = 5,
  referrer,
  constituents = [],
}) => {
  const handleMoreClick = () => {
    EverTrue.UrlManager.clear({ silent: true });
    console.log('link', link);
    EverTrue.Navigator(`/contact?${link}`, true);
  };

  return (
    <div>
      <Module header={header} help_key={helpKey} help_position={helpPosition} noMargin>
        {constituents ? (
          <div className="company-constituents--container">
            {constituents.length > limit ? (
              <div>
                {`Showing first ${limit} matches.`}
                <LinkButton className="company-constituents--more" onClick={handleMoreClick} title="View all matches">
                  View All
                  <i className="gt-icon gt-icon-double-chevron-right" />
                </LinkButton>
              </div>
            ) : (
              <div>{constituents.length === 1 ? 'Showing 1 match.' : `Showing ${constituents.length} matches.`}</div>
            )}

            <div className="company-constituents--content">
              <table className="contact-column">
                <tbody>
                  {constituents.slice(0, limit)?.map((constituent) => (
                    <tr key={constituent.id}>
                      <td>
                        <ContactCard contact={constituent} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {link && (
                <LinkButton
                  title="View more"
                  className="company-constituents--view-all"
                  onClick={handleMoreClick}
                  data-refer={referrer}
                >
                  {linkText}
                  <i className="gt-icon gt-icon-double-chevron-right" />
                </LinkButton>
              )}
            </div>
          </div>
        ) : null}
      </Module>
    </div>
  );
};

MatchingConstituents.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.any,
  helpKey: PropTypes.string,
  helpPosition: PropTypes.string,
  header: PropTypes.any,
  limit: PropTypes.number,
  referrer: PropTypes.string,
  constituents: PropTypes.array,
};

export default MatchingConstituents;
