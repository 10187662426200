import { useGate } from 'components/is-gated';
import { Icon, useOpenClose } from '@evertrue/et-components';
import { useEffect, useState } from 'react';
import HomepageCard from '../../homepage-card';
import SearchRecommendations from './sections/recommended-searches';
import useRecommendedActions from './hooks/use-recommended-actions';
import RecommendedActionSection from './sections/recommended-action-section';
import useRecommendedActionsUserSettings from './hooks/use-recommended-action-user-settings';
import RecommendedActionUserSettingsModal from './recommended-action-user-settings-modal';
import Loading from 'components/elements/loading';

const RecommendedActions = () => {
  const [empty, setEmpty] = useState(false);
  const [hasWindfall] = useGate('windfall');
  const { submitUserSettings, userSettings } = useRecommendedActionsUserSettings();
  const [isRecommendationsModalOpen, openRecommendationsModal, closeRecommendationsModal] = useOpenClose();
  const { recommendedActions, loading } = useRecommendedActions(userSettings);

  useEffect(() => {
    const showEmpty = !Object.values(recommendedActions).flat().length;
    setEmpty(showEmpty);
  }, [recommendedActions]);

  return (
    <>
      <h1 className="homepage-card-title">Your Assigned Constituents</h1>
      <HomepageCard
        leftHeading={
          <span className="homepage-recommendations-card-left">
            Recommended Actions
            <span className="homepage-recommendations-card-subtitle">
              Here are your assigned constituents with whom we recommend establishing a touchpoint.
            </span>
          </span>
        }
        rightHeading={
          <Icon icon="gear" size={1} onClick={openRecommendationsModal} className="homepage-settings-icon" />
        }
        body={
          <div>
            {loading ? (
              <div style={{ paddingTop: '18px', height: '42px', marginBottom: '10px', marginTop: '10px' }}>
                <Loading spinner_size={'small'} className={'loader-icon'} />
              </div>
            ) : (
              <>
                {empty ? (
                  <SearchRecommendations windfallEnabled={hasWindfall} />
                ) : (
                  <>
                    {Object.entries(recommendedActions).map(([section, actionsArray]) => {
                      switch (section) {
                        case 'recentGift':
                          return (
                            <RecommendedActionSection
                              key={section}
                              title={
                                userSettings && userSettings.daysSinceLastGift
                                  ? `Made a gift in the last ${userSettings.daysSinceLastGift} ${
                                      userSettings.daysSinceLastGift === 1 ? 'day' : 'days'
                                    }`
                                  : 'Made a recent gift'
                              }
                              subtitle="Recent Gift"
                              recommendedActions={actionsArray}
                              icon="gift"
                              type="gift"
                              emailButtonLabel="Say Thanks"
                              loading={loading}
                            />
                          );
                        case 'giftAnniversary':
                          return (
                            <RecommendedActionSection
                              key={section}
                              title="Upcoming gift anniversary in the next 30 days"
                              subtitle="Last Gift"
                              recommendedActions={actionsArray}
                              icon={'anniversary'}
                              type="gift"
                              emailButtonLabel="Say Thanks"
                              loading={loading}
                            />
                          );
                        case 'careerMove':
                          return (
                            <RecommendedActionSection
                              key={section}
                              title="Career Moves in past 3 months"
                              subtitle="Promotion"
                              recommendedActions={actionsArray}
                              icon={'work'}
                              iconKit="gt"
                              type="career"
                              emailButtonLabel="Say Congrats"
                              loading={loading}
                            />
                          );
                        case 'tripsAndMeetings':
                          return (
                            <RecommendedActionSection
                              key={section}
                              title="Unlogged Contact Reports"
                              subtitle="Contact Reports"
                              recommendedActions={actionsArray}
                              icon={'trip-log'}
                              iconKit="gt"
                              type="trips"
                              emailButtonLabel="Send a follow-up note"
                              secondaryActionLabel="Log your interaction details"
                              loading={loading}
                            />
                          );
                        case 'dps':
                          return (
                            <RecommendedActionSection
                              key={section}
                              title="Top rated DPS prospects without recent interactions"
                              subtitle="Top rated DPS"
                              recommendedActions={actionsArray}
                              icon={'et-pyramid'}
                              iconKit="gt"
                              type="dps"
                              emailButtonLabel="Send a note"
                              loading={loading}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </>
                )}
              </>
            )}
          </div>
        }
      />
      <RecommendedActionUserSettingsModal
        isOpen={isRecommendationsModalOpen}
        onClose={closeRecommendationsModal}
        initialSettings={userSettings}
        submitUserSettings={submitUserSettings}
      />
    </>
  );
};

export default RecommendedActions;
