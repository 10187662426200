module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {span, div, img} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils").default

   createComponent "FacebookReactionsDropdown",
      propTypes:
         value: ReactLibs.PropTypes.any
         onChange: ReactLibs.PropTypes.func
         placeholder: ReactLibs.PropTypes.string
         multiple: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         value: undefined
         multiple: true
         placeholder: "Select Options..."

      formatItem: (item, isSelected, isHovered) ->
         div className: "facebook-reactions-dropdown--item",
            div null,
               Checkbox className: "et--checkbox-small", checked: isSelected, value: item?.value
               span className: "facebook-reactions-dropdown--label", item.label
            img
               className: "facebook-reactions-dropdown--icon"
               src: item.icon

      render: ->
         div className: "facebook-reactions-dropdown",
            AdvancedCombobox
               options: FacebookReactions.getAll()
               value: _.map @props.value, (val) -> _.clone val
               multiple: @props.multiple
               placeholder: @props.placeholder
               onChange: @props.onChange
               formatItem: @formatItem
               searchable: true
               allowChangeAll: true
               allowUnselectAll: @props.multiple
