import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popover2 } from '@evertrue/et-components';
import ActionMenuItem from 'components/controls/action-menu-item';
import { getMomentObjectInTimezone } from 'base/new-utils';
import moment from 'moment';
import ScheduleEmailModal from './schedule-email-modal';
import ScheduleLabel from './schedule-label';

const SplitButton = ({ onSend, onSchedule, disabled, scheduledTime }) => {
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);

  const handleSendClick = () => {
    onSend();
  };

  const handleScheduleClick = (time, formattedDateTime) => {
    onSchedule(time, formattedDateTime);
  };

  const getFormattedDate = (daysToAdd, time) => {
    const date = getMomentObjectInTimezone(moment().add(daysToAdd, 'days').startOf('day'));
    const [hours, minutes] = time.split(':');
    const combinedDateTime = date.add(parseInt(hours, 10), 'hours').add(parseInt(minutes, 10), 'minutes');
    const epochTime = combinedDateTime.valueOf() / 1000; // Convert to seconds
    const formattedDateTime = combinedDateTime.format('dddd, MMM D, hh:mm A').replace(/AM/g, 'AM').replace(/PM/g, 'PM');
    return { epochTime, formattedDateTime };
  };

  const getOptions = () => [
    {
      id: 'schedule-email',
      heading: 'Schedule Email',
      items: [
        {
          id: 'tomorrow_morning',
          label: <ScheduleLabel text="Tomorrow Morning" dateTime={getFormattedDate(1, '08:00').formattedDateTime} />,
          onClick: () => {
            const { epochTime, formattedDateTime } = getFormattedDate(1, '08:00');
            handleScheduleClick(epochTime, formattedDateTime);
          },
        },
        {
          id: 'tomorrow_afternoon',
          label: <ScheduleLabel text="Tomorrow Afternoon" dateTime={getFormattedDate(1, '13:00').formattedDateTime} />,
          onClick: () => {
            const { epochTime, formattedDateTime } = getFormattedDate(1, '13:00');
            handleScheduleClick(epochTime, formattedDateTime);
          },
        },
        {
          id: 'custom',
          label: <>Custom Date & Time</>,
          onClick: () => setScheduleModalOpen(true),
        },
      ],
    },
  ];

  return (
    <div className={disabled ? 'split-btn-disabled' : 'split-button'}>
      <Button label="Send" className="split-button-send" type="default" onClick={handleSendClick} disabled={disabled}>
        Send
      </Button>
      <Popover2
        trigger={({ togglePopover, openPopover, is_open }) => (
          <Button type="default" onClick={togglePopover} className="split-button-arrow" disabled={disabled}>
            <Icon icon={is_open || isScheduleModalOpen ? 'chevron-up' : 'chevron-down'} />
          </Button>
        )}
      >
        {({ closePopover }) => (
          <div className="schedule-email-popover">
            {getOptions().map((optionGroup) => (
              <div key={optionGroup.id} className="et--actions-menu--option-group">
                <div className="et--actions-menu--option-heading">{optionGroup.heading}</div>
                {optionGroup.items.map((option) => (
                  <ActionMenuItem
                    title="Schedule Email"
                    className="et--actions-menu--menu-option"
                    key={option.id}
                    onClick={() => {
                      option.onClick();
                      closePopover();
                    }}
                  >
                    {option.label}
                  </ActionMenuItem>
                ))}
              </div>
            ))}
          </div>
        )}
      </Popover2>
      <ScheduleEmailModal
        isOpen={isScheduleModalOpen}
        onClose={() => setScheduleModalOpen(false)}
        onSchedule={handleScheduleClick}
        scheduledDateAndTime={scheduledTime}
      />
    </div>
  );
};

SplitButton.propTypes = {
  onSend: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  scheduledTime: PropTypes.number,
};

export default SplitButton;
