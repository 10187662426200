import PropTypes from "prop-types";

const ToggleSwitch = ({ label, checked, onChange }) => (
  <div className="settings--setting">
    <span>{label}</span>
    <label className="settings--toggle-switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="settings--slider"></span>
    </label>
  </div>
);

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSwitch;
