const gql = String.raw;

const prospectQueries = {
  /*
  ---------------------
  Gets Stages, Prospect's current stage, assignment id and selection mode.
  STAGES - for selecting the stage to update the prospect to.
  current stage - for displaying the current stage of the prospect.
  assignment id - for updating and removing the prospect from the stage.
  selection mode - for checking if the stage is updatable.
  ---------------------
  */

  fetchStagesAndProspectDetails: gql`
    query ($solicitorContactId: BigInt!, $prospectContactId: BigInt!) {
      stages(contact_id: $solicitorContactId, prospect_contact_id: $prospectContactId)
    }
  `,

  checkContactIsSolicitor: gql`
    query ($solicitorContactId: BigInt!, $prospectContactId: BigInt!) {
      checkContactIsSolicitor(contact_id: $solicitorContactId, prospect_contact_id: $prospectContactId)
    }
  `,
};

export default prospectQueries;
