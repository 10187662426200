import Api from 'entities/helpers/api';
import EverTrue from 'app';

export const getJourneyTimeoutsForUser = async (userId) => {
  if (!userId) return {};

  try {
    return await Api.JOURNEYS.JOURNEY_TIMEOUTS.get({
      urlArgs: { user_id: userId },
      success: (res) => {
        return res;
      },
    });
  } catch (e) {
    console.error(e);
    return EverTrue.Alert.error('Unable to fetch pause states');
  }
};

export const createJourneyTimeout = async (payload) => {
  return await Api.JOURNEYS.JOURNEY_TIMEOUTS.post({
    data: JSON.stringify(payload),
    success: (response) => {
      EverTrue.Alert.success('Pause saved successfully');
      return response;
    },
    error(e) {
      return EverTrue.Alert.error('Pause save unsuccessful. Please try again.');
    },
  });
};

export const updateJourneyTimeout = async (payload, journeyTimeoutId) => {
  return await Api.JOURNEYS.JOURNEY_TIMEOUT.put({
    urlArgs: { id: journeyTimeoutId },
    data: JSON.stringify(payload),
    success: (response) => {
      EverTrue.Alert.success('Pause updated successfully.');
      return response;
    },
    error() {
      return EverTrue.Alert.error('Pause update unsuccessful. Please try again.');
    },
  });
};

export const cancelJourneyTimeout = async (journeyTimeoutId) => {
  return await Api.JOURNEYS.CANCEL_JOURNEY_TIMEOUT.put({
    urlArgs: { id: journeyTimeoutId },
    success: (response) => {
      EverTrue.Alert.success('Pause cancelled successfully.');
      return response;
    },
    error() {
      return EverTrue.Alert.error('Pause cancel unsuccessful. Please try again.');
    },
  });
};

export const getHolidays = async () => {
  try {
    return Api.JOURNEYS.JOURNEY_TIMEOUT_HOLIDAYS.get();
  } catch (e) {
    console.error(e);
    return {};
  }
};
