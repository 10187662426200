import { useEffect, useState } from 'react';
import { pollAiCaptions, createAICaptions, destroyCaptions } from '../../../entities/helpers/thankview-helper';
import { useRecorder } from '../hooks/recorder-context';

const useAiCaptions = () => {
  const {
    selectedVideo: { libraryVideo: { id = null, caption_processing = false, caption_url = null } = {} } = {},
    clientId: { data: { client_id = null, client_secret = null } = {} } = {},
  } = useRecorder();
  const [captionsProcessing, setCaptionsProcessing] = useState(caption_processing);
  const [captions, setCaptions] = useState(caption_url || '');

  useEffect(() => {
    let isSubscribed = true;
    let timeoutId = null;

    const getCaptionsStatus = async () => {
      try {
        const {
          data: { processingState, captionUrl },
        } = await pollAiCaptions(id, client_id, client_secret);

        if (!isSubscribed) return;

        if (processingState === 'Complete') {
          setCaptionsProcessing(false);
          setCaptions(captionUrl);
          return;
        }

        // Continue polling if still processing
        timeoutId = setTimeout(getCaptionsStatus, 5000);
      } catch (error) {
        if (!isSubscribed) return;
        console.error('Error polling captions:', error);
      }
    };

    if (captionsProcessing) {
      getCaptionsStatus();
    }

    // Cleanup function
    return () => {
      isSubscribed = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [id, caption_processing, client_id, client_secret, captionsProcessing]);

  const createCaptions = () => {
    createAICaptions(id, client_id, client_secret);
    setCaptionsProcessing(true);
  };

  const deleteCaptions = () => {
    destroyCaptions(id, client_id, client_secret);
    setCaptionsProcessing(false);
  };

  return {
    captionsProcessing,
    createCaptions,
    deleteCaptions,
    captions,
  };
};

export default useAiCaptions;
