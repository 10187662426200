import { PropTypes } from 'base/react-libs';
import { PageHeader, Icon, Button } from '@evertrue/et-components';
import AddJourneyButton from '../top-nav/add-journey-button';

const JourneyIndexBanner = ({ handleCreateJourney = () => {} }) => (
  <div className="journeys-banner">
    <PageHeader title="Cadences" />
    <div className="journeys-banner--action-buttons">
      <AddJourneyButton handleCreateJourney={handleCreateJourney} />
      <Button type="simple" href="settings/cadences">
        <Icon icon="gear" size={1} className="homepage-settings-icon" />
      </Button>
    </div>
  </div>
);

JourneyIndexBanner.propTypes = {
  handleCreateJourney: PropTypes.func,
};

export default JourneyIndexBanner;
