import { useEffect } from 'react';
import { useFluxStore } from '@evertrue/et-flux';
import NotificationStore from 'apps/notifications/stores/notification-store';
import NotificationSource from 'apps/notifications/sources/notification-source';
import ContactsFixedTable from 'apps/contact/components/contacts/contact-fixed-table';
import ContactTableToolbar from 'apps/contact/components/contacts/table/contact-table-toolbar';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import SelectedContactsString from 'components/formatting/selected-contacts-string';
import PropTypes from 'prop-types';

const NotificationDetailsController = ({ id }) => {
  const { contacts, loading, notification } = useFluxStore(NotificationStore, () =>
    NotificationStore.getNotificationStoreData(id)
  );

  const selected_contacts = useFluxStore(SelectedContactsStore, () => SelectedContactsStore.getSelectedContacts());

  useEffect(() => {
    NotificationSource.fetchContacts(id);
  }, [id]);

  return (
    <div className="notification-details">
      <div className="notification-details--header">
        <div className="notification-details--label">
          {notification?.activity_type === 'facebook'
            ? 'Recent Facebook Engagement'
            : notification?.activity_type === 'linkedin'
            ? 'Recent LinkedIn Profile Updates'
            : 'Recent EverTrue Updates'}
        </div>

        <SelectedContactsString selected={selected_contacts.length} results={contacts?.total} />
      </div>

      <div className="notification-details--toolbar">
        <ContactTableToolbar contacts={contacts} />
      </div>

      <div className="notification-details--table">
        <ContactsFixedTable contacts={contacts} loading={loading} referrer="search_update" />
      </div>
    </div>
  );
};

NotificationDetailsController.propTypes = {
  id: PropTypes.number,
};

export default NotificationDetailsController;
