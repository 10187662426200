import { useState, useEffect } from 'react';
import _ from 'underscore';
import { Loading, Card, SearchInput, FlexTable, useOpenClose, Icon, PageHeader } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import ClickToInput from 'components/controls/click-to-input';
import DeleteModal from 'apps/search/components/delete-modal';
import EverTrue from 'app';
import { useIdentity } from 'base/identity-resolver';
import { useFolderDetails } from '../../hooks/use-folder-details';
import FolderActions from '../folder-actions';
import CommonDeleteModal from '../modal/common-delete-modal';
import { useSegments } from 'apps/segments/hooks/use-segments';
import SavedSearchesColumns from 'apps/segments/saved-searches-columns';

const FolderSegmentDetail = ({ id, folderOwner }) => {
  const {
    folderLists,
    folderName,
    folderType,
    loading,
    updateFolder,
    removeListFromFolder,
    deleteFolder,
    updateFolderLists,
  } = useFolderDetails(id);
  const { deleteSegment, deleteSegments, handleValueChange, preferences } = useSegments();
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableSegments, setSelectableSegments] = useState([]);
  const [selectedSavedSearches, setSelectedSavedSearches] = useState([]);
  const [enableAllActions, setEnableAllActions] = useState(false);
  const [segmentToDelete, setSegmentToDelete] = useState(null);
  const [isDeleteFolderModalOpen, openDeleteFolderModal, closeDeleteFolderModal] = useOpenClose();
  const [isRemoveSegmentsModalOpen, openRemoveSegmentsModal, closeRemoveSegmentsModal] = useOpenClose();
  const [isDeleteSegmentModalOpen, openDeleteSegmentModal, closeDeleteSegmentModal] = useOpenClose();
  const [isBulkSegmentsDeleteModalOpen, openBulkSegmentsDeleteModal, closeBulkSegmentsDeleteModal] = useOpenClose();

  const { user } = useIdentity();
  const userIsOwner = user.id == folderOwner;

  // Folder And Segments Handlers
  const handleSaveFolderName = (newName) => {
    if (newName && newName !== folderName) {
      updateFolder(newName);
    }
  };

  const handleSingleSegmentDelete = (segmentId) => {
    if (segmentId) {
      deleteSegment(segmentId);
      updateFolderLists([segmentId]);
      setSelectedSavedSearches([]);
      setSegmentToDelete(null);
      closeDeleteSegmentModal();
    }
  };

  const handleBulkSegmentsDelete = () => {
    deleteSegments(selectedSavedSearches.map((segment) => segment.id));
    updateFolderLists(selectedSavedSearches.map((segment) => segment.id));
    setSelectedSavedSearches([]);
    closeBulkSegmentsDeleteModal();
  };

  const openDeleteModal = (segmentId) => {
    setSegmentToDelete(segmentId);
    openDeleteSegmentModal();
  };

  let segmentsSorted = _.sortBy(selectableSegments, (segment) => segment.name.toLowerCase());
  if (!sortOrder) {
    segmentsSorted = segmentsSorted.reverse();
  }

  const checkOwnership = (updatedSelectedSavedSearches) => {
    const allSelectedSegmentsAreUserOwned =
      updatedSelectedSavedSearches.length > 0 &&
      updatedSelectedSavedSearches.every((segment) => segment.user_id === user.id);
    setEnableAllActions(allSelectedSegmentsAreUserOwned);
  };

  const handleCheckAllSavedSearches = (isChecked) => {
    if (isChecked) {
      setSelectedSavedSearches([...segmentsSorted]);
      checkOwnership([...segmentsSorted]);
    } else {
      setSelectedSavedSearches([]);
    }
  };

  const handleSavedSearchCheck = (savedSearch, isChecked) => {
    const updatedSavedSearches = isChecked
      ? [...selectedSavedSearches, savedSearch]
      : selectedSavedSearches.filter((l) => l.id !== savedSearch.id);

    setSelectedSavedSearches(updatedSavedSearches);
  };

  const handleSelectedSavedSearchesChange = (updatedSelectedSavedSearches) => {
    checkOwnership(updatedSelectedSavedSearches);
    setSelectedSavedSearches(updatedSelectedSavedSearches);
  };

  // Effect for search change
  useEffect(() => {
    const debouncedSetSelectableLists = _.debounce(() => {
      setSelectableSegments(_.filter(folderLists, (segment) => _.searchCompare(segment.name, searchText)));
    }, 300);
    debouncedSetSelectableLists();
  }, [searchText, folderLists]);

  const columns = SavedSearchesColumns(
    'Delete Saved Search',
    setSortOrder,
    sortOrder,
    user,
    selectedSavedSearches,
    handleSavedSearchCheck,
    handleCheckAllSavedSearches,
    folderLists,
    handleSelectedSavedSearchesChange,
    openDeleteModal,
    handleValueChange,
    userIsOwner,
    true, //hasFolders is true because if you are able to open the Folders page, it means you have access to Folders.
    preferences
  );

  const confirmDeleteFolder = () => {
    deleteFolder(id);
    closeDeleteFolderModal();
  };

  const confirmRemoveSegments = () => {
    const deletePromises = selectedSavedSearches.map((segment) => removeListFromFolder(id, segment.id));
    Promise.all(deletePromises)
      .then(() => {
        updateFolderLists(selectedSavedSearches.map((segment) => segment.id));
        closeRemoveSegmentsModal();
        EverTrue.Alert.success(`Saved Searches removed from ${folderName}`);
        setSelectedSavedSearches([]);
      })
      .catch((error) => {
        EverTrue.Alert.error('An error occurred while removing Saved Searches.');
      });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="task-header-container">
            <Icon
              icon="chevron-left"
              size={2}
              className="task-header-back"
              onClick={() => {
                EverTrue.Navigator('/segments');
              }}
            />
            <PageHeader
              title={
                <div className="list-detail--label">
                  <ClickToInput
                    value={folderName}
                    onSave={handleSaveFolderName}
                    showFullText={true}
                    disable={!userIsOwner}
                    maxLength={30}
                  />
                </div>
              }
              subtitle={`${segmentsSorted.length} Saved Searches`}
              actions={
                userIsOwner && (
                  <FolderActions
                    hasSelectedItems={selectedSavedSearches?.length > 0}
                    enableAllActions={enableAllActions}
                    handleDeleteFolder={openDeleteFolderModal}
                    handleRemoveItems={openRemoveSegmentsModal}
                    handleItemDelete={openBulkSegmentsDeleteModal}
                    folderType={folderType}
                  />
                )
              }
            />
          </div>
          <div className="segments-page-controller">
            <Card className="segments-page--table-folder">
              <div className="segments-page--search">
                <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
              </div>
              <FlexTable caption="segments" data={segmentsSorted} columns={columns} loading={loading} />
            </Card>
          </div>
          <CommonDeleteModal
            isOpen={isDeleteFolderModalOpen}
            onClose={closeDeleteFolderModal}
            handleDelete={confirmDeleteFolder}
            title="Delete Folder?"
            body="This folder will be deleted, but the Saved Searches inside it will not be affected. You can still access them in Saved Searches section."
            buttonLabel="Delete Folder"
          />
          <CommonDeleteModal
            isOpen={isRemoveSegmentsModalOpen}
            onClose={closeRemoveSegmentsModal}
            handleDelete={confirmRemoveSegments}
            title="Remove Saved Searches?"
            body="Are you sure you want to remove these Saved Searches from the Folder?"
            buttonLabel="Remove Saved Searches"
          />
          <CommonDeleteModal
            isOpen={isBulkSegmentsDeleteModalOpen}
            onClose={closeBulkSegmentsDeleteModal}
            handleDelete={handleBulkSegmentsDelete}
            title="Confirm Delete"
            body="Are you sure you want to delete these Saved Searches?"
            buttonLabel="Delete Saved Searches"
          />
          <DeleteModal
            isOpen={isDeleteSegmentModalOpen}
            onClose={closeDeleteSegmentModal}
            handleDelete={handleSingleSegmentDelete}
            title="Delete Saved Search?"
            body="Deleted saved searches cannot be recovered. Are you sure you want to delete this saved search?"
            list_id={segmentToDelete}
          />
        </>
      )}
    </>
  );
};

FolderSegmentDetail.propTypes = {
  id: PropTypes.string.isRequired,
  folderOwner: PropTypes.string.isRequired,
};

export default FolderSegmentDetail;
