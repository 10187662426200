import { useContext } from "react";
import numeral from "numeral";
import { PropTypes } from "base/react-libs";
import { ActionsMenu, Modal } from "@evertrue/et-components";
import DeleteModal from "apps/search/components/delete-modal";
import BookMarkSharingModal from "apps/search/components/bookmark-sharing-modal";
import BookMarkSearchModal from "apps/search/components/bookmark-search-modal";
import BookMarkUpdateModal from "apps/search/components/bookmark-update-modal";
import BookmarkOrgSharingModal from "apps/search/components/bookmark-org-sharing-modal";
import ExportSource from "apps/export/sources/export-source";
import ExportSearchController from "apps/export/controllers/export-search-controller";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import { BOOKMARK_PERMISSIONS, BOOKMARK_TYPES } from "apps/search/utils/search-helper";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import colors from 'base/colors';
import DynamicListsModal from "apps/search/components/dynamic-lists-modal";
import { deleteBookmark } from "apps/search/utils/delete-bookmark";

const BookmarkActions = ({ id, refetch, userPermission, orgPermission, dsl }) => {
  const { contactFilters, modified } = useContext(GlobalContactSearchContext);
  const [resultCount] = useResultCount(dsl);
  const formattedCount = numeral(resultCount).format("0,0");

  const exportAction = {
    id: 1,
    icon: "cloud-download",
    iconStyle: { color: colors.blue300 },
    label: "Export Records",
    description: `Create a csv file with ${formattedCount} records`,
     
    mountOnClick: ({ is_open, close }) => {
      const handleExport = (params) => {
        ExportSource.standardExportByType("contact", dsl, params);
      };

      return (
        <Modal isOpen={is_open} closeModal={close}>
          <ExportSearchController onExport={handleExport} onClose={close} />
        </Modal>
      );
    },
  };

  const bookmarkAction = {
    id: 2,
    icon: "star",
    iconStyle: { color: colors.yellow150 },
    label: "Save as a New Bookmark",
    description: "Saving new bookmarked searches is suspended",
    disabled: true,
     
    mountOnClick: ({ is_open, close }) => (
      <BookMarkSearchModal isOpen={is_open} onClose={close} type={BOOKMARK_TYPES.SAVE_AS} />
    ),
  };

  const copyAction = {
    id: 3,
    icon: "star",
    iconStyle: { color: colors.yellow150 },
    label: "Copy Bookmark",
    description: "Copy this shared bookmark to your bookmarks",
     
    mountOnClick: ({ is_open, close }) => (
      <BookMarkSearchModal isOpen={is_open} onClose={close} type={BOOKMARK_TYPES.COPY} />
    ),
  };

  const deleteAction = {
    id: 4,
    icon: "trash",
    label: "Delete Bookmark",
     
    mountOnClick: ({ is_open, close }) => (
      <DeleteModal
        isOpen={is_open}
        onClose={close}
        handleDelete={deleteBookmark}
        title="Delete Bookmarked Search?"
        body="Are you sure you want to delete this bookmarked search? This action cannot be undone."
        list_id={id}
      />
    ),
  };

  const disabledDeleteAction = { ...deleteAction, disabled: true };

  const updateAction = {
    id: 5,
    icon: "arrow-up",
    iconStyle: { color: colors.blue300 },
    label: "Update Bookmark",
    description: "Updating bookmarked searches is suspended",
    disabled: true,
     
    mountOnClick: ({ is_open, close }) => (
      <BookMarkUpdateModal id={id} filters={contactFilters} refetch={refetch} isOpen={is_open} onClose={close} />
    ),
  };

  const shareAction = {
    id: 6,
    icon: "share",
    iconStyle: { color: colors.purple300 },
    label: "Share Bookmark",
    description: "Share with another EverTrue user",
     
    mountOnClick: ({ is_open, close }) => <BookMarkSharingModal id={id} isOpen={is_open} onClose={close} />,
  };

  const orgShareAction = {
    id: 7,
    icon: "share",
    iconStyle: { color: colors.purple300 },
    label: "Share Bookmark",
    description: "Share with another EverTrue user",
     
    mountOnClick: ({ is_open, close }) => <BookmarkOrgSharingModal id={id} isOpen={is_open} onClose={close} />,
  };

  const listAction = {
    id: 8,
    icon: "segment",
    iconStyle: { color: colors.green200 },
    label: "Create Dynamic List",
    description: "Creation of new dynamic lists is suspended",
    disabled: true,
     
    mountOnClick: ({ is_open, close }) => (
      <DynamicListsModal isOpen={is_open} onClose={close} filters={contactFilters} />
    ),
  };

  const disabledShareAction = { ...shareAction, disabled: true };

  const getMenuOptions = () => {
    if (userPermission === BOOKMARK_PERMISSIONS.OWNER && modified) {
      return [exportAction, disabledShareAction, updateAction, bookmarkAction, listAction, disabledDeleteAction];
    } else if (userPermission === BOOKMARK_PERMISSIONS.OWNER && !modified) {
      return [exportAction, shareAction, listAction, deleteAction];
    } else if (userPermission === BOOKMARK_PERMISSIONS.VIEW && modified) {
      return [
        exportAction,
        ...(orgPermission === BOOKMARK_PERMISSIONS.VIEW ? [disabledShareAction] : []),
        bookmarkAction,
        listAction,
      ];
    } else if (userPermission === BOOKMARK_PERMISSIONS.VIEW && !modified) {
      return [
        exportAction,
        ...(orgPermission === BOOKMARK_PERMISSIONS.VIEW ? [orgShareAction] : []),
        copyAction,
        listAction,
      ];
    } else if (userPermission === BOOKMARK_PERMISSIONS.EDIT && modified) {
      return [
        exportAction,
        ...(orgPermission === BOOKMARK_PERMISSIONS.VIEW ? [disabledShareAction] : []),
        updateAction,
        bookmarkAction,
        listAction,
      ];
    } else if (userPermission === BOOKMARK_PERMISSIONS.EDIT && !modified) {
      return [
        exportAction,
        ...(orgPermission === BOOKMARK_PERMISSIONS.VIEW ? [orgShareAction] : []),
        copyAction,
        listAction,
      ];
    } else {
      return [exportAction, listAction];
    }
  };

  return <ActionsMenu options={getMenuOptions()} />;
};

BookmarkActions.propTypes = {
  id: PropTypes.string.isRequired,
  userPermission: PropTypes.oneOf(Object.keys(BOOKMARK_PERMISSIONS)).isRequired,
  orgPermission: PropTypes.oneOf([null, BOOKMARK_PERMISSIONS.VIEW]),
  refetch: PropTypes.func,
  dsl: PropTypes.object,
};

export default BookmarkActions;
