module.exports = do ->
   $ = require("jquery")
   {createFactory} = require("base/new-utils")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   ProfilePageController = require("apps/profile/controllers/profile-page-controller")
   ProfileGiftDetailController = createFactory require("apps/profile/controllers/profile-gift-detail-controller").default

   _trackContentViewed = (key) ->
      EverTrue.track.set "profile_content_viewed", {type: key, referrer: "person"}

   class ProfileRouter extends BaseRouter
      routes:
         # detail pages
         "contact/:id/gifts": "gifts"
         # root profile pages
         "contact/:id/:tab": "tab"
         "contact/:id/bio": "bio"
         "contact/:id/posts": "posts"
         "contact/:id/giving": "giving"
         "contact/:id/notes": "notes"
         "contact/:id/proposals": "proposals"
         "contact/:id/enrichment": "enrichment"
         "contact/:id": "profile"
         "contact/:id/task": "task"
         "contact/:id?*splat": "profile"

      beforeRoute: ->
         EverTrue.execute("setBrowserTitle", "Browse")
         $(document).scrollTop(0)

      profile: (id) ->
         EverTrue.track.set "profile", {type: "person"}
         _trackContentViewed("profile_section_summary")

         @currentView = ProfilePageController id: id, key: id
         EverTrue.page.mount(@currentView)

      tab: (id, tab) ->
         _trackContentViewed("profile_section_#{tab}")
         @currentView = ProfilePageController({id: id, type: tab})
         EverTrue.page.mount(@currentView)

      gifts: (id) ->
         @currentView = ProfileGiftDetailController
            id: id
            type: "gifts"
            title: "Gift Transactions"
            section: "giving"
         EverTrue.page.mount(@currentView)
