import $ from 'jquery';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import FixedTableHeader from 'components/tables/fixed-table-header';
import FixedTableRows from 'components/tables/fixed-table-rows';
import UrlSource from 'apps/layout/sources/url-source';
import EverTrue from 'app';

let scrollTimeout = null;

const FixedTableBody = ({
  data = [],
  columns = [],
  loading = false,
  emptyMessage = '',
  onRowClick = () => {},
  columnWidth,
  footer = null,
}) => {
  const scrollPos = EverTrue.UrlManager.get('scrollpos');
  const [preventAutoScroll, setPreventAutoScroll] = useState(false);
  const tableBodyRef = useRef(null);

  useEffect(() => {
    if (!loading && !preventAutoScroll && scrollPos) {
      $(tableBodyRef.current).scrollTop(scrollPos);
      setPreventAutoScroll(true);
    }
  }, [loading, preventAutoScroll, scrollPos]);

  useEffect(() => {
    return () => {
      UrlSource.clear('scrollpos');
    };
  }, []);

  const handleScroll = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => UrlSource.change({ scrollpos: $(tableBodyRef.current).scrollTop() }), 100);
  };

  return (
    <div>
      {/* Table Header - Fixed Vertically - No auto scrolling */}
      <div className="fixed-table--horizontal">
        <table className="fixed-table--header">
          <FixedTableHeader columns={columns} columnWidth={columnWidth} />
        </table>
      </div>

      {/* Table Body - Scrolls vertically with default browser behavior */}
      <div className="fixed-table--vertical" ref={tableBodyRef} onScroll={handleScroll}>
        <table className="fixed-table--body">
          <FixedTableRows
            data={data}
            loading={loading}
            emptyMessage={emptyMessage}
            columnWidth={columnWidth}
            onRowClick={onRowClick}
          />
        </table>
        {footer && <div className="fixed-table--footer">{footer}</div>}
      </div>
    </div>
  );
};

FixedTableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.node,
  onRowClick: PropTypes.func,
  columnWidth: PropTypes.number,
  footer: PropTypes.any,
};

export default FixedTableBody;
