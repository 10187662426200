_ = require("underscore").default
$ = require("jquery")

class Environment
   sandbox: "env_sandbox"
   pusher_keys:
      stage: "639a83692f3cfd752407"
      production: "ca252307eefecb2f069a"

   config:
      app_keys:
         givingtree: "815e8d01be8f78a41d1c71eb652b8be124b89058b74d284c6bb752a034dbb301"
      sentryPath: "https://9ada1553de15488e84c8455c28dd5e6a@app.getsentry.com/26426"
      application_ids:
         givingtree: 6
         community: 5
         volunteers: 35
      contentful:
         space_id: "gkrq9evynog3"

   getContentfulToken: ->
      contentful_token = if @getEnv() is "production" then "jpFvz3L_8Ok-7BPW4YBhxdo7fYQ8tIemPNm_-mLFTNQ" else "mzk-PbbhnkzLI7smJLTGOnK-MDbJQhY1vchFxhz65Fw"
   getThankviewClientKey: ->
      thankview_client_key = "JZAOf3ygE2j6y8HpCY7xyXd2VoBMwnRfHG48Axxw4aM="
   getThankviewClientSecret: ->
      thankview_client_secret = "+3zEWHBsp4N3N/8rxCaeEza0rXeBqHZxZhT1dl/P144="

   getLDToken: ->
     launch_darkly_token = if @getEnv() is "production" then "66e07dc8faee100faf392388" else "66e07dd97d9438100bcfce07"
   #  client side id does not need to be kept secret - https://docs.launchdarkly.com/sdk/concepts/client-side-server-side#client-side-id
   getBaseEnvironment: ->
      urlParts = try window.location.host.split(".").reverse()[2].split("-")
      if _.size(urlParts) > 1 then urlParts[0] else "production"

   getSandbox: ->
      localStorage.getItem(@sandbox)

   getApiBase: ->
      apiPrefix = if @getEnv() is "production" then "" else "stage-"
      "https://#{apiPrefix}api.evertrue.com/"

   getGiveBase: ->
      prefix = if @getEnv() is "production" then "" else "stage-"
      "https://#{prefix}give.evertrue.com/"

   getConsoleBase: ->
      prefix = if @getEnv() is "production" then "" else "stage-"
      "https://#{prefix}console.evertrue.com/"

   getAccountsBase: ->
      prefix = if @getEnv() is "production" then "" else "stage-"
      "https://#{prefix}accounts.evertrue.com/"
   
   getThankviewBase: ->
      "https://thankview.com/api/"

   getApplicationId: (app) ->
      ids = @config.application_ids
      ids[app] || null

   getEnv: ->
      url_env = @getBaseEnvironment()
      if url_env in ["production", "stage"] then url_env
      else @getSandbox() || url_env

   setEnvironmentData: ->
      if @isDevelopment()
         env = if @getEnv() is "production" then "Production" else "Stage"
         @config.environment_data = env
         @config.pusher_key = @pusher_keys[env.toLowerCase()]
      else
         @config.environment_data = undefined
         @config.pusher_key = @pusher_keys.production

   setSandbox: (env) ->
      if env != @getBaseEnvironment()
         localStorage?.setItem(@sandbox, env)
      else
         localStorage?.removeItem(@sandbox)

   switchEnvironments: ->
      if @config.environment_data != "Production"
         @setSandbox("production")
      else
         @setSandbox("stage")

   isDevelopment: ->
      @getBaseEnvironment() != "production"

   isTest: ->
      process.env.NODE_ENV == "test"

   configure: (callback) ->
      location = window.location
      urlOrigin = location.origin || (location.protocol + "//" + location.host)
      environment = @getBaseEnvironment()

      _.extend @config,
         apiBase: @getApiBase()
         contentful_token: @getContentfulToken()
         thankview_client_key: @getThankviewClientKey()
         thankview_client_secret: @getThankviewClientSecret()
         launch_darkly_token: @getLDToken()
         wwwBase: urlOrigin + "/"
         giveBase: @getGiveBase()
         consoleBase: @getConsoleBase()
         thankviewBase: @getThankviewBase()
         beta_gating: (environment != "production")
         gate_warning: (environment != "production")
         error_logging: (environment != "local")
         prod_analytics_tracking: (environment == "production")
         stage_analytics_tracking: (environment != "production")
         performance_testing: (environment != "production")
         show_internal_users: (environment != "production")
         disable_fs_console: (environment != "production")
         debug_mode: (environment != "production")
         environment: @getSandbox() || environment
         base_environment: environment
         switch_environment: (environment in ["dev", "local"])
         is_development: @isDevelopment()

      if @getBaseEnvironment() == "local"
         $.getJSON "/scripts/config/local_env.json", (data) =>
            env = data.environment
            _.extend(@config, data.configs[env]) if (env)
            @setEnvironmentData()
            callback?(@config)
      else
         callback?(@config)

      @setEnvironmentData()
      @config

module.exports = new Environment()

