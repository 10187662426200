module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   OrgSource = require("base/org/org-source").default
   TopFieldsSource = require("apps/profile/sources/top-fields-source").default
   GateSource = require("apps/layout/sources/gate-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   PropertySource = require("apps/contact/sources/property-source")
   PropertyStore = require("apps/layout/stores/property-store")

   MAX_FIELDS = 5
   _gated = (feature) ->
      fn = -> FeatureStore.hasFeature(feature)

   _gatedProspectStatus = ->
      !_gated("multiple_assignee")() && !_gated("rm_assigments")

   _custom_field_defaults = [
      "status"
      "prospectstatus"
      "volunteer"
      "volsolicitor"
      "vol_solicitor"
      "volunteersolicitor"
      "constituency"
      "constituencies"
      "constituentcode"
      "consttype"
      "ethnicity"
      "gender"
      "atheltics"
      "activities"
   ]

   _top_field_options = [
      {label: "Remote ID", value: "remote_id", group: "Donor Info", default: true}
      {label: "Gender", value: "properties.gender", group: "Donor Info"}
      {label: "Ethnicity", value: "properties.ethnicity", group: "Donor Info"}
      {label: "Constituency", value: "constituencies", group: "Donor Info", default: true}
      {label: "Your Capacity Score", value: "giving.capacity_score", group: "Donor Info"}
      {label: "Your Engagement Score", value: "giving.engagement_score", group: "Donor Info"}

      {label: "Solicitation Codes", value: "solicitation_codes", group: "Donor Status", default: true}
      {label: "Prospect Status", value: "prospect_statuses", group: "Donor Status", default: true, gated: _gatedProspectStatus}
      {label: "Donor Score", value: "giving.donor_score", group: "Donor Status"}

      {label: "Athletics", value: "sports", group: "Education", default: true}
      {label: "Activities", value: "extracurricular_activities", group: "Education"}
   ]

   _getDefaults = ->
      top_fields: []
      top_field_options: []
      default_top_fields: []
      fetched_top_fields: false



   createStore "TopFieldsStore",
      getInitialState: ->
         _getDefaults()

      firstListenerDidMount: ->
         if !@getState("fetched_top_fields")
            TopFieldsSource.fetchTopFields()

      registerActions: ->
         @on TopFieldsSource.actions.fetchedTopFields, @respondToFieldChange
         @on TopFieldsSource.actions.updatedTopFields, @respondToFieldChange
         @on PropertySource.actions.fetchedSchema, @respondToFetchedSchema
         @on GateSource.actions.fetchedGates, @respondToGates

         @on OrgSource.actions.newOrg, =>
            @setState _getDefaults()

      respondToFieldChange: (applied_fields) ->
         @setState
            fetched_top_fields: true
            top_fields: applied_fields || []

      respondToFetchedSchema: ->
         @setTopFieldOptions()

      respondToGates: ->
         @setTopFieldOptions()

      setTopFieldOptions: ->
         global_options = _.cloneData(_top_field_options)
         global_options = _.filter _top_field_options, (f) -> !f.gated || f.gated()

         custom_fields = _.filter PropertyStore.getCustomFields(), (field) -> field.visible
         options = global_options.concat _.map custom_fields, (field, key) ->
            {label: field.description, value: "customs.#{field.list_name}", group: "Custom Fields"}

         @setState
            top_field_options: options
            default_top_fields: @getDefaultFields(options)

      getDefaultFields: (options) ->
         default_fields = _.filter options, (field) -> field.default
         # If defaults are gated, add custom fields based on keys,
         # if no matches then fill in w/ other fields
         if default_fields.length < MAX_FIELDS
            [top_fields, other] = _.partition options, (field) ->
               _.contains(_custom_field_defaults, field.value?.replace("customs.", "").replace("_list", ""))
            default_fields = default_fields?.concat(top_fields)
            default_fields = default_fields.concat(other)

         # Only need 5 Values
         _.first(_.uniq(_.pluck(default_fields, "value")), MAX_FIELDS)

      api:
         hasEditableFields: ->
            _.size(@getState("top_field_options")) > MAX_FIELDS

         getSelectedTopFields: (include_default=true) ->
            options = @getState("top_field_options")
            top_fields = @getState("top_fields")

            if _.isEmpty(top_fields) && include_default
               top_fields = @getState("default_top_fields")

            _.compact _.map top_fields, (field) ->
               default_option = _.findWhere options, {value: field}
               if field
                  {label: default_option?.label, value: field, group: default_option?.group}

         getTopFieldOptions: ->
            selected = _.pluck @getSelectedTopFields(), "value"
            _.filter @getState("top_field_options"), (field) ->
               !_.contains(selected, field.value)

         getAllForContact: (contact_object) ->
            fields = @getState("top_field_options")
            @getForContact(contact_object, fields)

         getTopForContact: (contact_object) ->
            fields = @getSelectedTopFields()
            @getForContact(contact_object, fields)

         getForContact: (contact_object, fields) ->
            top_fields = @getState("top_fields")
            _.compact _.map fields, (field) ->
               if field.value == "remote_id"
                  field_val = _.findWhere(contact_object.identities, {type: "remote_id"})
                  display_value = [field_val?.value] if field_val?.value
               else
                  field_path = field.value?.split?(".")
                  field_val = _.reduce field_path,((memo, path) -> memo?[path]), contact_object
                  if _.contains(field_path, "properties")
                     field_val = [{properties: field_val}]
                  else if _.isObject(field_val) && !_.isArray(field_val)
                     field_val = _.toObject(_.last(field_path), field_val)

                  display_value = _.flatten _.compact _.map _.makeArray(field_val), (value_list) ->
                     _.compact _.map value_list, (value_object) ->
                        value = value_object?.value

                        if !_.isUndefined(value)
                           if value_object?.object_type == "currency"
                              value = _.currencyFormatter(value)
                           else if value_object?.object_type == "boolean"
                              value = if value then "Y" else "N"

                        if _.isString(value)
                           if !_.isEmpty(value.trim()) then value.trim()
                        else value
                  
                  display_value && display_value.length > 0 && display_value.sort()

               if field.label
                  label: field.label
                  key: field.value,
                  value: if _.isEmpty(display_value) then undefined else display_value
                  group: field.group
                  selected: _.contains(top_fields, field.value)

