import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import ListController from 'apps/lists/controllers/list-controller';
import ListsPageController from 'apps/lists/controllers/lists-page-controller';

class ListRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      lists: 'lists',
      'list/:id': 'show',
      'list/:id/:view': 'show',
    };
  }

  lists() {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'Lists');
    EverTrue.track.set('viewed', { type: 'lists' });
    EverTrue.track.setReferrer('lists');

    this.currentView = <ListsPageController />;
    EverTrue.page.mount(this.currentView);
  }

  show(id, view) {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'List');
    EverTrue.track.set('viewed', { type: 'list' });
    EverTrue.track.setReferrer('list');
    if (view !== 'map') {
      view = 'table';
    }

    this.currentView = <ListController list_id={id} view={view} />;
    EverTrue.page.mount(this.currentView);
  }
}

ListRouter.initClass();
export default ListRouter;
