import { Icon, Loading } from '@evertrue/et-components';
import PropTypes from 'prop-types';

const UploadedFiles = ({ files, removeFile }) => {
  const getFileType = (file) => {
    const regex = {
      word: /(docx|doc|document)$/i,
      image: /(jpg|jpeg|png|gif)$/i,
      video: /(mp4|mov|avi|wmv|mkv)$/i,
      powerpoint: /(pptx|ppt)$/i,
      pdf: /pdf$/i,
      audio: /(mp3|wav)$/i,
      excel: /(xlsx|xls|xlsm)$/i,
      zip: /(zip)$/i,
    };
    for (const key in regex) {
      if (regex[key].test(file.type)) {
        return key;
      }
    }
    return 'alt';
  };

  return (
    <>
      <div className="uploaded-files--container">
        {files
          .filter((file) => !file.isInline)
          .map(({ id, file, url }) => (
            <div key={id} className="uploaded-file">
              <div style={{ display: 'flex' }}>
                <Icon icon={`file-${getFileType(file)}`} iconKit="gt2"></Icon>
                <p style={{ marginLeft: '10px' }}>{file.name}</p>
              </div>
              {!!url ? (
                <Icon
                  icon="close"
                  onClick={() => removeFile(id)}
                  isReachable={true}
                  onKeyUp={() => removeFile(id)}
                ></Icon>
              ) : (
                <div style={{ position: 'relative' }}>
                  <Loading size={'small'} />
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

UploadedFiles.propTypes = {
  files: PropTypes.array,
  removeFile: PropTypes.func,
};

export default UploadedFiles;
