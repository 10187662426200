import { PropTypes } from 'base/react-libs';
import { useIdentity } from 'base/identity-resolver';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { moveConstituentToJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from '@evertrue/et-components';
import EverTrue from 'app';
import FormField from 'components/forms/form-field';
import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import { Formik } from 'formik';
import { RemoveFromJourneySchema } from './schema/remove-from-journey-schema';
import CharacterCounter from 'apps/interactions/components/character-counter';
import { editTaskReason } from 'apps/tasks/utils/utils';

const ReasonOptions = [
  { label: 'Adjusting cultivation strategy', value: 1 },
  { label: 'Event/trip/giving day has concluded', value: 2 },
  { label: 'Opted out', value: 3 },
  { label: 'Unresponsive', value: 4 },
  { label: 'Bad contact information', value: 5 },
  { label: 'Transition to another portfolio', value: 6 },
  { label: 'Other', value: 7 },
];

const RemoveFromJourneyModal = ({
  isOpen,
  onClose,
  contact = {},
  currentJourneys = [],
  optionalStateManagementFunction = () => {},
  refreshProfile = () => {},
}) => {
  const { journey_id, journey_name } = currentJourneys[0] || {};
  const { user, org } = useIdentity();

  const initialValues = {
    reason: null,
    reasonDetails: '',
    currentJourney: { label: journey_name, value: journey_id },
  };

  const handleSubmit = async (values) => {
    try {
      const { currentJourney } = values;
      // get the reason for task removal
      const reason = editTaskReason(values.reason, values.reasonDetails);
      const journeyId = currentJourney.value;
      await moveConstituentToJourney(journeyId, contact.id, user.id, org.id, null, null, reason);
      trackJourneysAction('remove_cadence');
      optionalStateManagementFunction('removeContact', { contactIdToRemove: contact.id });
      refreshProfile((prev) => !prev);
      onClose();
    } catch (e) {
      EverTrue.Alert.error('Error removing constituent from cadence.');
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={RemoveFromJourneySchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched, isValid }) => (
          <>
            <ModalHeaderWithActions
              title={'Remove from Cadence'}
              closeModal={onClose}
              onSubmit={handleSubmit}
              submitLabel="Remove"
              disableSubmit={!isValid}
            />
            <ModalBody scrollable={false}>
              <p className="journey-modal--warning">
                You will be removing the constituent who has indicated they no longer want contact or are identified as
                bad fits for the program.
              </p>
              <p>Are you sure you want to remove this constituent completely from their cadence?</p>

              <JourneyContactCard contact={contact} avatarSize={60} />

              <FormField label="Current Cadence" boldLabel={true} inlineLabel={false}>
                <ListboxSelect
                  emptyValue="Select Cadence"
                  size="medium"
                  options={currentJourneys.map(({ journey_name, journey_id }) => ({
                    label: journey_name,
                    value: journey_id,
                  }))}
                  onChange={(v) => setFieldValue('currentJourney', v)}
                  value={values.currentJourney}
                  disabled={currentJourneys.length === 1} // no need to select if only one journey is present
                  label="cadences"
                />
              </FormField>

              <FormField
                label="Reason"
                required={true}
                boldLabel={true}
                inlineLabel={false}
                error={touched.reason && errors.reason}
              >
                <ListboxSelect
                  emptyValue="Select Reason..."
                  showLabelIfMultipleSelected={true}
                  size="medium"
                  options={ReasonOptions}
                  onChange={(v) => setFieldValue('reason', v)}
                  value={values.reason}
                  label="reasons"
                />
                {touched.reason && errors.reason && <div className="error-message">{errors.reason}</div>}
              </FormField>

              {values.reason && values.reason.value === 7 && (
                <FormField
                  label="Details"
                  required={true}
                  inlineLabel={false}
                  error={touched.reasonDetails && errors.reasonDetails}
                >
                  <textarea
                    type="text"
                    style={{ resize: 'none' }}
                    value={values.reasonDetails}
                    onChange={handleChange('reasonDetails')}
                  />
                  <CharacterCounter limit={150} count={values.reasonDetails.length} />
                  {touched.reasonDetails && errors.reasonDetails && (
                    <div className="error-message">{errors.reasonDetails}</div>
                  )}
                </FormField>
              )}
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

RemoveFromJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
  currentJourneys: PropTypes.array,
  optionalStateManagementFunction: PropTypes.func,
  refreshProfile: PropTypes.func,
};

export default RemoveFromJourneyModal;
