import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useCallback } from "react";

const Timer = ({ startTime, className = "dialer--timer" }) => {
  const [count, setCount] = useState(new Date() - startTime);

  // takes in a number of milliseconds and formats it to time-readable string
  // 2000 ----> "00:02"
  const formatTimeToSeconds = useCallback((ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    return minute + ":" + second;
  }, []);

  // memoize the stringification process
  const callDuration = useMemo(() => formatTimeToSeconds(count), [count, formatTimeToSeconds]);

  useEffect(() => {
    if (!startTime) return;

    // every second, calculate the diff between current time and initial start time
    const id = setInterval(() => {
      setCount(new Date() - startTime);
    }, 1000);

    return () => clearInterval(id);
  }, [count, startTime]);

  if (startTime) return <div className={className}>{callDuration}</div>;
  else return null;
};

Timer.propTypes = {
  startTime: PropTypes.any,
  className: PropTypes.string,
};

export default Timer;
