import React from 'react';
import _ from 'underscore';
import get from 'lodash.get';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import KpiCard from './widget-kpi-card';
import useFetchResults from 'apps/dashboard/hooks/use-fetch-results';
import { ProgressBar } from '@evertrue/et-components';

const KpiMetricCard = ({
  template_json = {},
  title,
  query = '',
  variables = {},
  optionVariableName,
  options = [],
  fetchStat = () => {},
  fiscal_year,
  selectedOption = {},
  onOptionChange = () => {},
}) => {
  const { result, loading } = useFetchResults(
    query,
    optionVariableName,
    template_json.has_options,
    variables,
    selectedOption.value,
    fetchStat
  );

  const mainStat = get(result, template_json.main_stat ? template_json.main_stat.prop : null, null);
  const mainStatLabel = template_json.main_stat ? template_json.main_stat.label : null;
  const mainStatDataType = template_json.main_stat ? template_json.main_stat.data_type : null;

  const subStat = get(result, template_json.sub_stat ? template_json.sub_stat.prop : null, null);
  const subStatLabel = template_json.sub_stat ? template_json.sub_stat.label : null;
  const subStatDataType = template_json.sub_stat ? template_json.sub_stat.data_type : null;

  const mainGoal = get(result, template_json.main_goal ? template_json.main_goal.prop : null, null);
  const mainGoalLabel = template_json.main_goal ? template_json.main_goal.label : null;
  const mainGoalDataType = template_json.main_goal ? template_json.main_goal.data_type : null;

  const subGoal = get(result, template_json.sub_goal ? template_json.sub_goal.prop : null, null);
  const subGoalLabel = template_json.sub_goal ? template_json.sub_goal.label : null;
  const subGoalDataType = template_json.sub_goal ? template_json.sub_goal.data_type : null;

  const hasGoals = !!mainGoal || !!subGoal;

  const displayValue = (val, dataType) =>
    dataType === 'currency' && _.isNumber(val) ? numeral(val).format('$0[.]0a') : val;

  const getPercentage = (stat, goal) => {
    if (stat >= goal) {
      return 1;
    } else {
      return stat / goal;
    }
  };

  return (
    <KpiCard
      loading={loading}
      title={title}
      fiscal_year={fiscal_year}
      icon={template_json.icon}
      color={template_json.theme_color}
      options={options}
      onOptionChange={onOptionChange}
      selectedOption={selectedOption}
    >
      <div className="hub-kpicard--content">
        <div className="hub-kpicard--sub-content metric">
          <div className="hub-kpicard--main-stat">
            {_.isNull(mainStat) ? '-' : displayValue(mainStat, mainStatDataType)}
          </div>
          <div className="hub-kpicard--main-stat-label">{mainStatLabel}</div>
          {hasGoals ? (
            <div className="hub-kpicard--footer">
              {_.isNull(mainGoal) ? (
                'N/A'
              ) : (
                <React.Fragment>
                  <span>
                    <span className="hub-kpicard--footer-stat-label">{mainGoalLabel}</span>
                    {': '}
                    <span className="hub-kpicard--footer-stat">{displayValue(mainGoal, mainGoalDataType)}</span>
                  </span>
                  <ProgressBar
                    width={100}
                    percent={getPercentage(mainStat, mainGoal)}
                    progressColor={`#${template_json.theme_color}`}
                  />
                </React.Fragment>
              )}
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="hub-kpicard--sub-content metric">
          <div className="hub-kpicard--main-stat">
            {_.isNull(subStat) ? '-' : displayValue(subStat, subStatDataType)}
          </div>
          <div className="hub-kpicard--main-stat-label">{subStatLabel}</div>
          {hasGoals ? (
            <div className="hub-kpicard--footer">
              {_.isNull(subGoal) ? (
                'N/A'
              ) : (
                <React.Fragment>
                  <span>
                    <span className="hub-kpicard--footer-stat-label">{subGoalLabel}</span>
                    {': '}
                    <span className="hub-kpicard--footer-stat">{displayValue(subGoal, subGoalDataType)}</span>
                  </span>
                  <ProgressBar
                    width={100}
                    percent={getPercentage(subStat, subGoal)}
                    progressColor={`#${template_json.theme_color}`}
                  />
                </React.Fragment>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </KpiCard>
  );
};

KpiMetricCard.propTypes = {
  title: PropTypes.string,
  variables: PropTypes.object,
  optionVariableName: PropTypes.string,
  options: PropTypes.array,
  fetchStat: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  template_json: PropTypes.object,
  fiscal_year: PropTypes.number,
  onOptionChange: PropTypes.func,
  selectedOption: PropTypes.object,
};

export default KpiMetricCard;
