import PropTypes from 'prop-types';
import EverTrue from 'app';
import AdminNav from './admin-nav';

const AdminWrapper = ({ active, title, subtitle, children }) => {
  return (
    <div className="admin-page">
      <div className="admin-page--header">
        <div className="main-sub-header">
          <h3 className="inline">Feature Flags Admin</h3>

          {EverTrue.config.environment_data && (
            <span className="badge-warning">{EverTrue.config.environment_data}</span>
          )}

          <div>{subtitle}</div>
        </div>
        <AdminNav active={active} />
      </div>

      <div className="admin-page--body">{children}</div>
    </div>
  );
};

AdminWrapper.propTypes = {
  active: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default AdminWrapper;
