import { fetchFromGraphql } from 'entities/helpers/gqlCall';
import prospectQueries from '../gql/prospect-queries';

export const fetchStageAndProspectDetails = async (solicitorContactId, prospectContactId) => {
  const query = prospectQueries.fetchStagesAndProspectDetails;
  const queryVars = { solicitorContactId, prospectContactId };
  const { stages } = await fetchFromGraphql(query, queryVars, 'stages');

  return stages;
};

export const checkContactIsSolicitor = async (solicitorContactId, prospectContactId) => {
  const query = prospectQueries.checkContactIsSolicitor;
  const queryVars = { solicitorContactId, prospectContactId };
  const { checkContactIsSolicitor } = await fetchFromGraphql(query, queryVars, 'checkContactIsSolicitor');

  return checkContactIsSolicitor;
};
