import { useState } from "react";
import LinkHelper from "entities/helpers/link-helper";

const LIMITER = "CHUNK_TERMINATOR";
const CHUNK = "CHUNK";
const DONE = "DONE";

/**
 * This component can be used for all the AI related streaming API calls that return a list.
 */
const useListDataStream = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [response, setResponse] = useState([]);
  const [metadata, setMetadata] = useState({});

  let previousChunkIndex = 0;

  const openListDataStream = (options) => {
    setIsStreaming(true);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "text";

      xhr.onreadystatechange = (_e) => {
        if (xhr.readyState === 3) {
          const isCached = xhr.getResponseHeader("et-cached-response");
          if (isCached) {
            // Ignore the stream logic for cached responses
            // Parsing a cached response will break the streaming logic
          } else {
            const { tasks = [] } = processChunk(xhr.responseText);
            setResponse(tasks);
          }
        }
        if (xhr.readyState === 4) {
          // Check again for cached response and parse if needed
          const isCached = xhr.getResponseHeader("et-cached-response");
          if (isCached) {
            const { tasks = [] } = processChunk(xhr.responseText);
            setResponse(tasks);
          }
          if (xhr.status === 200) {
            setIsStreaming(false);
            resolve(xhr.response);
          } else {
            setIsStreaming(false);
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      const { method, endpoint, params, headers, data } = options;
      const full_url = LinkHelper.formatLink(endpoint, params);

      xhr.open(method, full_url);
      for (let key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }

      xhr.withCredentials = true;
      xhr.send(data);
    });
  };

  /**
   * Processes a raw chunk of data from an HTTP request and returns an array of data chunks..
   * @param {string} rawHTML
   * @template {string} dataChunk
   * @returns {array<dataChunk>} dataChunks
   */
  function processChunk(rawHTML) {
    const rawChunk = formatChunk(rawHTML);
    const chunks = parseChunk(rawChunk);

    // check to see if the chunk is unparsed
    try {
      const parsedChunk = JSON.parse(chunks[0]);
      return parsedChunk;
    } catch (e) {
      const fragments = chunks.map(({ response = "" }) => response);
      const data = fragments.join("");
      return { data };
    }
  }

  /**
   * onreadystatechange always returns the full stream:
   * This finds the delta between HTTP streamed chunks
   * @param {string} rawHTML
   * @returns {string} rawChunk
   */
  function formatChunk(rawHTML) {
    const rawChunk = rawHTML.slice(previousChunkIndex);
    previousChunkIndex = rawHTML.length;
    return rawChunk;
  }

  /**
   * Parses a chunk of a request by removing the limiter and returning an array of data chunks
   * @param {string} chunk
   * @template {Object} dataChunk
   * @returns {array<dataChunk>} dataChunks
   */
  function parseChunk(chunk) {
    const dataChunks = [];
    const segments = chunk.split(LIMITER);
    for (let segment of segments) {
      if (segment.length !== 0) {
        try {
          const { data = {}, event, metadata = {} } = JSON.parse(segment);
          if (event === CHUNK && Object.keys(metadata).length > 0) {
            setMetadata(metadata);
          }
          // handle summary data
          if (event === CHUNK) {
            dataChunks.push(data);
          }
          // handle full response
          if (event === DONE && !!data) {
            dataChunks.push(data.response);
          }
        } catch (e) {
          console.error("Streaming Parse Error", e);
        }
      }
    }
    return dataChunks;
  }

  return {
    openListDataStream,
    isStreaming,
    response,
    metadata,
  };
};

export default useListDataStream;
