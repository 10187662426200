import PropTypes from 'prop-types';
import { Link } from '@evertrue/et-components';
import FacebookEmbeddedPost from 'apps/facebook/components/facebook-embedded-post';
import Popover from 'components/overlays/popover';
import { getVerbByEngagementType } from 'apps/events/utils/engagement-config';

const EngagementCardFacebookTitle = ({ social, post, page }) => {
  const [page_id, post_id] = social?.content?.remote_id?.split('_') || [];
  const content = social?.content || {};
  const engagement = social?.engagement || {};
  let picture = post?.picture || page?.picture_url;
  const name = social?.profile?.name;

  // Facebook returns a file called save_image.php instead of throwing an error
  // which renders as blank, this should default it to the page icon.
  if (picture?.match('safe_image.php')) {
    picture = page?.picture_url;
  }

  return (
    <div>
      <strong className="fs-exclude-data">{name}</strong>
      {getVerbByEngagementType(engagement.type)}
      {page?.page_name ? (
        <span>
          <Link
            title={`Facebook page: ${page?.page_name}`}
            href={`https://www.facebook.com/${page?.remote_id}`}
            target="_BLANK"
          >
            <span className="fs-exclude-data">{page?.page_name}</span>
          </Link>
          's&nbsp;
        </span>
      ) : (
        'your '
      )}
      <Popover
        className="inline"
        trigger={
          <Link title="view post on Facebook" href={`/facebook/${page_id}/posts/${post_id}`}>
            {content.type}
          </Link>
        }
        position="left"
        event="hover"
      >
        <div className="facebook-engagement-card--post">
          <FacebookEmbeddedPost url={content?.public_url} post_id={content?.remote_id} width={350} />
        </div>
      </Popover>
      .
    </div>
  );
};

EngagementCardFacebookTitle.propTypes = {
  post: PropTypes.object,
  page: PropTypes.object,
  social: PropTypes.object,
};

export default EngagementCardFacebookTitle;
