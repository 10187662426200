import moment from 'moment';
import EverTrue from 'app';
import _ from 'underscore';
import { calculateDaysBetweenDates, getDateString, getMomentObjectInTimezone } from 'base/new-utils';

//hardcoded for now, double render on form causing validation to break
const ACTION_TYPES = [
  { value: 'OTHER', label: 'Other' },
  { value: 'RESEARCH', label: 'Research' },
  { value: 'CALL', label: 'Call' },
  { value: 'THANK_VIEW', label: 'ThankView' },
  { value: 'LINKEDIN', label: 'LinkedIn' },
  { value: 'PRINT_MAIL', label: 'Print Mail (e.g. Pledgemine, other)' },
  { value: 'TEXT', label: 'Text' },
  { value: 'EMAIL', label: 'Email' },
];

/**
 *
 * Takes in a string like "2025-01-10"
 * compares the date to today's date and returns an object with relative date information.
 * Note it COMPLETELY ignores time and timezones.
 */
export const daysFromNow = (string) => {
  if (string) {
    const dateString = getMomentObjectInTimezone(string).format('ddd, MMM D, YYYY'); // USE TIMEZONE WHEN RENDERING DATE
    const daysFromNow = calculateDaysBetweenDates(getDateString(), string); // IGNORE TIMEZONE FOR CALCULATION. CAN BE NEGATIVE

    if (daysFromNow === 0) {
      return { date: 'Today', overdue: false };
    } else if (daysFromNow === 1) {
      return { date: 'Tomorrow', overdue: false };
    } else if (daysFromNow > 1) {
      return { date: dateString, overdue: false };
    } else if (daysFromNow < 0) {
      return { date: dateString, overdue: true, style: { color: 'red' } };
    } else {
      return { date: '-' };
    }
  } else return { date: '-' };
};

export const formattedDate = (date) => {
  return moment.unix(date).format('MMM D, YYYY');
};

export const trackTaskAction = (type, condition) => {
  const track = (type) => {
    EverTrue.track.set('tasks', { type });
  };
  const { pathname } = window.location;

  switch (type) {
    case 'complete_task':
      if (pathname === '/tasks') {
        track('complete_task_from_task_screen');
      } else if (pathname.includes('/contact')) {
        track('complete_task_from_profile');
      }
      break;
    case 'edit_task':
      if (pathname === '/tasks') {
        track('edit_task_task_page');
      } else if (pathname.includes('/contact')) {
        track('edit_task_profile');
      }
      break;
    case 'delete_task':
      if (pathname === '/tasks') {
        track('delete_task_task_page');
      } else if (pathname.includes('/contact')) {
        track('delete_task_profile');
      }
      break;
    case 'Completed Tasks':
      if (pathname === '/tasks') {
        track('view_complete_task_task_page');
      } else if (pathname.includes('/contact')) {
        track('view_complete_tasks_profile');
      }
      break;
    case 'Incomplete Tasks':
      if (pathname === '/tasks') {
        track('view_inprogress_tasks_task_page');
      } else if (pathname.includes('/contact')) {
        track('view_inprogress_tasks_profile');
      }
      break;
    case 'All Tasks':
      if (pathname === '/tasks') {
        track('view_all_tasks_task_page');
      } else if (pathname.includes('/contact')) {
        track('view_all_tasks_profile');
      }
      break;
    case 'update_notification':
      if (condition) {
        track('create_task_email_notification');
      } else {
        track('disable_task_email_notification');
      }
      break;
    case 'add_task':
      if (pathname.includes('home')) {
        EverTrue.track.set('home_screen', { type: 'add_task' });
      }
      break;
    case 'create_suggested_task':
      track('create_suggested_task');
      break;
    case 'create_task_from_task_screen':
      track('create_task_from_task_screen');
      break;
    default:
      track(type);
      break;
  }
};

/**
 * Takes optional arguments, destructures, and sets defaults for task form.
 */
export const buildTaskDefaults = (taskObj, user = {}, profileContact) => {
  const { id: userId, name: userName } = user;
  const {
    id,
    title = '',
    description,
    action_type,
    owner_user_id = null,
    current_due_date,
    contact_journey_id,
    contact,
    owner_info: { name = '' } = {},
  } = taskObj;

  const owner = { value: owner_user_id, label: name };
  //if owner is current user add (You) to name
  if (owner_user_id === userId) {
    owner.label = userName + ' (You)';
  } else if (owner_user_id === null) {
    owner.value = userId;
    owner.label = userName + ' (You)';
  }

  const targetContact = getContactData(contact, profileContact);
  const actionType = action_type ? ACTION_TYPES.find(({ value }) => value === action_type) : null;

  const taskDefaults = {
    title,
    description: description || '',
    current_due_date: current_due_date ? current_due_date : getDateString(),
    owner,
    actionType,
    targetContact,
  };

  const suggestedTaskDefaults = {
    title,
    description: description || '',
    current_due_date: getDateString(moment().add(1, 'week').valueOf()),
    owner,
    actionType,
    targetContact,
  };

  return {
    id,
    contact_journey_id,
    taskDefaults,
    suggestedTaskDefaults,
  };
};

/**
 * Destructures form values and creates a payload
 */
export const setTaskPayload = (formValues) => {
  const {
    title,
    description,
    current_due_date,
    targetContact,
    actionType: { value: action_type } = {},
    owner: { value: owner_user_id = null } = {},
  } = formValues;

  // Constuent picker takes null object, so this is the workaround.
  const target_contact_id = targetContact ? targetContact.id : null;

  const payload = {
    title,
    description,
    action_type,
    owner_user_id,
    target_contact_id,
    current_due_date,
    completed_at: null,
    deleted_at: null,
  };

  if (current_due_date) {
    payload.current_due_date = getDateString(current_due_date);
  }
  payload.title = payload.title.trim();
  payload.description = payload.description.trim();

  return payload;
};

// Returns contact data for task to match GQL schema
const getContactData = (taskContactObject, profileContactObject) => {
  if (!_.isEmpty(taskContactObject)) {
    const {
      id,
      contact_attributes: { name_full = '' },
    } = taskContactObject;
    const avatar = taskContactObject.profile_image ? taskContactObject.profile_image.url : '';
    return { id, name: name_full, avatar };
  } else if (!_.isEmpty(profileContactObject)) {
    const { id, name, avatar } = profileContactObject;
    return { id, name, avatar };
  } else {
    return null;
  }
};

export const editTaskReason = (reasonObject, reasonDetails) => {
  const { label } = reasonObject;
  let reason;
  if (reasonDetails && reasonDetails.length)
    reason = reasonDetails; // this means the user supplied an "other" reason and supplied details
  else reason = label; // this is the option they selected from the dropdown menue.
  return reason;
};

const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));

export const arraysMatch = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);
