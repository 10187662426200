import { PropTypes } from 'base/react-libs';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import { ThreeDotActionsMenu } from '@evertrue/et-components';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { getContactEmailAddress, getGqlContactName } from 'apps/journeys/utils/helpers/utils';

import MoveToJourneyModal from 'apps/journeys/components/modals/move-to-journey-modal';
import RemoveFromJourneyModal from 'apps/journeys/components/modals/remove-from-journey-modal';

const ActiveConstituentActions = ({ journey = {}, contactJourney = {}, removeConstituentFromJourney = () => {} }) => {
  const { flagEnabled: hasSignal } = useFeatureFlag('signal');
  const { addDrawer } = useGlobalDrawerProvider();
  const { contact } = contactJourney;
  const email_address = getContactEmailAddress(contact);

  const phoneAction = {
    id: 1,
    onClick: () => {
      trackOutreachAction('open_dialer');
      addDrawer('dialer', {
        contact,
        phones: contact && contact.phones ? contact.phones : [],
        drawerTitle: getGqlContactName(contact) || 'New Phone Call',
        journey,
      });
    },
    label: 'Call Constituent',
    icon: 'call',
  };

  const emailAction = {
    id: 2,
    onClick: () => {
      trackOutreachAction('open_emailer');
      addDrawer('emailer', {
        contact: contact,
        contactId: contact && contact.id,
        drawerTitle: getGqlContactName(contact) || 'New Message',
        recipients: email_address === '' ? [] : [email_address],
      });
    },
    label: 'Email Constituent',
    icon: 'email',
  };

  const moveToCadenceAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <MoveToJourneyModal
        currentJourney={journey}
        isOpen={is_open}
        onClose={close}
        contact={contact}
        optionalStateManagementFunction={(action, { contactIdToRemove }) => {
          removeConstituentFromJourney(contactIdToRemove);
        }}
      />
    ),
    label: 'Move to Another Cadence',
    icon: 'swap-horizontal',
    iconKit: 'gt2',
  };

  const removeAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <RemoveFromJourneyModal
        onClose={close}
        isOpen={is_open}
        contact={contact}
        currentJourneys={[{ journey_name: journey.name, journey_id: journey.id }]} //matching active_journeys object
        optionalStateManagementFunction={(action, { contactIdToRemove }) => {
          removeConstituentFromJourney(contactIdToRemove);
        }}
      />
    ),
    label: 'Remove from Cadence',
    icon: 'cancel-circle',
  };

  const contactOptions = {
    id: 1,
    heading: 'Contact',
    items: [],
  };

  if (hasSignal) contactOptions.items.push(phoneAction);
  if (hasSignal) contactOptions.items.push(emailAction);

  const cadenceOptions = {
    id: 2,
    heading: 'Cadence',
    items: [moveToCadenceAction, removeAction],
  };

  return <ThreeDotActionsMenu options={hasSignal ? [contactOptions, cadenceOptions] : [cadenceOptions]} iconSize={1} />;
};

ActiveConstituentActions.propTypes = {
  journey: PropTypes.object.isRequired,
  contactJourney: PropTypes.object.isRequired,
  removeConstituentFromJourney: PropTypes.func,
};

export default ActiveConstituentActions;
