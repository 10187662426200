module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileAssignmentSource = require("apps/profile/sources/profile-assignment-source").default
   ProfileAssignmentStore = require("apps/profile/stores/profile-assignment-store").default
   {createFactory} = require("base/new-utils")
   ProfileCard = createFactory require("apps/profile/components/profile-card").default
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   Membership = require("apps/profile/components/membership")
   CapacityInfo = createFactory require("apps/profile/components/capacity-info").default
   DonorPotentialScoreInfo = createFactory require("apps/profile/components/donor-potential-score-info").default
   ProfileRecentInteractionsController =  require("apps/profile/controllers/profile-recent-interactions-controller")
   KeyMetrics = require("apps/profile/components/key-metrics")
   SocialActions = createFactory require("apps/profile/components/social-actions").default
   ProfileTopFields = createFactory require("apps/profile/components/profile-top-fields").default
   ProposalSummary = createFactory require("apps/profile/components/proposal-summary").default
   Card = createFactory(require("@evertrue/et-components").Card)
   ProfilePinnedCommentsController = createFactory require("apps/profile/controllers/profile-pinned-comments-controller").default
   AddPinnedCommentButton = createFactory require("apps/profile/components/pinned-comments/add-pinned-comment-button").default
   ProfileAIController = createFactory require("apps/profile/controllers/profile-ai-controller").default

   createComponent "ProfileSummaryController",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      registerStores: ->
         @on FeatureStore, ->
            has_proposals: FeatureStore.hasFeature("proposal_reads")
            has_volunteers: FeatureStore.hasFeature("volunteers")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")
            # does everyone have some form of interactions?

         @on ProfileAssignmentStore, "id", (props) ->
            volunteer_assignment_contacts: ProfileAssignmentStore.getVolunteerAssignmentsForProfile(props.contact.id)
            team_assignment_contacts: ProfileAssignmentStore.getTeamAssignmentsForProfile(props.contact.id)

      componentDidMount: ->
         if @state.has_rm_assignments
            # need to fetch assignment titles to sort the assignments
            ProfileAssignmentSource.fetchAssignmentTitles()
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

      componentDidUpdate: (prevProps, prevState) ->
         if !prevState.has_rm_assignments and @state.has_rm_assignments
            # need to fetch assignment titles to sort the assignments
            ProfileAssignmentSource.fetchAssignmentTitles()
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if !prevState.has_volunteers and @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

      render: ->
         { contact } = @props
         assignees = contact.assignees
         volunteer_assignees = []
         if @state.has_rm_assignments
            assignees = @state.team_assignment_contacts
         if @state.has_volunteers
            volunteer_assignees =  @state.volunteer_assignment_contacts
         total_assignees = _.compact([].concat(assignees, volunteer_assignees))

         div className: "profile-summary contact-profile-v2",
            ProfileCard size: 4,
               KeyMetrics
                  contact: contact
                  totalAssignees: total_assignees

            div className: "profile-summary--lower-wrapper",
               # LEFT COLUMN width: 66.7%
               div className: "profile-summary--column-left",
                  if @props.aiGeneral
                     Card null,
                        ProfileAIController
                           contactId: contact.id

                  Card null,
                     ProfileRecentInteractionsController
                        contact: contact

                  Card null,
                     ProfileCardHeader
                        className: "contact-profile--card-header"
                        title: "Capacity Indicators"
                     CapacityInfo
                        contact: contact

                  Card null,
                     DonorPotentialScoreInfo
                        id: contact.id

               # RIGHT COLUMN width: 33.3%
               div className: "profile-summary--column-right",
                  Card null,
                     ProfilePinnedCommentsController 
                        contact: contact

                  Card null,
                     ProfileCardHeader
                        title: "Social"
                        className: "contact-profile--card-header-small"
                     SocialActions contact: contact

                  Card null,
                     ProfileCardHeader
                        title: "Memberships"
                        className: "contact-profile--card-header-small"
                     Membership contact: contact

                  if @state.has_proposals
                     Card null,
                        ProposalSummary contact: contact

                  Card null,
                     ProfileTopFields contact: contact

