import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { userHasAssignments } from 'apps/home/utils/api-requests/home-page';
import RecommendedActions from './recommended-actions/recommended-actions';
import SavedSearches from './saved-searches/saved-searches';

const NextSteps = ({ user = {}, org }) => {
  const [hasAssignments, setHasAssignments] = useState(false);

  useEffect(() => {
    const checkIfUserHasAssignments = async () => {
      const doesUserHaveAssignments = await userHasAssignments(user, org);
      setHasAssignments(doesUserHaveAssignments);
    };

    checkIfUserHasAssignments();
  }, [user, org]);

  return <>{hasAssignments ? <RecommendedActions /> : <SavedSearches />}</>;
};

NextSteps.propTypes = {
  user: PropTypes.object,
  org: PropTypes.object,
};

export default NextSteps;
