import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'underscore';
import UserCard from 'apps/users/components/user-card';

import Decorator from 'clientDecorator';
import ActivityCardGroup from '../activity-card-group';

const CollaboratorActivity = ({ item = {}, currentUser, onMention }) => (
  <li className="list-activity--feed-item">
    <div className="list-activity--label">
      <strong
        className={classNames({
          'is-empty': _.isEmpty(item.user),
          'text-removed-user': item.user?.removed,
        })}
      >
        {Decorator.User.getName(item.user || {}, currentUser) || ' '}
      </strong>
      <span>
        {' '}
        {item.label} on {moment(item.created_at).format('MMM D')}
      </span>
    </div>
    <ActivityCardGroup
      className="list-activity--target"
      items={item?.activities?.filter((activity) => activity.target_data) || []}
      component={(activity) => <UserCard user={activity.target_data} onClick={(user) => onMention(user)} />}
    />
  </li>
);

CollaboratorActivity.propTypes = {
  item: PropTypes.object.isRequired,
  currentUser: PropTypes.number.isRequired,
  onMention: PropTypes.func.isRequired,
};

export default CollaboratorActivity;
