import { PropTypes } from 'base/react-libs';
import { percentOfTasksComplete } from 'apps/journeys/utils/helpers/counters';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import { useState, useEffect, useMemo } from 'react';
import TaskRowDetails from './task-row-details';
import StepRowSummary from './step-row-summary';

const StepRow = ({ step = {}, isFinalStep }) => {
  const { navigateFrom, setNavigateFrom } = useGlobalJourneyProvider();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => setIsExpanded(false), [step]);

  const string = JSON.stringify(step.tasks);
  const completionPercentage = useMemo(() => {
    if (string) return percentOfTasksComplete(step.tasks);
    else return 0;
  }, [step, string]);

  useEffect(() => {
    if (navigateFrom) {
      if (navigateFrom?.step_number === step?.step_number && navigateFrom?.day_number === step?.day_number) {
        setIsExpanded(true);
        setNavigateFrom(null);
      }
    }
  }, [navigateFrom, setIsExpanded, step, setNavigateFrom]);

  return (
    <div className="journeys-task-table--row">
      <StepRowSummary
        step={step}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        completionPercentage={completionPercentage}
      />
      {isExpanded && <TaskRowDetails step={step} isFinalStep={isFinalStep} />}
    </div>
  );
};

StepRow.propTypes = {
  step: PropTypes.object,
  isFinalStep: PropTypes.bool,
};

export default StepRow;
