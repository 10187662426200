import PagedColumnTableRow from 'components/tables/paged-col-table-row';
import PropTypes from 'prop-types';
import _ from 'underscore';

const PagedColumnTableBody = (props) => {
  const { syncing, data, columns, emptyMessage, sortProp, onRowClick, rowClass, cells } = props;

  const renderRows = () => {
    if (!syncing && _.isEmpty(data)) {
      return (
        <tr>
          <td colSpan={columns.length} className="paged-col-table--body-empty">
            {emptyMessage || 'Not Available'}
          </td>
        </tr>
      );
    } else {
      return data.map((item, i) => (
        <PagedColumnTableRow
          data={item}
          sortProp={sortProp}
          onRowClick={onRowClick}
          rowClass={rowClass}
          columns={columns}
          cells={cells}
          key={item.id || i}
        />
      ));
    }
  };

  return <tbody className="table--body paged-col-table--body">{renderRows()}</tbody>;
};

PagedColumnTableBody.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.array,
  cells: PropTypes.func,
  sortProp: PropTypes.string,
  syncing: PropTypes.bool,
  rowClass: PropTypes.func,
  onRowClick: PropTypes.func,
  emptyMessage: PropTypes.string,
};

export default PagedColumnTableBody;
