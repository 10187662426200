import PropTypes from 'prop-types';
import moment from 'moment';
import { Popover2 } from '@evertrue/et-components';
import CalendarPicker from 'components/controls/calendars/calendar-picker';

const DateQuery = ({ part, index, handleDateChange, setEditingField }) => {
  return (
    <Popover2
      key={part.value.value}
      trigger={({ openPopover }) => (
        <span
          className="date-highlighted-field"
          onClick={() => {
            setEditingField(index);
            openPopover();
          }}
        >
          {moment(part.value.value).format('MMM DD, YYYY')}
        </span>
      )}
    >
      {({ closePopover }) => (
        <div className="date-popover-content">
          <CalendarPicker
            hideClear={true}
            value={moment(part.value.value).format('YYYY-MM-DD')}
            onChange={(date) => {
              handleDateChange(date);
              closePopover();
            }}
          />
        </div>
      )}
    </Popover2>
  );
};

DateQuery.propTypes = {
  part: PropTypes.object,
  index: PropTypes.number,
  handleDateChange: PropTypes.func,
  setEditingField: PropTypes.func,
};

export default DateQuery;
