import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Loading, Icon, Popover2, AdvancedComboboxContent } from '@evertrue/et-components';

const renderOptionsPicker = ({ fiscal_year, options = [], onOptionChange, selectedOption }) => {
  let valueToRender = null;

  if (options.length) {
    valueToRender = (
      <Popover2
        placement="bottom"
        trigger={({ openPopover, is_open }) => {
          return (
            <span
              className="hub-kpicard--options-selection"
              onClick={(e) => {
                e.stopPropagation();
                openPopover();
              }}
            >
              <span className="hub-kpicard--options-selection-text">{selectedOption.label}</span>
              {is_open ? <i className="fa fa-fw fa-angle-up" /> : <i className="fa fa-fw fa-angle-down" />}
            </span>
          );
        }}
      >
        <div style={{ height: '300px', overflow: 'auto' }}>
          <AdvancedComboboxContent options={options} onChange={onOptionChange} />
        </div>
      </Popover2>
    );
  } else {
    valueToRender = <div>FY {fiscal_year}</div>;
  }

  return valueToRender;
};

const WidgetKpiCard = ({
  loading,
  title,
  fiscal_year,
  children,
  icon,
  color,
  options = [],
  onOptionChange = _.noop(),
  selectedOption,
}) => {
  return (
    <div className="hub-kpicard--wrap">
      {loading ? (
        <Loading size="small" />
      ) : (
        <React.Fragment>
          <div className="hub-kpicard--header">
            <span>
              {icon ? <Icon icon={icon} style={{ color: `#${color}` }} className="hub-kpicard--header-icon" /> : null}
              <strong>{title}</strong>
            </span>
            {renderOptionsPicker({ fiscal_year, options, onOptionChange, selectedOption })}
          </div>
          <div className="hub-kpicard--content-wrap">{children}</div>
        </React.Fragment>
      )}
    </div>
  );
};

WidgetKpiCard.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any,
  fiscal_year: PropTypes.number,
  icon: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array,
  onOptionChange: PropTypes.func,
  selectedOption: PropTypes.object,
};

export default WidgetKpiCard;
