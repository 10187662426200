import { useState, useEffect, useCallback } from 'react';
import _ from 'underscore';
import AllUsersStore from 'apps/users/stores/all-users-store';
import { useFluxStore } from '@evertrue/et-flux';
import { Loading, Card, SearchInput, FlexTable, useOpenClose, Icon, PageHeader } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import ClickToInput from 'components/controls/click-to-input';
import ListColumns from 'apps/lists/components/list-columns';
import DeleteModal from 'apps/search/components/delete-modal';
import EverTrue from 'app';
import { useIdentity } from 'base/identity-resolver';
import { useFolderDetails } from '../../hooks/use-folder-details';
import { useLists } from 'apps/lists/hooks/use-lists';
import FolderActions from '../folder-actions';
import CommonDeleteModal from '../modal/common-delete-modal';

const FolderListDetail = ({ id, folderOwner }) => {
  const {
    folderLists,
    folderName,
    folderType,
    loading,
    updateFolder,
    removeListFromFolder,
    deleteFolder,
    updateFolderLists,
  } = useFolderDetails(id);
  const { deleteList, deleteLists } = useLists();
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableLists, setSelectableLists] = useState();
  const [selectedLists, setSelectedLists] = useState([]);
  const [enableAllActions, setEnableAllActions] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const [isDeleteFolderModalOpen, openDeleteFolderModal, closeDeleteFolderModal] = useOpenClose();
  const [isRemoveListsModalOpen, openRemoveListsModal, closeRemoveListsModal] = useOpenClose();
  const [isDeleteListModalOpen, openDeleteListModal, closeDeleteListModal] = useOpenClose();
  const [isBulkListsDeleteModalOpen, openBulkListsDeleteModal, closeBulkListsDeleteModal] = useOpenClose();

  const mapState = useCallback(() => {
    return {
      users: AllUsersStore.getAllGivingTreeUsers(),
    };
  }, []);

  const { users } = useFluxStore(AllUsersStore, mapState);
  const { user } = useIdentity();
  const userIsOwner = user.id == folderOwner;

  // Folder And List Handlers
  const handleSaveFolderName = (newName) => {
    if (newName && newName !== folderName) {
      updateFolder(newName);
    }
  };

  const handleSingleListDelete = (listId) => {
    if (listId) {
      deleteList(listId);
      updateFolderLists([listId]);
      setSelectedLists([]);
      setListToDelete(null);
      closeDeleteListModal();
    }
  };

  const handleBulkListsDelete = () => {
    deleteLists(selectedLists.map((list) => list.id));
    updateFolderLists(selectedLists.map((list) => list.id));
    setSelectedLists([]);
    closeBulkListsDeleteModal();
  };

  const openDeleteModal = (listId) => {
    setListToDelete(listId);
    openDeleteListModal();
  };

  // List Columns Handlers
  let listsSorted = _.sortBy(selectableLists, (list) => list.name.toLowerCase());
  if (!sortOrder) {
    listsSorted = listsSorted.reverse();
  }

  const checkOwnership = (updatedSelectedLists) => {
    const allSelectedListsAreUserOwned =
      updatedSelectedLists.length > 0 && updatedSelectedLists.every((list) => list.user_id === user.id);
    setEnableAllActions(allSelectedListsAreUserOwned);
  };

  const handleCheckAllLists = (isChecked) => {
    if (isChecked) {
      setSelectedLists([...listsSorted]);
      checkOwnership([...listsSorted]);
    } else {
      setSelectedLists([]);
    }
  };

  const handleListCheck = (list, isChecked) => {
    const updatedLists = isChecked ? [...selectedLists, list] : selectedLists.filter((l) => l.id !== list.id);

    setSelectedLists(updatedLists);
  };

  const handleSelectedListsChange = (updatedSelectedLists) => {
    checkOwnership(updatedSelectedLists);
    setSelectedLists(updatedSelectedLists);
  };

  // Effect for search change
  useEffect(() => {
    const debouncedSetSelectableLists = _.debounce(() => {
      setSelectableLists(_.filter(folderLists, (list) => _.searchCompare(list.name, searchText)));
    }, 300);
    debouncedSetSelectableLists();
  }, [searchText, folderLists]);

  const columns = ListColumns(
    'Delete List',
    setSortOrder,
    sortOrder,
    users,
    user,
    selectedLists,
    handleListCheck,
    handleCheckAllLists,
    folderLists,
    handleSelectedListsChange,
    openDeleteModal,
    userIsOwner
  );

  const confirmDeleteFolder = () => {
    deleteFolder(id);
    closeDeleteFolderModal();
  };

  const confirmRemoveLists = () => {
    const deletePromises = selectedLists.map((list) => removeListFromFolder(id, list.id));
    Promise.all(deletePromises)
      .then(() => {
        updateFolderLists(selectedLists.map((list) => list.id));
        closeRemoveListsModal();
        EverTrue.Alert.success(`Lists removed from ${folderName}`);
        setSelectedLists([]);
      })
      .catch((error) => {
        EverTrue.Alert.error('An error occurred while removing lists.');
      });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="task-header-container">
            <Icon
              icon="chevron-left"
              size={2}
              className="task-header-back"
              onClick={() => {
                EverTrue.Navigator('/lists');
              }}
            />
            <PageHeader
              title={
                <div className="list-detail--label">
                  {folderName !== 'Favorites' ? (
                    <ClickToInput
                      value={folderName}
                      onSave={handleSaveFolderName}
                      showFullText={true}
                      disable={!userIsOwner}
                      maxLength={30}
                    />
                  ) : (
                    <h1 tabIndex={-1}>{folderName}</h1>
                  )}
                </div>
              }
              subtitle={`${listsSorted.length} Lists`}
              actions={
                userIsOwner && (
                  <FolderActions
                    hasSelectedItems={selectedLists?.length > 0}
                    enableAllActions={enableAllActions}
                    handleDeleteFolder={openDeleteFolderModal}
                    handleRemoveItems={openRemoveListsModal}
                    handleItemDelete={openBulkListsDeleteModal}
                    folderType={folderType}
                  />
                )
              }
            />
          </div>
          <div className="lists-page-controller">
            <Card className="lists-page--table-folder">
              <div className="lists-page--search">
                <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
              </div>
              <FlexTable caption="Lists" data={listsSorted} columns={columns} loading={loading} />
            </Card>
          </div>
          <CommonDeleteModal
            isOpen={isDeleteFolderModalOpen}
            onClose={closeDeleteFolderModal}
            handleDelete={confirmDeleteFolder}
            title="Delete Folder?"
            body="This folder will be deleted, but the lists inside it will not be affected. You can still access them in Lists section."
            buttonLabel="Delete Folder"
          />
          <CommonDeleteModal
            isOpen={isRemoveListsModalOpen}
            onClose={closeRemoveListsModal}
            handleDelete={confirmRemoveLists}
            title="Remove Lists?"
            body="Are you sure you want to remove these lists from the Folder?"
            buttonLabel="Remove Lists"
          />
          <CommonDeleteModal
            isOpen={isBulkListsDeleteModalOpen}
            onClose={closeBulkListsDeleteModal}
            handleDelete={handleBulkListsDelete}
            title="Confirm Delete"
            body="Are you sure you want to delete these lists?"
            buttonLabel="Delete Lists"
          />
          <DeleteModal
            isOpen={isDeleteListModalOpen}
            onClose={closeDeleteListModal}
            handleDelete={handleSingleListDelete}
            title="Confirm Delete"
            body="Are you sure you want to delete this list?"
            list_id={listToDelete}
          />
        </>
      )}
    </>
  );
};

FolderListDetail.propTypes = {
  id: PropTypes.string.isRequired,
  folderOwner: PropTypes.string.isRequired,
};

export default FolderListDetail;
