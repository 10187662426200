import PropTypes from 'prop-types';
import EverTrue from 'app';
import Nav from 'components/lists/nav';
import NavItem from 'components/lists/nav-item';

const FacebookNav = ({ onSelect, active = 'pages' }) => {
  const handleNavChange = (activeKey) => {
    let url;
    switch (activeKey) {
      case 'pages':
        url = '/facebook/pages';
        break;
      case 'interactions':
        url = '/facebook/interactions';
        break;
      case 'posts':
        url = '/facebook/posts';
        break;
      default:
        url = '/facebook/pages';
        break;
    }
    EverTrue.UrlManager.navigate(url, true, false);
  };

  return (
    <Nav className="facebook-nav sub-nav" active={active} onSelect={handleNavChange}>
      <NavItem refer="default" activeWhen="pages" label="Pages" key="pages" />
      <NavItem refer="default" activeWhen="posts" label="Posts" key="posts" />
    </Nav>
  );
};

FacebookNav.propTypes = {
  onSelect: PropTypes.func,
  active: PropTypes.any,
};

export default FacebookNav;
