import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ThreadDetailedView from '../components/thread-detailed-view';
import { useIdentity } from 'base/identity-resolver';
import { getAllThreadRepliesByThreadId } from '../utils/sent-emails-api';

const ThreadController = ({ id }) => {
  const { user = {} } = useIdentity();
  const [threadReplies, setThreadReplies] = useState({});

  useEffect(() => {
    const fetchThreadReplies = async () => {
      const replies = await getAllThreadRepliesByThreadId(user?.id, id);
      setThreadReplies({ ...replies, items: replies?.items || [] });
    };

    if (user?.id && id) {
      fetchThreadReplies();
    }
  }, [user?.id, id]);

  return (
    <>
      <ThreadDetailedView id={id} subject={threadReplies?.items?.[0]?.subject || ''} threadReplies={threadReplies} />
    </>
  );
};

ThreadController.propTypes = {
  id: PropTypes.string,
};

export default ThreadController;
