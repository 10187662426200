import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Api from 'entities/helpers/api';
import { useFluxStore } from '@evertrue/et-flux';
import ListStore from 'apps/lists/stores/list-store';
import ModalConfirm from 'components/modals/modal-confirm';
import { AdvancedCombobox } from '@evertrue/et-components';

const processLists = (lists) => {
  return lists.map((listGroup) => ({
    ...listGroup,
    value: listGroup.id,
    items: listGroup.items.map((list) => ({
      value: list.id,
      label: list.name,
      group: listGroup.label,
    })),
  }));
};

const ListPicker = ({ header = 'Select a List', limit = 500, listId, onSelect, requestHide }) => {
  const [list_id, setListId] = useState(listId);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState('');

  const lists = useFluxStore(ListStore, () => processLists(ListStore.getLists()));

  useEffect(() => {
    setListId(listId);
  }, [listId]);

  const handleSelect = () => {
    onSelect(ListStore.getList(list_id));
    requestHide();
  };

  const handleChange = (list) => {
    setListId(list.value);
    setLoading(true);
    setWarning('');

    if (limit) {
      Api.CONTACTS.LIST_CONTACTS.get({
        urlArgs: { id: list.value },
        params: { limit: 1 },
        success: (data) => {
          if (data.total > limit) {
            setWarning(
              `This list has ${data.total} constituents. You've reached the max amount of allowed constituents, only ${limit} will be added.`
            );
          }
          setLoading(false);
        },
        error: () => setLoading(false),
      });
    }
  };

  const selectedList = lists.flatMap((group) => group.items).find((item) => item.value === list_id);

  return (
    <ModalConfirm
      className="list-picker"
      header={header}
      buttonLabel="Select"
      hasMargin={true}
      saving={loading}
      disableButton={!list_id}
      onSubmit={handleSelect}
      onCancel={requestHide}
    >
      <div className="clearfix">
        <AdvancedCombobox
          placeholder="Select List...."
          value={selectedList}
          grouped
          searchable
          options={lists}
          onChange={handleChange}
        />
        {warning && <div className="list-picker--warning">{warning}</div>}
      </div>
    </ModalConfirm>
  );
};

ListPicker.propTypes = {
  header: PropTypes.string,
  limit: PropTypes.number,
  listId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  requestHide: PropTypes.func,
};

export default ListPicker;
