import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import SettingsController from 'apps/settings/user-settings-controller';
import InviteTeamController from 'apps/settings/invite-team-settings/invite-team-controller';
import InvitationController from 'apps/settings/manage-team-settings/invitation-controller';
import WithFlag from 'base/launch-darkly/with-flag.js';

class UserSettingsRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      settings: 'page',
      'settings/:page': 'page',
      'user/manage': 'manage',
      invite: 'invite',
      invitation: 'invitation',
    };
  }

  page(page) {
    this.currentView = <WithFlag Component={<SettingsController page={page} />} />;
    EverTrue.page.mount(this.currentView);
  }

  manage() {
    // redirect for the legacy route user/manage
    EverTrue.Navigator('settings/manage_team', true);
  }

  invite() {
    this.currentView = <InviteTeamController />;
    EverTrue.page.mount(this.currentView);
  }

  invitation() {
    this.currentView = <InvitationController />;
    EverTrue.page.mount(this.currentView);
  }
}

UserSettingsRouter.initClass();
export default UserSettingsRouter;
