import PropTypes from 'prop-types';
import { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { EmailTemplatesContext } from '../hooks/email-templates-context';

const EmailTemplatePreview = ({ template, className }) => {
  const { templates } = useContext(EmailTemplatesContext);
  const { templateBody = '' } = template || {};

  return (
    <div className={className || 'email-template--preview-container'}>
      <p>
        <strong>Preview</strong>
      </p>
      <div className="email-template--preview">
        {templates?.length ? template && <div>{ReactHtmlParser(templateBody)}</div> : <></>}
      </div>
    </div>
  );
};

EmailTemplatePreview.propTypes = {
  template: PropTypes.object,
  className: PropTypes.string,
};

export default EmailTemplatePreview;
