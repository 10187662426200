import { useContext } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import { useIdentity } from "base/identity-resolver";
import { useGate } from "components/is-gated";
import { Icon, ActionsMenu, Modal2, ModalConfirm, Modal } from "@evertrue/et-components";
import UpdateStageModal from "apps/assignments/components/update-stage-modal";
import UpdateTimeInStageModal from "apps/portfolio-performance/components/update-time-in-stage-modal";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import BioContactInfo from "apps/profile/components/bio-contact-info";
import ContactAssignmentContext from "apps/portfolio-performance/contexts/contact-assignment-context";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import {
  updateStage,
  updateTimeInStage,
  removeProspectFromPool,
  updateKeyProspect,
} from "apps/portfolio-performance/portfolio-requests";
import ProposalForm from "apps/proposals/components/proposal-form";
import ProspectProposalModal from "apps/portfolio-performance/components/prospect-proposal-modal";
import { GlobalPortfolioContext } from "./../../../apps/portfolio-performance/contexts/global-portfolio-context";

const ES_DELAY = 1000;

const ProspectCardMenu = (props) => {
  const { contact = {}, team = {}, assignment = {}, stage, onSetLoading } = props;
  const { fetchContactsByStage, updateProspectWithAssignmentsByStage } = useContext(ContactAssignmentContext);
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const { solicitor } = useContext(PortfolioMetaDataContext);
  const [rmKeyProspectEnabled] = useGate("rm_key_prospect");
  const [stageWritesEnabled] = useGate("rm_portfolio_stage_writes");
  const [proposalWritesEnabled] = useGate("proposal_writes");
  const [hasProposalReads] = useGate("proposal_reads");
  const { isOwner, isSuperUser, affiliation } = useIdentity();
  const user_contact_id = affiliation ? affiliation.contact_id : null;

  const isCurrentSolicitor = solicitor.id === user_contact_id;
  const canUpdateStage = isOwner || isSuperUser || (stageWritesEnabled && isCurrentSolicitor);
  const canRemoveProspect =
    isOwner || isSuperUser || (team.selection_mode === "SOLICITOR_SELECTION" && isCurrentSolicitor);

  const options = [
    {
      id: 1,
      icon: "info",
      label: "View Contact",
       
      mountOnClick: ({ is_open, close }) => (
        <Modal2 isOpen={is_open} onClose={close} onOutsideClick={close}>
          <ModalConfirm style={{ width: "400px" }} closeModal={close} header={contact.name}>
            <div style={{ padding: "16px 0" }}>
              <BioContactInfo hasHeader={false} contact={contact} />
            </div>
          </ModalConfirm>
        </Modal2>
      ),
    },
    {
      id: 2,
      icon: "add-circle",
      label: "Add Interaction",
       
      mountOnClick: ({ close, is_open }) => (
        <Modal2 contentStyle={{ width: 568 }} isOpen={is_open} onClose={close}>
          <InteractionForm contact={contact} requestHide={close} />
        </Modal2>
      ),
    },
  ];

  if (canUpdateStage) {
    options.unshift({
      id: 3,
      icon: "recently-viewed",
      label: "Update Prospect Stage",
       
      mountOnClick: ({ close, is_open }) => (
        <Modal2 isOpen={is_open} onClose={close}>
          <UpdateStageModal
            pool={team}
            assignment={{ ...assignment, contact: contact }}
            close={close}
            type={assignmentType}
            onSubmit={(assignment_id, stage) => {
              onSetLoading(true);
              EverTrue.track.set("portfolio_performance_action", { type: "update_prospect_stage" });
              updateStage(assignment_id, { stage }, assignmentType).then((resp) => {
                EverTrue.Alert.success("Stage updated.");
                setTimeout(onSetLoading, ES_DELAY, false);
              });
            }}
          />
        </Modal2>
      ),
    });
    options.push({
      id: 4,
      icon: "access-time",
      label: "Update Time in Stage",
       
      mountOnClick: ({ close, is_open }) => (
        <Modal2 isOpen={is_open} onClose={close}>
          <UpdateTimeInStageModal
            assignment={{ ...assignment, contact: contact }}
            close={close}
            onSubmit={(start_time) => {
              onSetLoading(true);
              updateTimeInStage(assignment.assignment_id, assignment.assignment_stage, start_time).then((resp) => {
                EverTrue.Alert.success("Time in stage updated.");
                setTimeout(onSetLoading, ES_DELAY, false);
              });
            }}
          />
        </Modal2>
      ),
    });
  }

  const keyProspectLabel = assignment.key_prospect ? "Unmark Key Relationship" : "Mark Key Relationship";

  if (rmKeyProspectEnabled) {
    options.push({
      id: 5,
      icon: "key-relationship",
      label: keyProspectLabel,
      onClick: () => {
        //Pass alone the stage name and updated assignment record.
        updateProspectWithAssignmentsByStage(stage, {
          ...assignment,
          key_prospect: !assignment.key_prospect,
        });
        updateKeyProspect(assignment.assignment_id, !assignment.key_prospect)
          .then(() => {
            EverTrue.Alert.success("Prospect marked successfully");
          })
          .catch(() => {
            //Resetting the stage because the optimistic update failed,
            //Which means the optimistic update is now wrong.
            fetchContactsByStage({ stage: stage });
            EverTrue.Alert.error("Marking prospect failed");
          });
      },
    });
  }

  if (hasProposalReads && assignment.proposal_count > 0) {
    options.push({
      id: 6,
      icon: "proposal",
      label: "View Proposals",
       
      mountOnClick: ({ close, is_open }) => (
        <Modal2 contentStyle={{ minWidth: 568 }} isOpen={is_open} onClose={close}>
          <ProspectProposalModal requestHide={close} contact={contact} solicitor={solicitor} />
        </Modal2>
      ),
    });
  }
  if (proposalWritesEnabled) {
    options.push({
      id: 7,
      icon: "note-add",
      label: "Add Proposal",
       
      mountOnClick: ({ close, is_open }) => (
        <Modal isOpen={is_open} closeModal={close}>
          <ProposalForm
            primaryContactId={contact.id}
            closeModal={close}
            afterCreate={() => fetchContactsByStage({ stage: stage })}
          />
        </Modal>
      ),
    });
  }

  if (canRemoveProspect) {
    options.push({
      id: 8,
      label: "Remove Prospect",
      icon: "delete",
      onClick: () => {
        EverTrue.Alert.confirm(
          {
            headline: "Remove Prospect?",
            content: `Are you sure you want to remove ${contact.name} from your portfolio?`,
          },
          (didConfirm) => {
            if (didConfirm) {
              removeProspectFromPool(assignment.assignment_id, assignmentType).then(() => {
                setTimeout(() => fetchContactsByStage({ stage: stage }), ES_DELAY);
                EverTrue.Alert.success("Prospect removed");
              });
            }
          }
        );
      },
    });
  }

  return (
    <ActionsMenu
      wrapperClassName="pp-prospect-card--actions"
      triggerClassName="portfolio-performance--action-trigger"
      trigger={({ isOpen }) => (
        <Icon title="prospect actions" className={"portfolio-performance--card-more"} size={1} icon="more-horiz" />
      )}
      options={options}
    />
  );
};

ProspectCardMenu.propTypes = {
  team: PropTypes.object,
  stage: PropTypes.string,
  contact: PropTypes.object,
  assignment: PropTypes.object,
  onSetLoading: PropTypes.func,
};

export default ProspectCardMenu;
