module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   FacebookPostStore = require("apps/facebook/stores/facebook-post-store")
   LoadingMixin = require("mixins/loading-mixin")
   FacebookMixin = require("mixins/facebook-mixin")
   {h1, div} = ReactLibs.DOMFactories
   FacebookHeader = require("apps/facebook/components/facebook-header")
   {createFactory} = require("base/new-utils")
   FacebookNav = createFactory require("apps/facebook/components/facebook-nav").default
   FacebookPosts = createFactory require("apps/facebook/components/facebook-posts")
   BackToTop = createFactory require("components/controls/back-to-top")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   FacebookSodaMessage = createFactory require("apps/facebook/components/facebook-soda-message").default

   FacebookPostsController = ReactLibs.createClass
      displayName: "FacebookPostsController"
      mixins: [LoadingMixin, FacebookMixin]

      getInitialState: ->
         loading: true
         data: []
         filtering: true
         filters: {}

      onPostStoreChange: ->
         if @depracated_is_mounted
            postData = FacebookPostStore.getPostData()
            @setState
               data: postData.data
               total: postData.total
               loading: false
               filtering: false

      componentDidMount: ->
         filters = EverTrue.UrlManager.get("fb_filters") || {}
         @setState filters: filters
         FacebookPostStore.initializePosts()
         FacebookPostStore.search(filters)
         FacebookPostStore.bindToUpdates(@onPostStoreChange)
         @depracated_is_mounted = true

      componentWillUnmount: ->
         FacebookPostStore.unbind()
         @depracated_is_mounted = false


      handleSearch: (filter_object) ->
         if @depracated_is_mounted
            @setState
               filters: filter_object
               filtering: true

         FacebookPostStore.search(filter_object)

      handleLoadMore: ->
         return if _.isEmpty(@state.data) || @state.filtering
         if FacebookPostStore.hasMorePostsToLoad()
            @setState {loading: true} if @depracated_is_mounted
            FacebookPostStore.loadMore()

      handleReset: ->
         @setState filters: {}
         FacebookPostStore.reset()

      render: ->
         div className: "facebook-posts-controller et-controller",

            div className: "facebook-header-wrapper",
               FacebookHeader
                  active: @props.view_type
                  total: @state.total
                  filters: @state.filters
                  onSearch: @handleSearch
                  onReset: @handleReset

               FacebookNav active: @props.view_type

            div className: "page-container",

               FacebookSodaMessage()

               h1 className: "facebook-header--recent-posts", "Recent Facebook Posts"

               div className: "social-results loading-container",

                  @moduleSpinner(@state.filtering)

                  if !@state.loading && _.isEmpty(@state.data)
                     EmptyStateMessage icon: "search", text: "No Posts Available"

                  FacebookPosts
                     posts: @state.data
                     length: @state.data.length
                     loadMorePosts: @handleLoadMore
                     scrollElement: ".page-container"
                     containerElement: ".social-results"

                  if @state.loading && !_.isEmpty(@state.data)
                     div className: "loader is-centered"

               BackToTop
                  showPosition: 265
                  scrollElement: ".page-container"
