import EverTrue from 'app';
import { useEffect } from 'react';
import Icon from 'components/elements/icon';
import ModalConfirm from 'components/modals/modal-confirm';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import HelpTip from 'components/tooltip/helptip';
import Loading from 'components/elements/loading';
import Decorator from 'clientDecorator';
import moment from 'moment';
import Api from 'entities/helpers/api';
import EmptyStateMessage from 'components/lists/empty-state-message';
import { LinkButton } from '@evertrue/et-components';
import PropTypes from 'prop-types';

const EventbriteEmailsModal = ({
  close = () => {},
  contact = {},
  engagementStatsByEmail = {},
  profiles = [],
  loading = false,
  error,
  refreshProfiles = () => {},
}) => {
  useEffect(() => {
    refreshProfiles();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllEmails = () => {
    const contactEmails = contact.identities
      ?.map(({ type, value }) => {
        const val = value?.toLowerCase?.();
        if (val && ['eventbrite_id', 'email'].includes(type)) {
          return val;
        }
        return null;
      })
      .filter(Boolean);

    return [...new Set(contactEmails.concat(Object.keys(engagementStatsByEmail)))];
  };

  const getSortedEmailData = () => {
    const allEmails = getAllEmails() || [];

    return allEmails
      .map((email) => {
        const profile = profiles?.find((p) => p.email === email);
        const info = engagementStatsByEmail[email] || {};

        return {
          email,
          has_email_identity:
            !!contact?.identities?.find(({ type, value }) => type === 'email' && value === email) || false,
          has_eb_identity:
            !!contact?.identities?.find(({ type, value }) => type === 'eventbrite_id' && value === email) || false,
          profile,
          match_status: profile?.matchStatus,
          count: info.count || 0,
          last_activity: info.last_activity,
        };
      })
      .sort((a, b) => b.count - a.count);
  };

  const handleUnmatch = (email) => {
    return Api.EVENTS.EVENTBRITE_MATCH.post({
      disableAlerts: true,
      params: {
        op: 'unmatch',
        contact_id: contact.id,
        email: encodeURIComponent(email),
      },
    })
      .then(() => {
        refreshProfiles();
        EverTrue.Alert.success(`Unmatched '${email}' from constituent`);
        EverTrue.track.set('identity_matching', {
          type: 'unmatch',
          source: 'Eventbrite',
          referrer: 'profile',
        });
      })
      .catch(() => {
        refreshProfiles();
        EverTrue.Alert.error(`Problem unmatching '${email}' from constituent`);
      });
  };

  const renderEmails = (emails, emptyMessage) => {
    if (emails?.length) {
      return emails.map((data = {}) => {
        const { email, has_email_identity, has_eb_identity, match_status, count, last_activity } = data;
        const last_seen = moment(last_activity || NaN).format('DD MMM YYYY h:mm a');

        return (
          <div key={email} className="eventbrite-emails-modal--list-item">
            {count ? (
              <Icon className="eventbrite-emails-modal--icon is-eventbrite" icon="eventbrite" />
            ) : (
              <Icon className="eventbrite-emails-modal--icon" icon="email" />
            )}

            <span
              className="eventbrite-emails-modal--email"
              title={count ? `${count} engagements, \nlast activity: ${last_seen}` : undefined}
            >
              {email}
            </span>

            <span className="eventbrite-emails-modal--source">
              {has_email_identity ? (
                <>
                  Imported Email
                  <HelpTip help_key="event_imported_email" />
                </>
              ) : has_eb_identity && match_status ? (
                (() => {
                  switch (match_status) {
                    case 'MATCHED':
                      return (
                        <LinkButton title="Remove Email" onClick={() => handleUnmatch(email)}>
                          <Icon icon="unlink" />
                          Remove Email
                        </LinkButton>
                      );
                    case 'UNMATCH_QUEUED':
                      return 'Unmatching...';
                    case 'MATCH_QUEUED':
                      return 'Matching...';
                    case 'UNMATCHED':
                      return 'Unmatching...';
                    case 'ERROR':
                      return 'Match Issue';
                    default:
                      return 'Match Issue';
                  }
                })()
              ) : (
                <span className="text-muted">
                  Loading Issue -
                  <LinkButton onClick={refreshProfiles} title="Refresh the page">
                    Refresh
                  </LinkButton>
                </span>
              )}
            </span>
          </div>
        );
      });
    }

    return <div className="eventbrite-emails-modal--list-item is-empty">{emptyMessage}</div>;
  };

  const parsedContact = Decorator.Contacts.parse(contact);
  const data = getSortedEmailData();
  const list = data.filter(({ match_status }) => match_status !== 'UNMATCHED');
  const { matched = [], unmatched = [] } = list.reduce(
    (acc, item) => {
      acc[item.count ? 'matched' : 'unmatched'].push(item);
      return acc;
    },
    { matched: [], unmatched: [] }
  );

  return (
    <div className="eventbrite-emails-modal">
      <ModalConfirm header="Manage Eventbrite Emails" onCancel={close}>
        <ContactCard contact={parsedContact} showNameLink={false} />

        <div className="eventbrite-emails-modal--contents">
          {loading || error ? (
            <div className="eventbrite-emails-modal--status-container loading-container">
              {loading ? (
                <Loading spinner_size="small" position="center" />
              ) : (
                <EmptyStateMessage
                  icon="question-circle"
                  text="Loading Error"
                  actionLabel="Retry"
                  size="small"
                  onAction={refreshProfiles}
                >
                  <div className="eventbrite-emails-modal--error-description">
                    There was an issue loading the Eventbrite match information for this constituent.
                  </div>
                </EmptyStateMessage>
              )}
            </div>
          ) : (
            <>
              <div className="eventbrite-emails-modal--title">MATCHED EMAILS</div>

              <div className="eventbrite-emails-modal--list">{renderEmails(matched, 'No Matched Emails')}</div>

              <div className="eventbrite-emails-modal--title">OTHER EMAILS</div>

              <div className="eventbrite-emails-modal--list">{renderEmails(unmatched, 'No Other Emails')}</div>
            </>
          )}
        </div>
      </ModalConfirm>
    </div>
  );
};

EventbriteEmailsModal.propTypes = {
  close: PropTypes.func,
  contact: PropTypes.object,
  engagementStatsByEmail: PropTypes.object,
  profiles: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  refreshProfiles: PropTypes.func,
};

export default EventbriteEmailsModal;
