import { PropTypes } from 'base/react-libs';
import { getPromotionDetails, titleCaseString } from 'apps/home/utils/helpers';
import { formatDateFromTimestamp } from 'apps/journeys/utils/helpers/dates';

const CareerMoveRowDetails = ({ contact }) => {
  const { enrichment } = contact;
  const mostRecentEvent = getPromotionDetails(enrichment);

  return (
    <>
      {mostRecentEvent ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className="homepage-recommendations-subtitle">{titleCaseString(mostRecentEvent.type || '')}</div>
          <div className="giving-date">Date Reported: {formatDateFromTimestamp(mostRecentEvent.recorded_at)}</div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div>No career move data available</div>
        </div>
      )}
    </>
  );
};

CareerMoveRowDetails.propTypes = {
  contact: PropTypes.object,
};

export default CareerMoveRowDetails;
