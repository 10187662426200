import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import Module from 'components/module';
import CompanyGiving from 'apps/companies/components/company-giving';
import CompanyParticipation from 'apps/companies/components/company-participation';
import LoadingMixin from 'mixins/loading-mixin';
import { Link } from '@evertrue/et-components';

const CompanyProfileBody = ({ data = {}, type, stats = {} }) => {
  const helpKey = (typeArg) => {
    switch (typeArg) {
      case 'giving':
        return `${type}_giving_summary`;
      case 'participation':
        return `${type}_participation`;
      default:
        return '';
    }
  };

  return (
    <div className="company-profile-body">
      <Module header="Giving Summary" help_key={helpKey('giving')}>
        <div className="loading-container">
          {LoadingMixin.loading(isEmpty(stats) || isEmpty(stats.giving_stats))}
          <CompanyGiving data={stats.giving_stats} />
        </div>
      </Module>
      <Module header="Participation" help_key={helpKey('participation')}>
        <div className="loading-container">
          {LoadingMixin.loading(isEmpty(stats) || isEmpty(stats.employee_participation))}
          <CompanyParticipation data={stats.employee_participation} />
        </div>
      </Module>
      {data?.companies && (
        <Module header="Popular Companies" hidden={isEmpty(data?.companies)} help_key="popular_companies">
          <table>
            <tbody>
              {data?.companies?.slice(0, 25)?.map((company, index) => (
                <tr key={company.value}>
                  <td>
                    {index + 1}.{' '}
                    <Link
                      href={`company?name=${encodeURIComponent(company.value)}`}
                      data-refer="industry"
                      title={company.value}
                    >
                      {company.value}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Module>
      )}
    </div>
  );
};

CompanyProfileBody.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  stats: PropTypes.object,
};

export default CompanyProfileBody;
