import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import AdminWrapper from 'apps/admin/components/admin-wrapper';
import AdminGatingController from 'apps/admin/controllers/admin-gating-controller';
import UserGatingController from 'apps/admin/controllers/user-gating-controller';

class AdminRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'admin/gates/active': 'activeGating',
      'admin/gates/advanced': 'advGating',
    };
  }

  activeGating() {
    EverTrue.execute('setBrowserTitle', 'Admin | Active Gates');
    this.currentView = (
      <AdminWrapper active="active_gates" subtitle={`Your feature flags for ${EverTrue.store.org?.get?.('name')}`}>
        {UserGatingController()}
      </AdminWrapper>
    );
    EverTrue.page.mount(this.currentView);
  }

  advGating() {
    EverTrue.execute('setBrowserTitle', 'Admin | Adv. Gating');
    this.currentView = (
      <AdminWrapper active="advanced_gates" subtitle="Flagging for all features in all organizations">
        <AdminGatingController />
      </AdminWrapper>
    );
    EverTrue.page.mount(this.currentView);
  }
}
AdminRouter.initClass();

export default AdminRouter;
