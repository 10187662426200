import PropTypes from 'prop-types';
import EverTrue from 'app';
import { Button } from '@evertrue/et-components';

function AndOrToggle({ edit, value, onChange = () => {} }) {
  const handleChange = (key) => {
    onChange(key);
  };

  const renderClasses = (check) => {
    return EverTrue.classSet('and-or-toggle--item', check, {
      'is-selected': !edit || value === check,
    });
  };

  return (
    <ul className="and-or-toggle">
      {edit ? (
        <>
          <li className={renderClasses('and')}>
            <Button type="simple" title="Select AND" onClick={() => handleChange('and')}>
              AND
            </Button>
          </li>
          <li className={renderClasses('or')}>
            <Button type="simple" title="Select OR" onClick={() => handleChange('or')}>
              OR
            </Button>
          </li>
        </>
      ) : (
        <li className={renderClasses()}>
          <Button type="simple">{value}</Button>
        </li>
      )}
    </ul>
  );
}

AndOrToggle.propTypes = {
  edit: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AndOrToggle;
