import _ from 'underscore';
import { createStore } from '@evertrue/et-flux';
import LayoutSource from 'apps/layout/sources/layout-source';
import MapSource from 'apps/map/sources/map-source';
import { boxToLatLng } from 'apps/map/map-utils';
import GeolocationStore from 'apps/contact/stores/geolocation-store';
import UrlSource from 'apps/layout/sources/url-source';

export default createStore('MapStore', {
  getInitialState() {
    return {
      map: undefined,
      clusters: {},
      zoom: 2,
      loading_clusters: false,
      bounding_box: undefined,
      highlighted: undefined,
    };
  },

  registerActions() {
    this.on(MapSource.actions.fetchedClusters, this.respondToFetchedClusters);
    this.on(MapSource.actions.loadingClusters, this.respondToLoadingClusters);
    this.on(MapSource.actions.clearClusters, this.respondToClear);

    this.on(MapSource.actions.initializedMap, this.respondToInitializedMap);
    this.on(MapSource.actions.changedZoom, this.respondToZoom);
    this.on(MapSource.actions.setBoundingBox, this.respondToChangedBounds);
    this.on(MapSource.actions.highlightCluster, this.respondToHighlight);

    this.on(UrlSource.actions.initializedUrl, (url) => {
      if (url.map) {
        this.setState({ bounding_box: url.map });
      }
    });

    this.on(UrlSource.actions.changedMap, (bounds) => {
      this.setState({ bounding_box: bounds });
    });

    this.on(LayoutSource.actions.endNavAnimation, () => {
      const map = this.getState('map');
      _.defer(() => {
        if (map) {
          window.google.maps.event.trigger(map, 'resize');
        }
      });
    });
  },

  respondToFetchedClusters(zoom, data) {
    this.setState({
      zoom: zoom || this.getState('zoom'),
      clusters: _.mapObject(data.geo_cluster, (cluster) => ({
        count: cluster.count,
        geobox: _.omit(cluster, 'count'),
      })),
    });
  },

  respondToLoadingClusters(is_loading) {
    this.setState({ loading_clusters: is_loading });
  },

  respondToClear() {
    this.setState({ clusters: {} });
  },

  respondToInitializedMap(map_object, zoom) {
    this.setState({
      map: map_object,
      zoom: _.toNumber(zoom),
    });
  },

  respondToZoom(zoom_level) {
    this.setState({ zoom: zoom_level });
  },

  respondToChangedBounds(box) {
    this.setState({ bounding_box: box });
  },

  respondToHighlight(cluster_key) {
    this.setState({ highlighted: _.makeArray(cluster_key) });
  },

  api: {
    getClusters() {
      return this.getState('clusters');
    },

    getHighlightedClusters() {
      return this.getState('highlighted');
    },

    getLoadingClusters() {
      return this.getState('loading_clusters');
    },

    getZoom() {
      return this.getState('zoom');
    },

    getMap() {
      return this.getState('map');
    },

    getBoundingBox() {
      return this.getState('bounding_box');
    },

    getLatLng() {
      const latLng = boxToLatLng(this.getState('bounding_box'));
      if (latLng) {
        return { lat: latLng.lat, lon: latLng.lng };
      }
    },

    getProximitySort(sort_reverse) {
      const order = sort_reverse ? 'desc' : 'asc';
      const sort_param_query = {};
      let latLng = boxToLatLng(this.getState('bounding_box'));
      if (!latLng) {
        latLng = GeolocationStore.getLocationOrDefault();
      }
      sort_param_query['addresses.location'] = {
        lat: latLng.lat,
        lon: latLng.lng,
        operator: 'min',
        order,
      };
      return sort_param_query;
    },
  },
});
