import PropTypes from 'prop-types';
import ActionMenuItem from 'components/controls/action-menu-item';

const SavedSearchesActions = ({
  hasFolders = true,
  onAddtoFolder = () => {},
  onCreateFolder = () => {},
  anySavedSearchSelected = false,
}) => {
  return (
    <>
      {hasFolders && (
        <>
          <ActionMenuItem icon="add" title="Create Folder" onClick={onCreateFolder}>
            Create Folder
          </ActionMenuItem>
          {anySavedSearchSelected && (
            <ActionMenuItem icon="folder" iconKit="gt2" title="Add Saved Searches to Folder" onClick={onAddtoFolder}>
              Add Saved Searches to Folder
            </ActionMenuItem>
          )}
        </>
      )}
    </>
  );
};

SavedSearchesActions.propTypes = {
  hasFolders: PropTypes.bool,
  onAddtoFolder: PropTypes.func,
  onCreateFolder: PropTypes.func,
  anySavedSearchSelected: PropTypes.bool,
};

export default SavedSearchesActions;
