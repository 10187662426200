import PropTypes from "prop-types";
import { IconButton, LinkButton, ButtonTooltip } from "@evertrue/et-components";
import Api from "entities/helpers/api";
import EverTrue from "app";

const gql = String.raw;

const fields = {
  "Net Worth": {
    gqlField: "wealth_attributes",
    mutationName: "wealth_attributes_mutations",
    toast: "Net Worth",
  },
  "Physical Assets": {
    gqlField: "wealth_physical_assets",
    mutationName: "wealth_physical_assets_mutations",
    toast: "Wealth Asset",
  },
  "Wealth Indicators": {
    gqlField: "wealth_indicators",
    mutationName: "wealth_indicators_mutations",
    toast: "Wealth Indicator",
  },
};

const EnrichmentList = ({
  className,
  title,
  list = [],
  accessor,
  limit,
  openModal = () => {},
  contactId,
  onDelete,
}) => {
  const showMore = list.length > limit;
  const moreCount = list.length - limit;

  const handleMutation = (interestId, updatedAt, rejecting) => {
    const params = { [fields[title].gqlField]: [{ id: interestId, rejected: rejecting, updated_at: updatedAt }] };

    const query = gql`
      mutation ($id: BigInt!, $enrichment: EnrichmentInput) {
        contact_mutation(id: $id, enrichment: $enrichment) {
          code
          enrichment_mutation {
            ${fields[title].mutationName} {
              success
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contactId, enrichment: params },
      }),
    })
      .then(() => {
        rejecting
          ? EverTrue.Alert.success(`${fields[title].toast}} Removed`)
          : EverTrue.Alert.success(`${fields[title].toast} Restored`);
        onDelete();
      })
      .catch(() => {
        rejecting
          ? EverTrue.Alert.error(`Problem removing ${fields[title].toast} record. Please try again later`)
          : EverTrue.Alert.error(`Problem restoring ${fields[title].toast} record. Please try again later`);
      });
  };

  return list.length ? (
    <div className={`profile-enrichment--lists ${className}`}>
      {title ? title : <div />}
      {list.slice(0, limit).map((item) => {
        return (
          <div className="profile-enrichment--list contact-interests-enrichment" key={item[accessor]}>
            <div className="profile-enrichment-item">
              <p>{item[accessor]}</p>
              {title &&
                (item.rejected ? (
                  <ButtonTooltip
                    children={`Restore ${title}`}
                    contentPadding={"4px 8px"}
                    trigger={
                      <LinkButton title="" onClick={() => handleMutation(item.id, item.updated_at, !item.rejected)}>
                        Undo
                      </LinkButton>
                    }
                  />
                ) : (
                  <ButtonTooltip
                    children={`Delete ${title}`}
                    contentPadding={"4px 8px"}
                    trigger={
                      <IconButton
                        title={`Delete ${title}`}
                        icon="clear"
                        className={"profile--enrichment-delete-button"}
                        onClick={() => handleMutation(item.id, item.updated_at, !item.rejected)}
                      />
                    }
                  ></ButtonTooltip>
                ))}
            </div>
          </div>
        );
      })}
      {showMore && (
        <LinkButton className="profile-enrichment--more" title={`View ${moreCount} more interests`} onClick={openModal}>
          (+{moreCount} More)
        </LinkButton>
      )}
    </div>
  ) : null;
};

EnrichmentList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  list: PropTypes.array,
  accessor: PropTypes.string,
  limit: PropTypes.number.isRequired,
  openModal: PropTypes.func,
  contactId: PropTypes.number,
  onDelete: PropTypes.func,
};

export default EnrichmentList;
