import PropTypes from 'prop-types';
import classNames from 'classnames';
import EngagementHelper from 'apps/events/utils/engagement-config';
import EngagementIcon from 'apps/profile/components/engagement-icon';

const EngagementIconPill = ({ action, className }) => {
  const [icon] = EngagementHelper.getEngagementActionsByTab(action) || [];
  return (
    <div className={classNames('engagement-action-pill', className)}>
      {icon && <EngagementIcon className="engagement-action-pill--icon" icon={icon} size={12} />}
      {' ' + action}
    </div>
  );
};

EngagementIconPill.propTypes = {
  action: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default EngagementIconPill;
