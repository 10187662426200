import { PropTypes } from 'base/react-libs';
import { Radio } from '@evertrue/et-components';

const CadenceList = ({ selected, options, handleJourneyChange, showAllCadenceView }) => {
  return (
    <>
      <div style={{ paddingLeft: '8px' }}>
        <div style={{ fontWeight: '500', padding: '5px' }}>Cadences</div>
        <ul className="cadence-list">
          {options &&
            options.map((journey, index) => {
              return (
                <li
                  style={{ padding: '8px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  className={`cadence-group-list-item `}
                  key={journey.value}
                  onClick={() => {
                    handleJourneyChange(journey);
                  }}
                >
                  <Radio name="is_primary" checked={journey.value === selected?.value} label={journey.label} />
                  {showAllCadenceView && (
                    <div style={{ color: '#8b8484', 'padding-left': '32px' }}>{journey.journey_group.name}</div>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

CadenceList.propTypes = {
  selected: PropTypes.object,
  options: PropTypes.array,
  handleJourneyChange: PropTypes.func,
  showAllCadenceView: PropTypes.bool,
};

export default CadenceList;
