import { isEmpty } from 'underscore';
import { PropTypes } from 'base/react-libs';
import { getJourneyEmptyState } from 'apps/journeys/utils/helpers/journeys-empty-state-handler';
import { FlexTable, Link, FlexTableSortHeaderCell, ButtonTooltip, Icon } from '@evertrue/et-components';
import colors from 'base/colors';
import EmptyStateMessage from 'components/lists/empty-state-message';
import JourneyFavoriteToggle from 'apps/journeys/utils/journey-favorite-toggle';
import ActiveJourneyClickable from 'apps/journeys/components/modals/active-journey-clickable.js';

const JourneysTable = ({
  journeys = [],
  loading,
  toggleFavorite,
  handleOpenSideBar,
  selectedTab,
  onlyJourneysWithStepsDue,
  handleTab,
  sortKey,
  sortOrder,
  setSortKey,
  setSortOrder,
}) => {
  const renderHeader = (header, key, tooltipMessage) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FlexTableSortHeaderCell
        onClick={() => {
          if (sortKey === key) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
          } else {
            setSortKey(key);
            setSortOrder('ASC');
          }
        }}
        children={header}
        isSorted={sortKey === key}
        sortOrder={sortOrder === 'DESC'}
      />
      {tooltipMessage && (
        <ButtonTooltip trigger={<Icon icon="info" style={{ color: colors.gray500 }} />} contentWidth={150}>
          {tooltipMessage}
        </ButtonTooltip>
      )}
    </div>
  );

  const totalMessage = () => {
    if (journeys.length === 1) {
      return `${journeys.length} Total Cadence`;
    }
    return `${journeys.length} Total Cadences`;
  };

  const emptyState = getJourneyEmptyState(onlyJourneysWithStepsDue, selectedTab, handleTab);

  const columns = [
    {
      key: 'name',
      width: 230,
      header: renderHeader('Cadence Name', 'name'),
      accessor: (data) => (
        <div className="journey-name">
          <JourneyFavoriteToggle journey={data} onClick={toggleFavorite} />
          <Link href={`/cadences/${data.id}`} title="Cadence Name">
            {data.name}
          </Link>
        </div>
      ),
    },
    {
      key: 'stepsDue',
      width: 100,
      header: renderHeader('Steps Due', 'total_steps_due_today'),
      accessor: (data) => {
        if (data && !isEmpty(data)) {
          const { total_steps_due_today } = data;
          return total_steps_due_today || 0
        } else return 0;
      },
    },
    {
      key: 'activeConstituents',
      width: 180,
      header: renderHeader('Constituents in Cadence', 'active_constituents',`All constituents you have added to cadence.`),
      accessor: (data) => <ActiveJourneyClickable handleOpenSideBar={handleOpenSideBar} journey={data} />,
    },
    {
      key: 'duration',
      width: 150,
      header: renderHeader(
        'Duration',
        'duration_in_days',
        `“days" refers to business days, not simply calendar days. There are five business days in a week.`
      ),
      accessor: (data) => {
        const { duration_in_days = 0 } = data;
        return `${duration_in_days} Days`;
      },
    },
    {
      key: 'cadenceGroup',
      width: 220,
      header: renderHeader('Cadence Category', 'journey_group.name'),
      accessor: (data) => (data.journey_group != null ? data.journey_group.name : ''),
    },
  ];

  return (
    <div>
      <div className="journeys-table--header">{totalMessage()}</div>
      <div className="journeys-table--body">
        {!journeys.length && !loading ? (
          <EmptyStateMessage
            text={emptyState.text}
            subtext={emptyState.subtext}
            actionLabel={emptyState.actionLabel}
            actionLink={emptyState.actionLink}
            icon={emptyState.icon}
            hideIcon={emptyState.hideIcon}
            iconKit={emptyState.iconKit}
            iconClassName={emptyState.iconClassName}
          />
        ) : (
          <FlexTable caption="Lists" columns={columns} data={journeys} loading={loading} />
        )}
      </div>
    </div>
  );
};

JourneysTable.propTypes = {
  journeys: PropTypes.array,
  loading: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  handleOpenSideBar: PropTypes.func,
  selectedTab: PropTypes.object,
  onlyJourneysWithStepsDue: PropTypes.bool,
  handleTab: PropTypes.func,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  setSortKey: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export default JourneysTable;
