module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {ul, li, div, span} = ReactLibs.DOMFactories
   AllUsersStore = require("apps/users/stores/all-users-store").default
   FeatureStore = require("apps/layout/stores/feature-store").default
   MembershipSource = require("apps/profile/sources/membership-source").default
   MembershipStore = require("apps/profile/stores/membership-store")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   ProfileAssignmentSource = require("apps/profile/sources/profile-assignment-source").default
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   MyTripUtils = require("apps/my-trips/utils/my-trips-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Loading = require("components/elements/loading")
   ProfileAssignmentStore = require("apps/profile/stores/profile-assignment-store").default
   Link = createFactory(require("@evertrue/et-components").Link)


   _formatLists = (lists) ->
      _.sortBy _.map(lists, (list) ->
         icon = if list.private then "lock" else "group"
         icon = "star" if list.name == "Favorites" && list.user_id == EverTrue.store.user?.id
         _.extend {}, list,
            icon: icon
            route: "/list/#{list.id}"
            type: "list"
            is_editable: true
      ), "name"

   _formatTrips = (trips) ->
      _.sortBy _.map(trips, (trip) ->
         _.extend {}, trip,
            icon: "my-trips"
            name: trip.name + " (#{MyTripUtils.getTripDisplayDate(trip)}) "
            route: "/trips/#{trip.id}"
            type: "trip"
            is_editable: EverTrue.store.user.get("id") == trip.user_id
      ), "name"


   createComponent "Membership",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefautlProps: ->
         contact: {}

      registerStores: ->
         @on MembershipStore, ->
            list_memberships: _formatLists MembershipStore.getListMembership(@props.contact.id)
            trip_memberships: _formatTrips MembershipStore.getTripMembership(@props.contact.id)
            loading: MembershipStore.getLoading()

         @on AllUsersStore, ->
            users: AllUsersStore.getAllUsers()

         @on FeatureStore, ->
            has_trips: FeatureStore.hasFeature("my_trips")
            has_volunteers: FeatureStore.hasFeature("volunteers")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")

         @on ProfileAssignmentStore, ->
            volunteer_assignments: ProfileAssignmentStore.getVolunteerMembership(@props.contact.id)
            team_assignments: ProfileAssignmentStore.getTeamMembership(@props.contact.id)

      componentDidMount: ->
         if @state.has_rm_assignments
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

         if @props.contact.id
            MembershipSource.fetchListsForContact(@props.contact.id)
            if @state.has_trips
               MembershipSource.fetchTripsForContact(@props.contact.id)


      componentDidUpdate: (prevProps, prevState) ->
         if !prevState.has_rm_assignments and @state.has_rm_assignments
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if !prevState.has_volunteers and @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

         if prevState.has_trips != @state.has_trips && @state.has_trips
            MembershipSource.fetchTripsForContact(@props.contact.id)

         if prevProps.contact.id != @props.contact.id
            @setState {loading: true}
            MembershipSource.fetchListsForContact(@props.contact.id)
            if @state.has_trips
               MembershipSource.fetchTripsForContact(@props.contact.id)

      handleRemove: (item) ->
         @refs?.viewAll?.hide?()
         switch item.type
            when "trip"
               TripContactsSource.removeContact item.id, @props.contact.id,
                  active_contact_id: @props.contact.id
            when "list"
               ListContactSource.removeContacts item.id, [@props.contact.id],
                  active_contact_id: @props.contact.id

      formattedMembershipSummary: ->
         {has_trips, has_volunteers, has_rm_assignments, list_memberships, trip_memberships, volunteer_assignments, team_assignments} = @state
         first_name = @props.contact.properties?.name_first?.value
         list_count = list_memberships.length
         trip_count = trip_memberships.length
         pool_count = volunteer_assignments.length
         team_count = team_assignments.length

         summary = "#{first_name} is a member of #{list_count} #{_.plural(list_count, "list")}"
         if has_trips
            if has_volunteers or has_rm_assignments then summary += "," else summary += " and"
            summary += " #{trip_count} #{_.plural(trip_count, "trip")}"
         if has_rm_assignments
            if has_volunteers then summary += "," else summary += " and"
            summary += " #{team_count} #{_.plural(team_count, "team")}"
         if has_volunteers
            summary += " and #{pool_count} #{_.plural(pool_count, "pool")}"
         summary

      renderViewAllModal: ->
         Modal width: 400, ref: "viewAll",
            ModalConfirm
               header: "Membership"
               className: "membership--view-all"
               @renderAllGroups()

      renderAllGroups: (limit) ->
         div className: "membership--groups clearfix",
            @renderGroup "Lists", limit, @state.list_memberships
            if @state.has_trips
               @renderGroup "Trips", limit, @state.trip_memberships
            if @state.has_volunteers
               @renderGroup "Pools", limit, @state.volunteer_assignments
            if @state.has_rm_assignments
               @renderGroup "Teams", limit, @state.team_assignments

      renderTitle: (label, limit, items) ->
         div className: "membership--label",
            span null, label
            if _.size(items) > limit
               ModalTrigger
                  title: "View Memberships"
                  className: "membership--title-more"
                  modal: @renderViewAllModal()
                  " (+#{_.size(items) - limit} more)"

      renderGroup: (label, limit, items) ->
         limit ?= items.length
         current_user_id = EverTrue.store.user?.get("id")
         div className: "membership--list",
            ProfileCardSubtitle label: @renderTitle(label, limit, items)

            if _.isEmpty(items)
               div className: "text-placeholder-light",
                  "Constituent has not been added to a #{label.toLowerCase().slice(0, -1)}"
            else
               ul null,
                  _.map _.first(items, limit), (item) =>
                     owner_name = @state.users?[item.user_id]?.name
                     {labels} = item

                     li key: item.id, className: "membership--item clearfix",
                        div className: "membership--item-label clearfix",
                           Link title: "#{item.name}", href: item.route, "data-refer": "person_profile", className: "ellipsis-overflow",
                              item.name

                           div className: "membership--owner",
                              if item.user_id == current_user_id then "Owned by you"
                              else if owner_name then "Owned by #{owner_name}"
                              else if labels then _.map(labels, (label, i) -> div({key: i}, label))

                        if item.is_editable
                           Icon icon: "cancel", onClick: (=> @handleRemove(item))

      render: ->
         is_empty = _.isEmpty(@state.list_memberships) && _.isEmpty(@state.trip_memberships) && _.isEmpty(@state.team_assignments) && _.isEmpty(@state.volunteer_assignments)

         div className: classNames("membership", "is-loading": @state.loading),

            if is_empty && !@state.loading
               div className: "membership--empty",
                  div null,
                     "Once a constituent is added to a list#{if @state.has_trips then " or trip" else ""},
                     you can see their memberships here."
            else if @state.loading
               Loading(text: "Loading...")
            else
               div className: "membership--summary",
                  div null, @formattedMembershipSummary()
                  ModalTrigger
                     title: "View Memberships"
                     className: "contact-profile--empty-button"
                     buttonType: "secondary"
                     modal: @renderViewAllModal()
                     Icon icon: "check-circle", size: 1
                     "View Memberships"
