import { useState, useEffect } from "react";
import ErrorLogger from "entities/helpers/error-log-helper";
import useDataStream from "entities/helpers/use-data-stream.js";

/**
 *
 * @param {Boolean} phoneCallWithAi -- Indicates whether or not this hook should be ran
 * @param {*} quickInteractionData -- Information necessary to send to the AI Prompt.
 * @returns an AI-Generated Summary of the phone call, based on the Contact, Notes, Task, and Journey associated with the call.
 * NOTE: this is only called when building a Quick Interaction after a phone call
 */
const useAiForDialer = (phoneCallWithAi, quickInteractionData) => {
  const [aiGeneratedDescription, setAiGeneratedDescription] = useState("");
  const { openDataStream, isStreaming, response } = useDataStream();

  const { contact = {}, task = {}, journey = {}, activeCallNotes } = quickInteractionData;
  useEffect(() => {
    const generateDescription = async () => {
      if (phoneCallWithAi) {
        // use AI to Summarize the Phone Call
        try {
          const query = {
            prompt: {
              key: "cadences/call_description",
              args: {
                conversation_details: activeCallNotes,
                task_title: task.title,
                journey: journey.name,
                contact,
              },
            },
          };


          const options = {
            method: "POST",
            endpoint: `ai/v2/completions/`,
            params: {
              append_request_id: "false",
            },
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "ET-Request-ID": `call_description`,
            },
            data: JSON.stringify(query),
          };
          await openDataStream(options);
        } catch (err) {
          ErrorLogger.error("Failed to generate AI Call Summary", {
            extra: { error: err, page_name: "Add To Cadences Popover" },
          });
        }
      }
    };
    generateDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCallWithAi]);

  // In the event we have opened the data stream (ie called the AI Service),
  // "response" will constantly be changing. we run this to update our ai-generated-summary
  // this is only relevant when phoneCallWithAi === true
  useEffect(() => {
    setAiGeneratedDescription((summary) => {
      return (summary += response);
    });
  }, [response]);

  return { aiGeneratedDescription, isStreaming };
};

export default useAiForDialer;
