import PropTypes from 'prop-types';
import ReviewVideo from '../recorder-components/review-video';
import ThankviewLibrary from '../thankview-library';
import ThankviewRecorder from '../thankview-recorder';
import ThankviewDefaultSettings from '../thankview-default-settings';
import { Modal } from '@evertrue/et-components';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const SignalVideoController = ({ uploadVideo, processingVideoState }) => {
  const {
    isThankviewModalOpen,
    handleCloseThankviewModal,
    modalView: { view },
  } = useSignalVideoModalContext();

  return (
    <Modal
      isOpen={isThankviewModalOpen}
      closeModal={handleCloseThankviewModal}
      size={view === 'settings' ? 'small' : 'dynamic'}
    >
      {view === 'library' ? (
        <ThankviewLibrary />
      ) : view === 'recorder' ? (
        <ThankviewRecorder uploadVideo={uploadVideo} processingVideoState={processingVideoState} />
      ) : view === 'review' ? (
        <ReviewVideo />
      ) : (
        <ThankviewDefaultSettings />
      )}
    </Modal>
  );
};

SignalVideoController.propTypes = {
  uploadVideo: PropTypes.func.isRequired,
  processingVideoState: PropTypes.string.isRequired,
};

export default SignalVideoController;
