import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";

/** To be used when looking up individual flag information */
export const useFeatureFlag = (flagName) => {
  const allFlags = useFlags();
  const flagEnabled = allFlags.hasOwnProperty(flagName) ? allFlags[flagName] : false;
  const loaded = allFlags.hasOwnProperty(flagName);

  return { flagEnabled, loaded };
};

/** To be used when looking up all flags, returns a map with all flag keys:values */
export const useFeatureFlags = () => {
  const allFlags = useFlags();

  // helper function for dev use only that prints an array of all flag keys
  const printAllFlags = () => {
    const FlagKeys = Object.keys(allFlags);
    console.log(FlagKeys);
  };

  return { allFlags, printAllFlags };
};

useFeatureFlag.propTypes = {
  flagName: PropTypes.string.isRequired,
};
