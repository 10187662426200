import { PageHeader, Icon } from '@evertrue/et-components';
import ClarityDashboard from 'apps/clarity/components/clarity-dashboard';
import { ClarityProvider } from './clarity-context';
import EverTrue from 'app';

const ClarityController = () => (
  <ClarityProvider>
    {/* this empty div stores the background png */}
    <div className="clarity-title"></div>
    <PageHeader
      title={
        <div className="clarity-title-containter">
          <Icon
            icon="chevron-left"
            className="settings-back"
            style={{ color: '$gray900', fontSize: '1.5em', alignSelf: 'flex-start' }}
            onClick={() => {
              EverTrue.UrlManager.navigate('/home', true, false);
            }}
          />
          <div>
            <h3>Clarity</h3>
            <div className="clarity-subheader">
              Welcome to Clarity, EverTrue's on-demand dashboard suite. Clarity helps you quickly analyze and explore
              data from every corner of your fundraising shop so you can track your team's progress and find new
              opportunities you might otherwise miss.
            </div>
          </div>
        </div>
      }
    />

    <ClarityDashboard />
  </ClarityProvider>
);

export default ClarityController;
