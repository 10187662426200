import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeaderWithActions } from '@evertrue/et-components';
import moment from 'moment';
import DatePickerDropdown from 'components/calendar/date-picker-dropdown';
import TimeDropdown from 'components/forms/time-dropdown';
import FormField from 'components/forms/form-field';

const ScheduleEmailModal = ({ isOpen, onClose, onSchedule, scheduledDateAndTime }) => {
  const getDefaultTime = (date) => {
    const currentTime = moment();
    const isToday = moment(date).isSame(currentTime, 'day');
    if (isToday) {
      const next30Minutes = currentTime
        .clone()
        .add(30 - (currentTime.minute() % 30), 'minutes')
        .startOf('minute');
      return next30Minutes;
    } else {
      return moment('09:00:00', 'HH:mm');
    }
  };

  const currentTime = moment();
  const isLateNight = currentTime.hours() >= 23;

  const initialDate = scheduledDateAndTime
    ? moment.unix(scheduledDateAndTime).startOf('day')
    : isLateNight
    ? currentTime.clone().add(1, 'day').startOf('day')
    : currentTime.startOf('day');
  const initialTime = scheduledDateAndTime ? moment.unix(scheduledDateAndTime) : getDefaultTime(initialDate);

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState({
    label: initialTime.format('hh:mm a'),
    value: initialTime.toISOString(),
  });

  useEffect(() => {
    const currentTime = moment();
    const selectedDateTime = moment(selectedDate)
      .startOf('day')
      .add(moment(selectedTime.value).hours(), 'hours')
      .add(moment(selectedTime.value).minutes(), 'minutes');

    if (selectedDateTime.isBefore(currentTime)) {
      const newTime = getDefaultTime(selectedDate);
      if (newTime.toISOString() !== selectedTime.value) {
        setSelectedTime({
          label: newTime.format('hh:mm a'),
          value: newTime.toISOString(),
        });
      }
    }
  }, [selectedDate, selectedTime]);

  const handleSchedule = () => {
    if (selectedDate && selectedTime) {
      const date = moment(selectedDate).startOf('day');
      const time = moment(selectedTime.value);
      const combinedDateTime = date.add(time.hours(), 'hours').add(time.minutes(), 'minutes');
      const epochTime = combinedDateTime.valueOf() / 1000;
      const formattedDateTime = combinedDateTime
        .format('dddd, MMM D, hh:mm A')
        .replace(/AM/g, 'AM')
        .replace(/PM/g, 'PM');
      onSchedule(epochTime, formattedDateTime);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="500px">
      <ModalHeaderWithActions
        title="Schedule Email"
        closeModal={onClose}
        submitLabel="Schedule Send"
        onSubmit={handleSchedule}
      />

      <ModalBody>
        <div style={{ marginBottom: '70px' }}>
          <FormField className="scheudle-email-form-field" label="Date" inlineLabel={true}>
            <DatePickerDropdown
              disabledDays={{
                before: new Date(),
                ...(isLateNight && { before: currentTime.add(1, 'day').toDate() }),
              }}
              triggerClassName={'task-edit-date-picker'}
              chevronClassName={'date-picker-chevron'}
              date={selectedDate}
              onDayClick={setSelectedDate}
            />
          </FormField>
          <FormField className="scheudle-email-form-field" label="Time (EDT)" inlineLabel={true}>
            <TimeDropdown increment={30} onChange={setSelectedTime} value={selectedTime} selectedDate={selectedDate} />
          </FormField>
        </div>
      </ModalBody>
    </Modal>
  );
};

ScheduleEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired,
  scheduledDateAndTime: PropTypes.number,
};

export default ScheduleEmailModal;
