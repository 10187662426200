module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   MobileTextMeSource = require("apps/login/stores/login-text-me-source")

   createStore "MobileTextMeStore",
      getInitialState: ->
         current_step: 0

      registerActions: ->
         @on MobileTextMeSource.actions.incrementStep,  ->
            @setState
               current_step: @getState("current_step") + 1
         @on MobileTextMeSource.actions.resetStep, ->
            @setState
               current_step: 0
      api:
         getCurrentStep: ->
            @getState "current_step"