import PropTypes from 'prop-types';
import FilterTextInput from 'apps/filters/components/filter-text-input';
import CharacterCounter from 'apps/interactions/components/character-counter';
import { Formik } from 'formik';
import { Button } from '@evertrue/et-components';
import { ListSchema } from 'apps/lists/components/schema/list-schema';

const NewListForm = ({ isOpen, onClose = () => {}, onSubmit, listName = '' }) => {
  const handleSubmit = (values) => {
    onSubmit(values.list_name);
  };

  const initialValues = () => {
    return {
      list_name: listName,
    };
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues()}
      enableReinitialize={true}
      validationSchema={ListSchema}
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <>
          <FilterTextInput
            className="add-new-list--input"
            placeholder="New list name..."
            value={{ value: values.list_name }}
            onChange={handleChange('list_name')}
          />
          <CharacterCounter limit={120} count={(values.list_name || '').length} />
          <Button onClick={handleSubmit} disabled={!!errors.list_name || !values.list_name}>
            Create List
          </Button>
          <Button type="secondary" onClick={onClose}>
            Go Back
          </Button>
        </>
      )}
    </Formik>
  );
};

NewListForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  listName: PropTypes.string,
};

export default NewListForm;
