import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import str from "underscore.string";
import EverTrue from "app";
import ToastAlert from "apps/layout/components/toast-alert";
import BulkAddStore from "apps/layout/stores/bulk-add-store";
import ProgressBar from "components/forms/progress-bar";
import ListStore from "apps/lists/stores/list-store";
import { LinkButton } from "@evertrue/et-components";

const mapStateToProps = (ownProps) => ({ listObj: ListStore.getList(ownProps.id) });

class BulKAddToast extends Component {
  static propTypes = {
    count: PropTypes.number,
    handleClose: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    inProgress: PropTypes.bool,
    progress: PropTypes.number,
    id: PropTypes.number,
    listObj: PropTypes.object,
  };

  static defaultProps = {
    count: "",
    listObj: {},
    type: "list",
    name: "",
    handleClose: _.noop,
  };

  handleViewList = () => {
    const path = this.props.type === "pool" ? "/volunteers/pool/" : "/list/";
    EverTrue.Navigator(`${path}${this.props.id}`, true);
    this.props.handleClose();
  };

  renderBody = () => {
    const { name, count, inProgress, id, listObj } = this.props;

    const progress = (this.props.progress || 0.001) * 100;
    const is_processing = progress === 0;
    const constituents = count.toLocaleString() || "";
    const type = str.titleize(this.props.type);

    if (progress < 100 && inProgress) {
      return (
        <div>
          <h5>Adding To {type}</h5>
          <p>{`Adding ${constituents} constituents...`}</p>

          <ProgressBar
            className={classNames("progress-bar-info-inverse", {
              "progress-bar-processing": is_processing,
            })}
            percent={is_processing ? 5 : progress}
          />
        </div>
      );
    } else if (!inProgress) {
      const thing_name = type === "List" ? listObj.name : name;
      const successMessage = thing_name ? `${constituents} Constituents Added to ${thing_name} ${type}` : `${constituents} Constituents Added to ${type}`;
      return (
        <div>
          <p className="bulk-add-to-list-toast--success-message">{successMessage}</p>
          <div className="toast--actions export-toast--actions">
            {id ? (
              <LinkButton onClick={this.handleViewList} title="View List">
                View {type}
              </LinkButton>
            ) : undefined}
            <LinkButton onClick={this.props.handleClose} title="Close List">
              Close
            </LinkButton>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="toast-container">
        <ToastAlert
          type={this.props.inProgress ? "info" : "success"}
          icon={this.props.inProgress ? "gt-icon-access-time" : undefined}
          onClose={this.props.handleClose}
        >
          <Fragment>
            <div className="bulk-add-to-list-toast">{this.renderBody()}</div>
          </Fragment>
        </ToastAlert>
      </div>
    );
  }
}

export default connect(BulKAddToast, [BulkAddStore, ListStore], mapStateToProps);
