/**
 * This is a JS version of the color palette exported from et-variables.
 * In the new version of sass/scss, we can't export variables to JS directly from SCSS.
 * So this is a (hopefully) temporary solution. At this point, et-variables is basically deprecated.
 */
export default {
  black: '#000000',
  white: '#ffffff',

  gray1400: '#2c2c2c',
  gray900: '#454545',
  gray500: '#737373' /* Accessible lighter text */,
  gray300: '#939393',
  gray200: '#b7b7b7',
  gray150: '#d7d7d7',
  gray110: '#f3f3f3',
  gray108: '#f6f6f6',
  gray105: '#f9f9f9' /* Default non-white background */,

  green500: '#3f7f34' /* Green text, or white text on green e.g. success */,
  green300: '#53a744' /* Large text, icons, graphs */,
  green200: '#73c166' /* Standard brand color. OK for icons, graphs, etc */,
  green106: '#f4faf4' /* Success */,

  blue500: '#007c9e' /* Default color for links and buttons */,
  blue300: '#00a0cc',
  blue200: '#00c0f5',
  blue106: '#f2fafc' /* Hover e.g. buttons */,

  purple500: '#a54ab5' /* Selected icons, radios, checkboxes, etc */,
  purple300: '#ba72c5',
  purple200: '#cc9dd7',
  purple105: '#fcf8fc' /* Active state */,

  red500: '#de1b2c' /* Alerts, Danger */,
  red300: '#eb5c68',
  red106: '#fef6f7' /* Danger */,

  yellow150: '#f3ca16',
  yellow108: '#fdf7dc',

  purple: '#a54ab5',
  lightPurple: '#d8c0ff',

  // Brand Colors
  linkText: '#0077cc',
  background: '#ffffff',
  border: '#e0e0e0',

  // Social Colors
  linkedinBlue: '#0077b5',
  facebookBlue: '#3b5998',
  eventbriteOrange: '#ff8000',
  twitterBlue: '#1da1f2',
  instagramPink: '#f00075',
};
