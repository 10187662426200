import PropTypes from 'prop-types';
import SignalAnalyticsCard from './signal-analytics/signal-analytics-card';
import Api from 'entities/helpers/api';
import EverTrue from 'app';

const SignalAnalytics = ({ oid }) => {
  const handleTableauClick = async (boardName) => {
    try {
      const { url } = await Api.AUTH.TABLEAU_URL.post({
        params: { oid, dashboard: boardName },
      });

      window.open(url, '_blank', 'noopener,noreferrer');
    } catch {
      EverTrue.Alert.error('Dashboard failed to open. Please try again later.');
    }
  };

  return (
    <div className="signal-analytics-container">
      <h1 className="homepage-card-title">Signal Analytics</h1>
      <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
        <SignalAnalyticsCard
          title="What's Working Best? View Your Outreach Breakdowns"
          icon="images/homescreen-clarity/target-emoji.svg"
          iconAlt="Target Emoji"
          image="images/homescreen-clarity/signal-activity.png"
          imageAlt="Outreach Breakdown"
          onClick={() => handleTableauClick('/SignalActivity/SignalActivity')}
        />
        <SignalAnalyticsCard
          title="Key Metrics: Donations after Outreach"
          icon="images/homescreen-clarity/search-emoji.svg"
          iconAlt="Search Emoji"
          image="images/homescreen-clarity/fundraiser-insight.png"
          imageAlt="Fundraiser Insight"
          onClick={() => handleTableauClick('/SignalInfluenceDollars/SignalInfluenceDollars')}
        />
        <SignalAnalyticsCard
          title="See the Bigger Picture: More Signal Analytics Inside"
          image="images/homescreen-clarity/abstract-graph-see-all.png"
          imageAlt="See All Dashboards"
          buttonName="View All Dashboards"
          position="center"
          onClick={() => EverTrue.Navigator('/clarity', true)}
        />
      </div>
    </div>
  );
};

SignalAnalytics.propTypes = {
  oid: PropTypes.number,
};

export default SignalAnalytics;
