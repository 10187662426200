export const mapContactObjectToGraphqlShape = (contact) => {
  // changes the shape of the contact object into the graphql payload that the contact card expects
  const { addresses, name, id, properties, profile_image } = contact;
  return {
    addresses,
    contact_attributes: {
      name_full: name,
      year: properties && properties.year && properties.year.value,
    },
    id,
    profile_image: profile_image && profile_image.url,
    constituencies: [],
  };
};

export const getContactEmailAddress = (contact) => {
  let email = '';
  if (contact && Array.isArray(contact.emails) && contact.emails.length) {
    const { emails } = contact;
    const primaryEmails = emails.filter((e) => e && e.primary);
    if (primaryEmails.length) email = primaryEmails[0].email;
    else email = emails[0] && emails[0].email;
  }

  return email;
};

export const getGqlContactName = (contact) => {
  if (contact && contact.contact_attributes) {
    const {
      contact_attributes: { name_full, name_first },
    } = contact;
    return name_full || name_first;
  }
  return '';
};
