import PropTypes from 'prop-types';
import { ModalConfirm } from '@evertrue/et-components';
import UserStore from 'apps/users/stores/user-store';

const UnlinkContact = ({ user, requestHide }) => {
  const handleUnlink = () => {
    UserStore.updateContactID(user.id, null, null);
    handleCancel();
  };

  const handleCancel = () => {
    if (typeof requestHide === 'function') {
      requestHide();
    }
  };

  return (
    <ModalConfirm
      type="destroy"
      saveLabel="Unlink"
      header="Unlink Contact Record?"
      buttonLabel="Unlink Contact Record"
      onSubmit={handleUnlink}
      onCancel={handleCancel}
    >
      <div className="unlink-user--text">
        If you unlink the Remote User ID for <strong>{user.name}</strong>, they will no longer see the My Portfolio
        feature in EverTrue and interactions will not tie back to the contact in exports.
      </div>
    </ModalConfirm>
  );
};

UnlinkContact.propTypes = {
  user: PropTypes.object.isRequired,
  requestHide: PropTypes.func,
};

export default UnlinkContact;
