import { useFluxStore } from '@evertrue/et-flux';
import SessionStore from 'base/session/session-store';

const mapStateToProps = () => ({
  session: SessionStore.getSession(),
});

export const useSession = () => {
  const { session: { user: { super_user } = {} } = {}, session } = useFluxStore(SessionStore, mapStateToProps);

  return { session, isSuperUser: super_user };
};

const SessionResolver = ({ render = () => null, session = {} }) => {
  const obj = useSession();
  return render(obj);
};

SessionResolver.displayName = 'SessionResolver';
SessionResolver.propTypes = {
  render: ReactLibs.PropTypes.func.isRequired,
  session: ReactLibs.PropTypes.object,
};

export { SessionResolver };

const WithSession = (WrappedComponent) => (props) =>
  (
    <SessionResolver
      render={({ session, isSuperUser }) => (
        <WrappedComponent isSessionOfSuperUser={isSuperUser} session={session} {...props} />
      )}
    />
  );

export default WithSession;
