import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';

const OutreachThreadIndicator = ({ threadCount }) => {
  return (
    <span className="outreach-card-thread-count">
      <span>{threadCount}</span>
      <Icon icon="email-received-outreach" className="email-thread-icon" iconKit="gt2"></Icon>
    </span>
  );
};

OutreachThreadIndicator.propTypes = {
  threadCount: PropTypes.number,
};

export default OutreachThreadIndicator;
