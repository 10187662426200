module.exports = do ->
   React = require("react")
   {ul, li, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Dropdown = createFactory require("components/controls/dropdown")
   ColumnSelector = require("apps/contact/components/contacts/columns/column-selector")
   Icon = createFactory (require("components/elements/icon").default)
   HelpTip = createFactory (require("components/tooltip/helptip").default)
   WindowSource = require("apps/layout/sources/window-source")
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "TableHeaderDropdown",
      propTypes:
         column: ReactLibs.PropTypes.object
         sort: ReactLibs.PropTypes.object
         onSort: ReactLibs.PropTypes.func

      handleSort: (reverse) ->
         WindowSource.outsideClick()
         if @props.column?.prop == "giving.et_donor_index" then reverse = !reverse

         @props.onSort(@props.column?.prop, reverse)

      renderColumnHeader: ->
         col = @props.column
         sort_prop = @props.sort?.sortProp
         sort_reverse = @props.sort?.sortReverse
         if sort_prop == "giving.et_donor_index"
            sort_reverse = !sort_reverse

         span className: "contact-fixed-table--header-label",
            col.label
            if col.toolTip
               HelpTip
                  body: col.toolTip
                  help_key: ""
            if col.prop && sort_prop?.match(col.prop)
               Icon
                  className: "contact-fixed-table--sort",
                  icon: if sort_reverse then "arrow-up" else "arrow-down"

      render: ->
         Dropdown header: @renderColumnHeader(), stay_open: true, label: @props.column?.label || "spacer",
            ul className: "contact-fixed-table--header-menu",
               if @props.column?.prop && @props.onSort
                  li null,
                     Button type: "simple", title: "Sort ascending", onClick:(=> @handleSort(false)),
                        "Sort Ascending"

               if @props.column?.prop && @props.onSort
                  li null,
                     Button type: "simple", title: "Sort descending", onClick:(=> @handleSort(true)),
                        "Sort Descending"

               if @props.is_editable
                  li className: "contact-fixed-table--edit-cols",
                     ModalTrigger modal: Modal({width: 660, animationClass: false}, ColumnSelector()), title: "Edit Columns",
                        "Edit Columns..."
