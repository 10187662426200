import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from 'components/elements/loading';

const FixedTableRows = ({ data, loading, emptyMessage, columnWidth = 175, onRowClick }) => {
  const columnCount = data[0]?.length;

  return (
    <tbody className="table--body paged-col-table--body">
      {!loading && !data.flat().length ? (
        <tr>
          <td colSpan={columnCount} className="is-empty">
            {emptyMessage || 'Not Available'}
          </td>
        </tr>
      ) : loading ? (
        <tr>
          <td colSpan={columnCount}>
            <Loading />
          </td>
        </tr>
      ) : (
        data.map((row) => (
          <tr key={row.key} onClick={() => onRowClick?.(row.id || row.key)}>
            {row.cells.map((cell) => (
              <td
                key={cell.key}
                className={classNames(cell.className, { 'is-sticky': cell.sticky })}
                style={{
                  width: cell.width,
                  maxWidth: !cell.width ? columnWidth : undefined,
                }}
              >
                {cell.element}
              </td>
            ))}
          </tr>
        ))
      )}
    </tbody>
  );
};

FixedTableRows.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.node,
  columnWidth: PropTypes.number,
  onRowClick: PropTypes.func,
};

export default FixedTableRows;
