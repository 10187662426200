import Decorator from 'clientDecorator';
import { createStore } from '@evertrue/et-flux';
import VolunteersSource from 'apps/volunteers/sources/volunteers-source';

const VolunteersStore = createStore('VolunteersStore', {
  getInitialState() {
    return {
      volunteers_search: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(VolunteersSource.actions.loading, (isLoading) => {
      this.setState({ loading: isLoading });
    });

    this.on(VolunteersSource.actions.fetchedVolunteers, (results) => {
      const contacts = Object.assign({}, results);
      contacts.items = results.items.map((item) => Decorator.Contacts.parse(item));
      this.setState({ volunteers_search: contacts });
    });
  },

  api: {
    getLoading() {
      return this.getState('loading');
    },

    getVolunteerOptions() {
      const volunteers = this.getState('volunteers_search');
      if (!volunteers) return [];
      const items = volunteers?.items || [];

      return items?.map((contact) => ({
        value: contact.id,
        label: Decorator.Contacts.getFullName(contact),
      }));
    },
  },
});

export default VolunteersStore;
