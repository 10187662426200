import EverTrue from 'app';
import $ from 'jquery';
import BaseRouter from 'base/base-router';
import Filters from 'entities/search/filters';
import LegacyCoreSearchController from 'apps/core-search/controllers/legacy-core-search-controller';
import SegmentDiffController from 'apps/filters/controllers/segment-diff-controller';
import GlobalAutocompleteResultsController from 'apps/layout/controllers/global-autocomplete-results-controller';
import RecentContactTable from 'apps/contact/components/contacts/recent-contact-table';

const _is_hash_id = (params) => {
  return params && params.length === 32 && params.match(/^[a-zA-Z0-9]+$/);
};

class ContactRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      contact: 'index',
      'contact?*splat': 'index',
      'contact/map': 'map',
      'contact/recent': 'recent',
      'contact/map/:hash_id': 'map',
      'contact/search/:hash_id': 'index',
      'global-search/results': 'globalSearch',
      'searches/activity/:activity_id': 'segmentDiff',
    };
  }

  initialize() {
    const search = EverTrue.store.fetch('search', Filters);
    EverTrue.store.push('search', search, { cache: true });
    return super.initialize(...arguments);
  }

  beforeRoute() {
    EverTrue.vent.trigger('set:page:title', 'Browse');
    $(document).scrollTop(0);
  }

  index(params) {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'Browse');
    let hash_id;

    if (_is_hash_id(params)) {
      hash_id = params;
      EverTrue.track.setReferrer('shared_search');
    }

    this.currentView = <LegacyCoreSearchController view="table" type="contacts" hash_id={hash_id} />;
    EverTrue.page.mount(this.currentView);
  }

  map(params) {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'Map');
    EverTrue.track.setReferrer('map');
    let hash_id;

    if (_is_hash_id(params)) {
      hash_id = params;
      EverTrue.track.setReferrer('shared_search');
    }

    this.currentView = <LegacyCoreSearchController view="map" type="contacts" hash_id={hash_id} />;
    EverTrue.page.mount(this.currentView);
  }

  segmentDiff(activity_id) {
    this.currentView = <SegmentDiffController activity_id={Number(activity_id)} />;
    EverTrue.page.mount(this.currentView);
  }

  globalSearch() {
    this.currentView = <GlobalAutocompleteResultsController query={EverTrue.UrlManager.get('query')} />;
    EverTrue.page.mount(this.currentView);
  }

  recent() {
    EverTrue.vent.trigger('set:page:title', 'Recently Viewed');
    EverTrue.execute('setBrowserTitle', 'Recently Viewed');

    this.currentView = <RecentContactTable />;
    EverTrue.page.mount(this.currentView);
  }
}

ContactRouter.initClass();
export default ContactRouter;
