import { PropTypes } from 'base/react-libs';
import { formatGivingDate, givingTimeAgo } from 'apps/journeys/utils/helpers/dates';

const GiftRowDetails = ({ giving, first, subtitle }) => {
  const { last_gift_date, last_gift_amount, last_gift_label } = giving;

  const formattedDate = formatGivingDate(last_gift_date);
  const timeAgo = givingTimeAgo(last_gift_date);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      {first && <div className="homepage-recommendations-subtitle">{subtitle}</div>}
      <div>
        <span className="giving-amount">${last_gift_amount}</span> | {last_gift_label}
      </div>
      <div className="giving-date">
        Gift Date: {formattedDate} {timeAgo}
      </div>
    </div>
  );
};

GiftRowDetails.propTypes = {
  giving: PropTypes.object,
  first: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default GiftRowDetails;
