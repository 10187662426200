const EverTrue = require('app');
const BaseRouter = require('base/base-router');
const MyTripsController = require('apps/my-trips/controllers/my-trips-controller');
const TripController = require('apps/my-trips/controllers/trip-controller');
const CronofySettingsController = require('apps/my-trips/controllers/cronofy-settings-controller').default;

class MyTripsRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      trips: 'allTrips',
      'trips/calendar': 'cronofy',
      'trips/:trip_id': 'tripPage',
      'trips/:trip_id/:view': 'tripPage',
    };
  }

  allTrips() {
    EverTrue.execute('setBrowserTitle', 'Trips');
    this.currentView = <MyTripsController />;
    return EverTrue.page.mount(this.currentView);
  }

  tripPage(trip_id, view) {
    EverTrue.execute('setBrowserTitle', 'Trips');
    if (!['itinerary', 'map'].includes(view)) {
      view = 'itinerary';
    }

    this.currentView = <TripController trip_id={Number(trip_id)} view={view} />;

    return EverTrue.page.mount(this.currentView);
  }

  cronofy() {
    this.currentView = <CronofySettingsController />;
    return EverTrue.page.mount(this.currentView);
  }
}

MyTripsRouter.initClass();
export default MyTripsRouter;
