import { forwardRef, useState, useRef, useImperativeHandle, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecorder } from './hooks/recorder-context';
import { Icon } from '@evertrue/et-components';
import TrimControls from './recorder-components/trim-controls';

const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
  </svg>
);

const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path d="M48 64C21.5 64 0 85.5 0 112L0 400c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48L48 64zm192 0c-26.5 0-48 21.5-48 48l0 288c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48l-32 0z" />
  </svg>
);

const VideoPlayer = forwardRef(
  (
    {
      src,
      stream,
      autoPlay = false,
      controls = false,
      onLoadedMetadata,
      onTimeUpdate,
      isRecording = false,
      captionsUrl,
      onApplyTrim,
      trimLoading,
      onRevertTrim,
      isTrimmed,
    },
    ref
  ) => {
    const videoRef = useRef(null);
    const { color, trackColor } = useRecorder();
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isTrimming, setIsTrimming] = useState(false);
    const [trimStart, setTrimStart] = useState(0);
    const [trimEnd, setTrimEnd] = useState(100);

    useEffect(() => {
      document.documentElement.style.setProperty('--player-color', color);
      document.documentElement.style.setProperty('--track-color', trackColor);
    }, [color, trackColor]);
    useImperativeHandle(ref, () => ({
      play: () => videoRef.current.play(),
      pause: () => videoRef.current.pause(),
      getVideo: () => videoRef.current,
      getDuration: () => duration,
    }));

    const handlePlayPause = () => {
      const video = videoRef.current;
      if (video.paused) {
        setIsPlaying(true);
        video.play();
      } else {
        setIsPlaying(false);
        video.pause();
      }
    };

    const handleLoadedMetadata = useCallback(() => {
      const video = videoRef.current;
      if (video) {
        const checkDuration = () => {
          if (isFinite(video.duration) && !isNaN(video.duration)) {
            setDuration(video.duration);
            const newProgress = (video.currentTime / video.duration) * 100;
            setProgress(newProgress);
            if (onLoadedMetadata) {
              onLoadedMetadata(video.duration);
            }
          } else {
            setTimeout(checkDuration, 50);
          }
        };
        checkDuration();
      }
    }, [onLoadedMetadata]);

    const handleTimeUpdate = useCallback(() => {
      if (isDragging) return;
      const video = videoRef.current;
      if (video) {
        const currentProgress = (video.currentTime / video.duration) * 100;
        if (currentProgress >= trimEnd) {
          video.pause();
          setIsPlaying(false);
          video.currentTime = (video.duration / 100) * trimStart;
        }
        setCurrentTime(video.currentTime);
        setProgress(currentProgress);
        if (onTimeUpdate) {
          onTimeUpdate(video.currentTime);
        }
      }
    }, [onTimeUpdate, isDragging, trimEnd, trimStart]);

    const handleProgressChange = (event) => {
      const video = videoRef.current;
      if (video && video.duration) {
        const value = parseFloat(event.target.value);
        const newTime = (video.duration / 100) * value;
        if (isFinite(newTime) && newTime >= 0 && newTime <= video.duration) {
          video.currentTime = newTime;
          setCurrentTime(newTime);
          setProgress(value);
          if (onTimeUpdate) {
            onTimeUpdate(newTime);
          }
        } else {
          console.warn('Invalid time value:', newTime);
        }
      }
    };
    const toggleMute = () => {
      const video = videoRef.current;
      video.muted = !video.muted;
      setIsMuted(video.muted);
    };

    const toggleCaptions = () => {
      setCaptionsEnabled(!captionsEnabled);
      const track = videoRef.current.textTracks[0];
      if (track) {
        track.mode = !captionsEnabled ? 'showing' : 'hidden';
      }
    };

    const toggleTrim = () => {
      setIsTrimming(!isTrimming);
    };

    const toggleFullScreen = () => {
      const container = videoRef.current.parentNode;
      if (!document.fullscreenElement) {
        setIsFullScreen(true);
        container.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          setIsFullScreen(false);
          document.exitFullscreen();
        }
      }
    };

    const formatTime = (time) => {
      if (!isFinite(time) || isNaN(time) || time < 0) {
        return '0:00';
      }
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        if (stream) {
          video.srcObject = stream;
        } else if (src) {
          video.src = src;
        }
        setProgress(0);
        setCurrentTime(0);
        setDuration(0);
      }
    }, [stream, src]);
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        const updateDuration = () => {
          if (video.duration === Infinity) {
            video.currentTime = 10000000;
            setTimeout(() => {
              video.currentTime = 0; // to reset the time, so it starts at the beginning
              if (isFinite(video.duration) && !isNaN(video.duration)) {
                setDuration(video.duration);
              }
            }, 1000);
          } else if (isFinite(video.duration) && !isNaN(video.duration)) {
            setDuration(video.duration);
          }
        };

        video.addEventListener('durationchange', updateDuration);
        video.addEventListener('loadedmetadata', updateDuration);
        updateDuration();

        return () => {
          video.removeEventListener('durationchange', updateDuration);
          video.removeEventListener('loadedmetadata', updateDuration);
        };
      }
    }, []);

    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('timeupdate', handleTimeUpdate);
        if (video.readyState >= 2) {
          handleLoadedMetadata();
        }
        return () => {
          video.removeEventListener('loadedmetadata', handleLoadedMetadata);
          video.removeEventListener('timeupdate', handleTimeUpdate);
        };
      }
    }, [handleLoadedMetadata, handleTimeUpdate, src, stream]);

    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    const handleTrimChange = (event, type) => {
      const value = parseFloat(event.target.value);
      const video = videoRef.current;

      if (type === 'start') {
        if (value < trimEnd - 2) {
          // Prevent start from overlapping with end while leaving 1 second in between
          setTrimStart(value);
          const newTime = (video.duration / 100) * value;
          video.currentTime = newTime;
        }
      } else {
        if (value > trimStart + 2) {
          // Prevent end from overlapping with start while leaving 1 second in between
          setTrimEnd(value);
        }
      }
    };

    return (
      <div className="video-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <video
          ref={videoRef}
          onClick={handlePlayPause}
          id="video-player"
          autoPlay={autoPlay}
          muted={stream ? true : isMuted}
          crossOrigin="anonymous"
        >
          {captionsUrl && <track src={captionsUrl} kind="subtitles" srcLang="en" label="English" default />}
        </video>
        {!isRecording && src && (
          <button
            className="large-play-pause-btn play-pause"
            onClick={handlePlayPause}
            style={{ display: isPlaying ? 'none' : 'block' }}
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </button>
        )}
        <div>
          {!isRecording && (
            <button onClick={handlePlayPause} className={`play-pause-btn ${isHovering ? 'visible' : 'hidden'}`}>
              <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'}`} aria-hidden="true"></i>
            </button>
          )}
          {controls && (
            <div className={`controls ${isHovering ? 'visible' : 'hidden'}`}>
              {isTrimming ? (
                <TrimControls
                  trimStart={trimStart}
                  trimEnd={trimEnd}
                  handleTrimChange={handleTrimChange}
                  progress={progress}
                  handleProgressChange={handleProgressChange}
                  setIsDragging={setIsDragging}
                  setIsTrimming={setIsTrimming}
                  setTrimStart={setTrimStart}
                  setTrimEnd={setTrimEnd}
                  onApplyTrim={onApplyTrim}
                  isPlaying={isPlaying}
                  currentTime={currentTime}
                  duration={duration}
                  formatTime={formatTime}
                  handlePlayPause={handlePlayPause}
                  onRevertTrim={onRevertTrim}
                  isTrimmed={isTrimmed}
                />
              ) : (
                <>
                  <button onClick={handlePlayPause}>
                    <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'}`} aria-hidden="true"></i>
                  </button>
                  <div className="tv-tooltip">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </div>

                  <input
                    className="time-track"
                    id="time-track"
                    type="range"
                    min="0"
                    max="100"
                    value={progress}
                    onChange={handleProgressChange}
                    onMouseDown={() => setIsDragging(true)}
                    onMouseUp={() => setIsDragging(false)}
                  />

                  <button onClick={toggleMute}>
                    <i className={`fa ${isMuted ? 'fa-volume-off' : 'fa-volume-up'}`} aria-hidden="true"></i>
                  </button>

                  <div className="time-display">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </div>

                  <button onClick={toggleCaptions}>
                    <i className="fa fa-cc" aria-hidden="true"></i>
                  </button>

                  <button onClick={toggleFullScreen}>
                    <i className={`fa ${isFullScreen ? 'fa-compress' : 'fa-expand'}`} aria-hidden="true"></i>
                  </button>

                  <div className="tv-tooltip-container">
                    <button onClick={toggleTrim} disabled={trimLoading} style={{ opacity: trimLoading ? 0.5 : 1 }}>
                      <Icon icon="cut" iconKit="tv" className="tv-btn-icon" />
                    </button>
                    <div className="tv-tooltip">
                      {trimLoading ? (
                        <div className="trim-processing-container">
                          Trimming <div className="loading-spinner" />
                        </div>
                      ) : (
                        'Trim'
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

VideoPlayer.propTypes = {
  src: PropTypes.string,
  stream: PropTypes.object,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  onLoadedMetadata: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  isRecording: PropTypes.bool,
  captionsUrl: PropTypes.string,
  onApplyTrim: PropTypes.func,
  onRevertTrim: PropTypes.func,
  trimLoading: PropTypes.bool,
  isTrimmed: PropTypes.bool,
};

export default VideoPlayer;
