import Api from 'entities/helpers/api';
import _ from 'underscore';
import Decorator from 'clientDecorator';
import { queries } from '@evertrue/vms-insights';

export const prospectsTotalQuery = ({ poolId, assigned }) => {
  const query = queries.prospectsTotalQuery({ poolId, assigned });
  return Api.SEARCH.ASSIGNMENTS.post({
    params: { limit: 1 },
    data: _.jsonStringify(query),
  });
};

export const stageStatsQuery = ({ poolId, stages, assigned, solicitorContactId }) => {
  const query = queries.stageStatsQuery({ poolId, stages, assigned, solicitorContactId });
  return Api.SEARCH.ASSIGNMENTS.post({
    params: { limit: 1 },
    data: _.jsonStringify(query),
  });
};

export const numberOfInteractions = ({ poolId, assigned, activityDateParameters }) => {
  const query = queries.numberOfInteractions({ poolId, assigned, activityDateParameters });
  return Api.SEARCH.CONTACT_NOTE.post({
    data: JSON.stringify(query),
    params: { limit: 1 },
  });
};

export const numberOfProspectsWithInteractions = ({ poolId, assigned, activityDateParameters }) => {
  const query = queries.numberOfProspectsWithInteractions({ poolId, assigned, activityDateParameters });
  return Api.CONTACTS.SEARCH.post({
    data: _.jsonStringify(query),
    params: { limit: 1 },
  });
};

export const activityTypeQuery = ({ poolId, assigned, activityDateParameters, solicitorUserId }) => {
  const query = queries.activityTypeQuery({ poolId, assigned, activityDateParameters, solicitorUserId });
  return Api.SEARCH.CONTACT_NOTE.post({
    data: JSON.stringify(query),
    params: { limit: 1 },
  });
};

export const SOLICITORS_BY_NAME_QUERY = (name = '', pools = null, poolType) => {
  const query = {
    sort: [
      {
        name_last: {
          order: 'asc',
        },
      },
      {
        name_first: {
          order: 'asc',
        },
      },
    ],
    ...Decorator.Filters.getQuery({ full_name: name }).toJSON(),

    has_child: [
      {
        type: 'assignment',
        query: {
          must: [
            {
              parent_role: {
                match: 'solicitor',
              },
            },
            ...(pools ? [{ pool_id: { in: pools } }] : []),
            ...(poolType
              ? [
                  {
                    pool_type: { match: poolType },
                  },
                ]
              : []),
          ],
        },
      },
    ],
  };
  // so an exact match on an email works
  query.must[0]['identities.value'] = { in: [name] };
  return query;
};

export const fetchAnalytics = ({ poolId, name, offset, sortOrder, sortBy }) => {
  const query = queries.getAnalyticsQuery({ poolId, name: name, sortOrder, sortBy });
  return Api.VOLUNTEER.ANALYTICS.post({
    data: JSON.stringify(query),
    params: { limit: 50, offset },
  });
};

export const fetchContactsById = ({ contactIds }) => {
  const query = queries.contactByIdsQuery({ contactIds });
  return Api.CONTACTS.SEARCH.post({
    data: JSON.stringify(query),
  });
};

export const fetchSessionsByContactId = ({ contactId, oid }) => {
  const query = queries.getVolunteerSessionsQuery({ contactId, oid });
  return Api.SEARCH.SESSIONS.post({
    data: _.jsonStringify(query),
    params: { limit: 1 },
  });
};

export const fetchUsersById = async (userIdList) => {
  const users = await Promise.all(
    userIdList.map(async (userId) => {
      const user = await Api.AUTH.USER.get({
        urlExtend: `/${userId}`,
        disableAlerts: true,
      });
      return user;
    })
  );

  return users.filter((user) => user !== null);
};
