import PropTypes from 'prop-types';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const GoogleDisclosure = ({ style = {} }) => {
  return (
    <p style={{ ...style }}>
      To continue with Google, Google will share your name, email address, language preference, and profile picture with
      evertrue.com. Before using this app, you can review evertrue.com&#39;s
      <a href={getLinkUrl('everTrue', 'privacyPolicyUrl')} target="_blank" rel="noopener noreferrer">
        &nbsp;privacy policy
      </a>
      &nbsp;and
      <a href={getLinkUrl('everTrue', 'termsAndConditionsUrl')} target="_blank" rel="noopener noreferrer">
        &nbsp;terms and conditions
      </a>
      .
    </p>
  );
};

GoogleDisclosure.propTypes = {
  style: PropTypes.object,
};

export default GoogleDisclosure;
