import _ from 'underscore';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import FilterStore from 'apps/filters/stores/filter-store';
import { getBoxFromLatLngRadius, boxToLatLng, isLatLngInBox } from 'apps/map/map-utils';
import Loading from 'components/elements/loading';
import ScoreProgress from 'apps/contact/components/contacts/score-progress';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import Gated from 'components/gated';
import EmptyStateMessage from 'components/lists/empty-state-message';

const roundObject = (obj) => _.mapObject(obj, (data) => parseFloat(numeral(data).format('0.00000')));

const MapClusterContacts = ({ loading, contacts, geobox }) => {
  const renderAddressTypes = (contact) => {
    let box = geobox;
    if (box && (box.north === box.south || box.east === box.west)) {
      box = getBoxFromLatLngRadius(boxToLatLng(box), 1);
    }

    const types = _.pluck(FilterStore.getActiveFilterByKey('address_type'), 'value');
    const addresses = _.compact(
      _.map(contact.addresses, (address) => {
        if (address) {
          const lat_lng = roundObject({
            lat: address.lat?.value,
            lng: address.lng?.value,
          });
          if (
            (_.isEmpty(types) || _.contains(types, address.type?.value)) &&
            isLatLngInBox(lat_lng, roundObject(box))
          ) {
            return address?.type?.value;
          }
        }
      })
    );

    return _.isEmpty(addresses) ? 'Other Address' : addresses.join(', ');
  };

  return (
    <div className="map-cluster-contacts">
      {loading ? (
        <Loading position="center" />
      ) : (
        <div className="map-cluster-contacts--content">
          {_.isEmpty(contacts) ? (
            <div className="map-cluster-contacts--empty-wrapper">
              <EmptyStateMessage
                className="map-cluster-contacts--empty"
                size="small"
                text="Error loading constituents"
              />
            </div>
          ) : (
            contacts.map((contact) => (
              <ContactCard key={contact.id} contact={contact}>
                <div className="map-cluster-contacts--row">
                  <Gated feature="scores">
                    <span className="text-label">EverTrue Score:</span>
                    <ScoreProgress score={contact.score?.score?.value} />
                  </Gated>
                </div>

                <div className="map-cluster-contacts--row">
                  <div className="text-label">{renderAddressTypes(contact)}</div>
                </div>
              </ContactCard>
            ))
          )}
        </div>
      )}
    </div>
  );
};

MapClusterContacts.propTypes = {
  loading: PropTypes.bool,
  contacts: PropTypes.array,
  geobox: PropTypes.object,
};

export default MapClusterContacts;
