import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "underscore";
import EverTrue from "app";
import Decorator from "clientDecorator";
import SolicitorInviteSource from "apps/volunteers/sources/solicitor-invite-source";
import AppInviteModal from "apps/volunteers/components/app-invitation-modal";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ResendInvite from "apps/volunteers/components/resend-invite";
import SolicitorAuthResolver from "apps/volunteers/components/solicitor-auth-resolver";
import Icon from "components/elements/icon";
import { useGate } from "components/is-gated";
import { Flex, Loading, ButtonTooltip, Modal2, ThreeDotActionsMenu } from "@evertrue/et-components";
import { toggleHeadAgentDesignation } from "apps/volunteers/utils";
import { WithSolicitorResolver } from "apps/volunteers/components/solicitor-resolver";

const verb = (isHeadAgent) => (isHeadAgent ? "Unmark" : "Mark");

const propTypes = {
  contact: PropTypes.object,
  poolId: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  solicitor: PropTypes.object,
  invite: PropTypes.object,
  count: PropTypes.number,
  loading: PropTypes.bool,
  fetchSolicitors: PropTypes.func.isRequired,
};

const defaultProps = {
  contact: {},
  org: {},
  solicitor: {},
  invite: {},
  count: 0,
};
export const PoolSolicitorCardComponent = (props) => {
  const [head_agent_feature_enabled] = useGate("vms_head_agent");

  const getFormattedName = () => {
    const contact = props.contact;
    return contact.properties ? Decorator.Contacts.getDetailedName(contact) : "this volunteer";
  };

  const handleCancel = (invite_object) => {
    EverTrue.Alert.confirm(
      {
        headline: "Are you sure?",
        content: `Canceling will make ${getFormattedName()}'s volunteer app invite invalid for any pools they are in.`,
        buttonLabel: "Cancel Invite",
      },
      (did_confirm) => {
        if (did_confirm) {
          SolicitorInviteSource.cancelInvite(invite_object);
        }
      }
    );
  };

  const handleHeadAgent = (solicitor, isHeadAgent) => {
    EverTrue.Alert.confirm(
      {
        headline: "Are you sure?",
        content: `Are you sure you want to ${verb(isHeadAgent).toLowerCase()} this volunteer as Volunteer Lead?`,
      },
      async (didConfirm) => {
        if (didConfirm) {
          await toggleHeadAgentDesignation(solicitor, isHeadAgent, props.poolId);
          setTimeout(() => {
            props.fetchSolicitors(props.poolId, [solicitor.contact_id]);
          }, 500);
        }
      }
    );
  };

  const getMenuOptions = (solicitor = {}, onResend) => {
    let options = [];
    const is_head_agent = solicitor.role === "Volunteer Lead";

    if (!solicitor) {
      return options;
    } else if (!solicitor.has_authed) {
      if (solicitor.invite) {
        options.push(
          {
            id: 1,
            icon: "email",
            label: "Resend App Invite",
            onClick: onResend,
          },
          {
            id: 2,
            icon: "cancel",
            label: "Cancel App Invite",
            onClick: () => handleCancel(solicitor.invite),
          }
        );
      } else if (props.contact.id) {
        options.push({
          id: 3,
          icon: "email",
           
          mountOnClick: ({ is_open, close }) => (
            <Modal2 isOpen={is_open} onClose={close}>
              <AppInviteModal
                onClose={close}
                enableHeadAgentDesignation={true}
                poolId={props.poolId}
                solicitor={solicitor}
                contact={props.contact}
                roleName={solicitor.role}
              />
            </Modal2>
          ),
          label: "Send App Invite",
        });
      }
    }

    if (head_agent_feature_enabled) {
      options.push({
        id: 4,
        icon: "head-agent",
        label: `${verb(is_head_agent)} Lead Volunteer`,
        onClick: () => handleHeadAgent(solicitor, is_head_agent),
      });
    }

    options.push({
      id: 5,
      icon: "delete",
      label: "Remove from Pool",
      onClick: () => props.onRemove(solicitor.id),
    });
    return options;
  };

  const renderAuthIndicator = (solicitor, loading) => {
    if (loading) {
      return (
        <div
          style={{
            position: "relative",
          }}
        >
          <Loading size="small" />
        </div>
      );
    }

    if (solicitor.has_authed) {
      return (
        <ButtonTooltip
          children="Volunteer Authenticated"
          trigger={
            <span className="pool-solicitor-card--has-authed">
              <Icon icon="community" disableTitle={true} />
            </span>
          }
        />
      );
    } else if (solicitor.invite) {
      return (
        <ButtonTooltip
          children="App Invitation Sent"
          trigger={
            <span className="pool-solicitor-card--has-invite">
              <Icon icon="community" disableTitle={true} />
            </span>
          }
        />
      );
    } else if (solicitor.id) {
      return <Icon icon="new" size={2} />;
    }

    return null;
  };

  const { contact, invite, solicitor, count, loading } = props;
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <ResendInvite
        contact={contact}
        invite={invite}
        solicitor={solicitor}
        roleName={solicitor.role}
        poolId={props.poolId}
        render={({ handleResend }) => (
          <ThreeDotActionsMenu
            triggerClassName="pool-solicitor-card--menu"
            menuClassName="pool-solicitor-card--menu-inner"
            contentWidth={200}
            options={getMenuOptions(solicitor, handleResend)}
          />
        )}
      />
      <div
        className={classNames("pool-solicitor-card", props.className)}
        onClick={props.onClick}
        role="button"
        tabIndex={0}
      >
        <>
          <ContactCard
            className="pool-solicitor-card--card"
            contact={contact}
            name={contact ? null : () => "Unknown Volunteer"}
          >
            <>
              <div className="pool-solicitor-card--assignment text-label">
                {`${count} Assigned Constituent${count === 1 ? "" : "s"}`}
              </div>
              <Flex alignItems="center">
                {renderAuthIndicator(solicitor, loading)}
                {head_agent_feature_enabled && solicitor.role === "Volunteer Lead" && (
                  <ButtonTooltip children="Lead Volunteer" trigger={<Icon size={1} icon="head-agent" />} />
                )}
              </Flex>
            </>
          </ContactCard>
        </>
      </div>
    </div>
  );
};
PoolSolicitorCardComponent.propTypes = propTypes;
PoolSolicitorCardComponent.defaultProps = defaultProps;

const WithAuthedSolicitor = (WrappedComponent) => (props) => {
  const { contact: { id } = {} } = props; // eslint-disable-line
  const contact_ids = _.makeArray(id);
  WrappedComponent.displayName = "WithAuthedSolicitor";
  return (
    <SolicitorAuthResolver
      contactIds={contact_ids}
      poolId={props.poolId} // eslint-disable-line
      render={({ solicitors_with_auth, loading }) => {
        const solicitor = solicitors_with_auth[id] || {};
        const invite = solicitor.invite || {};
        const count = solicitor.assignment_count || 0;

        return <WrappedComponent loading={loading} solicitor={solicitor} invite={invite} count={count} {...props} />;
      }}
    />
  );
};

export default WithSolicitorResolver(WithAuthedSolicitor(PoolSolicitorCardComponent));
