import {
  Checkbox,
  FlexTableSortHeaderCell,
  Icon,
  LinkButton,
  ButtonTooltip,
  AdvancedCombobox,
} from '@evertrue/et-components';
import SegmentSource from 'apps/filters/sources/segment-source';
import _ from 'underscore';

const SavedSearchesColumns = (
  buttonlabel,
  handleSort,
  sortReverse,
  user,
  selectedSavedSearches,
  handleSavedSearchCheck,
  handleCheckAllSavedSearches,
  totalSavedSearches,
  onSelectedSavedSearchChange,
  openDeleteModal,
  handleValueChange,
  userIsOwner = true,
  hasFolders = true,
  preferences
) => {
  const options = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Biweekly', value: 'biweekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Never', value: 'never' },
  ];
  const fullyChecked = totalSavedSearches.length > 0 && selectedSavedSearches.length === totalSavedSearches.length;
  const partiallyChecked = selectedSavedSearches.length > 0 && selectedSavedSearches.length < totalSavedSearches.length;

  const handleSavedSearchSelectionChange = (savedSearch, isChecked) => {
    const updatedselectedSavedSearch = isChecked
      ? [...selectedSavedSearches, savedSearch]
      : selectedSavedSearches.filter((l) => l.id !== savedSearch.id);
    handleSavedSearchCheck(savedSearch, isChecked);
    onSelectedSavedSearchChange(updatedselectedSavedSearch);
  };

  return [
    ...(hasFolders
      ? [
          {
            key: 0,
            header: (
              <>
                {userIsOwner ? (
                  <Checkbox
                    checked={fullyChecked || partiallyChecked}
                    partial={partiallyChecked}
                    onChange={(checked) => handleCheckAllSavedSearches(checked)}
                    className="header-checkbox"
                    label="Select All Saved Searches"
                  />
                ) : (
                  <span></span>
                )}
              </>
            ),
            accessor: (savedSearch) => {
              return userIsOwner ? (
                <Checkbox
                  checked={selectedSavedSearches.some((l) => l.id === savedSearch.id)}
                  onChange={(checked) => handleSavedSearchSelectionChange(savedSearch, checked)}
                  className={`task-checkbox ${selectedSavedSearches.length > 0 ? 'visible' : ''}`}
                  label="Select Saved Search"
                />
              ) : (
                <span></span>
              );
            },
            headerStyle: { maxWidth: '50px', textAlign: 'left' },
            cellClassName: 'task-checkbox-cell',
          },
        ]
      : []),
    {
      key: 1,
      header: (
        <FlexTableSortHeaderCell onClick={() => handleSort(!sortReverse)} sortOrder={sortReverse} isSorted={true}>
          Search Name
        </FlexTableSortHeaderCell>
      ),
      accessor: (i) => (
        <LinkButton title="View Segment" onClick={() => SegmentSource.applySegment(i.id)}>
          {i.name}
        </LinkButton>
      ),
    },

    {
      key: 2,
      header: (
        <>
          <Icon icon="email" style={{ marginRight: 10, fontSize: 20 }} />
          Email Updates
        </>
      ),
      accessor: (i) => {
        const preference = _.findWhere(preferences, { key: `list:${i.id}:` });
        let currentValue = { label: 'Never', value: 'never' };
        if (preference && preference.email_enabled) {
          currentValue = _.findWhere(options, { value: preference.email_frequency.toLowerCase() });
        }

        return (
          <AdvancedCombobox
            value={currentValue}
            options={options}
            onChange={(value) => handleValueChange(value, 'email', i)}
            placeholder="Select Frequency"
            formatSelection={(selectionText) => {
              if (currentValue.value !== 'never') {
                return (
                  <div className="frequency-selection">
                    <Icon className="notification-icon" icon="alarm" />
                    <span>{selectionText}</span>
                    <Icon icon="chevron-down" />
                  </div>
                );
              } else
                return (
                  <div className="frequency-selection">
                    {selectionText}
                    <Icon icon="chevron-down" />
                  </div>
                );
            }}
          />
        );
      },
    },
    {
      key: 3,
      header: (
        <>
          <Icon icon="phone-android" style={{ marginRight: 10, fontSize: 20 }} />
          Mobile Notifications
        </>
      ),
      accessor: (i) => {
        const preference = _.findWhere(preferences, { key: `list:${i.id}:` });
        let currentValue = { label: 'Never', value: 'never' };
        if (preference && preference.push_enabled) {
          currentValue = _.findWhere(options, { value: preference.push_frequency.toLowerCase() });
        }

        return (
          <AdvancedCombobox
            value={currentValue}
            options={options}
            onChange={(value) => handleValueChange(value, 'push', i)}
            placeholder="Select Frequency"
            formatSelection={(selectionText) => {
              if (currentValue.value !== 'never') {
                return (
                  <div className="frequency-selection">
                    <Icon className="notification-icon" icon="alarm" />
                    <span>{selectionText}</span>
                    <Icon icon="chevron-down" />
                  </div>
                );
              } else
                return (
                  <div className="frequency-selection">
                    {selectionText}
                    <Icon icon="chevron-down" />
                  </div>
                );
            }}
          />
        );
      },
    },
    {
      key: 4,
      headerStyle: { flexGrow: 1 },
      header: '',
      cellClassName: 'task-icon-col',
      accessor: (data) => {
        if (data.user_id === user.id)
          return (
            <ButtonTooltip
              children={buttonlabel}
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" onClick={() => openDeleteModal(data.id)} isReachable={true} />}
            />
          );
        else
          return (
            <ButtonTooltip
              children="Delete option is disabled because you are not the owner of this Saved Search"
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" style={{ color: 'grey' }} />}
            />
          );
      },
    },
  ];
};

export default SavedSearchesColumns;
