import { Modal, ModalHeader, ModalBody, Button, Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const GOOGLE_BASE_URL = getLinkUrl('calendarTemplates', 'googleCalendarUrl');
const MICROSOFT_BASE_URL = getLinkUrl('calendarTemplates', 'microsoftCalendarUrl');

const AddToCalendarModal = ({ closeModal, isOpen, calendarParams = {}, showTaskSaved = true }) => {
  const { title = '', description = '', current_due_date = '' } = calendarParams;
  const date = new Date(current_due_date);

  const handleClick = (provider) => {
    let calendarURL;
    if (provider === 'google') {
      //Format like "2024-07-29T18:07:33.345Z" -> "20240729"
      const isoString = date.toISOString().split('.')[0].replace(/[-]/g, '').slice(0, 8);
      calendarURL = `${GOOGLE_BASE_URL}text=${encodeURIComponent(title)}&dates=${isoString}/${isoString}`;
      if (description) calendarURL += `&details=${encodeURIComponent(description)}`;
    }
    if (provider === 'microsoft') {
      const isoString = date.toISOString().split('.')[0];
      calendarURL = `${MICROSOFT_BASE_URL}subject=${encodeURIComponent(title)}&startdt=${isoString}&allday=true`;
      if (description) calendarURL += `&body=${encodeURIComponent(description)}`;
    }
    window.open(calendarURL, '_blank');
    return calendarURL;
  };

  return (
    <>
      <Modal isOpen={isOpen} size="small" closeModal={closeModal} closeOnOutsideClick={true}>
        <ModalHeader title="Add to Calendar" closeModal={closeModal} />
        <ModalBody>
          <div className="add-to-calendar-modal">
            {showTaskSaved && (
              <p>
                <Icon icon="success-circle" style={{ color: '#53A744' }} /> Task Saved!
              </p>
            )}
            <p>
              <strong>Add the task to your calendar</strong>
            </p>
            <Button type="secondary" onClick={() => handleClick('google')} className="add-to-calendar-btn">
              <span className="google-icon"></span>Add to Google Calendar
            </Button>
            <Button type="secondary" onClick={() => handleClick('microsoft')} className="add-to-calendar-btn">
              <span className="microsoft-icon"></span>Add to Microsoft Calendar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

AddToCalendarModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  calendarParams: PropTypes.object,
  showTaskSaved: PropTypes.bool,
};

export default AddToCalendarModal;
