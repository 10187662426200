import Parameter from 'entities/search/query-parts/parameter-query';
import Property from 'entities/search/query-parts/property-query';

const google = window.google;

const toRadians = (degrees) => degrees * (Math.PI / 180);
const toDegrees = (radians) => radians * (180 / Math.PI);
const radiusConfig = [
  { radius: 1, zoom: 14 },
  { radius: 2, zoom: 13 },
  { radius: 5, zoom: 12 },
  { radius: 10, zoom: 11 },
  { radius: 25, zoom: 10 },
  { radius: 50, zoom: 8 },
];

// Map Utility Functions
export const getClusterQuery = (box) => {
  return [
    Parameter('must', [
      Property('location', getQueryFromBox(box), { type: 'object', parent: 'addresses', parent_type: 'instance' }),
    ]),
  ];
};

export const getQueryFromBox = (box) => {
  // If the cluster returns a point instead of a box, search with lat/lng
  if (box && (box.north === box.south || box.east === box.west)) {
    const { lat, lng } = boxToLatLng(box);
    return { lat, lon: lng, radius: '1ft' };
  }
  return { ...box };
};

export const boundsToBox = (bounds) => {
  return {
    north: bounds?.getNorthEast()?.lat()?.toFixed(5),
    south: bounds?.getSouthWest()?.lat()?.toFixed(5),
    east: bounds?.getNorthEast()?.lng()?.toFixed(5),
    west: bounds?.getSouthWest()?.lng()?.toFixed(5),
  };
};

export const boxToLatLngBounds = (box) => {
  const southWest = new google.maps.LatLng(box.south, box.west);
  const northEast = new google.maps.LatLng(box.north, box.east);
  const bounds = new google.maps.LatLngBounds(southWest, northEast);
  return bounds;
};

export const boxToLatLng = (box) => {
  if (!box || Object.keys(box).length === 0) return;
  const bounds = boxToLatLngBounds(box)?.getCenter();
  return { lat: bounds?.lat(), lng: bounds?.lng() };
};

export const radiusToLatLng = (radius_obj) => {
  if (!radius_obj || Object.keys(radius_obj).length === 0) return;
  return { lat: parseFloat(radius_obj.lat), lng: parseFloat(radius_obj.lon) };
};

export const getZoomForRadius = (radius) => {
  if (!radius) return;
  return radiusConfig.find((config) => config.radius === radius)?.zoom;
};

export const getRadiusOptions = () => {
  return radiusConfig.map((obj) => obj.radius);
};

export const getBoxFitForMarkers = (lat_lngs) => {
  const marker_bounds = new google.maps.LatLngBounds();
  lat_lngs.forEach((lat_lng) => marker_bounds.extend(new google.maps.LatLng(lat_lng.lat, lat_lng.lng)));
  return boundsToBox(marker_bounds);
};

export const getBoxFromLatLngRadius = (lat_lng, radius) => {
  const earth_radius = 6371; // in km
  const { lat, lng } = lat_lng;
  const lat_degrees = toDegrees(radius / earth_radius / Math.cos(toRadians(lat)));
  const lng_degrees = toDegrees(radius / earth_radius);

  return {
    west: lng - lat_degrees,
    east: lng + lat_degrees,
    north: lat + lng_degrees,
    south: lat - lng_degrees,
  };
};

export const isLatLngInBox = (latLng, box) => {
  if (!latLng.lat || !latLng.lng || !box) return;
  return latLng.lat >= box.south && latLng.lat <= box.north && latLng.lng >= box.west && latLng.lng <= box.east;
};

// This logic checks for zoomed out map with more than 360 deg map
// The above checks if an address point is within box
export const isLatLngInGlobalBox = (latLng, box) => {
  let in_long;
  box = Object.fromEntries(Object.entries(box).map(([key, value]) => [key, parseFloat(value)]));

  const east_bound = latLng.lng < box.east;
  const west_bound = latLng.lng > box.west;

  if (box.east < box.west) {
    in_long = east_bound || west_bound;
  } else {
    in_long = east_bound && west_bound;
  }

  return latLng.lat > box.south && latLng.lat < box.north && in_long;
};
