import { Component } from "react";
import EverTrue from "app";
import { connect } from "@evertrue/et-flux";
import VolunteersPoolsController from "apps/volunteers/controllers/volunteers-pools-controller";
import VolunteersManagementController from "apps/volunteers/controllers/volunteers-management-controller";
import PageHeader from "apps/layout/components/page-header";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import PoolStore from "apps/volunteers/stores/pools-store";
import PoolsActions from "apps/volunteers/components/pools-actions";
import ContactsResolver from "apps/contact/components/contacts/contacts-resolver";
import { SOLICITORS_BY_NAME_QUERY } from "apps/volunteers/vms-queries";
import PoolFormModal from "apps/volunteers/components/pool-form-modal";
import { Modal2 } from "@evertrue/et-components";

const mapStateToProps = () => ({
  poolsTotal: PoolStore.getOrgPoolTotal(),
});

class VolunteersController extends Component {
  static propTypes = {
    poolsTotal: ReactLibs.PropTypes.number,
    tab: ReactLibs.PropTypes.string,
  };

  static defaultProps = {
    pools: {},
    tab: "pools",
  };

  state = {
    active_tab: this.props.tab,
    is_modal_open: false,
  };

  handleTabChange = (active_tab) => {
    this.setState({ active_tab });
  };
  closeModal = () => {
    this.setState({ is_modal_open: false });
  };
  openCreatePoolModal = () => {
    this.setState({ is_modal_open: true });
  };

  render() {
    return (
      <>
        <Modal2 isOpen={this.state.is_modal_open} onClose={this.closeModal}>
          <PoolFormModal onClose={this.closeModal} />
        </Modal2>
        <PageHeader
          className="volunteers-controller--header"
          actions={<PoolsActions openCreatePoolModal={this.openCreatePoolModal} tab={this.state.active_tab} />}
          title="Volunteer Management"
          subtitle={
            <div className="pools--subtitle">
              {
                {
                  pools: <strong> {this.props.poolsTotal} Pools </strong>,
                  manage: (
                    <ContactsResolver
                      params={{ limit: 1, offset: 0 }}
                      query={SOLICITORS_BY_NAME_QUERY(undefined, undefined, "POOL")}
                      render={({ total = "" }) => <strong>{total} Volunteers</strong>}
                    />
                  ),
                }[this.state.active_tab]
              }
            </div>
          }
        />
        <Nav
          active={this.state.active_tab}
          className="volunteers-controller--nav"
          onSelect={(selected_tab) => {
            this.handleTabChange(selected_tab);
            EverTrue.UrlManager.navigate(`/volunteers/${selected_tab}`, true, false);
          }}
        >
          <NavItem activeWhen="pools" label="Prospect Pools" />
          <NavItem activeWhen="manage" label="Volunteers" />
        </Nav>
        {
          {
            pools: <VolunteersPoolsController openCreatePoolModal={this.openCreatePoolModal} />,
            manage: <VolunteersManagementController handleTabChange={this.handleTabChange} />,
          }[this.state.active_tab]
        }
      </>
    );
  }
}

export default connect(VolunteersController, [PoolStore], mapStateToProps);
