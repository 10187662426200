import classNames from "classnames";
import PropTypes from "prop-types";
import MyTripUtils from "apps/my-trips/utils/my-trips-utils";

const TripDate = ({ trip, className }) => {
  if (!trip) {
    return null;
  }

  const displayDate =
    trip.type === "trip"
      ? MyTripUtils.getTripDisplayDate(trip)
      : MyTripUtils.getMeetingDisplayDate(trip);

  return <span className={classNames("trip-date", className)}>{displayDate}</span>;
};

TripDate.propTypes = {
  trip: PropTypes.object,
  className: PropTypes.string,
};

export default TripDate;
