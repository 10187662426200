import { useRef, useState } from "react";
import { PropTypes } from "base/react-libs";
import { useOpenClose, Modal } from "@evertrue/et-components";
import ModalConfirm from "components/modals/modal-confirm";
import { useGlobalOutreachProvider } from "apps/outreach/contexts/global-outreach-context";
import classNames from "classnames";
import DrawerHeader from "./components/drawer-header";
import useOutsideClick from "hooks/use-outside-click";
import { useGlobalDrawerProvider } from "./context/global-drawer-context";

export const Drawer = ({
  id, // Unique UUID Generated by the GlobalDrawerContext
  type, // String indicating type of drawer (eg "dialer" "emailer" etc)
  drawerTitle = 'New Message', // String to Display in Header
  onClose = () => {}, // Function provided by GlobalDrawerContext
  drawerClassName = '', // Optional Styling to apply to drawer
  scrollable = false, // Indicator if content is scrollable
  children, // content of drawer
  headerIcon = null,
  closeModalProps = {}, // Props for the close modal
  onDrawerFullScreen = () => {}, //This is being used to disable drawer drag and drop when in fullscreen
  modalConfirmClassName = '', // Optional Styling to apply to modal confirm
}) => {
  const wrapperRef = useRef(null);
  const { onCall, CallTimer, endCall } = useGlobalOutreachProvider();
  const [isMinimized, minimize, maximize] = useOpenClose(false); // when a drawer is opened, default to Minimized == False
  const [isFullScreen, enterFullScreen, exitFullScreen] = useOpenClose(false); // when a drawer is opened, default to FullScreen == False
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false); // State to manage the visibility of the confirmation modal
  const { modalConfirmState } = useGlobalDrawerProvider(); // globalCOntextProvider to manage the state of the Modal Confirm Drawer by id

  const {
    modalTitle = 'Confirm Close', // Default title
    modalBody = <p>Are you sure you want to close this drawer?</p>, // Default body can be text/node
    confirmButtonLabel, // Default confirm button label
    confirmModalWidth = "400px", //Default is 400px medium size
  } = closeModalProps;

  // Track whether full-screen was enabled before minimization
  const [wasFullScreenBeforeMinimize, setWasFullScreenBeforeMinimize] = useState(false);

  useOutsideClick(wrapperRef, () => {
    // BUGGY WHEN WORKING WITH ListBoxSelect because ListBoxSelect items are not nested inside the drawer component, they are at top of page. So clicking them causes minimization.
    // if (!isMinimized) toggleMinimize();
  });

  // Safari-specific reflow fix
  const triggerReflow = () => {
    const container = document.querySelector('.drawers-container');
    if (container) {
      container.style.display = 'none'; // Hide temporarily
      void container.offsetHeight; // Trigger reflow
      container.style.display = 'flex'; // Show again
    }
  };

  // Toggle minimize/maximize
  const handleMinimize = () => {
    if (isFullScreen) {
      onDrawerFullScreen(false);
      exitFullScreen(); // Exit full-screen before minimizing
      triggerReflow(); // Safari fix
      setWasFullScreenBeforeMinimize(true); // Remember full-screen state
    }
    if (!isMinimized) {
      minimize();
    } else {
      maximize();
      if (wasFullScreenBeforeMinimize) {
        onDrawerFullScreen(true);
        enterFullScreen(); // Restore full-screen if it was active before minimization
        setWasFullScreenBeforeMinimize(false);
      }
    }
  };

  // Toggle full-screen mode
  const handleFullScreenToggle = () => {
    if (isMinimized) {
      // If minimized, return to normal state instead of full-screen
      maximize(); // Exit minimized state, return to normal state
      setWasFullScreenBeforeMinimize(false); // Ensure full-screen is not triggered automatically
    } else if (isFullScreen) {
      // If already full-screen, exit full-screen
      exitFullScreen();
      onDrawerFullScreen(false);
      triggerReflow(); // Safari-specific fix
    } else {
      // Otherwise, enter full-screen
      onDrawerFullScreen(true);
      enterFullScreen();
    }
  };

  const handleClose = () => {
    // Fetch the modal confirm state for the current drawer by its id
    const modalConfirmData = modalConfirmState[id];
    // Check if modalConfirmData exists, and if its type and value match the current drawer's type
    if (modalConfirmData && modalConfirmData.value === false && modalConfirmData.type === type) {
      setShowCloseConfirmation(true); // Show confirmation modal for this drawer
    } else {
      onClose(); // Directly close the drawer if no confirmation is required
    }
  };
  

  const confirmClose = () => {
    setShowCloseConfirmation(false); // Hide modal
    onClose(); // Call onClose after confirmation
  };

  return (
    <>
      {isFullScreen && <div className="drawer-backdrop" onClick={handleMinimize} />} {/* Backdrop for full-screen */}
      <div
        ref={wrapperRef}
        className={classNames(
          'drawer',
          isMinimized ? 'minimized' : '',
          isFullScreen ? 'fullscreen' : '',
          drawerClassName
        )}
      >
        <DrawerHeader
          type={type}
          onClose={handleClose}
          drawerTitle={drawerTitle}
          isMinimized={isMinimized}
          onMinimize={handleMinimize}
          isFullScreen={isFullScreen}
          onFullScreen={handleFullScreenToggle}
          wasFullScreenBeforeMinimize={wasFullScreenBeforeMinimize}
          headerIcon={headerIcon}
          onCall={onCall}
          CallTimer={CallTimer}
          endCall={endCall}
        />
        <div
          data-testid="drawer-content"
          className={classNames(
            isMinimized ? 'drawer--content-hidden' : 'drawer--content',
            { scrollable: scrollable },
            drawerClassName
          )}
        >
          {children}
        </div>
      </div>
      {/* Confirmation Modal */}
      {showCloseConfirmation && (
        <Modal
          isOpen={showCloseConfirmation}
          closeModal={() => setShowCloseConfirmation(false)}
          size={confirmModalWidth}
        >
          <ModalConfirm
            withModal
            header={modalTitle}
            buttonLabel={confirmButtonLabel}
            hasMargin
            closeModal={onClose}
            onSubmit={confirmClose}
            onCancel={() => setShowCloseConfirmation(false)}
            className={modalConfirmClassName}
          >
            {modalBody}
          </ModalConfirm>
        </Modal>
      )}
    </>
  );
};

Drawer.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  drawerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  drawerClassName: PropTypes.string,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  children: PropTypes.node,
  headerIcon: PropTypes.node,
  onDrawerFullScreen: PropTypes.func,
  closeModalProps: PropTypes.shape({
    modalTitle: PropTypes.string,
    modalBody: PropTypes.node,
    confirmButtonLabel: PropTypes.string,
    confirmModalWidth: PropTypes.string,
  }),
  modalConfirmClassName: PropTypes.string,
};

export default Drawer;
