import PropTypes from 'prop-types';
import classNames from 'classnames';
import EngagementHelper from 'apps/events/utils/engagement-config';

const renderTitle = (network, label) => {
  return [network, label]
    .filter(Boolean)
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' - ');
};
const EngagementIcon = ({ className, icon, action, tab, size = '24px', network, zIndex }) => {
  action = action || icon;
  size = typeof size === 'string' && size.endsWith('px') ? Number(size.replace('px', '')) : Number(size) || size;

  let src, label;
  if (tab) {
    ({ src, label } = EngagementHelper.getIconInfoByTabLabel(tab));
  } else {
    ({ src, label } = EngagementHelper.getEngagementInfoByValue(action));
  }

  const networkSrc = EngagementHelper.getNetworkImageByType(network);
  const networkSize = size * 0.5 || 12;

  return (
    <div
      className={classNames('engagement-icon', className)}
      style={{
        width: size,
        height: size,
        zIndex,
        backgroundImage: `url(${src})`,
      }}
      title={renderTitle(network, label)}
    >
      {networkSrc && (
        <div
          className="engagement-icon--network-image"
          style={{
            height: networkSize,
            width: networkSize,
            right: networkSize * -0.25,
            bottom: networkSize * -0.25,
            backgroundImage: `url(${networkSrc})`,
          }}
        />
      )}
    </div>
  );
};

EngagementIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.string,
  tab: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  network: PropTypes.string,
  zIndex: PropTypes.number,
};

export default EngagementIcon;
