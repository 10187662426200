import { getDateString } from 'base/new-utils';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import DatePickerDropdown from 'components/calendar/date-picker-dropdown';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';

const DueDatePicker = () => {
  const { selectedDueDate, setSelectedDueDate } = useGlobalJourneyProvider();
  return (
    <DatePickerDropdown
      date={selectedDueDate}
      onDayClick={(date) => {
        trackJourneysAction('view_calendar_date');
        setSelectedDueDate(getDateString(date));
      }}
      showDay={true}
      triggerClassName={'journeys-date-dropdown'}
      chevronClassName={'date-picker-chevron'}
      allowClear={false}
      buttonType="simple"
    />
  );
};

export default DueDatePicker;
