import PropTypes from 'prop-types';

const OverlayInstructions = ({ script = '', modifyScript = () => {}, toggleOff = () => {} }) => {
  return (
    <div id="recorder__instruction-view" className="signal-video-script--container">
      <textarea
        onChange={modifyScript}
        className="signal-video-script"
        placeholder="Click here to insert text"
        value={script}
      />
    </div>
  );
};

OverlayInstructions.propTypes = {
  script: PropTypes.string,
  modifyScript: PropTypes.func,
  toggleOff: PropTypes.func,
};

export default OverlayInstructions;
