import { PropTypes } from 'base/react-libs';
import { Button, useOpenClose, Icon } from '@evertrue/et-components';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { trackTaskAction } from 'apps/tasks/utils/utils';
import AddTask from 'apps/tasks/components/create/add-task';

const AddTaskButton = ({ contact = null, buttonType = 'default', optionalStateManagementFunction = () => {} }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const { flagEnabled: enabled, loaded } = useFeatureFlag('signal');

  const renderButton = () => {
    const buttonClass = isModalOpen ? 'round-task-button-focused' : 'round-button';
    if (buttonType === 'icon-btn')
      return (
        <Button type="secondary" onClick={isModalOpen ? closeModal : openModal} className={buttonClass}>
          {isModalOpen ? (
            <Icon icon="tasks" iconKit="gt2" style={{ color: '#A54AB5' }} />
          ) : (
            <Icon icon="tasks" iconKit="gt2" style={{ color: '#007C9E' }} />
          )}
        </Button>
      );
    else if (buttonType === 'icon')
      return (
        <Icon
          icon="tasks"
          iconKit="gt2"
          onClick={() => {
            trackTaskAction('add_task');
            isModalOpen ? closeModal() : openModal();
          }}
          isReachable={true}
          onKeyUp={() => {
            trackTaskAction('add_task');
            isModalOpen ? closeModal() : openModal();
          }}
          style={{ cursor: 'pointer' }}
        />
      );
    else
      return (
        <Button onClick={isModalOpen ? closeModal : openModal} type={buttonType}>
          + Add Task
        </Button>
      );
  };
  if (!loaded) return null;
  if (enabled) {
    return (
      <>
        <AddTask
          isOpen={isModalOpen}
          closeModal={closeModal}
          contact={contact}
          handleClose={closeModal}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
        {renderButton()}
      </>
    );
  }
};
AddTaskButton.propTypes = {
  contact: PropTypes.object,
  buttonType: PropTypes.string,
  optionalStateManagementFunction: PropTypes.func,
};
export default AddTaskButton;
