module.exports = do ->
   $ = require("jquery")
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, ul, li} = ReactLibs.DOMFactories
   GlobalAutocomplete = require("apps/layout/components/global-autocomplete")
   {createFactory} = require("base/new-utils")
   Dropdown = createFactory require("components/controls/dropdown")
   NotificationInboxController = require("apps/notifications/controllers/notification-inbox-controller")
   HeaderUserDropdown = require("apps/layout/components/header-user-dropdown")
   ChatLink = createFactory require("apps/layout/components/chat-link").default
   Icon = createFactory (require("components/elements/icon").default)
   IsGated = createFactory(require("components/is-gated").default)
   FlaggedComponent = createFactory(require("components/flagged-component").default)
   Link = createFactory(require("@evertrue/et-components").Link)
   Api = require("entities/helpers/api")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "HeaderView",
      getInitialState: ->
         searchFocus: false
         contactResults: undefined
         query: undefined
         user: undefined
         active_path: undefined

      componentDidMount: ->
         # Re-Trigger on Org Initialization and route change for features and org data
         EverTrue.vent.on "org:initialized storeReset route:change", @setData

      componentWillUnmount: ->
         EverTrue.vent.off "org:initialized storeReset route:change", @setData

      setData: ->
         return unless @depracated_is_mounted
         @setState user: EverTrue.store.user.toJSON()
         @setState active_path: window.location.pathname

      handleRedirect: ->
         Api.AUTH.FORUMBEE.post
            success: ({url}) =>
               window.open(url)
            error: ->
               window.open("https://evertrue.forumbee.com/")

      render: ->
         path = @state.active_path || ""
         
         div id: "base-header",
            div className: "base-header--search-container",
               div className: "base-header--search-wrapper",
                  GlobalAutocomplete
                     className: "base-header--search"
                     initialIndex: -1

            div className: "base-header--options",

               div className: "base-header--notifications",
                  NotificationInboxController()

               # This gate is specific for NYU, since beamer isn't accessible, we will hide it from them for the time being
               IsGated feature: "hide_a11y_compliance", inverse: true,
                  div className: "base-header--notifications beamerTrigger", tabIndex: 0, role: "button", "aria-label": "View Beamer",
                     Icon className: "base-header--beamer-icon", icon: "beamer"

               div className: "base-header--help",
                  Dropdown
                     className: "base-header--help-dropdown"
                     label: "Help"
                     header: div null,
                        Icon className: "base-header--help-dropdown-icon", icon: "help"
                     stay_open: true

                     ul className: "base-header--help-dropdown-content menu",
                        li null,
                           LinkButton 
                              title: "EverTrue Help Article"
                              onClick: => @handleRedirect()
                              Icon className: "help-dropdown--icon", icon: "resources", before: true, size: 1
                              "Help Articles"

                        unless EverTrue.request("isImpersonating?")
                           li null,
                              ChatLink
                                 onClick: ->
                                    $("body").trigger("click") # Close dropdown

                        li null,
                           Link href:"https://learn.evertrue.com/evertrue-university/", title: "Link to EverTrue University", target: "_BLANK", "data-bypass": true, rel: "noopener",
                              Icon className: "help-dropdown--icon", icon: "education", before: true, size: 1
                              "EverTrue University"


               div className: "base-header--user",
                  HeaderUserDropdown()
