import { Modal, ModalHeaderWithActions, ModalBody, Radio } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import ActiveJourney from '../profile/active-journey';
import { useState } from 'react';

const ProfileActionsRemoveFromCadenceModal = ({
  isOpen,
  onClose,
  activeJourneys = [],
  contactName = '',
  handleRemoveFromCadence,
}) => {
  const [selected, setSelected] = useState(null);
  return (
    <>
      <Modal isOpen={isOpen} closeModal={onClose} closeOnOutsideClick={true}>
        <ModalHeaderWithActions
          title="Move to Another Cadence"
          closeModal={onClose}
          onSubmit={() => handleRemoveFromCadence(selected)}
          submitLabel="Continue"
          disableSubmit={!selected}
        />
        <ModalBody>
          <div>
            <p className="active-journeys-modal--header">
              Please select the cadence from which you’d like to move the constituent from, then click Continue to
              choose a new cadence to move them to.
            </p>

            <ul style={{ padding: '10px' }}>
              {activeJourneys.length &&
                activeJourneys.map((journey) => {
                  return (
                    <li style={{ padding: '12px', cursor: 'pointer' }} key={journey.journey_id}>
                      <Radio
                        label="Select cadence"
                        className={`cadence-list-item`}
                        checked={journey?.journey_id === selected?.journey_id}
                        onChange={() => setSelected(journey)}
                      >
                        <ActiveJourney
                          journey={journey}
                          key={journey?.journey_id}
                          contactName={contactName}
                          style={{ margin: '0px 5px' }}
                        />
                      </Radio>
                    </li>
                  );
                })}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

ProfileActionsRemoveFromCadenceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  activeJourneys: PropTypes.array,
  contactName: PropTypes.string,
  handleRemoveFromCadence: PropTypes.func,
};

export default ProfileActionsRemoveFromCadenceModal;
