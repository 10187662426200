import _ from 'underscore';
import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';
import Decorator from 'clientDecorator';

const CompanySource = createSource('CompanySource', {
  actions: {
    fetchedCompany(company) {
      this.require(_.isObject(company), 'company should be object');
    },
    fetchedStats(stats) {
      this.require(_.isObject(stats), 'stats should be object');
    },
    fetchedCompaniesForIndustry(companies) {
      this.require(_.isObject(companies), 'companies should be object');
    },
    fetchedConstituents(constituents) {
      this.require(_.isObject(constituents), 'constituents should be object');
    },
    resetData: true,
  },

  getFilters(name, type) {
    switch (type) {
      case 'company':
        return [
          {
            'employments.company.untouched': {
              in: [name],
            },
          },
        ];
      case 'industry':
        return [
          {
            'employments.industry.untouched': {
              match: name,
            },
          },
        ];
      default:
        console.error('Invalid filter type in CompanySource');
        return [];
    }
  },

  async getLifetimeStats(filters) {
    return Api.SEARCH.STATS.post({
      data: JSON.stringify({
        must: filters,
        stats: [{ 'giving.lifetime_amount': {} }],
      }),
      success(resp) {
        return resp;
      },
    });
  },

  async getInstancesStat(filters) {
    return Api.CONTACTS.SEARCH.post({
      params: { limit: 500 },
      data: JSON.stringify({
        must: filters,
      }),
      success: (resp) => {
        const mappedItems = resp?.items?.map((contact) => Decorator.Contacts.parse(contact));
        resp.items = mappedItems;
        this.actions.fetchedConstituents(resp);
        return resp;
      },
    });
  },

  async getParticipationLifeStat(filters) {
    const must = [...filters, { 'giving.lifetime_amount': { gt: 0, coerce: 0 } }];
    return Api.SEARCH.STATS.post({
      data: JSON.stringify({
        must,
      }),
      success(resp) {
        return resp;
      },
    });
  },

  async getCurrentYearStat(filters, year) {
    const must = [
      ...filters,
      {
        giving_annual_donations: {
          instance: {
            fiscal_year: { match: year },
            amount: { gt: 0 },
          },
        },
      },
    ];

    return Api.CONTACTS.SEARCH.post({
      data: JSON.stringify({
        must,
      }),
      success(resp) {
        return resp;
      },
    });
  },

  async getLastYearStat(filters, year) {
    const must = [
      ...filters,
      {
        giving_annual_donations: {
          instance: {
            fiscal_year: { match: year - 1 },
            amount: { gt: 0 },
          },
        },
      },
    ];

    return Api.CONTACTS.SEARCH.post({
      data: JSON.stringify({
        must,
      }),
      success(resp) {
        return resp;
      },
    });
  },

  api: {
    resetData() {
      this.actions.resetData();
    },

    async fetchCompany(name) {
      await this.fetchStats(name, 'company');
      this.actions.fetchedCompany({ name });
    },

    async fetchStats(name, type) {
      const filters = this.getFilters(name, type);
      const year = new Date().getFullYear();

      const [lifetime, instances, participation_life, current_year, last_year] = await Promise.all([
        this.getLifetimeStats(filters),
        this.getInstancesStat(filters),
        this.getParticipationLifeStat(filters),
        this.getCurrentYearStat(filters, year),
        this.getLastYearStat(filters, year - 1),
      ]);

      const stats = { lifetime, instances, participation_life, current_year, last_year };

      this.actions.fetchedStats(stats);
    },

    fetchCompaniesForIndustry(name) {
      const key = 'employments.company.untouched';
      const filters = this.getFilters(name, 'industry');

      this.fetchStats(name, 'industry');

      return Api.CONTACTS.FACETS.post({
        data: JSON.stringify({
          must: filters,
          facet: [{ field: key }],
        }),
        success: (resp) => {
          this.actions.fetchedCompaniesForIndustry({
            name,
            companies: resp.facets[key],
          });
        },
      });
    },
  },
});

export default CompanySource;
