module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   {createFactory} = require("base/new-utils")
   Property = require("entities/search/query-parts/property-query")
   CommentMixin = require("mixins/comment-mixin")
   ThankViewInteractions = createFactory(require("apps/interactions/components/interaction-thankview").default)
   dompurify = require('dompurify')

   InteractionUtils =
      getHighlightRegex: (query_string) ->
         if _.isArray(query_string) then words = query_string
         else words = query_string?.split?(" ") || []
         _.compact(_.map(words, (word) ->
            trimmed_word = word?.toLowerCase()?.trim()
            if !_.isEmpty(trimmed_word)
               _.regexEscape(trimmed_word)
         )).join("|")

      getPropertiesForCategoryQuery: (categories) ->
         [ Property("interaction_type.untouched", categories, {type: "contains"}) ]

      # Add the ... to the beginning and end of the text if it's in the middle of the comment
      # Slice the text / show the text snippet unless @props.showFullText is set

      renderText: (interaction, showFullText, limit, showRichText) ->
         if showFullText
            if showRichText
               cleanHtml = dompurify.sanitize(interaction?.text || '')
               React.createElement('div', { dangerouslySetInnerHTML: { __html: cleanHtml } })
            else 
               interaction?.text
         else if interaction.interaction_type == "ThankView - Video" && window.location.pathname == "/contact/*"
            ThankViewInteractions
               interaction: interaction
         else if interaction.interaction_type == "ThankView - Video" && window.location.pathname == "/interaction/*"
            parts = interaction.text.split("\r\n\r\n\r\n\r\n")
            message = parts[0]
            CommentMixin.getSnippet(message, 200)

         else
            if _.isEmpty(interaction?._highlight)
               if interaction.interaction_type == "EverTrue Comment"
                  CommentMixin.getSnippet(interaction.text, 200)
               else if interaction.interaction_type == "ThankView - Video" && window.location.pathname == "/interaction/*"
                  parts = interaction.text.split("\r\n\r\n\r\n\r\n")
                  message = parts[0]
                  CommentMixin.getSnippet(message, message.length)
               else if interaction.interaction_type == "ThankView - Video" && window.location.pathname == "/contact/*"
                  ThankViewInteractions
                     interaction: interaction
               else
                  if showRichText
                     cleanHtml = dompurify.sanitize(interaction?.text || '')
                     # Create a temporary DOM element to parse the sanitized HTML
                     tempDiv = document.createElement('div')
                     tempDiv.innerHTML = cleanHtml
                     # Extract text content
                     textContent = tempDiv.textContent || tempDiv.innerText || ''
                     textContent.substr(0, 200) + (if textContent.length > 200 then "..." else "")
                  else 
                     text = interaction.text || ""
                     text.substr(0, 200) + (if text.length > 200 then "..." else "")
            else
               highlights = interaction?._highlight?.text
               limit = limit || highlights?.length

               start_highlight = _.first(highlights)
               highlight_index = interaction?.text?.indexOf?(start_highlight)

               prefix = if highlight_index == 0 then "" else "..."
               suffix = if highlight_index + start_highlight.length < interaction?.text?.length then "..." else ""
               prefix + _.first(highlights, limit)?.join("...") + suffix

   InteractionUtils
