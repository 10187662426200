import PropTypes from 'prop-types';
import { PageHeader } from '@evertrue/et-components';
import OutreachNav from '../components/outreach-nav';
import OutreachEmailComponent from '../components/outreach-email-component';


const OutreachController = ({ view }) => {
  
  return (
    <>
      <PageHeader title="Outreach" />
      <OutreachNav tab={view} />
      {view === 'email' && <OutreachEmailComponent />}
    </>
  );
};

OutreachController.propTypes = {
  view: PropTypes.string,
};

export default OutreachController;
