import ActiveJourneyPill from 'components/elements/active-journey-pill';
import ActiveJourneysModal from './active-journeys-modal';
import { useOpenClose } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';

const ActiveJourneysPills = ({ activeJourneys, contactName }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <ActiveJourneyPill activeCadence={activeJourneys[0]} type={'profile'} contactName={contactName} />
        {activeJourneys.length > 1 && (
          <div className="profile-header--cadence" onClick={openModal}>
            <p>+ {activeJourneys.length - 1} more</p>
          </div>
        )}
      </div>
      <ActiveJourneysModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        activeJourneys={activeJourneys}
        contactName={contactName}
      />
    </>
  );
};

ActiveJourneysPills.propTypes = {
  activeJourneys: PropTypes.array,
  contactName: PropTypes.string,
};

export default ActiveJourneysPills;
