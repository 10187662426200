import { Button } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { getMomentObjectInTimezone } from 'base/new-utils';
import ResumePauseModalButton from 'apps/settings/journey-settings/resume-pause-modal-button';
import useJourneyTimeoutsApi from 'apps/journeys/hooks/use-journey-timeouts-api';

const JourneyPauseContainer = ({ refreshTasks = () => {} }) => {
  const { inProgressJourneyTimeout, handleResume } = useJourneyTimeoutsApi();

  return (
    <>
      {inProgressJourneyTimeout && (
        <div className="journeys-pause-container">
          <div>
            <h5 className="bold-text">
              {`Your cadences and tasks are paused until ${getMomentObjectInTimezone(
                inProgressJourneyTimeout?.ends_on
              ).format('dddd, MMMM DD, YYYY')}.`}
            </h5>
            <span>We hope you are having a restful break!</span>
          </div>
          <div>
            <ResumePauseModalButton handleResume={handleResume} updateTaskCount={refreshTasks} />
            <Button
              href="settings/cadences"
              onClick={() => {
                trackJourneysAction('edit_cadence_pause');
              }}
            >
              Edit Pause
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

JourneyPauseContainer.propTypes = {
  refreshTasks: PropTypes.func,
};

export default JourneyPauseContainer;
