import { useOpenClose } from '@evertrue/et-components';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import useTwilio from 'components/dialer/voip-services/twilio/hooks/use-twilio';
import { bulkDeleteDrafts, getAllDraftsByUser, deleteDraftById } from 'apps/outreach/utils/draft-email-api';
import {
  getAllScheduledByUser,
  bulkDeleteScheduled,
  deleteScheduledById,
  cancelScheduledEmail,
} from 'apps/outreach/utils/scheduled-email-api';
import PropTypes from 'prop-types';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { getAllSentByUser } from '../utils/sent-emails-api';
import { useIdentity } from 'base/identity-resolver';

const GlobalOutreachContext = createContext(() => {});

const GlobalOutreachProvider = ({ children }) => {
  // establish outreach device for Voip Tool. Today, we use Twilio. if we ever replace Twilio, replace it here
  const voipState = useTwilio();
  const { user = {} } = useIdentity();

  // State Management of the Quick Interaction Modal, and Data used to populate that modal.
  const [quickInteractionData, setQuickInteractionData] = useState({});
  const [isQuickInteractionOpen, openQuickInteraction, closeQuickInteraction] = useOpenClose();
  const [drafts, setDrafts] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [sent, setSent] = useState([]);
  const [draftCount, setDraftCount] = useState(0);
  const [scheduledCount, setScheduledCount] = useState(0);
  const { flagEnabled: hasDraftsAndScheduled } = useFeatureFlag('outreachDraftsScheduled');

  // open the modal and update global state to include the data from the outreach attempt
  const openQuickInteractionModalWithData = useCallback(
    (type, args) => {
      const quickInteractionFields = {
        saveLabel: 'Save',
        cancelLabel: 'Skip',
        type,
        ...args,
      };

      // open a modal with the relevant data pre-populated
      setQuickInteractionData(quickInteractionFields);
      openQuickInteraction();
    },
    [openQuickInteraction]
  );

  // any time the quick interaction modal closes, reset the global state to empty
  const closeQuickInteractionWithData = () => {
    closeQuickInteraction();
    setQuickInteractionData({});
  };

  const getAllDrafts = async () => {
    const draftsList = await getAllDraftsByUser();
    if (draftsList?.items) setDrafts(draftsList.items);
    setDraftCount(draftsList?.total || 0);
  };

  const getAllScheduled = async () => {
    const scheduledList = await getAllScheduledByUser();
    if (scheduledList?.items) setScheduled(scheduledList.items);
    setScheduledCount(scheduledList?.total || 0);
  };

  const deleteScheduledSendById = async (scheduledId) => {
    await deleteScheduledById(scheduledId);
    setScheduled((previous) => previous.filter((scheduled) => scheduled.id !== scheduledId));
    setScheduledCount((previousCount) => previousCount - 1);
  };

  const getAllSent = useCallback(async () => {
    const sentList = await getAllSentByUser(user?.id);

    if (sentList?.items) {
      const threadMap = new Map();

      sentList.items.forEach((email) => {
        const threadId = email.nylas_thread_id;

        if (!threadMap.has(threadId)) {
          threadMap.set(threadId, { ...email, thread_count: 0 });
        }

        const parentEmail = threadMap.get(threadId);
        if (email.sent_at < parentEmail.sent_at) {
          threadMap.set(threadId, { ...email, thread_count: parentEmail.thread_count + 1 });
        } else {
          threadMap.set(threadId, { ...parentEmail, thread_count: parentEmail.thread_count + 1 });
        }
      });

      const filteredSent = Array.from(threadMap.values()).map((email) => ({
        ...email,
        thread_count: email.thread_count - 1, // Exclude the parent email itself from the count
      }));

      setSent(filteredSent);
    }
  }, [user]);

  const deleteScheduledSends = async (selectedScheduledIds) => {
    await bulkDeleteScheduled(selectedScheduledIds);
    setScheduled((previousScheduled) =>
      previousScheduled.filter((scheduled) => !selectedScheduledIds.includes(scheduled.id))
    );
    setScheduledCount((previousCount) => previousCount - selectedScheduledIds.length);
  };

  const deleteDrafts = async (selectedDraftIds) => {
    await bulkDeleteDrafts(selectedDraftIds);
    setDrafts((previousDrafts) => previousDrafts.filter((draft) => !selectedDraftIds.includes(draft.id)));
    setDraftCount((previousCount) => previousCount - selectedDraftIds.length);
  };

  const deleteDraft = async (draftId) => {
    await deleteDraftById(draftId);
    setDrafts((previousDrafts) => previousDrafts.filter((draft) => draft.id !== draftId));
    setDraftCount((previousCount) => previousCount - 1);
  };

  const cancelScheduled = async (selectedScheduledId) => {
    await cancelScheduledEmail(selectedScheduledId);
    setScheduled((previousScheduled) =>
      previousScheduled.filter((scheduled) => !selectedScheduledId.includes(scheduled.id))
    );
    setScheduledCount((previousCount) => previousCount - 1);
  };

  useEffect(() => {
    if (hasDraftsAndScheduled) {
      getAllScheduled();
      getAllDrafts();
      getAllSent();
    }
  }, [hasDraftsAndScheduled, getAllSent]);

  return (
    <GlobalOutreachContext.Provider
      value={{
        quickInteractionData,
        isQuickInteractionOpen,
        closeQuickInteraction: closeQuickInteractionWithData,
        openQuickInteraction: openQuickInteractionModalWithData,
        ...voipState,
        drafts,
        setDrafts,
        getAllDrafts,
        scheduled,
        setScheduled,
        getAllScheduled,
        deleteScheduledSends,
        deleteScheduledSendById,
        cancelScheduled,
        deleteDrafts,
        deleteDraft,
        getAllSent,
        sent,
        setSent,
        draftCount,
        scheduledCount,
      }}
    >
      {children}
    </GlobalOutreachContext.Provider>
  );
};

const useGlobalOutreachProvider = () => {
  const context = useContext(GlobalOutreachContext);
  if (!context) {
    throw new Error('useGlobalOutreachProvider must be used within a GlobalOutreachProvider');
  }
  return context;
};

GlobalOutreachProvider.propTypes = { children: PropTypes.any };

export { GlobalOutreachProvider, GlobalOutreachContext, useGlobalOutreachProvider };
