import Api from 'entities/helpers/api';
import OrgStore from 'base/org/org-store';
import EverTrue from 'app';
import { trackTaskAction } from 'apps/tasks/utils/utils';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const updateEmailAlerts = (prefs) => {
  const { email_enabled } = prefs;
  trackTaskAction('update_notification', email_enabled);

  Api.EMS.PREFS_BY_KEY.put({
    urlArgs: { key: `journeys:tasks:oid-${OrgStore.getCurrentOrg().id}:` },
    data: JSON.stringify(prefs),
  })
    .then(() => {
      EverTrue.Alert.success('Your email alert preferences have been updated.');
    })
    .catch(() => {
      EverTrue.Alert.error('There was a problem updating your email alert preferences.');
    });
};

export const setTaskCompletion = async ({ id, title }, isComplete) => {
  return await Api.JOURNEYS.COMPLETE_TASK.put({
    urlArgs: { task_id: id },
    params: { complete: isComplete },
    success: () => {
      isComplete
        ? EverTrue.Alert.success(`${title} Completed!`)
        : EverTrue.Alert.success(`${title} marked as incomplete`);
    },
    error() {
      return EverTrue.Alert.error('Task could not be completed. Please try again.');
    },
  });
};

export const skipTask = async ({ id, contact }, reason, { title }) => {
  const { contact_attributes = {} } = contact;
  const { name_full } = contact_attributes;

  const message = name_full && title ? `${title} task for ${name_full} skipped successfully.` : 'skipped to next step';

  return await Api.JOURNEYS.SKIP_TASK.put({
    urlArgs: { task_id: id },
    params: { reason },
    success: () => {
      EverTrue.Alert.success(message);
    },
    error(e) {
      return EverTrue.Alert.error('Unable to skip task. Please try again');
    },
  });
};

export const createAction = async (action) => {
  return await Api.OUTREACH.CALL.post({
    data: JSON.stringify(action),
    error(e) {
      ErrorLogger.error('Error creating call', { extra: { e, page_name: 'dialer' } });
    },
  });
};
