module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, input, li, ul, strong} = ReactLibs.DOMFactories
   ArrowSelectMixin = require("mixins/arrow-select-mixin")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   classNames = require("classnames")


   createComponent "TagInput",
      mixins: [ArrowSelectMixin]
      propTypes:
         options: ReactLibs.PropTypes.array
         selected: ReactLibs.PropTypes.array
         placeholder: ReactLibs.PropTypes.string
         itemContent: ReactLibs.PropTypes.func
         focusOnMount: ReactLibs.PropTypes.bool
         onTag: ReactLibs.PropTypes.func
         onType: ReactLibs.PropTypes.func
         disabled: ReactLibs.PropTypes.bool
         handleUnselect: ReactLibs.PropTypes.func

      # Selectable List for ArrowSelectMixin
      getSelectable: ->
         @props.options

      getInitialState: ->
         {query: undefined}

      componentDidMount: ->
         @setState {selectedIndex: 0}

         if @props.focusOnMount
            $(@refs.arrowSelectInput).focus()

      handleType: (query, e) ->
         @setState {selectedIndex: 0, query: if _.isEmpty(query?.trim()) then undefined else query?.trim()}
         @props.onType?(query, e)

      handleKeyEnter: (index) ->
         tag = @props.options?[index]
         tags = @props.selected || []
         new_tags = tags.concat(tag) if tag
         @props.onTag?(new_tags)

         @setState {selectedIndex: 0, query: undefined}
         @refs.arrowSelectInput.value = ""

      handleClear: (tag_id) ->
         tags = @props.selected
         tag = _.findWhere tags, {id: tag_id}
         @props.onTag?(_.without(tags, tag))
         if typeof @props.handleUnselect is "function"
            @props.handleUnselect(tag_id)

      handleEmptyBackspace: ->
         @handleClear(_.last(@props.selected)?.id)
         @setState {selectedIndex: 0, query: undefined}

      handleEscape: (evnt) ->
         @props.onEscape?(evnt)

      handleClick: ->
         $(@refs.arrowSelectInput).focus()

      render: ->
         div className: classNames("tag-input", {"is_disabled": @props.disabled}) , onClick: @handleClick,
            ul className: "tag-input--tags",

               # Render Tagged Items
               if _.isEmpty(@props.selected)
                  Icon className: "tag-input--search-icon", icon: "search"
               else
                  _.map @props.selected, (tag = {}) =>
                     li key: "tag_#{tag.id}_#{tag.name || ""}", className: tag.className,
                        tag.name || tag.contact_attributes.name_full # for graphql
                        Icon icon: "cancel", size: 1, after: true, onClick: (=> @handleClear(tag.id)),

               li className: "tag-input--wrapper",
                  input
                     type: "text"
                     ref: "arrowSelectInput"
                     className: "tag-input--input"
                     onKeyUp: @handleKeyPress
                     onKeyDown: @handleKeyDown
                     onFocus: @props.onFocus
                     onBlur: @props.onBlur
                     placeholder: @props.placeholder
                     disabled: @props.disabled
                     "aria-label": @props.placeholder

                  if @state.query
                     ul className: "tag-input--autocomplete", ref: "arrowSelectBody",
                        if @props.loading
                           li className: "tag-input--empty",
                              "Loading results for "
                              strong null, @state.query
                              "..."
                        else if @state.query && _.isEmpty(@props.options)
                           li className: "tag-input--empty",
                              "No results found matching "
                              strong null, @state.query
                        else
                           # Render Typeahead Autocomplete
                           _.map @props.options, (item, index) =>

                              li
                                 key: "typeahead_#{item.name}_#{item.id || ""}"
                                 className: if index == @state.selectedIndex then "is-selected" else ""
                                 onMouseOver: => @handleHoverSelect(index)
                                 onClick: => @handleKeyEnter(index)
                                 @props.itemContent?(item)
