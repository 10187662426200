/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import ChatInputField from './chat-input-field';
import ChatMessages from './chat-messages';
import PropTypes from 'prop-types';
import useChat from '../hooks/useChat';
import Loading from 'components/elements/loading';
import { Icon } from '@evertrue/et-components';
import get from 'lodash.get';

const AiProfileChat = ({ contact, disableModalConfirm=()=>{}}) => {
  const [userInput, setUserInput] = useState('');
  const [userMessages, setUserMessages] = useState([]);
  const requestIndexRef = useRef(0);
  const currentRequestIndexRef = useRef(0);
  const { id: contactId, name: contactFullName } = contact;
  const contactFirstName = get(contact, 'properties.name_first.value', contactFullName);
  const { createChat, sendMessage, aiResponse, isStreaming, chat } = useChat(contactId, requestIndexRef.current);
  const [suggestedSearchClicked, setSuggestedSearchClicked] = useState(false);

  const { request_id: requestId } = chat;

  const suggestedSearches = [
    `What is ${contactFirstName}'s lifetime giving total?`,
    `When was our most recent interaction with ${contactFirstName}?`,
    `What is ${contactFirstName}'s job?`,
    `Prep me for a meeting with ${contactFirstName}.`,
  ];

  useEffect(() => {
    if (userMessages.length <= 0) {
      disableModalConfirm(true);
    } else {
      disableModalConfirm(false);
    }
  }, [userMessages, userInput]);

  const suggestedSearchesHeading = (
    <div className="heading">
      <h3>Ask me anything you’d like about {contactFirstName}.</h3>
      <p>I know all about giving, proposals, assignments, interactions, and more!</p>
    </div>
  );

  const handleSuggestedSearchClick = (text) => {
    setUserInput(text);
    setSuggestedSearchClicked(true);
  };

  useEffect(() => {
    if (suggestedSearchClicked) {
      handleSubmit();
      setSuggestedSearchClicked(false);
    }
  }, [userInput, suggestedSearchClicked]);

  useEffect(() => {
    createChat();
  }, []);

  const handleInput = (input) => {
    setUserInput(input);
  };

  const handleSubmit = () => {
    const currentIndex = currentRequestIndexRef.current;
    requestIndexRef.current = currentIndex;
    sendMessage(userInput);
    setUserMessages((prevMessages) => [...prevMessages, { content: userInput, index: currentIndex }]);
    setUserInput('');
    currentRequestIndexRef.current++;
  };

  return (
    <div className="chat-bot">
      {Object.keys(chat).length > 0 ? (
        <>
          <ChatMessages
            userMessageRequests={userMessages}
            aiResponses={aiResponse}
            isStreaming={isStreaming}
            suggestedSearches={suggestedSearches}
            showSuggestedSearchesHeading={true}
            suggestedSearchesHeading={suggestedSearchesHeading}
            handleSuggestedSearchClick={handleSuggestedSearchClick}
          />
          <ChatInputField
            userInput={userInput}
            handleInput={handleInput}
            onSubmit={handleSubmit}
            placeholder="Type here..."
          />
          <div className="chat-bot-warning">
            <Icon icon="warning" style={{ fontSize: '11px' }} />
            <p>AI works best when paired with human expertise. For key tasks, double check results.</p>
          </div>
          <div className="request-id">
            {requestId && (
              <p>
                <small>{`Request ID: ${requestId}`}</small>
              </p>
            )}
          </div>
        </>
      ) : (
        <div className="loader-div">
          <Loading spinner_size="small" position="center" />
        </div>
      )}
    </div>
  );
};

AiProfileChat.propTypes = {
  contact: PropTypes.object,
  disableModalConfirm: PropTypes.func,
};

export default AiProfileChat;
