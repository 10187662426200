module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   UrlSource = require("apps/layout/sources/url-source")
   ContactSource = require("apps/contact/sources/contact-source")
   PropertySource = require("apps/contact/sources/property-source")
   FilterStore = require("apps/filters/stores/filter-store")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   GateSource = require("apps/layout/sources/gate-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default

   _timeout = undefined

   _defaultSort = ->
      sort_param_query = {}
      if FeatureStore.hasFeature("scores")
         sort_param_query["score.score"] = {order: "desc"}
      else
         sort_param_query["giving.et_donor_index"] = {order: "asc"}
      sort_param_query

   _isDefaultSort = (sort_object, check_order) ->
      score = sort_object["score.scores"]
      index = sort_object["giving.et_donor_index"]
      if check_order
         (score && score.order == "desc") || (index && index.order == "asc")
      else score || index

   # parse sort object into query (properties are mangled for display)
   _parseSortQuery = (sort) ->
      sort_param_query = _.map sort, (q, key) ->
         _.toObject key.split(":")[0], q
      sort_param_query.push(_defaultSort()) unless _isDefaultSort(sort)
      sort_param_query

   _getPageParams = (page, limit, total) ->
      # TODO: pull this logic in a util
      if total
         max_page = Math.ceil(total / limit)
         page = _.withinRange(page, 1, max_page)
         if page <= 1 then page = 1
         else if page > max_page then page = max_page

      params =
         offset: Math.max(page - 1, 0) * limit
         limit: limit
      params


   createStore "ContactQueryStore",
      getInitialState: ->
         contacts: {limit: 50, items: []}
         has_schema: false
         loading: true
         sort: _defaultSort()
         page: 1
         query_type: undefined

      registerActions: ->
         @on UrlSource.actions.initializedUrl, @respondToInitialUrl
         @on UrlSource.actions.changedSort, @respondToSortChange
         @on UrlSource.actions.changedPage, @respondToPageChange

         @on ContactSource.actions.loadingContacts, @respondToLoading
         @on ContactSource.actions.searchedContacts, @respondToSearch
         @on ContactSource.actions.initQuery, => @respondToQueryTrigger(true)
         @on ContactSource.actions.query, @respondToQueryTrigger
         @on ContactSource.actions.reset, @respondToReset

         @on CoreSearchSource.actions.query, (type) ->
            if type == "contacts" then @respondToQueryTrigger()

         @on PropertySource.actions.fetchedSchema, @respondToSchemaChange
         @on PropertySource.actions.loadingSchema, @respondToSchemaLoading
         @on ListContactSource.actions.loadingListContacts, @respondToLoading
         @on GateSource.actions.fetchedGates, @respondToGates

      respondToInitialUrl: (url) ->
         @setState
            sort: url.sort || _defaultSort()
            page: _.toNumber(url.page) || 1
         @query()

      respondToQueryTrigger: (prevent_pagination) ->
         @setState {query_type: "filter"}
         if prevent_pagination then @query()
         else UrlSource.updatePage(1)

      respondToReset: ->
         @setState
            contacts: {limit: 50, items: []}
            loading: true

      respondToSortChange: (sort, silent) ->
         @setState
            sort: if _.isEmpty(sort) then _defaultSort() else sort
            query_type: @getState("query_type") || "sort"

         unless silent
            clearTimeout(_timeout)
            _timeout = _.delay((=> @query()), 50)
         UrlSource.updatePage(1)

      respondToPageChange: (page, silent) ->
         @setState {page: page, query_type: @getState("query_type") || "page"}
         unless silent
            clearTimeout(_timeout)
            _timeout = _.delay((=> @query()), 50)

      respondToLoading: (isLoading) ->
         @setState {loading: isLoading}

      respondToSearch: (contacts) ->
         page = (contacts.offset / contacts.limit) + 1
         page = _.withinRange(page, 1, Math.ceil(contacts.total/contacts.limit))

         sort = @getState("sort")
         sort_key = _.first _.keys sort
         sort_order = sort?[sort_key]?.order

         new_contacts = _.extend {items: []}, contacts,
            page: @getState("page")
            sortProp: sort_key
            sortReverse: sort_order is "desc"

         @setState
            contacts: new_contacts
            page: page
            query_type: undefined

      respondToSchemaChange: ->
         @setState {has_schema: true}
         @query()

      # Show loading when schema is loading for org change
      respondToSchemaLoading: (is_loading) ->
         @setState {loading: true}

      respondToGates: ->
         if _isDefaultSort(@getState("sort"), true)
            @setState sort: _defaultSort()
            UrlSource.updateSort(_defaultSort())

      query: ->
         clearTimeout(_timeout)
         return unless @hasListeners() && @getState("has_schema")
         return if FilterStore.getLoading()
         contacts_data = @getState("contacts")

         # TODO: run some of this logic in a util
         query = FilterStore.getActiveFiltersQuery()
         sort_query = _parseSortQuery(@getState("sort"))
         query = _.extend({}, query, sort: sort_query) if sort_query

         SelectedContactsSource.clear()

         ContactSource.search
            query: query
            params: _getPageParams(@getState("page"), contacts_data.limit, contacts_data.total)

      api:
         getContacts: ->
            @getState("contacts")

         getLoading: ->
            @getState("loading")

         getSortQuery: ->
            sort: _parseSortQuery(@getState("sort"))
