import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const ProfileEventsSource = createSource('ProfileEventsSource', {
  actions: {
    profileEventEngagementsLoading: true,
    profileEventEngagementsSuccess: true,
    profileEventEngagementsError: true,
  },

  api: {
    fetchProfileEventEngagements(contact_id) {
      const query = {
        must: [{ contact_id: { match: contact_id } }],
        facet: [{ field: 'engagement_action' }],
        sort: [{ engaged_at: { order: 'desc' } }],
      };

      this.actions.profileEventEngagementsLoading(contact_id);
      return Api.EVENTS.ENGAGEMENTS.post({
        params: { limit: 10 },
        data: JSON.stringify(query),
        success: (resp) => {
          this.actions.profileEventEngagementsSuccess(contact_id, resp);
        },
        error: () => {
          this.actions.profileEventEngagementsError(contact_id);
        },
      });
    },
  },
});

export default ProfileEventsSource;
