module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Backbone = require("backbone")
   { createRoot } = require("react-dom/client")
   config = require("config/env")
   routeFilter = require("backbone.routefilter") # unused in file but required to to ensure code is loaded
   AppRouter = require("base/app-router").default
   Session = require("entities/auth/session")
   User = require("entities/auth/user")
   Org = require("entities/auth/org")
   DNA = require("entities/dna/dna")
   UrlManager = require("entities/helpers/url-helper")
   AnalyticsHelper = require("entities/helpers/analytics-helper")
   LidsService = require("entities/lids/services")
   AlertSource = require("apps/layout/sources/alert-source")
   MetaController = require("apps/layout/controllers/meta-controller")
   LayoutSource = require("apps/layout/sources/layout-source")
   classNames = require("classnames")
   UserSource = require("base/user/user-source")
   StatusSource = require("apps/layout/sources/status-source")
   MfaSource = require("base/mfa/mfa-source")
   OrgSource = require("base/org/org-source").default
   SessionSource = require("base/session/session-source")
   Cookies = require("entities/helpers/cookies-helper")
   LoginWaitController = require("apps/login/controllers/login-wait-controller").default
   LoginStore = require("apps/login/stores/login-store").default # unused in file but required to to ensure code is loaded
   ErrorLogger = require("entities/helpers/error-log-helper")
   { applySatismeter } = require("entities/helpers/satismeter-helper")
   { userIdentity } = require("entities/helpers/user-identity")

   class Setup
      init: ->
         # Set elements on the global Application object
         EverTrue.track = new AnalyticsHelper()
         EverTrue.UrlManager = new UrlManager()
         EverTrue.Alert = AlertSource
         EverTrue.classSet = classNames # window.classNames

         # Initialize Router and Start app

         _startRouter = ->
            if not EverTrue.store.org.get("id")
               if _id = _.toNumber(Cookies.get("gt_oid"))
                  if EverTrue.store.user.isSuperUser() or _.contains(_.pluck(EverTrue.store.user.getOrganizations(), "id"), _id)
                     # set org id so that the router won't redirect to `/login/org`
                     EverTrue.store.org.set(id: _id)
                     # fetch additonal org data to make sure we have roles, etc
                     OrgSource.fetch(_id)

            unless Backbone.History.started
               Backbone.history.start {pushState: true}

         EverTrue.start = (options) ->
            router = new AppRouter()
            EverTrue.Router = router
            # if we don't have a session and we're on an authenticated route, try to get a session
            route = window.location.pathname.slice(1)
            if route in _.without(router.noAuthRoutes, "login")
               _startRouter()
            else if EverTrue.store.session.get("token")
               _startRouter()
            else
               EverTrue.mount LoginWaitController()
               SessionSource.startInitialSessionAttempt()

         # Set Main Application Regions
         EverTrue.page =
            mount: (component) ->
               LayoutSource.mountContent(component)
            unmount: ->
               # We're unmounting on each page route
               # to ensure that componentDidMount gets called
               # on page level controllers
               # TODO: change this to let React do it's thing
               LayoutSource.unmountContent()

         EverTrue.mount = (component) ->
            container = $("#main-container")[0]
            root = createRoot(container)
            root.render(component)

         # Application Request Handlers
         # Call with EverTrue.request("request_name")
         EverTrue.reqres.setHandler "isAuthorized?", @isAuthorized
         EverTrue.reqres.setHandler "isMobile?", @isMobile
         EverTrue.reqres.setHandler "isImpersonating?", @isImpersonating

         # Application Command Handlers
         # Call with EverTrue.execute("command_name")
         EverTrue.commands.setHandler "logout", @logout, @
         EverTrue.commands.setHandler "resetApp", @resetApp, @
         EverTrue.commands.setHandler "enterApp", @enterApp, @
         EverTrue.commands.setHandler "startRouter", _startRouter, @
         EverTrue.commands.setHandler "requestMfaCode", @requestMfaCode, @
         EverTrue.commands.setHandler "promptForLinkedin", @promptForLinkedin, @
         EverTrue.commands.setHandler "setVersionClass", @setVersionClass, @
         EverTrue.commands.setHandler "setBrowserTitle", @setBrowserTitle, @
         EverTrue.commands.setHandler "setTitleCount", @setTitleCount, @
         EverTrue.commands.setHandler "removePageContainer", @removePageContainer, @
         EverTrue.commands.setHandler "goToInitialPage", @gotoInitialPage, @
         EverTrue.commands.setHandler "setPageReferrer", @setPageReferrer, @
         EverTrue.commands.setHandler "setMobileRedirect", @setMobileRedirect, @
         EverTrue.commands.setHandler "switchEnvironment", @switchEnvironment, @
         EverTrue.commands.setHandler "switchOrg", @switchOrg, @

         # Globally prevent default on click unless data-bypass is set
         # navigate with Backbone to the href unless it's "#"
         #
         # We bind to `body` so that we can intercept native events
         #    before react gets them on `document` and call stopPropagation
         $("body").on "click", "a", @linkOverride

         # Hack to fix circular dependency with
         # FeatureStore + OrgSource because of the Org model
         EverTrue.OrgClass = Org

         # Setup global ajax error handling for external api requests
         @ajaxErrorSetup()

         # Get data from localStroage and add to EverTrue.store
         @fetchDataFromStore()

         # Run Satismeter code to provide a survey
         @startSatismeter()

         # Setup Browser Nag Alert for Outdated Browsers
         @browserNag()

         # Setup idle timeout function to run when user has been inactive for 30 minutes
         $(document).ready =>
            @idleTimer()

         # Check Status page for outages
         StatusSource.check()

         # Mount MetaController
         $meta_container = $("#meta-container")[0]
         if $meta_container
            root = createRoot($meta_container)
            root.render(MetaController())

      isAuthorized: ->
         !!EverTrue.store.session?.get("token")

      isImpersonating: ->
         !!EverTrue.store.admin_impersonator?.get("id")

      isMobile: ->
         !!navigator.userAgent.match(/(iPhone|iPod|iPad)/i)

      logout: ->
         SessionSource.logout()
         @resetApp()

      resetApp: ->
         if window.location.pathname isnt "/login"
            $("body").removeClass("ui-version-latest")
            MfaSource.cancelMfaRequest()
            @setPageReferrer()
            EverTrue.execute("app:cleanup")
            EverTrue.vent.off()
            EverTrue.commands.removeAllHandlers()
            EverTrue.reqres.removeAllHandlers()
            EverTrue.store.destroy()
            OrgSource.clearAll()
            EverTrue.inApp = false
            localStorage?.removeItem("backboneCache")
            EverTrue.track.reset()
            EverTrue.Navigator "/login", true
            Backbone.history.stop()
            Cookies.clear("gt_oid")
            @init()

      enterApp: ->
         if !EverTrue.inApp
            EverTrue.vent.trigger("enter:app")
            EverTrue.auth.lids_token = undefined
            EverTrue.inApp = true
            unless @isImpersonating()
               EverTrue.track.setUserData(EverTrue.store.user)
               EverTrue.track.setFullStoryUser(EverTrue.store.user)
               userIdentity({user: EverTrue.store.user});

            if EverTrue.store.org.get("id")
               UserSource.setRavenUser()
               OrgSource.set(EverTrue.store.org.toJSON())

      requestMfaCode: (request, response) ->
         MfaSource.requestLoginCode(request, response)

      promptForLinkedin: ->
         # this is handled via an EverTrue.execute to get around the
         # circular dependencies with sources require api.coffee
         SessionSource.lidsIsUnauthorized(true)

      gotoInitialPage: ->
         ref = EverTrue.store.page_referrer
         route = if ref && !_.contains(["/login", "/logout", "/redirect"], ref) then ref else "/"

         if EverTrue.store.org.get("id")
            EverTrue.store.destroy("page_referrer", true)

         Backbone.history.navigate("/redirect", {trigger: false, replace: true})
         # this adds a location to the history that isn't '/lids/callback', so that we won't land
         # there when the user hits the back button from a profile.
         # the route also can't be something valid like '/' because then the navigate call
         # below won't trigger because it is the same
         try
            Backbone.history.navigate(route, {trigger: true, replace: false})
         catch error
            ErrorLogger.error("Initial Page Routing Error", {extra: {route: route, error: error}})
            Backbone.history.navigate("/", {trigger: true, replace: false})
         # this actually directs the user to their previous page

      # Store the original url so that we can redirect the user
      # if they have been following a link to a page within the app
      setPageReferrer: ->
         route = window.location.pathname + window.location.search
         return if route?.match("login") || route?.match("lids\/access")
         EverTrue.store.push "page_referrer", route, {cache: true, acrossSession: true}

      setVersionClass: (version) ->
         $wrapper = $("body")
         if version == 2 && !$wrapper.hasClass("ui-version-latest")
            $wrapper.addClass("ui-version-latest")
         else if version != 2
            $wrapper.removeClass("ui-version-latest")

      setBrowserTitle: (title) ->
         if title
            $("title").text("#{title} | EverTrue")
         else
            $("title").text("EverTrue")
         @setTitleCount EverTrue.store.notifications?.getUnreadCount()

      setTitleCount: (count) ->
         notifications_count = if count then "(#{count})" else ""
         regex = /\(([^\)]+)\)/ # select (#) from title
         current_title = $("title").text()
         if current_title.match(regex)
            current_title = current_title.replace(regex, notifications_count)
         else
            current_title = "#{notifications_count} #{current_title}"
         $("title").text(current_title)

      setMobileRedirect: (bool) ->
         EverTrue.store.push "redirectToApp", bool, {cache: true}

      removePageContainer: ->
         $("#region-page").removeClass("page-container")

      startSatismeter: -> 
         user = EverTrue.store.user.attributes
         applySatismeter(user.id, user.name, user.email, user.created_at)

      fetchDataFromStore: ->
         EverTrue.store.fetch "session", Session
         EverTrue.store.fetch "dna", DNA
         EverTrue.store.fetch "user", User
         EverTrue.store.fetch "admin_impersonator", User
         EverTrue.store.fetch "org", Org
         EverTrue.store.fetch "service", LidsService
         EverTrue.store.fetch "page_referrer", undefined, true
         EverTrue.store.fetch "user_has_assignees"
         EverTrue.store.fetch "mfa_trust_device"

         if EverTrue.store.org.get("id")
            OrgSource.saveToStore(EverTrue.store.org.toJSON())
         SessionSource.setSession(EverTrue.store.session.toJSON())

         # enter impersonation mode if we are refreshing while impersonating
         admin_impersonator = EverTrue.store.admin_impersonator
         if admin_impersonator?.get("id")
            UserSource.impersonate(EverTrue.store.user)

         # if the user has gotten to a weird state where they dont have
         # user id (from previous change), set the user from the stored session
         if !EverTrue.store.user.get("id")
            user = EverTrue.store.session?.get("user")
            if user
               user_model = new User(user)
               EverTrue.store.push("user", user_model, {cache: true})

         # Fix for previous users who have their org cached w/o roles
         # TODO: Remove when all users should have correct state
         if EverTrue.store.org.get("id") && _.isUndefined(EverTrue.store.org.get("roles"))
            OrgSource.fetch(EverTrue.store.org.get("id"))

      switchEnvironment: ->
         config.switchEnvironments()
         EverTrue.store.destroy()
         localStorage?.removeItem("backboneCache")
         window.history.pushState(null, '', '/')
         window.history.replaceState(null, '', '/')
         window.location.replace("/")
      
      switchOrg: ->
         window.history.pushState(null, '', '/')
         window.history.replaceState(null, '', '/')
         window.location.replace("/")

      ajaxErrorSetup: ->
         $.ajaxSetup
            headers: { "cache-control": "no-cache" }
            error: (xhr, textStatus, err) ->
               if xhr.responseText
                  response = _.jsonParse(xhr.responseText)
                  EverTrue.Alert.error(response.message) if response?.message

      linkOverride: (evt) ->
         $target = $(evt.currentTarget)
         $target_ref = $target.attr("href")

         return if $target.data("bypass")
         return if $target.attr("target") == "_blank"
         return if !$target_ref
         return if $target_ref.match(/^http/) && !$target_ref.match(/.*.evertrue.com.*/)

         evt.preventDefault()

         if EverTrue.track?.setReferrer
            EverTrue.track.setReferrer $target.data("refer")

         href = $target.attr("href")

         if href != "#"
            _.defer( ->
               $("body").trigger("click") # click outside for nav
               EverTrue.Navigator href, true
            )

      browserNag: ->
         EverTrue.store.fetch "browser_nag"
         return if EverTrue.store.browser_nag?.viewed

         browser_version = navigator.sayswho()
         [browser, version] = browser_version.split(" ")
         last_supported_versions = {firefox: 31, msie: 10, ie: 10, chrome: 34, safari: 6}

         browserSupport = last_supported_versions[browser.toLowerCase()]
         return unless browserSupport
         if  browserSupport > parseInt(version, 10)
            if EverTrue.config.error_logging
               Raven?.captureMessage("Outdated Browser", {extra: browser: browser_version})
            $message = $("#outdated")
            $message.slideDown()
            $message.find("#btnCloseUpdateBrowser").on "click", ->
               $message.slideUp()
               EverTrue.store.push "browser_nag", {viewed: true}, {cache: true}

      idleTimer: ->
         TIMEOUT = 60000
         active = true
         tokenRenewalInProgress = false
         SESSION_TIMEOUT = EverTrue.store.session.getSessionTimeout()

         markInactiveAfterWait = _.debounce((->
            active = false
            EverTrue.is_idle = true
            EverTrue.vent.trigger("idle")
         ), TIMEOUT)

         renewToken = ->
            if tokenRenewalInProgress
               return
            tokenRenewalInProgress = true
            EverTrue.store.session.renewSession()
            # Wait for the token to be updated in the store
            setTimeout(->
               session_expire_at = EverTrue.store.session.get("expire_at")
               SESSION_TIMEOUT = session_expire_at - new Date().getTime()
               tokenRenewalInProgress = false
            , 3000) 

         checkTokenExpiration = ->
            if EverTrue.store.session.isExpiringInTenSeconds()
               renewToken()

         checkActivity = ->
            if not active
               active = true
               EverTrue.is_idle = false
               EverTrue.vent.trigger("reactivate")
            markInactiveAfterWait()

         $("body").mousemove(checkActivity)
         $("body").keypress(checkActivity)

         setInterval(checkTokenExpiration, 5000)  # Check every 5 seconds