import PropTypes from 'prop-types';
import NLSHome from '../components/NLSHome';

const AISearchHomeController = ({query}) => {
  return (
    <div className="ai-search-home">
      <NLSHome searchQuery={query}/>
    </div>
  );
};

AISearchHomeController.propTypes = {
  query: PropTypes.string.isRequired,
};

export default AISearchHomeController;
