import EverTrue from "app";
import { useEffect } from "react";
import BaseRouter from "base/base-router";
import { Loading } from "@evertrue/et-components";
import { useDashboard } from "apps/dashboard/hooks/use-user-dashboard";
import { useIdentity } from "base/identity-resolver";
import { useFeatureFlag } from "entities/helpers/use-feature-flag";

// InitRouter and InitRouteController check gates and personas to determine if
// dashboard should be shown when you load the app or is you should go to browse("contact")
const InitRouteController = () => {
  const { user } = useIdentity();
  const { has_dashboard, completed_dashboard_checks } = useDashboard(user.id);
  const { flagEnabled: hasSignal } = useFeatureFlag("signal");

  useEffect(() => {
    if (completed_dashboard_checks) {
      if (hasSignal) {
        EverTrue.Navigator("/home", true);
      } else if (has_dashboard) {
        EverTrue.Navigator("/dashboard", true);
      } else {
        EverTrue.Navigator("/contact", true);
      }
    }
  }, [completed_dashboard_checks, has_dashboard, hasSignal]);
  return <Loading />;
};

class InitRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "init(/)": "init",
    };
  }

  init() {
    this.currentView = <InitRouteController />;
    EverTrue.page.mount(this.currentView);
  }
}

InitRouter.initClass();
export default InitRouter;
