import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';
import colors from 'base/colors';
import EverTrue from "app";

const AISearchCard = ({ query }) => {
  return (
    <div
      className="ai-search-card"
      onClick={() => {
          EverTrue.track.set("tab_view", { type: "ai_search" });
          EverTrue.Navigator(`/ai/search?query=${query}`, { trigger: true, replace: true });
      }}
    >
      <Icon icon="ai-search" iconKit="gt2" style={{ color: colors.purple500, fontSize: '19px' }} />
      <div className="ai-search-card-header">
        <strong>AI Search</strong>
        <span>"{query}"</span>
      </div>
    </div>
  );
};

AISearchCard.propTypes = {
  query: PropTypes.string.isRequired,
};

export default AISearchCard;
