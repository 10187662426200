module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, ul, strong, input, li, span} = ReactLibs.DOMFactories
   ArrowSelectMixin = require("mixins/arrow-select-mixin")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "SelecorMenu",
      mixins: [ArrowSelectMixin]
      propTypes:
         label: ReactLibs.PropTypes.string.isRequired
         options: ReactLibs.PropTypes.array.isRequired
         onCreate: ReactLibs.PropTypes.func
         onSelect: ReactLibs.PropTypes.func.isRequired
         action: ReactLibs.PropTypes.string
         removeAction: ReactLibs.PropTypes.string
         showCreateLink: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         action: "Add to"
         removeAction: "Remove from"
         onCreate: _.noop
         showCreateLink: true

      getInitialState: ->
         query: undefined

      getSelectable: ->
         _.flatten _.pluck @getFilteredItems(), "items"

      handleSelect: (item) ->
         if !@props.isDisabled
            @props.onSelect?(item)

      handleType: (query) ->
         value = if _.isEmpty(query) then undefined else query
         @setState {query: value, selectedIndex: -1}

      handleKeyEnter: (index) ->
         list = @getSelectable()?[index]
         if list then @handleSelect "", list.id, true
         else @handleCreate()

      handleCreate: ->
         if !@props.isDisabled
            @props.onCreate(@state.query)

      getFilteredItems: ->
         if _.isEmpty(@state.query)
            @props.options
         else
            grouped_options = _.clone @props.options
            _.compact _.map grouped_options, (group) =>
               list_group = _.extend {}, group,
                  items: _.filter group.items, (item) =>
                     _.searchCompare(item.label, @state.query)
               if !_.isEmpty(list_group.items) then list_group else undefined

      handleInputChange: (evnt) ->
         @handleKeyPress(evnt) # From ArrowSelectMixin handling

      render: ->
         options = @getFilteredItems()
         option_index = -1

         div className: "selector-menu",
            div className: "selector-menu--content",
               div className: "selector-menu--input",
                  input
                     type: "text"
                     placeholder: @props.searchPlaceholder || "Search or create new #{@props.label}..."
                     onKeyUp: @handleInputChange
                     ref: "arrowSelectInput"

               ul className: "selector-menu--options", ref: "arrowSelectBody",
                  _.map options, (group) =>
                     li key: group.value, className: "selector-menu--group",
                        strong className: "selector-menu--group-label", group.label
                        ul null,
                           _.map group.items, (item) =>
                              option_index += 1
                              current_index = _.clone(option_index)

                              LinkButton
                                 style: {display: "block"}
                                 title: if item.selected then "#{@props.removeAction} #{item.label}" else "#{@props.action} #{item.label}"
                                 key: item.value
                                 className: EverTrue.classSet "selector-menu--item",
                                    "is-active": option_index == @state.selectedIndex # hover state
                                    "is-checked": item.selected
                                 onClick: => @handleSelect(item)
                                 onMouseOver: => @handleHoverSelect(current_index)
                                 if item.selected then Icon icon: "check", className: "selector-menu--icon"
                                 span className: "ellipsis-overflow", item.label
               if @props.loadMore
                  LinkButton title: "Load more ", className: "selector-menu--load-more", onClick: @props.loadMore, "Load More"

            if @props.onCreate? and @props.showCreateLink
               LinkButton
                  title: "Create a new #{@props.label}"
                  className: EverTrue.classSet "selector-menu--actions",
                     "is-button": _.isEmpty(options)
                  onClick: @handleCreate
                  "Create a new #{@props.label} "
                  strong null, @state.query
