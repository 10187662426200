import PropTypes from 'prop-types';
import classNames from 'classnames';

const AnnualGivingScore = ({ score, className }) => {
  return (
    <div className={classNames('annual-giving-score', className)}>
      <div className="annual-giving-score--wrapper">
        <div className="annual-giving-score--inner" style={{ width: `${score}%` }}></div>
      </div>
      <div className="annual-giving-score--label">
        <span className="annual-giving-score--numerator">{score}</span>
        <span className="annual-giving-score--divider">/</span>
        <span className="annual-giving-score--denominator">100</span>
      </div>
    </div>
  );
};

AnnualGivingScore.propTypes = {
  score: PropTypes.number,
  className: PropTypes.string,
};

export default AnnualGivingScore;
