import { PropTypes } from 'base/react-libs';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { useState } from 'react';
import SelectCadence from './common/selectCadence';
import FormField from 'components/forms/form-field';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { moveConstituentToJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { useIdentity } from 'base/identity-resolver';

const ProfileActionsMoveToAnotherCadenceModal = ({
  isOpen,
  onClose,
  currentJourney,
  contact = {},
  title = 'Move to Another Cadence',
  refreshProfile = () => {},
}) => {
  const { user, org } = useIdentity();

  const [selected, setSelected] = useState(null);

  const handleSubmit = async () => {
    trackJourneysAction('move_cadence');
    await moveConstituentToJourney(
      currentJourney.journey_id,
      contact.id,
      user.id,
      org.id,
      selected.value,
      selected.label
    );
    refreshProfile((prev) => !prev);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={onClose}>
        <ModalHeaderWithActions
          title={title}
          closeModal={onClose}
          submitLabel="Confirm"
          onSubmit={handleSubmit}
          disableSubmit={!selected}
        />
        <ModalBody>
          <div className="max-cadence-alert">Select a new cadence for the constituent.</div>
          <FormField label="" boldLabel={true} inlineLabel={false}>
            <SelectCadence selected={selected} setSelected={setSelected} />
          </FormField>
        </ModalBody>
      </Modal>
    </>
  );
};

ProfileActionsMoveToAnotherCadenceModal.propTypes = {
  title: PropTypes.string,
  currentJourney: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
  refreshProfile: PropTypes.func,
};

export default ProfileActionsMoveToAnotherCadenceModal;
