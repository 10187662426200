import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import FolderController from './controllers/folder-controller';

class FolderRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'folder/:id': 'show',
    };
  }

  show(id) {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'Folder');

    this.currentView = <FolderController id={id} />;
    EverTrue.page.mount(this.currentView);
  }
}

FolderRouter.initClass();
export default FolderRouter;
