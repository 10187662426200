// This function converts the filters into an url for the constituent page.
// This is a custom logic based on how the urls are constructed after applying the filters.
export const convertFilterToUrl = (filter, baseUrl, beta = false, operator = 'and', page = 1) => {
  const params = new URLSearchParams();

  // Append beta to the URL parameters first
  params.append('beta', beta);

  // Loop through each filter and append it to the URL parameters
  filter.forEach((filterItem, index) => {
    const filterPrefix = `filters[${index}]`;

    params.append(`${filterPrefix}[filter_row_id]`, filterItem.filter_row_id);
    params.append(`${filterPrefix}[filter_id]`, filterItem.filter_id);

    if (typeof filterItem.value === 'object' && !Array.isArray(filterItem.value)) {
      // Handle nested objects (like { gte: "1000000" })
      Object.keys(filterItem.value).forEach((key) => {
        const subValue = filterItem.value[key];
        if (typeof subValue === 'object' && !Array.isArray(subValue)) {
          // Handle deeper nested objects
          Object.keys(subValue).forEach((subKey) => {
            params.append(`${filterPrefix}[value][${key}][${subKey}]`, subValue[subKey]);
          });
        } else if (Array.isArray(subValue)) {
          // Handle arrays within the object
          subValue.forEach((item, idx) => {
            Object.keys(item).forEach((itemKey) => {
              params.append(`${filterPrefix}[value][${key}][${idx}][${itemKey}]`, item[itemKey]);
            });
          });
        } else {
          params.append(`${filterPrefix}[value][${key}]`, subValue);
        }
      });
    } else if (Array.isArray(filterItem.value)) {
      // Handle arrays like [{ label: "LYBUNT", value: "lybunt" }]
      filterItem.value.forEach((item, idx) => {
        Object.keys(item).forEach((key) => {
          params.append(`${filterPrefix}[value][${idx}][${key}]`, item[key]);
        });
      });
    } else {
      // Handle primitive values (string, boolean, number)
      params.append(`${filterPrefix}[value]`, filterItem.value);
    }
  });

  // Append operator and page to the URL parameters at the end
  params.append('operator', operator);
  params.append('page', page);

  return `${baseUrl}?${params.toString()}`;
};

//Might make the other parser method obsolete.
//TODO - In future: Check if this is the case and remove the other method.
export function generateUrl(base, params) {
  const queryParams = [];

  // Process filters
  if (Array.isArray(params.filters)) {
    params.filters.forEach((filter, index) => {
      const prefix = `filters%5B${index}%5D%5B`;
      queryParams.push(`${prefix}filter_row_id%5D=${filter.filter_row_id}`);
      queryParams.push(`${prefix}filter_id%5D=${encodeURIComponent(filter.filter_id)}`);

      if (Array.isArray(filter.value)) {
        filter.value.forEach((v, idx) => {
          queryParams.push(`${prefix}value%5D%5B${idx}%5D%5Bvalue%5D=${encodeURIComponent(v.value)}`);
          queryParams.push(`${prefix}value%5D%5B${idx}%5D%5Blabel%5D=${encodeURIComponent(v.label)}`);
        });
      } else if (filter.value && typeof filter.value === 'object') {
        Object.entries(filter.value).forEach(([key, val]) => {
          if (val && typeof val === 'object') {
            Object.entries(val).forEach(([subKey, subVal]) => {
              queryParams.push(`${prefix}value%5D%5B${key}%5D%5B${subKey}%5D=${encodeURIComponent(subVal)}`);
            });
          } else {
            queryParams.push(`${prefix}value%5D%5B${key}%5D=${encodeURIComponent(val)}`);
          }
        });
      } else if (filter.value !== undefined) {
        queryParams.push(`${prefix}value%5D=${encodeURIComponent(filter.value)}`);
      }
    });
  }

  // Add operator
  if (params.operator) {
    queryParams.push(`operator=${encodeURIComponent(params.operator)}`);
  }

  // Add pagination
  if (params.page) {
    queryParams.push(`page=${params.page}`);
  }

  // Add sorting
  if (params.sort && typeof params.sort === 'object') {
    Object.entries(params.sort).forEach(([key, val]) => {
      Object.entries(val).forEach(([subKey, subVal]) => {
        queryParams.push(`sort%5B${key}%5D%5B${subKey}%5D=${encodeURIComponent(subVal)}`);
      });
    });
  }

  // Combine everything
  return `${base}?${queryParams.join('&')}`;
}
