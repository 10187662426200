import { useState, useEffect } from 'react';
import { useFluxStore } from '@evertrue/et-flux';
import RecentContactStore from 'apps/contact/stores/recent-contact-store';
import RecentContactSource from 'apps/contact/sources/recent-contact-source';
import SelectedContactsSource from 'apps/contact/sources/selected-contacts-source';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import ContactFixedTable from 'apps/contact/components/contacts/contact-fixed-table';
import ContactTableToolbar from 'apps/contact/components/contacts/table/contact-table-toolbar';
import ContactsPageHeader from 'apps/contact/components/contacts/contacts-page-header.js';

const ACTIVE_FILTER_HEIGHT = 75;
const TOOLBAR_HEIGHT = 68;

const recentContactStoreMap = () => {
  return {
    loading: RecentContactStore.getLoading(),
    contacts: RecentContactStore.getContacts(),
  };
};
const selectedContactsStoreMap = () => {
  return {
    selectedContacts: SelectedContactsStore.getSelectedContacts(),
  };
};

const RecentContactTable = () => {
  const [enrichmentLoading, setEnrichmentLoading] = useState(true);

  const { loading, contacts } = useFluxStore(RecentContactStore, recentContactStoreMap);
  const { selectedContacts } = useFluxStore(SelectedContactsStore, selectedContactsStoreMap);

  useEffect(() => {
    RecentContactSource.fetch();
  }, []);

  const handlePage = (page) => {
    RecentContactSource.changePage(page);
    SelectedContactsSource.clear();
    setEnrichmentLoading(true);
  };

  const numSelectedContacts = selectedContacts?.length;

  return (
    <>
      <ContactsPageHeader
        totalResults={contacts?.total}
        numSelectedContacts={numSelectedContacts}
        contactIds={selectedContacts}
        loading={loading}
      />

      <div className="fixed-page-wrapper" style={{ top: ACTIVE_FILTER_HEIGHT }}>
        <ContactTableToolbar contacts={contacts} onPage={handlePage} />

        <div className="fixed-page-wrapper" style={{ top: TOOLBAR_HEIGHT }}>
          <ContactFixedTable
            contacts={contacts}
            loading={loading}
            setEnrichmentLoading={setEnrichmentLoading}
            enrichmentLoading={enrichmentLoading}
          />
        </div>
      </div>
    </>
  );
};

export default RecentContactTable;
