import { Icon, Button } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { useIdentity } from 'base/identity-resolver';
import EverTrue from 'app';
import TodaysMeetingCard from './todays-meeting-card';
import useTripsAndMeetings from 'apps/home/hooks/use-trips-meetings';

// Extract message formatting logic
const getCountMessage = (count, type) => {
  if (count <= 0) return `No ${type}s scheduled today`;
  return `${type}${count === 1 ? '' : 's'} today`;
};

// Extract summary section component
const ActivitySummary = ({ count, type, icon }) => (
  <div className={`${type}-content`}>
    <Icon icon={icon} iconKit="gt" size={1} />
    {count > 0 ? (
      <span>
        You have <span className="trips-meetings-count">{count}</span>
        {` ${getCountMessage(count, type)}`}
      </span>
    ) : (
      <span className="trips-meeting-empty">{getCountMessage(count, type)}</span>
    )}
  </div>
);

ActivitySummary.propTypes = {
  count: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['trip', 'meeting']).isRequired,
  icon: PropTypes.string.isRequired,
};

const TripsAndMeetings = () => {
  const { user = {} } = useIdentity();

  const { tripsAndMeetingDetails } = useTripsAndMeetings(user.id);

  const { tripCount = 0, meetingCount = 0, meetings = [] } = tripsAndMeetingDetails || {};
  const MAX_VISIBLE_MEETINGS = 2;

  const handleViewClick = () => {
    EverTrue.track.set('home_screen', { type: 'view_trips_meetings' });
    EverTrue.UrlManager.navigate('/trips', true, false);
  };

  return (
    <div className="trips-meetings-wrapper">
      <h2 className="trips-meetings-header">Trips and Meetings</h2>

      <div className="trips-meetings-content">
        <div className="trips-meeting-summary">
          <ActivitySummary count={tripCount} type="trip" icon="my-trips" />

          <div className="trips-meetings--vertical-line" />

          <ActivitySummary count={meetingCount} type="meeting" icon="date-range" />
        </div>

        <div className="trips-meetings-details">
          {meetings?.length > 0 &&
            meetings
              .slice(0, MAX_VISIBLE_MEETINGS)
              .map((meeting) => <TodaysMeetingCard key={meeting.id} meeting={meeting} />)}

          {meetings.length > MAX_VISIBLE_MEETINGS && (
            <div className="more-meetings">{`${meetings.length - MAX_VISIBLE_MEETINGS} more`}</div>
          )}
        </div>
      </div>

      <div className="trips-meetings--view-today">
        <Button onClick={handleViewClick} type="secondary">
          View
        </Button>
      </div>
    </div>
  );
};

export default TripsAndMeetings;
