import PropTypes from "prop-types";
import { Button, Icon, Link, useOpenClose } from "@evertrue/et-components";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import EnrichmentList from "./enrichment-list";
import { useGate } from "components/is-gated";
import WealthIndicatorsModal from "./wealth-indicators-modal";
import { simplifyNetWorths } from "./utils/index";

const LIMIT = 3;
const ACCESSOR = "type";

const WealthIndicators = ({ netWorths, physicalAssets = [], wealthIndicators = [], contactAttributes, onDelete }) => {
  const [hasWindfallPlus] = useGate("windfall_wealth_plus");
  const [hasWindfall] = useGate("windfall");
  const [isOpen, openModal, closeModal] = useOpenClose();
  const filteredPhysicalAssets = physicalAssets.filter(({ rejected }) => !rejected);
  const filteredWealthIndicators = wealthIndicators.filter(({ rejected }) => !rejected);
  let simplifiedNetWorthsArray = simplifyNetWorths(netWorths, hasWindfallPlus);
  const filteredNetWorths = simplifiedNetWorthsArray.filter(({ rejected }) => !rejected);

  function showWindfallWealthData() {
    if (simplifiedNetWorthsArray.length) {
      return (
        (hasWindfallPlus && simplifiedNetWorthsArray[0].value === "Less than $1M") ||
        simplifiedNetWorthsArray[0].value !== "Less than $1M"
      );
    }
    return false;
  }

  const shouldShowWealthData = showWindfallWealthData();

  return (
    <div className="profile--enrichment-wealth">
      <ProfileCardHeader title="Wealth and Assets">
        {(netWorths.length > 0 || physicalAssets.length > 0 || simplifiedNetWorthsArray.length > 0) && hasWindfall && (
          <Button type="simple" onClick={openModal}>
            <Icon title="Edit wealth and assets" icon="edit" />
          </Button>
        )}
        {isOpen && (
          <WealthIndicatorsModal
            isOpen={isOpen}
            closeModal={closeModal}
            simplifiedNetWorthsArray={simplifiedNetWorthsArray}
            physicalAssets={physicalAssets}
            wealthIndicators={wealthIndicators}
            contactAttributes={contactAttributes}
            onDelete={onDelete}
          />
        )}
      </ProfileCardHeader>
      {hasWindfall ? (
        <>
          <div className="profile--enrichment-inner columns">
            <div>
              <Icon icon="money" />
              <span className="profile-enrichment-wealth-title">Household Net Worth</span>
              {filteredNetWorths.length && shouldShowWealthData ? (
                <EnrichmentList
                  list={filteredNetWorths}
                  accessor="value"
                  limit={1}
                  className="profile--enrichment-wealth--networth"
                  openModal={openModal}
                />
              ) : (
                <div> - </div>
              )}
            </div>
            <div>
              <Icon icon="home" />
              <span className="profile-enrichment-wealth-title">Physical Assets</span>
              {filteredPhysicalAssets.length ? (
                <EnrichmentList list={filteredPhysicalAssets} accessor={ACCESSOR} limit={LIMIT} openModal={openModal} />
              ) : (
                <div> - </div>
              )}
            </div>
            <div>
              <Icon icon="piggy-bank" />
              <span className="profile-enrichment-wealth-title">Wealth Indicators</span>
              {filteredWealthIndicators.length ? (
                <EnrichmentList
                  list={filteredWealthIndicators}
                  accessor={ACCESSOR}
                  limit={LIMIT}
                  openModal={openModal}
                />
              ) : (
                <div> - </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="profile-enrichment-teaser">
          We've partnered with Windfall to bring prospect net worth and info about wealth, assets, and nonprofit giving
          to TrueView profiles. Your organization hasn't added this feature yet, but you can{" "}
          <Link
            href={`/contact/${contactAttributes.contactId}/enrichment?appcue=-Ly1pV7jxtRJbWNfBVFM`}
            title="learn more about windfall"
          >
            see how it works
          </Link>{" "}
          and contact your Customer Success Manager to learn more.
        </div>
      )}
    </div>
  );
};

WealthIndicators.propTypes = {
  netWorths: PropTypes.array,
  physicalAssets: PropTypes.array,
  wealthIndicators: PropTypes.array,
  contactAttributes: PropTypes.object,
  onDelete: PropTypes.func,
};

export default WealthIndicators;
