import PropTypes from "prop-types";
import get from "lodash.get";
import { Component } from "react";
import EverTrue from "app";
import classNames from "classnames";
import { connect } from "@evertrue/et-flux";
import numeral from "numeral";
import Tooltip from "components/overlays/tooltip";
import AskAmountModal from "apps/volunteers/components/ask-amount-modal";
import UpdateStageModal from "apps/assignments/components/update-stage-modal";
import LinkProspectsModal from "apps/volunteers/components/link-prospects-modal";
import CachedContactStore from "apps/contact/stores/cached-contact-store";
import JointContactCard from "apps/volunteers/components/joint-contact-card";
import FeatureStore from "apps/layout/stores/feature-store";
import CommittedAmountModal from "./committed-amount-modal";
import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import IsGated from "components/is-gated";
import { ThreeDotActionsMenu, Icon, Flex, Button, Modal2, Loading, Checkbox } from "@evertrue/et-components";
import Api from "../../../entities/helpers/api";
import _ from "underscore";
import Decorator from "@evertrue/client-decorator";

const mapStateToProps = () => {
  return {
    has_vms_stages: FeatureStore.hasFeature("volunteers_stages"),
    vms_committed_amounts: FeatureStore.hasFeature("vms_committed_amounts"),
  };
};

class PoolProspectCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    pool: PropTypes.object,
    selected_solicitor: PropTypes.object,
    solicitor: PropTypes.object,
    selected: PropTypes.bool,
    hasJoint: PropTypes.bool,
    loading: PropTypes.bool,
    onRemove: PropTypes.func,
    onRemoveAssignment: PropTypes.func,
    onClick: PropTypes.func,
    has_vms_stages: PropTypes.bool,
    fetchSolicitors: PropTypes.func.isRequired,
    className: PropTypes.string,
    vms_committed_amounts: PropTypes.bool,
    secondary_contact_id: PropTypes.number,
  };

  static defaultProps = {
    data: {},
    pool: {},
    solicitor: {},
    assignment: {},
  };

  state = {
    secondary_contact: {},
  };

  //TODO the secondary_contact is all manually handled in component state because
  // relying on the flux store created data inconsistencies between components. This was done to address
  // a bug found in ET-10929.
  getSecondaryContact(id) {
    if (id) {
      Api.CONTACTS.SEARCH.post({
        data: _.jsonStringify({
          must: [
            {
              id: {
                match: id,
              },
            },
          ],
        }),
      }).then((secondary_contact) => {
        this.setSecondaryContactState(Decorator.Contacts.parse(secondary_contact.items[0]));
      });
    }
  }

  setSecondaryContactState(secondary_contact) {
    this.setState({ secondary_contact: secondary_contact });
  }

  componentDidMount() {
    if (this.props.secondary_contact_id) {
      this.getSecondaryContact(this.props.secondary_contact_id);
    }
  }

  getMenuOptions = (assignment, solicitor) => {
    const is_joint = get(assignment, "secondary_prospect_contact_ids.length");
    const is_assigned = !!assignment.solicitor_contact_id;
    const solicitor_name = solicitor ? solicitor.name_full : "";
    const show_update_stage = this.props.has_vms_stages && this.props.pool.stage_group_id;
    const keyProspectLabel = assignment.key_prospect ? "Unmark Key Relationship" : "Mark Key Relationship";
    const menu_options = [];

    if (show_update_stage) {
      menu_options.push({
        id: 1,
        icon: "recently-viewed",
        label: "Update Stage",
        mountOnClick: ({ close, is_open }) => (
          <Modal2 isOpen={is_open} onClose={close}>
            <UpdateStageModal
              close={close}
              pool={this.props.pool}
              assignment={assignment}
              secondary={this.state.secondary_contact}
            />
          </Modal2>
        ),
      });
    }

    menu_options.push({
      id: 2,
      icon: "key-relationship",
      label: keyProspectLabel,
      onClick: () => {
        PoolAssignmentsSource.updateKeyProspect(assignment.assignment_id, !assignment.key_prospect)
          .then(() => {
            EverTrue.Alert.success("Prospect marked successfully");
          })
          .catch(() => {
            EverTrue.Alert.error("Marking prospect failed");
          });
      },
    });

    menu_options.push({
      id: 3,
      icon: "edit",
      label: "Edit Ask Amount",
      mountOnClick: ({ close, is_open }) => (
        <Modal2 isOpen={is_open} onClose={close}>
          <AskAmountModal
            close={close}
            ask={assignment != null ? assignment.ask_amount_in_cents : undefined}
            searchAssignment={assignment}
            secondary={this.state.secondary_contact}
            assignedToName={solicitor_name}
          />
        </Modal2>
      ),
    });

    if (this.props.vms_committed_amounts) {
      menu_options.push({
        id: 4,
        icon: "committed",
        label: "Edit Committed Amount",
        mountOnClick: ({ close, is_open }) => (
          <Modal2 isOpen={is_open} onClose={close}>
            <CommittedAmountModal
              close={close}
              searchAssignment={assignment}
              secondary={this.state.secondary_contact}
              assignedToName={solicitor_name}
            />
          </Modal2>
        ),
      });
    }

    if (this.props.hasJoint) {
      menu_options.push({
        id: 5,
        icon: "link",
        mountOnClick: ({ close, is_open }) => (
          <Modal2 isOpen={is_open} onClose={close}>
            <LinkProspectsModal
              close={close}
              assignment={assignment}
              secondary={this.state.secondary_contact}
              join={!is_joint}
              fetchSolicitors={this.props.fetchSolicitors}
              removeSecondaryProspect={() => {
                this.setSecondaryContactState({});
              }}
              addSecondaryProspect={(id) => {
                this.getSecondaryContact(id);
              }}
            />
          </Modal2>
        ),

        label: is_joint ? "Edit Linked Prospects" : "Link Prospect",
      });
    }

    if (is_assigned) {
      const onRemove = () => this.props.onRemoveAssignment(solicitor);
      menu_options.push({ id: 6, icon: "clear", onClick: onRemove, label: "Remove Assignment" });
    }

    menu_options.push({ id: 7, icon: "delete", onClick: this.props.onRemove, label: "Remove from Pool" });

    return menu_options;
  };

  render() {
    const { data: assignment } = this.props;
    const is_assigned = !!assignment.solicitor_contact_id;
    let is_assigned_to_current = false;
    if (this.props.selected_solicitor) {
      is_assigned_to_current = assignment.solicitor_contact_id === this.props.selected_solicitor.id;
    }

    const ask_amount_in_cents = assignment.ask_amount_in_cents;
    const committed_amount_in_dollars = assignment.committed_amount_in_dollars;
    const is_joint = get(assignment, "secondary_prospect_contact_ids.length");
    const last_gift_amount = get(assignment, "contact.giving.last_gift_amount.value");
    const last_gift_date = get(assignment, "contact.giving.last_gift_date.value");

    return (
      <div className={classNames("pool-prospect-card", { "is-active": is_assigned_to_current }, this.props.className)}>
        <Flex alignItems="center">
          <div className="pool-prospect-card--action">
            {this.props.loading && <Loading spinner_size="small" />}

            {is_assigned ? (
              <Button
                type="simple"
                title="Remove assignment"
                onClick={() => this.props.onRemoveAssignment(this.props.solicitor)}
              >
                {Tooltip(
                  {
                    className: "pool-prospect-card--clear",
                    content: "Remove Assignment",
                    position: "top",
                  },
                  <Icon icon="clear" />
                )}
              </Button>
            ) : (
              <Checkbox label="Select Prospect" checked={this.props.selected} onChange={this.props.onClick} />
            )}
          </div>
          <JointContactCard
            is_joint={Boolean(is_joint)}
            is_key_prospect={assignment.key_prospect}
            contact={assignment.contact}
            showNameLink={true}
            secondary={this.state.secondary_contact}
          >
            <div className="pool-prospect-card--assignment text-label ellipsis-overflow">
              {is_joint && <div className="pool-prospect-card--data-value">Joint Assignment</div>}
              <div className="pool-prospect-card--React.DOM.divider" />
              {"Assigned To: "}
              {is_assigned ? (
                this.props.solicitor ? (
                  <span
                    className={classNames("pool-prospect-card--data-value", {
                      "text-bright": this.props.selected_solicitor && is_assigned_to_current,
                    })}
                  >
                    {this.props.solicitor.name_full}
                  </span>
                ) : (
                  "Unknown Volunteer"
                )
              ) : (
                <span className="text-placeholder-light">Unassigned</span>
              )}
              <Flex>
                <div className="text-label">
                  {"Ask Amount: "}
                  {ask_amount_in_cents !== undefined && ask_amount_in_cents !== null ? (
                    <span className="pool-prospect-card--data-value">
                      {`${numeral(ask_amount_in_cents / 100).format("$0,0[.]00")}`}
                    </span>
                  ) : (
                    <span className="text-placeholder-light"> none </span>
                  )}
                </div>

                <IsGated feature="vms_committed_amounts">
                  <div className="pool-prospect-card--committed">{" | "}</div>
                  <div className="text-label">
                    {"Committed Amount: "}
                    {committed_amount_in_dollars !== undefined && committed_amount_in_dollars !== null ? (
                      <span className="pool-prospect-card--data-value">
                        {`${numeral(committed_amount_in_dollars).format("$0,0[.]00")}`}
                      </span>
                    ) : (
                      <span className="text-placeholder-light"> none </span>
                    )}
                  </div>
                </IsGated>
              </Flex>
              <div className="text-label">
                {"Last Gift: "}
                {last_gift_amount ? (
                  <span className="pool-prospect-card--data-value">
                    {`${numeral(last_gift_amount).format("$0,0[.]00")} on ${last_gift_date}`}
                  </span>
                ) : (
                  <span className="text-placeholder-light">none</span>
                )}
              </div>
              {this.props.has_vms_stages && this.props.pool.stage_group_id ? (
                <div className="text-label">
                  {"Stage: "}
                  {assignment.assignment_stage ? (
                    <span className="pool-prospect-card--data-value">{assignment.assignment_stage}</span>
                  ) : (
                    <span className="text-placeholder-light">none</span>
                  )}
                </div>
              ) : undefined}
            </div>
          </JointContactCard>
          <ThreeDotActionsMenu
            triggerClassName="pool-prospect-card--dropdown-menu"
            options={this.getMenuOptions(assignment, this.props.solicitor)}
          />
        </Flex>
      </div>
    );
  }
}

export default connect(PoolProspectCard, [CachedContactStore, FeatureStore], mapStateToProps);
