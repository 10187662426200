import Api from 'entities/helpers/api';
import LocalApi from 'entities/helpers/local-api';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const fetchFromGraphql = (query, variables, queryName = null, local = false) => {
  const api = local ? LocalApi : Api;

  try {
    return api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query,
        variables,
      }),
    }).then((res) => {
      if (local) console.log('Local Response: \n', res);
      if (res?.data) {
        return res.data;
      } else {
        return res;
      }
    });
  } catch (e) {
    const error = e?.responseJSON?.errors ? JSON.stringify(e?.responseJSON?.errors) : e;
    ErrorLogger.error('GQL Error', { extra: { error, page_name: window.location.pathname } });
    return queryName ? { queryName } : {};
  }
};

export const gqlCall = (query, variables) =>
  Api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query: String.raw`${query}`,
      variables,
    }),
  });
