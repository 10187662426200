import PropTypes from 'prop-types';

const OutreachTemplateIndicator = ({ emailTemplateId }) => {
  return (
    <div className="outreach-card-tab-group--item">
      <span className="tab-text">
        <strong>Template </strong>
      </span>
    </div>
  );
};

OutreachTemplateIndicator.propTypes = {
  emailTemplateId: PropTypes.number,
};

export default OutreachTemplateIndicator;
