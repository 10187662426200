import { PropTypes } from "base/react-libs";
import { useFluxStore } from "@evertrue/et-flux";
import { PinnedCommentsContext } from "../../../profile/contexts/pinned-comments-context";
import { InteractionsContext } from "../../../profile/contexts/interactions-context";
import { useContext } from "react";
import Modal from "components/modals/modal";
import ModalTrigger from "components/modals/modal-trigger";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import FeatureStore from "apps/layout/stores/feature-store";
import Icon from "components/elements/icon";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";

const featureStoreMapState = () => ({
  hasInteractionWrites: FeatureStore.hasInteractionWrites(),
});

const InteractionAddBtn = ({
  interaction,
  contact,
  contactOptions,
  referrer,
  showIcon,
  onClick,
  buttonType = "secondary",
  buttonClass = null,
  title,
  enableInteractionSolicitorType,
  interactionDescriptionRichTextEditor,
}) => {
  const { hasInteractionWrites } = useFluxStore(FeatureStore, featureStoreMapState);
  const { addNewCommentToState } = useContext(PinnedCommentsContext);
  const { addNewInteractionToState } = useContext(InteractionsContext);

  const renderModal = () => {
    let _interaction = interaction;

    if (!hasInteractionWrites) {
      delete _interaction.interaction_type;
    }

    return (
      <Modal width={568} keepOpen={true}>
        <InteractionForm
          contact={contact}
          contactOptions={contactOptions}
          interaction={_interaction}
          referrer={referrer}
          addNewCommentToState={addNewCommentToState}
          addNewInteractionToState={addNewInteractionToState}
          enableInteractionSolicitorType={enableInteractionSolicitorType}
          interactionDescriptionRichTextEditor={interactionDescriptionRichTextEditor}
        />
      </Modal>
    );
  };

  const renderTitle = () => {
    if (title) return title;
    return hasInteractionWrites ? "Add Interaction" : "Add comment";
  };

  return (
    <ModalTrigger
      title={hasInteractionWrites ? "Add Interaction" : "Add comment"}
      modal={renderModal()}
      buttonType={buttonType}
      buttonClass={buttonClass}
      onClick={() => {
        trackJourneysAction("cadence-pinned-interaction");
      }}
    >
      {showIcon && <Icon icon={"trip-log"} />}
      {renderTitle()}
    </ModalTrigger>
  );
};

InteractionAddBtn.propTypes = {
  interaction: PropTypes.object,
  contact: PropTypes.object,
  contactOptions: PropTypes.array,
  referrer: PropTypes.string,
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  buttonClass: PropTypes.string,
  title: PropTypes.string,
  enableInteractionSolicitorType: PropTypes.bool,
  interactionDescriptionRichTextEditor: PropTypes.bool,
};

export default InteractionAddBtn;
