import moment from 'moment';
import numeral from 'numeral';
import { titleize, humanize } from 'underscore.string';

export const formatDate = (date) => {
  if (!date) {
    return '-';
  } else if (moment(date).isAfter(moment().subtract(7, 'days').format('YYYY-MM-DD'))) {
    return moment(date).local().fromNow();
  } else {
    return moment(date).local().format('MMM D, YYYY');
  }
};

export const formatGiftAmount = (amount) => numeral(amount).format('$0,000[.]00');

export const formatGiftType = (type = '') => {
  switch (type.toLowerCase()) {
    case 'hard_credit':
      return 'Hard Credit';
    case 'soft_credit':
      return 'Soft Credit';
    case 'pledge':
      return 'Pledge';
    default:
      return titleize(humanize(type));
  }
};
