import _ from 'underscore';
import { FlexTable, Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import KpiCard from './widget-kpi-card';
import useFetchResults from 'apps/dashboard/hooks/use-fetch-results';
import get from 'lodash.get';
import moment from 'moment';

const getColumnIcon = (rowValue = '', rows = []) => {
  const match = rows.find((rowConfig) => {
    return (
      rowConfig.criteria &&
      rowConfig.criteria.operator === 'equals' &&
      _.isString(rowValue) &&
      _.isString(rowConfig.criteria.value) &&
      rowValue.toLowerCase() === rowConfig.criteria.value.toLowerCase() &&
      rowConfig.style
    );
  });

  return match ? <Icon icon={match.style.icon} className="hub-kpicard--icon" /> : null;
};

const getColumns = (table_stat, headerStyle) => {
  let columns = [];

  if (table_stat && table_stat.columns && table_stat.columns.length > 0) {
    columns = table_stat.columns.map((column) => {
      let cellClassName = column.size ? `hub-kpicard--table-col-${column.size}` : '';
      cellClassName =
        cellClassName +
        (column.data_type === 'number' || column.data_type === 'percent' ? ' hub-kpicard--table-col-right' : '');

      let headerClassName = column.size ? `hub-kpicard--table-col-${column.size}` : '';
      headerClassName =
        headerClassName +
        (column.data_type === 'number' || column.data_type === 'percent' ? ' hub-kpicard--table-col-right' : '');

      return {
        header: column.label,
        headerClassName: headerClassName,
        headerStyle: headerStyle,
        cellClassName: cellClassName,
        accessor: (data) => {
          const value = get(data, column.prop);
          const icon = getColumnIcon(value, table_stat.rows);
          let formattedValue;
          switch (column.data_type) {
            case 'date':
              formattedValue = moment(value).format('MM-DD-YY');
              break;
            case 'number':
              formattedValue = value || 0;
              break;
            case 'string':
              formattedValue = <div className="hub-kpicard--text-cell">{value}</div>;
              break;
            default:
              formattedValue = value;
          }

          return (
            <>
              {icon} {formattedValue}
            </>
          );
        },
      };
    });
  }

  return columns;
};

const KpiTableCard = ({
  template_json = {},
  title,
  query = '',
  variables = {},
  optionVariableName,
  options = [],
  fetchStat = () => {},
  fiscal_year,
  selectedOption = {},
  onOptionChange = () => {},
  headerStyle = {},
  num_rows = 3,
}) => {
  const { result, loading } = useFetchResults(
    query,
    optionVariableName,
    template_json.has_options,
    variables,
    selectedOption.value,
    fetchStat
  );

  let table_data = get(result, template_json.table_stat ? template_json.table_stat.prop : '', []);
  let table_total = get(result, template_json.table_stat ? template_json.table_stat.total_prop : '', 0);

  // If sort is passed on the value_results_display, try sorting the data
  if (template_json.table_stat && template_json.table_stat.sort) {
    table_data = _.sortBy(table_data, (row) => {
      return get(row, template_json.table_stat.sort);
    });
  }

  const columns = getColumns(template_json.table_stat, headerStyle);
  const display_data = _.isArray(table_data) ? table_data.slice(0, num_rows) : [];
  // If a total_prop is defined on the card, use that, else count the table rows
  const more = table_total > 0 ? table_total - num_rows : _.isArray(table_data) ? table_data.length - num_rows : null;

  return (
    <KpiCard
      loading={loading}
      title={title}
      fiscal_year={fiscal_year}
      icon={template_json.icon}
      color={template_json.theme_color}
      options={options}
      onOptionChange={onOptionChange}
      selectedOption={selectedOption}
    >
      <div className="hub-kpicard--table">
        <FlexTable
          columns={columns}
          data={display_data}
          caption={title}
          emptyStateMessageText="No results to display"
        />
        <div className="hub-kpicard--table-more">{more > 0 ? `+${more} more` : null}</div>
      </div>
    </KpiCard>
  );
};

KpiTableCard.propTypes = {
  title: PropTypes.string,
  variables: PropTypes.object,
  optionVariableName: PropTypes.string,
  options: PropTypes.array,
  fetchStat: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  template_json: PropTypes.object,
  fiscal_year: PropTypes.number,
  onOptionChange: PropTypes.func,
  selectedOption: PropTypes.object,
  headerStyle: PropTypes.object,
  num_rows: PropTypes.number,
};

export default KpiTableCard;
