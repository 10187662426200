import PropTypes from 'prop-types';
import classNames from 'classnames';

const EventSourceIcon = ({ className, source, size = 16 }) => {
  const src = () => {
    switch (source) {
      case 'eventbrite':
        return '/images/eventbrite-logo.png';
      case 'facebook':
        return '/images/event-facebook-event.png';
      case 'reply':
        return '/images/tv_interaction_reply.png';
      default:
        return 'images/event-unknown-event.png';
    }
  };
  return (
    <span
      className={classNames('event-source-icon', className)}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${src()})`,
      }}
    />
  );
};

EventSourceIcon.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
  size: PropTypes.number,
};

export default EventSourceIcon;
