import _ from 'underscore';
import { createSource } from '@evertrue/et-flux';

// Most of the segment data is fetched from ListSource since they
// are retrieved from the same endpoint and fetched at the same time
export default createSource('SegmentSource', {
  actions: {
    applySegment(object_or_id) {
      this.require(_.isObject(object_or_id) || _.isNumber(object_or_id), 'object_or_id should be number or object');
    },
  },

  api: {
    applySegment(object_or_id) {
      this.actions.applySegment(object_or_id);
    },
  },
});
