import { useEffect, useState } from 'react';
import { Modal, Loading, ModalHeaderWithActions, useOpenClose } from '@evertrue/et-components';
import EmailSignaturesList from './email-signatures-list';
import EmailSignaturesForm from './email-signatures-form';
import PropTypes from 'prop-types';
import {
  getEmailSignatures,
  addEmailSignature,
  updateEmailSignature,
  deleteEmailSignature,
} from '../hooks/use-email-signatures-api';
import EmptyStateMessage from 'components/lists/empty-state-message-tv';
import AddSignatureModal from './add-signature-modal';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const noSignature = {
  id: 0,
  signature_name: 'No Signature',
  signature_body: '',
};

const EmailSignaturesModal = ({ isOpen, onClose, defaultSignature, setDefaultSignature, contact }) => {
  const [userSignatures, setUserSignatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [selectedSignature, setSelectedSignature] = useState({});
  const [isAddSigModalOpen, openAddSigModal, closeAddSigModal] = useOpenClose();

  const getAndSetSignatures = async () => {
    setLoading(true);
    const signatures = await getEmailSignatures();
    setLoading(false);
    setUserSignatures([...signatures, noSignature]);
    const currDefaultSignature = signatures.find((sign) => sign.default) || noSignature;
    setDefaultSignature(currDefaultSignature);
    setSelectedSignature(currDefaultSignature);
  };

  useEffect(() => {
    if (!userSignatures.length) {
      getAndSetSignatures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmailSignatures]);

  const handleAddSignature = async (signatureName, closeModal) => {
    try {
      const newSignature = await addEmailSignature({
        signature_name: signatureName,
        signature_body: '',
        default: false,
      });
      setUserSignatures(userSignatures.slice(0, userSignatures.length - 1).concat(newSignature, noSignature));
      closeModal();
    } catch {}
  };

  const handleUpdateDefaultSignature = async () => {
    const { id: prevDefaultId } = defaultSignature;
    const { id: selectedId } = selectedSignature;
    if (prevDefaultId !== selectedId) {
      await updateEmailSignature(selectedId === 0 ? prevDefaultId : selectedId, {
        default: selectedId !== 0,
      });
    }
    setDefaultSignature(selectedSignature);
    onClose();
  };

  const updateSignature = async (id, updatedSignature) => {
    const res = await updateEmailSignature(id, updatedSignature);
    if (res) {
      setUserSignatures(
        userSignatures.map((sig) => {
          if (sig.id === id) {
            return res;
          } else return sig;
        })
      );
      setSelectedSignature(res);
    }
  };

  const handleDeleteSignature = async (signId) => {
    const response = await deleteEmailSignature(signId);
    if (response && response.id) {
      let updatedSignaturesList = userSignatures.filter((signature) => signature.id !== response.id);
      setUserSignatures(updatedSignaturesList);
      if (response.default) {
        setDefaultSignature(noSignature);
      }
      if (selectedSignature.id === response.id) {
        if (response.default) {
          setSelectedSignature(noSignature);
        } else {
          setSelectedSignature(defaultSignature);
        }
      }
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedSignature(defaultSignature);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOutsideClick
      closeModal={handleClose}
      size={userSignatures.length > 1 ? '1000px' : '850px'}
    >
      {loading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <>
          <ModalHeaderWithActions
            title="Signature"
            submitLabel="Done"
            closeModal={handleClose}
            onSubmit={handleUpdateDefaultSignature}
            disableSubmit={!isSaved || userSignatures.length <= 1}
            closeLabel="Cancel"
          />

          {userSignatures.length > 1 ? (
            <div style={{ display: 'flex' }}>
              <EmailSignaturesList
                defaultSignature={defaultSignature}
                selectedSignature={selectedSignature}
                setSelectedSignature={setSelectedSignature}
                signatures={userSignatures}
                setUserSignatures={setUserSignatures}
                handleAddSignature={handleAddSignature}
                updateSignature={updateSignature}
                handleDeleteSignature={handleDeleteSignature}
                isModalOpen={isAddSigModalOpen}
                openModal={openAddSigModal}
                closeModal={closeAddSigModal}
              />
              {selectedSignature.id !== 0 && (
                <EmailSignaturesForm
                  selectedSignature={selectedSignature}
                  updateSignature={updateSignature}
                  setIsSaved={setIsSaved}
                  isSaved={isSaved}
                  contact={contact}
                />
              )}
            </div>
          ) : (
            <EmptyStateMessage
              style={{ padding: '60px' }}
              icon="email-signature-icon"
              iconKit="gt2"
              actionLabel={'Add Signature'}
              onAction={isAddSigModalOpen ? closeAddSigModal : openAddSigModal}
              text="Your signature awaits."
              children={
                <>
                  Add your contact details to give your emails a polished and consistent look. To learn more about Email
                  Signatures visit our help center article:&nbsp;
                  <a href={getLinkUrl('everTrue', 'emailsHelpCenterUrl')} target="_blank" rel="noreferrer">
                    Using Signal Email Templates and Signatures
                  </a>
                </>
              }
            />
          )}
          <AddSignatureModal
            isOpen={isAddSigModalOpen}
            close={closeAddSigModal}
            handleSubmit={(signatureName) => handleAddSignature(signatureName, closeAddSigModal)}
          />
        </>
      )}
    </Modal>
  );
};

EmailSignaturesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  defaultSignature: PropTypes.object,
  setDefaultSignature: PropTypes.func,
  contact: PropTypes.object,
};

export default EmailSignaturesModal;
