import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, ButtonTooltip, Button, Icon, Modal2, ModalConfirm, Flex, useOpenClose } from '@evertrue/et-components';
import { useFluxStore } from '@evertrue/et-flux';
import CollaboratorStore from 'apps/lists/stores/collaborator-store';
import CollaboratorSource from 'apps/lists/sources/collaborator-source';
import ListShareSettings from 'apps/lists/components/list-share-settings';

const useCollaboratorStore = (listId) =>
  useFluxStore(CollaboratorStore, () => ({
    collaborators: CollaboratorStore.getCollaborators(listId),
  }));

const CollaboratorIconList = ({ listId }) => {
  const [isShareModalOpen, openShareModal, closeShareModal] = useOpenClose();
  const { collaborators } = useCollaboratorStore(listId);

  useEffect(() => {
    CollaboratorSource.fetchCollaborators(listId);
  }, [listId]);

  const sortedCollaborators = collaborators?.sort((a, b) => (a.owner === b.owner ? 0 : a.owner ? -1 : 1)) || [];
  const hasCollaborators = collaborators?.length > 1; // account for owner

  return (
    <div className="collaborator-icon-list">
      {hasCollaborators &&
        sortedCollaborators.map((collab, index) =>
          collab.user ? (
            <ButtonTooltip
              key={`${collab.user.id}-${index}`}
              triggerClassName={classNames('collaborator-icon-list--tooltip', { 'is-owner': collab.owner })}
              onTriggerClick={openShareModal}
              className="collaborator-icon-list--action"
              trigger={
                <Avatar
                  name={collab.user.name || 'unnamed collaborator'}
                  url={collab.user.avatar}
                  className="collaborator-icon-list--icon"
                />
              }
            >
              {collab.user.name} {collab.owner ? ' is owner' : ' can edit'}
            </ButtonTooltip>
          ) : null
        )}

      <Button
        type="simple"
        onClick={openShareModal}
        className="collaborator-icon-list--action"
        title={hasCollaborators ? 'Manage Collaborators' : 'Add Collaborators'}
      >
        <Flex>
          <Icon icon={hasCollaborators ? 'settings' : 'group-add'} />
          {hasCollaborators ? 'Manage Collaborators' : 'Add Collaborators'}
        </Flex>
      </Button>

      <Modal2 contentStyle={{ width: '600px' }} isOpen={isShareModalOpen} onClose={closeShareModal}>
        <ModalConfirm header="Sharing Settings" className="share-settings--modal" closeModal={closeShareModal}>
          <ListShareSettings listId={listId} />
        </ModalConfirm>
      </Modal2>
    </div>
  );
};

CollaboratorIconList.propTypes = {
  listId: PropTypes.any.isRequired,
};

export default CollaboratorIconList;
