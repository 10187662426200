{div, ul, li, strong, p} = ReactLibs.DOMFactories
EngagementActionPill = require("apps/profile/components/engagement-action-pill").default
_ = require("underscore").default

Help =
   help_text:
      address_type:
         header: "Address Type"
         width: 340
         body: div className: "address-type--helptip",
            p null, "Filter constituents by the address types in your database. Useful for planning visits or events near peoples' home or business addresses."

      city:
         header: "City"
         body: div className: "city--helptip",
            p null, "Filter constituents by the cities in your database. If a city has a non-unique name — e.g., Springfield, Clinton — you may wish to add a state filter to be sure you're seeing results only from the city you intend."

      community_app_user_v2:
         header: "Community App User?"
         body: div className: "community-app-user--helptip",
            p null, "Filter constituents based on whether they are EverTrue Community App users."

      company_v2:
         width: 330
         header: "Company"
         body: div className: "company--helptip",
            p null, "Filter constituents by company."

      engagement_fb_v2:
         header: "Engaged on Facebook?"
         body: div className: "engagement-fb--helptip",
            p null, "Filter constituents based on whether they have Liked or Commented on any of your connected Facebook pages."

      facebook_page:
         header: "Facebook Page Engagement"
         body: div className: "facebook-page--helptip",
            p null, "Filter constituents who have engaged with specific Facebook pages. A page must already be connected to EverTrue
            in order to return results. Combine this filter with the Facebook Content Engagement and Facebook Engagement Date filters
            to further refine your search."

      facebook_content:
         header: "Facebook Content Engagement"
         width: 330
         body: div className: "facebook-content--helptip",
            p null, "Filter constituents who have engaged with Facebook posts that contain specific keywords
            — e.g. \"football\", \"theater\", \"professor\". Combine this filter with the Facebook Engagement Date
            and Facebook Page Engagement filters to further refine your search."

      facebook_date:
         header: "Facebook Engagement Date"
         body: div className: "facebook-date--helptip",
            p null, "Filter constituents based on dates of engagement with your Facebook posts.
            You can filter by dates that constituents did, or did not, engage. Combine this filter with
            the Facebook Content Engagement and Facebook Page Engagement filters to further refine your search."

      evertrue_score_v2:
         header: "EverTrue Score"
         width: 305
         body: div className: "et-index--helptip",
            p null, "EverTrue Score predicts expected giving likelihood and gift size over the next 3 years,
            relative to other constituents. We use anonymized, cross-institutional data from hundreds of
            customers to determine how aspects of constituents' profiles predict giving likelihood and
            expected gift value."

      gift_amount:
         header: "Gift Amount"
         body: div className: "gift-amount--helptip",
            p null, "Filter constituents who gave a gift within the specified dollar range.
            This filter queries individual gift amounts only, not the sum of multiple gifts.
            Combine this filter with the Gift Label and Gift Date filters to further refine your search."

      gift_date:
         header: "Gift Date"
         body: div className: "gift-date--helptip",
            p null, "Filter constituents who gave a gift within the specified date range.
            Combine this filter with the Gift Amount and Gift Label filters to further refine your search."

      gift_label:
         header: "Gift Label"
         width: 310
         body: div className: "gift-label--helptip",
            p null, "Filter constituents who gave a gift with the specified label(s). Gift labels are imported by your organization,
            and may include gift designation, payment type, appeal code, and other detailed gift data. Combine this filter
            with the Gift Amount and Gift Date filters to further refine your search."

      categorized_giving_filters:
         header: "Categorized Giving"
         body: div null,
            ul className: "bullet-list",
               li null,
                  div null, strong null,
                     "Category Donor Status..."
                  "Find constituents who have given to a particular giving category, or exclude anyone who has given to a giving category."

               li null,
                  div null, strong null,
                     "Advanced Categorized Giving Filters"
                  "Find or exclude constituents who have given a certain amount, or recently, for a giving category."


      education_school:
         header: "Education"
         body: div null,
            p null, "Filter constituents by education. Education values are imported by your organization,
            and may include schools within your institution and other institutions, depending on the data provided.
            Combine this filter with the Degree, Major, and Education Year filters to further refine your search."

      education_degree:
         header: "Degree"
         body: div null,
            p null, "Filter constituents based on degree. Degrees are imported by your organization.
            Combine this filter with the Education, Major, and Education Year filters to further refine your search."

      education_major:
         header: "Major"
         body: div null,
            p null, "Filter constituents by major. Majors are imported by your organization.
            Combine this filter with the Education, Degree, and Education Year filters to further refine your search."

      education_year:
         header: "Education Year"
         body: div null,
            p null, "Filter constituents by the years in which a specific education was completed — 
            e.g. BS in Chemistry completed between 2010 and 2015. This filter is most useful when
            combined with Education, Degree, or Major filters. Please note, Education Year is not
            necessarily the same as the primary graduation year displayed in the Graduation Year filter."

      interaction_content:
         header: "Interaction Content"
         body: div null,
            p null, "Filter constituents based on keywords within interactions. Interactions are imported
            by your organization, and may include contact/visit reports, notes, tasks, and actions."
            p null, "Combine this filter with the Interaction Type, Interaction Date, and Interaction Author filters to further refine your search."

      interaction_type:
         header: "Interaction Type"
         body: div null,
            p null, "Filter constituents based on interaction type. Interactions are imported by your organization,
            and may include contact/visit reports, notes, tasks, and actions."
            p null, "Combine this filter with the Interaction Content, Interaction Date, and Interaction Author filters to further refine your search."

      interaction_date:
         header: "Interaction Date"
         body: div null,
            p null, "Filter constituents by interaction date. Interactions are imported by your organization and may
            include contact/visit reports, notes, tasks, and actions."
            p null, "Combine this filter with the Interaction Content, Interaction Type, and Interaction Author filters to further refine your search."

      interaction_author:
         header: "Interaction Author"
         body: div null,
            p null, "Filter constituents by interaction author. Interactions are imported by your organization and may
            include contact/visit reports, notes, tasks, and actions."
            p null, "Combine this filter with the Interaction Content, Interaction Type, and Interaction Date filters to further refine your search."

      proposal_filters:
         header: "Proposals"
         body: div null,
            p null, "Filter constituents by proposal ask amount, ask date, status, solicitors and active state. Proposals are imported by your organization."
            p null, "Combine multiple proposal filters to further refine your search."

      industry_v2:
         width: 320
         header: "Industry"
         body: div className: "industry--helptip",
            p null, "Filter constituents by industry."

      wealthy_neighborhood_v2:
         header: "Lives in a Wealthy Neighborhood"
         body: div className: "wealthy-neighborhood--helptip",
            p null, "Filter constituents who have an address in a census tract with a median home value greater than $1M. Based on U.S. Census Bureau data."

      median_home_value:
         header: "Neighborhood Median Home Value"
         body: div className: "median-home-value--helptip",
            p null, "Filter constituents by the median home value of their home address neighborhood. Based on U.S. Census Bureau data."

      median_income:
         header: "Neighborhood Median Income"
         body: div className: "median-income--helptip",
            p null, "Filter constituents by the median income of their home address neighborhood. Based on U.S. Census Bureau data."

      reunion_year_group_v2:
         header: "Reunion Year Group"
         body: div className: "reunion-year-group--helptip",
            p null, "Filter constituents by reunion class. Groups are based on last digit and intervals of 5 —  e.g., 2's and 7's return graduates of 2012, 2007, 2002, etc."

      capacity_rating:
         header: "Your Capacity Rating"
         body: div className: "capacity-rating--helptip",
            p null, "Filter constituents by your organization's constituent capacity score or ranking, if provided. This score is based on your organization's own scoring process."

      engagement_score_v2:
         header: "Your Engagement Score"
         body: div className: "engagement-score--helptip",
            p null, "Filter constituents by your organization's constituent engagement score or ranking, if provided. This score is based on your organization's own scoring process."

      capacity_score:
         header: "Capacity Score"
         body: div null, "Your Capacity Score reflects your institution's constituent capacity
            score or ranking based on your institution’s specific scoring process (if provided)"

      engagement_score:
         header: "Engagement Score"
         body: div null, "Your Engagement Score reflects your institution's constituent engagement score or
            ranking based on your institution's specific scoring process (if provided)"

      engagement_fb:
         header: "Facebook Engagement"
         body: div null, "Select to include constituents who have liked or commented on any of your provided
            Facebook pages"

      evertrue_score:
         header: "EverTrue Score"
         body: div null, "The EverTrue Score predicts expected giving likelihood and gift size over the next 3 years,
            relative to other constituents. We use anonymized, cross-institutional data from hundreds of
            customers to determine how aspects of constituents' profiles predict giving likelihood and
            expected gift value."

      giving_score:
         header: "Giving Score"
         body: div null, "Giving Score is a component of EverTrue Score. It ranks constituents' predicted giving
            for the next 3 years, based on the past 3 years of giving data."

      profile_score_professional:
         header: "Professional Score"
         body: div null, "Ranks constituent's predicted giving for the next 3 years,
            based on industry, where available."

      profile_score_demographic:
         header: "Demographic Score"
         body: div null, "Ranks constituent's predicted giving for the next 3 years,
            based on imputed age."

      profile_score_giving:
         header: "Giving Score"
         body: div null, "Ranks constituent's predicted giving for the next 3 years,
            based on past 3 years of giving data."

      profile_score_social:
         header: "Social Score"
         body: div null, "Ranks constituent's predicted giving for the next 3 years,
            based on lifetime Facebook engagement data, where available."

      industry:
         header: "Industry"
         body: div null, "Select to view constituents associated with an industry according to your data. Select multiple industries by clicking multiple checkboxes"

      company:
         header: "Company"
         body: div null, "Select to view constituents associated with an company according to your data. Select multiple companies by clicking multiple checkboxes"

      location:
         header: "Location"
         body: div null, "Select a location to view constituents who include
            the specific geographic area on their profile"

      reunion_year_group:
         header: "Reunion Year Group"
         body: div null, "Reunion classes based on last digit and intervals of 5.
            Ex: 2s and 7s return graduates of 2012, 2007, 2002, etc."

      est_home_value:
         header: "Median Home Value"
         body: div null, "Median Home Value ($USD) of the provided home address neighborhood based on 2020 Census data"

      wealthy_neighborhood:
         header: "Lives in a Wealthy Neighborhood"
         body: div null, "Has an address in a census tract with a median home value greater than $1M"

      fb_likes:
         header: "Facebook Likes"
         body: div null, "Number of Facebook Likes by constituent on any of the provided Facebook posts"

      fb_comments:
         header: "Facebook Comments"
         body: div null, "Number of Facebook comments by constituent on any of the provided Facebook posts"

      fb_reactions:
         header: "Facebook Reactions"
         body: div null, "The total number of Facebook Reactions by a constituent on all posts associated with your connected Facebook pages"

      fb_engagements:
         header: "Facebook Engagements"
         body: div null, "The total number of Facebook Reactions and Comments by a constituent on all matched Pages"

      tv_engagements:
         header: "ThankView Engagements"
         body: div null,
         ul className: "bullet-list-no-bullet",
            li null,
            "The number of unique engagements a constituent has made with ThankView videos from your connected ThankView portal"
            li null,
            "i.e., ThankViews Viewed is counted only once per video"

      tv_engagement_status:
         header:"ThankView Engagement Status"
         body: div null,
            ul className: "bullet-list-no-bullet",
               li null,
               "Active - Constituents who can receive/have received ThankViews"
               li null,
               "Inactive - Constituents whose contact information has bounced, been unsubscribed, or marked messages as spam"
               li null,
               "Not Available - This individual’s constituent ID is not matched correctly or cannot be found in ThankView"

      event_engagements:
         header: "Event Engagements"
         body: div null, "The total number of times a constituent has engaged with events on your connected Facebook pages"

      event_engagement_filters:
         header: "Event Engagement Filters"
         width: 370
         body: div null,
            ul className: "bullet-list",
               li null,
                  div null, strong null,
                     "Constituents who engaged positively with..."
                  "Find constituents who engaged with events with responses of "
                  EngagementActionPill action: "Checked In"
                  ", "
                  EngagementActionPill action: "Attending"
                  ", or "
                  EngagementActionPill action: "Interested"
                  " within various time ranges."

               li null,
                  div null, strong null,
                     "Advanced Events Filters"
                  "Find constituents who have engaged (or did not engage) with specific events, events in a date range, or have specific event responses such as "
                  EngagementActionPill action: "Checked In"
                  ", "
                  EngagementActionPill action: "Attending"
                  ", "
                  EngagementActionPill action: "Interested"
                  ", "
                  EngagementActionPill action: "Invited"
                  ", or "
                  EngagementActionPill action: "Can't Go"

      event_imported_email:
         header: "Imported Email"
         width: 300
         body: div null, "This email was imported by your institution.  Eventbrite activity with this email will be automatically matched to this constituent."

      et_usage:
         header: "Community Engagement"
         body: div null, "Indicates the number of times the constituent has logged into your EverTrue Community"

      vol_usage:
         header: "Volunteer Engagement"
         body: div null, "Indicates the number of times, and the most recent time, the constituent has logged into your EverTrue Volunteers"


      relationships:
         header: "Relationships Data"
         width: 300
         body: div null, "Relationships in EverTrue come from your database. Links are made only when the Family Member's ID matches the Remote ID of another Constituent in EverTrue."

      company_giving_summary:
         header: "Company Giving"
         body: div null, "Giving summary for constituents who currently are or have been employed at this
         company according to your data."

      company_participation:
         header: "Company Participation"
         body: div null, "Participation summary for constituents who currently are or have been employed at this
         company according to your data."

      company_constituents:
         header: "Constituents at Company"
         body: div null, "Includes constituents who are currently or have been employed at this company
         based on your data."

      industry_giving_summary:
         header: "Industry Giving"
         body: div null, "Giving summary for constituents who currently are or have been employed at a
         company in this industry according to your data."

      industry_participation:
         header: "Industry Participation"
         body: div null, "Participation summary for constituents who currently are or have been employed at a
         company in this industry according to your data."

      industry_constituents:
         header: "Constituents in Industry"
         body: div null, "Includes constituents who are currently or have been employed at a company in this
         industry according to your data."

      popular_companies:
         header: "Popular Companies in Industry"
         body: div null, "Companies associated with this industry ordered by the companies with the
         most number of constituents"

      community_app_user:
         header: "Community Engagement"
         body: div null, "Select to include constituents who are EverTrue Community App users"

      unrated:
         header: "Rated"
         body: div null, "Select to include only constituents who do not have a capacity rating"

      trust_device:
         header: "Trust Device"
         body: div null, "Trusted devices only require a 2-Step Verification code every 30 days or when performing sensitive actions.
         Untrusted devices require entering a new code after 1 hour of inactivity.
         A new code will always be required on your next login if you selected \"Sign Out\"."

      what_is_mfa:
         header: "2-Step Verification"
         body: div null, "2-Step Verification adds extra security when logging in by requiring both a password and
         a mobile device that can receive text messages."

      notifications_unavailable:
         header: "Unavailable Notifications"
         body: div null, "This search contains one or more filters that are ineligible for notifications.
         You will not receive email updates or mobile notifications if new results
         match your search criteria."

      deceased:
         header: "Deceased?"
         body: div className: "deceased--helptip",
            p null, "Filter constituents by deceased status. By default, searches include
            all imported constituent records, living and deceased. If you wish to create an
            export file for the purpose of solicitation, use this filter to exclude deceased results."

      giving_pattern: ->
         width: 428
         header: "Giving Pattern"
         body: div className: "giving-pattern--helptip",
            p null, "Giving Pattern categorizes constituents based on their recent giving history."
            ul className: "bullet-list",
               li null,
                  strong null, "LYBUNT: "
                  "Gave last fiscal year, but not yet this fiscal year."
               li null,
                  strong null, "SYBUNT: "
                  "Gave some years, but not this fiscal year or the previous fiscal year. Does not include LYBUNTs."

      lifetime_recognition:
         header: "Lifetime Recognition"
         body: div className: "lifetime-recognition--helptip",
            p null, "Lifetime Recognition Credit is calculated by adding all imported hard credits,
            soft credits and outstanding Pledge balances."

      profile_lifetime_import:
         header: "Lifetime Giving"
         body: div className: "lifetime-rollups--helptip",
            p null, "Lifetime giving total is imported into EverTrue from your institution's database."

      profile_last_gift_import:
         header: "Last Gift"
         body: div className: "last-gift-rollups--helptip",
            p null, "Last gift value is imported into EverTrue from your institution's database."

      profile_largest_gift_import:
         header: "Largest Gift"
         body: div className: "largest-gift-rollups--helptip",
            p null, "Largest gift value is imported into EverTrue from your institution's database."

      profile_pledges_import:
         header: "Lifetime Giving"
         body: div className: "lifetime-rollups--helptip",
            p null, "Pledge balance is imported into EverTrue from your institution's database."

      profile_gift_transactions:
         header: "Gift Transactions"
         body: div className: "gift-transactions--helptip",
            p null, "All Gift Transaction dates are shown in your current timezone."       
      
      profile_ai_summary_import:
         body: div className: "ai-tooltip-text--helptip",
         p null, "This profile summary has been automatically generated by artificial intelligence (AI). It provides a concise overview of the constituent's background, skills, and experience based on available data. Use this profile summary as a quick reference to get a snapshot of the constituent's information."

      profile_evertrue_score:
         width: 428
         header: "EverTrue Score"
         body: div className: "et-index--helptip",
            p null, "EverTrue Score predicts expected giving likelihood and gift size over the next 3 years, relative to other constituents. We use anonymized, cross-institutional data from hundreds of customers to determine how aspects of constituents' profiles predict giving likelihood and expected gift value."
            ul className: "bullet-list",
               li null,
                  strong null, "Social: "
                  "Ranks lifetime Facebook engagement data, where available for matched constituents."
               li null,
                  strong null, "Demographic: "
                  "Ranks constituents by imputed age, based on imported graduation year data, where available."
               li null,
                  strong null, "Professional: "
                  "Ranks constituents by industry, where available."
               li null,
                  strong null, "Giving: "
                  "Ranks constituents based on past 3 years of giving data."

      annual_giving_score:
         header: "Annual Giving Score"
         body: "The EverTrue Annual Giving Score predicts the relative likelihood that a constituent
            will make a gift of any size within the current fiscal year. Each constituent receives a
            score on a 100 (most likely to make a gift) down to 0 (least likely) scale, based on
            multiple variables including giving history, social media engagement, and demographics"

      recent_online_gift:
         header: "Recent Online Gift"
         body: "
            There is a recent online gift attributed to this constituent,
            but the gift record may not have been processed and imported into EverTrue yet.
         "

      facebook_filters:
         header: "Facebook Filters"
         body: div null,
            p null, "Filter your post results or engagement results by the Facebook page, Facebook engagement date,
            or keywords contained in your Facebook posts."

      facebook_constituent_filters:
         header: "Constituent Filters"
         body: div null,
            p null, "Filter your Facebook engagements by lifetime giving, location, or assignment."
            p null, "Only available for Facebook engagements."

      facebook_date_selector:
         header: "Facebook Engagement Date"
         body: div className: "facebook-date--helptip",
            p null, "Filter constituents based on dates of engagement with your Facebook posts.
            Combine this filter with the Facebook Content Engagement and Facebook Page Engagement filters to further refine your search."

      posts_facebook_page:
         header: "Facebook Page Engagement"
         body: div className: "facebook-page--helptip",
            p null, "Filter all posts by a specific Facebook page or set of pages. A page must already be connected to EverTrue
            in order to return results. Combine this filter with the Facebook Content Engagement and Facebook Engagement Date filters
            to further refine your search."

      posts_facebook_content:
         header: "Facebook Content Engagement"
         width: 330
         body: div className: "facebook-content--helptip",
            p null, "Filter results to show Facebook posts that contain specific keywords
            — e.g. \"football\", \"theater\", \"professor\". Combine this filter with the Facebook Engagement Date
            and Facebook Page Engagement filters to further refine your search."

      posts_facebook_date_selector:
         header: "Facebook Engagement Date"
         body: div className: "facebook-date--helptip",
            p null, "Filter results based on the dates of constituent engagement with your posts. Combine this filter with
            the Facebook Content Engagement and Facebook Page Engagement filters to further refine your search."

      user_gate_state:
         header: "Enabled in current state"
         body: div null,
            "This column indicates which gates are available in the current state of your app. It combines what's enabled
            by the organization's settings and what you have enabled for your user."

      did_not_give_this_fiscal_year:
         header: "Did not give this Fiscal Year"
         body: div null,
            "Shows consituents who have not given this fiscal year based on both Online Giving Data, and the imported property 'Last Gift Date'. "

      online_gift_filters:
         header: "Online Gift Filters"
         body: div null,
            "Filter constituents based on their online gifts.  Gift properties can be Date, Amount, Online Giving Page, Designation, and Comments"

      has_primary_email:
         header: "Has Primary Email Address?"
         body:
            "Use this filter to build an email marketing segment by only
            including constituents with primary emails"

      my_trips_meeting_time:
         header: "Meeting Time"
         body: div null,
            p null, "Time is a required field for a meeting and is restricted to the days of the trip."
            "Setting the meeting location will set the timezone."

      my_meeting_time:
         header: "Meeting Time"
         body: div null,
            p null, "Time is a required field for a meeting."
            "The timezone will match what has been set for your organization."

      my_trips_location:
         header: "Selecting Location"
         body:
            "Selecting Location helps us calculate the time zone, distance, and travel time."

      virtual_meeting_url:
         header: "Valid URL"
         body:
            "Just a quick copy and paste from your platform of choice and you're good to go."

      appeal_conversion:
         header: "Appeal Conversion"
         body:
            "Appeal Conversion is the number of gifts attributed to the appeal divided by the total number of
            constituents that were solicited in the appeal"

      my_team_visits:
         header: "Visit Interactions"
         body: "The total number of interactions imported or created
         in EverTrue with specific types that are configured for your organization."

      my_team_solicitors:
         header: "Solicitor"
         body: "EverTrue Users who have been mapped to a Solicitor ID and have assigned prospects."

      my_team_meetings:
         header: "My Trips Meetings"
         body: "My Trips Meetings shows the count of meetings created by each solicitor."

      my_team_prospects:
         header: "Prospect Distribution"
         body: "Shows the count of prospects in each Prospect Stage for all solicitors."

      trip_collaborators:
         header: "Trip Collaborators"
         body: "Adding an EverTrue user as a collaborator gives them the ability to view and edit the trip and all meetings contained in the trip.
         In addition, the trip will appear in My Trips and in their EverTrue calendar feed."


      export_frequency:
         header: "Export Frequency"
         body: "Export Frequency is how often the EverTrue exporter will send a
         new csv file to your organization."

      scheduled_export_failure:
         header: "Could Not Export File"
         body: "We’ll attempt another export at the next scheduled time. All data since the last successful export will be included."

      facebook_pages_auth:
         header: "Facebook Pages Authentication"
         body: "A Facebook account with at least Basic Admin permissions for this page needs to be
         connected in order for EverTrue to access constituent engagement with posts."

      portfolio_solicitors_assignees:
         header: "Total Prospects Assigned"
         body: div className: "portfolio-solicitors-assignees--helptip",
            p null, "Indicates the number of constituents currently assigned to the solicitor."

      portfolio_solicitors_proposals:
         header: "Total Proposals"
         body: div className: "portfolio-solicitors-proposals--helptip",
            p null, "Includes both active and inactive proposals."

# If you need conditional log in your tooltip, make the object a function
getText = (key) ->
   if _.isFunction(Help.help_text[key])
      Help.help_text[key]()
   else
      Help.help_text[key] || ""

module.exports = {
   helpText: Help.help_text,
   getText
}
