import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const SubscriptionPrefSource = createSource('SubscriptionPrefSource', {
  actions: {
    fetchedPreferences(preferences) {
      this.require(Array.isArray(preferences), 'preferences should be an array');
    },

    updatedPreference(preference) {
      this.require(typeof preference === 'object', 'preference should be an object');
    },

    deletedPreference(key) {
      this.require(typeof key === 'string', 'key should be string');
    },
  },

  formatPreferences(prefs) {
    return {
      ...prefs,
      email_frequency: prefs.email_frequency.toLowerCase(),
      push_frequency: prefs.push_frequency.toLowerCase(),
    };
  },

  api: {
    fetch() {
      return Api.EMS.PREFS.get({
        success: (resp) => {
          this.actions.fetchedPreferences(resp.preferences);
        },
      });
    },

    update(key, data) {
      return Api.EMS.PREFS.put({
        urlExtend: `/${key}`,
        data: JSON.stringify(this.formatPreferences(data)),
        success: (pref) => {
          this.actions.updatedPreference(pref[0]);
        },
      });
    },

    delete(key) {
      return Api.EMS.PREFS.delete({
        urlExtend: `/${key}`,
        disableAlerts: true,
        success: () => {
          this.actions.deletedPreference(key);
        },
      });
    },
  },
});

export default SubscriptionPrefSource;
