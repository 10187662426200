import { PropTypes } from 'base/react-libs';
import { Icon } from '@evertrue/et-components';

const TaskContactDetails = ({ contact = {}, actionType = '' }) => {
  const phones = contact?.phones || [];
  const emails = contact?.emails || [];
  const messages = [];

  switch (actionType) {
    case 'CALL':
      if (phones.length === 0) {
        messages.push('Missing Phone Number');
      }
      break;
    case 'EMAIL':
      if (emails.length === 0) {
        messages.push('Missing Email Address');
      }
      break;
    case 'OTHER':
      if (phones.length === 0) {
        messages.push('Missing Phone Number');
      }
      if (emails.length === 0) {
        messages.push('Missing Email Address');
      }
      break;
    default:
      break;
  }

  return (
    <div>
      {messages.length > 0 && (
        <div>
          {messages.map((message) => (
            <div key={message} style={{ color: 'grey' }}>
              <Icon iconKit="gt2" icon="exclamation" />
              <span className="tasks-contact-details-info">{message}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TaskContactDetails.propTypes = {
  contact: PropTypes.object,
  actionType: PropTypes.string,
};

export default TaskContactDetails;
