import _ from 'underscore';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFluxStore } from '@evertrue/et-flux';
import MapSource from 'apps/map/sources/map-source';
import MapStore from 'apps/map/stores/map-store';
import FeatureStore from 'apps/layout/stores/feature-store';
import FilterStore from 'apps/filters/stores/filter-store';
import Loading from 'components/elements/loading';
import SelectContactCheckbox from 'apps/contact/components/contacts/select-contact-checkbox';
import ScoreProgress from 'apps/contact/components/contacts/score-progress';
import SortDropdown from 'components/controls/sort-dropdown';
import ContactCard from 'apps/contact/components/contact-card/contact-card';
import ContactGiving from 'apps/contact/components/contact-card/contact-giving';
import { isLatLngInBox } from 'apps/map/map-utils';
import Decorator from 'clientDecorator';

const _options = [
  { label: 'Distance from map center', value: 'proximity_sort' },
  { label: 'EverTrue Score', value: 'score.score' },
  { label: 'Lifetime Giving', value: 'giving.lifetime_amount' },
];

const hasScoresMapState = () => ({ has_scores: FeatureStore.hasFeature('scores') });
const mapStoreMapState = () => ({ bounding_box: MapStore.getBoundingBox(), clusters: MapStore.getClusters() });
const filterStoreMapState = () => ({
  types: _.map(FilterStore.getActiveFilterByKey('address_type'), (filter) => filter.value),
});

const MapContactsController = ({
  loading,
  contacts,
  onSort,
  hasActiveFocus,
  sortProp,
  sortReverse,
  sort_options,
  hideGiving,
  showLastGiftDate,
  hideScores,
  contactCardChildren,
}) => {
  const contactListRef = useRef();
  let mouseOutTimeout;

  const { has_scores } = useFluxStore(FeatureStore, hasScoresMapState);

  const { clusters } = useFluxStore(MapStore, mapStoreMapState);

  const { types } = useFluxStore(FilterStore, filterStoreMapState);

  useEffect(() => {
    if (hasActiveFocus) {
      contactListRef.current.focus();
    }
  }, [hasActiveFocus]);

  const handleMouseOver = (contact) => {
    clearTimeout(mouseOutTimeout);
    const lat_lngs = _.compact(
      _.map(contact.addresses, (address) => {
        const address_not_filtered = _.isEmpty(types) || _.contains(types, address.type?.value);
        if (address.lat?.value && address.lng?.value && address_not_filtered) {
          return { lat: address.lat.value, lng: address.lng.value };
        }
      })
    );

    const highlightedClusters = _.compact(
      _.map(clusters, (cluster, key) => {
        if (_.any(lat_lngs, (latlng) => isLatLngInBox(latlng, cluster.geobox))) {
          return key;
        }
      })
    );

    MapSource.highlightCluster(highlightedClusters);
  };

  const handleMouseOut = () => {
    clearTimeout(mouseOutTimeout);
    mouseOutTimeout = _.delay(() => MapSource.highlightCluster(), 100);
  };

  return (
    <div className="map-contacts" tabIndex={-1} ref={contactListRef}>
      {loading ? (
        <Loading text="Loading..." position="top" />
      ) : (
        <div>
          <div className="map-contacts--header">
            <SortDropdown
              options={sort_options || _options}
              sortProp={sortProp || contacts.sortProp}
              sortReverse={sortReverse || contacts.sortReverse}
              onSort={onSort}
            />
          </div>

          <div className="map-contacts--wrapper">
            <table>
              <tbody>
                {_.map(contacts.items, (contact) => {
                  if (_.isEmpty(contact)) return null;

                  return (
                    <tr className="map-contacts--contact" key={contact.id}>
                      <td className="map-contacts--checkbox">
                        <SelectContactCheckbox id={contact.id} name={Decorator.Contacts.getDetailedName(contact)} />
                      </td>
                      <td className="map-contacts--card">
                        <ContactCard
                          contact={contact}
                          onMouseOver={() => handleMouseOver(contact)}
                          onMouseOut={handleMouseOut}
                        >
                          {!hideGiving && <ContactGiving contact={contact} showLastGiftDate={showLastGiftDate} />}

                          {_.isFunction(contactCardChildren) && contactCardChildren(contact)}

                          {!hideScores && has_scores.has_scores && (
                            <div className="map-contacts--score">
                              <span className="text-label">EverTrue Score:</span>
                              <ScoreProgress score={contact.score?.score?.value} />
                            </div>
                          )}
                        </ContactCard>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

MapContactsController.propTypes = {
  loading: PropTypes.bool,
  contacts: PropTypes.object,
  onSort: PropTypes.func,
  hasActiveFocus: PropTypes.bool,
  sortProp: PropTypes.string,
  sortReverse: PropTypes.bool,
  sort_options: PropTypes.array,
  hideGiving: PropTypes.bool,
  showLastGiftDate: PropTypes.bool,
  hideScores: PropTypes.bool,
  contactCardChildren: PropTypes.func,
};

export default MapContactsController;
