import * as Yup from 'yup';

export const CreateJourneySchema = Yup.object().shape({
  journeyName: Yup.string()
    .required('Cadence name is required')
    .max(120, 'Cadence name must be at most 120 characters long'),
  journeyDescription: Yup.string().nullable().max(250, 'Cadence description must be at most 250 characters long'),
  journeyGroupId: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.mixed().required(),
    })
    .required('Please select a Cadence Category'),
  journeyType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.mixed().oneOf(['OTHER', 'QUALIFICATION', 'CULTIVATION', 'SOLICITATION', 'STEWARDSHIP']).required(),
    })
    .required(),
});
