/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { fetchSignalIndex, deleteVideo } from '../../entities/helpers/thankview-helper';
import { useRecorder } from './hooks/recorder-context';
import useUserAuthentication from './hooks/useUserAuthentication';
import { Loading, ModalHeaderWithActions, Icon } from '@evertrue/et-components';
import EmptyStateMessage from 'components/lists/empty-state-message-tv';
import VideoList from './library-components/video-list';
import EverTrue from 'app';
import ControlTools from './library-components/control-tools';
import { getBrowserTimezone } from './utils/video-helpers';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const PAGE_LIMIT = 10;
const filterTypes = [
  { label: 'Date Created', value: 'created_at', defaultOrder: 'desc' },
  { label: 'Date Modified', value: 'updated_at', defaultOrder: 'desc' },
  { label: 'Title', value: 'name', defaultOrder: 'asc' },
  { label: 'Duration', value: 'video_length', defaultOrder: 'asc' },
];

function ThankviewLibrary() {
  const { updateState, stopAndResetRecorder } = useRecorder();
  const { handleCloseThankviewModal, handleChangeModalView } = useSignalVideoModalContext();
  const { clientId } = useUserAuthentication();
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [userTimezone, setUserTimezone] = useState('UTC');
  // const [startsOn, setStartsOn] = useState(() => moment().tz(userTimezone).startOf("day"));
  const [searchText, setSearchText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(filterTypes[0]);
  const [isAscOrder, setIsAscOrder] = useState(filterTypes[0].defaultOrder === 'asc');
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [libraryVideos, setLibraryVideos] = useState([]);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const observerTarget = useRef(null);

  // const isAllSelected = useMemo(
  //   () => selectedVideos.length === listings.length && listings.length > 0,
  //   [selectedVideos, listings]
  // );

  const fetchInitialData = useCallback(
    async (showLoading = false) => {
      if (showLoading) setLoading(true);
      try {
        if (clientId) {
          let client_id = clientId && clientId.data.client_id;
          let client_secret = clientId && clientId.data.client_secret;
          const data = {
            sort: 'created_at',
            order: 'desc',
            page: 1,
            limit: '',
            filters: JSON.stringify({ is_signal: true, user_id: clientId.data.id }),
          };
          const {
            data: { personalVideos, libraryVideos, count },
          } = await fetchSignalIndex(data, client_id, client_secret);
          const uniquePersonalVideos = [];
          const uniqueLibraryVideos = [];
          const seenPersonalIds = new Set();
          const seenLibraryIds = new Set();

          personalVideos.forEach((video) => {
            if (!seenPersonalIds.has(video.id)) {
              seenPersonalIds.add(video.id);
              uniquePersonalVideos.push(video);
            }
          });

          libraryVideos.forEach((video) => {
            if (!seenLibraryIds.has(video.id)) {
              seenLibraryIds.add(video.id);
              uniqueLibraryVideos.push(video);
            }
          });

          setListings((prev) => (pageNum === 1 ? uniquePersonalVideos : [...prev, ...uniquePersonalVideos]));
          setLibraryVideos((prev) => (pageNum === 1 ? uniqueLibraryVideos : [...prev, ...uniqueLibraryVideos]));
          const currentCount = (pageNum - 1) * PAGE_LIMIT + uniquePersonalVideos.length;
          setCount(currentCount);
          setTotalCount(count);
        }
      } catch (err) {
        console.error('Error fetching initial data:', err);
      } finally {
        if (showLoading) setLoading(false);
      }
    },
    [clientId, pageNum]
  );

  useEffect(() => {
    fetchInitialData(true);
  }, [clientId, fetchInitialData]);

  useEffect(() => {
    const timezone = getBrowserTimezone();
    if (timezone !== userTimezone) {
      setUserTimezone(timezone);
    }
  }, [getBrowserTimezone, userTimezone]);

  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        if (clientId) {
          const { client_id, client_secret } = clientId.data;
          const filters = {
            is_signal: true,
            name: searchText,
            user_id: clientId.data.id,
            from_date: startDate ? moment(startDate).tz(userTimezone).startOf('day').format() : '',
            to_date: endDate ? moment(endDate).tz(userTimezone).endOf('day').format() : '',
          };

          const data = {
            sort: selectedFilter.value,
            order: isAscOrder ? 'asc' : 'desc',
            page,
            limit: PAGE_LIMIT,
            filters: JSON.stringify(filters),
          };

          const results = await fetchSignalIndex(data, client_id, client_secret);
          processResults(results, page);
        }
      } catch (err) {
        console.error('Error fetching data in video library:', err);
      } finally {
        setLoading(false);
      }
    },
    [clientId, isAscOrder, searchText, selectedFilter.value, startDate, endDate, userTimezone]
  );

  useEffect(() => {
    fetchData(pageNum);
  }, [isAscOrder, searchText, selectedFilter.value, startDate, endDate, pageNum]);

  const processResults = useCallback((results, page) => {
    const { personalVideos, libraryVideos, count } = results.data;
    const uniquePersonalVideos = removeDuplicates(personalVideos);
    const uniqueLibraryVideos = removeDuplicates(libraryVideos);

    setListings((prev) => (page === 1 ? uniquePersonalVideos : [...prev, ...uniquePersonalVideos]));
    setLibraryVideos((prev) => (page === 1 ? uniqueLibraryVideos : [...prev, ...uniqueLibraryVideos]));
    setCount((page - 1) * PAGE_LIMIT + uniquePersonalVideos.length);
    setTotalCount(count);
  }, []);

  const removeDuplicates = useCallback((videos) => {
    const seenIds = new Set();
    return videos.filter((video) => {
      if (!seenIds.has(video.id)) {
        seenIds.add(video.id);
        return true;
      }
      return false;
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && totalCount > 0 && count < totalCount) {
          setPageNum((prev) => prev + 1);
        }
      },
      { threshold: 1.0 }
    );

    const target = observerTarget.current;
    if (target) observer.observe(target);
    return () => {
      if (target) observer.unobserve(target);
    };
  }, [loading, totalCount, count]);

  useEffect(() => {
    setListings([]);
    setLibraryVideos([]);
    setCount(0);
    setTotalCount(0);
    setPageNum(1);
    setSelectedVideos([]);
  }, [searchText, selectedFilter.value, isAscOrder, startDate, endDate]);

  const toggleVideoSelection = useCallback(
    (videoId) => {
      setSelectedVideos((prev) => (prev.includes(videoId) ? prev.filter((id) => id !== videoId) : [...prev, videoId]));
    },
    [selectedVideos.length, updateState]
  );

  // const handleSelectAll = useCallback(() => {
  //   setSelectedVideos(isAllSelected ? [] : listings.map((video) => video.id));
  // }, [isAllSelected, listings]);

  const deleteVideoEntry = useCallback(
    (videoId) => {
      try {
        const client_id = clientId.data.client_id;
        const client_secret = clientId.data.client_secret;

        EverTrue.Alert.confirm(
          {
            headline: 'Delete Video',
            content: 'Are you sure you want to delete this video?',
          },
          async (did_confirm) => {
            if (did_confirm) {
              EverTrue.track.set('signal_video', { type: 'signal_video_delete' });
              await deleteVideo(videoId, client_id, client_secret);
              setSelectedVideos([]);
              fetchData();
            }
          }
        );
      } catch (err) {
        console.error('Error deleting video:', err);
      }
    },
    [clientId, fetchData]
  );

  // const handleBulkDelete = useCallback(async () => {
  //   try {
  //     const { client_id, client_secret } = clientId.data;
  //     setLoading(true);
  //     await bulkDelete({ personalVideoIds: selectedVideos, selectAll: false }, client_id, client_secret);
  //     fetchInitialData();
  //     setSelectedVideos([]);
  //     setLoading(false);
  //   } catch (err) {
  //     console.error("Error deleting videos:", err);
  //   }
  // }, [clientId, fetchData, selectedVideos]);

  const renderResults = () => {
    if (count === 0) {
      return (
        <>
          <EmptyStateMessage icon="logo" iconKit="tv" text="No Signal Videos Found" />
          {loading && <Loading />}
        </>
      );
    }

    return (
      <>
        {/* <SelectionHeader
          isAllSelected={isAllSelected}
          selectedCount={selectedVideos.length}
          onSelectAll={handleSelectAll}
          onBulkDelete={handleBulkDelete}
        /> */}
        <VideoList
          listings={listings}
          libraryVideos={libraryVideos}
          selectedVideos={selectedVideos}
          onToggleSelection={toggleVideoSelection}
          onDeleteVideo={deleteVideoEntry}
        />
        {loading && <Loading />}
        <div ref={observerTarget} style={{ height: '1px' }}></div>
      </>
    );
  };

  return (
    <>
      <ModalHeaderWithActions
        closeModal={() => handleCloseThankviewModal(stopAndResetRecorder)}
        title="Video Library"
        onSubmit={() => {
          handleChangeModalView('recorder');
          updateState({ videoUrl: '' });
        }}
        submitLabel={
          <>
            <Icon icon="tv-video" iconKit="tv" className="tv-btn-icon" />
            New Video
          </>
        }
        closeLabel="Cancel"
      />
      <div id="video-library-parent-container" className="video-library-parent-container">
        <ControlTools
          searchText={searchText}
          setSearchText={setSearchText}
          count={count}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          isAscOrder={isAscOrder}
          setIsAscOrder={setIsAscOrder}
          onDateRangeChange={handleDateRangeChange}
          totalCount={totalCount}
        />
        <div className="tv-library-container">{renderResults()}</div>
      </div>
    </>
  );
}

export default React.memo(ThankviewLibrary);
