import Api from 'entities/helpers/api';
import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const createDraft = async (payload) => {
  try {
    const res = await Api.OUTREACH.DRAFTS.post({
      data: JSON.stringify(payload),
    });
    EverTrue.Alert.success(`Draft created successfully.`);
    return res;
  } catch (e) {
    ErrorLogger.error('Error while creating email draft', { extra: { e, page_name: 'Outreach Email Drafts' } });
  }
};

export const updateDraft = async (payload, draftId) => {

  try {
    const res = await Api.OUTREACH.DRAFT.put({
      urlArgs: { id: draftId },
      data: JSON.stringify(payload),
    });
    EverTrue.Alert.success(`Draft updated successfully.`);
    return res;
  } catch (e) {
    ErrorLogger.error('Error while updating email draft', { extra: { e, page_name: 'Outreach Email Drafts' } });
  }
};

export const getAllDraftsByUser = async () => {
  try {
    const res = await Api.OUTREACH.DRAFTS.get();
    return res;
  } catch (e) {
    ErrorLogger.error('Error while getting email drafts', { extra: { e, page_name: 'Outreach Email Drafts' } });
  }
};

export const bulkDeleteDrafts = async (selectedDraftIds) => {
  try {
    await Api.OUTREACH.BULK_DELETE_DRAFTS.delete({
      data: JSON.stringify(selectedDraftIds),
    });
    return EverTrue.Alert.success(`Drafts deleted successfully.`);
  } catch (e) {
    ErrorLogger.error('Error while deleting email drafts', { extra: { error: e, page_name: 'Bulk Delete Drafts' } });
  }
};

export const deleteDraftById = async (draftId) => {
  try {
    await Api.OUTREACH.DRAFT.delete({
      urlArgs: { id: draftId },
    });
  } catch (e) {
    ErrorLogger.error('Error while deleting email draft', { extra: { error: e, page_name: 'Delete Draft' } });
  }
};
