import _ from "underscore";
import EverTrue from "app";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import ActionMenuItem from "components/controls/action-menu-item";
import { Modal2, ModalConfirm } from "@evertrue/et-components";
import ExportSource from "apps/export/sources/export-source";
import ContactsResolver from "apps/contact/components/contacts/contacts-resolver";
import { SOLICITORS_BY_NAME_QUERY } from "apps/volunteers/vms-queries";
import { useActionsMenu2ModalContext } from "components/controls/action-menu2";

const propTypes = {
  tab: PropTypes.string,
  openCreatePoolModal: PropTypes.func.isRequired,
};

const PoolsActions = (props) => {
  const handleExport = () => {
    EverTrue.track.set("manual_export_action", { type: "volunteer_usage" });
    Api.EXPORTS.EXPORT_TYPE.post({
      urlArgs: {
        type: "solicitor_summary",
      },
      data: _.jsonStringify({ name: "volunteer_summary" }),
    }).then(() => {
      ExportSource.start();
    });
  };
  const { setModal } = useActionsMenu2ModalContext();

  const close = () => setModal(null);

  return (
    <>
      <div className="my-trips-actions">
        <ActionMenuItem icon="add" title="Create New Pool" onClick={props.openCreatePoolModal}>
          Create New Pool
        </ActionMenuItem>
        {props.tab === "manage" && (
          <ContactsResolver
            params={{
              limit: 1,
              offset: 0,
            }}
            query={SOLICITORS_BY_NAME_QUERY(undefined, undefined, "POOL")}
            render={({ total = "" }) => (
              <ActionMenuItem
                icon="cloud-download"
                title={`Export ${total} Volunteers`}
                onClick={() =>
                  setModal(
                    <Modal2 isOpen={true} onClose={close}>
                      <ModalConfirm
                        header="Start Volunteer Export"
                        saveLabel="Export"
                        onSubmit={() => {
                          handleExport();
                          close();
                        }}
                        onCancel={close}
                      >
                        <div className="export-confirm">
                          <div className="text-block">{`You are about to export ${total} volunteer records.`}</div>
                        </div>
                      </ModalConfirm>
                    </Modal2>
                  )
                }
              >{`Export ${total} Volunteers`}</ActionMenuItem>
            )}
          />
        )}
      </div>
    </>
  );
};

PoolsActions.propTypes = propTypes;

export default PoolsActions;
