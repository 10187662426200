import { PropTypes } from 'base/react-libs';
import { useIdentity } from 'base/identity-resolver';
import { Icon, FlexTable } from '@evertrue/et-components';
import { completeContactJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import { useState, useEffect, useCallback } from 'react';
import {
  fetchPaginatedActiveContactJourneys,
  ACTIVE_CONSTITUENTS_PAGE_LIMIT,
} from 'apps/journeys/utils/api-requests/journey-details';
import JourneysTabNav from 'apps/journeys/components/index/journeys-table-filters/journeys-tab-nav';
import JourneyActiveSearchBar from 'apps/journeys/utils/journey-active-search-bar';
import renderColumns from './active-constituent-columns';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';

const TABS = [
  { id: 1, label: 'Your Constituents' },
  { id: 2, label: 'All Constituents' },
];

const ActiveConstituentTable = ({ journey = {} }) => {
  const { org = {}, user = {} } = useIdentity();
  const { navigateFrom, setNavigateFrom } = useGlobalJourneyProvider();
  const { openContactSummary = () => {} } = useJourneyDetailProvider();

  const [page, setPage] = useState(1);
  const [searchParam, setSearchParam] = useState('');
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [constituents, setConstituents] = useState({ items: [], count: 0 });
  const [loadingConstituents, setLoadingConstituents] = useState(false);

  // if navigating from a contact, set the search param to the first name
  useEffect(() => {
    if (navigateFrom) {
      let name = navigateFrom?.contact_name;
      if (name && name?.length && typeof name === 'string') {
        setSearchParam(name?.split(' ')[0]);
      }
      setSelectedTab(TABS[1]);
      setNavigateFrom(null);
    }
  }, [navigateFrom, setNavigateFrom]);

  // Fetch Active Constituents
  const fetchConstituents = useCallback(
    async (newPage) => {
      try {
        setLoadingConstituents(true);
        let paginatedContactJourneys;
        if (selectedTab.id === 1) {
          // User-specific tab
          paginatedContactJourneys = await fetchPaginatedActiveContactJourneys(
            newPage,
            journey?.id,
            searchParam,
            user?.id
          );
        } else {
          // All users tab
          paginatedContactJourneys = await fetchPaginatedActiveContactJourneys(newPage, journey?.id, searchParam);
        }
        setPage(newPage);
        setConstituents(paginatedContactJourneys);
      } finally {
        setLoadingConstituents(false);
      }
    },
    [journey?.id, searchParam, user?.id, selectedTab]
  );

  // This will fetch the constituents on initial load and when the search param, tab, journey, or user changes
  useEffect(() => {
    fetchConstituents(1);
  }, [fetchConstituents]);

  const emptyStateMessage = () => {
    if (searchParam && searchParam.length) {
      return selectedTab.id === 1
        ? 'You do not have any Constituents who meet that search criteria.'
        : 'There are no Constituents who meet that search criteria.';
    } else {
      return selectedTab.id === 1
        ? `You do not have any Constituents in the ${journey.name} Cadence.`
        : `There are no Constituents in the ${journey.name} Cadence.`;
    }
  };

  const handleCompletionClick = async (contactJourney = {}, oid) => {
    try {
      const { contact_id, user_id } = contactJourney;
      const { id } = journey;
      const payload = {
        contact_id,
        user_id,
        oid,
        old_journey: {
          id,
          reason: 'COMPLETED',
        },
      };
      // Call the API to complete the contact journey
      await completeContactJourney(payload);
      // Update the state to remove the contact from the list
      removeConstituentFromJourney(contact_id);
    } catch (e) {
      console.error('Error completing contact journey', e);
    }
  };

  const removeConstituentFromJourney = (contactId) => {
    // Update the state to remove the contact from the list
    const copy = { ...constituents };
    if (Array.isArray(copy?.items)) {
      copy.items = copy?.items?.filter((item) => item.contact_id !== contactId) || [];
    }
    if (typeof copy?.count === 'number') copy.count -= 1;
    setConstituents(copy);
  };

  return (
    <>
      <JourneysTabNav tabs={TABS} selectedTab={selectedTab} onSelect={(tab) => setSelectedTab(tab)} />
      <JourneyActiveSearchBar searchParam={searchParam} setSearchParam={setSearchParam} />
      <div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
        <Icon icon="group" size={2} />
        {`${constituents?.count} Active Constituents`}
      </div>
      <FlexTable
        caption="contactJourneys"
        columns={renderColumns(
          org,
          user,
          journey,
          openContactSummary,
          handleCompletionClick,
          removeConstituentFromJourney
        )}
        loading={loadingConstituents}
        data={constituents?.items || []}
        emptyStateIcon="group"
        emptyStateMessageText={emptyStateMessage()}
        page={page}
        handlePageChange={(newPage) => fetchConstituents(newPage)}
        limit={ACTIVE_CONSTITUENTS_PAGE_LIMIT}
        total={constituents.count}
      />
    </>
  );
};

ActiveConstituentTable.propTypes = {
  journey: PropTypes.object.isRequired,
};
export default ActiveConstituentTable;
