import { Icon, Button } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";
import SummaryRow from "../summary-row";

const ContactSummaryGiftHistory = ({ contact }) => {
  const { giving, properties, id } = contact;

  return (
    <>
      <SummaryRow title="Gift History" />
      {!giving || !giving.last_gift_amount || !giving.last_gift_label || giving.last_gift_amount.value === 0 ? (
        <div className="padded-sixteen text-placeholder fs-exclude-data">There are no gifts recorded.</div>
      ) : (
        <>
          <div className="padded-sixteen contact-summary-interaction" style={{ alignItems: "center" }}>
            <div className="contact-summary-interaction--image">
              <div className="interaction-icon">
                <Icon icon="dollar" />
              </div>
            </div>
            <div className="contact-summary-interaction--content">
              <div>
                <div className="contact-summary-interaction--text fs-exclude-data">
                  {properties.name_first && properties.name_first.value}{" "}
                  {properties.name_last && properties.name_last.value} gave{" "}
                  <span style={{ color: "#007C9E" }}>${giving.last_gift_amount.value}</span> to{" "}
                  <span style={{ fontWeight: 500 }}>{giving.last_gift_label.value}</span>.{" "}
                  <span style={{ color: "#737373" }}>(Gift)</span>
                </div>
              </div>
              <div className="fs-exclude-data">
                {giving.last_gift_date.value} <br></br>
              </div>
            </div>
          </div>
          <div className="padded-sixteen">
            <Button type="simple" href={"contact/" + id + "/giving"}>
              View All
            </Button>
          </div>
        </>
      )}
    </>
  );
};

ContactSummaryGiftHistory.propTypes = {
  contact: PropTypes.object,
};

export default ContactSummaryGiftHistory;
