import { useState, useRef, useEffect } from 'react';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import { Button, Icon } from '@evertrue/et-components';
import { RecorderProvider } from './hooks/recorder-context';
import UploadVideo from './upload-video';
import SignalVideoController from './controllers/signal-video-controller';
import { ThankviewSettingsProvider } from './hooks/thankview-settings-context';
import useVideoProcessing from './hooks/use-video-processing';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';
import { SignalVideoModalProvider } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const DropdownMenu = ({ isOpen, dropdownRef, menuItems }) => {
  if (!isOpen) return null;

  return (
    <div className="tv-dropdown-content" ref={dropdownRef}>
      {menuItems.map((item) => (
        <a key={item.label} onClick={item.onClick} className="tv-dropdown-item">
          {item.icon && <Icon icon={item.icon} iconKit={item.iconKit} className="tv-dropdown-item-icon" />}
          <span className="tv-dropdown-item-label">{item.label}</span>
        </a>
      ))}
    </div>
  );
};

const ThankviewCTA = () => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!dropdownIsOpen) {
      EverTrue.track.set('signal_video', { type: 'signal_video_open' });
    }
    setDropdownIsOpen(!dropdownIsOpen);
  };
  const uploadVideoRef = useRef(null);

  const { uploadVideo, uploadVideoFromFile, processingVideoState } = useVideoProcessing();
  const { handleOpenThankviewModal, isThankviewModalOpen } = useSignalVideoModalContext();

  useEffect(() => {
    if (dropdownIsOpen && dropdownRef.current) {
      const dropdownHeight = dropdownRef.current.offsetHeight;
      dropdownRef.current.style.top = `-${dropdownHeight + 5}px`;
    }
  }, [dropdownIsOpen]);

  const handleUploadClick = async () => {
    try {
      EverTrue.track.set('signal_video', { type: 'signal_video_upload' });
      toggleDropdown();
      uploadVideoRef.current.triggerFileInput();
    } catch (error) {
      EverTrue.Alert.error('Error uploading video');
    }
  };
  const menuItems = [
    {
      id: 'record',
      label: 'Record New Video',
      onClick: () => {
        toggleDropdown();
        handleOpenThankviewModal('recorder');
        EverTrue.track.set('signal_video', { type: 'signal_video_create' });
      },
      icon: 'tv-video',
      iconKit: 'tv',
    },
    {
      id: 'library',
      label: 'Video Library',
      onClick: () => {
        toggleDropdown();
        handleOpenThankviewModal('library');
        EverTrue.track.set('signal_video', { type: 'signal_video_library' });
      },
      icon: 'video-library',
      iconKit: 'tv',
    },
    {
      id: 'upload-video',
      label: 'Upload Video',
      onClick: handleUploadClick,
      icon: 'tv-upload',
      iconKit: 'tv',
    },
  ];
  const handleFileSelect = (file) => {
    handleOpenThankviewModal('recorder');
  };

  return (
    <>
      <div className="tv-video-upload-button">
        <Button className="et--button-secondary tv-cta-button--margin" onClick={toggleDropdown}>
          <Icon icon="tv-video" iconKit="tv" />
        </Button>
        <DropdownMenu isOpen={dropdownIsOpen} dropdownRef={dropdownRef} menuItems={menuItems} />
        <RecorderProvider>
          <ThankviewSettingsProvider>
            <UploadVideo
              ref={uploadVideoRef}
              onFileSelect={handleFileSelect}
              uploadVideoFromFile={uploadVideoFromFile}
            />
            {isThankviewModalOpen && (
              <SignalVideoController uploadVideo={uploadVideo} processingVideoState={processingVideoState} />
            )}
          </ThankviewSettingsProvider>
        </RecorderProvider>
      </div>
    </>
  );
};

DropdownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dropdownRef: PropTypes.object,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.string,
      iconKit: PropTypes.oneOf(['et', 'tv', 'gt']),
    })
  ).isRequired,
};

const WrappedThankviewCTA = () => (
  <SignalVideoModalProvider>
    <ThankviewCTA />
  </SignalVideoModalProvider>
);

export default WrappedThankviewCTA;
