module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createFactory} = require("base/new-utils")
   numeral = require("numeral")
   classNames = require("classnames")
   {div, span, strong} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   InteractionSearchStore = require("apps/interactions/stores/interaction-search-store")
   InteractionSearchSource = require("apps/interactions/sources/interaction-search-source")
   InteractionFeedController = require("apps/interactions/controllers/interaction-feed-controller")
   InteractionPagination = createFactory (require("apps/interactions/components/interaction-pagination").default)
   InteractionUtils = require("apps/interactions/interaction-utils")
   SortDropdown = require("components/controls/sort-dropdown")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   FilterStore = require("apps/filters/stores/filter-store")
   UrlSource = require("apps/layout/sources/url-source")
   StringSearchDisplay = require("components/formatting/string-search-display")

   scrollTimeout = null

   createComponent "LegacyInteractionSearchController",
      propTypes:
         style: ReactLibs.PropTypes.object

      registerStores: ->
         @on InteractionSearchStore, ->
            interactions: InteractionSearchStore.getInteractions()
            loading: InteractionSearchStore.getLoading()
            sort: InteractionSearchStore.getSort()
            paginating: InteractionSearchStore.getLoadingPage()
            has_more_to_load: InteractionSearchStore.hasMoreToLoad()

         @on FilterStore, ->
            active_filters: FilterStore.getActiveFilters()
            active_content: FilterStore.getActiveFilterByKey("interaction_keyword")
            active_global: FilterStore.getActiveFilterByKey("global_search")
            extra_active_filters: _.filter FilterStore.getActiveFilterKeys(), (key) ->
               !(key?.match?("interaction") || key == "global_search")

      componentDidMount: ->
         # Stores scroll position in URL for better handlilng back button behavior

         @setState {scrollpos: EverTrue.UrlManager.get("scrollpos")}

         unless @state.loading
            InteractionSearchSource.query()

      componentDidUpdate: (prevProps, prevState) ->
         if @state.interactions?.total != prevState.interactions?.total
            CoreSearchSource.setResultCount("interactions", @state.interactions.total)
            EverTrue.track.setReferrer("interaction_results")

         if !@state.loading && (prevState.loading != @state.loading)
            if @state.scrollpos && !@preventScroll
               @scrollToInteraction(@state.scrollpos)

         # when we navigate to the interaction page with filter params in the url
         # sometimes the filters doen't all load right away, so if that happens
         # make sure to rerun the query once all the filters have loaded
         if _.deepArrayCompare(prevState.active_filters, @state.active_filters)
            InteractionSearchSource.query()

      componentWillUnmount: ->
         UrlSource.clear("scrollpos")

      scrollToInteraction: (scrollpos) ->
         @preventScroll = true
         $(@refs.feed_scroll).animate {scrollTop: scrollpos}, =>
            @preventScroll = false
            @setState {scrollpos: undefined} if @depracated_is_mounted

      handleSort: (prop, reverse) ->
         InteractionSearchSource.sort(prop, reverse)

      handlePage: ->
         InteractionSearchSource.paginate()

      handleScroll: (e) ->
         unless @preventScroll
            clearTimeout(scrollTimeout)
            scrollTimeout = setTimeout((=> UrlSource.change({"scrollpos": $(@refs.feed_scroll).scrollTop()})), 100)

      getHighlights: ->
         _.compact _.flatten [
            @state.active_content?.value?.split(" ")
            @state.active_global?.value?.split(" ")
         ]

      render: ->
         highlights = @getHighlights()

         div className: "interaction-search-controller fixed-page-wrapper", style: @props.style,
            div className: "interaction-search-controller--toolbar clearfix",
               SortDropdown
                  className: "interaction-search-controller--sort"
                  options: [{label: "Interaction Date", value: "date_occurred"}]
                  sortProp: @state.sort?.prop
                  sortReverse: @state.sort?.reverse
                  onSort: @handleSort

            div
               ref: "feed_scroll"
               className: "interaction-search-controller--feed-wrapper"
               onScroll: @handleScroll

               InteractionFeedController
                  className: classNames("interaction-search-controller--feed", "is-empty": !@state.interactions?.total)
                  highlight: InteractionUtils.getHighlightRegex(highlights)
                  loading: @state.loading
                  interactions: @state.interactions.items
                  group: true

               if @state.has_more_to_load
                  InteractionPagination
                     className: "interaction-search-controller--pagination profile-notes--pagination"
                     loading: @state.paginating
                     offset: @state.interactions?.offset
                     onPage: @handlePage
