import { PropTypes } from 'base/react-libs';
import { Icon, Button } from '@evertrue/et-components';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { getGqlContactName } from 'apps/journeys/utils/helpers/utils';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import { useGlobalDrawerProvider } from '../context/global-drawer-context';

const EmailerDrawerButton = ({ drawerTitle, name, contact, emailAddress }) => {
  const { addDrawer } = useGlobalDrawerProvider();
  const { flagEnabled: hasSignal } = useFeatureFlag('signal');

  const getName = () => {
    if (name) return name;
    return getGqlContactName(contact);
  };

  const getTitle = () => {
    if (drawerTitle) return drawerTitle;
    else return getName() || 'New Message';
  };

  return (
    hasSignal && (
      <Button
        type="secondary"
        onClick={() => {
          trackOutreachAction('open_emailer');
          addDrawer('emailer', {
            contact,
            recipients: (emailAddress && [emailAddress]) || [],
            name: getName(),
            drawerTitle: getTitle(),
          });
        }}
        className="round-button"
      >
        <Icon icon="email" style={{ color: '#007C9E' }} />
      </Button>
    )
  );
};

EmailerDrawerButton.propTypes = {
  drawerTitle: PropTypes.string,
  name: PropTypes.string,
  contact: PropTypes.object,
  emailAddress: PropTypes.string,
};

export default EmailerDrawerButton;
