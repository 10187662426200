import { Avatar, Button } from '@evertrue/et-components';
import EverTrue from 'app';

import PropTypes from 'prop-types';
import { fetchContactInfoById } from 'apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests';
import { useEffect, useState } from 'react';
import Icon from 'components/elements/icon';

const AiTaskConstituent = ({ targetId, isStreaming }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [hasFetchedUserInfo, setHasFetchedUserInfo] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      if (!targetId || isStreaming || hasFetchedUserInfo) return;
      try {
        const userInfo = await fetchContactInfoById(targetId, false);
        if (!userInfo) {
          setUserInfo(null);
          setHasFetchedUserInfo(true);
          return;
        }
        const { contact_attributes: { name_full: fullName } = {}, profile_image, id } = userInfo;

        const profileImage = profile_image ? profile_image.url : ''; //

        setUserInfo({ fullName, profileImage, id });
        setHasFetchedUserInfo(true);
      } catch (error) {
        EverTrue.Alert.error('AI tasks response failed!');
      }
    };
    getUserInfo();
  }, [targetId, isStreaming, hasFetchedUserInfo]);

  if (userInfo && !isStreaming) {
    const { fullName, profileImage, id } = userInfo;
    return (
      <div className="ai-task-details--container">
        <Avatar height={30} width={30} name={fullName} url={profileImage} />
        <Button
          className="journeys-contact-card-name"
          type="simple"
          onClick={() => {
            EverTrue.Navigator(`/contact/${id}`, true);
          }}
        >
          {fullName}
        </Button>
      </div>
    );
  } else if (isStreaming) {
    return <Icon className="action-type-icon" icon="spinner" spin />;
  } else {
    return null;
  }
};

AiTaskConstituent.propTypes = {
  targetId: PropTypes.number,
  isStreaming: PropTypes.bool,
};

export default AiTaskConstituent;
