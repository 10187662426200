/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useDataStream from "entities/helpers/use-data-stream";
import EverTrue from "app";

const useAIEmailTemplate = ({ promptKey, contactId, tone }) => {
  const { openDataStream, response, isStreaming } = useDataStream();
  const [emailText, setEmailText] = useState("");

  const fetchEmailTemplate = async (tone) => {
    try {
      setEmailText("");
      const options = {
        method: "POST",
        endpoint: `ai/v2/completions`,
        params: { append_request_id: false },
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        data: JSON.stringify({
          prompt: {
            key: promptKey,
            args: {
              id: contactId,
              tone: tone,
            },
          },
        }),
      };
      await openDataStream(options);
    } catch (err) {
      EverTrue.Alert.error("AI email generation failed.");
    }
  };

  useEffect(() => {
    fetchEmailTemplate(tone);
  }, [tone]);

  useEffect(() => {
    if (response) {
      setEmailText((prevEmailText) => prevEmailText + response);
    }
  }, [response]);

  return {
    emailText,
    isStreaming,
    fetchEmailTemplate,
  };
};

export default useAIEmailTemplate;
