module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, img, button} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ShowHideAnimate = createFactory(require("components/show-hide-animate").default)
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "Slideshow",
      propTypes:
         title: ReactLibs.PropTypes.string
         slides: ReactLibs.PropTypes.array
         doneButtonText: ReactLibs.PropTypes.string
         cancelButtonText: ReactLibs.PropTypes.string
         onDone: ReactLibs.PropTypes.func
         onCancel: ReactLibs.PropTypes.func

      getInitialState: ->
         active_slide: 0

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if _.deepArrayCompare(@props.slides, newProps.slides)
            @setState {active_slide: 0}

      handleSlide: (index) ->
         direction = if index > @state.active_slide then "left" else "right"
         @setState {active_slide: index, direction: direction}

      handleCancel: (e) ->
         e.preventDefault()
         @props.onCancel?(@state.active_slide + 1)

      handleDone: ->
         @props.onDone?(@state.active_slide + 1)

      render: ->
         slide = @props.slides[@state.active_slide]

         div className: classNames("slideshow", @props.className),

            if @props.title
               div className: "slideshow--header",
                  @props.title

            div className: "slideshow--content",
               if @state.active_slide > 0
                  div className: "slideshow--left", onClick: (=> @handleSlide(@state.active_slide - 1)),
                     Icon icon: "chevron-left"

               if @state.active_slide < (@props.slides.length - 1)
                  div className: "slideshow--right", onClick: (=> @handleSlide(@state.active_slide + 1)),
                     Icon icon: "chevron-right"

               _.map @props.slides, (slide, index) =>
                  ShowHideAnimate
                     key: index
                     className: classNames("slideshow--slide", "is-active": index == @state.active_slide)
                     show: index == @state.active_slide
                     animateInClass: if @state.direction == "right" then "slideInLeft" else "slideInRight"
                     animateOutClass: if @state.direction == "right" then "slideOutRight" else "slideOutLeft"

                     if slide.text
                        div className: "slideshow--body-text", slide.text

                     if slide.image then img src: "/images/#{slide.image}", width: "100%"
                     else slide.content()

            div className: "slideshow--footer",
               if @props.onCancel
                  LinkButton title: "Cancel", className: "slideshow--ignore", onClick: @handleCancel,
                     @props.cancelButtonText

               if @props.slides.length > 1
                  div className: "slideshow--paging",
                     _.map @props.slides, (slide, index) =>
                        div
                           key: "dot_#{index}"
                           className: classNames("slideshow--dot", {"is-active": @state.active_slide == index})
                           onClick: => @handleSlide(index)

               if @props.onDone
                  button className: "slideshow--try btn-action", onClick: @handleDone,
                     @props.doneButtonText
