import Api from 'entities/helpers/api';
import colors from 'base/colors';
import { getDateInTimezone } from 'base/new-utils';
import { useState, useEffect } from 'react';
import { ButtonTooltip, Icon } from '@evertrue/et-components';

/**
 * Converts an object's keys to title case
 * @param {*} obj -- object to convert
 * @returns mapped object with Title Case Keys
 */
const convertObjectKeysToTitleCase = (obj) => {
  const result = {};

  // Iterate over each key-value pair in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Convert key to title case and assign its value to the result object
      let formattedKey = key
        .split('_') // Split the key by underscores
        .map((word) => {
          // Special case for holidays ending in 's' (e.g. Veteran's Day)
          if (word.toLowerCase().endsWith('s') && !['christmas', 'columbus', `veterans`].includes(word.toLowerCase())) {
            word = word.slice(0, -1) + "'s";
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }) // Capitalize first letter of each word
        .join(' '); // Join words with a space

      result[formattedKey] = obj[key]; // Assign the original value to the formatted key
    }
  }

  return result;
};

/**
 * Fetches federal holidays from the Journey Timeouts API and displays them in a tooltip
 * @returns {JSX.Element} a tooltip with information about recognized federal holidays
 */
const HolidayTooltip = () => {
  const [holidays, setHolidays] = useState({});

  useEffect(() => {
    const fetchHolidays = async () => {
      let holidaysFromApi = {};
      try {
        holidaysFromApi = await Api.JOURNEYS.JOURNEY_TIMEOUT_HOLIDAYS.get();
        setHolidays(convertObjectKeysToTitleCase(holidaysFromApi));
      } catch (e) {
        // don't throw an error, just log it
        console.error('Error fetching holidays', e);
      }
    };

    fetchHolidays();
  }, []);

  return Object.keys(holidays).length ? (
    <ButtonTooltip trigger={<Icon icon="info" style={{ color: colors.gray500 }} />}>
      <>
        <strong style={{ padding: '4px' }}>EverTrue recognizes the following federal holidays: </strong>
        <div>
          {Object.entries(holidays)
            .sort((a, b) => {
              const dateA = new Date(a[1]);
              const dateB = new Date(b[1]);
              return dateA - dateB; // Sort in ascending order (earliest date first)
            })
            .map(([key, value]) => (
              <div key={key} style={{ padding: '4px' }}>
                <span>{key}</span>: {getDateInTimezone(value).toLocaleDateString()}
              </div>
            ))}
        </div>
      </>
    </ButtonTooltip>
  ) : null;
};

export default HolidayTooltip;
