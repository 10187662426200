function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const mapSnakeToCamel = (object, mappings) => {
  const newObj = {};
  Object.entries(object).forEach(([key, val]) => {
    const camelKey = mappings[key];
    if (camelKey) newObj[camelKey] = val;
  });

  return newObj;
};

export const mapCamelToSnake = (object, mappings) => {
  const newObj = {};
  Object.entries(object).forEach(([key, val]) => {
    const snakeKey = getKeyByValue(mappings, key);
    if (snakeKey) newObj[snakeKey] = val;
  });

  return newObj;
};

export const getPromotionDetails = (enrichment) => {
  const { employment_events = [] } = enrichment || {};
  let mostRecentEvent = null;
  employment_events.forEach((event) => {
    const eventType = event.type.toLowerCase();
    if (
      ['promotion', 'new job', 'retired'].includes(eventType) &&
      (!mostRecentEvent || event.recorded_at > mostRecentEvent.recorded_at)
    ) {
      mostRecentEvent = event;
    }
  });

  return mostRecentEvent;
};

export const titleCaseString = (string = '') => {
  if (!string.length) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getEmptySectionName = (subtitle) => {
  switch (subtitle) {
    case 'Recent Gift':
      return 'recent gift';
    case 'Promotion':
      return 'promotion';
    case 'Last Gift':
      return 'gift anniversary';
    case 'Contact Reports':
      return 'contact reports';
    default:
      return '';
  }
};

// leverage api call for task action types to get updated labels
export const consolidateOtherTaskCounts = (otherTaskCounts) => {
  const {
    CALL = 0,
    EMAIL = 0,
    LINKEDIN = 0,
    RESEARCH = 0,
    PRINT_MAIL = 0,
    TEXT = 0,
    THANK_VIEW = 0,
    OTHER = 0,
    TOTAL = 0,
  } = otherTaskCounts;
  const consolidatedCounts = {
    Total: TOTAL,
    Emails: EMAIL,
    Calls: CALL,
    Text: TEXT,
    ThankView: THANK_VIEW,
    Other: OTHER + LINKEDIN + RESEARCH + PRINT_MAIL,
  };
  return consolidatedCounts;
};

export const consolidateCadenceStepTaskCounts = (cadenceStepTaskCounts) => {
  const {
    CALL = 0,
    EMAIL = 0,
    LINKEDIN = 0,
    RESEARCH = 0,
    PRINT_MAIL = 0,
    TEXT = 0,
    THANK_VIEW = 0,
    OTHER = 0,
    TOTAL = 0,
  } = cadenceStepTaskCounts;
  const consolidatedCounts = {
    Total: TOTAL,
    Emails: EMAIL,
    Calls: CALL,
    Other: OTHER + LINKEDIN + RESEARCH + PRINT_MAIL + TEXT + THANK_VIEW,
  };
  return consolidatedCounts;
};
