import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

export default createSource('CustomFieldsSource', {
  actions: {
    fetchedFields(resp) {
      this.require(typeof resp === 'object' && resp !== null, 'response should be an object');
    },
    fetchIfNotCached: true,
  },

  api: {
    fetch() {
      return Api.UGC.CUSTOM_FIELDS.get({
        success: (resp) => {
          this.actions.fetchedFields(resp);
        },
      });
    },

    fetchIfNotCached() {
      this.actions.fetchIfNotCached();
    },
  },
});
