import PropTypes from 'prop-types';
import EverTrue from 'app';
import ContactFormatMixin from 'mixins/contact-format-mixin';
import EllipsisOverflow from 'components/formatting/ellipsis-overflow';
import { Link } from '@evertrue/et-components';

const ContactBusiness = ({ contact, referrer }) => {
  const company = ContactFormatMixin.company(contact);
  const industry = ContactFormatMixin.industry(contact);
  return (
    <EllipsisOverflow className="contact-card--business">
      <span>
        {company && (
          <Link
            className="fs-exclude-data"
            href={`company${ContactFormatMixin.company_link(contact)}`}
            title={company}
            data-refer={referrer}
          >
            {company}
          </Link>
        )}

        <span>{company && industry ? ' | ' : ' '}</span>

        {industry && (
          <Link
            className="fs-exclude-data"
            href={`industry${ContactFormatMixin.industry_link(industry)}`}
            title={industry}
            data-refer={referrer}
          >
            {industry}
          </Link>
        )}
      </span>
    </EllipsisOverflow>
  );
};

ContactBusiness.propTypes = {
  contact: EverTrue.PropTypes.contactObject,
  referrer: PropTypes.string,
};

export default ContactBusiness;
