import { PropTypes } from 'base/react-libs';
import { Button } from '@evertrue/et-components';

const CompleteTaskToggle = ({ size = 'big', task = {}, onClick = () => {}, isClickable = true }) => {
  return (
    <Button
      type="simple"
      data-testid="task-completion-toggle"
      onClick={() => {
        onClick(task);
      }}
    >
      <div
        aria-label="task-completion-toggle"
        className={
          task.completed_at
            ? `${isClickable ? 'checked-circle--clickable' : 'checked-circle'} checked-circle--${size}`
            : task.status === 'PAUSED'
              ? `${isClickable ? 'paused-icon--clickable' : 'paused-icon'} paused-icon--${size}`
              : `${isClickable ? 'check-circle--clickable' : 'check-circle'} check-circle--${size}`
        }
      />
    </Button>
  );
};

CompleteTaskToggle.propTypes = {
  size: PropTypes.string,
  task: PropTypes.object,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
};

export default CompleteTaskToggle;
