import PropTypes from 'prop-types';

const ScheduleLabel = ({ text, dateTime }) => (
  <div>
    {text} <span className="date-time">{dateTime}</span>
  </div>
);

ScheduleLabel.propTypes = {
  text: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
};

export default ScheduleLabel;
