import { PropTypes } from 'base/react-libs';
import { useOpenClose, Modal, ModalHeader, ModalBody, Button } from '@evertrue/et-components';

const CancelPauseModalButton = ({ handleCancel = () => {} }) => {
  const [isOpen, openModal, closeModal] = useOpenClose();

  const cancelAction = async () => {
    await handleCancel();
    closeModal();
  };

  return (
    <>
      <Button type="secondary" onClick={openModal}>
        Cancel Upcoming Pause
      </Button>
      <Modal isOpen={isOpen} closeModal={closeModal} size="small">
        <ModalHeader title="Cancel Pause" />
        <ModalBody>Are you sure you want to cancel this pause?</ModalBody>
        <div className="confirm-modal-actions">
          <Button type="simple" onClick={closeModal}>
            Cancel
          </Button>
          <div style={{ margin: '0 20px' }}>
            <Button onClick={() => cancelAction()}>Cancel Pause</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CancelPauseModalButton.propTypes = {
  handleCancel: PropTypes.func,
};

export default CancelPauseModalButton;
