import { isEmpty } from 'underscore';
import { useState, useEffect } from 'react';
import { mapCamelToSnake, mapSnakeToCamel } from 'apps/home/utils/helpers';
import {
  getUserSettings,
  createUserSettings,
  updateUserSettings,
} from 'apps/home/utils/api-requests/recommended-actions/use-recommended-actions-user-settings-requests';

const DEFAULT_SETTINGS = {
  giftAmount: 500,
  daysSinceLastGift: 15,
  enableGiftSection: true,
  enableCareerMovesSection: true,
  enableBirthdaySection: true,
  enableAnniversarySection: true,
  enableTripMeeting: true,
  enableDPS: true,
  monthsSinceLastInteraction: 0,
};

const MAPPINGS = {
  min_gift_amount: 'giftAmount',
  enable_birthday: 'enableBirthdaySection',
  enable_gift_anniversary: 'enableAnniversarySection',
  enable_recent_gift: 'enableGiftSection',
  enable_career_moves: 'enableCareerMovesSection',
  days_since_recent_gift: 'daysSinceLastGift',
  months_since_interaction: 'monthsSinceLastInteraction',
  enable_trip_meeting: 'enableTripMeeting',
  enable_dps: 'enableDPS',
  owner_user_id: 'userId',
};

const useRecommendedActionsUserSettings = () => {
  const [updateId, setUpdateId] = useState();
  const [userSettings, setUserSettings] = useState({});

  const submitUserSettings = async (fields) => {
    const payload = mapCamelToSnake(fields, MAPPINGS);
    const submitFunc = updateId ? updateUserSettings : createUserSettings;
    const newSettings = await submitFunc(payload, updateId);
    setUserSettings(mapSnakeToCamel(newSettings, MAPPINGS));
    if (!updateId) setUpdateId(newSettings.id);
    return newSettings;
  };

  // On Initial Mount, Check if user has settings saved.
  // If they do, use them. else, DEFAULT_SETTINGS will be used.
  useEffect(() => {
    const getAndSetUserSettings = async () => {
      const existingUserSettings = await getUserSettings();

      if (existingUserSettings && !isEmpty(existingUserSettings)) {
        const { id } = existingUserSettings;

        const mappedUserSettings = mapSnakeToCamel(existingUserSettings, MAPPINGS);

        setUserSettings(mappedUserSettings);
        setUpdateId(id);
      } else {
        setUserSettings(DEFAULT_SETTINGS);
      }
    };

    getAndSetUserSettings();
  }, []);

  return {
    userSettings,
    submitUserSettings,
  };
};

export default useRecommendedActionsUserSettings;
