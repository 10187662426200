import AddToModal from 'apps/search/components/add-to-modal';
import PropTypes from 'prop-types';
import { connect } from '@evertrue/et-flux';
import FeatureStore from 'apps/layout/stores/feature-store';
import ListStore from 'apps/lists/stores/list-store';
import ProfileStore from 'apps/profile/stores/profile-store';
import MembershipStore from 'apps/profile/stores/membership-store';
import { ActionsMenu, Modal, Icon } from '@evertrue/et-components';
import InteractionForm from 'apps/interactions/components/create/interaction-form';
import ProposalFormLegacyModalWrapper from 'apps/profile/components/proposal-form-legacy-modal-wrapper';
import CDCModalController from 'apps/profile/components/cdc/cdc-modal-controller';
import FacebookMatchManage from 'apps/facebook/components/facebook-match-manage';
import colors from 'base/colors';
import AddToJourneyModal from 'apps/journeys/components/modals/add-to-journey-modal';
import RemoveFromJourneyModal from 'apps/journeys/components/modals/remove-from-journey-modal';
import { useIdentity } from 'base/identity-resolver';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { mapContactObjectToGraphqlShape } from 'apps/journeys/utils/helpers/utils';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { useState } from 'react';
import ProfileActionsRemoveFromCadenceModal from 'apps/journeys/components/modals/profile-actions-remove-from-cadence-modal';
import ProfileActionsMoveToAnotherCadenceModal from 'apps/journeys/components/modals/profile-actions-move-to-another-cadence-modal';

const mapStateToProps = ({ contact = {} }) => ({
  hasTrips: FeatureStore.hasFeature('my_trips'),
  hasInteractionWrites: FeatureStore.hasInteractionWrites(),
  hasCdcProfile: FeatureStore.hasFeature('cdc_profile'),
  hasProposalWrites: FeatureStore.hasFeature('proposal_writes'),
  favorite: ListStore.getFavoriteList(),
  favoriteMembership: !!MembershipStore.getFavoriteMembership(contact.id),
  hasFbMatch: ProfileStore.hasFacebookMatch(contact.id),
});

const ProfileActionsMenu = ({
  contact = {},
  afterProposalSave = () => {},
  hasTrips = false,
  hasInteractionWrites = false,
  hasCdcProfile = false,
  hasProposalWrites = false,
  favorite = {},
  favoriteMembership = false,
  hasFbMatch = false,
  activeJourneys = [],
  refreshProfile = () => {},
}) => {
  const [isMoveToJourneyModalOpen, setMoveToJourneyModalOpen] = useState(false);
  const [selectedCadenceToRemove, setSelectedCadenceToRemove] = useState(null);

  const { flagEnabled: hasCadences } = useFeatureFlag('signal');
  const { flagEnabled: hasAI } = useFeatureFlag('aiGeneral');
  const { flagEnabled: enableInteractionSolicitorType } = useFeatureFlag("enableInteractionSolicitorType");
  const { flagEnabled: interactionDescriptionRichTextEditor } = useFeatureFlag("interactionDescriptionRichTextEditor");
  

  const {
    user: { id: currentUserId },
  } = useIdentity();

  const { addDrawer } = useGlobalDrawerProvider();

  const addToList = {
    id: 5,
    icon: 'list',
    label: 'Add to List, Trip or Pool',
    mountOnClick: ({ is_open, close }) => (
      <AddToModal isOpen={is_open} close={close} selected={[contact.id]} hideCadences={true} />
    ),
  };

  const addInteraction = {
    id: 6,
    icon: 'edit',
    label: hasInteractionWrites ? 'Add Interaction' : 'Add Comment',
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close}>
        <InteractionForm 
          contact={contact} 
          referrer="profile_action_menu" 
          requestHide={close} 
          enableInteractionSolicitorType={enableInteractionSolicitorType}
          interactionDescriptionRichTextEditor={interactionDescriptionRichTextEditor}
        />
      </Modal>
    ),
  };

  const addProposal = {
    id: 7,
    icon: 'proposal',
    label: 'Add Proposal',
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close}>
        <ProposalFormLegacyModalWrapper
          primaryContactId={contact.id}
          referrer={'profile_action_menu'}
          afterCreate={afterProposalSave}
          requestHide={close}
        />
      </Modal>
    ),
  };

  const suggestContactUpdate = {
    id: 8,
    icon: 'upload-contact',
    label: 'Suggest Contact Update',
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close} size="large">
        <CDCModalController contact={contact} onClose={close} referrer="profile_action_menu" />
      </Modal>
    ),
  };

  const manageFbPageMatch = {
    id: 9,
    icon: 'link',
    label: 'Manage Facebook Page Match',
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close}>
        <FacebookMatchManage contact={contact} isFbMatched={hasFbMatch} requestHide={close} />
      </Modal>
    ),
  };

  const aiProfileChat = {
    id: 1,
    icon: 'ai-chat',
    iconKit: 'gt2',
    iconStyle: { color: colors.purple500 },
    label: 'Launch AI Profile Chat',
    onClick: () => {
      addDrawer('ai', {
        contact: contact,
        drawerTitle: (
          <span>
            <Icon icon="ai-chat" iconKit="gt2" style={{ color: colors.purple500, fontSize: '1.25em' }} />
            AI-Profile Chat - {contact && contact.name}
          </span>
        ),
        closeModalProps: {
          modalTitle: 'Close AI Profile Chat?',
          modalBody: (
            <div className="ai-chat-confirmation-body">
              <p>Are you sure you want to close the chat about {contact.name}?</p>
              <p>This cannot be undone, and the chat will not be saved. You can start a new chat anytime.</p>
            </div>
          ),
          confirmButtonLabel: 'End Chat',
          confirmModalWidth: '507px',
        },
      });
    },
  };

  const addToCadence = {
    id: 2,
    icon: 'add-to-cadence',
    iconKit: 'gt2',
    label: 'Add to Cadence',
    mountOnClick: ({ is_open, close }) => (
      <AddToJourneyModal
        contact={contact}
        onClose={close}
        isOpen={is_open}
        activeJourneys={activeJourneys}
        refreshProfile={refreshProfile}
      />
    ),
  };

  const moveToAnotherCadence = {
    id: 3,
    icon: 'swap-horizontal',
    iconKit: 'gt2',
    label: 'Move to Another Cadence',
    mountOnClick: ({ is_open, close }) => (
      <ProfileActionsRemoveFromCadenceModal
        handleRemoveFromCadence={(selectedNewJourney) => {
          close();
          setSelectedCadenceToRemove(selectedNewJourney);
          setMoveToJourneyModalOpen(true);
        }}
        contact={contact}
        onClose={close}
        isOpen={is_open}
        activeJourneys={activeJourneys.filter(({ owner_user_id }) => currentUserId === owner_user_id)}
      />
    ),
  };

  const removeFromCadence = {
    id: 4,
    icon: 'playlist-add',
    label: 'Remove from Cadence',
    mountOnClick: ({ is_open, close }) => (
      <RemoveFromJourneyModal
        onClose={close}
        isOpen={is_open}
        contact={mapContactObjectToGraphqlShape(contact)}
        currentJourneys={activeJourneys.filter(({ owner_user_id }) => currentUserId === owner_user_id)}
        refreshProfile={refreshProfile}
      />
    ),
  };

  const contactOptions = {
    id: 1,
    heading: 'Constituent Management',
    items: [addToList, addInteraction],
  };

  if (hasProposalWrites) contactOptions.items.push(addProposal);
  if (hasCdcProfile) contactOptions.items.push(suggestContactUpdate);
  if (hasFbMatch) contactOptions.items.push(manageFbPageMatch);

  const aiOptions = {
    id: 2,
    items: [],
  };
  if (hasAI) aiOptions.items.push(aiProfileChat);

  const cadenceOptions = {
    id: 3,
    heading: 'Cadences',
    items: [],
  };

  if (hasCadences && activeJourneys?.length < 5) cadenceOptions.items.push(addToCadence);
  if (hasCadences && activeJourneys.some(({ owner_user_id }) => currentUserId === owner_user_id)) {
    cadenceOptions.items.push(removeFromCadence);
    cadenceOptions.items.push(moveToAnotherCadence);
  }

  const getOptions = () => {
    const options = [contactOptions];

    if (hasCadences) {
      options.unshift(cadenceOptions);
    }

    if (hasAI) {
      options.unshift(aiOptions);
    }

    return options;
  };

  return (
    <>
      <ActionsMenu options={getOptions()} />
      <ProfileActionsMoveToAnotherCadenceModal
        isOpen={isMoveToJourneyModalOpen}
        currentJourney={selectedCadenceToRemove}
        onClose={() => setMoveToJourneyModalOpen(false)}
        refreshProfile={refreshProfile}
        contact={contact}
      />
    </>
  );
};

ProfileActionsMenu.propTypes = {
  contact: PropTypes.object,
  afterProposalSave: PropTypes.func,
  hasTrips: PropTypes.bool,
  hasInteractionWrites: PropTypes.bool,
  hasCdcProfile: PropTypes.bool,
  hasProposalWrites: PropTypes.bool,
  favorite: PropTypes.object,
  favoriteMembership: PropTypes.bool,
  hasFbMatch: PropTypes.bool,
  activeJourneys: PropTypes.array,
  refreshProfile: PropTypes.func,
};

export default connect(ProfileActionsMenu, [FeatureStore, ListStore, MembershipStore, ProfileStore], mapStateToProps);