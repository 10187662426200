import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import HomepageController from './controllers/homepage-controller';
import IsFlaggedController from 'components/flags/is-flagged-controller';

class HomepageRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'home(/)': 'homepage',
    };
  }
  homepage() {
    EverTrue.execute('setBrowserTitle', 'Home');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <HomepageController />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

HomepageRouter.initClass();
export default HomepageRouter;
