import { useContext } from 'react';
import classNames from 'classnames';
import ModalTrigger from 'components/modals/modal-trigger';
import { Button, Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import { ActionMenu2ClosePopoverContext } from 'components/controls/action-menu2';

const ActionMenuItem = ({
  children,
  icon,
  iconKit = 'gt',
  title,
  modal,
  href,
  disable,
  className,
  onClick,
  openNewTab,
}) => {
  const actionMenu2ClosePopoverContext = useContext(ActionMenu2ClosePopoverContext);

  const handleKeyPress = (event) => {
    if (event.which === 13 && !disable) {
      onClick && onClick();
      if (actionMenu2ClosePopoverContext) {
        actionMenu2ClosePopoverContext();
      }
    }
  };

  const handleClick = () => {
    onClick && onClick();
    if (actionMenu2ClosePopoverContext) {
      actionMenu2ClosePopoverContext();
    }
  };

  return (
    <div className={classNames('action-menu-item', className, { 'is-disabled': disable })}>
      {modal ? (
        <ModalTrigger
          modal={modal}
          disable={disable}
          title={title}
          onClick={() => {
            if (actionMenu2ClosePopoverContext) {
              actionMenu2ClosePopoverContext();
            }
          }}
        >
          {icon && <Icon icon={icon} iconKit={iconKit} className="action-menu-item--icon" />}
          <span className={classNames('action-menu-item--label', { 'no-icon': !icon })}>{children}</span>
        </ModalTrigger>
      ) : (
        <Button
          type="simple"
          aria-label={title}
          href={href}
          title={title}
          onKeyPress={handleKeyPress}
          onClick={!disable ? handleClick : undefined}
          disabled={disable}
          hrefTarget={openNewTab ? '_blank' : undefined}
        >
          {icon && <Icon icon={icon} iconKit={iconKit} className="action-menu-item--icon" />}
          <span className={classNames('action-menu-item--label', { 'no-icon': !icon })}>{children}</span>
        </Button>
      )}
    </div>
  );
};

ActionMenuItem.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
  iconKit: PropTypes.string,
  title: PropTypes.string.isRequired,
  modal: PropTypes.node,
  href: PropTypes.string,
  disable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
};

export default ActionMenuItem;
