import EverTrue from 'app';

const container = {
  textAlign: 'center',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  fontFamily: "'Lato', sans-serif",
};

const textStyle = {
  fontSize: '16px',
  lineHeight: '1.5',
  color: '#333',
  margin: '20px 0',
  textAlign: 'center',
  width: '100%',
  padding: '10px 20px',
  display: 'inline-block',
};

const linkStyle = {
  color: '#3367F2',
  textDecoration: 'underline',
};

const button = {
  backgroundColor: '#3367F2',
  color: '#fff',
  alignItems: 'center',
  padding: '10px 20px',
  borderRadius: '100px',
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: '400',
  cursor: 'pointer',
};

const imgStyle = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '100%',
};

const PATH = 'https://thankview.com/video/signal/';

const handleCopy = (video, libraryVideo, styles) => {
  EverTrue.track.set('signal_video', { type: 'signal_video_copy' });
  let url = `${PATH}${video.id_string}`;
  const convertStyleObjectToCss = (styleObject) => {
    return Object.entries(styleObject)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}:${value}`)
      .join(';');
  };

  const copyGifThumbnailWithHtml = async () => {
    try {
      let htmlToAppend = `
        <div style="${convertStyleObjectToCss(container)}">
          <a href="${url}" style="text-decoration: none;">
            <img 
              src="${libraryVideo.animated_thumb_path ? libraryVideo.animated_thumb_path : libraryVideo.video_thumb}" 
              alt="Video Thumbnail" 
              style="${convertStyleObjectToCss(imgStyle)}"
            >
          </a>
          </br>
          </br>
          <p style="${convertStyleObjectToCss({ ...textStyle, marginBottom: '10px' })}">
            Click <a href="${url}" style="${convertStyleObjectToCss(linkStyle)}">here</a> to see your Signal Video!
          </p>
      `;
      if (video.button_text && video.show_in_email) {
        const btnHTML = `</br>
          </br>
          <a 
            href="${video.button_url}" 
            target="_blank" 
            rel="noopener noreferrer" 
            style="${convertStyleObjectToCss({ ...button, display: 'inline-block', marginTop: '10px' })}"
          >
            ${video.button_text.trim()}
          </a>
        </div>`;
        htmlToAppend += btnHTML;
      } else htmlToAppend += '</div>';

      if (navigator.clipboard && window.ClipboardItem) {
         
        const clipboardItem = new ClipboardItem({
          'text/html': new Blob([htmlToAppend], { type: 'text/html' }),
        });
        await navigator.clipboard.write([clipboardItem]);
      } else {
        // Fallback for browsers that don't support ClipboardItem
        const tempElem = document.createElement('div');
        tempElem.innerHTML = htmlToAppend;
        document.body.appendChild(tempElem);
        const range = document.createRange();
        range.selectNodeContents(tempElem);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        document.body.removeChild(tempElem);
      }

      EverTrue.Alert.success('Video successfully copied to clipboard');
      return true;
    } catch (err) {
      EverTrue.Alert.error(`Failed to copy video to clipboard ${err}`);
    }
  };

  return copyGifThumbnailWithHtml();
};

export default handleCopy;
