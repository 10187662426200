import { PropTypes } from 'base/react-libs';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { Modal2, ThreeDotActionsMenu } from '@evertrue/et-components';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import { getContactEmailAddress, getGqlContactName } from 'apps/journeys/utils/helpers/utils';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import SkipTaskModal from 'apps/journeys/components/modals/skip-task-modal';
import MoveToJourneyModal from 'apps/journeys/components/modals/move-to-journey-modal';
import EditTaskDueDateModal from 'apps/journeys/components/modals/edit-task-due-date-modal';
import RemoveFromJourneyModal from 'apps/journeys/components/modals/remove-from-journey-modal';
import UpdateStageModal2 from 'apps/assignments/components/update-stage-modal2';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';

const TaskRowActionButton = ({ journey, step, task = {} }) => {
  const { addDrawer } = useGlobalDrawerProvider();
  const { flagEnabled: hasSignal } = useFeatureFlag('signal');
  const { optionalStateManagementFunction = () => {}, steps = [] } = useJourneyDetailProvider();

  const { contact, completed_at } = task;
  const email_address = getContactEmailAddress(contact);

  const phoneAction = {
    id: 1,
    onClick: () => {
      trackOutreachAction('open_dialer');
      addDrawer('dialer', {
        contact,
        phones: contact && contact.phones ? contact.phones : [],
        drawerTitle: getGqlContactName(contact) || 'New Phone Call',
        task,
        journey,
      });
    },
    label: 'Call Constituent',
    icon: 'call',
  };

  const emailAction = {
    id: 2,
    onClick: () => {
      trackOutreachAction('open_emailer');
      addDrawer('emailer', {
        taskId: task.id,
        contact,
        recipients: email_address === '' ? [] : [email_address],
        drawerTitle: getGqlContactName(contact) || 'New Message',
      });
    },
    label: 'Email Constituent',
    icon: 'email',
  };

  const moveToCadenceAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <MoveToJourneyModal
        currentJourney={journey}
        isOpen={is_open}
        onClose={close}
        contact={task.contact}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: 'Move to Another Cadence',
    icon: 'swap-horizontal',
    iconKit: 'gt2',
  };

  const editAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <EditTaskDueDateModal
        isOpen={is_open}
        onClose={close}
        task={task}
        step={step}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: 'Edit Due Date',
    icon: 'event',
  };

  const removeAction = {
    id: 3,
    mountOnClick: ({ is_open, close }) => (
      <RemoveFromJourneyModal
        onClose={close}
        isOpen={is_open}
        contact={task.contact}
        currentJourneys={[{ journey_name: journey.name, journey_id: journey.id }]} //matching active_journeys object
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: 'Remove from Cadence',
    icon: 'cancel-circle',
  };

  const skipAction = {
    id: 4,
    mountOnClick: ({ is_open, close }) => (
      <SkipTaskModal
        task={task}
        step={step}
        steps={steps}
        onClose={close}
        isOpen={is_open}
        currentJourney={journey}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: 'Skip Step',
    icon: 'skip',
    iconKit: 'gt2',
  };

  const updateProspectStageAction = {
    id: 5,
    icon: 'recently-viewed',
    label: 'Update Prospect Stage',

    mountOnClick: ({ close, is_open }) => (
      <Modal2 isOpen={is_open} onClose={close}>
        <UpdateStageModal2 contact={contact} close={close} />
      </Modal2>
    ),
  };

  const contactOptions = {
    id: 1,
    heading: 'Contact',
    items: [],
  };

  if (hasSignal) contactOptions.items.push(phoneAction);
  if (hasSignal) contactOptions.items.push(emailAction);

  const constituentOptions = {
    id: 2,
    heading: 'Manage Constituent',
    items: [moveToCadenceAction, removeAction, updateProspectStageAction],
  };

  const taskOptions = {
    id: 3,
    heading: 'Manage Task',
    items: [editAction, skipAction],
  };

  const getOptions = () => {
    const options = [constituentOptions];

    if (hasSignal) {
      options.unshift(contactOptions);
    }

    if (!completed_at) {
      options.push(taskOptions);
    }

    return options;
  };

  return <ThreeDotActionsMenu options={getOptions()} iconSize={1} />;
};

TaskRowActionButton.propTypes = {
  journey: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

export default TaskRowActionButton;
