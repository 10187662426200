import PropTypes from 'prop-types';
import Icon from 'components/elements/icon';

const PhoneTemplate = ({ data, hideIcon }) => {
  return (
    <div className="phone-template">
      {hideIcon ? <div className="phone-template--no-icon" /> : <Icon icon="call" className="phone-template--icon" />}

      {!data?.phone ? (
        <div className="phone-template--data is-empty">Not provided</div>
      ) : (
        <div className="phone-template--data">
          <div className="phone-template--label">
            {data?.type || 'Other'}
            {data?.primary && ' (Primary)'}
          </div>
          <span className="fs-exclude-data">{data.phone}</span>
        </div>
      )}
    </div>
  );
};

PhoneTemplate.propTypes = {
  data: PropTypes.object, // TODO: Check for expected format
  hideIcon: PropTypes.bool,
};

export default PhoneTemplate;
