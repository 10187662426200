module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   Api = require("entities/helpers/api")
   EverTrue = require("app")
   numeral = require("numeral")
   classNames = require("classnames")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span, a} = ReactLibs.DOMFactories
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   TripContactsStore = require("apps/my-trips/stores/trip-contacts-store")
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   TripLogStore = require("apps/my-trips/stores/trip-log-store")
   TripForm = require("apps/my-trips/components/trip-form")
   LocalMeetingForm = require("apps/my-trips/components/local-meeting-form")
   VirtualMeetingForm = require("apps/my-trips/components/virtual-meeting-form")
   PhoneMeetingForm = require("apps/my-trips/components/phone-meeting-form")
   {createFactory} = require("base/new-utils")
   MeetingDetails = createFactory(require("apps/my-trips/components/meeting-details-modal").default)
   TripDate = createFactory require("apps/my-trips/components/trip-date").default
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   Avatar = require("components/elements/avatar")
   Icon = createFactory (require("components/elements/icon").default)
   Loading = require("components/elements/loading")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   Tooltip = require("components/overlays/tooltip")
   Facepile = require("components/elements/facepile")
   CardAnalytics = require("components/containers/card-analytics")
   Link = createFactory(require("@evertrue/et-components").Link)
   ModalTrigger2 = createFactory(require("@evertrue/et-components").ModalTrigger2)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   IconButton = createFactory(require("@evertrue/et-components").IconButton)
   Card = createFactory(require("@evertrue/et-components").Card)
   ThreeDotActionsMenu = createFactory(require("@evertrue/et-components").ThreeDotActionsMenu)
   LayoutSource = require("apps/layout/sources/layout-source")

   createComponent "TripCard",
      propTypes:
         trip: ReactLibs.PropTypes.object
         current_user_platform_data: ReactLibs.PropTypes.any

      getInitialState: ->
         is_hovering: false
         contacts: {}

      registerStores: ->
         @on TripLogStore, ->
            interaction_count: TripLogStore.getTotalForTrip(@props.tripId) | 0

         @on MyTripsStore, ->
            collaborators: MyTripsStore.getTripCollaborators(@props.tripId)
            trip: MyTripsStore.getTrip(@props.tripId)
      
      componentDidMount: ->
         @fetchContacts()
      
      fetchContacts: ->
         contacts = @state.trip.contact_ids?.slice(0,5)
         Api.CONTACTS.BULK.get
            params: {id: contacts}
            success: (resp) =>
               @setState({contacts: resp.items})
      
      handleGoToProfile: (contact) ->
         EverTrue.Navigator("/contact/#{contact.id}", true)

      renderDeleteModal: ->
         if @state.trip.type != "trip"
            EverTrue.Alert.confirm
                  content: "Are you sure you want to delete your #{@state.trip.name}? You will still have access to your
                  constituent notes, but this action will permanently delete your meeting."
                  headline: "Delete Meeting?"
               , (e) =>
                  if e then MyTripsSource.delete(@state.trip?.trip_id)
         else
            EverTrue.Alert.confirm
               content: "Are you sure you want to delete your #{@state.trip.name} trip? You will still have access to your
               constituent notes, but this action will permanently delete your meetings."
               headline: "Delete Trip?"
            , (e) =>
               if e then MyTripsSource.delete(@state.trip?.id)

      renderAnalyticsItem: (label, value, icon) ->
         div className: classNames("trip-card--analytics-col", "is-empty": !value || value == "$0"),
            div className: "trip-card--analytics-data",
               strong null, value
               if icon then Icon icon: icon
            div null, label

      renderEditForm: ->
         editModal = switch
            when @state.trip.type=="local" then LocalMeetingForm({tripId: @state.trip.id, meeting: @state.trip, selected_contacts: @state.contacts, solicitors: @state.collaborators })
            when @state.trip.type=="virtual" then VirtualMeetingForm({tripId: @state.trip.id, meeting: @state.trip, current_user_platform_data: @props.current_user_platform_data, selected_contacts: @state.contacts, solicitors: @state.collaborators})
            when @state.trip.type=="phone" then PhoneMeetingForm({tripId: @state.trip.id, meeting: @state.trip, selected_contacts: @state.contacts, solicitors: @state.collaborators})
            else TripForm({trip: @state.trip})
         editModal

      renderAnalytics: ->
         lifetime_giving = _.sum @state.contacts, (contact) ->
            contact = Decorator.Contacts.parse(contact)
            contact?.giving?.lifetime_amount?.value
         virtual_meeting_link = if @state.trip.type=="virtual"
            @state.trip.meeting_link


         analytics = switch
            when @state.trip.type=="trip" then CardAnalytics
               className: "trip-card--analytics"
               data: [
                  {label: "Lifetime Gifts", value: span(className: "trip-card--analytics-dollar", numeral(lifetime_giving).format("$0a"))},
                  {label: "Meetings", value: _.size(@state.trip.meeting_ids), icon: "date-range"}
                  {label: "Interactions", value: @state.interaction_count, icon: "trip-log"}
               ]

            when @state.trip.type=="local" || @state.trip.type=="phone" then CardAnalytics
               className: "trip-card--analytics"
               data: [
                  {label: "Lifetime Gifts", value: span(className: "trip-card--analytics-dollar", numeral(lifetime_giving).format("$0a"))},
                  {label: "Interactions", value: @state.interaction_count, icon: "trip-log"}
               ]
            when @state.trip.type=="virtual" && _.isEmpty(virtual_meeting_link) then CardAnalytics
               className: "trip-card--analytics"
               data: [
                  {label: "Lifetime Gifts", value: span(className: "trip-card--analytics-dollar", numeral(lifetime_giving).format("$0a"))},
                  {label: "Interactions", value: @state.interaction_count, icon: "trip-log"}
               ]
            else
               CardAnalytics
                  className: "trip-card--analytics"
                  data: [
                     {label: "Lifetime Gifts", value: span(className: "trip-card--analytics-dollar", numeral(lifetime_giving).format("$0a"))},
                     {
                        label: "",
                        value: Link href:virtual_meeting_link, title:"join in", target: "_blank", rel: "noopener noreferrer",
                           "Join In"
                     }
                     {label: "Interactions", value: @state.interaction_count, icon: "trip-log"}
                  ]
         analytics


      render: ->
         trip = @state.trip
         facepile = Decorator.MyTrips.getFacepile(@state.contacts, 6)
         iconType = switch
            when trip.type=="local" then "group"
            when trip.type=="virtual" then "desktop-mac"
            when trip.type=="phone" then "call"
            else "map-pin"
                  
         Card className: "trip-card",
            div className: "trip-card--header",
               div className: "trip-card--date",
                  TripDate 
                     trip: trip
                  div className: "trip-card--options",
                     if @state.collaborators?.length
                        Tooltip
                           content:
                              div null,
                                 _.map @state.collaborators, (user) ->
                                    div
                                       className: "facepile--more-user-priveleges"
                                       key: user.id
                                       user.label
                           position: "bottom"
                           event: "hover"
                           children: ({open, close}) ->
                              IconButton
                                 title: "collaborators"
                                 onFocus: open,
                                 onBlur: close
                                 icon: "group"
                                 size: 1
                     if trip?.is_my_trip
                        ThreeDotActionsMenu 
                           options: [
                              {
                                 id: 1,
                                 icon: "edit",
                                 onClick: () => LayoutSource.launchModal @renderEditForm(trip)
                                 label: "Edit"
                              },
                              {
                                 id: 2,
                                 icon: "delete",
                                 className: "text-danger",
                                 onClick: @renderDeleteModal,
                                 label: "Delete"
                              }
                           ]

               if trip.type=="trip"
                  Link href: "/trips/#{trip?.id}", title: trip?.name, className: "trip-card--title ellipsis-overflow",
                     Icon icon:iconType, " "
                     trip?.name
               else
                  ModalTrigger2
                     title: trip.name
                     trigger: ({ open }) ->
                        LinkButton title: trip?.name, className: "trip-card--title ellipsis-overflow", onClick: open,
                           Icon icon:iconType, " "
                           trip.name
                     children: ({close}) =>
                        MeetingDetails
                           trip: trip
                           meeting: trip
                           contacts: @state.contacts
                           solicitors: @state.collaborators
                           remove: close
                  

               div className: "trip-card--meta fs-exclude-data",
                  div className: "trip-card--users clearfix",
                     if @state.loading_meetings
                        Loading
                           className: "trip-card--loader"
                           spinner_size: "small"
                           position: "top"
                     else if _.isEmpty(@state.contacts)
                        div className: "trip-card--user-empty text-muted",
                           "No constituents have been booked."
                     else if _.size(@state.contacts) == 1
                        contact = Decorator.Contacts.parse(_.first(@state.contacts))
                        fullName = Decorator.Contacts.getFullName(contact)
                        Link title: "Profile for #{fullName}", href: "/contact/#{contact.id}", className: "trip-card--single-avatar",
                           Avatar className: "trip-card--avatar", url: contact?.avatar, width: 40, name: fullName
                           fullName
                     else
                        div null,
                           Facepile
                              list: _.map facepile.contacts, (contact) ->
                                 {id: contact.id, avatar: contact.avatar, data: Decorator.Contacts.parse(contact)}
                              total: _.size(facepile.contacts)
                              popoverContent: (item) ->
                                 div className: "trip-card--avatar-hover",
                                    ContactCard
                                       contact: item.data
                                       shoNameLink: false
                              onAvatarClick: (item) =>
                                 @handleGoToProfile(item.data)

            @renderAnalytics()
