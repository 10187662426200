import { createSource } from '@evertrue/et-flux';

const CoreSearchSource = createSource('CoreSearchSource', {
  actions: {
    query(type) {
      this.require(typeof type === 'string', 'type should be a string');
    },
    setType(type) {
      this.require(typeof type === 'string', 'type should be a string');
    },
    setResultCount(type, count) {
      this.require(typeof type === 'string', 'type should be a string');
      this.require(typeof count === 'number', 'count should be a number');
    },
  },

  api: {
    query(type) {
      return this.actions.query(type);
    },

    setType(type) {
      return this.actions.setType(type);
    },

    setResultCount(type, count) {
      if (type && typeof count === 'number' && !isNaN(count)) {
        return this.actions.setResultCount(type, count);
      }
    },
  },
});

export default CoreSearchSource;
