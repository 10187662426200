module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   VolunteersStore = require("apps/volunteers/stores/volunteers-store").default
   VolunteersSource = require("apps/volunteers/sources/volunteers-source")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

   createComponent "VolunteerPoolDropdown",
      propTypes:
         value: ReactLibs.PropTypes.any
         options: ReactLibs.PropTypes.array
         onChange: ReactLibs.PropTypes.func

      getDefaultProps: ->
         value: undefined

      registerStores: ->
         @on VolunteersStore, ->
            volunteers: VolunteersStore.getVolunteerOptions()
            loading: VolunteersStore.getLoading()

      componentDidMount: ->
         VolunteersSource.fetch()

      handleSearch: (query) ->
         VolunteersSource.fetch(query)

      render: ->
         div style: {width: 275},
            AdvancedCombobox
               options: @state.volunteers
               value: _.map @props.value, (val) ->
                  _.extend {}, val, value: _.toNumber(val.value)
               multiple: true
               onChange: @props.onChange
               onSearch: @handleSearch
               loading: @state.loading
               searchable: true
               allowChangeAll: true
               allowUnselectAll: @props.multiple
