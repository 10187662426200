import { useState, useCallback, useEffect } from 'react';
import Api from 'entities/helpers/api';

const sortHelper = (a, b) => {
  const aTitle = a?.folder?.title || '';
  const bTitle = b?.folder?.title || '';
  return aTitle.toLowerCase().localeCompare(bTitle.toLowerCase());
};

export const useFolders = (folderType = 'STATIC') => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);

  // FOLDER METHODS

  // Retrieve folders from the API and update the state
  const getFolders = useCallback(() => {
    setLoading(true);
    return Api.CONTACTS.FOLDERS.get({
      params: { limit: 100, folder_type: folderType },
    })
      .then((resp) => {
        const sorted = resp?.sort(sortHelper) || [];
        setFolders(sorted);
        setLoading(false);
        return resp;
      })
      .catch(() => {
        setLoading(false);
        setFolders([]);
        return [];
      });
  }, [folderType]);

  // Fetch Initial Folders
  useEffect(() => {
    getFolders();
  }, [getFolders]);

  // Pure API call to create a folder
  const createFolder = (folderName) => {
    return Api.CONTACTS.FOLDERS.post({
      data: JSON.stringify({ title: folderName, type: folderType }),
    });
  };

  // Updates the state with a new folder
  const addFolderToState = (folder) => {
    const newFolders = [...folders, { folder, totalNumberOfLists: 0 }];
    setFolders(newFolders.sort(sortHelper));
  };

  // Create a folder and add it to the state
  const createFolderAndAddToState = (folderName) => {
    return createFolder(folderName).then((resp) => {
      addFolderToState(resp);
      return resp?.id;
    });
  };

  // LIST FOLDER METHODS

  // Pure API call to add lists to a folder
  const addListsToFolder = (folderIds, listIds) => {
    if (!Array.isArray(folderIds)) {
      folderIds = [folderIds];
    }

    const folderListMap = folderIds.reduce((acc, folderId) => {
      acc[folderId] = listIds;
      return acc;
    }, {});

    return Api.CONTACTS.FOLDER_LISTS.post({
      data: JSON.stringify({
        folderListMap,
      }),
    });
  };

  // When lists are added to a folder, update the folder's list count
  // if lists are added to a new folder, add the folder to the state and then update the list count
  const updateListCountState = (res, newFolder) => {
    if (!Array.isArray(res)) return;
    const newCounts = res.reduce((acc, curr) => {
      const { folderId } = curr;
      if (acc[folderId]) {
        acc[folderId] += 1;
      } else {
        acc[folderId] = 1;
      }
      return acc;
    }, {});
    const foldersCopy = newFolder ? [...folders, { folder: newFolder, totalNumberOfLists: 0 }] : [...folders];
    const newFolders = foldersCopy.map((f) => {
      const count = newCounts[f.folder.id] || 0;
      return { folder: f.folder, totalNumberOfLists: f.totalNumberOfLists + count };
    });
    setFolders(newFolders.sort(sortHelper));
  };

  // Make an API call to add lists to a folder, then update the state
  const addListsToFolderAndUpdateState = (folder, listIds) => {
    return addListsToFolder(folder.id, listIds).then((res) => {
      updateListCountState(res, folder);
    });
  };

  return {
    folders,
    loading,
    createFolder,
    addListsToFolder,
    createFolderAndAddToState,
    updateListCountState,
    addListsToFolderAndUpdateState,
  };
};
