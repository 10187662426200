import QuickInteractionModal from "apps/outreach/components/quick-interaction-modal";
import { useGlobalDrawerProvider } from "./context/global-drawer-context";
import DNDComponents from "components/controls/dnd-components/dnd-components";
import { useGlobalOutreachProvider } from "apps/outreach/contexts/global-outreach-context";

const DrawerContainer = () => {
  const { drawers, disableDragAndDrop } = useGlobalDrawerProvider();
  const { isQuickInteractionOpen, quickInteractionData, closeQuickInteraction } = useGlobalOutreachProvider();

  // Prepare the draggable items
  const draggableItems = drawers.map(({ id, drawer }) => ({
    id,
    component: drawer(),
  }));

  return (
    <>
      <DNDComponents
        items={draggableItems}
        itemsClassName="drawers-container"
        ignoreContainerClipping={true}
        isDragDisabled={disableDragAndDrop}
      />
      {isQuickInteractionOpen && (
        <QuickInteractionModal
          isOpen={isQuickInteractionOpen}
          closeModal={closeQuickInteraction}
          {...quickInteractionData}
        />
      )}
    </>
  );
};

export default DrawerContainer;
