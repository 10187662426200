import { useState } from "react";
import PropTypes from "prop-types";
import DNAStore from "base/dna/dna-store";
import { Giving } from "clientDecorator";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import GivingSummary from "apps/contact/components/profile/giving-data/giving-summary";
import CategorizedGivingHistory from "apps/contact/components/profile/giving-data/categorized-giving-history";
import GivingTitle from "apps/contact/components/profile/giving-data/giving-title";

const ProfileGivingController = ({ contact = {} }) => {
  const [selectedCategory, setSelectedCategory] = useState("Total Giving");
  const orgFiscalMmDd = DNAStore.getByKey("ET.Org.FiscalYear");

  const handleUpdateCategory = (val) => {
    setSelectedCategory(val.value);
  };

  const { categories: contactCategories } = Giving.getProfileGiving(contact, orgFiscalMmDd) || {};
  const categories = contactCategories.map((cat) => ({
    label: cat.label,
    value: cat.label,
    disabled:
      cat.history.length === 0 && !cat.total_pledge_balance && !cat.has_recurring_payments && !cat.lifetime_amount,
  }));

  categories.unshift({
    label: "Total Giving",
    value: "Total Giving",
    disabled: false,
  });

  const giving = Giving.getCategoryGiving(
    contact,
    selectedCategory === "Total Giving" ? null : selectedCategory,
    orgFiscalMmDd
  );

  return (
    <div className="profile-giving-controller">
      <ProfileCardHeader
        title={
          <GivingTitle
            contact={contact}
            selectedCategory={selectedCategory}
            categories={categories}
            onUpdateCategory={handleUpdateCategory}
          />
        }
      />
      <GivingSummary giving={giving} />
      <div className="giving-module fs-exclude-data">
        <CategorizedGivingHistory giving={giving} />
      </div>
    </div>
  );
};

ProfileGivingController.propTypes = {
  contact: PropTypes.object,
};

export default ProfileGivingController;
