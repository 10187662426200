import { useState } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { Loading, LinkButton, ModalBody } from "@evertrue/et-components";
import CareerItems from "apps/profile/components/enrichment/career-items";

const gql = String.raw;

const CareerEditModalBody = ({ contact_id, employmentData, onDelete, requestHide }) => {
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const activeEmployment = employmentData.filter((employment) => !employment.rejected);
  const rejectedEmployment = employmentData.filter((employment) => employment.rejected);

  const handleMutation = (career, rejecting) => {
    setLoading(true);
    const profileMutation = {
      employments: [{ id: career.id, rejected: rejecting, updated_at: career.updated_at }],
    };

    const query = gql`
      mutation($id: BigInt!, $enrichment: EnrichmentInput) {
        contact_mutation(id: $id, enrichment: $enrichment) {
          code
          enrichment_mutation {
            employments_mutations {
              success
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contact_id, enrichment: profileMutation },
      }),
    })
      .then(() => {
        _.delay(() => {
          rejecting ? EverTrue.Alert.success("Employment Removed") : EverTrue.Alert.success("Employment Restored");
          onDelete();
          setLoading(false);
          requestHide();
        }, 1500);
      })
      .catch(() => {
        setLoading(false);
        rejecting
          ? EverTrue.Alert.error("Problem removing employment record. Please try again later")
          : EverTrue.Alert.error("Problem restoring employment record. Please try again later");
      });
  };

  return (
    <ModalBody>
      {loading ? <Loading /> : null}
      <CareerItems
        employment={activeEmployment}
        editMode="delete"
        onMutation={(career) => handleMutation(career, true)}
      />

      <div className="profile--enrichment-restore toggle-deleted">
        {rejectedEmployment.length ? (
          <LinkButton onClick={() => setDeleteVisible(!deleteVisible)} title="Deleted Employments">
            {deleteVisible ? "Hide deleted" : "See deleted"}
          </LinkButton>
        ) : null}
      </div>
      {deleteVisible ? (
        <div className="profile--enrichment-rejected-list">
          <CareerItems
            employment={rejectedEmployment}
            editMode="restore"
            onMutation={(career) => handleMutation(career, false)}
          />
        </div>
      ) : null}
    </ModalBody>
  );
};

CareerEditModalBody.propTypes = {
  contact_id: PropTypes.number,
  employmentData: PropTypes.array,
  onDelete: PropTypes.func,
  requestHide: PropTypes.func,
};

export default CareerEditModalBody;
