import { useIdentity } from 'base/identity-resolver';
import { useState, useEffect } from 'react';
import { getUserContactIdForOrg } from 'apps/tasks/utils/contact-helpers';
import { getAndCreateRecommendedActionsForSection } from 'apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests';

// Key: Name of Section
// Value: User Setting Configuration associated with Section
const SECTION_TO_SETTINGS_MAPPINGS = {
  recentGift: 'enableGiftSection',
  careerMove: 'enableCareerMovesSection',
  giftAnniversary: 'enableAnniversarySection',
  tripsAndMeetings: 'enableTripMeeting',
  dps: 'enableDPS',
};

// Key: Name of Section
// Value: an empty array (default state)
// NOTE: This is the order in which the items will be rendered
const RECOMMENDATION_SECTIONS = {
  recentGift: [],
  careerMove: [],
  giftAnniversary: [],
  tripsAndMeetings: [],
  dps: [],
};

const useRecommendedActions = (userSettings) => {
  const [loading, setLoading] = useState(false);
  const [recommendedActions, setRecommendedActions] = useState(RECOMMENDATION_SECTIONS);
  const { user, org } = useIdentity();

  useEffect(() => {
    const getAndSetRecommendedActions = async () => {
      const actions = {};

      try {
        const userSolicitorContactId = getUserContactIdForOrg(user, org);
        if (!userSolicitorContactId) return;
        setLoading(true);
        for (const section in RECOMMENDATION_SECTIONS) {
          const enabled = !!userSettings[SECTION_TO_SETTINGS_MAPPINGS[section]];
          if (enabled) {
            // fetch an array of recommended actions for each enabled section
            const recs = await getAndCreateRecommendedActionsForSection(section, userSolicitorContactId, userSettings);
            actions[section] = recs;
          }
        }
        setRecommendedActions(actions);
      } finally {
        setLoading(false);
      }
    };

    getAndSetRecommendedActions();
  }, [org, user, userSettings]);

  return {
    loading,
    recommendedActions,
  };
};

export default useRecommendedActions;
