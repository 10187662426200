import _ from "underscore";
import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

export const MultiRecipientsInput = ({
  inputRecipients,
  setInputRecipients,
  numCc,
  numBcc,
  label,
  showCc,
  showBcc,
}) => {
  const emailInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showInput, setShowInput] = useState(true);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showInput]);

  const removeEmail = (email) => {
    const key = label.toLowerCase();
    setInputRecipients((prevState) => ({
      ...prevState,
      [key]: prevState[key].filter((recipient) => recipient !== email),
    }));
  };

  const addEmail = (email) => {
    setInputRecipients((prevState) => {
      const key = label.toLowerCase();
      if (!prevState[key].some((elem) => elem === email.toLowerCase())) {
        return { ...prevState, [key]: [...prevState[key], email] };
      } else {
        return prevState;
      }
    });
    emailInputRef.current.value = "";
  };

  const isValid = (email) => {
    return _.validateEmail(email.trim());
  };

  const handleOnFocus = () => {
    setIsFocused(true);
    emailInputRef.current.focus();
  };

  const handleOnBlur = (e) => {
    if (isValid(e.target.value)) addEmail(e.target.value);
    else e.target.value = "";

    if (
      e.relatedTarget &&
      (!e.relatedTarget.classList.contains("email-recipient-label-cross-icon") ||
        e.relatedTarget.classList.length === 0)
    ) {
      setIsFocused(false);
      setShowInput(false);
    }
  };

  const handleOnKeydown = (e) => {
    switch (e.key) {
      case "Enter":
      case ",":
      case " ":
        if (isValid(e.target.value)) addEmail(e.target.value);
        e.preventDefault();
        break;
      case "Tab":
        if (isValid(e.target.value)) addEmail(e.target.value);
        else e.target.value = "";
        break;
      case "Backspace":
        if (!e.currentTarget.value) {
          const key = label.toLowerCase();
          setInputRecipients((prevState) => ({
            ...prevState,
            [key]: prevState[key].slice(0, -1),
          }));
        }
        break;
      default:
    }
  };

  const getMoreStr = () => {
    const extraRecipients = Math.max(inputRecipients.length - 3, 0);
    const ccRecipients = !showCc ? numCc : 0;
    const bccRecipients = !showBcc ? numBcc : 0;
    const total = extraRecipients + ccRecipients + bccRecipients;
    if (total === 0) return "";
    const ccStr = ccRecipients ? `(${ccRecipients} Cc)` : "";
    const bccStr = bccRecipients ? `(${bccRecipients} Bcc)` : "";
    return `${total} More ${ccStr} ${bccStr}`;
  };

  return (
    <div className="email-form-header-field" style={{ minHeight: "64px" }} onClick={() => setShowInput(true)}>
      <label className="email-form--label">
        {(inputRecipients.length === 0 || showInput) && (
          <strong className="email-form-header-label">{`${label}:` || ""}</strong>
        )}
        <ul className="email-recipients-field">
          {inputRecipients && !isFocused ? (
            <>
              {inputRecipients.slice(0, 3).map((recipient) => (
                <li className="email-recipients-field-tag" key={recipient}>
                  {recipient}
                  <Icon
                    icon="cancel"
                    size={1}
                    after={true}
                    className={"email-recipient-label-cross-icon"}
                    onClick={() => removeEmail(recipient)}
                    style={{ margin: "5px", cursor: "pointer" }}
                  />
                </li>
              ))}
              {(inputRecipients.length > 3 || numCc > 0 || numBcc > 0) && (
                <li className="email-recipients-field-tag"> {getMoreStr()}</li>
              )}
            </>
          ) : (
            inputRecipients.map((recipient, index) => (
              <li className="email-recipients-field-tag" key={recipient}>
                {recipient}
                <Icon
                  icon="cancel"
                  size={1}
                  after={true}
                  className={"email-recipient-label-cross-icon"}
                  onClick={() => removeEmail(recipient)}
                  style={{ margin: "5px", cursor: "pointer" }}
                />
              </li>
            ))
          )}
          {showInput && (
            <input
              ref={emailInputRef}
              type="text"
              onKeyDown={handleOnKeydown}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              className={"email-recipients-field-input"}
              data-testid="recipient-field"
              name={`${label}-input`}
            />
          )}
        </ul>
      </label>
    </div>
  );
};

MultiRecipientsInput.propTypes = {
  inputRecipients: PropTypes.array,
  setInputRecipients: PropTypes.func,
  numCc: PropTypes.number,
  numBcc: PropTypes.number,
  label: PropTypes.string,
  showCc: PropTypes.bool,
  showBcc: PropTypes.bool,
};
