module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   {createFactory} = require("base/new-utils")
   ProfileCard = createFactory require("apps/profile/components/profile-card").default
   BioInfo = createFactory require("apps/profile/components/bio-info").default
   DonorInfo = require("apps/profile/components/donor-info")
   ProfileRelationshipsController = require("apps/profile/controllers/profile-relationships-controller")
   ProfileAssignmentSource = require("apps/profile/sources/profile-assignment-source").default
   ProfileAssignmentStore = require("apps/profile/stores/profile-assignment-store").default
   WithFlag = createFactory(require("base/launch-darkly/with-flag.js").default)

   createComponent "ProfileController",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      getInitialState: ->
         last_tracked_section: "summary"

      registerStores: ->
         @on FeatureStore, ->
            has_volunteers: FeatureStore.hasFeature("volunteers")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")
            gates_loading: FeatureStore.getGatesLoading()

         @on ProfileAssignmentStore, "id", (props) ->
            team_assignment_contacts: ProfileAssignmentStore.getTeamAssignmentsForProfile(props.contact.id)
            dxo_assignment_contacts: ProfileAssignmentStore.getDxoAssignmentsForProfile(props.contact.id)
            volunteer_assignment_contacts: ProfileAssignmentStore.getVolunteerAssignmentsForProfile(props.contact.id)

      componentDidMount: ->
         if @state.has_rm_assignments
            # need to fetch assignment titles to sort the assignments
            ProfileAssignmentSource.fetchAssignmentTitles()
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

      componentDidUpdate: (prevProps, prevState) ->
         if !prevState.has_rm_assignments and @state.has_rm_assignments
            # need to fetch assignment titles to sort the assignments
            ProfileAssignmentSource.fetchAssignmentTitles()
            ProfileAssignmentSource.fetchTeamMembership(@props.contact.id)
         if !prevState.has_volunteers and @state.has_volunteers
            ProfileAssignmentSource.fetchVolunteerMembership(@props.contact.id)

      render: ->
         contact = @props.contact
         team_assignees = contact.assignees
         dxo_assignees = []
         volunteer_assignees = []
         if @state.has_rm_assignments
            team_assignees = @state.team_assignment_contacts
         if @state.has_volunteers
            volunteer_assignees =  @state.volunteer_assignment_contacts
         total_assignees = _.compact([].concat(team_assignees, volunteer_assignees, dxo_assignees))

         div null,
            div className: "profile-nav-section--box clearfix",
               ProfileCard size: 2,
                  BioInfo contact: contact

               ProfileCard size: 2, className: "profile-section-link--assignee",
                  WithFlag
                     Component: DonorInfo
                        contact: contact
                        totalAssignees: total_assignees

               ProfileCard size: 4,
                  ProfileRelationshipsController
                     contact_id: contact?.id
                     contact: contact
