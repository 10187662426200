import { bulkTaskCompletion } from 'apps/journeys/utils/api-requests/modal-actions';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import ErrorLogger from 'entities/helpers/error-log-helper';

/**
 * The BulkTaskCompleteModal component renders a modal to bulk mark tasks as complete or incomplete.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {boolean} [props.isComplete=false] - Determines if tasks should be marked as complete (true) or incomplete (false).
 * @param {Array} props.tasks - Array of selected tasks
 * @param {function} [props.optionalStateManagementFunction] - Optional function for managing external state after completion.
 * @param {string} [props.label='Tasks Selected'] - Label for the number of tasks selected.
 */
const BulkTaskCompleteModal = ({
  isOpen,
  onClose,
  isComplete = false,
  tasks,
  optionalStateManagementFunction = () => {},
  trackAction = () => {}, // mixpanel tracking function
  label = 'Tasks Selected',
}) => {
  const taskCount = tasks ? tasks.length : 0;

  const handleSubmit = async () => {
    try {
      if (tasks.length > 0) {
        const taskIds = tasks.map((taskObj) => taskObj.id);
        const payload = {
          task_ids: taskIds,
        };
        await bulkTaskCompletion(isComplete, payload);
        optionalStateManagementFunction('bulkCompleteTasks', { taskIds, isComplete });
        trackAction('bulk_complete', taskIds, isComplete);

        onClose();
        EverTrue.Alert.success(
          `You've successfully marked the selected task(s) ${
            isComplete ? 'Complete' : 'Incomplete'
          }. Please refresh the page to continue.`
        );
      }
    } catch (error) {
      ErrorLogger.error('Error in handleSubmit while completing tasks:', {
        extra: { error, page_name: 'BulkTaskCompleteModal' },
        tags: { taskIds: tasks.map((taskObj) => taskObj.id), isComplete },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={isComplete ? 'Mark as Complete' : 'Mark as Incomplete'}
        closeModal={onClose}
        submitLabel={'Confirm'}
        onSubmit={handleSubmit}
        disableSubmit={taskCount === 0}
      />
      <ModalBody scrollable={false}>
        <div>
          <span className="task-complete-label">
            {taskCount} {label}
          </span>
          <span>{`Are you sure you want to mark selected tasks as ${isComplete ? 'complete' : 'incomplete'}?`}</span>
        </div>
      </ModalBody>
    </Modal>
  );
};

BulkTaskCompleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isComplete: PropTypes.bool,
  tasks: PropTypes.array,
  optionalStateManagementFunction: PropTypes.func,
  trackAction: PropTypes.func,
  label: PropTypes.string,
};

export default BulkTaskCompleteModal;
