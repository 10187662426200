import { createContext, useContext, useState, useRef, useCallback } from 'react';
import EverTrue from 'app';
import useUserAuthentication from './useUserAuthentication';
import PropTypes from 'prop-types';

export const RecorderContext = createContext();

const color = '#3367F2';
const trackColor = '#FFFFFF';

export const RecorderProvider = ({ children }) => {
  const { user, clientId = '' } = useUserAuthentication();
  const [state, setState] = useState({
    users: [],
    loading: false,
    signalVideos: [],
    webcamStream: null,
    webcamEnabled: false,
    isRecording: false,
    isCountingDown: false,
    countdown: 3,
    timestamp: 0,
    videoUrl: null,
    recordedVideoSrc: null,
    savedVideos: [],
    selectedVideoId: {},
  });

  const videoRef = useRef(null);
  const reviewVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const screenVideoRef = useRef(null);
  const chunksRef = useRef([]);
  const timestampInterval = useRef(null);
  const [resolutions, setResolutions] = useState([]);
  const [selectedResolution, setSelectedResolution] = useState('');
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleUploadVideoFromFile = async (
    video,
    fileName,
    duration,
    personalVideoParams = {},
    uploadVideoFromFile,
    onUpload = () => {}
  ) => {
    const newVideo = await uploadVideoFromFile(video, fileName, duration, personalVideoParams);
    setSelectedVideo(newVideo);
    onUpload();
    return newVideo;
  };

  async function getAudioAndVideoDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      setAudioDevices(audioDevices);
      setVideoDevices(videoDevices);

      if (audioDevices.length > 0 && !selectedAudioDevice) {
        setSelectedAudioDevice(audioDevices[0].deviceId);
      }

      if (videoDevices.length > 0 && !selectedVideoDevice) {
        setSelectedVideoDevice(videoDevices[0].deviceId);
      }
    } catch (error) {
      console.error('Error enumerating devices');
    }
  }
  async function getCameraResolutions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();

      // Stop the stream immediately after getting capabilities
      stream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });

      if (capabilities.width && capabilities.height) {
        const resolutions = [];
        const standardResolutions = [
          { width: 1920, height: 1080 },
          { width: 1280, height: 720 },
          { width: 640, height: 480 },
        ];

        standardResolutions.forEach((res) => {
          if (res.width <= capabilities.width.max && res.height <= capabilities.height.max) {
            resolutions.push(res);
          }
        });

        setResolutions(resolutions);
        if (resolutions.length > 0) {
          setSelectedResolution(`${resolutions[0].width}x${resolutions[0].height}`);
        }
      }
    } catch (error) {
      console.error('Error accessing media devices');
    }
  }

  const updateState = useCallback(
    (newState) => {
      setState((prevState) => {
        const updatedState = typeof newState === 'function' ? newState(prevState) : newState;
        return { ...prevState, ...updatedState };
      });
    },
    [setState]
  );

  const resetRecorderState = () => {
    updateState({
      isRecording: false,
      webcamStream: null,
      recordedVideoSrc: null,
      videoUrl: null,
      timestamp: 0,
      countdown: 3,
    });
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    chunksRef.current = [];
  };
  const startTimestamp = () => {
    updateState({ timestamp: 0 });
    if (timestampInterval.current) {
      clearInterval(timestampInterval.current);
    }

    setTimeout(() => {
      timestampInterval.current = setInterval(() => {
        updateState((prevState) => ({ timestamp: prevState.timestamp + 1 }));
      }, 1000);
    }, 0);
  };

  const stopTimestamp = () => {
    clearInterval(timestampInterval.current);
  };

  const resetTimestamp = () => {
    updateState({ timestamp: 0 });
  };

  const clearRecording = () => {
    updateState({ videoUrl: null });
    resetTimestamp();
    if (chunksRef.current) {
      chunksRef.current = [];
    }
    if (videoRef.current) {
      videoRef.current.src = '';
    }
  };

  const handleDeviceChange = async (deviceId, type) => {
    try {
      // Stop current stream
      if (state.webcamStream) {
        state.webcamStream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
      }

      // Update the selected device
      if (type === 'video') {
        setSelectedVideoDevice(deviceId);
      } else if (type === 'audio') {
        setSelectedAudioDevice(deviceId);
      }

      // Create new constraints based on current selections
      const constraints = {
        audio: {
          deviceId:
            type === 'audio' ? { exact: deviceId } : selectedAudioDevice ? { exact: selectedAudioDevice } : undefined,
        },
        video: {
          deviceId:
            type === 'video' ? { exact: deviceId } : selectedVideoDevice ? { exact: selectedVideoDevice } : undefined,
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      };

      await enableWebcam(constraints);
    } catch (error) {
      console.error('Error changing device:', error);
      EverTrue.Alert.error(`Failed to switch device: ${error.message}`);
    }
  };

  const enableWebcam = async (customConstraints = {}) => {
    const fallbackConstraints = [
      // Try HD with selected devices
      {
        ...customConstraints,
        video: {
          ...customConstraints.video,
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      },
      // Try SD with selected devices
      {
        ...customConstraints,
        video: {
          ...customConstraints.video,
          width: { ideal: 640 },
          height: { ideal: 480 },
        },
      },
      // Try just the device selection without resolution constraints
      customConstraints,
      // Last resort: basic video/audio
      {
        video: true,
        audio: true,
      },
    ];

    try {
      // Stop existing streams
      if (state.webcamStream) {
        state.webcamStream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
      }

      let stream = null;
      let error = null;

      // Try each constraint set until one works
      for (const constraints of fallbackConstraints) {
        try {
          stream = await navigator.mediaDevices.getUserMedia(constraints);
          break; // Exit loop if successful
        } catch (e) {
          error = e;
          console.warn('Failed constraint set:', constraints, e);
          continue; // Try next constraint set
        }
      }

      if (!stream) {
        throw error || new Error('Could not access webcam with any constraints');
      }

      if (!audioDevices.length || !videoDevices.length) {
        await getAudioAndVideoDevices();
        await getCameraResolutions();
      }

      // Update state and video element
      updateState({ webcamStream: stream, webcamEnabled: true });
      if (videoRef.current) {
        if (videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false;
          });
        }
        videoRef.current.srcObject = stream;
      }

      return true;
    } catch (error) {
      console.error('Error accessing webcam:', error);
      updateState({ webcamEnabled: false });
      throw error;
    }
  };

  const stopWebcam = () => {
    const stopAllTracks = (stream) => {
      if (!stream) return;
      stream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
    };

    // Stop the MediaRecorder if it's active
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      try {
        mediaRecorderRef.current.stop();
        // Get and stop tracks from MediaRecorder's stream
        if (mediaRecorderRef.current.stream) {
          stopAllTracks(mediaRecorderRef.current.stream);
        }
      } catch (error) {
        console.error('Error stopping MediaRecorder:', error);
      }
    }

    // Stop the main webcam stream
    if (state.webcamStream) {
      stopAllTracks(state.webcamStream);
    }

    // Stop any tracks in the video elements
    if (videoRef.current && videoRef.current.srcObject) {
      stopAllTracks(videoRef.current.srcObject);
      videoRef.current.srcObject = null;
    }

    if (reviewVideoRef.current && reviewVideoRef.current.srcObject) {
      stopAllTracks(reviewVideoRef.current.srcObject);
      reviewVideoRef.current.srcObject = null;
    }

    // Clear all references
    mediaRecorderRef.current = null;

    // Clear state
    updateState({
      webcamStream: null,
      webcamEnabled: false,
      isRecording: false,
    });
  };

  const startRecording = async () => {
    if (!selectedResolution) {
      console.error('No resolution selected');
      return;
    }

    // Only stop existing recording, don't stop webcam
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }

    const [width, height] = selectedResolution.split('x').map(Number);

    try {
      // Use existing stream if available and active
      let stream = state.webcamStream;
      if (!stream || !stream.active) {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: { ideal: width },
            height: { ideal: height },
          },
        });

        // Store the stream in both state and video element
        updateState({
          webcamStream: stream,
          webcamEnabled: true,
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      }

      // Create and start MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(stream);
      chunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start();
      startTimestamp();
      updateState({ isRecording: true });

      // Set up auto-stop after 5 minutes
      setTimeout(() => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
          stopRecording();
        }
      }, 5 * 60 * 1000);

      return true;
    } catch (error) {
      console.error('Error in startRecording:', error);
      stopWebcam();
      updateState({ isRecording: false });
      return false;
    }
  };

  const startCountdown = () => {
    return new Promise((resolve) => {
      updateState({ isCountingDown: true, countdown: 3 });
      const countdownInterval = setInterval(() => {
        updateState((prevState) => {
          if (prevState.countdown <= 1) {
            clearInterval(countdownInterval);
            resolve(); // Resolve the promise when countdown is complete
            return { isCountingDown: false, countdown: 0 };
          }
          return { countdown: prevState.countdown - 1 };
        });
      }, 1000);
    });
  };

  const stopRecording = (personalVideoParams, uploadVideo = () => {}) => {
    const finalize = () => {
      stopWebcam();
      stopTimestamp();
      updateState({ isRecording: false });
    };

    const processRecording = async (blob) => {
      try {
        const url = URL.createObjectURL(blob);
        const duration = await getBlobDuration(blob);
        updateState({ videoUrl: url, videoDuration: duration });
        if (reviewVideoRef.current) {
          reviewVideoRef.current.srcObject = null;
          reviewVideoRef.current.src = url;
          reviewVideoRef.current.load();
        }
        saveVideoToState(url);
        return { url, duration };
      } catch (error) {
        EverTrue.Alert.error(`Error processing recording ${error}`);
        throw error;
      }
    };

    return new Promise((resolve, reject) => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.onstop = async () => {
          if (chunksRef.current.length > 0) {
            const blob = new Blob(chunksRef.current, { type: mediaRecorderRef.current.mimeType });
            try {
              const { url, duration } = await processRecording(blob);
              finalize();
              const newVideo = await uploadVideo(blob, url, duration, personalVideoParams);
              setSelectedVideo(newVideo);
              resolve(newVideo);
            } catch (error) {
              console.error(`Error in processRecording ${error}`);
              rerecord();
              reject(error);
            }
          } else {
            finalize();
            reject(new Error('No recording data available'));
          }
        };

        try {
          mediaRecorderRef.current.stop();
        } catch (error) {
          console.error(`Error stopping MediaRecorder ${error}`);
          finalize();
          reject(error);
        }
      } else {
        finalize();
        reject(new Error("MediaRecorder not active or doesn't exist"));
      }
    });
  };

  const rerecord = () => {
    stopTimestamp();
    clearRecording();
    resetTimestamp();

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }

    mediaRecorderRef.current = null;

    updateState({ isRecording: false });
    enableWebcam();
  };

  const getBlobDuration = (blob) => {
    return new Promise((resolve, reject) => {
      const tempVideoEl = document.createElement('video');
      tempVideoEl.addEventListener('loadedmetadata', () => {
        if (tempVideoEl.duration === Infinity) {
          tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
          tempVideoEl.ontimeupdate = () => {
            tempVideoEl.ontimeupdate = null;
            resolve(tempVideoEl.duration);
            tempVideoEl.remove();
          };
        } else {
          resolve(tempVideoEl.duration);
          tempVideoEl.remove();
        }
      });
      tempVideoEl.onerror = (event) => reject(event.target.error);
      tempVideoEl.src = URL.createObjectURL(blob);
    });
  };

  const saveVideoToState = (videoUrl) => {
    updateState((prevState) => ({
      savedVideos: [...prevState.savedVideos, videoUrl],
    }));
  };
  const changeResolution = (newResolution) => {
    setSelectedResolution(newResolution);
  };

  const toggleSetting = (setting) => {
    setState((prevState) => {
      const selectedVideo = prevState.signalVideos.find(
        (video) => video.personalVideo.id === prevState.selectedVideoId
      );
      if (!selectedVideo) return prevState;

      return {
        ...prevState,
        signalVideos: prevState.signalVideos.map((video) =>
          video.personalVideo.id === prevState.selectedVideoId
            ? {
                ...video,
                personalVideo: {
                  ...video.personalVideo,
                  [setting]: !video.personalVideo[setting],
                },
              }
            : video
        ),
      };
    });
  };

  const stopAndResetRecorder = () => {
    stopWebcam();
    resetRecorderState();
    setSelectedVideo(null);
  };

  const value = {
    state,
    updateState,
    user,
    clientId,
    videoRef,
    reviewVideoRef,
    mediaRecorderRef,
    screenVideoRef,
    timestampInterval,
    startCountdown,
    startTimestamp,
    stopTimestamp,
    resetTimestamp,
    clearRecording,
    enableWebcam,
    stopWebcam,
    startRecording,
    stopRecording,
    rerecord,
    resetRecorderState,
    saveVideoToState,
    resolutions,
    selectedResolution,
    changeResolution,
    selectedAudioDevice,
    selectedVideoDevice,
    selectedVideo,
    audioDevices,
    videoDevices,
    setSelectedAudioDevice,
    setSelectedVideoDevice,
    toggleSetting,
    color,
    trackColor,
    handleUploadVideoFromFile,
    setSelectedVideo,
    handleDeviceChange,
    stopAndResetRecorder,
  };

  return <RecorderContext.Provider value={value}>{children}</RecorderContext.Provider>;
};

RecorderProvider.propTypes = {
  children: PropTypes.any,
};

export const useRecorder = () => useContext(RecorderContext);
