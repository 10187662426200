import { Modal, ModalHeader, ModalBody } from '@evertrue/et-components';
import ActiveJourney from './active-journey';
import { PropTypes } from 'base/react-libs';

const ActiveJourneysModal = ({ isOpen, closeModal, activeJourneys = [], contactName = '' }) => {
  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal} closeOnOutsideClick={true}>
        <ModalHeader title="Cadences" closeModal={closeModal} />
        <ModalBody>
          <div>
            <p className="active-journeys-modal--header">
              {contactName} is added to {activeJourneys.length} Cadences.
            </p>
            {activeJourneys.map((journey) => (
              <ActiveJourney journey={journey} key={journey.journey_id} contactName={contactName} />
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

ActiveJourneysModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  activeJourneys: PropTypes.array,
  contactName: PropTypes.string,
};

export default ActiveJourneysModal;
