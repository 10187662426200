import { Icon, Button } from '@evertrue/et-components';
import SuggestedSearchList from 'apps/constituents/ai-profile-chat/components/suggested-search-list';
import colors from 'base/colors';
import PropTypes from 'prop-types';

const AISearchErrorPage = ({ query, suggestedSearches, handleSuggestedSearchClick }) => {
  return (
    <div className="error-page">
      <Icon icon="ai-search" iconKit="gt2" style={{ fontSize: '36px', color: colors.gray200 }} />
      <p className="error-title">No results found for "{query}"</p>
      <SuggestedSearchList
        suggestedSearches={suggestedSearches}
        handleSuggestedSearchClick={handleSuggestedSearchClick}
        headerText="Sorry, EverTrue's AI Search tool doesn't appear to have access to data in this area. Perhaps you want to
            search for one of the following:"
      />
      <p className="learn-more-title">
        Or, you may want to learn about the types of searches you can do with EverTrue's AI Search.
      </p>
      <Button type="secondary" className="bt-learn-more" onClick={() => {}}>
        Learn More
      </Button>
    </div>
  );
};

AISearchErrorPage.propTypes = {
  query: PropTypes.string,
  suggestedSearches: PropTypes.array,
  handleSuggestedSearchClick: PropTypes.func,
};

export default AISearchErrorPage;
