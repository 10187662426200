import { useEffect, useState } from 'react';
import { pollVideoTrimStatus, trimVideo, revertTrim } from '../../../entities/helpers/thankview-helper';
import { useRecorder } from './recorder-context';
import EverTrue from 'app';

const useTrimVideo = (selectedVideo, setSelectedVideo) => {
  const { clientId: { data: { client_id = null, client_secret = null } = {} } = {} } = useRecorder();
  const { id, trim_scheduled, trim_executing, video_untrimmed } = selectedVideo.libraryVideo;
  const [trimVideoPath, setTrimVideoPath] = useState(null);

  // This is the loading state for trimming as soon as the API call is made (There is a delay of a few seconds in the response)
  // When this is true, the trim button will be disabled and the control bar will go back to the review state
  const [trimLoading, setTrimLoading] = useState(trim_executing || trim_scheduled);

  // Used for polling the video trim status once the API response is received
  const [pollTrim, setPollTrim] = useState(trim_executing || trim_scheduled);

  useEffect(() => {
    setPollTrim(trim_executing || trim_scheduled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideo]);

  useEffect(() => {
    let isSubscribed = true;
    let timeoutId = null;
    let pollStartTime = Date.now();
    const MAX_POLL_DURATION = 2 * 60 * 1000;

    const getTrimStatus = async () => {
      try {
        const {
          data: { processingState, videoPath },
        } = await pollVideoTrimStatus(id, client_id, client_secret);

        if (!isSubscribed) return;

        if (processingState === 'Complete') {
          EverTrue.Alert.success('Video trim is complete');
          setPollTrim(false);
          setTrimLoading(false);
          setTrimVideoPath(videoPath);
          setSelectedVideo({
            ...selectedVideo,
            libraryVideo: {
              ...selectedVideo.libraryVideo,
              video_path: videoPath,
              trim_scheduled: false,
              trim_executing: false,
            },
          });
          return;
        }

        // Check if we've exceeded the maximum polling duration
        if (Date.now() - pollStartTime > MAX_POLL_DURATION) {
          EverTrue.Alert.error('Video trim is taking longer than expected. Please check back later.');
          setPollTrim(false);
          setTrimLoading(false);
          return;
        }

        // Continue polling if still processing
        timeoutId = setTimeout(getTrimStatus, 5000);
      } catch (error) {
        if (!isSubscribed) return;
        console.error('Error polling trim status:', error);
        setPollTrim(false);
        setTrimLoading(false);
      }
    };

    if (pollTrim) {
      getTrimStatus();
    }

    // Cleanup function
    return () => {
      isSubscribed = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, client_id, client_secret, pollTrim, selectedVideo]);

  const applyTrim = async (startTrim, endTrim) => {
    setTrimLoading(true);
    const res = await trimVideo(id, { startTrim, endTrim }, client_id, client_secret);

    if (res?.success) {
      setPollTrim(true);
      EverTrue.Alert.success('Your video is being trimmed...');
    } else {
      EverTrue.Alert.error('There was a problem trimming your video.');
      setTrimLoading(false);
    }
  };

  const revertTrimmedVideo = async () => {
    try {
      const res = await revertTrim(id, client_id, client_secret);
      if (res?.success) {
        EverTrue.Alert.success('Video trim reverted successfully');
        setSelectedVideo({
          ...selectedVideo,
          libraryVideo: { ...selectedVideo.libraryVideo, video_path: video_untrimmed },
        });
      }
    } catch (error) {
      EverTrue.Alert.error('There was a problem reverting your trim.');
    }
  };

  return {
    trimLoading,
    trimVideoPath,
    applyTrim,
    revertTrimmedVideo,
  };
};

export default useTrimVideo;
