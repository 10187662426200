import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@evertrue/et-components';
import { useRecorder } from './hooks/recorder-context';
import EverTrue from 'app';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const PATH = getLinkUrl('thankView', 'copyVideoPathUrl');

const CopyVideo = ({ videoThumbnail = '', copyIsEnabled, settings }) => {
  const { selectedVideo, stopAndResetRecorder } = useRecorder();
  const { handleCloseThankviewModal } = useSignalVideoModalContext();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (selectedVideo) {
      const link = `${PATH}${selectedVideo.personalVideo.id_string}`;
      setUrl(link);
    }
  }, [selectedVideo]);

  async function copyGifThumbnailWithHtml(gifUrl) {
    try {
      let htmlToAppend = `
        <div style="text-align: center; width: 100%; max-width: 600px; margin: 0 auto; font-family: 'Lato', sans-serif;">
          <a href="${url}" style="color: #0645AD; text-decoration: none;">
            <img 
              src="${
                selectedVideo.libraryVideo.animated_thumb_path
                  ? selectedVideo.libraryVideo.animated_thumb_path
                  : selectedVideo.libraryVideo.video_thumb
              }" 
              alt="Video Thumbnail" 
              style="display: block; margin: 0 auto; margin-bottom: 15px; cursor: pointer;"
            >
          </a>
          </br>
          </br>
          <p style="font-size: 16px; line-height: 1.5; color: #333; margin: 20px 0; text-align: center; width: 100%; padding: 10px 20px; display: inline-block;">
            Click <a href="${url}" style="color: #0645AD; text-decoration: none;">here</a> to see your Signal Video!
          </p>
      `;

      if (settings.button_text && settings.show_in_email) {
        let btnHTML = `
        </br>    
        </br>    
        <a 
              href="${settings.button_url}" 
              target="_blank" 
              rel="noopener noreferrer" 
              style="background-color: #3367F2; color: #fff; display: flex; justify-content: center; align-items: center; padding: 10px 20px; border-radius: 100px; text-decoration: none; font-size: 16px; width: fit-content; font-weight: 400; margin: 20px auto;"
            >
              ${settings.button_text.trim()}
            </a>
          </div>`;
        htmlToAppend += btnHTML;
      } else htmlToAppend += '</div>';

      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlToAppend], { type: 'text/html' }),
      });

      // Write to clipboard
      await navigator.clipboard.write([clipboardItem]);
      return true;
    } catch (err) {
      console.error('Failed to copy: ', err);
      throw err;
    }
  }

  const handleCopyClick = async () => {
    try {
      await copyGifThumbnailWithHtml();
      handleCloseThankviewModal(stopAndResetRecorder);
      EverTrue.Alert.success('Successfully Copied!');
    } catch (error) {
      EverTrue.Alert.error('There was an error copying the video.');
    }
  };

  return (
    <>
      <Button onClick={handleCopyClick}>
        <Icon icon="content-copy" iconKit="gt2" className="tv-btn-icon" />
        Copy Video
      </Button>
    </>
  );
};

CopyVideo.propTypes = {
  videoThumbnail: PropTypes.string,
  copyIsEnabled: PropTypes.bool,
  settings: PropTypes.object,
};

export default CopyVideo;
