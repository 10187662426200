import PropTypes from 'prop-types';
import OutreachCard from './outreach-card';

const OutreachScheduleCard = ({ scheduled, selectedScheduled, setSelectedScheduled, discard }) => {
  return (
    <OutreachCard
      outreachEmail={scheduled}
      selectedOutreaches={selectedScheduled}
      setSelectedOutreaches={setSelectedScheduled}
      outreachType="Scheduled"
      discard={discard}
    />
  );
};

OutreachScheduleCard.propTypes = {
  scheduled: PropTypes.object.isRequired,
  selectedScheduled: PropTypes.array,
  setSelectedScheduled: PropTypes.func,
  discard: PropTypes.func,
};

export default OutreachScheduleCard;
