import * as Yup from 'yup';

export const EditTaskDueDateSchema = Yup.object().shape({
  reason: Yup.object().required('Reason is required'),
  reasonDetails: Yup.string().when('reason', {
    is: (reason) => reason && reason.value === 'Other',
    then: Yup.string().required('Details are required').max(150, 'Details must be 150 characters or less'),
    otherwise: Yup.string().notRequired(),
  }),
});
