import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useOpenClose, Modal, ModalHeader, ModalBody, Button } from '@evertrue/et-components';

const RESUME_PAUSE_TITLE = 'Resume Cadences and Tasks';
const RESUME_PAUSE_BODY =
  "Are you ready to come back? Your cadences and tasks will resume where you left off, and we'll reactivate your tasks starting with those due today.";
const RESUME_PAUSE_ACTION = 'Resume';

const ResumePauseModalButton = ({ handleResume = () => {}, updateTaskCount = () => {} }) => {
  const [isOpen, openModal, closeModal] = useOpenClose();

  const resumeAction = async () => {
    trackJourneysAction('end_cadence_pause');
    await handleResume();
    updateTaskCount();
    closeModal();
  };

  return (
    <>
      <Button type="secondary" onClick={() => openModal()}>
        Resume Cadences and Tasks
      </Button>
      <Modal isOpen={isOpen} closeModal={closeModal} size="small">
        <ModalHeader title={RESUME_PAUSE_TITLE} />
        <ModalBody>{RESUME_PAUSE_BODY}</ModalBody>
        <div className="confirm-modal-actions">
          <Button type="simple" onClick={closeModal}>
            Cancel
          </Button>
          <div style={{ margin: '0 20px' }}>
            <Button onClick={() => resumeAction()}>{RESUME_PAUSE_ACTION}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ResumePauseModalButton.propTypes = {
  handleResume: PropTypes.func,
  updateTaskCount: PropTypes.func,
};

export default ResumePauseModalButton;
