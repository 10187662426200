import { PropTypes } from 'base/react-libs';
import { generateUrl } from 'apps/home/components/next-steps/recommended-actions/sections/filters/filters-parser-util';
import { useIdentity } from 'base/identity-resolver';
import { useEffect, useState } from 'react';
import { getUserContactIdForOrg } from 'apps/tasks/utils/contact-helpers';
import Api from 'entities/helpers/api';
import EverTrue from 'app';

const useFeaturedLinks = (filters = []) => {
  const { user, org } = useIdentity();
  const [solicitorName, setSolicitorName] = useState('John Doe');
  const [featuredLinks, setFeaturedLinks] = useState([]);
  const [featuredSegments, setFeaturedSegments] = useState([]);
  const [solicitorContactId, setSolicitorContactId] = useState(getUserContactIdForOrg(user, org));

  useEffect(() => {
    if (solicitorContactId) {
      // Query GraphQL for contact details
      Api.GRAPHQL.GRAPHQL.post({
        data: JSON.stringify({
          query: `
            query ($ids: [BigInt!]) {
              contacts(ids: $ids) {
                id
                contact_attributes {
                  name_full
                }
              }
            }
          `,
          variables: { ids: [solicitorContactId] },
        }),
      })
        .then((resp = {}) => {
          const contacts = resp?.data?.contacts || [];

          const contact = contacts.find((contact) => {
            if (!contact || !contact?.id) return false;
            return String(contact.id) === String(solicitorContactId);
          });

          setSolicitorName(contact?.contact_attributes?.name_full || user?.name || 'John Doe');
        })
        .catch((error) => {
          console.error('Error fetching contact details:', error);
        });
    } else {
      setSolicitorName('John Doe');
      setSolicitorContactId(1);
    }
  }, [solicitorContactId, user]);

  useEffect(() => {
    // Update featuredSegments whenever solicitorName or solicitorContactId changes
    const updatedSegments = filters.map((segment) => ({
      ...segment,
      segments: segment.segments.map((item) => {
        const updatedFilters = item.filter.filters.map((filter) => {
          if (filter.filter_id === '12.6') {
            return {
              ...filter,
              value: [
                {
                  label: solicitorName,
                  value: solicitorContactId,
                },
              ],
            };
          }
          return filter;
        });

        return {
          ...item,
          filter: {
            ...item.filter,
            filters: updatedFilters,
          },
        };
      }),
    }));

    setFeaturedSegments(updatedSegments);
  }, [filters, solicitorContactId, solicitorName]);

  useEffect(() => {
    const links = featuredSegments.map((section) => (
      <div style={section.sectionStyle} key={section.id}>
        <strong>{section.title}</strong>
        {section.segments.map((segment) => {
          const url = generateUrl('contact', segment.filter);

          const handleLinkClick = () => {
            if (segment.checkSolicitor && solicitorContactId === 1) {
              EverTrue.Alert.warn('No associated solicitor contact, defaulting to John Doe');
            }
          };

          return segment.component(segment, url, handleLinkClick);
        })}
      </div>
    ));
    setFeaturedLinks(links);
  }, [featuredSegments, solicitorContactId]);

  return featuredLinks;
};

useFeaturedLinks.propTypes = {
  filters: PropTypes.array,
  position: PropTypes.string,
};

export default useFeaturedLinks;
