import { PropTypes } from 'base/react-libs';
import { PageHeader, Icon, Button } from '@evertrue/et-components';
import EverTrue from 'app';
import JourneyActionMenu from '../top-nav/journeys-action-menu';

const JourneyDetailBanner = ({ journey = {} }) => (
  <div className="journeys-banner">
    <PageHeader
      title={journey?.name || 'Cadence'}
      className="journey-details-title"
      subtitle={<span className="dashboard-days--subtext">{journey?.objective_description || ''}</span>}
      onBackClick={() => {
        EverTrue.UrlManager.navigate('/cadences', true, false);
      }}
      breadcrumbs={[
        <span key={0} style={{ lineHeight: 'normal' }}>
          {`Cadence (Owned by ${journey?.owner?.name || 'EverTrue'})`}
        </span>,
      ]}
    />
    <div className="journeys-banner--action-buttons">
      <JourneyActionMenu journey={journey} />
      <Button type="simple" href="settings/cadences">
        <Icon icon="gear" size={1} className="homepage-settings-icon" />
      </Button>
    </div>
  </div>
);

JourneyDetailBanner.propTypes = {
  journey: PropTypes.object,
};

export default JourneyDetailBanner;
