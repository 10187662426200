import { getDateString } from 'base/new-utils';
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const TABS = [
  { id: 1, label: 'Your Favorites' },
  { id: 2, label: 'Created by You' },
  { id: 3, label: 'All Cadences' },
];

const GlobalJourneyContext = createContext(() => {});

/**
 * This context has been added to the GlobalProviderController as another slice of global state.
 *
 * It tracks things related to journeys ("cadences") that need to be shared across multiple components.
 * This should be an absolute last-resort for sharing state, so please add to it sparingly
 *
 */
const GlobalJourneyProvider = ({ children }) => {
  const [globalJourney, setGlobalJourney] = useState({});
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [onlyJourneysWithStepsDue, setOnlyJourneysWithStepsDue] = useState(true);
  const [selectedDueDate, setSelectedDueDate] = useState(getDateString());
  const [navigateFrom, setNavigateFrom] = useState(null);

  return (
    <GlobalJourneyContext.Provider
      value={{
        selectedTab,
        globalJourney,
        onlyJourneysWithStepsDue,
        selectedDueDate,
        setSelectedTab,
        setGlobalJourney,
        setOnlyJourneysWithStepsDue,
        setSelectedDueDate,
        TABS,
        setNavigateFrom,
        navigateFrom,
      }}
    >
      {children}
    </GlobalJourneyContext.Provider>
  );
};

const useGlobalJourneyProvider = () => {
  const context = useContext(GlobalJourneyContext);
  if (!context) {
    throw new Error('useGlobalJourneyProvider must be used within a GlobalJourneyProvider');
  }
  return context;
};

GlobalJourneyProvider.propTypes = {
  children: PropTypes.any,
};

export { GlobalJourneyProvider, GlobalJourneyContext, useGlobalJourneyProvider };
