import _ from 'underscore';
import Api from 'entities/helpers/api';
import { useState, useEffect, Fragment } from 'react';
import EverTrue from 'app';
import { connect } from '@evertrue/et-flux';
import { AdvancedCombobox, Loading } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import Decorator from 'clientDecorator';
import { useIdentity } from 'base/identity-resolver';
import DashboardNav from 'apps/dashboard/components/dashboard-nav';
import TeamDashboardWidgets from 'apps/dashboard/components/team-dashboard-widgets';
import PageHeader from 'apps/layout/components/page-header';
import ActionMenuItem from 'components/controls/action-menu-item';
import DNAStore from 'base/dna/dna-store';
import { TEAM_TAB_KEY } from 'apps/dashboard/constants';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const gql = String.raw;
const query = gql`
  query {
    organization {
      id
      pools_pagination(page: 1, perPage: 100) {
        count
        items {
          id
          name
        }
      }
    }
  }
`;

const mapStateToProps = () => ({
  fiscalYearEnd: DNAStore.getFiscalYearEnd(),
  daysUntilFiscalYearEnd: DNAStore.getDaysUntilFiscalYearEnd(),
});

const defaultTeam = (user, orgId, firstOption) => {
  const stored_id = parseInt(localStorage.getItem(TEAM_TAB_KEY), 10);
  const user_contact_id = Decorator.User.getContactId(user, orgId);

  // if the team was saved in local storage
  if (!_.isNaN(stored_id)) {
    return stored_id;
    // else if user has a contact_id, default based on user's contact id memberships
  } else if (user_contact_id) {
    Api.VOLUNTEER.MEMBERSHIP.get({
      urlArgs: {
        contact_id: user_contact_id,
        type: 'solicitor',
      },
      params: { pool_type: 'TEAM' },
    }).then((resp) => {
      const team_id = resp[0] ? resp[0].pool_id : null;
      return team_id;
    });
    // else just return first option in list
  } else {
    return firstOption;
  }
};

// dashboard for teams
const TeamDashboardController = (props) => {
  const [teamId, setTeamId] = useState();
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [teamOptions, setTeamOptions] = useState([]);
  const { user, org } = useIdentity();
  const fy = moment(props.fiscalYearEnd).year();

  // Load team options and determine what team to default to
  useEffect(() => {
    setLoadingTeams(true);
    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: query,
      }),
    })
      .then((resp = {}) => {
        const pools = resp.data.organization.pools_pagination.items;
        const sorted_pools = _.sortBy(pools, 'name');
        const options = _.map(sorted_pools, (pool) => ({ value: pool.id, label: pool.name }));
        setTeamOptions(options);
        setLoadingTeams(false);
        setTeamId(defaultTeam(org.id, user, options[0].value));
      })
      .catch(() => {
        setLoadingTeams(false);
      });
  }, [org.id, user]);

  const dashboardActions = () => {
    return (
      <Fragment>
        <ActionMenuItem
          title="Launchpad Help"
          icon="question-circle"
          openNewTab={true}
          href={getLinkUrl('everTrue', 'launchpadHelpCenterUrl')}
        >
          Go to Launchpad Help
        </ActionMenuItem>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <PageHeader
        title="Team Launchpad"
        subtitle={
          <div>
            <span className="dashboard-days">{props.daysUntilFiscalYearEnd}</span>
            <span className="dashboard-days--subtext"> Days left in FY</span>
          </div>
        }
        actions={dashboardActions()}
      />
      <DashboardNav tab="team" />

      <div className="dashboard--toolbar-wrapper">
        <div className="dashboard--toolbar">
          <AdvancedCombobox
            label="Team name"
            placeholder="Select team..."
            options={teamOptions}
            value={{ value: teamId }}
            onChange={(selected_option) => {
              EverTrue.track.set('launchpad_action', {
                type: 'team_view_switch',
                referrer: 'Team',
              });

              localStorage.setItem(TEAM_TAB_KEY, selected_option.value);
              setTeamId(selected_option.value);
            }}
            className="dashboard--solicitor-dropdown"
          />
        </div>
      </div>

      <div className="dashboard-widgets">
        {loadingTeams ? (
          <Loading className="dashboard--loading" />
        ) : (
          <TeamDashboardWidgets fiscal_year={fy} team_id={teamId} />
        )}
      </div>
    </Fragment>
  );
};

TeamDashboardController.propTypes = {
  fiscalYearEnd: PropTypes.string,
  daysUntilFiscalYearEnd: PropTypes.number,
};

export default connect(TeamDashboardController, [DNAStore], mapStateToProps);
