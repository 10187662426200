import SolicitorInviteSource from 'apps/volunteers/sources/solicitor-invite-source';
import { createStore } from '@evertrue/et-flux';

export default createStore('SolicitorInviteStore', {
  getInitialState() {
    return {
      loading: false,
      invites_by_contact_id: {},
      ids_in_flight: new Set(),
    };
  },

  registerActions() {
    this.on(SolicitorInviteSource.actions.loading, this.respondToLoading);
    this.on(SolicitorInviteSource.actions.fetchedInvites, this.respondTofetchedInvites);
    this.on(SolicitorInviteSource.actions.sendAppInviteSuccess, this.respondSendAppInviteSuccess);
    this.on(SolicitorInviteSource.actions.fetchIfNotCached, (contact_ids) => {
      const not_cached_ids = contact_ids.filter((id) => {
        return !this.state.invites_by_contact_id[id] && !this.state.ids_in_flight.has(id);
      });

      if (not_cached_ids.length) {
        SolicitorInviteSource.fetch(not_cached_ids);
      }

      const ids = new Set(this.state.ids_in_flight);
      contact_ids.forEach((id) => ids.add(id));
      this.setState({ ids_in_flight: ids });
    });
  },

  respondToLoading(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondTofetchedInvites(invite_array, contact_ids) {
    const existing_invites = this.getState('invites_by_contact_id');
    const invites_by_contact_id = invite_array.reduce(
      (accum, invite) => ({ ...accum, [invite.contact_id]: invite }),
      existing_invites
    );

    const contact_ids_in_flight = new Set(this.state.ids_in_flight);
    contact_ids.forEach((id) => contact_ids_in_flight.add(Number(id)));

    this.setState({ invites_by_contact_id, contact_ids_in_flight });
  },

  respondSendAppInviteSuccess(res, contact_id) {
    let invites_by_contact_id = { ...this.getState('invites_by_contact_id') };
    if (res) {
      invites_by_contact_id[contact_id] = res;
    } else {
      const { [contact_id]: removed, ...rest } = invites_by_contact_id;
      invites_by_contact_id = rest;
    }
    this.setState({ invites_by_contact_id });
  },

  api: {
    getLoading() {
      return this.getState('loading');
    },

    getSentInvites() {
      return this.getState('invites_by_contact_id');
    },

    getSentInvitesForContactIds(contact_ids = []) {
      return contact_ids.reduce((accum, id) => ({ ...accum, [id]: this.state.invites_by_contact_id[id] }), {});
    },
  },
});
