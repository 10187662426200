import Api from 'entities/helpers/api';
import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const getAllScheduledByUser = async () => {
  try {
    const res = await Api.OUTREACH.SCHEDULED.get();
    return res;
  } catch (e) {
    ErrorLogger.error('Error getting scheduled emails', { extra: { e, page_name: 'Outreach Scheduled Emails' } });
  }
};

export const createScheduledSend = async (payload, scheduledDateAndTime) => {
  try {
    const res = await Api.OUTREACH.SCHEDULED.post({
      data: JSON.stringify(payload),
    });
    EverTrue.Alert.success(`Send Scheduled for ${scheduledDateAndTime}`);
    return res;
  } catch (e) {
    ErrorLogger.error('Error creating scheduled email');
  }
};

export const bulkDeleteScheduled = async (selectedScheduledIds) => {
  try {
    await Api.OUTREACH.BULK_DELETE_SCHEDULED.delete({
      data: JSON.stringify(selectedScheduledIds),
    });
    return EverTrue.Alert.success(`Scheduled emails deleted successfully.`);
  } catch (e) {
    ErrorLogger.error('Error deleting scheduled emails', {
      extra: { error: e, page_name: 'Bulk Delete Scheduled Emails' },
    });
  }
};

export const deleteScheduledById = async (scheduledEmailId) => {
  try {
    await Api.OUTREACH.SCHEDULED_BY_ID.delete({
      urlArgs: { id: scheduledEmailId },
    });
  } catch (e) {
    ErrorLogger.error('Error deleting scheduled email', { extra: { error: e, page_name: 'Delete Scheduled Send' } });
  }
};

export const updateScheduledSend = async (payload, id) => {
  try {
    const res = await Api.OUTREACH.SCHEDULED_BY_ID.put({
      urlArgs: { id: id },
      data: JSON.stringify(payload),
    });
    EverTrue.Alert.success(`Scheduled email updated successfully.`);
    return res;
  } catch (e) {
    ErrorLogger.error('Error while updating scheduled email', { extra: { e, page_name: 'Update Scheduled Send' } });
  }
};

export const cancelScheduledEmail = async (selectedScheduledId) => {
  try {
    await Api.OUTREACH.CANCEL_SCHEDULED.put({
      urlArgs: { id: selectedScheduledId },
    });
    return EverTrue.Alert.success(`Scheduled email cancelled successfully.`);
  } catch (e) {
    ErrorLogger.error('Error cancelling scheduled email', { extra: { error: e, page_name: 'Cancel Scheduled Email' } });
  }
};
