import { IconButton, LinkButton, ButtonTooltip } from "@evertrue/et-components";
import Api from "entities/helpers/api";
import EverTrue from "app";

const gql = String.raw;

const ContactInterestsList = ({ interests, contactId, onDelete }) => {
  const handleMutation = (interestId, updatedAt, rejecting) => {
    const profileMutation = {
      contact_interests: [{ id: interestId, rejected: rejecting, updated_at: updatedAt }],
    };

    const query = gql`
      mutation ($id: BigInt!, $enrichment: EnrichmentInput) {
        contact_mutation(id: $id, enrichment: $enrichment) {
          code
          enrichment_mutation {
            contact_interests_mutations {
              success
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contactId, enrichment: profileMutation },
      }),
    })
      .then(() => {
        rejecting ? EverTrue.Alert.success("Interest Removed") : EverTrue.Alert.success("Interest Restored");
        onDelete();
      })
      .catch(() => {
        rejecting
          ? EverTrue.Alert.error("Problem removing interest record. Please try again later")
          : EverTrue.Alert.error("Problem restoring interest record. Please try again later");
      });
  };
  return interests.map(({ id, interest_name, updated_at, rejected }) => (
    <div className="profile-enrichment--list contact-interests-enrichment" key={id}>
      <div className="profile-enrichment-item">
        <p>{interest_name}</p>
        {rejected ? (
          <ButtonTooltip
            children={"Restore interest"}
            contentPadding={"4px 8px"}
            trigger={<LinkButton onClick={() => handleMutation(id, updated_at, !rejected)}>Undo</LinkButton>}
          />
        ) : (
          <ButtonTooltip
            children={"Delete interest"}
            contentPadding={"4px 8px"}
            trigger={
              <IconButton
                title={`Delete Interest`}
                icon="clear"
                className={"profile--enrichment-delete-button"}
                onClick={() => handleMutation(id, updated_at, !rejected)}
              />
            }
          ></ButtonTooltip>
        )}
      </div>
    </div>
  ));
};

export default ContactInterestsList;
