import { useState } from 'react';
import { FlexTable, Modal, useOpenClose } from '@evertrue/et-components';
import { useIdentity } from 'base/identity-resolver';
import { getUserContactIdForOrg } from 'apps/tasks/utils/contact-helpers';
import useSuggestedTasks from 'apps/tasks/hooks/use-suggested-tasks';
import useTasks from 'apps/tasks/hooks/use-tasks';
import AiTaskHeaderDescription from './suggested-task/ai-task-header-description';
import AiTaskConstituent from './suggested-task/ai-task-constituent';
import AiTaskReviewButton from './suggested-task/ai-task-review-button';
import { getIconByActionType } from 'apps/tasks/utils/tasks-helpers';
import Icon from 'components/elements/icon';
import ReviewSuggestedTaskForm from '../create/review-suggested-task-form';

const TasksSuggestionsTable = () => {
  const { user, org } = useIdentity();
  const contactId = getUserContactIdForOrg(user, org);
  const { aiTasks: tasks, isStreaming, enabled } = useSuggestedTasks(contactId);
  const { parseActionType } = useTasks();
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const formatTaskForForm = (task) => {
    if (!task) return null;
    const formattedTask = {
      ...task,
      action_type: task.action_type.toUpperCase(),
      current_due_date: '',
    };
    return formattedTask;
  };

  const columns = [
    {
      key: 1,
      width: 350,
      cellClassName: 'ai-task-title--container',
      headerClassName: 'hidden-header',
      accessor: (task) => {
        const { title, description } = task;
        return (
          <AiTaskHeaderDescription
            title={title}
            description={description}
            onClick={() => {
              setSelectedTask(task);
              isModalOpen ? closeModal() : openModal();
            }}
          />
        );
      },
    },
    {
      key: 2,
      width: 130,
      headerClassName: 'hidden-header',
      accessor: (task) => {
        const { target_contact_id: targetId } = task;
        return <AiTaskConstituent targetId={targetId} isStreaming={isStreaming} />;
      },
    },
    {
      key: 3,
      width: 125,
      headerClassName: 'hidden-header',
      accessor: () => {
        const today = new Date();
        const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        const formattedDate = nextWeek.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'long' });
        return <p className="suggested-task-body-text">{formattedDate}</p>;
      },
    },
    {
      key: 4,
      width: 90,
      headerClassName: 'hidden-header',
      accessor: (task) => {
        const { action_type: actionType } = task;
        const parsedActionType = parseActionType(actionType.toUpperCase());

        return (
          <div className="ai-task-details--container">
            <Icon icon={getIconByActionType(parsedActionType)} iconKit="gt2" size={1} className="action-type-icon" />
            <p className="suggested-task-body-text">{parsedActionType}</p>
          </div>
        );
      },
    },
    {
      key: 5,
      width: 80,
      headerClassName: 'hidden-header',
      accessor: (task) => {
        const { title } = task;
        return (
          <AiTaskReviewButton
            title={title}
            onClick={() => {
              setSelectedTask(task);
              isModalOpen ? closeModal() : openModal();
            }}
          />
        );
      },
    },
  ];

  if (!enabled) {
    return (
      <div className="ai-disabled--container">
        <img src="/images/ai-icon.png" alt="ai-icon-img" height={40} />
        <h3>Enable Suggested Tasks</h3>
        <p>
          EverTrue's new “Suggested Tasks” feature can make suggestions for followup tasks based on the recent
          interactions tied to your assigned constituents. We're making your to-do list easy and boosting constituent
          engagement like never before!
        </p>
        <p>Contact your EverTrue representative to learn more and activate this feature.</p>
      </div>
    );
  }

  if (!isStreaming && tasks.length === 0) {
    return (
      <div className="ai-disabled--container">
        <img src="/images/ai-icon.png" alt="ai-icon-img" height={40} />
        <h3>Suggested Tasks</h3>
        <p>
          EverTrue's new "Suggested Tasks" feature can make suggestions for followup tasks based on the recent
          interactions tied to your assigned constituents.
        </p>
        <p>
          We don't have any new tasks to suggest for you today, but keep adding interactions, and we'll review them to
          make task suggestions moving forward.
        </p>
      </div>
    );
  }
  return (
    <>
      <div className="dynamic-lists-controller">
        <div className="ai-tasks-lists-table">
          <div className="suggested-task-table-header">
            <img src="/images/ai-icon.png" alt="ai-header-icon-img" className="ai-header-icon" />
            <p className="header-copy">
              AI-Suggested Tasks: Tailored from your recent interactions! Review, customize, and save them as your own
              to boost your productivity.
            </p>
          </div>
          <FlexTable
            caption="Lists"
            emptyStateMessageText={'No tasks found'}
            columns={columns}
            data={tasks.slice(0, 50)}
            loading={isStreaming}
          />
        </div>
      </div>
      <>
        <Modal
          isOpen={isModalOpen}
          closeModal={() => {
            closeModal();
          }}
        >
          <ReviewSuggestedTaskForm
            handleClose={() => {
              closeModal();
            }}
            taskObj={formatTaskForForm(selectedTask)}
            contactId={selectedTask && selectedTask.target_contact_id}
          />
        </Modal>
      </>
    </>
  );
};

export default TasksSuggestionsTable;
