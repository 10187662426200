import { PropTypes } from 'base/react-libs';
import TaskCountTable from './task-count-table';
import Loading from 'components/elements/loading';

const TaskCount = ({ title, subTitle, showIcon, icon, data, loadingCounts }) => {
  return (
    <div className="task-counter-content">
      <div className="task-card-padding">
        <div className="task-counter-home--title">{title}</div>
        {loadingCounts ? (
          <div style={{ paddingTop: '18px', height: '42px', marginBottom: '10px', marginTop: '10px' }}>
            <Loading spinner_size={'small'} className={'loader-icon'} />
          </div>
        ) : (
          <>
            <div className="task-counter-home--subtitle">{subTitle}</div>
            {showIcon ? (
              <table className="task-counter-card--details--icon">
                <tbody className="task-counter-check-icon-tb">
                  <tr>
                    <td>
                      <img src={icon} alt="icon" style={{ cursor: 'pointer' }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <TaskCountTable data={data} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

TaskCount.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showIcon: PropTypes.bool,
  icon: PropTypes.string,
  data: PropTypes.object,
  loadingCounts: PropTypes.bool,
};

export default TaskCount;
