import { Icon } from '@evertrue/et-components';
import { useState, useRef, useEffect } from 'react';
import { useRecorder } from './hooks/recorder-context';

const ResolutionComponent = () => {
  const { resolutions, selectedResolution, changeResolution } = useRecorder();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleResolutionChange = (newResolution) => {
    changeResolution(newResolution);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!resolutions || resolutions.length === 0) {
    return <div>No resolutions available</div>;
  }

  return (
    <div className="resolution-dropdown" ref={dropdownRef}>
      <button type="button" onClick={toggleDropdown} className="device-selector-trigger">
        <Icon title="Change Resolution" icon="cog" iconKit="tv" className="tv-dropdown-item-icon" />
      </button>
      {isOpen && (
        <ul className="resolution-dropdown-menu">
          {resolutions.map(({ width, height }) => (
            <li
              key={`${width}x${height}`}
              onClick={() => handleResolutionChange(`${width}x${height}`)}
              className={selectedResolution === `${width}x${height}` ? 'active' : ''}
            >
              {`${width}p`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ResolutionComponent;
