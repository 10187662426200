import numeral from 'numeral';
import KpiCard from 'components/containers/kpi-card';
import PropTypes from 'prop-types';

const CompanyParticipation = ({ data }) => {
  const percent = (prop) => {
    const key = data?.[prop];
    if (key && key !== 'failed') {
      let num = key.numerator / key.denominator || 0;
      if (!Number.isFinite(num)) {
        num = 0;
      }
      return numeral(num).format('0.00%');
    } else {
      return <span className="text-placeholder-light">Not Provided</span>;
    }
  };

  const ratio = (prop) => {
    const key = data?.[prop];
    if (key && key !== 'failed') {
      return (
        <div>
          {key.numerator} of {key.denominator}
        </div>
      );
    }
  };

  const year = new Date().getFullYear();

  return (
    <div className="company-participation-cards fs-exclude-data">
      <KpiCard title="Lifetime Participation" value={percent('lifetime_participation')} width={33}>
        {ratio('lifetime_participation')}
      </KpiCard>

      <KpiCard title={`FY ${year}`} value={percent('current_year')} width={33}>
        {ratio('current_year')}
      </KpiCard>

      <KpiCard title={`FY ${year - 1}`} value={percent('last_year')} width={33}>
        {ratio('last_year')}
      </KpiCard>
    </div>
  );
};

CompanyParticipation.propTypes = {
  data: PropTypes.object,
};

export default CompanyParticipation;
