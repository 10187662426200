import { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import _ from 'underscore';
import PagedColumnTableHeader from 'components/tables/paged-col-table-header';
import PagedColumnTableBody from 'components/tables/paged-col-table-body';
import PropTypes from 'prop-types';

const PagedColumnTable = (props) => {
  const { className, columns, columnWidth, rows, visible, onRowClick, onSort, emptyMessage, data, syncing, sortData } =
    props;
  const fullTableRef = useRef(null);
  const [start, setStart] = useState(0);
  const [visibleCount, setVisibleCount] = useState(0);
  const responsiveDelay = useRef(null);

  useEffect(() => {
    const respond = () => {
      clearTimeout(responsiveDelay.current);
      responsiveDelay.current = _.delay(() => {
        const tableWidth = $(fullTableRef.current).width();
        let numOfStickyCols = 0;
        const stickyColWidthTotal = _.reduce(
          columns,
          (memo, col) => {
            if (col.sticky) {
              numOfStickyCols += 1;
              return memo + (col.width || 0);
            }
            return memo;
          },
          0
        );
        const columnSpace = tableWidth - stickyColWidthTotal;

        let columnsWidth = 0;
        const visibleColumns = _.filter(columns, (config) => {
          const nextWidth = columnsWidth + parseInt(config.width || columnWidth || 175);
          if (nextWidth >= columnSpace || config.sticky) return false;
          columnsWidth = nextWidth;
          return true;
        });

        const numberOfCols = visibleColumns.length + numOfStickyCols;
        let newStart = start;
        if (columns.length <= start + numberOfCols) {
          newStart = columns.length - numberOfCols;
        }

        setVisibleCount(numberOfCols);
        setStart(newStart);
      }, 200);
    };

    $(window).resize(respond);
    respond();

    return () => {
      clearTimeout(responsiveDelay.current);
      $(window).off('resize', respond);
    };
  }, [columns, columnWidth, start]);

  const getVisibleColumns = () => {
    const sticky = _.filter(columns, (col) => col.sticky);
    const startIndex = start + sticky.length;
    const last = startIndex + (visibleCount - sticky.length - 1);
    return _.flatten([sticky, columns.slice(startIndex, last + 1)]);
  };

  const handleNext = () => {
    const last = columns.length - (visibleCount || visible);
    setStart(Math.min(start + 1, last));
  };

  const handlePrev = () => {
    setStart(Math.max(start - 1, 0));
  };

  const classes = `table table-hover table-sort paged-col-table ${className}`;

  return (
    <table className={classes} ref={fullTableRef}>
      <PagedColumnTableHeader
        visibleColumns={getVisibleColumns()}
        columns={columns}
        beginIndex={start}
        sortData={sortData}
        columnWidth={columnWidth}
        onPrev={handlePrev}
        onNext={handleNext}
        onSort={onSort}
      />
      <PagedColumnTableBody
        data={data}
        syncing={syncing}
        sortProp={sortData?.sortProp}
        emptyMessage={emptyMessage}
        cells={rows}
        columns={getVisibleColumns()}
        onRowClick={onRowClick}
      />
    </table>
  );
};

PagedColumnTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.func.isRequired,
  visible: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRowClick: PropTypes.func,
  onSort: PropTypes.func,
  emptyMessage: PropTypes.any,
  data: PropTypes.array,
  syncing: PropTypes.bool,
  sortData: PropTypes.object,
};

export default PagedColumnTable;
