import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import { getDateInTimezone } from 'base/new-utils';

const DateRangePicker = ({
  range = { from: getDateInTimezone(), to: getDateInTimezone() },
  onDayClick = () => {},
  disabledDays = [],
  disableWeekends = false,
  numberOfMonths = 1,
}) => {
  const handleClick = (selection) => {
    const dateSelection = getDateInTimezone(selection);
    const newRange = DateUtils.addDayToRange(dateSelection, range);
    onDayClick(newRange);
  };

  const weekendsDisabledDays = disableWeekends
    ? { daysOfWeek: [0, 6] } // 0 for Sunday, 6 for Saturday
    : {};

  const combinedDisabledDays = Array.isArray(disabledDays)
    ? [...disabledDays, weekendsDisabledDays]
    : [disabledDays, weekendsDisabledDays];

  return (
    <DayPicker
      className="date-range-picker"
      numberOfMonths={numberOfMonths}
      disabledDays={combinedDisabledDays}
      selectedDays={[{ from: getDateInTimezone(range?.from), to: getDateInTimezone(range?.to) }]}
      onDayClick={(date, { disabled, selected, today }, event) => {
        if (disabled) return; // this means the user selected a disabled date.
        handleClick(date);
      }}
      modifiers={{ start: range?.from, end: range?.to }}
    />
  );
};

DateRangePicker.propTypes = {
  range: PropTypes.object,
  onDayClick: PropTypes.func.isRequired,
  disabledDays: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disableWeekends: PropTypes.bool,
  numberOfMonths: PropTypes.number,
};

export default DateRangePicker;
