import { PropTypes } from 'base/react-libs';
import { useEffect } from 'react';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import StepTable from './step-view/step-table';
import ActiveConstituentTable from './active-constituent-view/active-constituent-table';
import { useIdentity } from 'base/identity-resolver';

const JourneyDetailTable = ({ journey = {}, tableView, setTableView }) => {
  const { navigateFrom, setNavigateFrom } = useGlobalJourneyProvider();
  const { user } = useIdentity();

  useEffect(() => {
    if (navigateFrom) {
      if (!(user?.name === navigateFrom?.owner?.name)) {
        setTableView('activeConstituents');
      }
    }
  }, [navigateFrom, setNavigateFrom, user, tableView, setTableView]);

  return <>{tableView === 'steps' ? <StepTable /> : <ActiveConstituentTable journey={journey} />}</>;
};

JourneyDetailTable.propTypes = {
  journey: PropTypes.object.isRequired,
  setTableView: PropTypes.func,
  tableView: PropTypes.string,
};

export default JourneyDetailTable;
