import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Loading, Radio, ModalConfirm } from "@evertrue/et-components";
import { Formik } from "formik";
import get from "lodash.get";
import _ from "underscore";
import Decorator from "clientDecorator";
import EverTrue from "app";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import Composer from "react-composer";
import InvitesResolver from "apps/volunteers/components/invites-resolver";
import VolunteerUserResolver from "apps/volunteers/components/volunteer-user-resolver";
import SolicitorInviteSource from "apps/volunteers/sources/solicitor-invite-source";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import Api from "entities/helpers/api";
import EmailInput from "components/forms/email-input";
import { getProductTypes, getContactDataForVMSInvite } from "apps/volunteers/utils";
import { useGate } from "components/is-gated";

const fetchUsers = contact_ids => {
  return Api.AUTH.USER.post({
    urlExtend: "/bulk_fetch",
    data: JSON.stringify({ contact_id: contact_ids }),
    disableAlerts: true,
  });
};

const getFormattedError = (e, contact_data) => ({ error: get(e, "responseJSON.message"), contact_data });

const propTypes = {
  contacts: PropTypes.array,
  roleName: PropTypes.string,
  onClose: PropTypes.func,
};

const BulkInviteVolunteersModal = ({ contacts = [], roleName = "Volunteer", onClose, ...rest }) => {
  const contact_ids = useMemo(() => _.pluck(contacts, "id"), [contacts]);
  const [loading, setLoading] = useState(false);
  const [has_sso] = useGate("volunteer_sso");

  const [data = {}] = usePromiseResolver(() => fetchUsers(contact_ids), [contact_ids]);

  return (
    <Composer
      renderPropName="render"
      components={[
        // fetch community or et users
        // eslint-disable-next-line react/jsx-key
        <VolunteerUserResolver contactIds={contact_ids} cache />,
        // eslint-disable-next-line react/jsx-key
        <InvitesResolver contactIds={contact_ids} cache />,
      ]}
    >
      {([{ volunteer_users }, { invites }]) => {
        const { users: non_volunteer_users = [] } = data;
        const non_volunteer_user_map = _.indexBy(non_volunteer_users, "contact_id");

        const vols_to_invite = contacts.reduce((accum, curr) => {
          const { id: contact_id } = curr;
          const primary_email = get(Decorator.Profile.getPrimaryContactInfo(curr), "email.email");
          if (!volunteer_users[contact_id] && !invites[contact_id]) {
            accum[contact_id] = {
              email_type: "primary",
              contact: curr,
              primary_email,
              alt_email: "",
            };
          }
          return accum;
        }, {});

        return (
          <Formik
            validate={({ vols_to_invite }) => {
              if (_.isEmpty(vols_to_invite)) return {};

              const result = Object.entries(vols_to_invite).reduce((acc, [contact_id, contact]) => {
                const non_vol_user = non_volunteer_user_map[contact_id];
                if (non_vol_user && contact.email_type === "primary" && !non_vol_user.email) {
                  acc[contact_id] = "No email address for this user";
                  return acc;
                }
                if (contact.email_type === "primary" && !contact.primary_email) {
                  acc[contact_id] = "No email address for this user";
                  return acc;
                }
                if (
                  contact.email_type === "alt" &&
                  contact.alt_email.length > 3 &&
                  !_.validateEmail(contact.alt_email)
                ) {
                  acc[contact_id] = "Email address not valid";
                  return acc;
                }
                return acc;
              }, {});
              return result;
            }}
            initialValues={{ vols_to_invite }}
          >
            {({ errors, values, submitForm, handleSubmit, setFieldValue, setErrors }) => (
              <ModalConfirm
                disableSave={Boolean(Object.keys(errors).length)}
                header="Invite Volunteers"
                className="bulk-invite-volunteers-modal"
                closeModal={onClose}
                saveLabel="Send"
                onSubmit={async () => {
                  let asyncErrors = {};
                  setLoading(true);
                  const contact_data_arr = Object.entries(values.vols_to_invite).map(([key, obj]) => {
                    const user = non_volunteer_users[key] || {};
                    const { contact } = obj;
                    const contact_data = getContactDataForVMSInvite({ ...obj, user, contact, sso: has_sso });
                    return contact_data;
                  });

                  for (let contact_data_item of contact_data_arr) {
                    if (has_sso) {
                      await SolicitorInviteSource.promise.sendSSOAppInvite(contact_data_item).catch(e => {
                        asyncErrors[contact_data_item.contact_id] = getFormattedError(e, contact_data_item);
                      });
                    } else {
                      await SolicitorInviteSource.promise.sendAppInvite(contact_data_item, roleName, true).catch(e => {
                        asyncErrors[contact_data_item.contact_id] = getFormattedError(e, contact_data_item);
                      });
                    }
                  }
                  setLoading(false);
                  if (!Object.keys(asyncErrors).length) {
                    EverTrue.Alert.success("Invites sent");
                    onClose();
                  } else {
                    setErrors({ ...errors, ...asyncErrors });
                  }
                }}
              >
                <form className="bulk-invite-volunteers-modal--main" onSubmit={handleSubmit}>
                  {loading && <Loading />}
                  {// sort contacts w/ invites to the bottom
                  [...contacts]
                    .sort(contact => !vols_to_invite[contact.id])
                    .map(contact => {
                      const user = volunteer_users[contact.id];
                      const invite = invites[contact.id];
                      const primary_email = get(Decorator.Profile.getPrimaryContactInfo(contact), "email.email");
                      const current = values.vols_to_invite[contact.id] || {};
                      const non_vol_user = non_volunteer_user_map[contact.id];
                      const error = errors[contact.id];

                      return (
                        <div className="bulk-invite-volunteers-modal--contact-section" key={contact.id}>
                          <ContactCard
                            className="bulk-invite-volunteers-modal--contact-card"
                            key={contact.id}
                            contact={contact}
                          >
                            <>
                              {user && <div>Volunteer is already a user</div>}
                              {!user && invite ? <div>Volunteer has aready been invited</div> : null}
                              {!user && !invite && non_vol_user && (
                                <div>
                                  This constituent is a registered user of our {getProductTypes(non_vol_user)}.
                                  Volunteer invites will be sent to the email they used to authenticate previously.
                                </div>
                              )}
                              {error && <div className="badge-sq-error">Error: {error}</div>}
                            </>
                          </ContactCard>
                          {!invite && !non_vol_user && (
                            <div>
                              <Radio
                                className="app-invitation-modal--label"
                                checked={current.email_type === "primary"}
                                onChange={() =>
                                  setFieldValue("vols_to_invite", {
                                    ...values.vols_to_invite,
                                    [contact.id]: { ...current, email_type: "primary" },
                                  })
                                }
                              >
                                Primary email:
                              </Radio>
                              <span className="app-invitation-modal--primary-email">
                                {primary_email || "No primary email found"}
                              </span>
                              <Radio
                                className="app-invitation-modal--label"
                                onChange={alt_email =>
                                  setFieldValue("vols_to_invite", {
                                    ...values.vols_to_invite,
                                    [contact.id]: { ...current, email_type: "alt" },
                                  })
                                }
                                checked={current.email_type === "alt"}
                              >
                                Alternative Email Address
                              </Radio>
                              {current.email_type === "alt" && (
                                <EmailInput
                                  // Formik handles this validation
                                  valid={() => true}
                                  className="bulk-invite-volunteers-modal--alt-email-input"
                                  value={current.alt_email}
                                  onChange={alt_email =>
                                    setFieldValue("vols_to_invite", {
                                      ...values.vols_to_invite,
                                      [contact.id]: { ...current, alt_email },
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </form>
              </ModalConfirm>
            )}
          </Formik>
        );
      }}
    </Composer>
  );
};

BulkInviteVolunteersModal.propTypes = propTypes;

export default BulkInviteVolunteersModal;
