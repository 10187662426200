import * as Yup from 'yup';

export const CreateUpdateEmailSchema = Yup.object().shape({
  templateName: Yup.string()
    .trim()
    .min(4, 'Template Name is too short.')
    .max(120, 'Template Name is too long.')
    .required('Please enter a Template Name'),
  templateSubject: Yup.string()
    .trim()
    .min(4, 'Subject is too short.')
    .max(120, 'Subject is too long.')
    .required('Please enter a Subject'),
  templateBody: Yup.string().trim().nullable(),
});

