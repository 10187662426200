import Loading from 'components/elements/loading';

export default function LoginWaitController() {
  return (
    <div className="login--wait-container">
      <div className="login--content">
        <div className="login--spinner">
          <Loading position="center" />
        </div>
      </div>
    </div>
  );
}
