import EverTrue from "app";
import PropTypes from "prop-types";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';

const NavigationOutreachLink = ({ active_path = "" }) => {
  const { flagEnabled: hasDraftsAndScheduled } = useFeatureFlag('outreachDraftsScheduled');

  if (hasDraftsAndScheduled) {
    return (
      <NavigationSubItem
        icon="textsms-message"
        label="Outreach"
        isActive={!!active_path.match(new RegExp(`outreach.*`))}
        onClick={() => {
          EverTrue.Navigator("/outreach/email", true);
        }}
      />
    );
  } else {
    return null;
  }
};

NavigationOutreachLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationOutreachLink;
