export function getIconByActionType(actionType) {
  let icon = 'ai-chat';

  // Add logic to determine the icon based on the actionType
  switch (actionType) {
    case 'Email':
      icon = 'ai-email';
      break;
    case 'Call':
      icon = 'ai-phone';
      break;
    case 'Linkedin':
      icon = 'ai-linkedin';
      break;
    case 'Text':
      icon = 'ai-chat';
      break;
    case 'Research':
      icon = 'ai-research';
      break;
    case 'Other':
      icon = 'ai-other';
      break;
    default:
      icon = 'ai-chat';
      break;
  }
  return icon;
}

export const mapTasksByContactJourneyId = (tasks) => {
  return tasks.reduce((acc, task) => {
    const contactJourneyId = task?.contact_journey_id;
    if (!contactJourneyId) return acc;
    if (!acc[contactJourneyId]) {
      acc[contactJourneyId] = [task];
    } else {
      acc[contactJourneyId].push(task);
    }
    return acc;
  }, {});
};
