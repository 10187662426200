module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   PropTypes = require("prop-types")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   EverTrue = require("app")
   {input, div, textarea} = ReactLibs.DOMFactories
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   {createFactory} = require("base/new-utils")
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   FormField = createFactory require("components/forms/form-field")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   AllUsersStore = require("apps/users/stores/all-users-store").default
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   CharacterCounter = require("apps/interactions/components/character-counter").default   

   _formatDate = (date) ->
      moment(date).format("YYYY-MM-DD")

   createComponent "TripForm",
      propTypes:
         trip: PropTypes.object
         contactIds: PropTypes.array
         filterParams: PropTypes.object,
         navigateToTrip: PropTypes.bool
         manageCollaborators: PropTypes.bool
         isFiltersV2: PropTypes.bool
         

      getInitialState: ->
         _.extend @getFormattedTripDate(@props.trip),
            title: @props.trip?.name
            collaborators_added: false
            notify_enabled: true
            inputLength: @props.title?.text?.length || 0

      registerStores: ->
         @on MeetingsStore, ->
            meetings: MeetingsStore.getForTrip(@props.trip?.id)

         @on AllUsersStore, ->
            users: _.map AllUsersStore.getAllGivingTreeUsers(), (user) ->
               {value: user.id, label: user.name}

         @on MyTripsStore, ->
            collaborators: MyTripsStore.getTripCollaborators(@props.trip?.id)

      componentDidMount: ->
         $(@refs.name).focus()
         id = (person.id for person in @state.collaborators)
         @setState {ids: id}

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(@props.trip, newProps.trip) && newProps.trip
            @setState _.extend @getFormattedTripDate(newProps.trip),
               title: newProps.trip?.name

      getFormattedTripDate: (trip) ->
         if trip && trip.start_at && trip.end_at
            date_state =
               date_from: _formatDate(trip.start_at)
               date_to: _formatDate(trip.end_at)
         else
            date_state =
               date_from: _formatDate(moment().valueOf())
               date_to: _formatDate(moment().add("3", "days").valueOf())
         date_state

      getTripData: ->
         ids_without_owner = _.pluck(_.filter(@state.collaborators, (collab) =>
            collab.id != @props.trip?.user_id), "id")

         _.extend {}, @props.trip,
            type: "trip"
            name: @state.title
            solicitor_user_ids: ids_without_owner
         , Decorator.MyTrips.getTripTimestamps(@state.date_from, @state.date_to)

      handleCollaboratorsChange: (users) ->
         collaborators   = _.map users, (user) ->
            _.extend {id: user.value, name: user.label}, user

         ids_without_owner = _.pluck(_.filter(collaborators, (collab) =>
            collab.id != @props.trip?.user_id), "id")

         solicitors = @props.trip?.solicitor_user_ids || []
         diff = _.difference(ids_without_owner, solicitors)

         @setState
            collaborators_added: diff.length > 0
            collaborators: collaborators

      handleNotifyChange: ->
         @setState {notify_enabled: @refs.notify.getIsChecked()}

      handleSubmit: ->
         trip_data = @getTripData()

         if @state.collaborators_added
            nodeNote = @refs.note
            _.extend trip_data,
               message: nodeNote?.value
               skip_notification: !@state.notify_enabled

         if trip_data.id
            MyTripsSource.update(trip_data)
         else
            MyTripsSource.create({
               data: trip_data
               contactIds: @props.contactIds
               filterParams: @props.filterParams
               options: {
                  navigateToTrip: @props.navigateToTrip
               }
            }, @props.isFiltersV2)

      sortedUsers: (users,ids) ->
         _.sortBy users, (user) ->
            if _.contains(ids, user.value)
               [-1, user.label]  # This ensures that selected users appear at the top and in alphabetical order
            else
               [1, user.label]   # This places unselected users after the selected ones and in alphabetical order

      render: ->
         trip_data = @getTripData()
         has_valid_range = Decorator.MyTrips.hasValidTripRange(trip_data, @state.meetings)
         current_user_id = EverTrue.store.user.get("id")
         INPUT_MAX_LENGTH = 120
         header = if @props.manageCollaborators then "Manage Collaborators"
         else if @props.trip then "Edit Trip" else "Create Trip"
         # if creating new remove owner else disable owner in options
         users =
            if !@props.trip
               _.filter @state.users, (user) -> user.value != current_user_id
            else
               _.map @state.users, (user) =>
                  if user.value == @props.trip?.user_id
                     _.extend({disabled: true}, user, {label: "#{user.label} | Owner"})
                  else
                     user
         Modal
            width: 500
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            ModalConfirm
               header: header
               hasMargin: true
               buttonLabel: if @props.trip then "Save" else "Create"
               disableButton: !@state.title || !has_valid_range || @state.inputLength > INPUT_MAX_LENGTH
               onSubmit: @handleSubmit
               onCancel: @props.remove

               if !@props.manageCollaborators
                  FormField
                     inlineLabel: false
                     label: "Title*",
                        input
                           ref: "name"
                           type: "text"
                           placeholder: "Enter trip name..."
                           value: @state.title
                           onFocus: (e) ->
                              # starts cursor at end of input field and allows cursor to enter without highlighting text
                              e.currentTarget.value = e.currentTarget.value
                           onChange: (e) =>
                              @setState {title: e.currentTarget.value, inputLength: e.currentTarget.value.length}

                        div className: "",
                           CharacterCounter
                              count: @state.inputLength
                              limit: INPUT_MAX_LENGTH


               if !@props.manageCollaborators
                  FormField
                     inlineLabel: false
                     label: "Time*"
                     CalendarDropdown
                        className: "trip-form-calendar"
                        date_from: @state.date_from
                        date_to: @state.date_to
                        onChange: (from, to) =>
                           @setState {date_from: from, date_to: to}

                     if !has_valid_range
                        div className: "trip-form--error text-danger",
                           "You have scheduled meetings outside of this range."

               FormField
                  inlineLabel: false
                  label: "Collaborators"
                  helpKey: "trip_collaborators"

                  AdvancedCombobox
                     placeholder: "Select Users..."
                     multiple: true
                     searchable: true
                     value: _.map @state.collaborators, (collaborators) ->
                        _.extend {}, collaborators,
                           value: collaborators.id
                           label: collaborators.name
                     options: @sortedUsers(users, @state.ids)
                     onChange: (users) =>
                        @handleCollaboratorsChange(users)

               if @state.collaborators_added
                  div className: "form-field",
                     Checkbox
                        defaultChecked: @state.notify_enabled
                        onChange: @handleNotifyChange
                        ref: "notify"
                        "Send an email notification to new collaborators"

                     if @state.notify_enabled
                        textarea
                           className: "share-settings--message"
                           placeholder: "Add a note (optional)"
                           ref: "note"
