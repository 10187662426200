import Icon from 'components/elements/icon';
import PropTypes from 'prop-types';

const AiTaskReviewButton = ({ title, onClick }) => {
  return (
    <a title={`Open task ${title}`} onClick={onClick}>
      <div className="ai-task-details--container ai-task-review">
        <Icon icon="add-circle" size={2} className="review-btn" />
        <p style={{ cursor: 'pointer' }} className="suggested-task-body-text">
          Review
        </p>
      </div>
    </a>
  );
};

AiTaskReviewButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AiTaskReviewButton;
