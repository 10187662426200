import PropTypes from 'prop-types';
import { useFluxStore } from '@evertrue/et-flux';
import FacebookPagesStore from 'apps/facebook/stores/facebook-pages-store';
import ProfileFacebookStore from 'apps/profile/stores/profile-facebook-store';
import EngagementCard from 'apps/profile/components/engagement-card';
import EngagementIcon from 'apps/profile/components/engagement-icon';
import EngagementCardFacebookTitle from 'apps/profile/components/engagement-card-facebook-title';

const EngagementCardFacebook = ({ contact, social }) => {
  const { page } = useFluxStore(FacebookPagesStore, () => ({
    page: FacebookPagesStore.getById(social?.content?.page_remote_id),
  }));

  const { post } = useFluxStore(ProfileFacebookStore, () => ({
    post: ProfileFacebookStore.getPost(social?.content?.remote_id),
  }));

  const engagement = social?.engagement || {};
  const content = social?.content || {};
  const engaged_at = engagement.created_at;

  const icon = <EngagementIcon icon={engagement.type} size="40" network="facebook" />;
  const title = <EngagementCardFacebookTitle social={social} post={post} page={page} />;

  return (
    <EngagementCard
      title={title}
      contents={<span className="fs-exclude-data">{content.contents}</span>}
      comment={engagement.contents && <span className="fs-exclude-data">{engagement.contents}</span>}
      date={engaged_at}
      icon={icon}
      network="facebook"
    />
  );
};

EngagementCardFacebook.propTypes = {
  contact: PropTypes.object,
  social: PropTypes.object,
};

export default EngagementCardFacebook;
