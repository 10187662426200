import { useState, useContext, useEffect } from 'react';
import {
  FlexTable,
  useOpenClose,
  ButtonTooltip,
  Icon,
  Link,
  Modal,
  LinkButton,
  FlexTableSortHeaderCell,
  Checkbox,
  Button,
} from '@evertrue/et-components';
import TasksToolbar from './tasks-toolbar';
import DeleteModal from './delete-modal';
import { daysFromNow, formattedDate } from 'apps/tasks/utils/utils';
import Avatar from 'components/elements/avatar';
import useTasks from 'apps/tasks/hooks/use-tasks';
import { PropTypes } from 'base/react-libs';
import { TasksContext } from 'apps/tasks/hooks/tasks-context';
import CompleteTaskToggle from '../complete-task-toggle';
import AddTaskButton from '../create/add-task-button';
import StrikeThroughWrapper from 'components/containers/strikethrough-wrapper';
import { useTasksNotificationContext } from 'apps/tasks/hooks/tasks-notification-context.js';
import { useIdentity } from 'base/identity-resolver';
import TaskName from './task-name';
import AddTask from 'apps/tasks/components/create/add-task';
import AddToCalendarModal from 'apps/tasks/components/create/add-to-calendar-modal';
import { trackOutreachAction } from 'apps/outreach/utils/utils';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { getContactEmailAddress } from 'apps/journeys/utils/helpers/utils';
import InteractionForm from 'apps/interactions/components/create/interaction-form';
import { PinnedCommentsContext } from 'apps/profile/contexts/pinned-comments-context';

const TasksTable = ({
  tasks = [],
  loading,
  total,
  tab = '',
  userType,
  taskFilters = {},
  contact = {},
  handleTaskSelectionChange,
  bulkSelectedTasks,
  hasBulkTaskMoves,
  inBulkSelectMode,
  setInBulkSelectMode,
}) => {
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();
  const { referrer, filtersDefaultValue, filters, updateTableSettings } = useContext(TasksContext);
  const { user } = useIdentity();
  const { deleteTask, toggleCompletionStatus, parseActionType } = useTasks();
  // State for selected tasks
  const [selectedTask, setSelectedTask] = useState({});

  // Derived states - tasks where user is either owner OR creator (not necessarily both)
  const userOwnedAndAssignedTasks = tasks.filter(
    (task) => task.owner_user_id === user.id || task.creator_user_id === user.id
  );

  // Helper to check if user is owner or creator of task
  const isTaskOwnerOrCreator = (task, user) => {
    return task.owner_user_id === user.id || task.creator_user_id === user.id;
  };

  const allTasksChecked =
    userOwnedAndAssignedTasks.length > 0 &&
    bulkSelectedTasks.filter((t) => t.owner_user_id === user.id || t.creator_user_id === user.id).length ===
      userOwnedAndAssignedTasks.length;

  const partiallyChecked =
    bulkSelectedTasks.filter((t) => t.owner_user_id === user.id || t.creator_user_id === user.id).length > 0 &&
    bulkSelectedTasks.filter((t) => t.owner_user_id === user.id || t.creator_user_id === user.id).length <
      userOwnedAndAssignedTasks.length;

  const [isOpen, openModal, closeModal] = useOpenClose();
  const [isAddToCalendarModalOpen, openAddToCalendarModal, closeAddToCalendarModal] = useOpenClose();
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const { addDrawer } = useGlobalDrawerProvider();
  const [isPinnedCommentModalOpen, openPinnedCommentModal, closePinnedCommentModal] = useOpenClose();
  const { addNewCommentToState } = useContext(PinnedCommentsContext);
  const [taskContact, setTaskContact] = useState();
  const [sortKey, setSortKey] = useState('current_due_date');
  const [sortOrder, setSortOrder] = useState('ASC');

  const handleCheckAll = (isChecked) => {
    if (isChecked) {
      handleTaskSelectionChange(userOwnedAndAssignedTasks);
    } else {
      handleTaskSelectionChange([]);
    }
  };

  const handleTaskCheck = (task, isChecked) => {
    const updatedTasks = isChecked ? [...bulkSelectedTasks, task] : bulkSelectedTasks.filter((t) => t.id !== task.id);
    handleTaskSelectionChange(updatedTasks);
  };

  const renderHeader = (header, key) => (
    <span style={{ textAlign: 'left' }} name={header}>
      <FlexTableSortHeaderCell
        onClick={() => {
          if (sortKey === key) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
          } else {
            setSortKey(key);
            setSortOrder('ASC');
          }
        }}
        children={header}
        isSorted={sortKey === key}
        sortOrder={sortOrder === 'DESC'}
      />
    </span>
  );

  useEffect(() => {
    if (taskFilters.statuses.includes('COMPLETED')) setSortKey('completed_at');
  }, [taskFilters]);

  useEffect(() => {
    updateTableSettings({ sortProp: sortKey, sortReverse: sortOrder === 'ASC' ? false : true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey, sortOrder]);

  const handleActionTypeClick = (action_type, contact) => {
    const name = contact?.contact_attributes?.name_full;
    const title = name ?? 'New Message';

    setTaskContact({ ...contact, name });
    const email_address = getContactEmailAddress(contact);

    if (action_type === 'EMAIL') {
      trackOutreachAction('open_emailer');
      addDrawer('emailer', {
        contact: contact || {},
        recipients: email_address === '' ? [] : [email_address],
        name: name,
        drawerTitle: title,
      });
    } else if (action_type === 'CALL') {
      trackOutreachAction('open_dialer');
      addDrawer('dialer', {
        contact: contact || {},
        phones: (contact && contact.phones) || [],
        name: name,
        drawerTitle: title,
      });
    } else {
      isPinnedCommentModalOpen ? closePinnedCommentModal() : openPinnedCommentModal();
    }
  };

  const isFilterApplied = () => !(JSON.stringify(filters) === JSON.stringify(filtersDefaultValue));
  const columns = [
    {
      key: 1,
      header: (
        <>
          {hasBulkTaskMoves && inBulkSelectMode && (
            <Checkbox
              checked={allTasksChecked || partiallyChecked}
              partial={partiallyChecked}
              onChange={(checked) => {
                handleCheckAll(checked);
              }}
              className="header-checkbox"
              label="Select All Tasks"
            />
          )}
        </>
      ),
      accessor: (data) => {
        const isOwnerOrCreator = isTaskOwnerOrCreator(data, user);
        return (
          <>
            {inBulkSelectMode ? (
              <>
                {isOwnerOrCreator && (
                  <Checkbox
                    checked={bulkSelectedTasks.some((t) => t.id === data?.id)}
                    onChange={(checked) => handleTaskCheck(data, checked)}
                    label="Select Task"
                  />
                )}
              </>
            ) : (
              <>
                {isOwnerOrCreator ? (
                  <CompleteTaskToggle task={data} onClick={toggleCompletionStatus} ownerUserId={data?.owner_user_id} />
                ) : (
                  <ButtonTooltip
                    contentPadding="4px 8px"
                    trigger={<span className="dash-task-circle" />}
                    children="Complete Task option is disabled because you are not the owner of this task"
                  />
                )}
              </>
            )}
          </>
        );
      },
      headerStyle: { maxWidth: '50px', textAlign: 'left' },
      cellClassName: 'task-checkbox-cell',
    },
    {
      key: 2,
      width: 335,
      header: renderHeader('Task Name', 'title'),
      accessor: (data) => {
        const { title, completed_at, metadata = {}, current_due_date, owner_info, contact = {} } = data;
        const contactName = contact?.contact_attributes?.name_full || '';
        if (metadata) {
          metadata.current_due_date = current_due_date;
          metadata.owner = owner_info;
        }
        return (
          <div className="task-name-cell">
            <div className="task-name-content">
              <TaskName
                title={title}
                completed_at={completed_at}
                metadata={metadata}
                onClick={() => {
                  setSelectedTask(data);
                  setTaskModalOpen(!isTaskModalOpen);
                }}
                contactName={contactName}
              />
            </div>
          </div>
        );
      },
    },
    {
      key: 3,
      width: 200,
      header: renderHeader('Constituent', 'target_contact_name'),
      accessor: ({ contact = {}, target_contact_id, completed_at }) => {
        let name_full, url;
        // workaorund until we can fix GQL to not return null for empty fields
        if (contact !== null) {
          name_full = contact.contact_attributes ? contact.contact_attributes.name_full : '';
          url = contact.profile_image && contact.profile_image.url ? contact.profile_image.url : '';
        }
        return (
          <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
            {target_contact_id ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Link title={`${name_full}'s Profile`} href={`/contact/${target_contact_id}`}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      className="new-contact-card--avatar fs-exclude-data"
                      name={name_full}
                      url={url || ''}
                      width={40}
                    />
                    <div className="journeys-contact-card-name table-toolbar-amount fs-exclude-data">{name_full}</div>
                  </div>
                </Link>
              </span>
            ) : (
              <span>-</span>
            )}
          </StrikeThroughWrapper>
        );
      },
    },
    ...(!taskFilters.statuses.includes('COMPLETED')
      ? [
          {
            key: 4,
            header: renderHeader('Due Date', 'current_due_date'),
            accessor: ({ current_due_date, completed_at }) => {
              if (current_due_date) {
                const due = daysFromNow(current_due_date);
                return (
                  <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
                    <span className="tasks--due-date">
                      <p style={due.style}>{due.date}</p>
                    </span>
                  </StrikeThroughWrapper>
                );
              } else return <span>-</span>;
            },
          },
        ]
      : []),
    {
      key: 5,
      header: renderHeader('Action Type', 'action_type'),
      accessor: ({ action_type, completed_at, contact, owner_user_id }) => {
        return (
          <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
            {action_type ? (
              owner_user_id === user.id &&
              contact &&
              (['EMAIL', 'CALL'].includes(action_type) || (action_type === 'RESEARCH' && contact !== null)) ? (
                <LinkButton
                  title={action_type}
                  className="fs-exclude-data"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleActionTypeClick(action_type, contact)}
                >
                  {parseActionType(action_type)}
                </LinkButton>
              ) : (
                <span className="fs-exclude-data">{parseActionType(action_type)}</span>
              )
            ) : null}
          </StrikeThroughWrapper>
        );
      },
    },
    {
      key: 6,
      header: renderHeader('Assignee', 'owner_user_name'),
      accessor: ({ owner_info, completed_at }) => (
        <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
          {owner_info ? owner_info.name : null}
        </StrikeThroughWrapper>
      ),
    },
    {
      key: 7,
      header: renderHeader('Created By', 'creator_user_name'),
      accessor: ({ completed_at, creator_info }) => (
        <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
          {creator_info ? <span className="fs-exclude-data">{creator_info.name}</span> : null}
        </StrikeThroughWrapper>
      ),
    },
    ...(!taskFilters.statuses.includes('IN_PROGRESS')
      ? [
          {
            key: 8,
            header: renderHeader('Completed At', 'completed_at'),
            accessor: (data) => (data.completed_at ? formattedDate(data.completed_at) : null),
          },
        ]
      : []),
    {
      key: 9,
      header: '',
      headerStyle: { flexGrow: 1 },
      cellClassName: 'task-icon-col',
      accessor: (task) => {
        if (task && task.status === 'PAUSED') {
          return (
            <ButtonTooltip
              children={'Add to calendar is disabled because this task is currently paused'}
              contentPadding={'4px 8px'}
              trigger={<Icon icon="add-task-table" iconKit="gt2" style={{ color: 'grey' }} />}
            />
          );
        }
        if (task.owner_user_id === user.id || task.creator_user_id === user.id)
          return (
            <ButtonTooltip
              children="Add to Calendar"
              contentPadding={'4px 8px'}
              trigger={
                <Icon
                  icon="add-task-table"
                  iconKit="gt2"
                  onClick={() => {
                    setSelectedTask(task);
                    openAddToCalendarModal();
                  }}
                  style={{ color: '#007C9E' }}
                  onKeyUp={() => {
                    setSelectedTask(task);
                    openAddToCalendarModal();
                  }}
                  isReachable={true}
                />
              }
            />
          );
        else
          return (
            <ButtonTooltip
              children={'Add to calendar is disabled because you are not the owner or creator of this task'}
              contentPadding={'4px 8px'}
              trigger={<Icon icon="add-task-table" iconKit="gt2" style={{ color: 'grey' }} />}
            />
          );
      },
    },
    {
      key: 10,
      headerStyle: { flexGrow: 1 },
      header: '',
      cellClassName: 'task-icon-col',
      accessor: (data) => {
        if (data && data.status === 'PAUSED')
          return (
            <ButtonTooltip
              children={'Delete task is disabled because task is currently paused'}
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" style={{ color: 'grey' }} />}
            />
          );
        if (isTaskOwnerOrCreator(data, user))
          return (
            <ButtonTooltip
              children="Delete"
              contentPadding={'4px 8px'}
              trigger={
                <Icon
                  icon="trash"
                  onClick={() => {
                    setSelectedTask(data);
                    isOpen ? closeModal() : openModal();
                  }}
                  isReachable={true}
                  onKeyUp={() => {
                    setSelectedTask(data);
                    isOpen ? closeModal() : openModal();
                  }}
                />
              }
            />
          );
        else
          return (
            <ButtonTooltip
              children="Delete option is disabled because you are not the owner of this task"
              contentPadding={'4px 8px'}
              trigger={<Icon icon="trash" style={{ color: 'grey' }} />}
            />
          );
      },
    },
  ];

  return (
    <>
      <div>
        <TasksToolbar numTasks={total} userType={userType} filterStatus={taskFilters} />
        <div className="tasks-lists-container">
          <div style={{ paddingLeft: '16px' }}>
            <Button
              type="simple"
              className="blue-button"
              onClick={() => {
                if (inBulkSelectMode) {
                  handleCheckAll(false);
                }
                setInBulkSelectMode(!inBulkSelectMode);
              }}
            >
              {inBulkSelectMode ? (
                <div className="text-bold">Cancel</div>
              ) : (
                <div className="text-bold">Select Tasks</div>
              )}
            </Button>
          </div>
          <div className="tasks-lists-table">
            <FlexTable
              caption="Lists"
              emptyStateMessageText={
                isFilterApplied() ? 'You don’t have any tasks matching your search criteria' : 'All Done!'
              }
              emptyStateMessageSubtext={
                isFilterApplied()
                  ? 'Try adjusting your filters or changing your search combination.'
                  : 'Your Task List is Empty.'
              }
              emptyStateActionButton={
                !taskFilters.statuses.includes('COMPLETED') ? (
                  !isFilterApplied() ? (
                    <AddTaskButton primary={true} userType={userType} contact={contact} />
                  ) : null
                ) : null
              }
              emptyStateIcon={isFilterApplied() ? 'filter-search' : 'tasks'}
              emptyStateIconKit={'gt2'}
              columns={
                referrer === 'contact'
                  ? columns.filter((col) => col.header.props?.name !== 'Constituent')
                  : tab === 'assigned'
                  ? columns.filter((col) => col.header.props?.name !== 'Created By')
                  : tab !== 'assigned'
                  ? columns.filter((col) => col.header.props?.name !== 'Assignee')
                  : columns
              }
              data={tasks.slice(0, 50)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        title="Delete Task"
        body="Are you sure you want to delete this task?"
        isOpen={isOpen}
        onClose={closeModal}
        onSubmit={async () => {
          await deleteTask(selectedTask, userType);
          getAndSetGlobalTasksDueCount();
        }}
      />
      {isAddToCalendarModalOpen && (
        <AddToCalendarModal
          isOpen={isAddToCalendarModalOpen}
          closeModal={closeAddToCalendarModal}
          calendarParams={selectedTask}
          showTaskSaved={false}
        />
      )}
      <>
        <AddTask
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setTaskModalOpen(false);
          }}
          handleClose={() => {
            setTaskModalOpen(false);
          }}
          taskObj={selectedTask}
        />
      </>
      <>
        <Modal isOpen={isPinnedCommentModalOpen} closeModal={closePinnedCommentModal}>
          <InteractionForm
            contact={taskContact}
            referrer="add-pinned-comment-action"
            interaction={{ interaction_type: 'EverTrue Comment', pinned: true }}
            requestHide={closePinnedCommentModal}
            addNewCommentToState={addNewCommentToState}
          />
        </Modal>
      </>
    </>
  );
};

TasksTable.propTypes = {
  loading: PropTypes.bool,
  tasks: PropTypes.array,
  total: PropTypes.number,
  tab: PropTypes.string,
  userType: PropTypes.string,
  taskFilters: PropTypes.object,
  contact: PropTypes.object,
  bulkSelectedTasks: PropTypes.array,
  handleTaskSelectionChange: PropTypes.func,
  hasBulkTaskMoves: PropTypes.bool,
  inBulkSelectMode: PropTypes.bool,
  setInBulkSelectMode: PropTypes.func,
};

export default TasksTable;
