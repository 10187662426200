export const SAVED_SEARCH_SEGMENT_FILTERS = [
  {
    title: 'Leadership',
    id: 1,
    sectionStyle: { marginBottom: '2rem', textAlign: 'center' },
    segments: [
      {
        id: 1.1,
        title: 'Assigned to Me and Career Move',
        subtitle: '',
        checkSolicitor: true,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.6',
              value: [
                {
                  label: '',
                  value: '',
                },
              ],
            },
            {
              filter_row_id: '1',
              filter_id: '15.3',
              value: {
                parameter: {
                  value: 'must',
                },
                value: 'now-3M/d',
                range: '',
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 1.2,
        title: 'Assigned to Me and Gift in the Last Week',
        subtitle: '',
        checkSolicitor: true,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.5',
              value: {
                parameter: {
                  label: 'Is in the last',
                  value: 'must:gte',
                },
                from: '2024-11-11',
                to: '2024-11-18',
                in: '1-weeks',
              },
            },
            {
              filter_row_id: '1',
              filter_id: '12.6',
              value: [
                {
                  label: '',
                  value: '',
                },
              ],
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 1.3,
        title: 'Unassigned Donor Above $10K',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 9999,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Gift Officer',
    id: 2,
    sectionStyle: { marginBottom: '2rem', textAlign: 'center' },
    segments: [
      {
        id: 2.1,
        title: 'Assigned to Me and Career Move',
        subtitle: '',
        checkSolicitor: true,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.6',
              value: [
                {
                  label: '',
                  value: '',
                },
              ],
            },
            {
              filter_row_id: '1',
              filter_id: '15.3',
              value: {
                parameter: {
                  value: 'must',
                },
                value: 'now-3M/d',
                range: '',
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 2.2,
        title: 'Assigned to Me and Gift in the Last Week',
        subtitle: '',
        checkSolicitor: true,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.5',
              value: {
                parameter: {
                  label: 'Is in the last',
                  value: 'must:gte',
                },
                from: '2024-11-11',
                to: '2024-11-18',
                in: '1-weeks',
              },
            },
            {
              filter_row_id: '1',
              filter_id: '12.6',
              value: [
                {
                  label: '',
                  value: '',
                },
              ],
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 2.3,
        title: '$1k+ gift, Unassigned, and $2.5MM+ in Net Worth: Chicago',
        subtitle: ' - swap in your current location, or prep for an upcoming trip!',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 999,
              },
            },
            {
              filter_row_id: '2',
              filter_id: '14.1',
              value: {
                gte: 2500000,
              },
            },
            {
              filter_row_id: '3',
              filter_id: '7.2',
              value: [
                {
                  value: 'Chicago',
                  label: 'Chicago',
                  icon: undefined,
                },
              ],
            },
          ],
          operator: 'and',
          page: 1,
          sort: {
            'score.score': {
              order: 'desc',
            },
          },
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Prospect Development',
    id: 3,
    sectionStyle: { marginBottom: '2rem', textAlign: 'center' },
    segments: [
      {
        id: 3.1,
        title: '$1k+ gift, Unassigned, and $5.5MM+ in Net Worth',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 999,
              },
            },
            {
              filter_row_id: '2',
              filter_id: 14.1,
              value: {
                gte: 5000000,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 3.2,
        title: 'Assigned Prospects with a Career Move',
        subtitle: '',
        checkSolicitor: true,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.6',
              value: [
                {
                  label: '',
                  value: '',
                },
              ],
            },
            {
              filter_row_id: '1',
              filter_id: '15.3',
              value: {
                parameter: {
                  value: 'must',
                },
                value: 'now-3M/d',
                range: '',
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 3.3,
        title: '85+ Donor Potential Score, Unassigned',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '5.1b',
              value: {
                gte: 85,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Annual Giving',
    id: 4,
    sectionStyle: { marginBottom: '2rem', textAlign: 'center' },
    segments: [
      {
        id: 4.1,
        title: 'First Time Donors',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.3',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 1,
              },
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 1,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 4.2,
        title: '70+ Donor Potential Score, Unassigned',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '5.1b',
              value: {
                gte: 70,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 4.3,
        title: 'LYBUNTs Engaged on Facebook in the Last 90 Days',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.7',
              value: [
                {
                  label: 'LYBUNT',
                  value: 'lybunt',
                },
              ],
            },
            {
              filter_row_id: '1',
              filter_id: '2.3',
              value: {
                parameter: {
                  value: 'must',
                },
                value: 'now-3M/d',
                range: '',
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
      {
        id: 4.4,
        title: 'Leadership Gift Notifications',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is between',
                  value: 'must:gte-lte',
                },
                value: 3,
                range: '',
                gte: 1499,
                lte: 24999,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <div key={segment.id}>
            <span>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </span>
          </div>
        ),
      },
    ],
  },
];

export const HOME_PAGE_NEXT_STEP_FILTERS = [
  {
    title: 'Leadership & Gift Officer',
    id: 1,
    sectionStyle: { marginBottom: '2rem', textAlign: 'left' },
    segments: [
      {
        id: 1.1,
        title: 'Unassigned Donor Above $10K',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 9999,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
      {
        id: 1.2,
        title: '$1k+ gift, Unassigned, and $2.5MM+ in Net Worth: Chicago',
        subtitle: ' - swap in your current location, or prep for an upcoming trip!',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 999,
              },
            },
            {
              filter_row_id: '2',
              filter_id: '14.1',
              value: {
                gte: 2500000,
              },
            },
            {
              filter_row_id: '3',
              filter_id: '7.2',
              value: [
                {
                  value: 'Chicago',
                  label: 'Chicago',
                  icon: undefined,
                },
              ],
            },
          ],
          operator: 'and',
          page: 1,
          sort: {
            'score.score': {
              order: 'desc',
            },
          },
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: 'Prospect Development',
    id: 2,
    sectionStyle: { marginBottom: '2rem', textAlign: 'left' },
    segments: [
      {
        id: 2.1,
        title: '$1k+ gift, Unassigned, and $5.5MM+ in Net Worth',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 999,
              },
            },
            {
              filter_row_id: '2',
              filter_id: 14.1,
              value: {
                gte: 5000000,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
      {
        id: 2.2,
        title: '85+ Donor Potential Score, Unassigned',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '5.1b',
              value: {
                gte: 85,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: 'Annual Giving',
    id: 3,
    sectionStyle: { marginBottom: '2rem', textAlign: 'left' },
    segments: [
      {
        id: 3.1,
        title: 'First Time Donors',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.3',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 1,
              },
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is greater than',
                  value: 'must:gt',
                },
                value: 1,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
      {
        id: 3.2,
        title: '70+ Donor Potential Score, Unassigned',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '5.1b',
              value: {
                gte: 70,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
      {
        id: 3.3,
        title: 'LYBUNTs Engaged on Facebook in the Last 90 Days',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '3.7',
              value: [
                {
                  label: 'LYBUNT',
                  value: 'lybunt',
                },
              ],
            },
            {
              filter_row_id: '1',
              filter_id: '2.3',
              value: {
                parameter: {
                  value: 'must',
                },
                value: 'now-3M/d',
                range: '',
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
      {
        id: 3.4,
        title: 'Leadership Gift Notifications',
        subtitle: '',
        checkSolicitor: false,
        filter: {
          filters: [
            {
              filter_row_id: '0',
              filter_id: '12.5',
              value: false,
            },
            {
              filter_row_id: '1',
              filter_id: '3.4',
              value: {
                parameter: {
                  label: 'Is between',
                  value: 'must:gte-lte',
                },
                value: 3,
                range: '',
                gte: 1499,
                lte: 24999,
              },
            },
          ],
          operator: 'and',
          page: 1,
        },
        component: (segment, url, handleLinkClick) => (
          <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }} key={segment.id}>
            <li style={{ display: 'list-item' }}>
              <a href={url} onClick={handleLinkClick}>
                {segment.title}
              </a>
              {segment.subtitle}
            </li>
          </ul>
        ),
      },
    ],
  },
];
