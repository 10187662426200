import PropTypes from 'prop-types';
import Icon from 'components/elements/icon';
import { Link } from '@evertrue/et-components';

const EmailTemplate = ({ data, hideIcon, onClick }) => {
  return (
    <div className="email-template">
      {hideIcon ? <div className="email-template--no-icon" /> : <Icon icon="email" className="email-template--icon" />}

      {!data?.email ? (
        <div className="email-template--data is-empty">Not provided</div>
      ) : (
        <div className="email-template--data">
          <div className="email-template--label">
            {data?.type || 'Other'}
            {data?.primary && ' (Primary)'}
          </div>
          <Link title="send email" href={`mailto:${data.email}`} data-bypass onClick={onClick}>
            <span className="fs-exclude-data">{data.email}</span>
          </Link>
        </div>
      )}
    </div>
  );
};

EmailTemplate.propTypes = {
  data: PropTypes.object,
  hideIcon: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EmailTemplate;
