import PropTypes from 'prop-types';
import { Button } from '@evertrue/et-components';
import InteractionAddBtn from 'apps/interactions/components/create/interaction-add-btn';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { trackOutreachAction } from 'apps/outreach/utils/utils';

const ThreadDetailsActions = ({ id, threadReplies }) => {
  const { addDrawer } = useGlobalDrawerProvider();
  const lastEmail = threadReplies?.items?.[threadReplies.items.length - 1];
  return (
    <div style={{ margin: '20px 40px' }}>
      <Button
        type="secondary"
        onClick={() => {
          trackOutreachAction('open_emailer');
          addDrawer('emailer', {
            outreach: {
              email_recipients: threadReplies?.items?.[0]?.email_recipients,
              subject: threadReplies?.items?.[0]?.subject,
              body: lastEmail.body,
              nylas_thread_id: id,
            },
          });
        }}
      >
        Reply
      </Button>
      {!threadReplies?.items?.[0]?.interaction_id && (
        <InteractionAddBtn
          interaction={{
            interaction_type: 'email',
          }}
        />
      )}
    </div>
  );
};

ThreadDetailsActions.propTypes = {
  id: PropTypes.string.isRequired,
  threadReplies: PropTypes.object.isRequired,
};

export default ThreadDetailsActions;
