import PropTypes from "prop-types";
import FeatureStore from "apps/layout/stores/feature-store";
import { Icon, AdvancedCombobox } from "@evertrue/et-components";

const GivingTitle = ({ contact, selectedCategory, categories, onUpdateCategory }) => {
  const hasRecurringGifts = FeatureStore.hasFeature("recurring_payment_field");
  const hasRecurringInTotal = contact?.giving?.has_recurring_payments?.value;
  const totalIsSelected = selectedCategory === "Total Giving";
  const hasRecurringInSelectedCategory = contact?.giving_categories?.reduce((hasRecurring, catObj) => 
    hasRecurring || (catObj.has_recurring_payments?.value && catObj.label?.value === selectedCategory), 
    false
  ) || false;

  const shouldShowRecurringGiftHeader = hasRecurringGifts && ((hasRecurringInTotal && totalIsSelected) || hasRecurringInSelectedCategory);

  return (
    <div className="profile-giving-controller--title">
      <span>Giving History</span>
      <AdvancedCombobox
        className="advanced-combobox-secondary profile-giving-controller--category-dropdown"
        value={{ value: selectedCategory }}
        searchable={false}
        options={categories}
        onChange={onUpdateCategory}
      />
      {shouldShowRecurringGiftHeader && (
        <span className="profile-giving-controller--recurring-gift">
          <Icon icon="recurring" />
          <span className="profile-giving-controller--recurring-text">Recurring Payments Set</span>
        </span>
      )}
    </div>
  );
};

GivingTitle.propTypes = {
  contact: PropTypes.object.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  onUpdateCategory: PropTypes.func.isRequired,
};

export default GivingTitle; 