import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import { daysFromNow } from 'apps/tasks/utils/utils';
import { Button } from '@evertrue/et-components';
import ActiveConstituentActions from './active-constituent-actions';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';

export default (
  org = {},
  user = {},
  journey = {},
  openContactSummary = () => {},
  handleCompletionClick = () => {},
  removeConstituentFromJourney = () => {}
) => [
  {
    key: 1,
    header: 'Constituent',
    accessor: (contactJourney = {}) => (
      <JourneyContactCard contact={contactJourney?.contact} currentJourney={journey} />
    ),
  },
  {
    key: 2,
    header: 'Next Step',
    accessor: (contactJourney = {}) => {
      let currentTask;
      if (contactJourney && contactJourney.tasks && Array.isArray(contactJourney.tasks)) {
        [currentTask] = contactJourney.tasks;
      }

      return (
        <div>
          {currentTask?.journey_task ? (
            <div>
              <div>
                Step {currentTask?.journey_task?.step_number} | Day {currentTask?.journey_task?.day_number}
              </div>
              <div>{currentTask?.title}</div>
            </div>
          ) : (
            <>All steps for this cadence have been completed!</>
          )}
        </div>
      );
    },
  },
  {
    key: 3,
    header: 'Due Date',
    accessor: (contactJourney = {}) => {
      const userIsOwner = user?.id === contactJourney?.user_id;
      let currentTask;
      if (contactJourney && contactJourney.tasks && Array.isArray(contactJourney.tasks)) {
        [currentTask] = contactJourney.tasks;
      }
      if (currentTask?.current_due_date) {
        const due = daysFromNow(currentTask?.current_due_date);
        return (
          <span>
            <p style={due?.style}>{due?.date}</p>
          </span>
        );
      } else if (userIsOwner && org?.id) {
        return (
          <Button type="secondary" onClick={() => handleCompletionClick(contactJourney, org?.id)}>
            Mark Cadence Complete
          </Button>
        );
      } else return <span>-</span>;
    },
  },
  {
    key: 4,
    header: 'Owner',
    accessor: (contactJourney = {}) => {
      const userIsOwner = user?.id === contactJourney?.user_id;
      return (
        <>
          {userIsOwner ? (
            <span>{contactJourney?.user?.name ? `${contactJourney?.user?.name} (You)` : 'You'}</span>
          ) : (
            <span>{contactJourney?.user?.name || '-'}</span>
          )}
        </>
      );
    },
  },
  {
    key: 5,
    header: 'Preview & Manage',
    accessor: (contactJourney) => {
      const userIsOwner = user?.id === contactJourney?.user_id;
      const contact = contactJourney?.contact || null;

      return (
        <>
          <Button
            disabled={!contact}
            onClick={() => {
              trackJourneysAction('view_link');
              openContactSummary(contact);
            }}
            type="simple"
          >
            Preview
          </Button>
          {userIsOwner && (
            <ActiveConstituentActions
              journey={journey}
              contactJourney={contactJourney}
              removeConstituentFromJourney={removeConstituentFromJourney}
            />
          )}
        </>
      );
    },
  },
];
