import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, PageHeader } from '@evertrue/et-components';
import ScheduledDetailedView from '../components/scheduled-detailed-view';
import Backbone from 'backbone';

const ScheduledController = ({ id }) => {
  const [subject, setSubject] = useState('');

  return (
    <>
      <PageHeader
        title={
          <div style={{ display: 'flex' }}>
            <Icon
              icon="chevron-left"
              className="settings-back"
              style={{ color: '$gray900', fontSize: '1.5em', alignSelf: 'flex-start' }}
              onClick={() => {
                Backbone.history.history.back();
              }}
            />
            <h3 style={{ fontSize: '18px', fontWeight: '500', marginTop: '3px' }}>{subject}</h3>
          </div>
        }
      />
      <ScheduledDetailedView id={id} setSubject={setSubject} />
    </>
  );
};

ScheduledController.propTypes = {
  id: PropTypes.string,
};

export default ScheduledController;
