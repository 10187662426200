import PropTypes from 'prop-types';
import SideBar from 'components/animations/side-bar';
import { Link } from '@evertrue/et-components';

const JourneySideBarContainer = ({
  activeSideBarConstituentsList,
  viewSideBar,
  handleCloseSideBar,
  selectedJourney,
}) => {
  return (
    <>
      <SideBar
        className="active-constituent-side-bar"
        start={viewSideBar}
        speed={5000}
        mountOnEnter={true}
        unmountOnExit={true}
        enableEnterAnimations={true}
        enableExitAnimations={true}
        enableAppearAnimations={true}
        closeSideBar={() => handleCloseSideBar()}
      >
        <div className="journey-drawer-container">
          <strong className="journey-drawer-header">{selectedJourney.name}</strong>
          <div className="journey-drawer-active-constituents">
            {selectedJourney.active_constituents} Active Constituents
          </div>
          <table className="journey-drawer-table">
            <tbody>
              {Object.entries(activeSideBarConstituentsList).map(([id, name]) => {
                return (
                  <tr key={id}>
                    <td className="journey-drawer-row">
                      <Link title={`Go to ${name}'s profile`} href={`/contact/${id}`}>
                        {name}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </SideBar>
    </>
  );
};

JourneySideBarContainer.propTypes = {
  selectedJourney: PropTypes.object,
  activeSideBarConstituentsList: PropTypes.object,
  viewSideBar: PropTypes.bool,
  handleCloseSideBar: PropTypes.func,
};

export default JourneySideBarContainer;
