import Api from 'entities/helpers/api';
import { useIdentity } from 'base/identity-resolver';
import { useState, useEffect, useCallback } from 'react';
import {
  getJourneyTimeoutsForUser,
  createJourneyTimeout,
  updateJourneyTimeout,
  cancelJourneyTimeout,
} from '../utils/api-requests/use-journey-timeouts-requests';
import { getDateInTimezone, getDateString } from 'base/new-utils';

// Custom Hook to handle all Cadence Pausing/Journey Timeout API Requests
const useJourneyTimeoutsApi = () => {
  const { user = {}, org } = useIdentity();
  const [inProgressJourneyTimeout, setInProgressJourneyTimeout] = useState(null);
  const [futureJourneyTimeout, setFutureJourneyTimeout] = useState(null);
  const [businessDaysUntilFutureTimeout, setBusinessDaysUntilFutureTimeout] = useState(0);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      let holidaysFromApi = {};
      try {
        holidaysFromApi = await Api.JOURNEYS.JOURNEY_TIMEOUT_HOLIDAYS.get();
        const datesArray = Object.values(holidaysFromApi).map((date) => getDateInTimezone(date));
        setHolidays(datesArray);
      } catch (e) {
        // don't throw an error, just log it
        console.error('Error fetching holidays', e);
      }
    };

    fetchHolidays();
  }, []);

  // Function to fetch and set user timeouts
  const getAndSetUserTimeouts = useCallback(async () => {
    if (user.id) {
      const { activeJourneyTimeout = null, scheduledJourneyTimeouts = [] } = await getJourneyTimeoutsForUser(user.id);
      setInProgressJourneyTimeout(activeJourneyTimeout);
      setFutureJourneyTimeout(null);
      if (!activeJourneyTimeout && scheduledJourneyTimeouts.length) {
        const sortedScheduledTimeouts = scheduledJourneyTimeouts.sort((a, b) => a.starts_on - b.starts_on);
        setFutureJourneyTimeout(sortedScheduledTimeouts[0]);
      }
    }
  }, [user.id]);

  // Populate state on initial render
  useEffect(() => {
    getAndSetUserTimeouts();
  }, [getAndSetUserTimeouts]);

  // API Calls
  const handleCreate = async (start, end) => {
    const payload = {
      owner_user_id: user.id,
      oid: org.id,
      starts_on: start,
      ends_on: end,
    };
    await createJourneyTimeout(payload);
    await getAndSetUserTimeouts();
  };

  const handleUpdate = async (start, end, currentTimeout) => {
    const payload = {
      ...currentTimeout,
      starts_on: start,
      ends_on: end,
    };
    await updateJourneyTimeout(payload, currentTimeout.id);
    await getAndSetUserTimeouts();
  };

  const handleCancel = async () => {
    // should only be called for FUTURE timeout
    await cancelJourneyTimeout(futureJourneyTimeout.id);
    await getAndSetUserTimeouts();
  };

  const handleResume = async () => {
    const payload = {
      ...inProgressJourneyTimeout,
      ends_on: getDateString(), // ends_on: today
    };

    await updateJourneyTimeout(payload, inProgressJourneyTimeout.id);
    await getAndSetUserTimeouts();
  };

  useEffect(() => {
    if (futureJourneyTimeout && futureJourneyTimeout?.starts_on) {
      const pauseStart = getDateInTimezone(futureJourneyTimeout?.starts_on);
      let totalBusinessDays = 0;

      for (let current = new Date(); current <= pauseStart; current.setDate(current.getDate() + 1)) {
        const dayOfWeek = current.getDay();
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          totalBusinessDays++;
        }
      }
      setBusinessDaysUntilFutureTimeout(totalBusinessDays);
    }
  }, [futureJourneyTimeout]);

  return {
    holidays,
    futureJourneyTimeout,
    inProgressJourneyTimeout,
    businessDaysUntilFutureTimeout,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleResume,
  };
};

export default useJourneyTimeoutsApi;
