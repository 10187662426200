import PropTypes from 'prop-types';
import { useEffect } from 'react';

const OutreachTabGroup = ({ activeTab, onTabChange, draftsCount, scheduledCount }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentTab = params.get('tab');
    if (currentTab) {
      onTabChange(currentTab);
    }
  }, [onTabChange]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className="outreach-tab-group">
        <div
          className={`outreach-tab-group--item ${activeTab === 'Drafts' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('Drafts')}
        >
          <span className="tab-text">Drafts {draftsCount}</span>
        </div>
        <span className="border-span"> | </span>
        <div
          className={`outreach-tab-group--item ${activeTab === 'Scheduled' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('Scheduled')}
        >
          <span className="tab-text">Scheduled {scheduledCount}</span>
        </div>
        <span className="border-span"> | </span>
        <div
          className={`outreach-tab-group--item ${activeTab === 'Sent' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('Sent')}
        >
          <span className="tab-text">Sent </span>
        </div>
      </div>
    </div>
  );
};

OutreachTabGroup.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  draftsCount: PropTypes.number.isRequired,
  scheduledCount: PropTypes.number.isRequired,
};

export default OutreachTabGroup;
