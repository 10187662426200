import { useState } from 'react';
import ErrorLogger from "entities/helpers/error-log-helper";

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      ErrorLogger.captureRequest("Error accessing local storage", e);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      ErrorLogger.captureRequest("Error setting local storage", e);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;