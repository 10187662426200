module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {table, thead, tbody, tr, th, td} = ReactLibs.DOMFactories
   ContactTableRows = require("apps/contact/components/contacts/contact-table-rows").default


   createComponent "SimpleContactsTable",
      propTypes:
         contacts: ReactLibs.PropTypes.array

      render: ->
         table className: "simple-contacts-table table",
            thead className: "simple-contacts-table--header",
               tr null,
                  th style: {width: ContactTableRows.constituent.width}, "Constituent"
                  th style: {width: ContactTableRows.lifetime_giving.width}, "Lifetime Giving"
                  th style: {width: ContactTableRows.largest_gift.width}, "Largest Gift"
                  th style: {width: ContactTableRows.last_gift.width}, "Last Gift"

            tbody className: "simple-contacts-table--body",
               _.map @props.contacts, (contact) ->
                  tr key: contact.id,
                     td className: ContactTableRows.constituent.className,
                        ContactTableRows.constituent.row(contact)

                     td null,
                        ContactTableRows.lifetime_giving.row(contact)

                     td null,
                        ContactTableRows.largest_gift.row(contact)

                     td null,
                        ContactTableRows.last_gift.row(contact)

