import { createStore } from '@evertrue/et-flux';
import WindowSource from 'apps/layout/sources/window-source';

const WindowStore = createStore('WindowStore', {
  getInitialState() {
    return {
      height: undefined,
      width: undefined,
    };
  },

  registerActions() {
    this.on(WindowSource.actions.resize, this.respondToResize);
  },

  respondToResize(size) {
    this.setState(size);
  },

  api: {
    getWidth() {
      return this.getState('width');
    },

    getHeight() {
      return this.getState('height');
    },
  },
});

export default WindowStore;
