import { Button } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import HomepageCard from '../homepage-card';
import classNames from 'classnames';

const SignalAnalyticsCard = ({
  title = '',
  icon = '',
  iconAlt = '',
  image = '',
  imageAlt = '',
  buttonName = 'View',
  position = 'left',
  onClick = () => {},
}) => {
  return (
    <div className="signal-analytics-card">
      <HomepageCard
        leftHeading={
          <span className="signal-analytics-card-heading">
            {icon && (
              <span>
                <img className="signal-analytics-card-heading-emoji" src={icon} alt={iconAlt} />
              </span>
            )}
            <span className="signal-analytics-card-heading-title">{title}</span>
          </span>
        }
        body={
          <div className="metrics-card-body" onClick={onClick}>
            <div className={classNames({ 'signal-analytics-card--center': position === 'center' })}>
              <img src={image} alt={imageAlt} />
              <div className={classNames({ 'signal-analytics-card-button--center': position === 'center' })}>
                <Button type="secondary" onClick={onClick}>
                  {buttonName}
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

SignalAnalyticsCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  buttonName: PropTypes.string,
  position: PropTypes.oneOf(['left', 'center']),
  onClick: PropTypes.func,
};

export default SignalAnalyticsCard;
