import { ActionsMenu } from '@evertrue/et-components';
import BulkDeleteEmailModal from './bulk-delete-email-modal';
import { PropTypes } from 'base/react-libs';

const OutreachActionMenu = ({ selected = [], setSelected = () => {}, outreachType }) => {
  const draftOptions = [
    {
      id: 'bulk-discard-draft',
      icon: 'delete',
      label: 'Discard Drafts',
      disabled: selected.length === 0,
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkDeleteEmailModal
            isOpen={is_open}
            onClose={close}
            selected={selected}
            setSelected={setSelected}
            outreachType={outreachType}
          />
        );
      },
    },
  ];

  const scheduledOptions = [
    {
      id: 'bulk-discard-scheduled',
      icon: 'trash',
      label: 'Discard Scheduled Emails',
      disabled: selected.length === 0,
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkDeleteEmailModal
            isOpen={is_open}
            onClose={close}
            selected={selected}
            setSelected={setSelected}
            outreachType={outreachType}
          />
        );
      },
    },
  ];

  return <ActionsMenu options={outreachType === 'Drafts' ? draftOptions : scheduledOptions} />;
};

OutreachActionMenu.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  outreachType: PropTypes.string,
};

export default OutreachActionMenu;
