import { PropTypes } from 'base/react-libs';
import { Icon } from '@evertrue/et-components';

const JourneyFavoriteToggle = ({ journey = {}, onClick = () => {} }) => {
  return (
    <Icon
      icon={journey.favorite ? 'favorite-icon-filled' : 'favorite-star'}
      iconKit="gt2"
      className={journey.favorite ? `favorited favorite-star` : `favorite-star`}
      onClick={() => {
        onClick(journey);
      }}
    />
  );
};

JourneyFavoriteToggle.propTypes = {
  journey: PropTypes.object,
  onClick: PropTypes.func,
};

export default JourneyFavoriteToggle;
