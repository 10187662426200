import PropTypes from 'prop-types';
import EverTrue from 'app';
import ListSource from 'apps/lists/sources/list-source';
import ListContactSource from 'apps/lists/sources/list-contact-source';
import Modal from 'components/modals/modal';
import ModalConfirm from 'components/modals/modal-confirm';
import ExportSearchController from 'apps/export/controllers/export-search-controller';
import ListShareSettings from 'apps/lists/components/list-share-settings';
import BulkListController from 'apps/lists/controllers/bulk-list-controller';
import EmmaPushController from 'apps/lists/components/emma-push-controller';
import ActionMenuItem from 'components/controls/action-menu-item';
import { useIdentity } from 'base/identity-resolver';
import { useGate } from 'components/is-gated';
import ListSelectorController from 'apps/lists/controllers/lists-selector-controller';

const ListActions = ({
  list = {},
  resultCount = 0,
  selectedContacts = [],
  onRemove = () => {},
  emma_accounts = [],
  emma_groups = [],
  onEmmaChange = () => {},
  onAddtoFolder = () => {},
  hasFolders = true,
}) => {
  const { user } = useIdentity();
  const [hasEmma] = useGate('emma');

  const handleDeleteList = () => {
    EverTrue.Alert.confirm(
      {
        content: 'Deleted lists cannot be recovered. Are you sure you want to delete this list?',
        headline: (
          <span>
            Delete <strong>{list?.name}</strong>
          </span>
        ),
        buttonLabel: 'Delete List',
      },
      (didConfirm) => {
        if (didConfirm) {
          ListSource.delete(list?.id);
        }
      }
    );
  };

  const handleExport = (propertyIds) => {
    ListContactSource.export(list?.id, propertyIds);
  };

  const handleRemove = () => {
    onRemove();
  };

  const handleAddtoFolder = () => {
    onAddtoFolder();
  };

  const renderShareModal = () => (
    <Modal width={600} keepOpen>
      <ModalConfirm header="Sharing Settings" className="share-settings--modal">
        <ListShareSettings listId={list?.id} />
      </ModalConfirm>
    </Modal>
  );

  const renderSelectorModal = () => (
    <Modal width={400}>
      <ModalConfirm header="Add Constituents to List">
        <ListSelectorController contactIds={selectedContacts} />
      </ModalConfirm>
    </Modal>
  );

  const renderExportModal = () => (
    <Modal width={600} keepOpen>
      <ExportSearchController
        exportType="List"
        onExport={handleExport}
        exportText={
          <div>
            <div className="text-block">You are about to export all constituent records for the list:</div>
            <strong>{list?.name}</strong>
          </div>
        }
      />
    </Modal>
  );

  const renderAddModal = () => <BulkListController list={list} />;

  const renderEmmaModal = () => (
    <Modal width={500}>
      <EmmaPushController
        list={list}
        listResultCount={resultCount}
        accounts={emma_accounts}
        groups={emma_groups}
        onSuccess={onEmmaChange}
        onError={onEmmaChange}
      />
    </Modal>
  );

  const isOwner = list?.user_id === user?.id;
  const selectedCount = selectedContacts?.length || 0;

  return (
    <>
      <div className="list-actions">
        {selectedCount > 0 && (
          <ActionMenuItem
            title={`Add ${selectedCount} Selected Constituents to Another List`}
            icon="playlist-add"
            modal={renderSelectorModal()}
          >
            {`Add ${selectedCount} Selected Constituents to Another List`}
          </ActionMenuItem>
        )}
        {selectedCount > 0 && (
          <ActionMenuItem
            title={`Remove ${selectedCount} Selected Constituents From List`}
            icon="playlist-minus"
            onClick={handleRemove}
          >
            {`Remove ${selectedCount} Selected Constituents From List`}
          </ActionMenuItem>
        )}
        <ActionMenuItem title="Manage Collaborators" icon="settings" modal={renderShareModal()}>
          Manage Collaborators
        </ActionMenuItem>
        <ActionMenuItem title={`Export ${resultCount} Constituents`} icon="cloud-download" modal={renderExportModal()}>
          {`Export ${resultCount} Constituents`}
        </ActionMenuItem>
        <ActionMenuItem title="Add Constituents to List" icon="add" modal={renderAddModal()}>
          Add Constituents to List
        </ActionMenuItem>
        {hasEmma && (
          <ActionMenuItem title="Send List to Emma" icon="email" modal={renderEmmaModal()}>
            Send List to Emma
          </ActionMenuItem>
        )}
        {isOwner && list?.name !== 'Favorites' && (
          <ActionMenuItem title="Delete List" icon="delete" className="text-danger" onClick={handleDeleteList}>
            Delete List
          </ActionMenuItem>
        )}
        {hasFolders && (
          <ActionMenuItem title="Add List to Folder" icon="folder" iconKit="gt2" onClick={handleAddtoFolder}>
            Add List to Folder
          </ActionMenuItem>
        )}
      </div>
    </>
  );
};

ListActions.propTypes = {
  list: PropTypes.object,
  resultCount: PropTypes.number,
  selectedContacts: PropTypes.array,
  onRemove: PropTypes.func,
  emma_accounts: PropTypes.array,
  emma_groups: PropTypes.array,
  onEmmaChange: PropTypes.func,
  onAddtoFolder: PropTypes.func,
  hasFolders: PropTypes.bool,
};

export default ListActions;
