/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Icon, ModalHeaderWithActions } from '@evertrue/et-components';
import ThankviewSettings from '../thankview-settings';
import { useRef, useState, useEffect } from 'react';
import { useRecorder } from '../hooks/recorder-context';
import VideoPlayer from '../thankview-recorder-base';
import CopyVideo from '../copy-video';
import { updateVideo } from '../../../entities/helpers/thankview-helper';
import EverTrue from 'app';
import useAiCaptions from '../hooks/use-ai-captions';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';
import useTrimVideo from '../hooks/use-trim-video';

const ReviewVideo = () => {
  const {
    reviewVideoRef,
    selectedVideo,
    state,
    clientId,
    updateState,
    saveVideoToState,
    setSelectedVideo,
    stopAndResetRecorder,
  } = useRecorder();
  const { handleCloseThankviewModal, handleChangeModalView } = useSignalVideoModalContext();
  const { captionsProcessing, createCaptions, deleteCaptions, captions } = useAiCaptions();
  const { trimLoading, applyTrim, revertTrimmedVideo } = useTrimVideo(selectedVideo, setSelectedVideo);
  const accordionContentRef = useRef(null);
  const [currentVideoSrc, setCurrentVideoSrc] = useState('');
  const [videoThumbnail, setVideoThumbnail] = useState('');
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [copyIsEnabled, setCopyIsEnabled] = useState(true);

  const personalVideo = selectedVideo?.personalVideo;
  const [settings, setSettings] = useState({
    logo_enabled: personalVideo?.logo_enabled,
    button_enabled: personalVideo?.button_enabled,
    button_text: personalVideo?.button_text,
    button_url: personalVideo?.button_url,
    show_in_email: personalVideo?.show_in_email,
    thumbnail_type: personalVideo?.thumbnail_type,
    logo_url: personalVideo?.logo_url,
  });

  const { videoUrl } = state;

  useEffect(() => {
    if (videoUrl) {
      setCurrentVideoSrc(videoUrl);
    } else if (selectedVideo && selectedVideo.libraryVideo) {
      setCurrentVideoSrc(selectedVideo.libraryVideo.video_path);
    }
  }, [videoUrl, selectedVideo]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.personalVideo) {
      setSettings({
        logo_enabled: personalVideo?.logo_enabled,
        button_enabled: personalVideo?.button_enabled,
        button_text: personalVideo?.button_text,
        button_url: personalVideo?.button_url,
        show_in_email: personalVideo?.show_in_email,
        thumbnail_type: personalVideo?.thumbnail_type,
        logo_url: personalVideo?.logo_url,
      });
    }
  }, [selectedVideo.personalVideo]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.libraryVideo) {
      setVideoThumbnail(selectedVideo.libraryVideo.animated_thumb_path || selectedVideo.libraryVideo.video_thumb);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.personalVideo) {
      setVideoName(selectedVideo.personalVideo.name || '');
    }
  }, [selectedVideo]);

  useEffect(() => {
    function wrap(fn, cleanup) {
      return (...args) => {
        cleanup();
        return fn(...args);
      };
    }
    new Promise((resolve, reject) => {
      try {
        const url = selectedVideo.libraryVideo.video_path;
        updateState({
          videoUrl: url,
        });

        const videoElement = reviewVideoRef.current.getVideo();
        if (!videoElement) {
          throw new Error('Unable to get video element from ref');
        }

        videoElement.src = url;

        const onLoadedMetadata = () => {
          updateState({
            videoUrl: url,
            isUploading: false,
            videoDuration: videoElement.duration,
          });
          saveVideoToState(url);
          resolve();
        };

        const onError = (e) => {
          EverTrue.Alert.error(`Error loading video: for  ${e}`);
          updateState({ isUploading: false, uploadError: 'Error loading video' });
          reject(new Error('Error loading video'));
        };

        const onLoadedData = () => {
          EverTrue.Alert.success('Video data has loaded');
        };

        videoElement.addEventListener('loadedmetadata', onLoadedMetadata);
        videoElement.addEventListener('error', onError);
        videoElement.addEventListener('loadeddata', onLoadedData);

        const timeoutId = setTimeout(() => {
          reject(new Error('Video loading timed out'));
        }, 30000); // 30 seconds timeout

        videoElement.load();

        const cleanup = () => {
          videoElement.removeEventListener('loadedmetadata', onLoadedMetadata);
          videoElement.removeEventListener('error', onError);
          videoElement.removeEventListener('loadeddata', onLoadedData);
          clearTimeout(timeoutId);
        };

        resolve = wrap(resolve, cleanup);
        reject = wrap(reject, cleanup);
      } catch (error) {
        console.error('Error in reviewing video');
        updateState({ isUploading: false, uploadError: error.message });
        reject(error);
      }
    });
  }, [reviewVideoRef, selectedVideo]);

  const handleInputChange = (e) => {
    const newName = e.target.value;
    setVideoName(newName);
  };

  const handleUpdateVideo = () => {
    updateVideo(
      selectedVideo.personalVideo.id,
      { name: videoName },
      clientId.data.client_id,
      clientId.data.client_secret
    )
      .then(() => {
        EverTrue.Alert.success('Video name updated successfully');
      })
      .catch((error) => {
        EverTrue.Alert.error('Failed to update video name:', error);
      });
  };

  return (
    <>
      <ModalHeaderWithActions
        closeModal={() => handleCloseThankviewModal(stopAndResetRecorder)}
        title="Review Video"
        onSubmit={() => {
          handleChangeModalView('recorder');
          updateState({ videoUrl: '' });
        }}
        submitLabel={
          <>
            <Icon icon="tv-video" iconKit="tv" className="tv-btn-icon" />
            New Video
          </>
        }
        closeLabel="Cancel"
      />
      <div className="flex flex-col h-full">
        <div className="flex justify-between flex-1">
          <div className="left-options-container">
            <div className="open-left">
              <div className="accordion-item">
                <div className="cursor-pointer" onClick={() => setAccordionOpen(!accordionOpen)}>
                  <span className={`accordion-icon ${accordionOpen ? 'rotate-90' : 'rotate-270'}`}>
                    <i className="fa fa-cog"></i>
                  </span>
                  {accordionOpen ? (
                    <i className="fa fa-chevron-right chevron-margin-top"></i>
                  ) : (
                    <i className="fa fa-chevron-left chevron-margin-top"></i>
                  )}
                </div>
                <div
                  ref={accordionContentRef}
                  style={{
                    overflowY: accordionOpen ? 'scroll' : 'hidden',
                    maxHeight: accordionOpen ? '500px' : '0',
                    opacity: accordionOpen ? 1 : 0,
                    maxWidth: accordionOpen ? '480px' : '50px',
                    transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
                  }}
                >
                  <ThankviewSettings
                    setCopyIsEnabled={setCopyIsEnabled}
                    createCaptions={createCaptions}
                    deleteCaptions={deleteCaptions}
                    settings={settings}
                    setSettings={setSettings}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-2/3" style={{ paddingRight: '42px' }}>
            <input
              type="text"
              className="video-title-input"
              onChange={handleInputChange}
              onBlur={handleUpdateVideo}
              name="Video Title"
              placeholder={selectedVideo ? selectedVideo.name : ''}
              value={videoName}
            />
            {captionsProcessing && (
              <div className="captions-processing-banner">
                <Icon iconKit="tv" icon="closed-captioning" />
                <p>Captioning In Progress</p>
              </div>
            )}
            <VideoPlayer
              id="player-review"
              src={currentVideoSrc}
              ref={reviewVideoRef}
              controls={true}
              autoPlay={false}
              isRecording={false}
              captionsUrl={captions}
              onApplyTrim={applyTrim}
              onRevertTrim={revertTrimmedVideo}
              trimLoading={trimLoading}
              isTrimmed={
                selectedVideo.libraryVideo.video_length_untrimmed &&
                Math.round(selectedVideo.libraryVideo.video_length) !==
                  selectedVideo.libraryVideo.video_length_untrimmed
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end" style={{ margin: '15px' }}>
        <Button
          onClick={() => {
            handleChangeModalView('library');
            setSelectedVideo(null);
          }}
        >
          <Icon icon="video-library" iconKit="tv" className="tv-btn-icon" />
          Video Library
        </Button>
        {videoThumbnail && (
          <CopyVideo videoThumbnail={videoThumbnail} copyIsEnabled={copyIsEnabled} settings={settings} />
        )}
      </div>
    </>
  );
};

export default ReviewVideo;
