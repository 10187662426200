import { PropTypes } from 'base/react-libs';
import AddTaskButton from './create/add-task-button';
import { Icon, Button, Card } from '@evertrue/et-components';
import TasksHeader from './tasks-header';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const ListItem = ({ icon, text }) => (
  <li>
    <Icon icon={icon} iconKit="gt2" />
    {text}
  </li>
);

const TasksWelcomePage = ({ userType }) => {
  return (
    <>
      <TasksHeader userType={userType} emptyState={true} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card className="tasks-welcome-page-card" cardSize="small">
          <Icon icon="tasks" iconKit="gt2" className="tasks-welcome-page-icon" />
          <h2 className="tasks-welcome-page-header">Welcome to Tasks</h2>
          <div className="tasks-welcome-page-body">
            <p className="tasks-welcome-page-subheader">Keep your day organized and efficient!</p>
            <ul>
              <p className="tasks-welcome-page-list-header">With Tasks, you can:</p>
              <ListItem icon="checkmark-emptystate" text="Plan donor outreach." />
              <ListItem icon="checkmark-emptystate" text="Stay prepared for trips or events." />
              <ListItem
                icon="checkmark-emptystate"
                text="Set reminders for key dates like birthdays or on-campus activities."
              />
            </ul>
            <p className="tasks-welcome-page-footer">
              Create tasks for yourself or tag in your teammate for support. Everything you need is now in one place,
              directly tied to each constituent profile. Say goodbye to sticky notes and scattered reminders!
            </p>
          </div>
          <div className="tasks-welcome-page-buttons">
            <Button
              type="secondary"
              title="Learn More"
              onClick={() => window.open(getLinkUrl('everTrue', 'tasksHelpCenterUrl'), '_blank')}
            >
              Learn More
            </Button>
            <AddTaskButton userType={userType} />
          </div>
        </Card>
      </div>
    </>
  );
};

TasksWelcomePage.propTypes = {
  userType: PropTypes.string,
};

ListItem.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TasksWelcomePage;
