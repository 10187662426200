module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   EverTrue = require("app")
   classNames = require("classnames")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   CoreSearchStore = require("apps/core-search/stores/core-search-store")
   WindowStore = require("apps/layout/stores/window-store").default
   OutsideClickMixin = require("mixins/outside-click-mixin")
   GlobalAutocompleteSource = require("apps/layout/sources/global-autocomplete-source")
   GlobalAutocompleteStore = require("apps/layout/stores/global-autocomplete-store")
   {createFactory} = require("base/new-utils")
   SearchBox = createFactory require("components/forms/search-box")
   GlobalAutocompleteDropdown = createFactory(require("apps/layout/components/global-autocomplete-dropdown").default)

   createComponent "GlobalAutocomplete",
      mixins: [OutsideClickMixin]
      propTypes:
         initialIndex: ReactLibs.PropTypes.number

      linkButtonRef:  React.createRef()

      getInitialState: ->
         selectedIndex: @props.initialIndex || 0
         is_focused: false
         query: ""

      registerStores: ->
         @on GlobalAutocompleteStore, ->
            results: GlobalAutocompleteStore.getTypeaheadResults()
            loading: GlobalAutocompleteStore.getLoading()

         @on WindowStore, ->
            window_height: WindowStore.getHeight()

         @on CoreSearchStore, ->
            has_multiple_types: CoreSearchStore.getAvailableSearchTypes()?.length > 1
            core_search_config: CoreSearchStore.getConfig()

      componentDidUpdate: ->
         return if _.isEmpty(@state.results) || _.isEmpty(@state.query)
         $element = $(ReactDOM.findDOMNode(@)).find(".global-autocomplete--list")
         $element.scrollToHighlight(".is-selected")
         @highlightQuery(@state.query) if @state.query

      highlightQuery: (text) ->
         elements = $(ReactDOM.findDOMNode(@)).find(".new-contact-card--name strong")
         elements.highlight(text.trim().split(" "), "text-highlight")

      handleKeyPress: (value = "", evnt) ->
         last_item_idx = @state.results.length # pad it +1 for the 'view all thing'
         switch evnt.key
            when "ArrowUp"
               evnt.preventDefault()
               @setState {selectedIndex: Math.max(@state.selectedIndex - 1, @props.initialIndex)}
            when "ArrowDown"
               evnt.preventDefault()
               min_value = if @state.results then (last_item_idx) else @props.initialIndex
               # for the 'View all results' at very bottom
               if @state.selectedIndex == last_item_idx   && @state.has_multiple_types && !_.isEmpty(@state.results)
                  @setState({selectedIndex: -1}, => @linkButtonRef.current?.focus())
               else
                  @setState {selectedIndex: Math.min(@state.selectedIndex + 1, min_value)}
            when "Enter"
               evnt.preventDefault()
               if @state.selectedIndex == last_item_idx
                  @handleGlobalSearch()
               else
                  @handleRowClick(evnt, @state.results?[@state.selectedIndex])
            when "Escape"
               @handleOutsideClick()
            when "Shift"
               return false
            else
               GlobalAutocompleteSource.cancelPreviousSearches()
               query = value.replace(/[\/]/g, " ").trim()
               @setState
                  query: query
                  selectedIndex: @props.initialIndex
                  loading: true

               clearTimeout(@searchDelay)
               @searchDelay = _.wait 750, -> GlobalAutocompleteSource.search(query)
               return undefined

      handleOutsideClick: (evnt) ->
         $(evnt.currentTarget).blur() if evnt
         @refs.search_box.handleClear()
         @setState {query: "", selectedIndex: @props.initialIndex}

      handleRowHighlight: (index) ->
         @setState selectedIndex: index

      handleRowClick: (evnt, result) ->
         if @state.selectedIndex < 0
            @handleGlobalSearch(evnt) if @state.query
         else
            EverTrue.track.setReferrer("typeahead")

            if result?.type == "header"
               EverTrue.track.set "global_search_action", {type: result.key}
               @handleGoToResultType(result.key)
            else
               if result?.type == "ai-search"
                  EverTrue.track.set "global_search_action", {type: "ai-search"}
                  @handleOutsideClick(evnt)
               else   
                  EverTrue.track.set "global_search_action", {type: result.type}
                  route = @state.core_search_config?[result.type]?.route
                  if result?.type != "contacts"
                     params = "?" + $.param({highlight: @state.query})
                  else params = ""
                  _.defer ->
                     EverTrue.Navigator("#{route}/#{result.id}#{params}", {trigger: true})
                  @handleOutsideClick(evnt)

      handleGoToResultType: (type) ->
         GlobalAutocompleteSource.viewAll(type, @state.query, {referrer: "typeahead"})
         @handleOutsideClick()

      handleGlobalSearch: (evnt) ->
         EverTrue.track.set "global_search_action",
            type: "view_all"
         if @state.has_multiple_types
            EverTrue.track.setReferrer("typeahead")
            EverTrue.Navigator("/global-search/results?query=#{encodeURIComponent(@state.query)}", {trigger: true, replace: false})
            @handleOutsideClick(evnt)
         else @handleGoToResultType("contacts")


      render: ->
         div className: classNames("global-autocomplete", @props.className),
            SearchBox
               ref: "search_box"
               className: classNames("global-autocomplete--input",
                  "is-inactive": !(@state.is_focused || @state.query)
               )
               placeholder: if @state.has_multiple_types
                              "Search constituents and interactions..."
                           else
                              "Start searching..."
               onKeyUp: @handleKeyPress
               onChange: (query = "") =>
                  @setState {query: query}
               onFocus: => @setState {is_focused: true}
               onBlur: => @setState {is_focused: false}

            if @state.query
               GlobalAutocompleteDropdown
                  query: @state.query
                  open: @state.open
                  handleInsideClick: @handleInsideClick
                  hasMultipleTypes: @state.has_multiple_types
                  results: @state.results
                  windowHeight: @state.window_height
                  loading: @state.loading
                  selectedIndex: @state.selectedIndex
                  handleRowHighlight: @handleRowHighlight
                  handleRowClick: @handleRowClick
                  handleGoToResultType: @handleGoToResultType
                  linkButtonRef: @linkButtonRef
                  handleGlobalSearch: @handleGlobalSearch
