import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import NotificationDetailsController from 'apps/notifications/controllers/notification-details-controller';

export default class NotificationRouter extends BaseRouter {
  routes = {
    'notification/:id': 'show',
  };

  show(id) {
    this.currentView = <NotificationDetailsController id={Number(id)} />;
    EverTrue.page.mount(this.currentView);
  }
}
