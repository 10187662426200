module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileInteractionsStore = require("apps/profile/stores/profile-interactions-store").default
   ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default
   InteractionsFeed = require("apps/interactions/components/interaction-feed")
   {createFactory} = require("base/new-utils")
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   CommentTextarea = createFactory(require("apps/profile/components/interactions/comment-textarea"))
   InteractionAddBtn = createFactory require("apps/interactions/components/create/interaction-add-btn").default
   { div, span } = ReactLibs.DOMFactories
   Link = createFactory(require("@evertrue/et-components").Link)
   withLDConsumer = require("launchdarkly-react-client-sdk").withLDConsumer

   ProfileRecentInteractionsController = createComponent "ProfileRecentInteractionsController",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      getInitialState: ->
         editing_note: undefined

      registerStores: ->
         @on ProfileInteractionsStore, ->
            comments: ProfileInteractionsStore.getForContact(@props.contact?.id)
            total_comments: ProfileInteractionsStore.getTotal(@props.contact?.id)
            loading: ProfileInteractionsStore.getLoading()

         @on FeatureStore, ->
            has_interaction_writes: FeatureStore.hasInteractionWrites()

      componentDidMount: ->
         ProfileInteractionsSource.fetch(@props.contact?.id)

      componentDidUpdate: (prevProps) ->
         if prevProps.contact?.id != @props.contact?.id
            ProfileInteractionsSource.fetch(@props.contact?.id)

      handleSave: (comment) ->
         ProfileInteractionsSource.saveComment(@props.contact?.id, comment)

      render: ->
         div className: "recent-interactions",
            if @state.has_interaction_writes
               div className: classNames("recent-interactions--creator", "is-empty": _.isEmpty(@state.comments)),
                  ProfileCardHeader
                     title: "Recent Interactions"
                     className: "recent-interactions--header-create"
                     unless _.isEmpty(@state.comments)
                        InteractionAddBtn
                           contact: @props.contact
                           referrer: "profile_interaction_summary"
                           enableInteractionSolicitorType: @props.flags.enableInteractionSolicitorType
                           interactionDescriptionRichTextEditor: @props.flags.interactionDescriptionRichTextEditor
            else
               div
                  className: classNames("recent-interactions--creator", "is-empty": _.isEmpty(@state.comments))
                  ProfileCardHeader title: "Add New EverTrue Comment", icon: "gt-comment"
                  CommentTextarea comment: {}, onSave: @handleSave

            if !_.isEmpty(@state.comments)
               div null,
                  div className: "recent-interactions--feed",
                     InteractionsFeed
                        notes: _.first(@state.comments, 3)
                        loading: @state.loading
                        showContact: false
                        showMeta: false
                        contactId: @props.contact.id

                  if (@state.total_comments > 3 || @state.comments?.length > 3) && @props.contact && !@state.loading
                     Link
                        title: "View all contact interactions"
                        className: "recent-interactions--view-all"
                        href: "/contact/#{@props.contact.id}/notes"
                        "View All"

            else if _.isEmpty(@state.comments) && @state.has_interaction_writes
               div className: "recent-interacions--empty",
                  div null,
                     span className: "fs-exclude-data", "#{EverTrue.store.org?.get("name") || "Your organization"}"
                     " has not logged any interactions with this constituent.
                     Use interactions to report on meetings, phone calls, or other engagements."
                  InteractionAddBtn
                     className: "contact-profile--empty-button"
                     contact: @props.contact
                     referrer: "profile_interaction_summary"
   
   withLDConsumer()(ProfileRecentInteractionsController)
