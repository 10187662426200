import PropTypes from 'prop-types';
import ThreadBody from './thread-body';

const ThreadList = ({ id, threadReplies }) => {
  const emails = threadReplies?.items || [];

  return (
    <div style={{ width: '95%', margin: '0 auto' }}>
      {emails.map((email, index) => (
        <ThreadBody key={email.id} email={email} isLastEmail={index === emails.length - 1} />
      ))}
    </div>
  );
};

ThreadList.propTypes = {
  id: PropTypes.string.isRequired,
  threadReplies: PropTypes.object,
};

export default ThreadList;
