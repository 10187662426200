import {
  RecommendedActionsSearches,
  mapFiltersToLinks,
} from 'apps/home/components/next-steps/recommended-actions/sections/filters/recommend-actions-searches';
import { PropTypes } from 'base/react-libs';

const SearchRecommendations = ({ windfallEnabled }) => {
  const links = mapFiltersToLinks(RecommendedActionsSearches, windfallEnabled);

  return (
    <div className="homepage-recommendations-windfall-content">
      <h2 className="homepage-recommendations-windfall-title">
        We don’t see any assigned constituents with these characteristics today.
      </h2>
      <div className="homepage-recommendations-subtitle">
        Dive into your database with a search of{' '}
        {links.reduce((acc, link, index) => {
          if (index === 0) {
            return [link];
          }
          return [...acc, ' or ', link];
        }, [])}
      </div>
    </div>
  );
};

SearchRecommendations.propTypes = {
  windfallEnabled: PropTypes.bool,
};

export default SearchRecommendations;
