import PropTypes from 'prop-types';
import { proposalSolicitor } from '../utils';

const ProposalTableSolicitors = ({ solicitors, userContactId }) => {
  if (!solicitors.length) return <span>-</span>;

  const userProposalContact = proposalSolicitor(solicitors, userContactId);

  let firstSolicitor = '';

  if (userProposalContact?.id) {
    firstSolicitor = 'You';
  } else {
    const primarySolicitor = solicitors.find((solicitor) => solicitor.type === 'PRIMARY' && solicitor.name);
    firstSolicitor = primarySolicitor?.name || solicitors.find((solicitor) => solicitor.name)?.name || '';
  }

  const solicitorCount = solicitors.filter((solicitor) => solicitor.name).length;
  const otherSolicitors = solicitorCount > 1 ? `+${solicitorCount - 1}` : '';

  return (
    <div>
      <div>
        {firstSolicitor} {otherSolicitors}
      </div>
    </div>
  );
};

ProposalTableSolicitors.propTypes = {
  solicitors: PropTypes.array.isRequired,
  userContactId: PropTypes.number,
};
export default ProposalTableSolicitors;
