import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

const PagedColumnTableRow = (props) => {
  const { data, columns, cells, sortProp, columnWidth, rowClass, onRowClick } = props;

  const handleRowClick = (event) => {
    if (_.isFunction(onRowClick)) {
      onRowClick(data.id, event);
    }
  };

  const renderCells = () => {
    const cellFunctions = _.isFunction(cells) ? cells() : cells;

    return _.map(columns, (config) => {
      if (cellFunctions && _.isFunction(cellFunctions[config.row])) {
        return (
          <td
            key={`row-${config.row}${config.key || ''}`}
            className={classNames(config.className, {
              'table-col-sorted': sortProp && sortProp === config.prop,
              'is-sticky': config.sticky,
            })}
            style={{
              width: config.width,
              maxWidth: !config.width ? columnWidth || 175 : undefined,
            }}
          >
            {cellFunctions[config.row](data, config)}
          </td>
        );
      }
      return null;
    });
  };

  return (
    <tr onClick={handleRowClick} className={rowClass ? rowClass(data) : ''}>
      {renderCells()}
    </tr>
  );
};

PagedColumnTableRow.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.array,
  cells: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  sortProp: PropTypes.string,
  columnWidth: PropTypes.number,
  rowClass: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default PagedColumnTableRow;
