import PropTypes from 'prop-types';
import { ListboxMultiselect } from '@evertrue/et-components';
import { useState, useEffect } from 'react';
import useFetchProposalSolicitor from '../hooks/use-fetch-proposal-solicitor';

const ProposalSolicitorDropdown = ({
  label = 'Select Solicitor(s)',
  emptyLabel = 'Select Solicitor(s)',
  size = 'medium',
  values = [],
  onChange = () => {},
  disabled = false,
}) => {
  const [solicitors, setSolicitors] = useState([]);
  const [filteredSolicitors, setFilteredSolicitors] = useState([]);
  const { loading, solicitorsData } = useFetchProposalSolicitor();

  useEffect(() => {
    if (solicitorsData && !loading) {
      setSolicitors(solicitorsData);
      setFilteredSolicitors(solicitorsData);
    }
  }, [solicitorsData, loading]);

  // Search handler with debouncing
  let searchQueryTimeout = null;
  const handleSearch = (search) => {
    clearTimeout(searchQueryTimeout);
    searchQueryTimeout = setTimeout(() => {
      if (search === '') {
        // If search is cleared, reset to the original list
        setFilteredSolicitors(solicitors);
      } else {
        // Filter the solicitors based on the search query
        const filtered = solicitors.filter((solicitor) => solicitor.name.toLowerCase().includes(search.toLowerCase()));
        setFilteredSolicitors(filtered);
      }
    }, 500);
  };

  const options = filteredSolicitors.map((solicitor) => ({
    label: solicitor.name,
    value: solicitor.contact_id,
  }));

  return (
    <ListboxMultiselect
      emptyValue={emptyLabel}
      label={label}
      size={size}
      searchable={true}
      values={values}
      options={options}
      onChange={(selectedOptions) => onChange(selectedOptions)}
      loading={loading}
      onSearch={handleSearch}
      showInfoBar={true}
      disabled={disabled}
    />
  );
};

ProposalSolicitorDropdown.propTypes = {
  emptyLabel: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ProposalSolicitorDropdown;
