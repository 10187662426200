import { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import PropTypes from 'prop-types';
import CustomImageResize from './utils/custom-image-resize';
import CustomClipboard from './utils/helpers/custom-clipboard';
import DynamicFieldPopup from './dynamic-popup';
import useDynamicFields from './hooks/use-dynamic-fields';

const CustomIcon = `
  <svg viewBox="0 0 1 15" class="dynamic-fields" role="img">
   <title>Add Dynamic Field</title>
   <p id="dynamic-fields" style="margin-top:0.04rem; padding-left:10px;" >{ }</p>
  </svg>
`;

// Create custom Quill formats
const Parchment = Quill.import('parchment');
const BorderRadiusStyle = new Parchment.Attributor.Style('borderRadius', 'border-radius', {
  scope: Parchment.Scope.INLINE,
});
const PaddingStyle = new Parchment.Attributor.Style('padding', 'padding', {
  scope: Parchment.Scope.INLINE,
});
const TextDecorationStyle = new Parchment.Attributor.Style('textDecoration', 'text-decoration', {
  scope: Parchment.Scope.INLINE,
});
const DisplayStyle = new Parchment.Attributor.Style('display', 'display', {
  scope: Parchment.Scope.BLOCK,
});

export const RichTextEditor = ({
  setEditorBody,
  editorBody,
  className,
  quillRef,
  placeholderText = '',
  onTextChange = () => {},
  isSignatureForm = false,
  contact,
  autoReplaceDynamicFields = false,
  enableDynamicFields = false,
  isTemplateBody,
  handleFileUpload,
}) => {
  const quill = quillRef && quillRef.current && quillRef.current.getEditor();
  const fontNames = [
    'arial',
    'Times New Roman, Times, serif',
    'Sans Serif',
    'monospace',
    'arial black',
    'arial narrow',
    'comic sans ms',
    'garamond',
    'georgia',
    'tahoma',
    'trebuchet ms',
    'verdana',
    'brush script mt',
    'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
    'Palatino, Palatino Linotype, Book Antiqua, Georgia, serif',
    'Arial Black, Gadget, sans-serif',
    'impact',
    'Helvetica, Arial, Verdana, sans-serif',
    'Baskerville, Baskerville Old Face, Hoefler Text, Garamond, serif',
  ];
  const TRIM_NEWLINES = /("\\\\n(\\\\n){0,3}"\}\]\})$/;

  const quillContents = quill && quill.getContents();

  const {
    showDynamicPopup,
    setShowDynamicPopup,
    popupPosition,
    filteredFields,
    handleFieldClick,
    dynamicFieldsHandler,
    dynamicPopupViaToolbar,
  } = useDynamicFields(quillRef, contact, isSignatureForm, autoReplaceDynamicFields, isTemplateBody);

  useEffect(() => {
    if (quillRef.current && quillContents) {
      const quill = quillRef.current.getEditor();
      const delta = quill.getContents();

      delta.ops.forEach((op, index) => {
        if (typeof op.insert === 'string' && op.insert?.trim().length === 0) {
          if (op.attributes) {
            const filteredAttributes = Object.keys(op.attributes)
              .filter((key) => key !== 'color' && key !== 'background')
              .reduce((acc, key) => ({ ...acc, [key]: op.attributes[key] }), {});

            op.attributes = filteredAttributes;
          }
        }
      });
      const selection = quill.getSelection();
      quill.setContents(delta);
      quill.setSelection(selection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBody, quillRef]);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const handleClickOutside = (event) => {
        const pattern = /(?:^|\s)ql-picker-label(?:\s|$)/;
        if (
          (quill.hasFocus() || document.activeElement.className.match(pattern)) &&
          !quill.container.contains(event.target) &&
          quill.getFormat().background === '#ACCEF7'
        ) {
          quill.format('background', false);
        }
      };
      document.addEventListener('click', handleClickOutside);
      const backgroundColor = document.querySelector('.ql-background');
      const textColor = document.querySelector('.ql-color');

      const clearSelection = () => {
        const colorOptions = document.querySelectorAll('.ql-color-picker .ql-picker-item');
        colorOptions.forEach((option) => option.classList.remove('ql-selected'));
      };
      backgroundColor.addEventListener('click', clearSelection);
      textColor.addEventListener('click', clearSelection);

      return () => {
        document.removeEventListener('click', handleClickOutside);
        backgroundColor.removeEventListener('click', clearSelection);
        textColor.removeEventListener('click', clearSelection);
      };
    }
  }, [quillRef]);

  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    quill.keyboard.bindings[9].unshift({
      key: 9,
      handler: () => quill.blur(),
    });
    const updatedContent = JSON.stringify(quill.getContents());
    if (updatedContent.match(TRIM_NEWLINES) && quill.hasFocus()) {
      let child = document.querySelector('.ql-size').firstElementChild;
      if (child) {
        child.className = 'ql-picker-label ql-active';
        child.setAttribute('data-value', quill.getFormat().size);
      }
    }
    quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        onTextChange();
      }
    });
  }

  const Quill = ReactQuill.Quill;
  Quill.import('ui/icons')['dynamic-fields'] = CustomIcon;
  const FontAttributor = Quill.import('attributors/style/font');
  FontAttributor.whitelist = fontNames;
  Quill.register(FontAttributor, true);
  const Size = Quill.import('attributors/style/size');
  Size.whitelist = ['13px', '16px', '19px'];
  Quill.register(Size, true);
  Quill.register(Quill.import('attributors/style/direction'), true);
  Quill.register(Quill.import('attributors/style/align'), true);
  Quill.register(Quill.import('attributors/style/color'), true);
  Quill.register('modules/imageResize', CustomImageResize);
  Quill.register('modules/clipboard', CustomClipboard);
  Quill.register(PaddingStyle, true);
  Quill.register(BorderRadiusStyle, true);
  Quill.register(TextDecorationStyle, true);
  Quill.register(DisplayStyle, true);

  let Link = Quill.import('formats/link');

  Link.sanitize = function (url) {
    if (!(url.startsWith('http://') || url.startsWith('https://'))) {
      return 'http://' + url;
    }
    return url;
  };

  const toolbarOptions = [
    [
      { font: fontNames },
      { size: ['13px', '16px', '19px'] },
      'bold',
      'italic',
      'underline',
      { color: [] },
      { background: [] },
    ],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { list: 'ordered' }, { list: 'bullet' }],
    [{ link: 'link' }, 'dynamic-fields'],
  ];

  const modules = {
    toolbar: {
      handlers: {},
      container: toolbarOptions,
    },
    imageResize: { modules: ['Resize'] },
    clipboard: {
      matchVisual: false, // Keep visual format
    },
  };

  Object.assign(modules.toolbar.handlers, { 'dynamic-fields': dynamicFieldsHandler });

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();

    if (!selection) {
      return;
    }

    const cursorPosition = selection.index;
    const file = e.dataTransfer.files[0];

    const [newFile] = await handleFileUpload([file], true);
    editor.insertEmbed(cursorPosition, 'image', newFile.url);
    editor.setSelection(cursorPosition + 1);
  };

  return (
    <>
      <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} style={{ overflow: 'hidden' }}>
        <ReactQuill
          className={className || 'email-form--body-inner'}
          value={editorBody}
          onChange={(e) => setEditorBody(e, quillRef)}
          placeholder={placeholderText}
          modules={modules}
          theme="snow"
          ref={quillRef}
        />
      </div>
      {enableDynamicFields && (
        <DynamicFieldPopup
          showDynamicPopup={showDynamicPopup}
          setShowDynamicPopup={setShowDynamicPopup}
          popupPosition={popupPosition}
          filteredFields={filteredFields}
          handleFieldClick={handleFieldClick}
          dynamicPopupViaToolbar={dynamicPopupViaToolbar}
        />
      )}
    </>
  );
};

RichTextEditor.propTypes = {
  editorBody: PropTypes.string,
  setEditorBody: PropTypes.func,
  className: PropTypes.string,
  quillRef: PropTypes.object,
  placeholderText: PropTypes.string,
  onTextChange: PropTypes.func,
  isSignatureForm: PropTypes.bool,
  contact: PropTypes.object,
  autoReplaceDynamicFields: PropTypes.bool,
  enableDynamicFields: PropTypes.bool,
  isTemplateBody: PropTypes.bool,
  handleFileUpload: PropTypes.func,
};

export default RichTextEditor;
