import EverTrue from 'app';
import OutreachTabGroup from '../components/outreach-tab-group';
import OutreachDraftCard from '../components/outreach-draft-card';
import { useState } from 'react';
import OutreachActionMenu from './outreach-action-menu';
import OutreachScheduleCard from './outreach-schedule-card';
import { useGlobalOutreachProvider } from '../contexts/global-outreach-context';
import OutreachCard from './outreach-card';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { trackOutreachAction } from 'apps/outreach/utils/utils';

const OutreachEmailComponent = () => {
  const { addDrawer, drawers, removeDrawer } = useGlobalDrawerProvider();
  const [activeTab, setActiveTab] = useState('Drafts');
  const [selectedDrafts, setSelectedDrafts] = useState([]);
  const [selectedScheduled, setSelectedScheduled] = useState([]);
  const [selectedSent, setSelectedSent] = useState([]);
  const { drafts, scheduled, deleteDraft, sent, deleteScheduledSendById, draftCount, scheduledCount } =
    useGlobalOutreachProvider();

  const handleDiscardDraft = async (draft) => {
    const confirmed = await new Promise((resolve) => {
      EverTrue.Alert.confirm(
        {
          headline: 'Discard Draft',
          content: `Are you sure you want to discard the draft?`,
          buttonLabel: 'Discard',
        },
        (confirmation) => resolve(confirmation)
      );
    });

    if (confirmed) {
      const drawer = drawers.find((d) => {
        return d.type === 'emailer' && d.childProps?.outreach?.id === draft?.id;
      });
      if (drawer) {
        removeDrawer(drawer.id);
      }
      deleteDraft(draft.id);
      EverTrue.Alert.success(`Draft deleted successfully.`);
    }
  };

  const handleDiscardScheduled = async (schedule) => {
    const confirmed = await new Promise((resolve) => {
      EverTrue.Alert.confirm(
        {
          headline: 'Discard Scheduled',
          content: `Are you sure you want to discard the scheduled email?`,
          buttonLabel: 'Discard',
        },
        (confirmation) => resolve(confirmation)
      );
    });

    if (confirmed) {
      const drawer = drawers.find((d) => {
        return d.type === 'emailer' && d.childProps?.outreach?.id === schedule?.id;
      });
      if (drawer) {
        removeDrawer(drawer.id);
      }
      deleteScheduledSendById(schedule.id);
      EverTrue.Alert.success(`Scheduled Email deleted successfully.`);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '16px' }}>
        <OutreachTabGroup
          activeTab={activeTab}
          onTabChange={(newTab) => {
            const params = new URLSearchParams(window.location.search);
            params.set('tab', newTab);
            window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
            setActiveTab(newTab);
          }}
          draftsCount={draftCount}
          scheduledCount={scheduledCount}
        />
        {activeTab === 'Drafts' && (
          <OutreachActionMenu selected={selectedDrafts} setSelected={setSelectedDrafts} outreachType={activeTab} />
        )}
        {activeTab === 'Scheduled' && (
          <OutreachActionMenu
            selected={selectedScheduled}
            setSelected={setSelectedScheduled}
            outreachType={activeTab}
          />
        )}
      </div>
      <div className="outreach-container">
        {activeTab === 'Drafts' && (
          <div className="outreach-heading">
            <h2>
              {' '}
              <strong>{drafts.length} Drafts</strong>
            </h2>
            {drafts.length &&
              drafts.map((draft) => (
                <OutreachDraftCard
                  key={draft.id}
                  draft={draft}
                  selectedDrafts={selectedDrafts}
                  setSelectedDrafts={setSelectedDrafts}
                  handleOpenDraft={() => {
                    const drawer = drawers.find((d) => {
                      return d.type === 'emailer' && d.childProps?.outreach?.id === draft?.id;
                    });
                    if (!drawer) {
                      trackOutreachAction('open_emailer');
                      addDrawer('emailer', {
                        drawerTitle: `Edit - Draft`,
                        outreach: draft,
                        outreachType: 'Draft',
                      });
                    }
                  }}
                  discard={() => handleDiscardDraft(draft)}
                />
              ))}
          </div>
        )}
        {activeTab === 'Scheduled' && (
          <div className="outreach-heading">
            <h2>
              {' '}
              <strong>{scheduled.length} Scheduled</strong>
            </h2>
            {scheduled.map((schedule) => (
              <OutreachScheduleCard
                key={schedule.id}
                scheduled={schedule}
                selectedScheduled={selectedScheduled}
                setSelectedScheduled={setSelectedScheduled}
                discard={() => handleDiscardScheduled(schedule)}
              />
            ))}
          </div>
        )}
        {activeTab === 'Sent' && (
          <div className="outreach-heading">
            <h2>
              {' '}
              <strong>{sent.length} Sent</strong>
            </h2>
            {sent.map((snt) => (
              <OutreachCard
                key={snt.id}
                outreachEmail={snt}
                selectedOutreaches={selectedSent}
                setSelectedOutreaches={setSelectedSent}
                outreachType="Sent"
                onClick={() => {
                  EverTrue.UrlManager.navigate(`/outreach/email/sent/${snt.nylas_thread_id}`, true, false);
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default OutreachEmailComponent;
