import _ from 'underscore';
import { connect } from '@evertrue/et-flux';
import PropTypes from 'prop-types';
import EverTrue from 'app';
import { useContext, useEffect, Fragment, useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import MapStore from 'apps/map/stores/map-store';
import MapSource from 'apps/map/sources/map-source';
import { getSelectionQueryFromIds } from 'apps/portfolio-performance/portfolio-queries';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import SelectedContactsSource from 'apps/contact/sources/selected-contacts-source';
import PortfolioMetaDataContext from 'apps/portfolio-performance/contexts/portfolio-meta-data-context';
import ProspectSelectionContext from 'apps/portfolio-performance/contexts/prospect-selection-context';
import MapWithClusters from 'apps/map/components/map-with-clusters';
import ClusterContactSource from 'apps/map/sources/cluster-contact-source';
import { getContactsForPortfolioQuery } from 'apps/portfolio-performance/portfolio-queries';
import ContactAssignmentContext from 'apps/portfolio-performance/contexts/contact-assignment-context';
import MapContacts from 'apps/map/controllers/map-contacts-controller';
import { SORT_OPTIONS_MAP, getFormattedTimeInStage } from 'apps/portfolio-performance/portfolio-utils';
import MapRadiusDropdown from 'apps/map/components/map-radius-dropdown';
import GeolocationStore from 'apps/contact/stores/geolocation-store';
import FilterStore from 'apps/filters/stores/filter-store';
import { getZoomForRadius, radiusToLatLng, boundsToBox } from 'apps/map/map-utils';
import FilterSource from 'apps/filters/sources/filter-source';

const MIN_RADIUS_ZOOM = 8;
const google = window.google;

const mapStateToProps = () => ({
  zoom: MapStore.getZoom(),
  clusters: MapStore.getClusters(),
  loading_clusters: MapStore.getLoadingClusters(),
  bounding_box: MapStore.getBoundingBox(),
  selected_contacts: SelectedContactsStore.getSelectedContacts(),
  map: MapStore.getMap(),
  mapCenter: MapStore.getLatLng(),
});

const PortfolioMapController = (props) => {
  const { selected_contacts, bounding_box, zoom, mapCenter, map } = props;
  const { team, solicitor, sort_prop, sort_reverse, setSort } = useContext(PortfolioMetaDataContext);
  const { selected_map_stages, setSelectionCount, setSelectionQuery } = useContext(ProspectSelectionContext);
  const { fetchContactsByStages, prospects_with_assignments, loading } = useContext(ContactAssignmentContext);
  const [circle, setCircle] = useState(null);
  const [setZoom, setSetZoom] = useState(zoom);
  const [locationMarker, setLocationMarker] = useState(null);
  const [currentCenter, setCurrentCenter] = useState(null);
  const [activeMapFilter, setActiveMapFilter] = useState({});
  const { radius: radiusFromFilter } = activeMapFilter || {};

  // Load prospect data for map
  useEffect(() => {
    fetchContactsByStages({
      pool_id: team.id,
      solicitor_id: solicitor.id,
      sort_prop,
      sort_reverse,
      bounds: bounding_box,
      stages: selected_map_stages,
      offset: 0,
      center: currentCenter,
      radius: radiusFromFilter,
    });
    // eslint-disable-next-line
  }, [team.id, solicitor.id, bounding_box, selected_map_stages, sort_prop, sort_reverse]);

  const contacts = _.pluck(prospects_with_assignments, 'contact');

  useEffect(() => {
    EverTrue.track.setReferrer('portfolio_performance_map');
    EverTrue.track.set('viewed', { type: 'portfolio_performance_map' });

    return () => {
      FilterSource.updateMapFilter(bounding_box);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When contacts are selected in the map view, pass on the change to portfolio selection context
  useEffect(() => {
    const contact_ids = selected_contacts;
    const count = !_.isEmpty(contact_ids) ? contact_ids.length : 0;
    const selection_query = getSelectionQueryFromIds(team.id, solicitor.id, contact_ids);
    setSelectionCount(count);
    setSelectionQuery(selection_query);
    // eslint-disable-next-line
  }, [selected_contacts]);

  // This is to load the map clusters when the map tab first loads
  // and to load the clusters when the selected stages change
  useEffect(() => {
    if (bounding_box) {
      MapSource.fetchClusters(zoom, { query: getQuery(bounding_box) });
    }

    if (radiusFromFilter) {
      showRadiusOnMap();
    }
    // eslint-disable-next-line
  }, [bounding_box, selected_map_stages, zoom, prospects_with_assignments, activeMapFilter]);

  const getQuery = (bounds, isCluster) => {
    if (radiusFromFilter && !isCluster) {
      return getContactsForPortfolioQuery(
        sort_prop,
        sort_reverse,
        team.id,
        solicitor.id,
        selected_map_stages,
        bounds,
        null,
        currentCenter,
        radiusFromFilter
      );
    }
    return getContactsForPortfolioQuery(sort_prop, sort_reverse, team.id, solicitor.id, selected_map_stages, bounds);
  };

  const handleMapChange = (zoom, bounds) => {
    if (bounds) {
      MapSource.fetchClusters(zoom, { query: getQuery(bounds) });
    }
    MapSource.setBoundingBox(bounds);
    SelectedContactsSource.clear();
  };

  const handleClusterOpen = (key, cluster = {}) => {
    ClusterContactSource.fetch(key, getQuery(cluster.geobox, true));
  };

  const updateMapFilters = (bounds) => {
    setActiveMapFilter(bounds);
    FilterSource.fetchFilteredClusters();
  };

  const handleSetRadius = (radius) => {
    const object = { ...mapCenter };
    object.radius = radius + 'mi';
    setCurrentCenter(mapCenter);
    updateMapFilters(object);
    const google_formatted_center = { ...object, lng: object.lon };
    setLocationMarker(google_formatted_center);
  };

  const radiusStringToNumber = (string = '') => {
    return Number(string.replace('mi', ''));
  };

  const handleMapInit = (bounds) => {
    const { radius } = activeMapFilter || {};
    if (radius) {
      FilterSource.fetchFilteredClusters();
      MapSource.setBoundingBox(bounds);
    } else {
      MapSource.setBoundingBox(bounds);
      FilterSource.fetchFilteredClusters();
    }
  };

  const showRadiusOnMap = () => {
    const { radius } = activeMapFilter || {};
    const radiusNum = radiusStringToNumber(radius);
    const zoom = getZoomForRadius(radiusNum);
    setSetZoom(zoom);
    if (circle) circle.setMap(null);
    setCircle(
      new google.maps.Circle({
        strokeColor: '#5598ab',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5598ab',
        fillOpacity: 0.35,
        map: map,
        center: radiusToLatLng(activeMapFilter),
        radius: radiusNum * 1609.34, // to meters
      })
    );
  };

  const handleUnsetRadius = () => {
    if (circle) circle.setMap(null);
    const bounds = boundsToBox(map.getBounds());
    updateMapFilters(bounds);
  };
  const canSetRadius = (_.notEmpty(mapCenter) && zoom >= MIN_RADIUS_ZOOM) || radiusFromFilter;
  return (
    <div className="portfolio-performance--map fixed-page-wrapper">
      <MapContacts
        key={contacts.length}
        contacts={{ items: contacts }}
        loading={loading}
        onSort={setSort}
        sort_options={SORT_OPTIONS_MAP}
        sortProp={sort_prop}
        sortReverse={sort_reverse}
        showLastGiftDate={true}
        hideGiving={true}
        hideScores={true}
        contactCardChildren={(contact = {}) => {
          const contact_assignment = _.findWhere(prospects_with_assignments, { id: contact.id }) || {};
          const formatted_stage_start_date = getFormattedTimeInStage(contact_assignment.assignment);

          return (
            <Fragment>
              <div>
                <span className="contact-card--label">Lifetime: </span>
                <span className="contact-card--value">
                  {contact.giving && contact.giving.lifetime_amount
                    ? numeral(contact.giving.lifetime_amount).format('$0,0')
                    : '-'}
                </span>
              </div>

              <div>
                <span className="contact-card--label">Last Gift Date: </span>
                <span className="contact-card--value">
                  {contact.giving && contact.giving.last_gift_date
                    ? moment(contact.giving.last_gift_date, 'YYYY-MM-DD').format('MMM DD, YYYY')
                    : 'N/A'}
                </span>
              </div>

              <div>
                <span className="contact-card--label">Last Contact: </span>
                <span className="contact-card--value">
                  {contact_assignment.assignment.most_recent_contact_date
                    ? moment(contact_assignment.assignment.most_recent_contact_date).fromNow()
                    : '-'}
                </span>
              </div>

              <div>
                <span className="contact-card--label">Time in Stage: </span>
                <span className="contact-card--value">{formatted_stage_start_date}</span>
              </div>
            </Fragment>
          );
        }}
      />

      <div className="map-search-controller">
        <MapWithClusters
          clusters={props.clusters}
          loading={props.loading_clusters}
          defaultBounds={props.bounding_box}
          onMapInit={handleMapInit}
          onMapChange={handleMapChange}
          onClusterOpen={handleClusterOpen}
          setZoom={setZoom}
          setLocationMarker={locationMarker}
        >
          <div className="map-search-controller--filters">
            <MapRadiusDropdown
              value={radiusFromFilter}
              disabled={!canSetRadius}
              onChange={handleSetRadius}
              onClear={handleUnsetRadius}
            />
          </div>
        </MapWithClusters>
      </div>
    </div>
  );
};

PortfolioMapController.propTypes = {
  // from store
  bounding_box: PropTypes.object,
  clusters: PropTypes.object,
  loading_clusters: PropTypes.bool,
  zoom: PropTypes.number,
  selected_contacts: PropTypes.array,
  map: PropTypes.object,
  mapCenter: PropTypes.object,
};

export default connect(
  PortfolioMapController,
  [MapStore, SelectedContactsStore, GeolocationStore, FilterStore],
  mapStateToProps
);
