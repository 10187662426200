import { useState, useEffect } from "react";
import _ from "underscore";
import Api from "entities/helpers/api";
import { connect } from "@evertrue/et-flux";
import Decorator from "clientDecorator";
import PropTypes from "prop-types";
import { useIdentity } from "base/identity-resolver";
import { useGate } from "components/is-gated";
import { AdvancedCombobox, Button, Icon, Radio } from '@evertrue/et-components';
import AllUsersStore from "apps/users/stores/all-users-store";
import { useDebounce } from "apps/portfolio-performance/portfolio-utils";
import DNAStore from "base/dna/dna-store";
import { TEAM_SOLICITORS_QUERY } from "apps/portfolio-performance/portfolio-queries";

const LIMIT = 100;
const mapStateToProps = () => ({
  users: _.sortBy(AllUsersStore.getAllGivingTreeUsers(), "name"),
  interaction_solicitor_limit: _.toNumber(DNAStore.getByKey("interaction_solicitor_limit")),
});

const InteractionFormSolicitors = ({ disabled, interaction_solicitor, handleFormChange, enableInteractionSolicitorType, ...props }) => {
  const [has_rm_assignments] = useGate("rm_assignments");
  const [loadingSolicitors, setLoadingSolicitors] = useState(false);
  const [solicitors, setSolicitors] = useState({});
  const [solicitorTotal, setSolicitorTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { org } = useIdentity();

  const fetchSolicitors = (search_text) => {
    setLoadingSolicitors(true);
    Api.CONTACTS.SEARCH.post({
      params: { limit: LIMIT },
      data: _.jsonStringify(TEAM_SOLICITORS_QUERY(search_text)),
    })
      .then((resp = {}) => {
        const solicitors = {};
        _.each(resp.items, (contact) => {
          const parsed_contact = Decorator.Contacts.parse(contact);
          const remote_id = Decorator.Relationships.getRemoteId(contact);
          parsed_contact.remote_id = remote_id;
          solicitors[parsed_contact.id] = parsed_contact;
        });
        setSolicitors((prev) => {
          return { ...prev, ...solicitors };
        });
        // set total on initial load (when no search text has been entered)
        if (!search_text) {
          setSolicitorTotal(resp.total);
        }
        setLoadingSolicitors(false);
      })
      .catch(() => {
        setLoadingSolicitors(false);
      });
  };

  // load solicitors once if rm_assignments is on
  useEffect(() => {
    if (has_rm_assignments) {
      fetchSolicitors();
    }
  }, [has_rm_assignments]);

  // load solicitors again if search text has been entered
  useEffect(() => {
    if (has_rm_assignments && !!debouncedSearchText) {
      fetchSolicitors(debouncedSearchText);
    }
  }, [debouncedSearchText, has_rm_assignments]);

  // clear search text when on mount
  useEffect(() => {
    return () => {
      setSearchText("");
    };
  }, []);

  const parsedOptions = (selected, options) => {
    return _.filter(
      [
        {
          label: "Current Selections",
          items: selected,
        },
        {
          label: "Options",
          items: _.sortBy(options, "label"),
        },
      ],
      (group) => {
        return group.items.length > 0;
      }
    );
  };

  const getPrimaryIfNone = (solicitors) => {
    const solicitorsWithPrimary = [...solicitors];
    if (!solicitorsWithPrimary.length) return solicitorsWithPrimary;
    const primary = solicitorsWithPrimary.find((solicitor) => solicitor.type === "PRIMARY");
    if (!primary) solicitorsWithPrimary[0].type = "PRIMARY";
    return solicitorsWithPrimary;
  };

  const handleRemoveSolicitor = (contactId, handleSolicitorOnChange, selected_solicitors) => {
    const solicitors = selected_solicitors.filter((solicitor) => solicitor.value !== contactId);
    const solicitorsWithPrimary = getPrimaryIfNone(solicitors);
    handleSolicitorOnChange(solicitorsWithPrimary);
  };

  const handleSolicitorTypeChange = (contactId, type, handleSolicitorOnChange, selected_solicitors) => {
    const solicitors = selected_solicitors.map((solicitor) => {
      if (solicitor.value === contactId) {
        return {
          ...solicitor,
          type: "PRIMARY",
        };
      }
      return {
        ...solicitor,
        type: "SECONDARY",
      };
    });
    handleSolicitorOnChange(solicitors);
  };


  const InteractionSolicitorTypeComponent = ({ selected_solicitors, handleSolicitorOnChange }) => (
    <div className="interaction-form--solicitor-with-type-header">
      <ul>
        {selected_solicitors.map((solicitor) => (
          <li key={solicitor.value} className="interaction-form--list-row">
            <div className="interaction-form--solicitor-column">
              <Radio
                label={`Is ${solicitor.label} the primary solicitor`}
                showLabel={false}
                checked={solicitor.type === "PRIMARY"}
                onChange={(e) => {
                  const isPrimary = e.target.checked;
                  const type = isPrimary ? "PRIMARY" : "SECONDARY";
                  handleSolicitorTypeChange(
                    solicitor.value,
                    type,
                    handleSolicitorOnChange,
                    selected_solicitors
                  );
                }}
                name="is_primary"
              />
              <div>
                <span><strong>{solicitor.label}</strong></span>
                <span>{solicitor.type === "PRIMARY" && <div>Primary</div>}</span>
              </div>
            </div>
            <div className="interaction-form--solicitor-column">
              <Button
                type="simple"
                onClick={() => handleRemoveSolicitor(solicitor.value, handleSolicitorOnChange, selected_solicitors)}
                label="Remove solicitor"
              >
                <Icon
                  icon="cancel"
                  className="interaction-form--cancel"
                  title={`Remove solicitor, ${solicitor.label}`}
                  size={1}
                />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  InteractionSolicitorTypeComponent.propTypes = {
    selected_solicitors: PropTypes.array,
    handleSolicitorOnChange: PropTypes.func,
  }
  // If rm_assignments is on, use a list of solicitors matched by remote id
  if (has_rm_assignments) {
    const options = _.compact(
      _.map(solicitors, (solicitor) => {
        if (solicitor.remote_id && solicitor.name) {
          return { label: solicitor.name, value: solicitor.remote_id };
        }
      })
    );
    const filtered_options = searchText
      ? _.filter(options, (item) => _.searchCompare(item.label, debouncedSearchText))
      : options;

    const selected_solicitors = _.compact(
      _.map(interaction_solicitor, (solicitor) => {
        return (
          _.findWhere(options, { value: solicitor.remote_user_id, type: solicitor.type }) || {
            label: solicitor.name,
            value: solicitor.remote_user_id,
            type: solicitor.type,
          }
        );
      })
    );

    const handleInteractionChange = (val = []) => {
      if (props.interaction_solicitor_limit && val.length > props.interaction_solicitor_limit) {
        return;
      }

      if (val.length === 1) {
        val[0].type = "PRIMARY";
      }

      handleFormChange(
        "solicitor",
        _.map(val, (item) => {
          const selectSolicitor = _.findWhere(solicitors, { remote_id: item.value });
          return _.compactObject({
            name: item.label,
            remote_user_id: item.value,
            type: item.type,
            user_id: selectSolicitor && selectSolicitor.user_id != null ? selectSolicitor.user_id : undefined,
          });
        })
      );
    };
    return (
      <>
        {enableInteractionSolicitorType && (
          <InteractionSolicitorTypeComponent
            selected_solicitors={selected_solicitors || []}
            handleSolicitorOnChange={handleInteractionChange} />
        )}
        <AdvancedCombobox
          label="Select Solicitors"
          options={parsedOptions(selected_solicitors, filtered_options)}
          value={selected_solicitors}
          loading={loadingSolicitors}
          disabled={disabled}
          multiple
          searchable
          grouped
          allowChangeAll={filtered_options.length <= props.interaction_solicitor_limit}
          allowUnselectAll
          // if there are more solicitors to load, the type ahead search will load them
          // because when the searchText changes the fetchSolicitors will re-fire
          onSearch={solicitorTotal > LIMIT ? setSearchText : null}
          onChange={handleInteractionChange}
        />
      </>
    );
    // otherwise let them select any user in the org
  } else {
    const selected_solicitors = _.map(interaction_solicitor, (solicitor) => ({
      label: solicitor.name,
      value: solicitor.user_id || solicitor.id,
      type: solicitor.type,
    }));

    const handleInteractionChange = (val = []) => {
      if (props.interaction_solicitor_limit && val.length > props.interaction_solicitor_limit) {
        return;
      }

      if (val.length === 1) {
        val[0].type = "PRIMARY";
      }

      handleFormChange(
        "solicitor",
        _.map(val, (item) => {
          // get matching user from all user store, then get affiliation for remote id
          const user = _.findWhere(props.users, { id: item.value });
          const affiliation = Decorator.User.getAffiliationFor(user, org.id) || {};
          return _.compactObject({
            name: item.label,
            user_id: item.value,
            type: item.type,
            remote_user_id: affiliation != null ? affiliation.remote_user_id : undefined,
          });
        })
      );
    };

    return (
      <>
        {enableInteractionSolicitorType && (
          <InteractionSolicitorTypeComponent
            selected_solicitors={selected_solicitors || []}
            handleSolicitorOnChange={handleInteractionChange} />
        )}
        <AdvancedCombobox
          label="Select Solicitors"
          className="interaction-form--solicitors"
          value={selected_solicitors}
          options={parsedOptions(
            selected_solicitors,
            _.map(props.users, (data) => ({ label: data.name, value: data.id }))
          )}
          multiple={true}
          disabled={disabled}
          searchable={true}
          grouped
          allowUnselectAll={true}
          placeholder="Select users..."
          onChange={handleInteractionChange}
        />
      </>
    );
  }
};

InteractionFormSolicitors.propTypes = {
  disabled: PropTypes.bool,
  interaction_solicitor: PropTypes.any,
  handleFormChange: PropTypes.func,
  interaction_solicitor_limit: PropTypes.number,
  users: PropTypes.array,
  enableInteractionSolicitorType: PropTypes.bool,
};

export default connect(InteractionFormSolicitors, [AllUsersStore, DNAStore], mapStateToProps);
