import homepageQueries from '../gql/queries';
import { fetchFromGraphql } from 'entities/helpers/gqlCall';
import { getUserContactIdForOrg } from 'apps/tasks/utils/contact-helpers';

//Common function to fetch counts for today/next five days for Journey and Other Tasks based on day range field.
// dayRange 1 : Today's Count
// dayRange 7 : Next Five Days Count
// fetchJourneysTaskCount: If TRUE - Fetches journey counts, if FALSE fetches other task count

//Fetches counts for today/next five days for Journey and Other Tasks based on day range field.
export const getUpcomingTaskCounts = async (
  taskOwnerUserId,
  taskStatuses,
  includeInactive,
  fetchJourneysTaskCount,
  dayRange
) => {
  const query = homepageQueries.fetchUpcomingTaskCounts;
  const queryVars = {
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    fetchJourneysTaskCount,
    dayRange,
  };

  const { getUpcomingTaskCounts } = await fetchFromGraphql(query, queryVars, 'getUpcomingTaskCounts');
  return getUpcomingTaskCounts;
};

/**
 *
 *
 * @returns array with two objects: one for "other" tasks (not in a journey) and one for journey tasks
 * [ { CALL: 0, EMAIL: 1 ... etc}, { CALL : 0, EMAIL: 1 ... etc} ]
 */
export const getTaskCountForToday = async () => {
  const query = homepageQueries.activeTaskCountForToday;

  const { todayActiveTaskCount } = await fetchFromGraphql(query, {}, 'todayActiveTaskCount');
  return todayActiveTaskCount;
};

export const getPausedTaskCount = async (endDate) => {
  const query = homepageQueries.pausedForDaysTaskCount;
  const queryVars = {
    endDate,
  };

  const { pausedForDaysTaskCount } = await fetchFromGraphql(query, queryVars, 'pausedForDaysTaskCount');
  return pausedForDaysTaskCount;
};

//Fetching counts for Next five days for Journeys and Other tasks
export const fetchNextFiveDays = async (taskOwnerUserId, taskStatuses, includeInactive, dayRange) => {
  if (!taskOwnerUserId) return [];
  const nextFiveJourneyTasks = await getUpcomingTaskCounts(
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    true,
    dayRange
  );
  const nextFiveOtherTasks = await getUpcomingTaskCounts(
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    false,
    dayRange
  );

  let merged = [];
  if (nextFiveJourneyTasks && nextFiveOtherTasks) {
    nextFiveJourneyTasks.forEach((journeyDay) => {
      const found = nextFiveOtherTasks.find((taskDay) => taskDay.name === journeyDay.name);
      if (found) {
        const { name, date, ...counts } = found;
        Object.entries(counts).forEach(([key, value]) => {
          journeyDay[key] += value;
        });
      }
      merged.push(journeyDay);
    });

    nextFiveOtherTasks.forEach((taskDay) => {
      const found = merged.find((day) => day.name === taskDay.name);
      if (!found) {
        merged.push(taskDay);
      }
    });

    merged = merged.sort((a, b) => a.date - b.date).slice(0, 5);
  }
  return merged;
};

/**
 * in GQL, there is a query exposed that gives details about a Solicitor Record
 *
 * In our use-case, we don't actually care much about it, just whether any assignments exist.
 * so this returns a boolean indicating if there are any recent assignments.
 *
 * @param {*} user
 * @param {*} org
 * @returns boolean if the user has any recent assignments
 */
export const userHasAssignments = async (user, org) => {
  const userSolicitorContactId = getUserContactIdForOrg(user, org);
  if (!userSolicitorContactId) return false;

  const query = homepageQueries.fetchSolicitorInformation;
  const queryVars = {
    contactId: userSolicitorContactId,
  };

  const { solicitor = {} } = await fetchFromGraphql(query, queryVars, 'solicitor');
  if (!solicitor) return false;

  return solicitor?.assignments_pagination?.count > 0;
};
