import { Component } from 'react';
import PropTypes from 'prop-types';
import EverTrue from 'app';
import moment from 'moment';
import FeatureStore from 'apps/layout/stores/feature-store';
import { connect } from '@evertrue/et-flux';
import { Profile, Work } from 'clientDecorator';
import ProfileCardHeader from 'apps/profile/components/profile-card-header';
import ProfileCardSubtitle from 'apps/profile/components/profile-card-subtitle';
import Career from 'apps/profile/components/templates/career';
import Address from 'apps/profile/components/templates/address';
import Education from 'apps/profile/components/templates/education';
import Email from 'apps/profile/components/templates/email';
import Phone from 'apps/profile/components/templates/phone';
import BioInfoAll from 'apps/profile/components/bio-info-all';
import { getAddresses, getDefaultAddress } from 'mixins/address-mixin';
import Icon from 'components/elements/icon';
import CDCModalController from 'apps/profile/components/cdc/cdc-modal-controller';
import { ModalWrapper, ModalTriggerDeprecated, ModalDeprecated, ModalConfirm } from '@evertrue/et-components';

const mapStateToProps = () => ({
  has_cdc_profile: FeatureStore.hasFeature('cdc_profile'),
});

class BioInfo extends Component {
  static propTypes = {
    contact: PropTypes.object,
    has_cdc_profile: PropTypes.bool,
  };

  static defaultProps = {
    contact: {},
    has_cdc_profile: false,
  };

  handleTrack(type) {
    return EverTrue.track.set('contact_action', {
      type,
      referrer: 'profile_section_bio',
    });
  }

  renderFullData() {
    return (
      <ModalDeprecated width={480} closeOnOutsideClick={false} closeOnEscape={false}>
        <ModalConfirm header="Bio">
          <BioInfoAll contact={this.props.contact} />
        </ModalConfirm>
      </ModalDeprecated>
    );
  }

  renderSuggestModal() {
    return (
      <ModalWrapper
        width={770}
        isOpen
        closeOnOutsideClick={false}
        render={({ close }) => {
          return <CDCModalController contact={this.props.contact} onClose={close} referrer="profile_bio_card" />;
        }}
      />
    );
  }

  renderModalTrigger(count) {
    if (count > 0) {
      return (
        <ModalTriggerDeprecated modal={this.renderFullData()}>
          <span className="bio-info--title-more">
            {' '}
            (+
            {count} more)
          </span>
        </ModalTriggerDeprecated>
      );
    } else return null;
  }

  renderSuggestTrigger() {
    if (this.props.has_cdc_profile) {
      return (
        <ModalTriggerDeprecated modal={this.renderSuggestModal()} className="bio-info--title-suggest">
          <>
            <Icon icon="upload-contact" before />
            Suggest Contact Update
          </>
        </ModalTriggerDeprecated>
      );
    } else return null;
  }

  renderContactTitle(title, count) {
    return (
      <span className="bio-info--contact-title">
        <h1 style={{ fontSize: '14px' }}>
          {title}
          {this.renderModalTrigger(count)}
        </h1>
        {this.renderSuggestTrigger()}
      </span>
    );
  }

  renderTitle(title, count) {
    return (
      <h1 className="bio-info--title" style={{ fontSize: '14px' }}>
        {title}
        {this.renderModalTrigger(count)}
      </h1>
    );
  }

  renderBioTitle() {
    const title = (() => {
      let timestamp;
      if ((timestamp = this.props.contact.updated_at)) {
        const date = moment(timestamp).format('MMMM Do YYYY, h:mm a');
        const ago = moment(timestamp).fromNow();
        return `Contact Record updated: ${date} \n(${ago})`;
      } else {
        return "no 'updated_at' property found";
      }
    })();
    return (
      <h1 title={title} className="profile-card-header--title">
        Bio
      </h1>
    );
  }

  getContactInfoCount() {
    let count = 0;
    const addresses = getAddresses(this.props.contact);
    const contact_info = Profile.getContactInfo(this.props.contact);

    count += Math.max(addresses.length - 1, 0);
    count += Math.max((contact_info.phones != null ? contact_info.phones.length : undefined) - 1, 0);
    count += Math.max((contact_info.emails != null ? contact_info.emails.length : undefined) - 1, 0);
    return count;
  }

  render() {
    const positions = Work.getAllPositions(this.props.contact);
    const educations = Profile.getParsedEducation(this.props.contact);

    const primary_work = Work.getCurrentCompany(this.props.contact);
    const primary_contact_info = Profile.getPrimaryContactInfo(this.props.contact);
    const primary_address = getDefaultAddress(this.props.contact);
    const primary_education = Profile.getParsedEducation(this.props.contact)?.[0];

    return (
      <div className="bio-info">
        <ProfileCardHeader title={this.renderBioTitle()}>
          <ModalTriggerDeprecated className="pull-right" modal={this.renderFullData()}>
            View All
          </ModalTriggerDeprecated>
        </ProfileCardHeader>

        <div className="bio-info--section">
          <ProfileCardSubtitle label={this.renderTitle('Career', positions.length - 1)} />
          <Career data={primary_work} />
        </div>

        <div className="bio-info--section">
          <ProfileCardSubtitle label={this.renderTitle('Education', educations.length - 1)} />
          <Education data={primary_education} />
        </div>

        <div className="bio-info--section">
          <ProfileCardSubtitle label={this.renderContactTitle('Primary Contact Info', this.getContactInfoCount())} />
          <Phone data={primary_contact_info.phone} />
          <Email data={primary_contact_info.email} onClick={() => this.handleTrack('email')} />
          <Address data={primary_address} onClick={() => this.handleTrack('address')} />
        </div>
      </div>
    );
  }
}
export default connect(BioInfo, [FeatureStore], mapStateToProps);
