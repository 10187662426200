import { useState } from "react";
import PropTypes from "prop-types";

export const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="settings--accordion-item">
      <div className="settings--accordion-header" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`}></i>
      </div>
      {isOpen && <div className="settings--accordion-content">{children}</div>}
    </div>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
