import { useCallback, useEffect, useState } from 'react';
import { Card, Loading, ModalHeaderWithActions } from '@evertrue/et-components';
import { useIdentity } from 'base/identity-resolver';
import { setPersonalVideoDefaultSettings, setPersonalVideoDefaultLogo } from '../../entities/helpers/thankview-helper';
import useUserAuthentication from './hooks/useUserAuthentication';
import { FileUploadWithPreview } from 'components/thankview/shared/file-upload-with-preview';
import ToggleSwitch from 'components/thankview/shared/toggle';
import { useThankviewSettingsContext } from './hooks/thankview-settings-context';
import ErrorLogger from 'entities/helpers/error-log-helper';
import { useRecorder } from './hooks/recorder-context';
import { formatURL, validateUrl } from './utils/url-helpers';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const ThankviewDefaultSettings = () => {
  const { org } = useIdentity();
  const { clientId } = useUserAuthentication();
  const { handleCloseThankviewModal, handleChangeModalView } = useSignalVideoModalContext();
  const { selectedVideo, setSelectedVideo, updateState, stopAndResetRecorder } = useRecorder();
  const { defaultUserSettings, loadingDefaultSettings, setDefaultUserSettings } = useThankviewSettingsContext();
  const [isModalSubmitDisabled, setIsModalSubmitDisabled] = useState(false);

  // disabling linting because eslint thinks useCallback needs an inline function, not a nested function (debounce in this case)
  // see here: https://stackoverflow.com/questions/69830440/react-hook-usecallback-received-a-function-whose-dependencies-are-unknown-pass
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveSettings = useCallback(
    debounce(async (updatedSettings) => {
      updatedSettings.logo_path = updatedSettings.logo_url;
      try {
        const { data } = await setPersonalVideoDefaultSettings(
          {
            ...updatedSettings,
            ...(selectedVideo && { video_id: selectedVideo.personalVideo.id }),
          },
          clientId.data.client_id,
          clientId.data.client_secret
        );
        if (data[1]) {
          setSelectedVideo((prevVideo) => ({
            ...prevVideo,
            personalVideo: {
              ...prevVideo.personalVideo,
              ...data[1].updated_video,
            },
          }));
        }
      } catch (e) {
        ErrorLogger.error('Error uupdating default settings', {
          extra: { e, page_name: 'Signal Video Default Settings' },
        });
      }
    }, 300),
    [selectedVideo, clientId]
  );
  const handleFileSelect = async (file) => {
    try {
      let logoUrl = '';
      if (file) {
        const { data } = await setPersonalVideoDefaultLogo(
          file,
          clientId.data.client_id,
          clientId.data.client_secret,
          selectedVideo ? selectedVideo.personalVideo.id : null
        );
        setDefaultUserSettings((prev) => ({
          ...prev,
          logo_url: file ? URL.createObjectURL(file) : '',
        }));
        logoUrl = data.default_logo_path;
      } else {
        await setPersonalVideoDefaultSettings(
          {
            ...defaultUserSettings,
            logo_path: null,
            ...(selectedVideo && { video_id: selectedVideo.personalVideo.id }),
          },
          clientId.data.client_id,
          clientId.data.client_secret
        );
        setDefaultUserSettings((prev) => ({
          ...prev,
          logo_url: '',
        }));
      }
      if (selectedVideo) {
        setSelectedVideo((prevVideo) => ({
          ...prevVideo,
          personalVideo: {
            ...prevVideo.personalVideo,
            logo_url: logoUrl,
          },
        }));
      }
    } catch (error) {
      console.error('Failed to upload logo:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedSettings = { ...defaultUserSettings, [name]: value };
    setDefaultUserSettings(updatedSettings);
    if (name === 'button_url' && defaultUserSettings.button_enabled) {
      if (validateUrl(value)) {
        debouncedSaveSettings({ ...updatedSettings, button_url: formatURL(value) });
      }
    } else {
      debouncedSaveSettings(updatedSettings);
    }
  };

  const handleToggle = (setting) => {
    let updatedSettings = { ...defaultUserSettings };
    if (setting === 'button_enabled' && defaultUserSettings.button_enabled) {
      updatedSettings.button_enabled = false;
      updatedSettings.show_in_email = false;
    } else {
      updatedSettings[setting] = !defaultUserSettings[setting];
    }
    setDefaultUserSettings(updatedSettings);
    debouncedSaveSettings(updatedSettings);
  };

  useEffect(() => {
    const isInvalidUrl = !validateUrl(defaultUserSettings.button_url) && defaultUserSettings.button_enabled;
    setIsModalSubmitDisabled(isInvalidUrl);
  }, [setIsModalSubmitDisabled, defaultUserSettings.button_enabled, defaultUserSettings.button_url]);

  return (
    <>
      <ModalHeaderWithActions
        closeModal={() => handleCloseThankviewModal(stopAndResetRecorder)}
        title="Your Settings"
        onSubmit={() => {
          handleChangeModalView('prev');
          updateState({ videoUrl: '' });
        }}
        submitLabel="Next"
        closeLabel="Close"
        disableSubmit={isModalSubmitDisabled}
      />
      <div>
        {loadingDefaultSettings ? (
          <Card headingText="Landing Page Settings" padding="16px" className="default-settings-font">
            <Loading />
          </Card>
        ) : (
          <>
            <Card headingText="Landing Page Settings" padding="16px" className="default-settings-font">
              <ToggleSwitch
                label="Show Logo on Landing Page"
                checked={defaultUserSettings.logo_enabled}
                onChange={() => handleToggle('logo_enabled')}
              />
              {defaultUserSettings.logo_enabled && (
                <FileUploadWithPreview
                  businessId={org.id}
                  clientId={clientId?.data?.client_id}
                  clientSecret={clientId?.data?.client_secret}
                  onFileSelect={handleFileSelect}
                  initialImageUrl={defaultUserSettings.logo_url}
                />
              )}
            </Card>
            <Card headingText="Call-To-Action Button Settings" padding="16px" className="default-settings-font">
              <ToggleSwitch
                label="Toggle Call-To-Action Button"
                checked={defaultUserSettings.button_enabled}
                onChange={() => handleToggle('button_enabled')}
              />
              {defaultUserSettings.button_enabled && (
                <>
                  <input
                    label="Button Text"
                    type="text"
                    value={defaultUserSettings.button_text}
                    onChange={handleInputChange}
                    className="button-input"
                    name="button_text"
                    placeholder="Enter Button Text"
                  />
                  <input
                    label="Button Link"
                    type="text"
                    className="button-input"
                    value={defaultUserSettings.button_url}
                    onChange={handleInputChange}
                    name="button_url"
                    placeholder="Enter Button Link URL"
                  />
                  <p
                    className={
                      !validateUrl(defaultUserSettings.button_url) && defaultUserSettings.button_enabled
                        ? 'url-warning'
                        : 'url-warning url-warning--hidden'
                    }
                  >
                    Please enter a valid URL
                  </p>
                </>
              )}
              <ToggleSwitch
                label="Show Button in Email"
                checked={defaultUserSettings.show_in_email}
                onChange={() => handleToggle('show_in_email')}
              />
            </Card>
          </>
        )}
      </div>
    </>
  );
};
export default ThankviewDefaultSettings;
