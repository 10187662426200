import PropTypes from 'prop-types';
import classNames from 'classnames';

const FixedTableHeader = ({ columns = [], columnWidth = 175, resizeLastColumn }) => {
  return (
    <thead className="paged-col-table--header table--header">
      <tr>
        {columns.map((column, index) => {
          const lastItem = resizeLastColumn && index === columns.length - 1;

          return (
            <th
              key={column.key || column.prop}
              className={classNames(column.className, { 'is-sticky': column.sticky })}
              style={{
                width: lastItem ? 'auto' : column.width,
                minWidth: lastItem && column.width !== 'auto' ? columnWidth : undefined,
                maxWidth: column.width || columnWidth,
              }}
            >
              <span className="ellipsis-overflow">{column.label}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

FixedTableHeader.propTypes = {
  columns: PropTypes.array,
  columnWidth: PropTypes.number,
  resizeLastColumn: PropTypes.bool,
};

export default FixedTableHeader;
