import _ from 'underscore';
import { Component } from 'react';
import Icon from 'components/elements/icon';
import Popover from 'components/overlays/popover';
import classNames from 'classnames';
import { getRadiusOptions } from 'apps/map/map-utils';

class MapRadiusDropdown extends Component {
  static propTypes = {
    value: ReactLibs.PropTypes.string,
    onChange: ReactLibs.PropTypes.func,
    onClear: ReactLibs.PropTypes.func,
    disabled: ReactLibs.PropTypes.bool,
  };

  render() {
    return (
      <Popover
        className="map-address-filter"
        options={{ arrowColor: '#3F565E', arrowBorderColor: '#3F565E' }}
        trigger={
          <div>
            {this.props.value || (
              <Icon
                className={this.props.disabled ? 'icon-muted' : 'icon-highlight'}
                icon="map-marker-radius"
                size={1}
              />
            )}
          </div>
        }
        render={({ close }) => (
          <div className="map-address-filter--content">
            <div className="map-address-filter--header">Search by Radius</div>
            {this.props.disabled ? (
              <div className="map-radius-dropdown--disabled-helptip">
                Zoom in to or enter an address to search constituents in a set distance from map center.
              </div>
            ) : (
              <div className="map-address-filter--body">
                {_.map(getRadiusOptions(), (radius) => (
                  <div
                    key={radius}
                    role="button"
                    className={classNames('map-radius-dropdown--option', {
                      'is-selected': `${radius}mi` === this.props.value,
                    })}
                    tabIndex="0"
                    onClick={() => {
                      this.props.onChange(radius);
                      close();
                    }}
                  >
                    {radius} mi
                  </div>
                ))}
                {this.props.value && (
                  <a
                    className="map-radius-dropdown--clear"
                    role="button"
                    onClick={() => {
                      this.props.onClear();
                      close();
                    }}
                    tabIndex="0"
                  >
                    Clear Selection
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      />
    );
  }
}

export default MapRadiusDropdown;
