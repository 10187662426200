import Api from 'entities/helpers/api';

const useEmailTemplates = () => {
  const fetchEmailTemplatePrompts = async () => {
    return await Api.AI_V2.PROMPTS.get({
      params: { filter: 'templates/email' },
      success: (resp) => {
        return resp;
      },
      error() {
        throw new Error('Fetching email prompts failed!');
      },
    });
  };

  return {
    fetchEmailTemplatePrompts,
  };
};

export default useEmailTemplates;
