import PropTypes from 'prop-types';
import { ActionsMenu } from '@evertrue/et-components';

const FolderActions = ({
  hasSelectedItems,
  enableAllActions,
  handleDeleteFolder,
  handleRemoveItems,
  handleItemDelete,
  folderType,
}) => {
  const generalOptions = [
    {
      id: 1,
      icon: 'delete',
      label: 'Delete Folder',
      onClick: handleDeleteFolder,
    },
  ];

  const allOptions = [
    {
      id: 2,
      icon: 'cancel-circle',
      label: folderType === 'DYNAMIC' ? 'Remove Saved Search(es) from Folder' : 'Remove List(s) from Folder',
      onClick: handleRemoveItems,
    },
    {
      id: 3,
      icon: 'delete',
      label: folderType === 'DYNAMIC' ? 'Delete Saved Search(es)' : 'Delete List(s)',
      onClick: handleItemDelete,
    },
    {
      id: 4,
      icon: 'delete',
      label: 'Delete Folder',
      onClick: handleDeleteFolder,
    },
  ];

  const getOptions = () => {
    const options = [];

    if (!hasSelectedItems) {
      options.push({
        id: 'general-options',
        items: generalOptions,
      });
    } else {
      options.push({
        id: 'all-options',
        items: enableAllActions ? allOptions : allOptions.filter((option) => option.id !== 3),
      });
    }

    return options;
  };

  const options = getOptions();
  return options.length ? <ActionsMenu options={options} /> : null;
};

FolderActions.propTypes = {
  hasSelectedItems: PropTypes.bool.isRequired,
  enableAllActions: PropTypes.bool.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  handleRemoveItems: PropTypes.func.isRequired,
  handleItemDelete: PropTypes.func.isRequired,
  folderType: PropTypes.oneOf(['STATIC', 'DYNAMIC']).isRequired,
};

export default FolderActions;
