import { SearchInput, AdvancedCombobox, Button, Icon, ButtonTooltip } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DateRangeFilter from './video-library-date-picker';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const filterTypes = [
  { label: 'Date Created', value: 'created_at', defaultOrder: 'desc' },
  { label: 'Date Modified', value: 'updated_at', defaultOrder: 'desc' },
  { label: 'Title', value: 'name', defaultOrder: 'asc' },
  { label: 'Duration', value: 'video_length', defaultOrder: 'asc' },
];

const ControlTools = ({
  searchText,
  setSearchText,
  count,
  selectedFilter,
  setSelectedFilter,
  isAscOrder,
  setIsAscOrder,
  onDateRangeChange,
  totalCount,
}) => {
  const { handleChangeModalView } = useSignalVideoModalContext();

  return (
    <div className="video-library--control-tools">
      <div className="video-library--top-row">
        <SearchInput
          value={searchText}
          onChange={setSearchText}
          width={350}
          placeholder="Search for a Video"
          debounceTimeout={300}
        />
        <div className="video-count">{totalCount} Videos</div>
        <ButtonTooltip
          trigger={
            <Icon icon="settings" onClick={() => handleChangeModalView('settings')} className="settings-icon"></Icon>
          }
        >
          <div>Edit Default Settings</div>
        </ButtonTooltip>
      </div>
      <div className="video-library--bottom-row">
        <p>Sort By</p>
        <AdvancedCombobox onChange={setSelectedFilter} value={selectedFilter} options={filterTypes} />
        <Button
          className={classNames('order-button', { 'is-asc': isAscOrder })}
          onClick={() => setIsAscOrder(!isAscOrder)}
          label="order of videos"
        >
          <Icon icon="arrow-down" iconKit="gt" />
        </Button>
        <span style={{ marginTop: '10px' }}>
          <DateRangeFilter onDateRangeChange={onDateRangeChange} />
        </span>
      </div>
    </div>
  );
};

ControlTools.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  selectedFilter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    defaultOrder: PropTypes.string.isRequired,
  }).isRequired,
  totalCount: PropTypes.number,
  setSelectedFilter: PropTypes.func.isRequired,
  isAscOrder: PropTypes.bool.isRequired,
  setIsAscOrder: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
};

export default ControlTools;
