import Api from 'entities/helpers/api';
import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const updateEmail = async (payload, id) => {
  try {
    const res = await Api.OUTREACH.EMAIL.put({
      urlArgs: { id: id },
      data: JSON.stringify(payload),
    });
    EverTrue.Alert.success(`Email updated successfully.`);
    return res;
  } catch (e) {
    ErrorLogger.error('Error while updating email ', { extra: { e, page_name: 'Outreach Email' } });
  }
};
