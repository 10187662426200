import PropTypes from 'prop-types';
import EverTrue from 'app';
import Nav from 'components/lists/nav';
import NavItem from 'components/lists/nav-item';
import _ from 'underscore';
import { useContext } from 'react';
import { TasksNotificationContext } from '../hooks/tasks-notification-context.js';
import TaskNotificationBadge from 'apps/tasks/task-notification-badge';

const TasksNav = ({ tab, contact = {} }) => {
  const { enabled, loaded, tasksDueCount } = useContext(TasksNotificationContext);
  const { owned = 0, assigned = 0 } = tasksDueCount;
  return (
    <Nav
      active={tab}
      className="tasks--nav"
      onSelect={(selected_tab) => {
        if (_.isEmpty(contact)) {
          EverTrue.UrlManager.navigate(`/tasks/${selected_tab}`, true, false);
        } else if (selected_tab === 'ai-tasks') {
          EverTrue.UrlManager.navigate(`contact/${contact.id}/task/ai`, true, false);
        } else {
          EverTrue.UrlManager.navigate(`contact/${contact.id}/task/${selected_tab}`, true, false);
        }
      }}
    >
      <NavItem activeWhen="" label="Your Tasks">
        {enabled && loaded && <TaskNotificationBadge count={owned} />}
      </NavItem>
      <NavItem activeWhen="assigned" label="Assigned By You">
        {enabled && loaded && <TaskNotificationBadge count={assigned} />}
      </NavItem>
      <NavItem activeWhen="ai-tasks" label="AI Suggestions" />
    </Nav>
  );
};

TasksNav.propTypes = {
  tab: PropTypes.string,
  contact: PropTypes.object,
};

export default TasksNav;
