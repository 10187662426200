import PropTypes from "prop-types";
import { Icon, Button } from "@evertrue/et-components";

const IconButton = ({ className, icon, innerClassName, ...props }) => {
  return (
    <Button type="simple" className={className} {...props}>
      <Icon icon={icon} className={innerClassName} />
    </Button>
  );
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  innerClassName: PropTypes.string,
};

export default IconButton;
