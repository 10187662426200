import PropTypes from 'prop-types';

const BulkConflictedTasks = ({ conflictingTasksDetails = {}, hasConflictingTasks }) => {
  const renderConflictingTasks = () => {
    let totalDisplayedTasks = 0;

    const taskElements = Object.entries(conflictingTasksDetails)
      .filter(([_, tasks]) => tasks.length > 1)
      .map(([contactJourneyId, tasks]) => {
        if (totalDisplayedTasks >= 6) return null;

        const contact = tasks[0]?.contact;
        const contactName = contact?.contact_attributes?.name_full;

        const visibleTasks = tasks.slice(0, 6 - totalDisplayedTasks);
        totalDisplayedTasks += visibleTasks.length;

        return (
          <div key={contactJourneyId} className="conflict-group">
            <div>
              <strong>Contact Name:</strong> {contactName}
              <ul>
                {visibleTasks.map((task) => {
                  const metadata = task?.metadata;
                  if (!metadata) return null;

                  const { journey_name, step_number, day_number } = metadata;

                  return (
                    <li key={task.id}>
                      <strong>Cadence:</strong> {journey_name} - Step {step_number} | Day {day_number}
                    </li>
                  );
                })}
              </ul>
              <br />
            </div>
          </div>
        );
      });

    if (totalDisplayedTasks >= 6) {
      taskElements.push(
        <div key="global-and-more" className="conflict-group">
          <ul>
            <li>...and more</li>
          </ul>
        </div>
      );
    }

    return taskElements;
  };

  return (
    <div className="bulk-conflict-tasks">
      {hasConflictingTasks && (
        <div>
          <p>
            <br /> <strong>Heads up!</strong> <br />
            Due date updates are disabled when multiple tasks for the same constituent are bulk-selected in a cadence.
            To keep cadence step spacing accurate, select only one task per cadence. If an earlier task is updated,
            subsequent tasks will be automatically adjusted according to cadence spacing.
          </p>
          {renderConflictingTasks()}
        </div>
      )}
    </div>
  );
};

BulkConflictedTasks.propTypes = {
  conflictingTasksDetails: PropTypes.object.isRequired,
  hasConflictingTasks: PropTypes.bool.isRequired,
};

export default BulkConflictedTasks;
