import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TempTable from './ai-search-temp-table';
import Loading from 'components/elements/loading';
import { Button, Icon } from '@evertrue/et-components';
import useExecuteQuery from './use-execute-query';
import RenderableQuery from './renderable-query';
import AISearchErrorPage from './ai-search-error-page';
import colors from 'base/colors';

const NLSHome = ({ searchQuery }) => {
  const [query, setQuery] = useState(searchQuery);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  const {
    data,
    loading,
    errors,
    warnings,
    suggestedSearches,
    totalResults,
    renderableQuery,
    originalQuery,
    setRenderableQuery,
    executeQuery,
  } = useExecuteQuery(searchQuery);

  const handleSubmitChanges = () => {
    const updatedQuery = renderableQuery
      .map((part) => (part.type === 'text' ? part.value : part.value.value))
      .join(' ');
    setQuery(updatedQuery);  
    executeQuery(updatedQuery);
  };

  const handleSuggestedSearchClick = (text) => {
    setQuery(text);
    executeQuery(text);
  };

  const columns = [
    { header: 'ID', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
  ];

  /* check if renderableQuery hasChanges from the initial originalQuery */
  const hasChanges = JSON.stringify(renderableQuery) !== JSON.stringify(originalQuery);

  return (
    <div className="ai-search-container">
      {loading ? (
        <div className="loader-div">
          <Loading position="center" />
        </div>
      ) : (
        <>
          <div className="ai-search-header">
            <h1>AI Search Result</h1>
          </div>
          {errors.length > 0 ? (
            <AISearchErrorPage
              query={query}
              suggestedSearches={suggestedSearches}
              handleSuggestedSearchClick={handleSuggestedSearchClick}
            />
          ) : (
            <>
              <div className="ai-search-content">
                {!hasChanges && (
                  <>
                    <strong> Search Results for </strong>
                    <Icon icon="nls-ai-spark" iconKit="gt2" style={{ color: colors.purple500, fontSize: '25px' }} />
                  </>
                )}
                <RenderableQuery renderableQuery={renderableQuery} setRenderableQuery={setRenderableQuery} />
                {hasChanges && (
                  <Button type="secondary" className="query-submit" onClick={handleSubmitChanges}>
                    Submit Changes
                  </Button>
                )}
              </div>
              {warnings.length > 0 && (
                <div className="original-query">
                  <strong>Search Instead for </strong>
                  <a href="#" onClick={() => handleSuggestedSearchClick(query)}>
                    {query}
                  </a>
                </div>
              )}
              <p className="total-results">Total Results: {totalResults}</p>
              <TempTable data={data} columns={columns} />
            </>
          )}
        </>
      )}
    </div>
  );
};

NLSHome.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default NLSHome;
