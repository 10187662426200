import { Device } from '@twilio/voice-sdk';
import { createAction } from 'apps/tasks/utils/tasks-actions';
import Api from 'entities/helpers/api';
import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const establishTwilioDevice = async () => {
  // Fetch unique token and instantiate Device
  const { token } = await Api.OUTREACH.VOIP_TOKEN.get();
  const twilioDevice = await new Device(token);

  // EVENT LISTENERS

  // If there is an error, show a message to user and log it in Sentry
  twilioDevice.on('error', (error) => {
    EverTrue.Alert.error('Sorry, there was an issue with our Caller integration. Please try again soon.', {
      id: 4,
      timeout: 10000,
    });
    ErrorLogger.error('Twilio.Device Error', { extra: { error, page_name: 'dialer' } });
  });

  // in the event tokens expire, refresh token
  twilioDevice.on('tokenWillExpire', async () => {
    const { token } = await Api.OUTREACH.VOIP_TOKEN.get();
    twilioDevice.updateToken(token);
  });

  return twilioDevice;
};

export const createActionFromCall = async (callObject, startTime, contactId, activeCallNotes) => {
  const endTime = new Date().getTime();

  const { _isAnswered: answered, _wasConnected: wasConnected } = callObject;
  const twoWayCommunication = !!(answered && wasConnected);
  let durationInSeconds = 0;
  if (twoWayCommunication) {
    durationInSeconds = Math.floor((endTime - startTime) / 1000);
  }

  const action = {
    contact_id: contactId,
    two_way_communication: twoWayCommunication,
    duration_in_seconds: durationInSeconds,
    status: 'SUCCESS',
    call_notes: activeCallNotes,
  };

  await createAction(action);
};
