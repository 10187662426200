import { useState } from 'react';
import { PropTypes } from 'base/react-libs';
import { setTaskCompletion } from 'apps/tasks/utils/tasks-actions';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useOpenClose } from '@evertrue/et-components';
import EndJourneyModal from 'apps/journeys/components/modals/end-journey-modal';
import TaskRow from './task-row';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';

const TaskRowDetails = ({ step, isFinalStep }) => {
  const [taskToEnd, setTaskToEnd] = useState({});
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const { journey = {}, optionalStateManagementFunction } = useJourneyDetailProvider();

  const completeTaskStateUpdates = async (task, step) => {
    const markComplete = task.completed_at ? false : true;
    const type = markComplete ? 'complete_step' : 'uncomplete_step';
    trackJourneysAction('complete_toggle', type);
    const { completed_at } = await setTaskCompletion(task, markComplete);
    optionalStateManagementFunction('completionToggle', { task, step, completed_at });
  };

  const handleCompletionClick = async (task) => {
    const { completed_at } = task;
    if (isFinalStep && !completed_at) {
      setTaskToEnd(task);
      isModalOpen ? closeModal() : openModal();
      return;
    }
    completeTaskStateUpdates(task, step);
  };

  const { tasks } = step;

  return (
    <div>
      {tasks &&
        tasks.map((task) => (
          <TaskRow key={task.id} step={step} task={task} handleCompletionClick={handleCompletionClick} />
        ))}
      <EndJourneyModal
        isOpen={isModalOpen}
        onClose={closeModal}
        task={taskToEnd}
        step={step}
        completeTaskStateUpdates={completeTaskStateUpdates}
        currentJourney={journey}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    </div>
  );
};

TaskRowDetails.propTypes = {
  step: PropTypes.object,
  tasks: PropTypes.array,
  isFinalStep: PropTypes.bool,
};

export default TaskRowDetails;
