/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import { Button, ModalHeaderCustom } from '@evertrue/et-components';
import OverlayInstructions from './overlay-instructions';
import ResolutionComponent from './thankview-recorder-resolutions';
import { useRecorder } from './hooks/recorder-context';
import VideoPlayer from './thankview-recorder-base';
import CountdownTimer from './countdown-timer';
import DeviceSelector from './device-selector';
import useDebounce from 'hooks/use-debounce';
import { ProgressBar } from './hooks/use-progress-bar';
import VideoControl from './recorder-components/video-controls';
import { useSignalVideoModalContext } from 'apps/signal-video/hooks/use-signal-video-modal-context';

const ScriptToggle = ({ showScript, onToggle }) => (
  <Button className="et--button-secondary" onClick={onToggle}>
    {showScript ? 'Hide Script' : 'Show Script'}
  </Button>
);

ScriptToggle.propTypes = {
  showScript: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const ThankviewRecorder = ({ uploadVideo, processingVideoState }) => {
  const {
    state,
    videoRef,
    enableWebcam,
    startRecording,
    stopRecording,
    rerecord,
    startCountdown,
    resetTimestamp,
    selectedAudioDevice,
    selectedVideoDevice,
    audioDevices,
    videoDevices,
    setSelectedAudioDevice,
    setSelectedVideoDevice,
    color,
    trackColor,
    stopAndResetRecorder,
  } = useRecorder();
  const { handleCloseThankviewModal, handleChangeModalView } = useSignalVideoModalContext();
  const { webcamStream, webcamEnabled, isRecording, videoUrl } = state;
  const [showScript, setShowScript] = useState(false);
  const [script, setScript] = useState('');
  const debouncedScript = useDebounce(script, 500);

  useEffect(() => {
    const controls = document.querySelector('.controls');
    if (controls) {
      controls.style.backgroundColor = color;
    }
    const slider = document.querySelector('.time-track');
    if (slider) {
      slider.style.setProperty('--track-color', trackColor);
    }
  }, [color, trackColor]);

  useEffect(() => {
    const savedScript = localStorage.getItem('thankview-script');
    if (savedScript) {
      setScript(savedScript);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('thankview-script', script);
  }, [debouncedScript]);

  const formatTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    return minutes.toString().padStart(2, '0') + ':' + remainingSeconds.toString().padStart(2, '0');
  };

  const toggleScript = () => setShowScript(!showScript);
  const handleScriptChange = (event) => setScript(event.target.value);

  const handleEnableWebcam = async () => {
    try {
      const constraints = {
        audio: { deviceId: selectedAudioDevice ? { exact: selectedAudioDevice } : undefined },
        video: { deviceId: selectedVideoDevice ? { exact: selectedVideoDevice } : undefined },
      };
      await enableWebcam(constraints);
    } catch (error) {
      EverTrue.Alert.error(`Error enabling webcam ${error}`);
    }
  };
  const handleStartRecording = async () => {
    resetTimestamp();
    await startCountdown();
    startRecording();
  };

  const handleStopRecording = async () => {
    try {
      await stopRecording({ script }, uploadVideo);
      setShowScript(false);
      localStorage.removeItem('thankview-script');
      setScript('');
      handleChangeModalView('review');
    } catch (error) {
      console.error('Error creating recording:', error);
    }
  };

  return (
    <>
      <ModalHeaderCustom>
        <h3>{processingVideoState ? 'Upload Video' : 'Record New Video'}</h3>
        <Button onClick={() => handleCloseThankviewModal(stopAndResetRecorder)}>Close Window</Button>
      </ModalHeaderCustom>
      <div id="thankview-recorder-container" className="thankview-recorder-container">
        {showScript && (
          <OverlayInstructions script={script} modifyScript={handleScriptChange} toggleOff={toggleScript} />
        )}
        {webcamStream &&
          !isRecording &&
          !state.isCountingDown && ( // Hide DeviceSelector and ResolutionComponent when recording
            <>
              <div className="recorder-top-buttons" style={{ zIndex: 1002 }}>
                <div className="top-left-buttons">
                  <div className="timestamp">{formatTime(state.timestamp)}/5:00</div>
                </div>
                {/* Only show ResolutionComponent and DeviceSelector after enabling the webcam and not recording */}
                {webcamEnabled && (
                  <div className="top-right-buttons">
                    <ResolutionComponent />
                    <DeviceSelector
                      devices={audioDevices}
                      selectedDevice={selectedAudioDevice}
                      onDeviceChange={setSelectedAudioDevice}
                      label="Microphone"
                    />
                    <DeviceSelector
                      devices={videoDevices}
                      selectedDevice={selectedVideoDevice}
                      onDeviceChange={setSelectedVideoDevice}
                      label="Camera"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        {webcamStream && isRecording && (
          <div className="recorder-top-buttons" style={{ zIndex: 1002 }}>
            <div className="top-left-buttons">
              <div className="timestamp">{formatTime(state.timestamp)}/5:00</div>
            </div>
          </div>
        )}
        {!videoUrl && !state.isCountingDown && !processingVideoState && (
          <div className="tv-recorder-buttons">
            <ScriptToggle showScript={showScript} onToggle={toggleScript} />
            <VideoControl
              isRecording={isRecording}
              webcamEnabled={webcamEnabled}
              onEnableWebcam={handleEnableWebcam}
              onStartRecording={handleStartRecording}
              onStopRecording={handleStopRecording}
              onRerecord={rerecord}
              state={state}
              processingVideoState={processingVideoState}
            />
          </div>
        )}
        {videoRef && !processingVideoState && (
          <div className="video-container">
            <VideoPlayer
              id="recorder"
              ref={videoRef}
              controls={false}
              stream={webcamStream}
              autoPlay={true}
              isRecording={true}
            />
            {state.isCountingDown && <CountdownTimer onCountdownEnd={handleStartRecording} />}
          </div>
        )}
        {processingVideoState && <ProgressBar processingVideoState={processingVideoState} />}
      </div>
    </>
  );
};

ThankviewRecorder.propTypes = {
  uploadVideo: PropTypes.func.isRequired,
  processingVideoState: PropTypes.string.isRequired,
};

export default ThankviewRecorder;
