import EverTrue from "app";
import BaseRouter from "base/base-router";
import IsFlaggedController from "components/flags/is-flagged-controller";
import AISearchHomeController from "./controller/ai-search-home-controller";

class AISearchRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "ai/search(/)?(query=*query)": "index",
    };
  }

  index(query) {
    EverTrue.execute("setBrowserTitle", "AI Search");
    const searchQuery = EverTrue.UrlManager.get("query")
    this.currentView = (
      <IsFlaggedController flag="nlsEarlyAdopter" navigatePath="/home">
        <AISearchHomeController query={searchQuery}/>
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

AISearchRouter.initClass();
export default AISearchRouter;
