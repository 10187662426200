import { useContext, useState, useEffect } from 'react';
import _ from 'underscore';
import EverTrue from 'app';
import Api from 'entities/helpers/api';
import { useDebounce } from 'apps/portfolio-performance/portfolio-utils';
import { FilterPopover, Icon } from '@evertrue/et-components';
import ContactAssignmentContext from 'apps/portfolio-performance/contexts/contact-assignment-context';
import { AssignmentTitleResolver } from 'apps/portfolio-performance/components/assignment-title-resolver';
import FeatureStore from 'apps/layout/stores/feature-store';
import PortfolioMetaDataContext from 'apps/portfolio-performance/contexts/portfolio-meta-data-context';
import { getAssignmentTitles } from 'apps/portfolio-performance/portfolio-requests';

const defaultFilters = {
  key_prospect: [],
  assignment_title: [],
  name: [],
};

const PROSPECT_NAME_QUERY = (search_text, pool_id, solicitor_id) => ({
  must: !search_text
    ? []
    : _.map(search_text ? search_text.split(' ') : undefined, (name) => ({
        name_first: { wildcard: `${name}*` },
        name_last: { wildcard: `${name}*` },
      })),
  sort: [{ name_first: { order: 'asc' } }, { name_last: { order: 'asc' } }],
  has_child: [
    {
      type: 'assignment',
      query: {
        must: [
          { parent_role: { match: 'prospect' } },
          { pool_id: { match: pool_id } },
          { solicitor_contact_id: { match: solicitor_id } },
        ],
      },
    },
  ],
});

const gql = String.raw;
const query = gql`
  query ($ids: [BigInt!]) {
    contacts(ids: $ids) {
      id
      contact_attributes {
        name_full
      }
    }
  }
`;

const PortfolioFilters = () => {
  const { filters = defaultFilters, setFilters } = useContext(ContactAssignmentContext);
  const { team, solicitor, setDisableDrag } = useContext(PortfolioMetaDataContext);
  const [localFilters, setLocalFilters] = useState(filters);
  const [searchText, setSearchText] = useState();
  const [options, setOptions] = useState([]);
  const debouncedSearchText = useDebounce(searchText, 300);
  const { assignment_options } = AssignmentTitleResolver();
  const [allTitles, setAllTitles] = useState([]);

  const getFilterConfig = (name_options) => {
    let filterConfig = {
      name: {
        title: 'Name',
        type: 'dropdown',
        options: name_options,
        multiple: true,
        searchable: true,
        allowChangeAll: true,
        allowUnselectAll: true,
        onSearch: (text) => {
          setSearchText(text);
        },
      },
      assignment_title: {
        title: 'Assignment Title',
        type: 'dropdown',
        options: allTitles,
        multiple: true,
        searchable: true,
        allowChangeAll: true,
        allowUnselectAll: true,
      },
    };

    if (FeatureStore.hasFeature('rm_key_prospect')) {
      filterConfig.key_prospect = {
        title: 'Key Prospect',
        type: 'checkbox',
        options: [
          {
            name: 'Show Key Relationships only',
            key: 'key_prospect',
          },
        ],
      };
    }
    return filterConfig;
  };

  // loads prospect's names for the "name" filter
  // it uses search + graphql instead of contacts search so its faster
  useEffect(() => {
    if (team.id && solicitor.id) {
      // get the contact ids from search
      Api.SEARCH.CONTACTS.post({
        params: { limit: 250 },
        data: _.jsonStringify(PROSPECT_NAME_QUERY(debouncedSearchText, team.id, solicitor.id)),
      }).then((search_resp = {}) => {
        const contact_ids = search_resp.items || [];
        // use the contact ids to retrieve the full name from graph ql without having to load the whole contact obj
        Api.GRAPHQL.GRAPHQL.post({
          data: JSON.stringify({
            operationName: null,
            query: String.raw`${query}`,
            variables: { ids: contact_ids },
          }),
        }).then((resp = {}) => {
          const contacts = resp.data.contacts || [];
          const options = contacts.map(({ id, contact_attributes = {} }) => {
            return { value: id, label: contact_attributes.name_full };
          });
          setOptions(options);
        });
      });
    }
  }, [debouncedSearchText, solicitor.id, team.id]);

  useEffect(() => {
    const getAndSetAllTitles = async () => {
      const titles = await getAssignmentTitles();
      if (assignment_options) {
        titles.push(...assignment_options.map((obj) => obj.value));
      }
      const uniqueTitles = [...new Set(titles)];
      const uniqueTitlesObj = uniqueTitles.map((val) => ({
        value: val,
        label: val,
      }));
      setAllTitles(uniqueTitlesObj);
    };
    getAndSetAllTitles();
  }, [assignment_options]);

  return (
    <FilterPopover
      placement="bottom-start"
      className="portfolio-filter"
      buttonClassName="et--plain-button segment-filters--toggle"
      buttonText={(count, is_open) => {
        return (
          <div className="segment-filters--toggle-wrap">
            <Icon icon="filter" size={1} />
            <span>Filters</span>
            <Icon icon={is_open ? 'chevron-up' : 'chevron-down'} size={1} />
          </div>
        );
      }}
      buttonType="default"
      config={getFilterConfig(options)}
      activeFilters={localFilters}
      onFilterChange={setLocalFilters}
      onApply={() => {
        EverTrue.track.set('portfolio_performance_action', { type: 'filter_key_prospects' });

        // Set the filters regardless of their state
        setFilters(localFilters);
      }}
      onClear={() => {
        // only clear the filters if some filter has been applied already
        if (!_.isEmpty(filters.key_prospect) || !_.isEmpty(filters.assignment_title) || !_.isEmpty(filters.name)) {
          setLocalFilters(defaultFilters);
          setFilters(defaultFilters);
        }
      }}
      onOpen={() => {
        // dont allow prospect cards to be dragged while filters is open
        setDisableDrag(true);
      }}
      onClose={() => {
        // when filters is closed, allow dragging again
        setDisableDrag(false);
      }}
    />
  );
};

export default PortfolioFilters;
