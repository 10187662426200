import PropTypes from 'prop-types';
import classNames from 'classnames';
import EllipsisOverflow from 'components/formatting/ellipsis-overflow';

const ContactDataList = ({ datalist = [] }) => {
  const renderDatalist = () => {
    return datalist
      .filter((item) => item && typeof item === 'object')
      .map((item, index) => (
        <EllipsisOverflow
          key={item.key || index}
          className={classNames('new-contact-card--datalist-item', item.className)}
        >
          {item.label && <span className="new-contact-card--datalist-label">{item.label}:&nbsp;</span>}
          {item.value ? (
            <span className="new-contact-card--datalist-value">{item.value}</span>
          ) : (
            <span className="new-contact-card--datalist-value text-placeholder-light">Not Provided</span>
          )}
        </EllipsisOverflow>
      ));
  };

  return <div className="new-contact-card--datalist">{renderDatalist()}</div>;
};

ContactDataList.propTypes = {
  datalist: PropTypes.array.isRequired,
};

export default ContactDataList;
