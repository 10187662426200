import { PropTypes } from 'base/react-libs';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { fetchContactsById } from 'apps/volunteers/vms-queries';
import { useEffect, useState } from 'react';
import { useContactModalContext } from 'apps/profile/contexts/contact-modal-context';
import { useGlobalDrawerProvider } from 'components/drawer/context/global-drawer-context';
import { ThreeDotActionsMenu, Modal } from '@evertrue/et-components';
import { getContactEmailAddress, getGqlContactName } from 'apps/journeys/utils/helpers/utils';
import Api from 'entities/helpers/api';
import EverTrue from 'app';
import Decorator from 'clientDecorator';
import DPSRowDetails from '../row-details/dps-row-details';
import GiftRowDetails from '../row-details/gift-row-details';
import InteractionForm from 'apps/interactions/components/create/interaction-form';
import AddToJourneyModal from 'apps/journeys/components/modals/add-to-journey-modal';
import CompleteTaskToggle from 'apps/tasks/components/complete-task-toggle';
import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import CareerMoveRowDetails from '../row-details/career-move-row-details';
import TripsAndMeetingsRowDetails from '../row-details/trips-meetings-row-details';

const RecommendedActionRow = ({
  icon,
  iconKit,
  recommendation = {},
  type = 'gift',
  first = false,
  subtitle,
  emailButtonLabel,
  secondaryActionLabel,
  handleViewClick,
}) => {
  const { addDrawer } = useGlobalDrawerProvider();
  const { contact, id } = recommendation;
  const { active_journeys, id: contact_id } = contact;
  const { openContactModal } = useContactModalContext();
  const email_address = getContactEmailAddress(contact);

  const [parsedContact, setParsedContact] = useState([]);

  useEffect(() => {
    const getAndSetContactDetails = async (id) => {
      const { items } = await fetchContactsById({ contactIds: [id] });
      const [fullContact] = items;
      const decoratedContact = Decorator.Contacts.parse(fullContact);
      setParsedContact(decoratedContact);
    };
    getAndSetContactDetails(contact_id);
  }, [contact_id]);

  const { flagEnabled: hasAI } = useFeatureFlag('aiGeneral');

  const sayThanksAction = {
    id: 1,
    onClick: () => {
      if (hasAI) {
        openContactModal();
      } else {
        addDrawer('emailer', {
          contact,
          recipients: email_address === '' ? [] : [email_address],
          drawerTitle: getGqlContactName(contact) || 'New Message',
        });
      }
      EverTrue.track.set('home_screen', { type: 'recommended_action' });
      EverTrue.Navigator(`/contact/${contact.id}`, true);
    },
    label: emailButtonLabel,
    icon: 'email',
    iconKit: 'gt',
  };

  const moveToCadenceAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => {
      EverTrue.track.set('home_screen', { type: 'recommended_action' });
      return (
        <>
          {secondaryActionLabel ? (
            <Modal isOpen={is_open} closeModal={close} size="medium">
              <InteractionForm contact={parsedContact} requestHide={close} />
            </Modal>
          ) : (
            <AddToJourneyModal
              contact={contact}
              onClose={close}
              isOpen={is_open}
              activeJourneys={active_journeys ? active_journeys : []}
              recommendedAction={true}
            />
          )}
        </>
      );
    },
    label: secondaryActionLabel || 'Add to Cadences',
    icon: icon || 'cadences',
    iconKit: iconKit || 'gt2',
  };

  const handleCompletionClick = async () => {
    EverTrue.track.set('home_screen', { type: 'recommended_action' });
    handleViewClick(recommendation);

    await Api.JOURNEYS.RECOMMENDED_ACTIONS_VIEW.put({
      urlArgs: { id: id },
    });
  };

  return (
    <div className="recommended-action-row--container">
      <div className="recommended-action-row--contact">
        <CompleteTaskToggle task={{ completed_at: false }} size="small" onClick={handleCompletionClick} />
        <JourneyContactCard contact={contact} />
      </div>
      {type === 'gift' && <GiftRowDetails giving={contact.giving || {}} first={first} subtitle={subtitle} />}
      {type === 'career' && <CareerMoveRowDetails contact={contact} />}
      {type === 'trips' && <TripsAndMeetingsRowDetails contact={contact} />}
      {type === 'dps' && <DPSRowDetails contact={contact} giving={contact.giving || {}} />}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ThreeDotActionsMenu options={[sayThanksAction, moveToCadenceAction]} iconSize={1} />
      </div>
    </div>
  );
};

RecommendedActionRow.propTypes = {
  recommendation: PropTypes.object,
  type: PropTypes.string,
  first: PropTypes.bool,
  subtitle: PropTypes.string,
  emailButtonLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  handleViewClick: PropTypes.func,
  icon: PropTypes.string,
  iconKit: PropTypes.string,
};

export default RecommendedActionRow;
