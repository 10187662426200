import _ from 'underscore';
import PropTypes from 'prop-types';
import Decorator from 'clientDecorator';
import Avatar from 'components/elements/avatar';
import { useCachedContactByIdsResolver } from '@evertrue/vms-insights';

const ProposalTableProspects = ({ proposal }) => {
  const contactIds = _.pluck(proposal.contacts, 'contact_id');
  const { contacts } = useCachedContactByIdsResolver(contactIds);
  const primaryContact = proposal.contacts.find((contact) => contact.type === 'PRIMARY');
  const contact = contacts[primaryContact.contact_id];
  const name = Decorator.Contacts.getFullName(contact);
  const avatarUrl = Decorator.Profile.getAvatar(contact);
  const prospectCount = proposal.contacts.length;

  return (
    <div className="proposal-table--prospect">
      <Avatar url={avatarUrl} name={name} width={40} className="proposal-table--avatar" />
      <div>
        <div>
          {name} {prospectCount > 1 && `+${prospectCount - 1}`}
        </div>
      </div>
    </div>
  );
};

ProposalTableProspects.propTypes = {
  proposal: PropTypes.object.isRequired,
};

export default ProposalTableProspects;
