module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   OrgSource = require("base/org/org-source").default
   ProfileSource = require("apps/profile/sources/profile-source")
   UrlSource = require("apps/layout/sources/url-source")
   ProfileMatchingSource = require("apps/contact/sources/profile-matching-source")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   ProfileRelationshipsSource = require("apps/profile/sources/profile-relationships-source")
   Decorator = require("clientDecorator")

   ERROR = "error"

   createStore "ProfileStore",
      getInitialState: ->
         profiles: {}
         lids_profiles: {}
         contact_relationships: {}
         search_relationships: {}
         sessions: {}
         online_gifts: {}
         last_contact_dates: {}
         loading: false
         loading_lids: false
         loading_gifts_ids: []
         profile_error: null
         selected_section: "summary"

      registerActions: ->
         @on ProfileSource.actions.loadingProfile, @respondToLoadingProfile
         @on ProfileSource.actions.fetchedProfile, @respondToFetchedProfile
         @on ProfileSource.actions.loadingLIDS, @respondToLoadingLIDS
         @on ProfileSource.actions.fetchedLIDS, @respondToFetchedLIDS
         @on ProfileSource.actions.fetchedCommunitySessions, @respondToFetchedCommunity
         @on ProfileSource.actions.fetchedLastContactDate, @respondToFetchedLastContactDate
         @on ProfileSource.actions.loadingOnlineGiving, @respondToLoadingOnlineGiving
         @on ProfileSource.actions.fetchedOnlineGiving, @respondToFetchedOnlineGiving
         @on ProfileSource.actions.selectedSection, @respondToSelectSection

         @on ProfileMatchingSource.actions.matched, @respondToMatchChange
         @on ProfileMatchingSource.actions.unmatched, @respondToMatchChange

         @on ProfileRelationshipsSource.actions.fetchedContactRelationships, (contact_id, resp) ->
            @saveToCache("contact_relationships", contact_id, resp)

         @on ProfileRelationshipsSource.actions.fetchedSearchRelationships, (contact_id, resp) ->
            @saveToCache("search_relationships", contact_id, resp)

         @on ProfileSource.actions.errorFetchingProfile, @respondToProfileError
         @on ProfileSource.actions.errorFetchingLIDS, (id) =>
            @respondToFetchedLIDS(id, ERROR)

         @on AssignProspectSource.actions.changedAssignment, (contact_id) =>
            if @hasListeners() then ProfileSource.fetch(_.first(contact_id))

         @on OrgSource.actions.newOrg, =>
            @setState
               profiles: {}
               lids_profiles: {}
               fetched_top_fields: false
               top_fields: []

         @on UrlSource.actions.initializedUrl, (url) =>
            if url.section then @setState({selected_section: url.section})

      respondToLoadingProfile: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetchedProfile: (profile_data) ->
         cached_profiles = _.extend {}, @getState("profiles")
         if _.size(cached_profiles) > 100 then cached_profiles = {}
         cached_profiles[profile_data.id] = profile_data

         if profile_data.user_id
            ProfileSource.fetchCommunitySessions(profile_data.id, profile_data.user_id)

         @setState {profiles: cached_profiles}

      respondToLoadingLIDS: (is_loading) ->
         @setState {loading_lids: is_loading}

      respondToFetchedLIDS: (id, lids) ->
         cached_profiles = _.extend {}, @getState("lids_profiles")
         if _.size(cached_profiles) > 100 then cached_profiles = {}
         cached_profiles[id] = lids
         @setState {lids_profiles: cached_profiles}

      saveToCache: (cache_key, contact_id, value) ->
         arr = _.last(_.map(@getState(cache_key), (val, key) -> [val, key]), 100)
         new_state = _.reduce(arr, ((obj, [val, key]) -> _.toObject(key, val, obj)), {})
         new_state[contact_id] = value
         @setState _.toObject(cache_key, new_state)

      respondToFetchedCommunity: (id, session_count) ->
         cached_sessions = _.extend {}, @getState("sessions")
         if _.size(cached_sessions) > 100 then cached_sessions = {}
         cached_sessions[id] = session_count
         @setState {sessions: cached_sessions}

      respondToFetchedLastContactDate: (id, date) ->
         last_contact_dates = _.cloneData @getState("last_contact_dates")
         if _.size(last_contact_dates) > 100 then last_contact_dates = {}
         last_contact_dates[id] = date
         @setState {last_contact_dates: last_contact_dates}

      respondToLoadingOnlineGiving: (id) ->
         @setState loading_gifts_ids: @getState("loading_gifts_ids").concat(id)

      respondToFetchedOnlineGiving: (id, gifts) ->
         cached_gifts = _.extend {}, @getState("online_gifts")
         if _.size(cached_gifts) > 100 then cached_gifts = {}
         cached_gifts[id] = gifts
         @setState
            online_gifts: cached_gifts
            loading_gifts_ids: _.without(@getState("loading_gifts_ids"), id)

      respondToOnlineGivingError: (id) ->
         @setState loading_gifts_ids: _.without(@getState("loading_gifts_ids"), id)

      respondToSelectSection: (key) ->
         @setState {selected_section: key || "summary"}
         if key then UrlSource.change({section: key})
         else UrlSource.clear("section")

      respondToMatchChange: (contact_id) ->
         EverTrue.Alert.success "The Profile has been successfully matched to the constituent.",
         ProfileSource.fetch(contact_id)
         ProfileSource.fetchLIDS(contact_id)

      respondToProfileError: (id) ->
         @setState {profile_error: id}

      api:
         getProfile: (id) ->
            lids = @getState("lids_profiles")?[id]
            _.extend {id: _.toNumber(id)}, @getState("profiles")?[id],
               lids: if lids != ERROR then lids
               lids_error: lids == ERROR

         getSelectedSection: ->
            @getState("selected_section")

         getFacebookLink: (id) ->
            data = @getProfile(id)
            if link = data?.lids?.facebook?.properties?.link then link
            else if data?.identities?.facebook_id
               "http://facebook.com/#{data?.identities?.facebook_id}"

         getLastContactDate: (id) ->
            @getState("last_contact_dates")?[id]

         getProfileError: (id) ->
            @getState("profile_error") == id

         getLoadingLids: ->
            @getState("loading_lids")

         getLoadingProfile: ->
            @getState("loading")

         getCommunityUsage: (id) ->
            @getState("sessions")?[id]

         getRelationships: (contact_id) ->
            contact = @getState("profiles")?[contact_id]
            contact_relationships = @getState("contact_relationships")[contact_id] || {}
            search_relationships = @getState("search_relationships")[contact_id] || {}

            Decorator.Relationships.getRelationships(contact, contact_relationships, search_relationships)

         hasFacebookMatch: (id) ->
            Decorator.Profile.hasFacebookMatch(@getState("profiles")[id], @getState("lids_profiles")[id])

         getOnlineGivingInfo: (id) ->
            info = @getState("online_gifts")[id]
            loading = _.contains(@getState("loading_gifts_ids"), id)
            last_event = info?.items?[0]
            return {
               loading: loading and not info
               amount: last_event?.charge?.amount_in_cents
               date: last_event?.enqueued_at
            }
