import { useState, useCallback, useEffect } from 'react';
import Api from 'entities/helpers/api';
import EverTrue from 'app';

export const useFolderDetails = (id) => {
  const [folderLists, setFolderLists] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [folderType, setFolderType] = useState('');
  const [folderOwner, setFolderOwner] = useState('');
  const [loading, setLoading] = useState(true);

  // API to get folder lists mapping
  const getFolderLists = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await Api.CONTACTS.FOLDER_LIST.get({
        urlArgs: { id: id },
      });
      setFolderListsMapping(resp);
    } catch (error) {
      console.error('Failed to fetch folder lists', error);
      EverTrue.Alert.error('Failed to fetch folder lists');
    } finally {
      setLoading(false);
    }
  }, [id]);

  // Get folder lists mapping on component mount
  useEffect(() => {
    getFolderLists();
  }, [getFolderLists]);

  const setFolderListsMapping = (folderLists) => {
    setFolderName(folderLists.title);
    setFolderLists(folderLists.contactLists);
    setFolderType(folderLists.type);
    setFolderOwner(folderLists.userId);
  };

  // API to update a folder
  const updateFolder = (newName) => {
    return Api.CONTACTS.FOLDER.put({
      urlArgs: { id: id },
      data: JSON.stringify({ title: newName, type: folderType }),
    }).then(() => {
      setFolderName(newName);
      EverTrue.Alert.success('Folder name updated');
    });
  };

  // API to delete a list from folder
  const removeListFromFolder = (folderId, listId) => {
    return Api.CONTACTS.FOLDER_LISTS.delete({
      params: { folder_id: folderId, list_id: listId },
    });
  };

  // API to delete the folder
  const deleteFolder = (folderId) => {
    return Api.CONTACTS.FOLDER.delete({
      urlArgs: { id: folderId },
    }).then(() => {
      setTimeout(() => {
        if (EverTrue.UrlManager.hasPreviousPath()) {
          EverTrue.Alert.success(`${folderName} deleted`);
          setTimeout(() => window.history.back(), 0);
        } else {
          let route = folderType === 'STATIC' ? '/lists' : '/segments';
          EverTrue.Alert.success(`${folderName} deleted`);
          EverTrue.Navigator(route, { trigger: true });
        }
      }, 300);
    });
  };

  // Update folder lists mapping when lists are removed from the folder or deleted entirely
  const updateFolderLists = (listIds) => {
    const updatedLists = folderLists.filter((list) => !listIds.includes(list.id));
    setFolderLists(updatedLists);
  };

  return {
    folderLists,
    folderName,
    folderType,
    folderOwner,
    loading,
    updateFolder,
    removeListFromFolder,
    deleteFolder,
    updateFolderLists,
  };
};
