import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import TasksController from 'apps/tasks/controllers/tasks-controller';
import { TasksProvider } from 'apps/tasks/hooks/tasks-context';
import IsFlaggedController from 'components/flags/is-flagged-controller';

class TasksRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'tasks(/)': 'index',
      'tasks/assigned': 'assigned',
      'tasks/ai-tasks': 'aiTasks',
    };
  }

  index() {
    EverTrue.execute('setBrowserTitle', 'Tasks');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <TasksProvider>
          <TasksController tab="" userType="OWNER" />
        </TasksProvider>
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }

  assigned() {
    EverTrue.execute('setBrowserTitle', 'Tasks | Assigned');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <TasksProvider>
          <TasksController tab="assigned" userType="CREATOR_BUT_NOT_OWNER" />
        </TasksProvider>
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
  aiTasks() {
    EverTrue.execute('setBrowserTitle', 'Task Suggestions | AI Tasks');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <TasksProvider>
          <TasksController tab="ai-tasks" userType="OWNER" />
        </TasksProvider>
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

TasksRouter.initClass();
export default TasksRouter;
