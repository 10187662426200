import { PropTypes } from 'base/react-libs';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import FormField from 'components/forms/form-field';
import { bulkSkipTasks } from 'apps/journeys/utils/api-requests/modal-actions';
import ErrorLogger from 'entities/helpers/error-log-helper';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';

/**
 * The BulkSkipModal component renders a modal for bulk skipping selected tasks to the next step in a journey.
 *
 * @param {Object} props - The props for the component.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {Object} props.journey - The current journey object containing the step information for the selected tasks.
 * @param {Array} props.selectedTaskIds - Array of IDs of the selected tasks to be skipped.
 * @param {function} [props.optionalStateManagementFunction] - Optional function for managing external state after skip action.
 */
const BulkSkipModal = ({
  onClose,
  isOpen,
  journey = {},
  steps = [],
  selectedTaskIds,
  optionalStateManagementFunction,
}) => {
  const getStepForSelectedTasks = (selectedTaskIds, nextStep = false) => {
    let matchedStep = null;

    if (!steps || !steps?.length) {
      return 'Cadence steps not found';
    }

    // Find the step that all selected tasks are in
    for (const step of steps) {
      // Check if tasks exist in the step
      const stepTaskIds = step?.tasks?.map((task) => task.id) || [];

      // Check if all selected task IDs are present in this step
      const allTasksMatch = selectedTaskIds.every((taskId) => stepTaskIds.includes(taskId));

      if (allTasksMatch) {
        matchedStep = step;
        break; // Once we find the matched step, no need to continue looping
      }
    }

    // If we want the next step, find the step after the matched step
    if (nextStep && matchedStep) {
      const matchedIndex = steps.findIndex((step) => step === matchedStep);

      // Check if the matched index is valid and the following step exists
      const followingStep = steps[matchedIndex + 1];

      return followingStep
        ? `Step ${followingStep.step_number} | Day ${followingStep.day_number} ${followingStep.title}`
        : 'This is the last step in this cadence';
    }

    // Return details of the matched step or a fallback message
    return matchedStep
      ? `Step ${matchedStep.step_number} | Day ${matchedStep.day_number} ${matchedStep.title}`
      : 'Multiple Steps selected or no match found';
  };

  const handleBulkSkip = async (selectedTaskIds) => {
    try {
      const payload = { task_ids: selectedTaskIds };
      await bulkSkipTasks(payload);

      optionalStateManagementFunction('bulkRemoveTasks', { taskIds: selectedTaskIds });
      trackJourneysAction('bulk_skip_step');
      onClose();
    } catch (error) {
      ErrorLogger.error('Error bulk skipping step', {
        extra: { error, page_name: 'bulkSkipModal' },
        tags: { taskIds: selectedTaskIds },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Skip Current Step"
        closeModal={onClose}
        onSubmit={() => {
          handleBulkSkip(selectedTaskIds);
        }}
        disableSubmit={false}
      />
      <ModalBody>
        <div className="modal-alert--message">
          You are taking action on all&nbsp;<strong>{selectedTaskIds?.length} Constituents (Tasks)</strong>
          &nbsp;selected within the same step.
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
          <FormField label={'Current Cadence'} boldLabel={true} inlineLabel={false}>
            <span>{journey?.name}</span>
          </FormField>
          <FormField label={'Current Step'} boldLabel={true} inlineLabel={false}>
            <span>{getStepForSelectedTasks(selectedTaskIds)}</span>
          </FormField>
        </div>
        <div className="modal-alert--message-gray">
          <FormField label={'Selected constituents will be moved to:'} boldLabel={true} inlineLabel={false}>
            <span>{getStepForSelectedTasks(selectedTaskIds, true)}</span>
          </FormField>
        </div>
      </ModalBody>
    </Modal>
  );
};

BulkSkipModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  journey: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  selectedTaskIds: PropTypes.array.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default BulkSkipModal;
