import PropTypes from 'prop-types';
import useDialer from './hooks/use-dialer';
import DialerNotes from './components/dialer-notes';
import DialerHeader from './components/dialer-header';
import DialerNumPad from './components/dialer-num-pad';
import { getGqlContactName } from 'apps/journeys/utils/helpers/utils';
import { useGlobalOutreachProvider } from 'apps/outreach/contexts/global-outreach-context';
import { useEffect } from 'react';

const Dialer = ({
  contact = {}, // Contact Object
  name, // Name of Contact (only separate because of the Profile Page)
  phones = [], // array of Phone Numbers
  handleCallEnd = () => {}, // Function provided by Global Outreach Provider. This will open a Quick Interaction Modal after the call is over.
  ...callDetails // Optional arguments that can be used to provide more details about the call in the summary. currently just a Task object and a Journey object
}) => {
  const {
    phoneRef,
    extensionRef,
    phoneNumber,
    extension,
    notes,
    onPhoneFocus,
    onExtFocus,
    handleInput,
    handleDropdownInput,
    handleNoteChange,
    onCall,
    DialerButton,
    hasVoip,
  } = useDialer(contact.id);

  const getName = () => {
    if (name) return name;
    return getGqlContactName(contact);
  };

  const { setActiveCallNotes } = useGlobalOutreachProvider();

  useEffect(() => {
    setActiveCallNotes('');
  }, [setActiveCallNotes]);

  return (
    <div className="dialer">
      {!hasVoip ? (
        <div className="voip-error-message">
          Please contact your EverTrue customer success manager or implementation partner for assistance with setting up
          your phone settings.
        </div>
      ) : (
        <>
          <div className="dialer-left-container">
            <DialerNotes handleNoteChange={handleNoteChange} notes={notes} />
          </div>
          <div className="dialer-right-container">
            <DialerHeader
              name={getName()}
              phones={phones}
              handleNumberChange={handleDropdownInput}
              value={phoneNumber}
              phoneRef={phoneRef}
              extensionRef={extensionRef}
              phoneNumber={phoneNumber}
              extension={extension}
              onPhoneFocus={onPhoneFocus}
              onExtFocus={onExtFocus}
              handleInput={handleInput}
              onCall={onCall}
            />

            <DialerNumPad
              handleNumPadPress={handleInput}
              DialerButton={DialerButton}
              phoneNumber={phoneNumber}
              extension={extension}
              contactId={contact?.id}
              onCallDisconnect={handleCallEnd}
              contact={contact}
              {...callDetails}
            />
          </div>
        </>
      )}
    </div>
  );
};

/**
 * PropTypes for the Dialer component:
 * - contact: The contact object.
 * - name: The name of the contact.
 * - phones: An array of phone numbers.
 * - handleCallEnd: Function used to trigger Quick Interaction Modal Opening (created by the Global Drawer Context and passed to each Dialer upon instantiation)
 * - ...callDetails is a catch-all for other props that are supplied, like a Journey object or Task object. Used to add more detail for the quick interaction
 */
Dialer.propTypes = {
  contact: PropTypes.object,
  name: PropTypes.string,
  phones: PropTypes.array,
  handleCallEnd: PropTypes.func,
};

export default Dialer;
