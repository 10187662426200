import { formatDateFromTimestamp } from 'apps/journeys/utils/helpers/dates';
import PropTypes from 'prop-types';

const DPSRowDetails = ({ contact = {}, giving }) => {
  const { dps_details: { dps_score, last_interaction_date } = {} } = contact;
  const { last_gift_date } = giving;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <div>
        <span className="giving-amount">DPS Score: {dps_score ? dps_score : 'NA'}</span> | Last Gift:{' '}
        {last_gift_date ? formatDateFromTimestamp(last_gift_date) : 'NA'}
      </div>
      <div className="giving-date">
        Last recorded interaction: {last_interaction_date ? formatDateFromTimestamp(last_interaction_date) : 'NA'}
      </div>
    </div>
  );
};

DPSRowDetails.propTypes = {
  contact: PropTypes.object,
  giving: PropTypes.object,
};

export default DPSRowDetails;
