import Api from 'entities/helpers/api';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const getAllSentByUser = async (userId) => {
  try {
    const res = await Api.OUTREACH.EMAILS.post({
      data: JSON.stringify({
        status: 'SENT',
        creator_user_id: userId,
      }),
    });
    return res;
  } catch (e) {
    ErrorLogger.error('Error getting sent emails', { extra: { e, page_name: 'Outreach Sent Emails' } });
  }
};

export const getAllThreadRepliesByThreadId = async (userId, threadId) => {
  try {
    const res = await Api.OUTREACH.EMAILS.post({
      data: JSON.stringify({
        nylas_thread_id: threadId,
        creator_user_id: userId,
      }),
    });
    return res;
  } catch (e) {
    ErrorLogger.error('Error getting thread replies', { extra: { e, page_name: 'Thread details view page' } });
  }
};

export const updateSent = async (payload, emailId) => {
  try {
    const res = await Api.OUTREACH.EMAIL.put({
      urlArgs: { id: emailId },
      data: JSON.stringify(payload),
    });

    return res;
  } catch (e) {
    ErrorLogger.error('Error updating sent email', { extra: { e, page_name: 'Outreach Sent Emails' } });
  }
};
