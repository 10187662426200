import PropTypes from 'prop-types';
import { Button } from '@evertrue/et-components';

const ActiveJourneyClickable = ({ journey, handleOpenSideBar }) => {
  return (
    <>
      <Button
        onClick={() => {
          handleOpenSideBar(journey);
        }}
        className="clickable-active-constituent"
        type="simple"
        disable={journey.active_constituents === 0}
      >
        <div>{journey.active_constituents} </div>
      </Button>
    </>
  );
};

ActiveJourneyClickable.propTypes = {
  journey: PropTypes.object,
  handleOpenSideBar: PropTypes.func,
};

export default ActiveJourneyClickable;
