module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   MapStore = require('apps/map/stores/map-store').default
   GateSource = require("apps/layout/sources/gate-source")
   FacetSource = require("apps/filters/sources/facet-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   ProspectColumnStore = require("apps/major-gifts/stores/prospect-column-store")
   ProspectColumnSource = require("apps/major-gifts/sources/prospect-column-source")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   MyPortfolioConfig = require("apps/major-gifts/my-portfolio-config")
   Decorator = require("clientDecorator")

   QUERY_STAT_PARAM = [_score: {include_percentiles: true, shardSize: 1000, size: 100}]

   _getDefaults = ->
      has_mgo: FeatureStore.hasFeature("major_gifts")
      has_mgo_writes: FeatureStore.hasFeature("write_assignee")
      total_prospects: {}
      total_median_age: null
      total_count: 0
      total_count_loading: true
      mapped_prospects: {}
      grouped_prospects: {}
      map_loading: false
      loading_cols: true
      sort_param: null
      all_columns: []
      active_columns: []
      address_types: []
      fetched_last_contact_ids: []

   _getParsedContacts = (data) ->
      _.map data?.items, (contact) ->
         Decorator.Contacts.parse(contact)

   createStore "MyPortfolioStore",

      getInitialState: ->
         _.extend(user: null, _getDefaults())

      registerActions: ->
         @on MyPortfolioSource.actions.resetStore, @respondToResetStore
         @on MyPortfolioSource.actions.fetchedLastMoveDates, @respondToFetchedLastMoveDates
         @on MyPortfolioSource.actions.fetchedLastContactDates, @respondToFetchedLastContactDates
         @on MyPortfolioSource.actions.fetchedProspectsByStatus, @respondToFetchedProspectsByStatus
         @on MyPortfolioSource.actions.infiniteLoadByStatus, @respondToInfiniteLoadByStatus
         @on MyPortfolioSource.actions.clearProspects, @respondToClearProspects
         @on MyPortfolioSource.actions.clearMedianAges, @respondToClearMedianAges
         @on MyPortfolioSource.actions.loadingTotalCount, (loading) ->
            @setState total_count_loading: loading
         @on MyPortfolioSource.actions.fetchedTotalProspectCount, (count) ->
            @setState total_count: count
         @on MyPortfolioSource.actions.startFetchForMappedProspects, @respondToStartFetchForMappedProspects
         @on MyPortfolioSource.actions.fetchedMapProspects, @respondToFetchedMapProspects
         @on MyPortfolioSource.actions.paginateMappedProspects, @respondToPaginateMappedProspects
         @on MyPortfolioSource.actions.mapLoading, (bool) ->
            @setState map_loading: bool
         @on MyPortfolioSource.actions.prospectStatusLoading, @respondToStatusLoading
         @on MyPortfolioSource.actions.changeSortParam, @respondToChangeSortParam
         @on MyPortfolioSource.actions.fetchedViewedSlides, @respondToFetchedViewedSlides
         @on MyPortfolioSource.actions.changeUser, @respondToChangeUser
         @on MyPortfolioSource.actions.startExport, @respondToStartExport
         @on MyPortfolioSource.actions.setAddressTypes, @respondToSetAddressTypes
         @on MyPortfolioSource.actions.fetchedMedianAgeByStatus, @respondToFetchedMedianAgeByStatus
         @on MyPortfolioSource.actions.fetchedTotalMedianAge, @respondToFetchedTotalMedianAge

         @on ProspectColumnSource.actions.fetchedColumns, @respondToFetchedColumns
         @on ProspectColumnSource.actions.updatedColumns, @respondToFetchedColumns

         @on AssignProspectSource.actions.changedAssignment, @respondToChangedAssignment

         @on FacetSource.actions.fetchedFacets, ->
            all_cols = ProspectColumnStore.getAllColumns()
            @setState all_columns: _.pluck all_cols, "label"

         @on GateSource.actions.refreshUserGates, @respondToGates
         @on GateSource.actions.fetchedGates, @respondToGates

      respondToResetStore: ->
         @setState _getDefaults()

      respondToFetchedColumns: (cols) ->
         grouped = {}
         _.each cols, (col) ->
            grouped[col.key] = {label: col.label, total: 0, initial_loading: true}
         cols = _.pluck cols, "label"
         @setState grouped_prospects: grouped, active_columns: cols, loading_cols: false
         @fetchAll()

      respondToChangeSortParam: (sort_param) ->
         @setState sort_param: sort_param
         prop = _.keys(sort_param)?[0]
         if prop != "proximity_sort"
            @fetchProspectCols()
         if prop != "time_in_stage"
            @fetchMappedProspects()

      respondToFetchedLastMoveDates: (items, status) ->
         total_prospects = _.cloneData @getState("total_prospects")

         _.each items, (item) ->
            contact_to_update = total_prospects[item.id]
            if contact_to_update and !contact_to_update.last_move_dates
               contact_to_update.last_move_dates = {}

            if contact_to_update
               contact_to_update.last_move_dates[status] = item.date

         @setState total_prospects: total_prospects

      respondToFetchedLastContactDates: (items) ->
         total_prospects = _.cloneData @getState("total_prospects")

         _.each items, (item) ->
            contact_to_update = total_prospects[item.id]
            if contact_to_update
               contact_to_update.last_contact_date = item.date

         @setState total_prospects: total_prospects

      respondToStatusLoading: (status, bool, type) ->
         cols = _.clone(@getState("grouped_prospects"))
         if cols[status]
            cols[status][type] = bool
         @setState grouped_prospects: cols

      respondToInfiniteLoadByStatus: (status, offset) ->
         @fetchProspectsByStatus(status, @getState("user")?.id, @getState("sort_param"), offset)

      respondToFetchedProspectsByStatus: (status, data) ->
         cols = _.cloneData @getState("grouped_prospects")
         total_prospects = _.cloneData @getState("total_prospects")
         group_contacts = _getParsedContacts(data)

         # Get last contact dates for array of contacts
         @fetchLastContactDate(_.pluck(group_contacts, "id"))

         _.extend(cols[status], _.omit(data, "items"))
         _.each group_contacts, (prospect) ->
            # Add to grouped columns, cant just set contacts to group_contacts bc of pagination
            col_contacts = cols[status]?.contacts || []
            if _.isEmpty(col_contacts) || !_.contains(col_contacts, prospect.id)
               col_contacts.push(prospect.id)
            cols[status]?.contacts = col_contacts

            # Add to total prospects
            if !total_prospects[prospect.id]
               total_prospects[prospect.id] = prospect

         @setState grouped_prospects: cols, total_prospects: total_prospects

      respondToClearProspects: ->
         cols = _.compactObject _.clone(@getState("grouped_prospects"))
         _.each cols, (col) -> col.contacts = []
         @setState grouped_prospects: cols, mapped_prospects: {items: []}

      respondToClearMedianAges: ->
         cols = _.clone(@getState("grouped_prospects"))
         _.each cols, (col) -> col.median_time = null if col.median_time
         @setState grouped_prospects: cols, total_median_age: null

      respondToFetchedViewedSlides: (data) ->
         @setState should_view: !_.jsonParse(data?.viewed)

      respondToStartExport: (ids) ->
         data =
            all_columns: @getState("all_columns")
            active_columns: @getState("active_columns")
         prospect_obj = Decorator.MyPortfolio.getTotalProspectStatusParam(data)

         query_obj = {assignee: @getState("user")?.id}
         if prospect_obj
            _.extend query_obj, {total_prospect_status: prospect_obj}

         query = Decorator.MyPortfolio.getQuery(query_obj)
         MyPortfolioSource.export(ids, query)

      respondToChangeUser: (user) ->
         @setState user: user, total_count: 0, total_count_loading: true
         @fetchAll(user?.id)

      respondToSetAddressTypes: (address_types) ->
         @setState address_types: address_types

      respondToFetchedMapProspects: (data) ->
         data.items = _getParsedContacts(data) if data
         @setState {mapped_prospects: data || {}}

         @fetchLastContactDate(_.pluck data.items, "id")

      respondToPaginateMappedProspects: (offset) ->
         data = {bounds: MapStore.getBoundingBox(), offset: offset}
         @fetchMappedProspects(data)

      respondToStartFetchForMappedProspects: (data) ->
         @fetchMappedProspects(data)

      respondToFetchedMedianAgeByStatus: (status, time) ->
         return unless time
         cols = _.cloneData @getState("grouped_prospects")
         time = MyPortfolioConfig.getFormattedTimeInStage(time)
         cols[status] = _.extend cols[status], {median_time: time}
         @setState grouped_prospects: cols

      respondToFetchedTotalMedianAge: (time) ->
         time = MyPortfolioConfig.getFormattedTimeInStage(time)
         @setState total_median_age: time

      respondToChangedAssignment: ->
         grouped_prospects = _.cloneData @getState("grouped_prospects")
         _.each grouped_prospects, (group, key) ->
            grouped_prospects[key] = _.extend group, {contacts: [], offset: 0}
         @setState {grouped_prospects: grouped_prospects}
         @fetchAll(@getState("user")?.id)
         if @hasListeners() then SelectedContactsSource.clear()

      respondToGates: ->
         has_mgo = FeatureStore.hasFeature("major_gifts")
         has_mgo_writes = FeatureStore.hasFeature("write_assignee")
         @setState {has_mgo: has_mgo, has_mgo_writes: has_mgo_writes}
         @fetchAging() if has_mgo && has_mgo_writes

      fetchAll: (user_id) ->
         @fetchProspectCols(user_id)
         @fetchTotalProspectCount(user_id)
         @fetchMappedProspects(user_id)
         @fetchAging(user_id)

      fetchMappedProspects: (data={}) ->
         return unless !@getState("loading_cols") and @getState("user")
         map_data =
            bounds: MapStore.getBoundingBox()
            address_types: @getState("address_types")
            sort_param: @getState("sort_param")
            active_columns: @getState("active_columns")
            all_columns: @getState("all_columns")

         data = _.extend map_data, data
         query = Decorator.MyPortfolio.getMapQuery(@getState("user")?.id, data)
         MyPortfolioSource.fetchMappedProspects(query, data.offset, data.bounds, data.sort_param)

      fetchProspectsByStatus: (status, user_id, sort_param, offset) ->
         data =
            status: status
            sort_param: sort_param
         query = Decorator.MyPortfolio.getProspectsByStatusQuery(user_id, data)
         MyPortfolioSource.fetchProspectsByStatus(query, status, sort_param, offset)

      fetchLastMoveDate: (status) ->
         return unless @getState("has_mgo")
         query = Decorator.MyPortfolio.getLastMoveQuery(@getState("user")?.id, status)
         MyPortfolioSource.fetchLastMoveDate(query, status)

      fetchLastContactDate: (ids) ->
         fetched_last_contact_ids = @getState("fetched_last_contact_ids")
         if @getState("has_mgo")
            ids_to_fetch = _.cloneData(_.difference ids, fetched_last_contact_ids)
            if !_.isEmpty(ids_to_fetch)
               # TODO: probably should set this state on the success callback because
               # in this case we can think ids have been fetched but the source call could fail
               @setState fetched_last_contact_ids: _.union ids_to_fetch, fetched_last_contact_ids
               MyPortfolioSource.fetchLastContactDate(ids_to_fetch)

      fetchProspectCols: (user_id) ->
         groups = @getState("grouped_prospects")
         sort = @getState("sort_param")
         user_id = user_id || @getState("user")?.id
         return unless !_.isEmpty(groups) and user_id
         _.each groups, (value, key) =>
            @fetchProspectsByStatus(value.label, user_id, sort)

      fetchAging: (user_id) ->
         groups = @getState("grouped_prospects")
         user_id = user_id || @getState("user")?.id
         return unless !_.isEmpty(groups) and user_id and @getShowAging()

         # Disable until the separate endpoint for total median age exists
         # MyPortfolioSource.fetchTotalMedianAge(user_id, prospect_status_param)

         _.each groups, (value, key) =>
            status = value?.label
            return unless status and (status != "No Status")

            # Get Median Age
            data =
               status: status
               sort_param_prop: "time_in_stage"
            query = Decorator.MyPortfolio.getProspectsByStatusQuery(user_id, data)
            query.stats = QUERY_STAT_PARAM
            MyPortfolioSource.fetchMedianAgeByStatus(query, status)

            # Get Contacts' individual time in stage
            @fetchLastMoveDate(status)

      fetchTotalProspectCount: (user_id) ->
         return unless !@getState("loading_cols")
         user_id = user_id || @getState("user")?.id
         data = {active_columns: @getState("active_columns"), all_columns: @getState("all_columns")}
         query = Decorator.MyPortfolio.getTotalProspectsQuery(user_id, data)
         MyPortfolioSource.fetchTotalProspectCount(query)

      api:
         getShowAging: -> @getState("has_mgo") && @getState("has_mgo_writes")

         getShowLastContactDate: -> @getState("has_mgo")

         getTotalProspects: -> {items: _.values(@getState("total_prospects"))}

         getTotalMedianAge: -> @getState("total_median_age")

         getTotalCount: -> @getState("total_count")

         getTotalCountLoading: -> @getState("total_count_loading")

         getMappedProspects: ->
            mapped_prospects = _.cloneData @getState("mapped_prospects")
            total_prospects = @getState("total_prospects")
            ids = _.pluck(mapped_prospects.items, "id")
            mapped_prospects.items =  _.map ids, (id) -> total_prospects[id] || {}
            mapped_prospects

         getGroup: (status) ->
            if status then @getState("grouped_prospects")?[status]
            else @getState("grouped_prospects")

         getContact: (id) ->
            total_prospects = @getState("total_prospects")
            total_prospects[id] || {}

         getMapLoading: -> @getState("map_loading")

         getShouldViewSlides: -> @getState("should_view")

         getLoadingCols: -> @getState("loading_cols")

         getUser: -> @getState("user")

         getSortParam: -> @getState("sort_param")

         getAddressTypes: -> @getState("address_types")


