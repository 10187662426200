const REACTIONS = [
  { value: 'comment', label: 'Comment', icon: '/images/fb-reactions-comment.png' },
  { value: 'like', label: 'Like', icon: '/images/fb-reactions-like.png' },
  { value: 'love', label: 'Love', icon: '/images/fb-reactions-love.png' },
  { value: 'wow', label: 'Wow', icon: '/images/fb-reactions-wow.png' },
  { value: 'sad', label: 'Sad', icon: '/images/fb-reactions-sad.png' },
  { value: 'haha', label: 'Haha', icon: '/images/fb-reactions-haha.png' },
  { value: 'angry', label: 'Angry', icon: '/images/fb-reactions-angry.png' },
  { value: 'thankful', label: 'Thankful', icon: '/images/fb-reactions-thankful.png' },
];

const FacebookReactions = {
  findReaction(reaction_key) {
    return REACTIONS.find((reaction) => reaction.value === reaction_key);
  },

  getAll() {
    return [...REACTIONS];
  },

  getAllValues() {
    return REACTIONS.map((reaction) => reaction.value);
  },

  getIconFor(reaction_key) {
    const reaction = this.findReaction(reaction_key);
    if (reaction) {
      return reaction.icon;
    }
    return this.findReaction('comment').icon;
  },

  getLabelFor(reaction_key) {
    return this.findReaction(reaction_key)?.label;
  },
};

export default FacebookReactions;
