import EverTrue from 'app';
import Icon from 'components/elements/icon';

const ChatLink = () => {
  const openBeacon = () => {
    window.location.href = 'mailto:genius@evertrue.com';
  };

  if (EverTrue.request('isImpersonating?')) {
    return null;
  }

  return (
    <a onClick={openBeacon}>
      <Icon className="help-dropdown--icon" icon="chat" before={true} size={1} />
      EverTrue Support
    </a>
  );
};

export default ChatLink;
