import { useEffect, useState } from 'react';
import { PropTypes } from 'base/react-libs';
import CadenceCategoryList from './cadenceCategoryList';
import CadenceList from './cadenceList';
import {
  fetchJourneyGroups,
  fetchJourneysForGroup,
  fetchAllJourneysForAllJourneyGroups,
} from 'apps/journeys/utils/api-requests/journey-index';

const SelectCadence = ({ selected, setSelected }) => {
  const [selectedCadenceCategory, setSelectedCadenceCategory] = useState({});
  const [cadenceCategories, setCadenceCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [cadenceListOptions, setCadenceListOptions] = useState([]);
  const [showAllCadenceView, setShowAllCadenceView] = useState(false);

  useEffect(() => {
    const getAndSetJourneyGroups = async () => {
      setLoadingCategories(true);
      const journeyGroupsFromApi = await fetchJourneyGroups();

      const journeyGroupOptions =
        journeyGroupsFromApi &&
        journeyGroupsFromApi.map((group) => ({
          label: group.name,
          value: group.id,
        }));
      setLoadingCategories(false);
      setCadenceCategories(journeyGroupOptions);
      journeyGroupOptions?.length && setSelectedCadenceCategory(journeyGroupOptions[0]);
    };
    getAndSetJourneyGroups();
  }, []);

  useEffect(() => {
    const cadenceList = document.getElementsByClassName('cadence-list');
    if (cadenceList.length) cadenceList[0].scrollTop = 0;
  }, [cadenceListOptions]);

  useEffect(() => {
    const getAndSetJourneysForJourneyGroup = async () => {
      const journeyList = await fetchJourneysForGroup(selectedCadenceCategory.value);

      const listOptions =
        journeyList &&
        journeyList.map((journey) => ({
          label: journey.name,
          value: journey.id,
          journey_group: journey.journey_group,
        }));

      setCadenceListOptions(listOptions);
    };
    if (selectedCadenceCategory.value) getAndSetJourneysForJourneyGroup();
  }, [selectedCadenceCategory, setSelectedCadenceCategory]);

  const getAllCadences = async () => {
    const journeyList = await fetchAllJourneysForAllJourneyGroups();

    const listOptions =
      journeyList &&
      journeyList.map((journey) => {
        return {
          label: journey.name,
          value: journey.id,
          journey_group: journey.journey_group,
        };
      });
    setCadenceListOptions(listOptions);
  };

  const handleCadenceCategoryChange = (category) => {
    setShowAllCadenceView(false);
    setSelectedCadenceCategory(category);
  };

  const handleJourneyChange = (journey) => {
    setSelected(journey);
  };

  const handleShowAllCadence = () => {
    setSelectedCadenceCategory({});
    setShowAllCadenceView(true);
    getAllCadences();
  };

  return (
    <div style={{ display: 'flex', marginBottom: '-20px' }}>
      <CadenceCategoryList
        selectedCadenceCategory={selectedCadenceCategory}
        loadingCategories={loadingCategories}
        cadenceCategories={cadenceCategories}
        handleCadenceCategoryChange={handleCadenceCategoryChange}
        handleShowAllCadence={handleShowAllCadence}
        showAllCadenceView={showAllCadenceView}
      />
      <CadenceList
        selected={selected}
        options={cadenceListOptions}
        handleJourneyChange={handleJourneyChange}
        showAllCadenceView={showAllCadenceView}
      />
    </div>
  );
};

SelectCadence.propTypes = {
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

export default SelectCadence;
