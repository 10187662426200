import { PropTypes } from "base/react-libs";
import { Icon, Button } from "@evertrue/et-components";
import { useFeatureFlag } from "entities/helpers/use-feature-flag";
import { getGqlContactName } from "apps/journeys/utils/helpers/utils";
import { trackOutreachAction } from "apps/outreach/utils/utils";
import { useGlobalDrawerProvider } from "../context/global-drawer-context";

const DialerDrawerButton = ({ drawerTitle, name, contact, phones }) => {
  const { addDrawer } = useGlobalDrawerProvider();
  const { flagEnabled: hasSignal } = useFeatureFlag("signal");

  const getName = () => {
    if (name) return name;
    return getGqlContactName(contact);
  };

  const getTitle = () => {
    if (drawerTitle) return drawerTitle;
    else return getName() || "New Phone Call";
  };

  return (
    hasSignal && (
      <>
        <Button
          type="secondary"
          onClick={() => {
            trackOutreachAction("open_dialer");
            addDrawer("dialer", { contact, phones, name: getName(), drawerTitle: getTitle() });
          }}
          className="round-button"
        >
          <Icon icon="call" style={{ color: "#007C9E" }} />
        </Button>
      </>
    )
  );
};

DialerDrawerButton.propTypes = {
  drawerTitle: PropTypes.string,
  contact: PropTypes.object,
  name: PropTypes.string,
  phones: PropTypes.array,
};

export default DialerDrawerButton;
