import PropTypes from 'prop-types';
import { ModalHeaderWithActions } from '@evertrue/et-components';
import EmailTemplateForm from './email-template-form';
import MissingInfoModal from '../../common/missing-information-modal';
import { Formik } from 'formik';
import { CreateUpdateEmailSchema } from 'apps/outreach/utils/create-update-email-schema';

const CreateOrUpdateEmailForm = ({
  originalTemplate = {},
  setShowEditView,
  contact,
  missingFields,
  handleSubmit,
  quillRef,
  showReviewPopup,
  setShowReviewPopup,
}) => {
  const {
    templateName: originalTemplateName = '',
    templateSubject: originalTemplateSubject = '',
    templateBody: originalTemplateBody = '',
  } = originalTemplate;

  const initialValues = {
    templateName: originalTemplateName,
    templateSubject: originalTemplateSubject,
    templateBody: originalTemplateBody,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={CreateUpdateEmailSchema} onSubmit={handleSubmit}>
      {({ values, handleSubmit, setFieldValue, isValid }) => {
        return (
          <>
            <ModalHeaderWithActions
              title={!Object.keys(originalTemplate).length ? 'Create Email Template' : 'Edit Email Template'}
              closeModal={() => setShowEditView(false)}
              onSubmit={handleSubmit}
              disableSubmit={!isValid}
            />
            <div style={{ display: 'flex', height: '550px' }}>
              <EmailTemplateForm
                templateName={values.templateName}
                templateBody={values.templateBody}
                templateSubject={values.templateSubject}
                setTemplateName={(value) => setFieldValue('templateName', value)}
                setTemplateBody={(value) => setFieldValue('templateBody', value)}
                setTemplateSubject={(value) => setFieldValue('templateSubject', value)}
                quillRef={quillRef}
                contact={contact}
              />
            </div>
            <MissingInfoModal
              title="Review Unsupported Dynamic Fields"
              secondaryButtonLabel="Save Anyway"
              missingFields={missingFields}
              handleSubmit={handleSubmit}
              isOpen={showReviewPopup}
              close={() => setShowReviewPopup(false)}
            />
          </>
        );
      }}
    </Formik>
  );
};

CreateOrUpdateEmailForm.propTypes = {
  originalTemplate: PropTypes.object,
  setShowEditView: PropTypes.func,
  contact: PropTypes.object,
  showReviewPopup: PropTypes.bool,
  setShowReviewPopup: PropTypes.func,
  quillRef: PropTypes.object,
  missingFields: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default CreateOrUpdateEmailForm;
