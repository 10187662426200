import PropTypes from 'prop-types';
import Icon from 'components/elements/icon';

const Education = ({ data }) => {
  const edu = data || {};

  if (Object.keys(edu).length === 0) {
    return (
      <div className="education-template">
        <Icon icon="education" className="education-template--icon" />
        <div className="education-template--data is-empty">Not provided</div>
      </div>
    );
  }

  return (
    <div className="education-template">
      <Icon icon="education" className="education-template--icon" />

      <div className="education-template--data">
        <div>
          <strong className="education-template--name fs-exclude-data">{edu.school_name}</strong>
        </div>

        <div className="education-template--degree">
          <span className="fs-exclude-data">{edu.degree_in_major}</span>
        </div>

        <div className="education-template--label">
          <span className="fs-exclude-data">{edu.year}</span>
        </div>
      </div>
    </div>
  );
};

Education.propTypes = {
  data: PropTypes.object,
};

export default Education;
