import PropTypes from "prop-types";
import EverTrue from "app";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";

const OutreachNav = ({ tab }) => {
  return (
    <Nav
      active={tab}
      className="outreach--nav"
      onSelect={(selected_tab) => {
        EverTrue.UrlManager.navigate(`/outreach/${selected_tab}`, true, false);
      }}
    >
      <NavItem activeWhen="email" label="Email" />
      <NavItem activeWhen="phone" label="Phone" />
    </Nav>
  );
};

OutreachNav.propTypes = {
  tab: PropTypes.string,
};

export default OutreachNav;
