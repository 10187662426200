import { PropTypes } from 'base/react-libs';
import { useRef } from 'react';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { getStepIconComponent } from 'apps/journeys/utils/helpers/icon-fetch';
import { Button, Icon, ButtonTooltip, Checkbox } from '@evertrue/et-components';
import StepRowActionButton from './step-row-action-button';
import ProgressBarWithLabel from 'components/forms/progress-bar-with-label';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';

const StepRowSummary = ({ step, isExpanded, setIsExpanded = () => {}, completionPercentage }) => {
  const summaryRef = useRef(null);

  const { selectedTasks, setSelectedTasks, userIsCreator, inBulkSelectMode } = useJourneyDetailProvider();
  const { description: stepDescription, default_action_type: actionType } = step;
  const numberOfConstituents = step.tasks && Array.isArray(step.tasks) ? step.tasks.length : 0;
  const constituentLabel = numberOfConstituents === 1 ? 'Constituent' : 'Constituents';

  const allTasksChecked = () => {
    if (!selectedTasks || !selectedTasks?.length || !step?.tasks?.length) return false;
    const selectedTaskIds = selectedTasks.map((task) => task.id);
    return step.tasks.every((task) => selectedTaskIds.includes(task.id));
  };

  const partiallyChecked = () => {
    if (!selectedTasks || !selectedTasks?.length || !step?.tasks?.length) return false;
    const selectedTaskIds = selectedTasks.map((task) => task.id);
    return step.tasks.some((task) => selectedTaskIds.includes(task.id));
  };

  const handleCheckAll = (checked) => {
    if (!step?.tasks?.length) return;

    if (checked) {
      setSelectedTasks((prev) => Array.from(new Set([...prev, ...step.tasks])));
    } else {
      setSelectedTasks((prev) => prev.filter((task) => !step.tasks.includes(task)));
    }
  };

  const handleExpandStep = () => {
    trackJourneysAction('expand_step');
    setIsExpanded(!isExpanded);
  };

  const handleSummaryClick = (e) => {
    const ignoredSelectors = ['.checkbox-small', '.journey-task-table-row--action-buttons'];

    const clickedIgnoredElement = ignoredSelectors.some((selector) => e.target.closest(selector));

    if (clickedIgnoredElement) return;

    handleExpandStep();
  };

  return (
    <div
      className="journeys-task-table-row--summary"
      style={{ cursor: !!numberOfConstituents ? 'pointer' : 'default' }}
      onClick={!!numberOfConstituents ? handleSummaryClick : () => {}}
      ref={summaryRef}
    >
      {step?.tasks?.length > 0 && inBulkSelectMode && (
        <Checkbox
          label="Step Checkbox"
          checked={allTasksChecked() || partiallyChecked()}
          partial={partiallyChecked() && !allTasksChecked()}
          onChange={(checked) => {
            handleCheckAll(checked);
          }}
          className="checkbox-small"
          checkboxStyle={{ height: '18px', width: '18px' }}
        />
      )}
      <div style={{ flex: 4 }} className="journeys-task-table-row--summary-container">
        <div className={`journeys-task-table-row--summary ${step.tasks?.length === 0 ? 'no-tasks' : ''}`}>
          {getStepIconComponent(actionType)}
        </div>
        <div className="journeys-task-table-row--summary-details">
          <div className="journeys-task-table-row--sub-header">Step {step.step_number}</div>
          <div className="bold-text">
            {step.title}
            {stepDescription && (
              <span data-testid="tooltip-icon">
                <ButtonTooltip tooltipPositionTop={true} trigger={<i className="gt-icon gt-icon-info tooltip-icon" />}>
                  {stepDescription}
                </ButtonTooltip>
              </span>
            )}
          </div>
        </div>
      </div>
      <>
        {!!numberOfConstituents && (
          <ProgressBarWithLabel
            title={`${numberOfConstituents} ${constituentLabel}`}
            showPercent={true}
            weight="thin"
            percent={completionPercentage}
            progressColor="#73c166"
            width={50}
            containerStyle={{ flex: 4 }}
          />
        )}
        <div style={{ flex: 1 }} className="journey-task-table-row--action-buttons">
          {!!numberOfConstituents && (
            <Button type="simple" onClick={handleExpandStep}>
              <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} size={2} />
            </Button>
          )}
          {userIsCreator && <StepRowActionButton existingJourneyTask={step} />}
        </div>
      </>
    </div>
  );
};

StepRowSummary.propTypes = {
  step: PropTypes.object,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  completionPercentage: PropTypes.number,
};

export default StepRowSummary;
