import { Button, Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';

const TrimControls = ({
  trimStart,
  trimEnd,
  handleTrimChange,
  progress,
  handleProgressChange,
  setIsDragging,
  onApplyTrim,
  setIsTrimming,
  isPlaying,
  currentTime,
  duration,
  formatTime,
  handlePlayPause,
  onRevertTrim,
  setTrimStart,
  setTrimEnd,
  isTrimmed,
}) => {
  const handleApplyTrim = () => {
    const trimStartSeconds = Math.round((trimStart / 100) * duration);
    const trimEndSeconds = Math.round((trimEnd / 100) * duration);
    onApplyTrim(trimStartSeconds, trimEndSeconds);
    setTrimStart(0);
    setTrimEnd(100);
    setIsTrimming(false);
  };
  const handleRevertTrim = () => {
    onRevertTrim();
    setIsTrimming(false);
  };

  const getTrimmedDuration = () => {
    const trimStartSeconds = Math.round((trimStart / 100) * duration);
    const trimEndSeconds = Math.round((trimEnd / 100) * duration);
    return formatTime(trimEndSeconds - trimStartSeconds);
  };

  return (
    <div className="trim-controls">
      <div style={{ width: '35px' }}>
        <button onClick={handlePlayPause}>
          <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'}`} aria-hidden="true"></i>
        </button>
        <div className="tv-tooltip">
          {formatTime(currentTime - (trimStart / 100) * duration)} /
          {formatTime(((trimEnd - trimStart) / 100) * duration)}
        </div>
      </div>

      <div className="trim-controls-sliders">
        <div
          className="trim-highlight"
          style={{
            left: `${trimStart}%`,
            width: `${trimEnd - trimStart}%`,
          }}
        />
        <input
          className="trim-slider start"
          type="range"
          min="0"
          max="100"
          value={trimStart}
          onChange={(e) => handleTrimChange(e, 'start')}
        />
        <input
          className="time-track"
          type="range"
          min={`${trimStart}%`}
          max={`${trimEnd}%`}
          value={progress}
          onChange={handleProgressChange}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
        />
        <input
          className="trim-slider end"
          type="range"
          min="0"
          max="100"
          value={trimEnd}
          onChange={(e) => handleTrimChange(e, 'end')}
        />
      </div>

      <div className="trim-controls-buttons">
        <div className="time-display">{getTrimmedDuration()}</div>
        {isTrimmed && (
          <div className="tv-tooltip-container">
            <Button type="simple" className="trim-btn" onClick={handleRevertTrim}>
              <Icon icon="undo" style={{ fontWeight: 'normal', fontSize: '15px' }}></Icon>
            </Button>
            <div className="tv-tooltip">Revert to Original</div>
          </div>
        )}
        <div className="tv-tooltip-container">
          <Button type="simple" className="trim-btn" onClick={handleApplyTrim}>
            <Icon icon="check"></Icon>
          </Button>
          <div className="tv-tooltip">Apply</div>
        </div>
        <div className="tv-tooltip-container">
          <Button type="simple" className="trim-btn" onClick={() => setIsTrimming(false)}>
            <Icon icon="close"></Icon>
          </Button>
          <div className="tv-tooltip">Cancel</div>
        </div>
      </div>
    </div>
  );
};

TrimControls.propTypes = {
  trimStart: PropTypes.number.isRequired,
  trimEnd: PropTypes.number.isRequired,
  handleTrimChange: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  handleProgressChange: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  onApplyTrim: PropTypes.func.isRequired,
  setIsTrimming: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  formatTime: PropTypes.func.isRequired,
  onRevertTrim: PropTypes.func.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  setTrimStart: PropTypes.func.isRequired,
  setTrimEnd: PropTypes.func.isRequired,
  isTrimmed: PropTypes.bool.isRequired,
};

export default TrimControls;
