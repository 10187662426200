import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import MyPortfolioController from 'apps/major-gifts/controllers/my-portfolio-controller';

class MyPortfolioRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'my-portfolio': 'showMyPortfolio',
      'my-portfolio/:type': 'showMyPortfolio',
      'my-portfolio/:type/:subtype': 'showMyPortfolio',
    };
  }

  showMyPortfolio(type = 'prospects', subtype) {
    EverTrue.execute('removePageContainer');
    EverTrue.execute('setBrowserTitle', 'My Portfolio');

    if (!['map', 'stage'].includes(subtype)) {
      subtype = null;
    }

    this.currentView = <MyPortfolioController type={type} subtype={subtype} />;
    EverTrue.page.mount(this.currentView);
  }
}

MyPortfolioRouter.initClass();
export default MyPortfolioRouter;
