import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import classNames from 'classnames';

const ShowHideAnimate = ({
  show,
  hide,
  el: Element = 'div',
  speed = 400,
  animateInClass,
  animateOutClass,
  animateOnMount,
  className,
  style = {},
  onClick,
  children,
}) => {
  const [visible, setVisible] = useState(show || hide === false);
  const [animation, setAnimation] = useState(
    !animateOnMount && animateInClass ? undefined : getAnimationMode({ show, hide })
  );
  const isMounted = useRef(true);
  const elementRef = useRef(null);

  function getAnimationMode({ show, hide }) {
    return show || hide === false ? 'in' : 'out';
  }

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const newVisible = show || hide === false;
    if (newVisible !== visible) {
      const newAnimation = getAnimationMode({ show, hide });
      setAnimation(newAnimation);
      setVisible(newVisible);
    }
  }, [show, hide, visible]);

  useEffect(() => {
    if (elementRef.current) {
      if (animation === 'in' && !animateInClass) {
        $(elementRef.current).slideDown(speed, 'linear');
      }
      if (animation === 'out' && !animateOutClass) {
        $(elementRef.current).slideUp(speed, 'linear');
      }
    }
  }, [animation, animateInClass, animateOutClass, speed]);

  const animationClass = animation === 'in' ? animateInClass : animation === 'out' ? animateOutClass : '';

  const classes = classNames(className, animationClass, {
    'show-hide-animate': true,
    animated: !!(animateInClass || animateOutClass),
  });

  const animationStyle = {
    WebkitAnimationDuration: `${speed / 1000}s`,
    MozAnimationDuration: `${speed / 1000}s`,
    animationDuration: `${speed / 1000}s`,
    ...style,
  };

  if (!animateInClass && animateOnMount) {
    animationStyle.display = 'none';
  }

  return (
    <Element ref={elementRef} className={classes} style={animationStyle} onClick={onClick}>
      {visible ? children : null}
    </Element>
  );
};

ShowHideAnimate.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.bool,
  el: PropTypes.string,
  speed: PropTypes.number,
  animateInClass: PropTypes.string,
  animateOutClass: PropTypes.string,
  animateOnMount: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default ShowHideAnimate;
