import EverTrue from 'app';
import useUserAuthentication from './useUserAuthentication';
import { useState, useCallback } from 'react';
import { pollAnimatedThumbnailUntilFinished, uploadVideoHelper } from 'entities/helpers/thankview-helper';

const useVideoProcessing = (video_id = null) => {
  const [lastBlob, setLastBlob] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [processingVideoState, setProcessingVideoState] = useState('');

  const { clientId } = useUserAuthentication();

  const uploadVideo = useCallback(
    async (blob, fileName, duration, personalVideoParams = {}) => {
      setLastBlob((prevBlobs) => [...prevBlobs, { blob, fileName, duration }]);
      setProcessingVideoState('uploading');
      // Cancel previous request if it exists
      if (abortController) {
        abortController.abort();
      }
      const newController = new AbortController();
      setAbortController(newController);
      try {
        const videoFile = new File([blob], 'blob.webm', { type: blob.type });
        const formData = new FormData();
        formData.append('file', videoFile);

        if (duration) {
          formData.append('video_length', Math.round(duration).toString());
        }

        formData.append('personalVideoParams', JSON.stringify(personalVideoParams));

        const { libraryVideo, personalVideo } = await uploadVideoHelper(
          formData,
          clientId.data.client_id,
          clientId.data.client_secret
        );

        setProcessingVideoState('generating_thumbnail');
        const { animatedThumbnailPath } = await pollAnimatedThumbnailUntilFinished(
          libraryVideo.id,
          clientId.data.client_id,
          clientId.data.client_secret
        );
        libraryVideo.animated_thumb_path = animatedThumbnailPath;
        setLastBlob([]);
        EverTrue.Alert.success('Video uploaded successfully');
        return { libraryVideo, personalVideo };
      } catch (error) {
        EverTrue.Alert.error('There was a problem uploading your video');
        throw error;
      } finally {
        setProcessingVideoState('');
      }
    },
    [abortController, clientId]
  );

  async function uploadVideoFromFile(video, fileName, duration, personalVideoParams = {}) {
    setProcessingVideoState('uploading');
    try {
      const formData = new FormData();
      formData.append('file', video);
      formData.append('video_length', Math.round(duration).toString());
      formData.append('personalVideoParams', JSON.stringify(personalVideoParams));
      const { libraryVideo, personalVideo } = await uploadVideoHelper(
        formData,
        clientId.data.client_id,
        clientId.data.client_secret
      );

      setProcessingVideoState('generating_thumbnail');
      const { animatedThumbnailPath } = await pollAnimatedThumbnailUntilFinished(
        libraryVideo.id,
        clientId.data.client_id,
        clientId.data.client_secret
      );
      libraryVideo.animated_thumb_path = animatedThumbnailPath;
      EverTrue.Alert.success('Video uploaded successfully');
      return { libraryVideo, personalVideo };
    } catch (error) {
      EverTrue.Alert.error('There was a problem uploading your video');
      throw error;
    } finally {
      setProcessingVideoState('');
    }
  }

  return { uploadVideo, uploadVideoFromFile, processingVideoState, lastBlob };
};

export default useVideoProcessing;
