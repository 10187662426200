import PropTypes from 'prop-types';
import ActiveJourneyPill from 'components/elements/active-journey-pill';

const OutreachCadenceIndicator = ({ taskId }) => {
  return (
    <div className="outreach-card-tab-group--item outreach-custom-active-pill">
      <ActiveJourneyPill activeCadence={{}} />
    </div>
  );
};

OutreachCadenceIndicator.propTypes = {
  taskId: PropTypes.number,
};

export default OutreachCadenceIndicator;
