import _ from 'underscore';
import CompanySource from 'apps/companies/sources/company-source';
import { createStore } from '@evertrue/et-flux';

const CompanyStore = createStore('CompanyStore', {
  getInitialState() {
    return {
      data: {
        square_logo_url: '/images/et_avatar_company_120.png',
      },
      stats: {
        giving_stats: {},
        employee_participation: {},
      },
      constituents: {},
    };
  },

  registerActions() {
    this.on(CompanySource.actions.fetchedCompany, this.respondToFetchedCompany);
    this.on(CompanySource.actions.fetchedStats, this.respondToFetchedStats);
    this.on(CompanySource.actions.fetchedCompaniesForIndustry, this.respondToFetchedCompanies);
    this.on(CompanySource.actions.fetchedConstituents, this.respondToFetchedConstituents);
    this.on(CompanySource.actions.resetData, this.resetData);
  },

  resetData() {
    this.setState({ data: {}, stats: {}, constituents: {} });
  },

  respondToFetchedCompany(data = {}) {
    if (!data.square_logo_url) {
      data.square_logo_url = '/images/et_avatar_company_120.png';
    }
    this.setState({ data });
  },

  respondToFetchedStats(data = {}) {
    const stats = {
      giving_stats: {
        total: data?.lifetime?.stats['giving.lifetime_amount']?.total,
        average: data?.lifetime?.stats['giving.lifetime_amount']?.mean,
        median: this.calculateMedian(data?.instances?.items),
      },
      employee_participation: {
        lifetime_participation: {
          numerator: data?.participation_life?.total,
          denominator: data?.instances?.total,
        },
        current_year: {
          numerator: data?.current_year?.total,
          denominator: data?.instances?.total,
        },
        last_year: {
          numerator: data?.last_year?.total,
          denominator: data?.instances?.total,
        },
      },
    };

    if (_.isEmpty(data?.current_year?.items)) {
      stats.employee_participation.current_year = 'failed';
    }
    if (_.isEmpty(data?.last_year?.items)) {
      stats.employee_participation.last_year = 'failed';
    }

    this.setState({ stats });
  },

  respondToFetchedCompanies(data = {}) {
    this.setState({
      data: {
        name: data?.name,
        companies: data?.companies,
        square_logo_url: '/images/et_avatar_industry_120.png',
      },
    });
  },

  respondToFetchedConstituents(data = {}) {
    this.setState({ constituents: data });
  },

  calculateMedian(contactsArray = []) {
    const givingAmounts = _.map(contactsArray, (contact) => contact.giving?.lifetime_amount?.value);
    const sorted = _.sortBy(_.compact(givingAmounts));
    return sorted[Math.floor(sorted?.length / 2)];
  },

  api: {
    getData() {
      return this.getState('data');
    },

    getStats() {
      return this.getState('stats');
    },

    getConstituents() {
      return this.getState('constituents');
    },
  },
});

export default CompanyStore;
