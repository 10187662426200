import { useState } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import MatchFacebook from 'apps/facebook/components/match-facebook';
import MatchFacebookConfirm from './match-facebook-confirm';
import { WizardFrame, WizardPage, Loading } from '@evertrue/et-components';
import ProfileMatchingSource from 'apps/contact/sources/profile-matching-source';
import EverTrue from 'app';

const MatchFacebookModal = ({ profile, postUrl, page, engagement, requestHide, onMatch }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [matchedContactId, setMatchedContactId] = useState(null);
  const [step, setStep] = useState(1);
  const [contact, setContact] = useState({});
  const [activeSearchVal, setActiveSearchVal] = useState();

  const steps = [
    {
      label: 'Match Profile',
      value: 1,
       
      render: ({ onPrevStep, onNextStep, isDisabled }) => {
        return (
          <WizardPage
            isDisabled={isDisabled}
            handleNextStep={onNextStep}
            handlePrevStep={onPrevStep}
            affirmButtonText="Next"
            rejectButtonText="Cancel"
            handleCancel={requestHide}
          >
            {loading ? (
              <Loading />
            ) : (
              <MatchFacebook
                page={page}
                engagement={engagement}
                postUrl={postUrl}
                profile={profile}
                matchedContactId={matchedContactId}
                onCheckChange={handleCheckChange}
                activeSearchVal={activeSearchVal || profile.name}
                onSearch={(val) => setActiveSearchVal(val)}
              />
            )}
          </WizardPage>
        );
      },
    },
    {
      label: 'Step 2',
      value: 2,
       
      render: ({ onPrevStep, onNextStep, isDisabled }) => {
        return (
          <WizardPage
            isDisabled={isDisabled}
            handleNextStep={onNextStep}
            handlePrevStep={onPrevStep}
            affirmButtonText="Confirm"
            rejectButtonText="Back"
            handleCancel={requestHide}
          >
            {loading ? (
              <Loading />
            ) : (
              <MatchFacebookConfirm contact={contact} page={page} postUrl={postUrl} engagement={engagement} />
            )}
          </WizardPage>
        );
      },
    },
  ];

  const handleCheckChange = (new_state, contact) => {
    if (new_state) {
      setDisabled(false);
      setMatchedContactId(contact.id);
      setContact(contact);
    } else {
      setDisabled(true);
      setMatchedContactId(null);
      setContact(null);
    }
  };

  const cleanupUnMatch = () => {
    setLoading(false);
    onMatch(profile.id);
    requestHide();
  };

  const handleMatch = () => {
    if (profile.id && matchedContactId && page.id) {
      setLoading(true);
      ProfileMatchingSource.setAnalytics('identity to contact');
      ProfileMatchingSource.match(matchedContactId, 'facebook', profile.id, page.id, 'unmatched engagers');
      EverTrue.Alert.info('Match request sent');
      cleanupUnMatch();
    }
  };

  const onNextStep = () => {
    if (step < steps.length) setStep((s) => s + 1);
    else if (step === steps.length) handleMatch();
  };

  const onPrevStep = () => {
    if (step > 1) setStep((s) => s - 1);
    else requestHide();
  };

  return (
    <WizardFrame
      title="Match to Constituent"
      hideDots
      handleCancel={requestHide}
      steps={2}
      currentStep={1}
      loading={loading}
    >
      {_.findWhere(steps, { value: step }).render({
        onNextStep: onNextStep,
        onPrevStep: onPrevStep,
        isDisabled: disabled,
      })}
    </WizardFrame>
  );
};

MatchFacebookModal.propTypes = {
  profile: PropTypes.any.isRequired,
  postUrl: PropTypes.string.isRequired,
  page: PropTypes.object,
  engagement: PropTypes.object,
  requestHide: PropTypes.func,
  onMatch: PropTypes.func,
};

export default MatchFacebookModal;
