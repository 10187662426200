import EverTrue from 'app';
import { Icon } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';

const ActiveJourneyPill = ({
  activeCadence: { journey_name, step_number, day_number, journey_id, current_due_date, owner } = {},
  type,
  contactName,
}) => {
  const className = type === 'profile' ? 'profile-header--cadence' : 'tasks-table--cadence';
  const { setNavigateFrom } = useGlobalJourneyProvider();

  const activeCadenceObj = {
    journey_name,
    step_number,
    day_number,
    current_due_date,
    owner,
    contact_name: contactName,
  };

  return (
    <div
      className={className}
      onClick={() => {
        setNavigateFrom(activeCadenceObj);
        EverTrue.Navigator(`/cadences/${journey_id}`, true);
      }}
    >
      <Icon iconKit="gt2" icon="cadences"></Icon>
      <p>
        {journey_name} - Step {step_number} | Day {day_number}
      </p>
    </div>
  );
};

export default ActiveJourneyPill;

ActiveJourneyPill.propTypes = {
  activeCadence: PropTypes.shape({
    journey_name: PropTypes.string,
    step_number: PropTypes.number,
    day_number: PropTypes.number,
    current_due_date: PropTypes.string,
    owner: PropTypes.object,
  }),
  type: PropTypes.oneOf(['profile', 'tasks']),
  contactName: PropTypes.string,
};
