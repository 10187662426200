module.exports = do ->
	_ = require("underscore").default
	React = require("react")
	EverTrue = require("app")
	classNames = require("classnames")
	{div, ul, h3} = ReactLibs.DOMFactories
	{string} = ReactLibs.PropTypes
	FeatureStore = require("apps/layout/stores/feature-store").default
	{createFactory} = require("base/new-utils")
	NavItem = createFactory require("components/lists/nav-item")
	SecuritySettingsController = require("apps/settings/security-settings/controllers/security-settings-controller")
	ManageTeamController = require("apps/settings/manage-team-settings/manage-team-controller")
	ExportDataController = require("apps/settings/export-settings/controllers/export-data-controller")
	ProfileSettingsController = createFactory(require("apps/settings/profile-settings/profile-settings-controller").default)
	ProfileEmailerController = createFactory(require("apps/settings/email-settings/profile-emailer-controller").default)
	JourneySettingsController = createFactory(require("apps/settings/journey-settings/journey-settings-controller").default)
	CallSettings = createFactory(require("apps/settings/phone-settings/phone-settings-form").default)
	IdentityResolver = createFactory(require("base/identity-resolver").default)
	Loading = require("components/elements/loading")
	ExportTypeConfig = require("apps/export/export-type-config").default
	{createComponent} = require("@evertrue/et-flux")
	Icon = createFactory(require("@evertrue/et-components").Icon)
	OutreachSettingsController = createFactory(require("apps/settings/outreach-settings-controller").default)


	createComponent "UserSettingsController",
		propTypes:
			page: ReactLibs.PropTypes.string

		componentDidMount: ->
			if @state.features_have_loaded
				@checkRedirect()

		registerStores: ->
			@on FeatureStore, ->
				features_have_loaded: FeatureStore.hasFeaturesLoaded()
				has_mfa: FeatureStore.hasFeature("two_factor_auth")
				has_windfall: FeatureStore.hasFeature("windfall")
				has_enrichment_export: FeatureStore.hasFeature("enrichment_export_active")
				has_career_updates: FeatureStore.hasFeature("career_updates")
				has_writes: _.any ExportTypeConfig, (obj) ->
					if _.isFunction(obj.gate) then obj.gate()
					else if obj.gate then FeatureStore.hasFeature(obj.gate)
					else true
				has_signal: @props.signal

		componentDidUpdate: (prevProps, prevState) ->
			if !prevState.features_have_loaded and @state.features_have_loaded
				@checkRedirect()

		checkRedirect: ->
			tab_keys = _.pluck(@getTabs(), "key")
			if not _.contains(tab_keys, @props.page)
				key = _.first(tab_keys)
				if key?
					EverTrue.UrlManager.navigate("/settings/#{key}", true)
				else
					EverTrue.UrlManager.navigate("/", true)

		getTabs: ->
			is_owner_ish = (EverTrue.store.user?.isSuperUser() || EverTrue.store.user?.isOwner())
			all_tabs = [
				key: "profile"
				name: "Profile"
				enabled: true
			,
				key: "manage_team"
				name: "Manage Team"
				enabled: is_owner_ish
			,
				key: "security"
				name: "Security"
				enabled: true
			,
				key: "export_data"
				name: "Export Data"
				enabled: ( @state.has_writes || @state.has_windfall || @state.has_enrichment_export || @state.has_career_updates) && is_owner_ish
			,
				key: "cadences"
				name: "Pause Cadences and Tasks"
				enabled: @state.has_signal
			,
				key: "outreach"
				name: "Outreach"
				enabled: @state.has_signal
			]

			return _.filter(all_tabs, (tab) -> tab.enabled)

		handleSelect: (key) ->
			EverTrue.UrlManager.navigate("/settings/#{key}", true, false)
			return undefined

		handleBack: ->
			if window.history.length > 1 then window.history.back()
			else EverTrue.Navigator("/home", true, false)

		render: ->
			IdentityResolver null,
				({user, org, is_impersonating, affiliation}) =>
					tabs = @getTabs()

					div className: classNames("fixed-page-wrapper": @props.page == "manage_team"),
						div className: "profile-settings-header", 
							Icon icon: "chevron-left", size: 2, className: "settings-back", onClick: @handleBack 
							h3(null, "Settings")
						ul className: "nav sub-nav",
							_.map tabs, (tab) =>
								NavItem
									refer: "default"
									onSelect: => @handleSelect(tab.key)
									activeWhen: tab.key
									key: tab.key
									active: @props.page
									label: tab.name
						div className: classNames("user-settings-page--content", "fixed-page-wrapper": @props.page == "manage_team"),
							if !@state.features_have_loaded
								Loading()
							else

								switch @props.page
									when "profile" then ProfileSettingsController({affiliation})
									when "manage_team" then ManageTeamController()
									when "security" then SecuritySettingsController()
									when "export_data" then ExportDataController()
									when "cadences" then JourneySettingsController()
									when "outreach" then OutreachSettingsController()
