export const formatURL = (url) => {
  if (!url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const validateUrl = (url) => {
  const validUrlTest = /^[^\s]+\.[a-z]{2,}(\/.*)*/i;
  return validUrlTest.test(url);
};
