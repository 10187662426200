import TaskForm from './task-form';
import { Modal, useOpenClose } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import useLocalStorage from 'hooks/use-local-storage';
import AddToCalendarModal from './add-to-calendar-modal';
import { useState } from 'react';

const AddTask = ({ isOpen, closeModal, contact, handleClose, optionalStateManagementFunction, taskObj }) => {
  const [isAddToCalendarModalOpen, openAddToCalendarModal, closeAddToCalendarModal] = useOpenClose();
  const [taskFormData, setTaskFormData] = useState({});
  const [addToCalendarDefaultOpen, setAddToCalendarDefaultOpen] = useLocalStorage('addToCalendarDefaultOpen', true);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <TaskForm
          contact={contact}
          handleClose={handleClose}
          optionalStateManagementFunction={optionalStateManagementFunction}
          taskObj={taskObj}
          openAddToCalendarModal={openAddToCalendarModal}
          setTaskFormData={setTaskFormData}
          setAddToCalendarDefaultOpen={setAddToCalendarDefaultOpen}
          addToCalendarDefaultOpen={addToCalendarDefaultOpen}
        />
      </Modal>
      <AddToCalendarModal
        isOpen={addToCalendarDefaultOpen && isAddToCalendarModalOpen}
        closeModal={closeAddToCalendarModal}
        calendarParams={taskFormData}
      />
    </>
  );
};

AddTask.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  contact: PropTypes.object,
  handleClose: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
  taskObj: PropTypes.object,
};

export default AddTask;
