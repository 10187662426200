import { createStore } from '@evertrue/et-flux';
import ProfileEventsSource from 'apps/profile/sources/profile-events-source';

const ProfileEventsStore = createStore('ProfileEventsStore', {
  getInitialState() {
    return { contact_event_engagements: {} };
  },

  registerActions() {
    this.on(ProfileEventsSource.actions.profileEventEngagementsSuccess, (contact_id, event_engagements) => {
      this.mergeState('contact_event_engagements', { [contact_id]: event_engagements });
    });
  },

  getData(contact_id) {
    return this.getState('contact_event_engagements')?.[contact_id] || {};
  },

  api: {
    getEventEngagements(contact_id) {
      return this.getData(contact_id)?.items;
    },

    getEventEngagementCount(contact_id) {
      return this.getData(contact_id)?.total;
    },

    getEventEngagementIcons(contact_id) {
      return this.getData(contact_id)?.facets?.engagement_action.map((action) => action.value);
    },
  },
});

export default ProfileEventsStore;
