import { PropTypes } from 'base/react-libs';

const AiTaskHeaderDescription = ({ title, description, onClick }) => {
  return (
    <div>
      <a
        title={`Open task ${title}`}
        onClick={onClick}
        className="ellipsis-overflow-one-line suggested-task-body-title"
      >
        {title}
      </a>
      <div>
        <p className="ellipsis-overflow-two-lines">{description}</p>
      </div>
    </div>
  );
};

AiTaskHeaderDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default AiTaskHeaderDescription;
