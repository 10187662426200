import PropTypes from 'prop-types';
import EverTrue from 'app';
import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { CATEGORY_OPTIONS, ONE_TEAM_LABEL } from '../constants';
import { useIdentity } from 'base/identity-resolver';
import Api from 'entities/helpers/api';
import { formatClarityDashboardResponse } from './helpers';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';

/** Context for managing Clarity dashboard state and data */
const ClarityContext = createContext(() => {});

/**
 * Hook to access the Clarity context
 */
const useClarityProvider = () => {
  const context = useContext(ClarityContext);
  if (!context) {
    throw new Error('useClarityProvider must be used within a ClarityProvider');
  }
  return context;
};

/**
 * Provider component that manages Clarity dashboard data and loading state
 */
const ClarityProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardCollection, setDashboardCollection] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({ label: 'All Dashboards', value: 'All Dashboards' });
  const { org } = useIdentity();
  const { flagEnabled: isClarityAdmin } = useFeatureFlag('clarityAdmin');
  // Filter category options based on admin status
  const filteredCategoryOptions = CATEGORY_OPTIONS.filter((option) => !option.requiresAdmin || isClarityAdmin);

  // Get filtered dashboard collection based on admin status and selected category
  const getFilteredDashboards = useCallback(() => {
    let filteredCollection = { ...dashboardCollection };

    if (!isClarityAdmin) {
      delete filteredCollection[ONE_TEAM_LABEL];
    }

    if (selectedCategory.value === 'All Dashboards') {
      return filteredCollection;
    }
    return { [selectedCategory.value]: filteredCollection[selectedCategory.value] };
  }, [dashboardCollection, isClarityAdmin, selectedCategory.value]);

  /**
   * Fetches and formats dashboard collection data from the Tableau API
   */
  const getClarityDashboardCollection = useCallback(async () => {
    try {
      setLoading(true);

      const response = await Api.AUTH.TABLEAU.get({
        params: { oid: org.id },
      });

      const formattedResponse = formatClarityDashboardResponse(response);
      setDashboardCollection(formattedResponse);

      setLoading(false);
    } catch {
      setLoading(false);
      EverTrue.Alert.error('Dashboard failed to open. Please try again later');
    }
  }, [org.id]);

  useEffect(() => {
    getClarityDashboardCollection();
  }, [getClarityDashboardCollection]);

  return (
    <ClarityContext.Provider
      value={{
        loading,
        dashboardCollection: getFilteredDashboards(),
        selectedCategory,
        setSelectedCategory,
        filteredCategoryOptions,
        org,
      }}
    >
      {children}
    </ClarityContext.Provider>
  );
};

ClarityProvider.propTypes = {
  children: PropTypes.any,
};

export { ClarityProvider, ClarityContext, useClarityProvider };
