module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, label, input, textarea, fieldset, h3} = ReactLibs.DOMFactories
   {object} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   Loading = require("components/elements/loading")
   ContactTagList = require("apps/contact/components/contact-tag-list")
   Loading = require("components/elements/loading")
   IconButton = createFactory(require("@evertrue/et-components").IconButton)
   Button = createFactory(require("@evertrue/et-components").Button)
   Radio = createFactory(require("@evertrue/et-components").Radio)
   BulkListSource = require("apps/lists/sources/bulk-list-source").default
   BulkListStore = require("apps/lists/stores/bulk-list-store")
   ListActivityStore = require("apps/lists/stores/list-activity-store")
   ListActivitySource = require("apps/lists/sources/list-activity-source")   
   TagList = require("components/tag-list")

   createComponent "BulkListController",
      propTypes:
         list: object

      getInitialState: ->
         bulkSelector: if @props.fromClarity then "remote_id" else "email"

      registerStores: ->
         @on BulkListStore, ->
            step: BulkListStore.getStep()
            error_message: BulkListStore.getErrorMessage()
            name: BulkListStore.getName()
            list_id: BulkListStore.getListId()
            selected_contacts: BulkListStore.getSelectedContacts()
            raw_tokens_string: BulkListStore.getRawTakensString()
            unverified_tokens: BulkListStore.getUnverifiedTokens()
            hits: BulkListStore.getHits()
            misses: BulkListStore.getMisses()
            can_submit_from_edit: BulkListStore.canSubmitFromEdit()
            can_submit_from_confirm: BulkListStore.canSubmitFromConfirm()

         @on ListActivityStore, ->
            list_activity_collapsed: ListActivityStore.getCollapsed()      

      componentDidMount: ->
         if id = @props.list?.id
            BulkListSource.setListId(id)

         if not @props.list?.id
            @refs.name_input?.focus?()
         
         if @props.listName then BulkListSource.setName(@props.listName)

      UNSAFE_componentWillReceiveProps: (nextProps) ->
         if (id = nextProps.list?.id) and (nextProps.list?.id isnt @props.list?.id)
            BulkListSource.setListId(id)

      UNSAFE_componentWillUpdate: (nextProps, nextState) ->
         if nextState.step is "done"
            if @depracated_is_mounted then @props.remove?()
            @props.onComplete?()

      componentWillUnmount: ->
         BulkListSource.clear()

      handleCancel: ->
         @props.remove?()

      handleSelectContacts: (contacts) ->
         BulkListSource.setSelectedContacts(contacts)
         @refs.contact_tag_list_component?.handleTypeahead("")

      handleTokensChange: (evt) ->
         BulkListSource.setManualInput(evt.target.value)

      handleNameChange: (evt) ->
         BulkListSource.setName(evt.target.value)

      handleEdit: ->
         BulkListSource.editManualContacts()

      handleSubmit: ->
         BulkListSource.submit(@state.bulkSelector)
         if !@state.list_activity_collapsed
            ListActivitySource.collapse(!@state.list_activity_collapsed)

      renderHitsAndMisses: ->
         misses = _.map @state.misses, (miss) ->
            id: miss, name: miss, isError: true, type: "miss"

         hits = _.map _.keys(@state.hits), (hit) ->
            id: hit, name: hit, type: "hit"

         items = misses.concat(hits)

         div className: "tag-input bulk-list-controller--verified-tokens",
            TagList
               className: "bulk-list-controller--tag-list"
               items: items
               onRemove: (item) ->
                  switch item.type
                     when "miss" then BulkListSource.removeMiss(item.id)
                     when "hit" then BulkListSource.removeHit(item.id)

      renderEdit: ->
         div null,
            div className: "modal-confirm--body modal-confirm--content",

               if !@props.list?.id
                  label className: "bulk-list-controller--label", "List Name*"

               if !@props.list?.id
                  input
                     ref: "name_input"
                     type: "text"
                     className: "bulk-list-controller--name"
                     placeholder: "New List Name..."
                     value: @state.name
                     onChange: @handleNameChange

               label className: "bulk-list-controller--label", "Add Constituents*"

               ContactTagList
                  ref: "contact_tag_list_component"
                  className: "bulk-list-controller--search"
                  focusOnMount: !@props.list?.id
                  placeholder: "Search for constituents."
                  selected: @state.selected_contacts
                  onSelect: @handleSelectContacts
                  listId: @props.list?.id


               label className: "bulk-list-controller--label", "Bulk Add To List"
                  fieldset null,
                     Radio
                        checked: @state.bulkSelector == "email"
                        onChange: => @setState {bulkSelector: "email"}
                        label: "Email"
                        name: "bulk"
                     Radio
                        checked: @state.bulkSelector == "remote_id"
                        onChange: => @setState {bulkSelector: "remote_id"}
                        label: "Remote ID"
                        name: "bulk"


               if _.notEmpty(@state.hits) or _.notEmpty(@state.misses)
                  @renderHitsAndMisses()
               else
                  bulkSelectorString = _.pluralize(@state.bulkSelector.replace("_", " ").toLowerCase())
                  textarea
                     className: "bulk-list-controller--emails"
                     placeholder: "Copy and paste constituent #{bulkSelectorString} from an external spreadsheet or document."
                     value: @state.raw_tokens_string
                     onChange: @handleTokensChange

            div className: "modal-confirm--footer",
               Button
                  type: "simple"
                  className: "modal-confirm--cancel"
                  onClick: @handleCancel
                  "Cancel"

               Button
                  className: "modal-confirm--submit"
                  disabled: !@state.can_submit_from_edit
                  onClick: @handleSubmit
                  if @props.list?.id then "Add Constituents" else "Create List"

      renderProcessing: ->
         div null,
            div className: "bulk-list-controller--confirm",
               Loading()

            div className: "modal-confirm--footer",
               Button
                  type: "secondary"
                  className: "modal-confirm--cancel"
                  onClick: @handleCancel
                  "Cancel"

               Button
                  className: "modal-confirm--submit"
                  disabled: true
                  if @props.list?.id then "Add Constituents" else "Create List"

      renderError: ->
         div null,
            div className: "modal-confirm--body modal-confirm--content",
               div className: "bulk-list-controller--error",
                  div null, "Problem Adding Constituents"
                  div className: "bulk-list-controller--error-list",
                     @state.error_message

            div className: "modal-confirm--footer",
               Button
                  type: "secondary"
                  className: "modal-confirm--cancel"
                  onClick: @handleEdit
                  "Edit My List"

               Button
                  className: "modal-confirm--submit"
                  disabled: true
                  if @props.list?.id then "Add Constituents" else "Create List"

      renderConfirm: ->
         number_to_add = _.size(@state.hits) + _.size(@state.selected_contacts)
         number_misses = _.size(@state.misses)

         div null,
            div className: "modal-confirm--body modal-confirm--content bulk-list-controller--confirm",
               if _.notEmpty(@state.misses)
                  div null,
                     div className: "bulk-list-controller--error",
                        div null, "Unable to add #{_.size(@state.misses)} items: "
                        div className: "bulk-list-controller--error-list",
                           @state.misses.join(", ")

                     if number_to_add > 0
                        prompt = if @props.list?.id then "Add to" else "Create"
                        div className: "bulk-list-controller--prompt",
                           "#{prompt} list without these #{number_misses} additions?"

            div className: "modal-confirm--footer",
               Button
                  type: "secondary"
                  className: "modal-confirm--cancel"
                  onClick: @handleEdit
                  "Edit My List"

               Button
                  className: "modal-confirm--submit"
                  disabled: !@state.can_submit_from_confirm
                  onClick: @handleSubmit
                  if @props.list?.id then "Add Constituents" else "Create List"

      render: ->
         existing_list = !!@props.list?.id

         action_label = if existing_list then "Add to '#{@props.list.name}'" else "Create List"
         Modal
            width: 400
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            div className: "modal-confirm bulk-list-controller",
               div className: "modal-confirm--header",
                  h3 null,
                     action_label
                     IconButton title:"close modal", className: "modal-confirm--close", icon: "close", onClick: @handleCancel

               switch @state.step
                  when "edit" then @renderEdit()
                  when "processing" then @renderProcessing()
                  when "error" then @renderError()
                  when "confirm" then @renderConfirm()
