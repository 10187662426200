module.exports = do ->
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Icon = createFactory (require("components/elements/icon").default)
   ScheduleExportsModal = require("apps/settings/export-settings/components/schedule-exports-modal")
   ManualExportModal = require("apps/settings/export-settings/components/manual-export-modal")
   _ = require("underscore").default
   moment = require("moment")
   FeatureStore = require("apps/layout/stores/feature-store").default


   createComponent "ExportItem",
      propTypes:
         exportType: ReactLibs.PropTypes.string
         lastExport: ReactLibs.PropTypes.object

      getDefaultProps: ->
         canBeScheduled: true,

      registerStores: ->
         @on FeatureStore, ->
            has_scheduled_exports: FeatureStore.hasFeature("export_settings")

      render: ->
         {item, exportType, lastExport, label} = @props
         lastExportTime = if lastExport
            moment(lastExport.updated_at).format("MMM DD, YYYY h:mm A")
         else
            " None"

         div className: "export-item",
            div className: "export-item--heading", label
            div className: "export-item--schedule-section",
               if !_.isEmpty(item)
                  if item.scheduled
                     span null,
                        Icon icon: "access-time"
                        " Daily at #{item?.scheduled || ""} (#{item?.timezone || ""})"
               else
                  span null,
                     Icon icon: "warning"
                     " Not Set"

            div null,
               lastExportTime
            div className:"export-item--manual-button",
               if exportType != "task"
                  ModalTrigger
                        title: "Edit Schedule"
                        modal: ScheduleExportsModal({exportType, item})
                        Icon icon: "settings"

               ModalTrigger
                     className:"export-item--manual-button"
                     title: "Manual Export"
                     modal: ManualExportModal({exportType})
                     Icon icon: "file-export"