import _ from "underscore";
import { createContext, Component } from "react";
import PropTypes from "prop-types";
import { getSelectionQueryAll, getSelectionQueryFromColumns } from "apps/portfolio-performance/portfolio-queries";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const ProspectSelectionContext = createContext(() => {});

class ProspectSelectionProvider extends Component {
  static propTypes = {
    stages: PropTypes.array,
    team: PropTypes.object,
    solicitor: PropTypes.object,
    total: PropTypes.number,
    children: PropTypes.any,
  };

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    if (this._isMounted) {
      this.setState(...args);
    }
  };

  handleSelectAll = (selectAll, tab, map_bounds) => {
    // if selecting all on stages view
    if (tab === "stage") {
      const cols = {};
      _.each(this.props.stages, (stage_name) => {
        cols[stage_name] = { all: selectAll, ids: [] };
      });
      this.setSelectedColumns(selectAll ? cols : {});
      this.setSelectionQueryAndTotal(selectAll ? cols : {});
      // else if in map view
    } else {
      SelectedContactsSource.selectAll(selectAll);
      if (selectAll) {
        let query = getSelectionQueryAll(
          this.props.team.id,
          this.props.solicitor.id,
          this.state.selected_map_stages,
          map_bounds
        );
        this.handleSetSelectionCount(this.props.total);
        this.handleSetSelectionQuery(query);
        // if you're removing all selections
      } else {
        this.handleSetSelectionCount(0);
        this.handleSetSelectionQuery({});
      }
    }
  };

  handleUnselectAll = () => {
    this.setSelectedColumns({});
    this.setSelectionQueryAndTotal({});
    this.handleSetSelectionCount(0);
  };

  handleSelectColumn = (is_selected, selected_stage, col_total) => {
    let selected_cols = { ...this.state.selected_columns };
    if (is_selected) {
      // update all: true b/c of infinite scroll won't necessarily have all ids loaded
      selected_cols[selected_stage] = { all: true, ids: [], selected_count: col_total };
    } else {
      selected_cols = _.omit(selected_cols, selected_stage);
    }
    this.setSelectedColumns(selected_cols);
    this.setSelectionQueryAndTotal(selected_cols);
  };

  handleSelectContact = (contact_id, column, ids_in_column, total_in_column) => {
    const selected_cols = { ...this.state.selected_columns };
    const selected_column = selected_cols[column] || { all: false, ids: [] };

    // if the whole column is already selected, select all the ids available except for the one clicked
    if (selected_column.all) {
      selected_column.ids = _.without(ids_in_column, contact_id);
    } else if (_.contains(selected_column.ids, contact_id)) {
      selected_column.ids = _.without(selected_column.ids, contact_id);
    } else {
      selected_column.ids.push(contact_id);
    }
    // update if all are selected (need to do b/c of infinite scroll, may not have all ids)
    selected_column.all = false;
    if (selected_column.ids.length === total_in_column) {
      selected_column.all = true;
    }

    // update total selected
    selected_column.selected_count = selected_column.ids.length;
    selected_cols[column] = selected_column;

    this.setSelectedColumns(selected_cols);
    this.setSelectionQueryAndTotal(selected_cols);
  };

  setSelectionQueryAndTotal = (selected_columns) => {
    let selection_query = {};
    const selected_all = this.areColumnsFullySelected(selected_columns);
    if (selected_all) {
      selection_query = getSelectionQueryAll(this.props.team.id, this.props.solicitor.id, this.props.stages);
    } else {
      selection_query = getSelectionQueryFromColumns(this.props.team.id, this.props.solicitor.id, selected_columns);
    }

    const selected_count = this.getSelectedCount(selected_columns);
    // sets the selected count and selection query in contact selection provider
    this.handleSetSelectionCount(selected_count);
    this.handleSetSelectionQuery(selection_query);
  };

  areColumnsFullySelected = (cols) => {
    const all_stages = this.props.stages;
    const total = _.reduce(
      cols,
      (accum, col = {}) => {
        if (col.all) {
          accum = accum + 1;
        }
        return accum;
      },
      0
    );
    return all_stages.length === total;
  };

  getSelectedCount = (selected_columns) => {
    const selected_all = this.areColumnsFullySelected(selected_columns);
    if (selected_all) {
      return this.props.total;
    } else {
      const sum = _.reduce(
        selected_columns,
        (accum, col_obj) => {
          return accum + col_obj.selected_count;
        },
        0
      );
      return sum;
    }
  };

  handleSetSelectionCount = (count) => {
    this.setState({ selected_count: count });
  };

  handleSetSelectionQuery = (query) => {
    this.setState({ selection_query: query });
  };

  handleClearSelectionData = () => {
    this.setState({
      selected_count: 0,
      selection_query: {},
    });
  };

  setSelectedColumns = (selected_columns) => {
    this.setState({ selected_columns });
  };

  setSelectedMapStages = (selected_map_stages) => {
    this.setState({ selected_map_stages });
  };

  getDefaultQuery = () => {
    return getSelectionQueryAll(this.props.team.id, this.props.solicitor.id, this.props.stages);
  };

  // selected columns example:
  //
  // selected_columns: {
  //   {"Identify": {all: true, ids: []]}}}
  // }
  //
  // Note: "all: true or false" is needed because the column is infinite scroll
  // so not all ids might be loaded
  state = {
    selected_columns: [],
    areColumnsFullySelected: this.areColumnsFullySelected,
    onSelectColumn: this.handleSelectColumn,
    onSelectContact: this.handleSelectContact,
    onSelectAll: this.handleSelectAll,
    selected_count: 0,
    getDefaultQuery: this.getDefaultQuery,
    selection_query: {},
    clearSelectionData: this.handleClearSelectionData,
    setSelectionCount: this.handleSetSelectionCount,
    setSelectionQuery: this.handleSetSelectionQuery,
    // used on map
    selected_map_stages: [],
    setSelectedMapStages: this.setSelectedMapStages,
    unselectAll: this.handleUnselectAll,
  };

  render() {
    return (
      <ProspectSelectionContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ProspectSelectionContext.Provider>
    );
  }
}

export { ProspectSelectionProvider };

export default ProspectSelectionContext;
