module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   SessionSource = require("base/session/session-source")
   UserSource = require("base/user/user-source")

   class Session extends AuthApi.Model
      urlRoot: "skiff"
      TEN_SECONDS = 10000

      initialize: ->
         @on "change", @saveToStore, @

      parse: (data={}) ->
         if data.token
            _.extend {}, data, {id: "id-#{data.token}"}

      renewSession: (options = {}) ->
         SessionSource.startRenewSession(options)

      saveToStore: ->
         EverTrue.store.push "session", @, {cache: true}
         unless EverTrue.request "isImpersonating?"
            if user_data = @get("user")
               UserSource.set(user_data)

      # Return session timeout - 10 seconds to account for network latency
      getSessionTimeout: ->
         session_created_at = @get("created_at")
         session_expire_at = @get("expire_at") - TEN_SECONDS
         return Math.max(0, session_expire_at - session_created_at)
         
         SESSION_TIMEOUT
      
      # Check if the session is expiring in the next 10 seconds
      isExpiringInTenSeconds: ->
         session_expire_at = @get("expire_at")
         current_time = new Date().getTime()
            
         session_expire_at - current_time <= TEN_SECONDS
