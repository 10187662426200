export const ANNUAL_GIVING_LABEL = 'Annual Giving';
export const FUNDRAISING_TRENDS_LABEL = 'Fundraising Trends';
export const ONE_TEAM_LABEL = 'One Team';
export const PROSPECT_MANAGEMENT_LABEL = 'Prospect Identification and Management';
export const SIGNAL_ACTIVITY_LABEL = 'Signal Prospect Activity - Tasks and Cadences';
export const INCLUDED_WITH_EVERTRUE_LABEL = 'Included with EverTrue';

// Fundraising Trends dashboards
export const FUNDRAISING_ANALYTICS = 'Fundraising Analytics';

// Annual Giving dashboards
export const ANNUAL_GIVING_SUMMARY = 'Annual Giving Summary';
export const ANNUAL_GIVING_PROGRESSION = 'Annual Giving Level Progression';
export const APPEALS_IMPACT = 'Appeals Impact';
export const DONORS_BY_FISCAL_YEAR = 'Donors by Fiscal Year and Month';

// Prospect Management dashboards
export const FUNDRAISER_INSIGHTS = 'Fundraiser Insights';
export const PORTFOLIO_ENGAGEMENT = 'Portfolio Engagement';
export const PORTFOLIO_CONTACTS = 'Portfolio Contacts';
export const PORTFOLIOS_BENCHMARKING = 'Portfolios Benchmarking';
export const SIGNAL_TOP_PROSPECTS = 'Signal Top Prospects';
export const PROPOSAL_PIPELINE = 'Proposal Pipeline';

// Signal Activity dashboards
export const SIGNAL_TASK_TRACKER = 'Signal Task Tracker';
export const SIGNAL_ACTIVITY = 'Signal Activity';
export const SIGNAL_ACTIVITY_YOY = 'Signal Activity YOY';
export const SIGNAL_DXO_MEETINGS_AND_ASKS = 'Signal DXO Meetings and Asks';
export const SIGNAL_INFLUENCE_DOLLARS = 'Signal Influence Dollars';

// One Team Dashbooard
export const DONORS_BY_YEAR = 'Donors by Year Across Customers';
export const DXPM_REPORTING = 'DXPM Reporting Resources';
export const EVERTRUE_APP_USAGE = 'EverTrue Application Usage';
export const FILE_AVAILABILITY = 'File Availability';
export const LEVEL_PROGRESSION = 'Level Progression Across Customers';
export const PORTFOLIO_ENGAGEMENT_CUSTOMERS = 'Portfolio Engagement Across Customers';
export const PROPOSAL_PIPELINE_CUSTOMERS = 'Proposal Pipeline Across Customers';
export const THANKVIEW = 'ThankView';
export const TOTAL_INTERACTIONS = 'Total Interactions';

// Included with EverTrue Dashboards
export const CLARITY_PREVIEW = 'Clarity Preview';
export const APP_USAGE_V2 = 'EverTrue Application Usage V2';
export const STANDARDIZATION = 'Standardization';

export const CATEGORY_OPTIONS = [
  { label: 'All Dashboards', value: 'All Dashboards' },
  { label: SIGNAL_ACTIVITY_LABEL, value: SIGNAL_ACTIVITY_LABEL },
  { label: FUNDRAISING_TRENDS_LABEL, value: FUNDRAISING_TRENDS_LABEL },
  { label: PROSPECT_MANAGEMENT_LABEL, value: PROSPECT_MANAGEMENT_LABEL },
  { label: ANNUAL_GIVING_LABEL, value: ANNUAL_GIVING_LABEL },
  { label: INCLUDED_WITH_EVERTRUE_LABEL, value: INCLUDED_WITH_EVERTRUE_LABEL },
  { label: ONE_TEAM_LABEL, value: ONE_TEAM_LABEL, requiresAdmin: true },
];
