import { useMemo } from 'react';
import { PropTypes } from 'base/react-libs';
import { countTasksForSteps, percentOfStepTasksComplete } from 'apps/journeys/utils/helpers/counters';
import DueDatePicker from 'apps/journeys/components/top-nav/due-date-picker';
import ProgressBarWithLabel from 'components/forms/progress-bar-with-label';
import JourneyActionTypeFilterButton from 'apps/journeys/utils/journey-action-type-filter-button';

const StepTableHeader = ({ steps = [] }) => {
  const string = JSON.stringify(steps);
  const completionPercentage = useMemo(() => {
    if (string) return percentOfStepTasksComplete(steps);
    else return 0;
  }, [steps, string]);

  const counts = useMemo(() => {
    if (string) {
      const counts = countTasksForSteps(steps);
      delete counts.Completed; // don't visualize completed tasks here
      return counts;
    } else return {};
  }, [steps, string]);

  const mappedEntries = () => {
    const entries = [];
    const { Total, ...rest } = counts;
    for (const name in rest) {
      const count = counts[name];
      entries.push(<JourneyActionTypeFilterButton name={name} count={count} key={`${name}${count}`} />);
    }

    return entries;
  };

  return (
    <div className="step-table-header">
      <div className="step-table-header--item">Total: {counts?.Total || 0}</div>
      <div className="step-table-header--item">
        <div className="step-table-header--icon-group">{mappedEntries()}</div>
      </div>
      <div className="step-table-header--item">
        <ProgressBarWithLabel
          containerStyle={{ flex: 2 }}
          width={100}
          weight="thin"
          title="Progress"
          inlineLabel={true}
          showPercent={true}
          progressColor="#73c166"
          percent={completionPercentage}
        />
      </div>
      <div className="step-table-header--item">
        <DueDatePicker />
      </div>
    </div>
  );
};

StepTableHeader.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default StepTableHeader;
