import EverTrue from 'app';
import PropTypes from 'prop-types';
import NavigationSubItem from 'apps/layout/components/navigation-sub-item';
import { useContext } from 'react';
import { TasksNotificationContext } from '../hooks/tasks-notification-context.js';
import DotNotificationBadge from 'components/notifications/dot-notification-badge.js';
import { trackTaskAction } from 'apps/tasks/utils/utils';

const NavigationTaskLink = ({ active_path = '', isCollapsed }) => {
  const { enabled, loaded, tasksDueCount = {} } = useContext(TasksNotificationContext);
  const { owned = 0 } = tasksDueCount;

  if (!loaded) return null;
  if (enabled) {
    return (
      <NavigationSubItem
        icon="tasks"
        label="Tasks"
        isActive={!!active_path.match(new RegExp(`tasks.*`))}
        onClick={() => {
          trackTaskAction('navigate_to_tasks_from_left_nav');
          EverTrue.Navigator('/tasks', true);
        }}
        iconKit="gt2"
      >
        <DotNotificationBadge
          popoverText={'Incomplete Tasks Due Today'}
          showBadge={!!owned}
          style={{ marginLeft: isCollapsed ? '5px' : '75px' }}
        />
      </NavigationSubItem>
    );
  } else {
    return null;
  }
};

NavigationTaskLink.propTypes = {
  active_path: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default NavigationTaskLink;
