import { useContext, useEffect, useRef, useState } from 'react';
import TasksTable from 'apps/tasks/components/table/tasks-table';
import TasksHeader from 'apps/tasks/components/tasks-header';
import TaskQuickFilters from 'apps/tasks/components/task-quick-filters';
import { TasksContext } from 'apps/tasks/hooks/tasks-context';
import TasksNav from 'apps/tasks/controllers/tasks-nav';
import PropTypes from 'prop-types';
import TasksWelcomePage from 'apps/tasks/components/tasks-welcome-page';
import { Loading } from '@evertrue/et-components';
import AddTaskButton from '../components/create/add-task-button';
import TasksSuggestionsTable from '../components/table/tasks-suggestions-table';
import JourneyPauseContainer from 'apps/journeys/components/top-nav/journeys-pause-container';
import TasksActionMenu from '../components/tasks-action-menu';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { getDateString } from 'base/new-utils';

const TasksController = ({ tab, contact = {}, userType }) => {
  const {
    total,
    tasks,
    loading,
    getAndSetTasks,
    referrer,
    taskFilters,
    tableSettings,
    setTaskFilters,
    getDefaultFilters,
    allTasksCount,
    allTasksCountLoading,
    removedLastTask,
  } = useContext(TasksContext);

  const tasksResumedRef = useRef(false);

  //Task Actions Menu props
  const [bulkSelectedTasks, setBulkSelectedTasks] = useState([]);
  const [inBulkSelectMode, setInBulkSelectMode] = useState(false);
  const selectedDueDate = getDateString();
  const { flagEnabled: hasBulkTaskMoves } = useFeatureFlag('bulkTaskMoves');

  const handleTaskSelectionChange = (newSelectedTasks) => {
    setBulkSelectedTasks(newSelectedTasks);
  };

  const refreshTasks = () => {
    getAndSetTasks(taskFilters);
    tasksResumedRef.current = true;
  };

  useEffect(() => {
    setTaskFilters({ ...getDefaultFilters() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, userType]);

  useEffect(() => {
    getAndSetTasks(taskFilters, userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, tableSettings.sortReverse, tableSettings.page, tableSettings.sortProp]);

  useEffect(() => {
    handleTaskSelectionChange([]);
  }, [tab]);

  if (allTasksCountLoading) {
    return <Loading />;
  } else {
    if (referrer !== 'contact' && allTasksCount < 1 && !removedLastTask) {
      return <TasksWelcomePage userType={userType} loading={loading} />;
    } else {
      return (
        <>
          {referrer !== 'contact' && (
            <TasksHeader
              userType={userType}
              bulkSelectedTasks={bulkSelectedTasks}
              selectedDueDate={selectedDueDate}
              hasBulkTaskMoves={hasBulkTaskMoves}
              resetBulkSelect={() => {
                setBulkSelectedTasks([]);
                setInBulkSelectMode(false);
              }}
            />
          )}
          {referrer !== 'contact' && <TasksNav tab={tab} contact={contact} />}
          <div className="flex flex-col">
            {tab !== 'ai-tasks' && (
              <>
                <div className="flex space-between">
                  <TaskQuickFilters userType={userType} />
                  {referrer === 'contact' && (
                    <div style={{ padding: '8px 16px' }}>
                      <AddTaskButton contact={contact} userType={userType} />
                      <TasksActionMenu
                        selectedDueDate={selectedDueDate}
                        bulkSelectedTasks={bulkSelectedTasks}
                        referrer={referrer}
                        hasBulkTaskMoves
                        resetBulkSelect={() => {
                          setBulkSelectedTasks([]);
                          setInBulkSelectMode(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div style={{ width: '100%' }}>
                  {tab !== 'assigned' && <JourneyPauseContainer refreshTasks={refreshTasks} />}
                </div>
              </>
            )}
          </div>
          {tab === 'ai-tasks' ? (
            <TasksSuggestionsTable />
          ) : (
            <TasksTable
              total={total}
              tasks={tasks}
              loading={loading}
              tab={tab}
              userType={userType}
              taskFilters={taskFilters}
              contact={contact}
              bulkSelectedTasks={bulkSelectedTasks}
              handleTaskSelectionChange={handleTaskSelectionChange}
              hasBulkTaskMoves={hasBulkTaskMoves}
              inBulkSelectMode={inBulkSelectMode}
              setInBulkSelectMode={setInBulkSelectMode}
            />
          )}
        </>
      );
    }
  }
};

TasksController.propTypes = {
  contact: PropTypes.object,
  tab: PropTypes.string,
  userType: PropTypes.string,
};

export default TasksController;
