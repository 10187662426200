const gql = String.raw;

const homepageQueries = {
  activeTaskCountForToday: gql`
    query {
      todayActiveTaskCount {
        CALL
        EMAIL
        LINKEDIN
        RESEARCH
        PRINT_MAIL
        TEXT
        THANK_VIEW
        OTHER
        TOTAL
        COMPLETED
      }
    }
  `,

  pausedForDaysTaskCount: gql`
    query ($endDate: String!) {
      pausedForDaysTaskCount(endDate: $endDate) {
        CALL
        EMAIL
        LINKEDIN
        RESEARCH
        PRINT_MAIL
        TEXT
        THANK_VIEW
        OTHER
        TOTAL
        COMPLETED
      }
    }
  `,

  fetchTrips: gql`
    query ($userId: BigInt!, $dayRange: BigInt) {
      trips(userId: $userId, dayRange: $dayRange) {
        name
        type
        upcoming_meetings(dayRange: $dayRange) {
          id
          type
          name
          start_at
          end_at
          contact_meetings {
            contact {
              id
              contact_attributes {
                name_full
              }
            }
          }
        }
      }
    }
  `,

  //todo: replace with a query which accepts date range or returns details for next 5 days
  //query to fetch counts for all journey groups for a specific date
  fetchUpcomingTaskCounts: gql`
    query (
      $taskOwnerUserId: BigInt!
      $taskStatuses: [String!]
      $includeInactive: Boolean!
      $fetchJourneysTaskCount: Boolean!
      $dayRange: Int!
      $journeyGroupType: String
    ) {
      getUpcomingTaskCounts(
        taskOwnerUserId: $taskOwnerUserId
        taskStatuses: $taskStatuses
        includeInactive: $includeInactive
        fetchJourneysTaskCount: $fetchJourneysTaskCount
        dayRange: $dayRange
        journeyGroupType: $journeyGroupType
      )
    }
  `,

  /*
  ---------------------
  Recommended Actions Queries
  ---------------------
    */

  // This fetches information about the Solicitor Record, which is what assignments are connected to.
  fetchSolicitorInformation: gql`
    query ($contactId: BigInt!) {
      solicitor(contact_id: $contactId) {
        contact_id
        assignments_pagination {
          count
        }
      }
    }
  `,

  fetchAssignedWithRecentGiftContactIds: gql`
    query ($userSolicitorContactId: BigInt!, $daysSinceLastGift: Int!, $minGiftAmount: Float!) {
      assignedWithRecentGiftContactIds(
        userSolicitorContactId: $userSolicitorContactId
        daysSinceLastGift: $daysSinceLastGift
        minGiftAmount: $minGiftAmount
      )
    }
  `,

  fetchAssignedWithGiftAnniversaryContactIds: gql`
    query ($userSolicitorContactId: BigInt!) {
      assignedWithGiftAnniversaryContactIds(userSolicitorContactId: $userSolicitorContactId)
    }
  `,

  fetchAssignedWithCareerMovesContactIds: gql`
    query ($userSolicitorContactId: BigInt!) {
      assignedWithCareerMovesContactIds(userSolicitorContactId: $userSolicitorContactId)
    }
  `,

  fetchAssignedWithTripsAndMeetingsContactIds: gql`
    query ($userSolicitorContactId: BigInt!, $userId: BigInt!) {
      assignedWithTripsAndMeetingsContactIds(userSolicitorContactId: $userSolicitorContactId, userId: $userId)
    }
  `,

  fetchAssignedWithDPSContactIds: gql`
    query ($userId: BigInt!, $userSolicitorContactId: BigInt!, $monthsSinceLastInteraction: Int!) {
      assignedWithDPSContactIds(
        userId: $userId
        userSolicitorContactId: $userSolicitorContactId
        monthsSinceLastInteraction: $monthsSinceLastInteraction
      )
    }
  `,

  fetchContactForRecommendedAction: gql`
    query ($id: BigInt!) {
      contact(id: $id) {
        contact_attributes {
          name_full
        }
        emails {
          email
          primary
        }
        profile_image {
          url
        }
        active_journeys {
          journey_name
          step_number
          day_number
        }
        giving {
          lifetime_amount
          last_gift_date
          last_gift_amount
          last_gift_label
        }
        id
        enrichment {
          employment_events {
            type
            recorded_at
          }
        }
      }
    }
  `,

  fetchContactForRecommendedActionTrips: gql`
    query ($contactId: BigInt!, $userId: BigInt!) {
      getLastMeetingAndInteractionDates(contactId: $contactId, userId: $userId)
    }
  `,

  fetchContactForRecommendedActionDps: gql`
    query ($contactId: BigInt!, $userId: BigInt!) {
      getDpsScoreAndInteractionDate(contactId: $contactId, userId: $userId)
    }
  `,
};

export default homepageQueries;
