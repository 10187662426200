import PropTypes from 'prop-types';
import { useFluxStore } from '@evertrue/et-flux';
import CollaboratorStore from 'apps/lists/stores/collaborator-store';
import UserCard from 'apps/users/components/user-card';
import PagedColumnTableBody from 'components/tables/paged-col-table-body';
import { IconButton } from '@evertrue/et-components';
import CollaboratorSource from 'apps/lists/sources/collaborator-source';
import EverTrue from 'app';

const CollaboratorUserList = ({ listId }) => {
  const collaborators = useFluxStore(CollaboratorStore, () => ({
    loading: CollaboratorStore.getLoading(),
    collaborators: CollaboratorStore.getCollaborators(listId),
  }));

  const handleRemove = (data) => {
    CollaboratorSource.remove(listId, data.collaborator_user_id);
    EverTrue.track.set('list_action', { type: 'remove_collabs' });
  };

  return (
    <div className="share-settings--users loading-container">
      <div className="share-settings--users-heading">Who has access?</div>
      <div className="share-settings--users-body loading-container">
        <table className="table">
          <PagedColumnTableBody
            data={collaborators.collaborators}
            columns={[
              { label: 'User', prop: 'name', row: 'user', width: 325, sticky: true },
              { label: '', prop: null, row: 'role', key: 'role' },
              { label: '', prop: null, row: 'actions', width: 125, key: 'action' },
            ]}
            emptyMessage="List has not been shared"
            syncing={collaborators.loading}
            cells={{
              user: (data) => <UserCard user={data.user} />,
              role: (data) => <div>{data.owner ? 'Owner' : 'Can Edit'}</div>,
              actions: (data) => (
                <div>
                  {data.owner ? null : (
                    <IconButton title="Remove Access" icon="cancel" size={2} onClick={() => handleRemove(data)} />
                  )}
                </div>
              ),
            }}
          />
        </table>
      </div>
    </div>
  );
};

CollaboratorUserList.propTypes = {
  listId: PropTypes.string.isRequired,
};

export default CollaboratorUserList;
