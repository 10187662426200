import { useState, useEffect, useCallback } from 'react';
import EverTrue from 'app';
import { useIdentity } from 'base/identity-resolver';
import { getLinkUrl } from 'entities/helpers/hardcodedLinks';

const useUserAuthentication = () => {
  const [user, setUser] = useState(null);
  const [clientId, setClientId] = useState(null);
  const { org } = useIdentity();
  const fetchUser = useCallback(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      const current_user = EverTrue.store.user.toJSON();
      setUser(current_user);
      localStorage.setItem('user', JSON.stringify(current_user));
    }
  }, []);

  const fetchClientId = useCallback(() => {
    const storedClientId = localStorage.getItem('clientId');
    if (storedClientId) {
      setClientId(JSON.parse(storedClientId));
    }
  }, []);

  const authenticateUser = useCallback(
    async (currentUser) => {
      if (!currentUser) return;

      try {
        const params = {
          name: currentUser.name,
          email: currentUser.email,
          et_oid: org.id,
          et_user_id: currentUser.id,
          business_name: org.slug || 'givingtreedemo',
        };

        const url = getLinkUrl('thankView', 'authenticateUserUrl');

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Signal-Client-Key': EverTrue.config.thankview_client_key,
            'Signal-Client-Secret': EverTrue.config.thankview_client_secret,
          },
          redirect: 'error',
          body: JSON.stringify(params),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClientId(data);
        localStorage.setItem('clientId', JSON.stringify(data));
      } catch (error) {
        console.error('Error:', error);
      }
    },
    [org]
  );

  useEffect(() => {
    fetchUser();
    fetchClientId();
  }, [fetchUser, fetchClientId]);

  useEffect(() => {
    if (user && !clientId) {
      authenticateUser(user);
    }
  }, [user, clientId, authenticateUser]);

  return { user, clientId };
};

export default useUserAuthentication;
