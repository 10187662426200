import { Button, Checkbox } from '@evertrue/et-components';
import { useState, useEffect } from 'react';
import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import TaskStatusMessage from './task-status-message';
import CompleteTaskToggle from 'apps/tasks/components/complete-task-toggle';
import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import TaskRowActionButton from './task-row-action-button';
import TaskRowOutreachButtons from './task-row-outreach-buttons';

const TaskRow = ({ task = {}, step = {}, handleCompletionClick = () => {} }) => {
  const [isActive, setIsActive] = useState(false);
  const {
    journey = {},
    sidebarIsOpen,
    selectedTasks = [],
    setSelectedTasks = () => {},
    openContactSummary,
    inBulkSelectMode,
  } = useJourneyDetailProvider();

  useEffect(() => {
    if (!sidebarIsOpen) {
      setIsActive(false);
    }
  }, [sidebarIsOpen]);

  if (!task) return null;
  const { id, contact, action_type } = task || {};

  return (
    <div key={`task-record-${id}`} className={`journeys-task-table-row--details ${isActive ? 'active' : ''}`}>
      <div>
        {inBulkSelectMode ? (
          <Checkbox
            label="Task Selector"
            checked={selectedTasks.some((t) => t.id === task.id)}
            onChange={(checked) => {
              if (checked) {
                setSelectedTasks((prev) => [...prev, task]);
              } else {
                setSelectedTasks((prev) => prev.filter((t) => t.id !== task.id));
              }
            }}
            className="checkbox-small"
            checkboxStyle={{ height: '18px', width: '18px', margin: '8px' }}
          />
        ) : (
          <CompleteTaskToggle task={task} size="small" onClick={handleCompletionClick} />
        )}
        <JourneyContactCard contact={contact || {}} currentJourney={journey} actionType={action_type} />
      </div>
      <div style={{ display: 'flex', gap: '15px' }}>
        <TaskStatusMessage task={task} />
        <TaskRowOutreachButtons task={task} />
        <TaskRowActionButton task={task} step={step} journey={journey} />
        <Button
          disabled={!task || !contact}
          onClick={() => {
            trackJourneysAction('view_link');
            openContactSummary(contact || {});
            setIsActive(true);
          }}
          type="simple"
        >
          Preview
        </Button>
      </div>
    </div>
  );
};

TaskRow.propTypes = {
  step: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  handleCompletionClick: PropTypes.func,
};

export default TaskRow;
