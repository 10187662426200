module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   FilterStore = require("apps/filters/stores/filter-store")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   JOIN_WIDTH = 40
   MORE_WIDTH = 100


   createComponent "FilterDisplayTitle",
      getInitialState: ->
         widths: undefined
         outerWidth: undefined

      registerStores: ->
         @on FilterStore, ->
            operator: FilterStore.getActiveOperator()
            active_filters: FilterStore.getActiveFilters()

         @on FilterConfigStore, ->
            options: FilterConfigStore.getAllFilterData()

      componentDidMount: ->
         $(window).resize(@calulateWidths)
         @calulateWidths()

      componentWillUnmount: ->
         $(window).off("resize", @calulateWidths)

      componentDidUpdate: (prevProps, prevState) ->
         if _.deepArrayCompare(prevState.active_filters, @state.active_filters) ||
               _.deepArrayCompare(prevState.options, @state.options)
            @calulateWidths()

      calulateWidths: ->
         $node = $(ReactDOM.findDOMNode(@))
         widths = []

         if $node[0].scrollWidth >= $node.innerWidth()
            $node.find(".filter-display-title--ruler .filter-display-title--label").each ->
               widths.push($(@).width())

         @setState
            widths: widths
            outerWidth: $node.innerWidth()

      render: ->
         all_labels = _.compact _.map @state.active_filters, (filter) =>
            config = _.findWhere(@state.options, {filter_id: filter.filter_id})
            if config?.label_fn then config.label_fn?(filter.value)
            else config?.label

         # Push "Map" to first label
         all_labels = _.sortBy all_labels, (label) ->
            if label?.toLowerCase?() == "map" then 0 else 1

         if @state.widths
            total = 0
            labels = _.filter all_labels, (label, index) =>
               total += (@state.widths[index] || 0) + JOIN_WIDTH
               total < (@state.outerWidth - MORE_WIDTH)
         else labels = all_labels

         # Check if any of the labels cannot fit on the screen,
         # Show "N more..." text when labels can't fit in the width available
         is_overflowing = labels.length < all_labels.length


         div className: classNames(@props.className, "filter-display-title"),

            # Hidden element to measure width of each label
            span className: "filter-display-title--ruler",
               _.map all_labels, (label) ->
                  span className: "filter-display-title--label", key: label, label

            # Display Labels as count or concatenated strings
            if (labels.length == 0 && all_labels.length > 0) || _.isEmpty(@state.options)
               # When no labels can fit, show the total count
               LinkButton className: "filter-display-title--label", title: "Active Filters", onClick: @props.onClick,
                  "#{@state.active_filters.length} active filters"
            else
               span null,
                  # Display each labels and operator between each
                  _.map labels, (label, index) =>
                     span key: label,
                        LinkButton className: "filter-display-title--label", "aria-label": label, title: "", onClick: @props.onClick, label
                        if index < labels.length - 1 || is_overflowing
                           span className: "filter-display-title--operator", style: {width: JOIN_WIDTH},
                              if label?.toLowerCase?() == "map" then " and "
                              else " #{@state.operator} "

                  # Display N more... if filters are overflowing the text area
                  if is_overflowing
                     LinkButton
                        className: "filter-display-title--overflow"
                        title: "View Move Filters"
                        onClick: @props.onClick
                        style: {width: MORE_WIDTH}

                        all_labels.length - labels.length
                        " more..."
