import { PropTypes } from 'base/react-libs';
import { useIdentity } from 'base/identity-resolver';
import { useState, useEffect } from 'react';
import { moveConstituentToJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { fetchAllJourneysForAllJourneyGroups } from 'apps/journeys/utils/api-requests/journey-index';
import MoveModal from 'apps/journeys/components/modals/common/move-modal';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { mapContactObjectToGraphqlShape } from 'apps/journeys/utils/helpers/utils';

const AddToJourneyModal = ({ isOpen, onClose, contact, activeJourneys, refreshProfile = () => {} }) => {
  const { user, org } = useIdentity();
  const [journeysList, setJourneysList] = useState([]);

  useEffect(() => {
    const getAndSetJourneys = async () => {
      const allUserJourneys = await fetchAllJourneysForAllJourneyGroups();
      const activeJourneyIds = activeJourneys.map(({ journey_id }) => journey_id);
      setJourneysList(
        allUserJourneys.reduce((filteredJourneys, journey) => {
          const { id, name } = journey;
          const journeyOption = { label: name, value: id };
          return !activeJourneyIds.includes(id) ? [...filteredJourneys, journeyOption] : filteredJourneys;
        }, [])
      );
    };
    getAndSetJourneys();
  }, [user.id, activeJourneys]);

  const handleSubmit = async (selectedNewJourney) => {
    await moveConstituentToJourney(
      null,
      contact.id,
      user.id,
      org.id,
      selectedNewJourney.value,
      selectedNewJourney.label
    );
    trackJourneysAction('add_cadence_from_profile');
    refreshProfile((prev) => !prev);
    onClose();
  };

  return (
    <MoveModal
      title="Add To Cadence"
      isOpen={isOpen}
      onClose={onClose}
      handleSubmit={handleSubmit}
      contact={mapContactObjectToGraphqlShape(contact)}
      options={journeysList}
      activeJourneys={activeJourneys}
    />
  );
};

AddToJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
  activeJourneys: PropTypes.array,
  refreshProfile: PropTypes.func,
};
export default AddToJourneyModal;
