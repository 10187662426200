import { fetchFromGraphql } from 'entities/helpers/gqlCall';
import Api from 'entities/helpers/api';
import ErrorLogger from 'entities/helpers/error-log-helper';
import homepageQueries from 'apps/home/utils/gql/queries';

// HELPER FUNCTION  IN CREATING RECOMMENDED ACTIONS
const getPayloadParamsForSection = (section) => {
  switch (section) {
    case 'recentGift':
      return { segment: 'recent_gift', number_of_days_relevant: 15 };
    case 'giftAnniversary':
      return { segment: 'gift_anniversary', number_of_days_relevant: 30 };
    case 'birthday':
      return { segment: 'birthday', number_of_days_relevant: 7 };
    case 'careerMove':
      return { segment: 'career_move', number_of_days_relevant: 120 };
    case 'tripsAndMeetings':
      return { segment: 'trip_meeting', number_of_days_relevant: 45 };
    case 'dps':
      return { segment: 'dps', number_of_days_relevant: 30 };
    default:
      return { segment: null, number_of_days_relevant: null };
  }
};

const getRecommendedActionsForSection = async (section) => {
  const { segment } = getPayloadParamsForSection(section);
  return (await Api.JOURNEYS.RECOMMENDED_ACTIONS.get({ params: { segment, include_viewed: false } })) || [];
};

const getContactIdsForSection = async (section, userSolicitorContactId, userSettings) => {
  if (!section || !userSolicitorContactId) return [];

  try {
    switch (section) {
      case 'recentGift':
        return await getContactIdsForRecentGiftSection(userSolicitorContactId, userSettings);
      case 'giftAnniversary':
        return await getContactIdsForGiftAnniversarySection(userSolicitorContactId);
      case 'birthday':
        return []; // TODO: Implement this section.
      case 'careerMove':
        return await getContactIdsForCareerMovesSection(userSolicitorContactId);
      case 'tripsAndMeetings':
        return await getContactIdsForTripsAndMeetingsSection(userSolicitorContactId, userSettings);
      case 'dps':
        return await getContactIdsForDPSSection(userSolicitorContactId, userSettings);
      default:
        return [];
    }
  } catch (error) {
    ErrorLogger.error(`Error in fetching Contact Ids for ${section} section.`, {
      extra: { error, page_name: 'home_page' },
    });
  }
};

const createRecommendedActionsForContacts = async (contactIds = [], section, existingRecs = []) => {
  const { segment, number_of_days_relevant } = getPayloadParamsForSection(section);

  const alreadyCreatedContactIds = existingRecs.map((rec) => rec.contact_id);
  const contactIdsToCreate = contactIds.filter((id) => !alreadyCreatedContactIds.includes(id));

  if (!contactIdsToCreate.length) return [];

  const promises = contactIdsToCreate.map(async (id) => {
    const payload = { contact_id: id, segment };
    return await Api.JOURNEYS.RECOMMENDED_ACTIONS.post({
      data: JSON.stringify(payload),
      params: { number_of_days_relevant },
    });
  });

  const created = (await Promise.all(promises)) || [];
  return created.filter((ra) => !ra.viewed);
};

const getContactIdsForRecentGiftSection = async (userSolicitorContactId, userSettings) => {
  const { daysSinceLastGift, giftAmount } = userSettings;
  const query = homepageQueries.fetchAssignedWithRecentGiftContactIds;
  const params = { userSolicitorContactId, daysSinceLastGift, minGiftAmount: giftAmount };

  const { assignedWithRecentGiftContactIds } = await fetchFromGraphql(
    query,
    params,
    'assignedWithRecentGiftContactIds'
  );

  // returns an array of Contact IDs.
  return assignedWithRecentGiftContactIds || [];
};

const getContactIdsForGiftAnniversarySection = async (userSolicitorContactId) => {
  const query = homepageQueries.fetchAssignedWithGiftAnniversaryContactIds;
  const params = { userSolicitorContactId };

  const { assignedWithGiftAnniversaryContactIds } = await fetchFromGraphql(
    query,
    params,
    'assignedWithGiftAnniversaryContactIds'
  );

  // returns an array of Contact IDs.
  return assignedWithGiftAnniversaryContactIds || [];
};

const getContactIdsForCareerMovesSection = async (userSolicitorContactId) => {
  const query = homepageQueries.fetchAssignedWithCareerMovesContactIds;
  const params = { userSolicitorContactId };

  const { assignedWithCareerMovesContactIds } = await fetchFromGraphql(
    query,
    params,
    'assignedWithCareerMovesContactIds'
  );

  // returns an array of Contact IDs.
  return assignedWithCareerMovesContactIds || [];
};

const getContactIdsForTripsAndMeetingsSection = async (userSolicitorContactId, userSettings) => {
  const { userId } = userSettings;
  if (!userId) return [];
  const query = homepageQueries.fetchAssignedWithTripsAndMeetingsContactIds;
  const params = { userSolicitorContactId, userId };
  const { assignedWithTripsAndMeetingsContactIds } = await fetchFromGraphql(
    query,
    params,
    'assignedWithTripsAndMeetingsContactIds'
  );

  // returns an array of Contact IDs.
  return assignedWithTripsAndMeetingsContactIds || [];
};

const getContactIdsForDPSSection = async (userSolicitorContactId, userSettings) => {
  const { userId, monthsSinceLastInteraction } = userSettings;
  if (!userId || !monthsSinceLastInteraction) return [];
  const query = homepageQueries.fetchAssignedWithDPSContactIds;
  const params = { userSolicitorContactId, userId, monthsSinceLastInteraction };
  const { assignedWithDPSContactIds } = await fetchFromGraphql(query, params, 'assignedWithDPSContactIds');

  // returns an array of Contact IDs.
  return assignedWithDPSContactIds || [];
};

export const getAndCreateRecommendedActionsForSection = async (section, userSolicitorContactId, userSettings) => {
  try {
    // STEP 1: Fetch the recommended actions for the section.
    const existingRecommendedActions = await getRecommendedActionsForSection(section);
    // STEP 2: Fetch Contact IDs for contacts that meet the section criteria.
    const contactIds = await getContactIdsForSection(section, userSolicitorContactId, userSettings);
    // STEP 3: Attempt to create a recommended action for each contact. If one already exists, it will not create a duplicate
    const newlyCreatedRecommendedActions = await createRecommendedActionsForContacts(
      contactIds,
      section,
      existingRecommendedActions
    );

    return [...newlyCreatedRecommendedActions, ...existingRecommendedActions];
  } catch (e) {
    ErrorLogger.error(`Error in fetching Recommended Actions for ${section} section.`, {
      extra: { error: e, page_name: 'home_page' },
    });
  }
};

export const fetchContactInfoById = async (id) => {
  const query = homepageQueries.fetchContactForRecommendedAction;
  const params = { id };

  const data = await fetchFromGraphql(query, params, 'contact');
  if (!data) return null;
  return data?.contact || null;
};

export const fetchContactInteractionAndMeetingDetails = async (contactId, userId) => {
  const query = homepageQueries.fetchContactForRecommendedActionTrips;
  const params = { contactId, userId };

  const { getLastMeetingAndInteractionDates = {} } = await fetchFromGraphql(
    query,
    params,
    'getLastMeetingAndInteractionDates'
  );
  return getLastMeetingAndInteractionDates;
};

export const fetchContactInteractionAndDpsDetails = async (contactId, userId) => {
  const query = homepageQueries.fetchContactForRecommendedActionDps;
  const params = { contactId, userId };

  const { getDpsScoreAndInteractionDate = {} } = await fetchFromGraphql(query, params, 'getDpsScoreAndInteractionDate');
  return getDpsScoreAndInteractionDate;
};
