import { getMomentObjectInTimezone } from 'base/new-utils';
import { getPausedTaskCount } from 'apps/home/utils/api-requests/home-page';
import { Icon, Loading } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { useEffect, useState } from 'react';
import ErrorLogger from 'entities/helpers/error-log-helper';
import HomepageCard from '../homepage-card';
import TripsAndMeetingsForNextFive from './trips-meetings-next-five';
import colors from 'base/colors';

const PausedNextFiveDays = ({ inProgressJourneyTimeout = {}, user = {} }) => {
  const [loadingNextFiveCounts, setLoadingNextFiveCounts] = useState(true);
  const [pausedDaysTasksCounts, setPausedDaysTasksCounts] = useState({});

  const pluralize = (count, singular, plural = `${singular}s`) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };

  useEffect(() => {
    if (!inProgressJourneyTimeout || !inProgressJourneyTimeout?.ends_on) return;
    const fetchPausedTaskCounts = async () => {
      try {
        const taskCount = await getPausedTaskCount(inProgressJourneyTimeout?.ends_on);
        setPausedDaysTasksCounts(taskCount);
      } catch (e) {
        ErrorLogger.log('Error fetching paused task counts', e);
      } finally {
        setLoadingNextFiveCounts(false);
      }
    };

    fetchPausedTaskCounts();
  }, [user.id, inProgressJourneyTimeout]);

  const { CALL: calls = 0, EMAIL: emails = 0, TOTAL: total = 0 } = pausedDaysTasksCounts || {};

  return (
    <HomepageCard
      leftHeading={`You have ${total || 0} tasks paused until ${getMomentObjectInTimezone(
        inProgressJourneyTimeout?.ends_on
      ).format('dddd, MMM DD, YYYY')}`}
      headingRow={false}
      body={
        <>
          {loadingNextFiveCounts ? (
            <div style={{ marginTop: '120px', height: '200px' }}>
              <Loading spinner_size={'small'} className={'loader-icon'} position="center" />
            </div>
          ) : (
            <>
              <div className="paused-task-counter-icons--container">
                <div className="icon-count-wrapper">
                  <div className="icon-background">
                    <Icon icon="email" size={1} />
                  </div>
                  <p>{pluralize(emails, 'Email')}</p>
                </div>
                <div className="icon-count-wrapper">
                  <div className="icon-background">
                    <Icon icon="call" size={1} />
                  </div>
                  <p>{pluralize(calls, 'Call')}</p>
                </div>
                <div className="icon-count-wrapper">
                  <div className="icon-background">
                    <Icon
                      iconKit="gt2"
                      icon="other-tasks-cadences"
                      size={2}
                      style={{ color: colors.gray900, fontSize: '20px' }}
                    />
                  </div>
                  <p>{pluralize(total - (calls + emails), 'Other', 'Others')}</p>
                </div>
              </div>
            </>
          )}
          <hr></hr>
          <TripsAndMeetingsForNextFive />
        </>
      }
    />
  );
};

PausedNextFiveDays.propTypes = {
  inProgressJourneyTimeout: PropTypes.object,
  user: PropTypes.object,
};

export default PausedNextFiveDays;
