import { useFeatureFlag } from "entities/helpers/use-feature-flag";
import PropTypes from "prop-types";
import EverTrue from "app";

const FEATURES = Object.freeze({
  cadences: "Cadences",
  signal: "Signal",
  aiSearch: "AI Search",
  aiGeneral: "AI General",
});

const IsFlaggedController = ({ flag, children, navigatePath }) => {
  const { flagEnabled: enabled, loaded } = useFeatureFlag(flag);
  if (loaded && !enabled && navigatePath) {
    EverTrue.Navigator(navigatePath, true);
    return null;
  }
  if (loaded && !enabled)
    return (
      <h1
        style={{
          textAlign: "center",
          margin: "auto",
          marginTop: "35vh",
          color: "#737373",
          lineHeight: 2,
        }}
        className="empty-state-message--text"
      >
        Your Organization does not have&nbsp;{FEATURES[flag] || "this feature"}&nbsp;enabled.
        <br />
        Please contact your CSM to add&nbsp;{FEATURES[flag] || "this feature"}&nbsp;to your organization.
      </h1>
    );
  if (enabled) return <>{children}</>;
  return null;
};

IsFlaggedController.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  navigatePath: PropTypes.string,
};

export default IsFlaggedController;
