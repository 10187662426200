import _ from 'underscore';
import moment from 'moment';
import PropTypes from 'prop-types';
import Decorator from 'clientDecorator';
import classNames from 'classnames';

const CreatedActivity = ({ item, currentUser }) => (
  <li className="list-activity--feed-item">
    <div className="list-activity--label">
      <strong
        className={classNames({
          'is-empty': _.isEmpty(item.user),
          'text-removed-user': item.user?.removed,
        })}
      >
        {Decorator.User.getName(item.user || {}, currentUser) || ' '}
      </strong>
      <span>
        {' '}
        {item.label} on {moment(item.created_at).format('MMM D')}
      </span>
    </div>
    <div className="list-activity--card">
      <strong>{item.name}</strong>
    </div>
  </li>
);

CreatedActivity.propTypes = {
  item: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
};

export default CreatedActivity;
