import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import CompanySource from 'apps/companies/sources/company-source';
import CompanyProfileController from 'apps/companies/controllers/company-profile-controller';

class CompanyRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'company/:id': 'company',
      'company*splat': 'company', // account for name
      'industry/:id': 'industry',
      'industry*splat': 'industry',
    };
  }

  beforeRoute() {
    EverTrue.execute('removePageContainer');
  }

  company(id) {
    const idNum = Number(id);
    if (!id || Number.isNaN(idNum)) {
      const name = EverTrue.UrlManager.get('name');
      CompanySource.fetchCompany(name);
    } else {
      CompanySource.fetchCompany(idNum);
    }

    EverTrue.execute('setBrowserTitle', 'Company');
    EverTrue.track.set('profile', { type: 'company' });
    EverTrue.track.setReferrer('company');

    this.currentView = <CompanyProfileController type="company" />;

    EverTrue.page.mount(this.currentView);
  }

  industry() {
    EverTrue.execute('setBrowserTitle', 'Industry');
    EverTrue.track.set('profile', { type: 'industry' });
    EverTrue.track.setReferrer('industry');

    const name = EverTrue.UrlManager.get('name');

    CompanySource.fetchCompaniesForIndustry(name);
    this.currentView = <CompanyProfileController type="industry" />;

    EverTrue.page.mount(this.currentView);
  }
}

CompanyRouter.initClass();

export default CompanyRouter;
