import EverTrue from 'app';

export const getBlobDuration = (blob) => {
  return new Promise((resolve, reject) => {
    const tempVideoEl = document.createElement('video');
    tempVideoEl.addEventListener('loadedmetadata', () => {
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration);
          tempVideoEl.remove();
        };
      } else {
        resolve(tempVideoEl.duration);
        tempVideoEl.remove();
      }
    });
    tempVideoEl.onerror = (event) => reject(event.target.error);
    tempVideoEl.src = URL.createObjectURL(blob);
  });
};

export const getBrowserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    EverTrue.Alert.error(`Error getting timezone ${e}`);
    return 'UTC'; // Default to UTC if unable to get the timezone
  }
};
