export const countTasksForSteps = (steps = []) => {
  const totalsObj = { Completed: 0, Total: 0, Emails: 0, Calls: 0, Other: 0 };
  if (!steps || !steps.length) return totalsObj;
  steps.forEach(({ tasks = [] }) => {
    tasks.forEach(({ completed_at, action_type }) => {
      if (!completed_at) {
        totalsObj.Total += 1;
        switch (action_type) {
          case 'EMAIL':
            totalsObj.Emails += 1;
            break;
          case 'CALL':
          case 'PHONE':
            totalsObj.Calls += 1;
            break;
          default:
            totalsObj.Other += 1;
            break;
        }
      } else {
        totalsObj.Completed += 1;
      }
    });
  });

  return totalsObj;
};

// CALCULATE COMPLETION PERCENTAGES FOR JOURNEYS TASK TABLE
const countCompleteTasks = (tasks) => {
  if (!tasks || !tasks.length) return 0;

  return tasks.reduce((accumulator, task) => {
    if (task.completed_at) return accumulator + 1;
    else return accumulator;
  }, 0);
};

const countCompleteSteps = (steps) =>
  steps.reduce((accum, step) => {
    const completeTasks = countCompleteTasks(step.tasks);
    return accum + completeTasks;
  }, 0);

const countNumberOfTasksInSteps = (steps) => {
  if (!steps) return 0;

  return steps.reduce((accum, step) => {
    const n = step.tasks ? step.tasks.length : 0;
    return accum + n;
  }, 0);
};

// returns DECIMAL. 1.00 === 100%. 0.05 === 5%, etc
export const percentOfStepTasksComplete = (steps) => {
  if (!steps) return 0;

  const completeSteps = countCompleteSteps(steps);

  const totalTasks = countNumberOfTasksInSteps(steps);
  if (totalTasks === 0) return 0;

  return completeSteps / totalTasks;
};

// returns DECIMAL. 1.00 === 100%. 0.05 === 5%, etc
export const percentOfTasksComplete = (tasks) => {
  if (!tasks.length) return 0;
  return countCompleteTasks(tasks) / tasks.length;
};
