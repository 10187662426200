import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';
import Decorator from 'clientDecorator';
import PaginationUtils from 'mixins/pagination-utils';

export default createSource('RecentContactSource', {
  actions: {
    loading(bool) {
      this.require(typeof bool === 'boolean', 'bool should be a boolean');
    },
    changedPage(page) {
      this.require(typeof page === 'number', 'page should be a number');
    },
    fetchedRecentContacts(contacts) {
      this.require(typeof contacts === 'object' && contacts !== null, 'contacts should be an object');
    },
  },

  api: {
    changePage(page) {
      this.actions.changedPage(page);
    },

    fetch(options = {}) {
      this.actions.loading(true);

      return Api.CONTACTS.RECENT.get({
        params: PaginationUtils.getParams(options),
        success: (resp) => {
          resp.items = resp.items.map((contact) => Decorator.Contacts.parse(contact));
          this.actions.fetchedRecentContacts(resp);
          this.actions.loading(false);
        },
        error: () => {
          this.actions.loading(false);
        },
      });
    },
  },
});
