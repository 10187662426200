import { useState, useEffect } from 'react';
import EverTrue from 'app';
import { useFluxStore } from '@evertrue/et-flux';
import $ from 'jquery';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFeatureFlag } from 'entities/helpers/use-feature-flag';
import { useGate } from 'components/is-gated';
import { useIdentity } from 'base/identity-resolver';
import { useFolders } from 'apps/folder/hooks/use-folders';

// Import sources
import ListSource from 'apps/lists/sources/list-source';
import ListContactSource from 'apps/lists/sources/list-contact-source';
import SelectedContactsSource from 'apps/contact/sources/selected-contacts-source';
import MapSource from 'apps/map/sources/map-source';
import ListActivitySource from 'apps/lists/sources/list-activity-source';

// Import stores
import ListStore from 'apps/lists/stores/list-store';
import ListContactStore from 'apps/lists/stores/list-contact-store';
import ListActivityStore from 'apps/lists/stores/list-activity-store';
import SelectedContactsStore from 'apps/contact/stores/selected-contacts-store';
import BulkAddStore from 'apps/layout/stores/bulk-add-store';

// Import components
import ActionMenu from 'components/controls/action-menu';
import ListActions from 'apps/lists/components/list-actions';
import ClickToInput from 'components/controls/click-to-input';
import EmptyStateMessage from 'components/lists/empty-state-message';
import ContactTableToolbar from 'apps/contact/components/contacts/table/contact-table-toolbar';
import ContactFixedTable from 'apps/contact/components/contacts/contact-fixed-table';
import CollaboratorIconList from 'apps/lists/components/collaborator-icon-list';
import ListActivity from 'apps/lists/components/list-activity';
import ListMapController from 'apps/lists/controllers/list-map-controller';
import MapContacts from 'apps/map/controllers/map-contacts-controller';
import SelectedContactsString from 'components/formatting/selected-contacts-string';
import ShowHideAnimate from 'components/show-hide-animate';
import ProgressBar from 'components/forms/progress-bar';
import { LinkButton, Button, Icon, useOpenClose } from '@evertrue/et-components';
import EmmaListFetcher from 'apps/lists/fetchers/emma-list-fetcher';
import BulkListController from 'apps/lists/controllers/bulk-list-controller';
import EmmaListTimestamp from '../components/emma-list-timestamp';
import AddToFolderModal from 'apps/folder/components/modal/add-to-folder-modal';

const listStoreMap = (listId) => ({
  list: ListStore.getList(listId),
  list_loading: ListStore.getLoading(),
  previous_list: ListStore.getLastSelectedList(),
});

const listContactStoreMap = (listId) => ({
  list_contacts: ListContactStore.getContacts(listId),
  loading: ListContactStore.getLoading(),
});

const listActivityStoreMap = () => ({
  list_activity_collapsed: ListActivityStore.getCollapsed(),
});

const selectedContactsStoreMap = () => ({
  selected_contacts: SelectedContactsStore.getSelectedContacts(),
});

const bulkAddStoreMap = () => ({
  bulk_add_status_obj: BulkAddStore.getBulkAddToList(),
});

const ListController = ({ list_id, view = 'table' }) => {
  const [showModal, setShowModal] = useState(false);
  const [enrichmentLoading, setEnrichmentLoading] = useState(true);
  const { user } = useIdentity();

  const { list, list_loading, previous_list } = useFluxStore(ListStore, () => listStoreMap(list_id));
  const { list_contacts, loading } = useFluxStore(ListContactStore, () => listContactStoreMap(list_id));
  const { list_activity_collapsed } = useFluxStore(ListActivityStore, listActivityStoreMap);
  const { selected_contacts } = useFluxStore(SelectedContactsStore, selectedContactsStoreMap);
  const { bulk_add_status_obj } = useFluxStore(BulkAddStore, bulkAddStoreMap);
  const [hasEmma] = useGate('emma');

  const [isAddToFolderModalOpen, openAddToFolderModal, closeAddToFolderModal] = useOpenClose();
  const { folders, createFolder, addListsToFolder, updateListCountState, addListsToFolderAndUpdateState } =
    useFolders();
  const { flagEnabled: hasFolders } = useFeatureFlag('folders');

  useEffect(() => {
    if (previous_list !== list_id) {
      MapSource.setBoundingBox();
    }
  }, [previous_list, list_id]);

  useEffect(() => {
    ListSource.select(list_id);
    ListContactSource.filter(list_id);

    // Set the event type for certain actions on lists
    setTimeout(() => ListContactSource.setEvent('list_action'), 0);

    return () => {
      ListSource.select(null);
      SelectedContactsSource.clear();
      MapSource.clearClusters();
    };
  }, [list_id]);

  const handleSaveListName = (name) => {
    if (name && name !== list?.name) {
      ListSource.update({
        ...list,
        name,
        type: 'user_list',
      });
      EverTrue.track.set('list_action', { type: 'update_name' });
    }
  };

  // Either create a new folder and add lists to it, or add lists to existing folders, then update state and close modal
  const handleAddListsToFolders = (result) => {
    if (result.type === 'create') {
      createFolder(result.folderName)
        .then((folder) => addListsToFolderAndUpdateState(folder, [list?.id]))
        .then(() => {
          EverTrue.Alert.success(`${list.name} added to ${result.folderName}`);
          closeAddToFolderModal();
        })
        .catch((error) => {
          EverTrue.Alert.error('Error creating Folder and adding List');
        });
    } else if (result.type === 'existing') {
      const { folders } = result;
      const folderIds = folders.map((folder) => folder.value);
      addListsToFolder(folderIds, [list?.id])
        .then((res) => {
          if (!res || !Array.isArray(res)) return;
          EverTrue.Alert.success(`${list.name} added to Folder(s)`);
          updateListCountState(res.flat()); // API is returning nested array when adding to multiple folders
        })
        .then(closeAddToFolderModal)
        .catch((error) => {
          EverTrue.Alert.error('Error adding Lists to Folder(s)');
        });
    }
  };

  const handleRemove = () => {
    ListContactSource.removeContacts(list_id, selected_contacts);
    if (!list_activity_collapsed) {
      ListActivitySource.collapse(!list_activity_collapsed);
    }
    SelectedContactsSource.clear();
  };

  const handleSort = (sort_key, reverse, silent) => {
    ListContactSource.changeSort(list_id, sort_key, !!reverse, silent);
    SelectedContactsSource.clear();
  };

  const handlePage = (page) => {
    ListContactSource.changePage(list_id, page);
    SelectedContactsSource.clear();
    setEnrichmentLoading(true);
  };

  const handleCollapseActivity = () => {
    if (list_activity_collapsed) {
      EverTrue.track.set('list_action', { type: 'list_activity_feed' });
    }
    ListActivitySource.collapse(!list_activity_collapsed);
    // Trigger resize to re-calculate the table columns & scrolling
    setTimeout(() => {
      setTimeout(() => $(window).trigger('resize'), 500);
    }, 0);
  };

  const renderPanelToggle = () => {
    return (
      <LinkButton
        title="Toggle list activity"
        className={classNames('list-detail--activity-toggle', {
          'is-collapsed': list_activity_collapsed,
        })}
        onClick={handleCollapseActivity}
      >
        <Icon icon="collapse" size={2} rotate={!list_activity_collapsed ? 180 : 0} />
        {list_activity_collapsed && <span className="list-detail--activity-toggle-label">Show Activity</span>}
      </LinkButton>
    );
  };

  const isOwner = list?.user_id === user?.id;
  const progress = bulk_add_status_obj?.progress;
  const in_progress = typeof progress === 'number' && progress !== 1;
  const is_bulk_list_in_progress = bulk_add_status_obj?.id === Number(list_id) && in_progress;

  const getProgressValue = () => {
    let progressValue = bulk_add_status_obj?.progress * 100;
    if (progressValue === 0) progressValue += 0.03;
    return progressValue;
  };

  return (
    <div className="list-detail">
      {!list_loading && !list ? (
        <EmptyStateMessage icon="lock" text="You do not have access to this list" />
      ) : is_bulk_list_in_progress ? (
        <EmptyStateMessage icon="clipboard-text" text="Adding constituents to list...">
          <div className="list-detail--progress-bar">
            <ProgressBar className="progress-bar-info-inverse" percent={getProgressValue()} />
          </div>
        </EmptyStateMessage>
      ) : (
        <EmmaListFetcher list_id={parseInt(list_id, 10)} has_emma={hasEmma}>
          {({ accounts, groups, loading: emmaLoading, error, refresh }) => (
            <div>
              <div className="list-detail--header">
                <div className="list-detail--description">
                  <div className="list-detail--label">
                    {isOwner && list?.name !== 'Favorites' ? (
                      <ClickToInput value={list?.name} onSave={handleSaveListName} />
                    ) : (
                      <h1 tabIndex={-1}>{list?.name}</h1>
                    )}
                  </div>

                  <div className="list-detail--info">
                    <SelectedContactsString selected={selected_contacts?.length} results={list_contacts?.total} />
                    <EmmaListTimestamp groups={groups} accounts={accounts} />
                  </div>
                </div>

                <div className="list-detail--actions">
                  {isAddToFolderModalOpen && (
                    <AddToFolderModal
                      onSubmit={handleAddListsToFolders}
                      isOpen={isAddToFolderModalOpen}
                      onClose={closeAddToFolderModal}
                      existingFolders={folders}
                    />
                  )}
                  <ActionMenu width={selected_contacts?.length ? 400 : undefined}>
                    <ListActions
                      list={list}
                      resultCount={list_contacts?.total}
                      selectedContacts={selected_contacts}
                      onRemove={handleRemove}
                      emma_groups={groups}
                      emma_accounts={accounts}
                      onEmmaChange={refresh}
                      hasFolders={hasFolders}
                      onAddtoFolder={openAddToFolderModal}
                    />
                  </ActionMenu>
                </div>
              </div>

              <div className="list-detail--sub-header">
                <CollaboratorIconList listId={list_id} />
              </div>

              <div className="list-detail--content fixed-page-wrapper">
                <ContactTableToolbar
                  paginationClassName="list-toolbar-pagination"
                  contacts={list_contacts}
                  listActionLabel="Add to another list"
                  onPage={handlePage}
                  allowAddAll={false}
                >
                  <Button type="destroy" disabled={!selected_contacts?.length} onClick={handleRemove}>
                    Remove from list
                  </Button>

                  <Button
                    type="simple"
                    onClick={() =>
                      EverTrue.Navigator(view === 'table' ? `/list/${list_id}/map` : `/list/${list_id}`, true)
                    }
                  >
                    <Icon icon={view === 'table' ? 'map gt-icon-lg' : 'table-large gt-icon-lg'} />
                    {view === 'table' ? 'Map' : 'Table'}
                  </Button>
                </ContactTableToolbar>

                <div className="list-detail--body fixed-page-wrapper">
                  {showModal && <BulkListController list={list} remove={() => setShowModal(false)} />}

                  {view === 'map' ? (
                    <>
                      <MapContacts contacts={list_contacts} loading={loading} onSort={handleSort} />
                      <ListMapController list_id={list_id} />
                    </>
                  ) : (
                    <>
                      <ContactFixedTable
                        className={classNames('list-detail--contact-table', {
                          'is-activity-collapsed': list_activity_collapsed,
                        })}
                        contacts={list_contacts}
                        loading={loading}
                        onPage={handlePage}
                        onSort={handleSort}
                        emptyStateMessage="No constituents have been added to this list."
                        actionLabel="+ Add Constiutents"
                        onAction={() => setShowModal(true)}
                        setEnrichmentLoading={setEnrichmentLoading}
                        enrichmentLoading={enrichmentLoading}
                      />

                      <ShowHideAnimate
                        show={list_activity_collapsed}
                        className="list-detail--activity-panel is-collapsed"
                        animateInClass="slideInRight"
                        animateOutClass="slideOutRight"
                      >
                        {renderPanelToggle()}
                      </ShowHideAnimate>

                      <ShowHideAnimate
                        show={!list_activity_collapsed}
                        className="list-detail--activity-panel"
                        animateInClass="slideInRight"
                        animateOutClass="slideOutRight"
                      >
                        <div className="list-detail--activity">
                          {renderPanelToggle()}
                          <ListActivity list_id={list_id} />
                        </div>
                      </ShowHideAnimate>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </EmmaListFetcher>
      )}
    </div>
  );
};

ListController.propTypes = {
  list_id: PropTypes.any.isRequired,
  view: PropTypes.oneOf(['table', 'map']),
};

export default ListController;
