module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   Stats = require("entities/contacts/stats")
   SocialEngagement = require("entities/search/social-engagement")
   FacebookMixin = require("mixins/facebook-mixin")
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils").default
   Backbone = require("backbone")

   PostStatStore = ->
      post_stats = new Backbone.Collection()
      engagement_types = []

      _saveGivingStats = (id, stats) ->
         post = post_stats.get(id) || new Backbone.Model({id: id})
         post.set({
            "total_giving": stats.total_giving
            "avg_giving": stats.avg_giving
            "matched_count": stats.matched_count
         })
         post_stats.add(post, {merge: true})

      _saveEngagementStats = (id, type, count) ->
         post = post_stats.get(id) || new Backbone.Model({id: id})
         new_stat = {}
         type_name = "matched_" + type + "_count"
         new_stat[type_name] = count
         post.set(new_stat)
         post_stats.add(post, {merge: true})

      _getPostStats = (id) ->
         if post_stats.get(id)
            post_stats.get(id).toJSON()
         else
            null

      # Public Methods
      api = $.extend true, {}, Backbone.Events,
         getInitialStats: (id) ->
            if _getPostStats(id)
               _getPostStats(id)
            else
               total_giving: "Calculating"
               avg_giving: "Calculating"
               matched_count: "Calculating"
               matched_like_count: "Calculating"
               matched_comment_count: "Calculating"

         bindToUpdates: (callback) ->
            @listenTo post_stats, "add remove change reset", ->
               callback(post_stats?.toJSON())

         getStats: (post_id) ->
            return unless post_id
            engagement_types = FacebookReactions.getAllValues()
            post = _getPostStats(post_id)
            # Get stats if not cached
            # TODO: can probably optimize this more so stats are only loaded if needed
            if !post
               @loadGivingStats(post_id)

            _.each FacebookReactions.getAllValues(), (reaction) =>
               @loadEngagementTypeStat(post_id, reaction)

         getReactionData: (post_id) ->
            stats = _getPostStats(post_id)
            reaction_data = {
               total: 0
               items: []
            }

            _.each FacebookReactions.getAll(), (reaction_obj) ->
               key = reaction_obj.value
               unless key is "comment" # comments counted separately
                  reaction_count = stats?["matched_#{key}_count"]
                  if _.isNumber(reaction_count) and reaction_count > 0
                     reaction_data.total += reaction_count
                     reaction_data.items.push key
            reaction_data

         loadGivingStats: (post_id) ->
            errorText = "Failed to Calculate"
            stats = new Stats()
            stats.fetch
               filters: {contact_post_engagers: {post_id: post_id, types: engagement_types}}
               query: {stats: ["giving.lifetime_amount": {}]}
               disableAlerts: true
               error: ->
                  error_obj = {
                     total_giving: errorText
                     avg_giving: errorText
                     matched_count: errorText
                  }
                  _saveGivingStats(post_id, error_obj)
               success: ->
                  data = stats.get("giving.lifetime_amount")
                  avg = if stats.total > 0 then data?.total / stats.total
                  stat_results = {
                     total_giving: numeral(data?.total).format("$0,0") || 0
                     avg_giving: numeral(avg).format("$0,0") || 0
                     matched_count: stats.total || 0
                  }
                  _saveGivingStats(post_id, stat_results)

         loadEngagementTypeStat: (post_id, type) ->
            content_id = FacebookMixin.formatContentId(post_id)
            engagement_stats = new SocialEngagement()
            engagement_stats.fetch
               disableAlerts: true
               search_query: {post_engagers: {post_id: content_id, types: [type]}}
               success: ->
                  count = engagement_stats.total || 0
                  _saveEngagementStats(post_id, type, count)


   PostStatStore()
