/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { ListboxSelect, ListboxMultiselect, Button } from '@evertrue/et-components';
import { TasksContext } from 'apps/tasks/hooks/tasks-context';
import { trackTaskAction } from 'apps/tasks/utils/utils';
import useFetchAllUsers from 'apps/search/hooks/use-fetch-all-users';
import useTasks from 'apps/tasks/hooks/use-tasks';
import { useIdentity } from 'base/identity-resolver';
import Icon from 'components/elements/icon';

const taskCompletionOptions = [
  { label: 'Incomplete Tasks', value: ['IN_PROGRESS', 'PAUSED'] },
  { label: 'Completed Tasks', value: ['COMPLETED'] },
  { label: 'All Tasks', value: ['IN_PROGRESS', 'COMPLETED', 'PAUSED'] },
];

const cadenceTaskOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TaskQuickFilters = ({ userType }) => {
  const {
    tasks,
    taskFilters,
    setTaskFilters,
    updateTaskFilters,
    updateTableSettings,
    filters,
    setFilters,
    filtersDefaultValue,
    referrer,
    getDefaultFilters,
  } = useContext(TasksContext);

  const taskQuickFilterOptions = [
    { label: 'Action Type', value: 'action_types', filterName: 'actionTypes' },
    { label: 'Cadence Tasks', value: 'cadence_task', filterName: 'isCadenceTask' },
  ];
  if (referrer === 'contact') {
    taskQuickFilterOptions.push(
      { label: 'Created By', value: 'creator_user_ids', filterName: 'taskCreators' },
      { label: 'Assignee', value: 'owner_user_ids', filterName: 'assignees' }
    );
  } else {
    taskQuickFilterOptions.push(
      userType === 'OWNER'
        ? { label: 'Created By', value: 'creator_user_ids', filterName: 'taskCreators' }
        : { label: 'Assignee', value: 'owner_user_ids', filterName: 'assignees' }
    );
    taskQuickFilterOptions.push({ label: 'Constituent Name', value: 'target_contact_ids', filterName: 'constituents' });
  }

  const { user: currentUser } = useIdentity();
  const { users, fetchUsers } = useFetchAllUsers();
  const [constituentOptions, setConstituentOptions] = useState([]);
  const [taskActionTypeOptions, setTaskActionTypeOptions] = useState([]);
  const { fetchFormattedActionTypes } = useTasks();
  const [selectedQuickFilters, setSelectedQuickFilters] = useState([]);

  useEffect(() => {
    const getUniqueContacts = (tasks) => {
      const contactsMap = new Map();

      tasks.forEach((task) => {
        if (task.contact && task.contact.id) {
          const contactId = task.contact.id;
          const contactName = task.contact.contact_attributes.name_full;
          if (!contactsMap.has(contactId)) {
            contactsMap.set(contactId, { label: contactName, value: contactId });
          }
        }
      });
      return Array.from(contactsMap.values());
    };
    const uniqueContacts = getUniqueContacts(tasks);
    setConstituentOptions(uniqueContacts);
  }, [tasks]);

  useEffect(() => {
    const fetchData = async () => {
      const actionTypes = await fetchFormattedActionTypes();
      setTaskActionTypeOptions(actionTypes);
    };
    fetchData();
  }, []);

  const usersOptions = users.map((user) => {
    return { label: user.id === currentUser.id ? user.name + ' (You)' : user.name, value: user.id };
  });

  let searchQueryTimeout = null;

  useEffect(() => {
    resetQuickFilters();
  }, [userType]);

  const resetQuickFilters = (filter) => {
    if (filter) {
      if (filter === 'isCadenceTask') {
        setFilters({ ...filters, isCadenceTask: undefined });
      } else {
        setFilters({ ...filters, [filter]: [] });
      }
    } else {
      setFilters(filtersDefaultValue);
      setSelectedQuickFilters([]);
    }
  };

  const handleSelectedFiltersChange = (vals) => {
    const unselectedOptions = selectedQuickFilters.filter((option) => !vals.includes(option));
    if (unselectedOptions.length > 0) {
      const unselectedKey = unselectedOptions[0].value;
      if (unselectedKey in taskFilters) {
        const { [unselectedKey]: _, ...remainingFilters } = taskFilters;
        updateTaskFilters(remainingFilters);
      }
      resetQuickFilters(unselectedOptions[0].filterName);
    }
    setSelectedQuickFilters(vals);
  };

  const handleFilterValueChange = (name, filter, values) => {
    setFilters({ ...filters, [name]: values });
    if (name === 'isCadenceTask') {
      updateTaskFilters({ ...taskFilters, cadence_task: values.value });
    } else {
      updateTaskFilters({
        ...taskFilters,
        [filter]: values.length === 0 ? undefined : values.map((item) => item.value),
      });
    }
  };

  const handleClearFilters = () => {
    const { taskCompletion } = filters;
    setFilters({ ...filtersDefaultValue, taskCompletion });
    setSelectedQuickFilters([]);

    const defaultFilters = getDefaultFilters();
    const { statuses } = taskFilters;
    if (JSON.stringify(filters) !== JSON.stringify(filtersDefaultValue))
      setTaskFilters({ ...defaultFilters, statuses });
  };

  return (
    <div className="tasks-filters-bar" style={{ paddingTop: userType ? '15px' : '8px' }}>
      <ListboxSelect
        size="medium"
        options={taskCompletionOptions}
        onChange={(e) => {
          trackTaskAction(e.label);
          setFilters({ ...filters, taskCompletion: e });
          if (e.label === 'Completed Tasks') {
            updateTableSettings({ sortProp: 'completed_at' });
          } else if (e.label === 'Incomplete Tasks') {
            updateTableSettings({ sortProp: 'current_due_date' });
          }
          updateTaskFilters({ ...taskFilters, statuses: e.value });
        }}
        value={filters.taskCompletion}
        emptyValue="Task Completion"
        label="Task Completion"
      />
      {selectedQuickFilters.some((filter) => filter.label === 'Action Type') && (
        <ListboxMultiselect
          emptyValue="Action Type"
          label="Action Type"
          options={taskActionTypeOptions}
          onChange={(vals) => handleFilterValueChange('actionTypes', 'action_types', vals)}
          values={filters.actionTypes}
        />
      )}
      {selectedQuickFilters.some((filter) => filter.label === 'Cadence Tasks') && (
        <ListboxSelect
          alwaysShowLabel={true}
          options={cadenceTaskOptions}
          onChange={(vals) => handleFilterValueChange('isCadenceTask', 'cadence_task', vals)}
          value={filters.isCadenceTask}
          label="Cadence Task"
          emptyValue="Cadence Task"
        />
      )}
      {(userType === 'OWNER' || referrer === 'contact') &&
        selectedQuickFilters.some((filter) => filter.label === 'Created By') && (
          <ListboxMultiselect
            emptyValue="Created By"
            label="Created By"
            searchable={true}
            options={usersOptions}
            onChange={(vals) => handleFilterValueChange('taskCreators', 'creator_user_ids', vals)}
            onSearch={(search) => {
              clearTimeout(searchQueryTimeout);
              searchQueryTimeout = setTimeout(() => {
                fetchUsers(search, 0);
              }, 500);
            }}
            scrollFetch={(search) => {
              fetchUsers(search, users.length);
            }}
            values={filters.taskCreators}
            showInfoBar={true}
          />
        )}
      {(userType === 'CREATOR_BUT_NOT_OWNER' || referrer === 'contact') &&
        selectedQuickFilters.some((filter) => filter.label === 'Assignee') && (
          <ListboxMultiselect
            emptyValue="Assignee"
            label="Assignee"
            searchable={true}
            options={usersOptions}
            onChange={(vals) => handleFilterValueChange('assignees', 'owner_user_ids', vals)}
            onSearch={(search) => {
              clearTimeout(searchQueryTimeout);
              searchQueryTimeout = setTimeout(() => {
                fetchUsers(search, 0);
              }, 500);
            }}
            scrollFetch={(search) => {
              fetchUsers(search, users.length);
            }}
            values={filters.assignees}
            showInfoBar={true}
          />
        )}
      {!referrer && selectedQuickFilters.some((filter) => filter.label === 'Constituent Name') && (
        <ListboxMultiselect
          emptyValue="Constituent Name"
          label="Constituent Name"
          options={constituentOptions}
          onChange={(vals) => handleFilterValueChange('constituents', 'target_contact_ids', vals)}
          values={filters.constituents}
          searchable={true}
          showInfoBar={true}
        />
      )}
      <ListboxMultiselect
        buttonClassName={'add-filter-btn'}
        emptyValue="+ Add Filter"
        alwaysShowLabel={true}
        size="compact"
        options={taskQuickFilterOptions}
        onChange={handleSelectedFiltersChange}
        values={selectedQuickFilters}
        label="+ Add Filter"
      />
      {selectedQuickFilters.length > 0 && (
        <Button type="simple" onClick={handleClearFilters} title="ClearFilters" className="reset-button-margin">
          <span>
            <Icon icon={'clear'}></Icon>Clear Filters
          </span>
        </Button>
      )}
    </div>
  );
};

TaskQuickFilters.propTypes = {
  userType: PropTypes.string,
};

export default TaskQuickFilters;
