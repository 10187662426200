import { useIdentity } from 'base/identity-resolver';
import { useEffect, useState } from 'react';
import JourneysTable from '../components/index/table/journeys-table';
import JourneyIndexBanner from '../components/index/journey-index-banner';
import JourneysTableFilters from '../components/index/journeys-table-filters/journeys-table-filters';
import { fetchJourneysForTable } from '../utils/api-requests/journey-index';
import { deleteFavoriteJourney, createFavoriteJourney } from '../utils/api-requests/journey-index';
import JourneyPauseContainer from '../components/top-nav/journeys-pause-container';
import { createJourney } from '../utils/api-requests/modal-actions';
import EverTrue from 'app';
import JourneySideBarContainer from '../components/top-nav/journey-side-bar-container';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import { sortByKey } from '../utils/helpers/sort-by-key';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';

const JourneyIndexController = () => {
  const {
    selectedTab,
    setSelectedTab,
    TABS,
    onlyJourneysWithStepsDue,
    setOnlyJourneysWithStepsDue,
    selectedDueDate,
    setSelectedDueDate,
  } = useGlobalJourneyProvider();
  const { user } = useIdentity();
  const [journeys, setJourneys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewSideBar, setViewSideBar] = useState(false);
  const [activeSideBarConstituentsList, setActiveSideBarConstituentsList] = useState({});
  const [selectedJourney, setSelectedJourney] = useState({});
  const [sortKey, setSortKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [redirected, setRedirected] = useState(selectedTab.id !== 1);

  useEffect(() => {
    const getAndSetJourneys = async () => {
      try {
        setLoading(true);
        const journeysListResponse = await fetchJourneysForTable(selectedTab.id, selectedDueDate, user.id);

        if (selectedTab.id === 1 && !journeysListResponse.length && !redirected) {
          setSelectedTab(TABS[1]);
          setRedirected(true); // Ensure the redirect only happens once on mount
        } else {
          setJourneys(journeysListResponse);
        }

        if (onlyJourneysWithStepsDue) {
          const filtered = journeysListResponse.filter(({ total_steps_due_today = {} }) => total_steps_due_today > 0);
          setJourneys(filtered);
        } else {
          setJourneys(journeysListResponse);
        }
      } finally {
        setLoading(false);
      }
    };
    getAndSetJourneys();
  }, [user.id, selectedDueDate, selectedTab, setSelectedTab, TABS, onlyJourneysWithStepsDue, redirected]);

  const updateTableState = (action = '', journey = {}) => {
    const journeysCopy = [...journeys];
    const { id: journeyId } = journey;
    const indexToUpdate = journeys.findIndex((j) => j.id === journeyId);

    switch (action) {
      case 'update':
        journeysCopy[indexToUpdate] = journey;
        break;
      case 'remove':
        journeysCopy.splice(indexToUpdate, 1);
        break;
      default:
        break;
    }
    setJourneys(journeysCopy);
  };

  const toggleFavorite = async (journey = {}) => {
    const { id: journeyId, favorite } = journey;
    const { id: favoriteId = null } = favorite || {};
    // if the journey has a favorite already
    if (favoriteId) {
      // remove it
      await deleteFavoriteJourney(favoriteId);
      trackJourneysAction('unfavorite_cadence');
      journey.favorite = null;
      // if we are only showing favorites
      if (selectedTab.id === 1) {
        // remove the item from the table
        updateTableState('remove', journey);
      } else {
        // else just grey out the star
        updateTableState('update', journey);
      }
    } else {
      // create a favorite
      const newJourneyFavorite = await createFavoriteJourney(journeyId);
      trackJourneysAction('favorite_cadence');
      journey.favorite = newJourneyFavorite;
      updateTableState('update', journey);
    }
  };

  const handleCreateJourney = async (payload) => {
    const journey = await createJourney(payload, journeys.length + 1);
    EverTrue.Navigator(`/cadences/${journey.id}`, true);
  };

  const handleOpenSideBar = (journey) => {
    const { contact_journeys } = journey;
    if (!!contact_journeys.length) {
      // map array into object where ID is key, name is value
      setActiveSideBarConstituentsList(
        contact_journeys.reduce((contactsObject, contactJourney) => {
          if (!contactJourney) return contactsObject;

          const { contact } = contactJourney;
          if (contact && contact.contact_attributes) {
            const {
              contact_attributes: { name_full, name_first },
            } = contact;
            contactsObject[contact.id] = name_full || name_first;
          }
          return contactsObject;
        }, {})
      );
    }
    setSelectedJourney(journey);
    setViewSideBar(true);
  };

  const handleCloseSideBar = () => {
    setActiveSideBarConstituentsList({});
    setSelectedJourney({});
    setViewSideBar(false);
  };

  const handleTab = (tabId) => {
    const tab = TABS.find((t) => t.id === tabId);
    if (tab) setSelectedTab(tab);
  };

  return (
    <div>
      <JourneyIndexBanner handleCreateJourney={handleCreateJourney} />
      <JourneysTableFilters
        tabs={TABS}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedDueDate={selectedDueDate}
        setSelectedDueDate={setSelectedDueDate}
        onlyJourneysWithStepsDue={onlyJourneysWithStepsDue}
        setOnlyJourneysWithStepsDue={setOnlyJourneysWithStepsDue}
      />
      <JourneyPauseContainer />
      <JourneysTable
        journeys={sortByKey(journeys, sortKey, sortOrder)}
        loading={loading}
        toggleFavorite={toggleFavorite}
        handleOpenSideBar={handleOpenSideBar}
        selectedTab={selectedTab}
        onlyJourneysWithStepsDue={onlyJourneysWithStepsDue}
        handleTab={handleTab}
        sortKey={sortKey}
        sortOrder={sortOrder}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
      />
      <JourneySideBarContainer
        viewSideBar={viewSideBar}
        activeSideBarConstituentsList={activeSideBarConstituentsList}
        handleCloseSideBar={handleCloseSideBar}
        selectedJourney={selectedJourney}
      />
    </div>
  );
};

export default JourneyIndexController;
