import { Loading } from '@evertrue/et-components';
import { getDateString } from 'base/new-utils';
import { useEffect, useState } from 'react';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import SideBar from 'components/animations/side-bar';
import ContactSummary from 'apps/contact/components/contact-summary/contact-summary';
import JourneyDetailTable from 'apps/journeys/components/detail/journey-detail-table/journey-detail-table';
import JourneyDetailBanner from 'apps/journeys/components/detail/journey-detail-banner';
import JourneyPauseContainer from 'apps/journeys/components/top-nav/journeys-pause-container';
import JourneyDetailTableToggle from 'apps/journeys/components/detail/journey-detail-table/journey-detail-table-toggle';

const JourneyDetailController = () => {
  const { navigateFrom, setSelectedDueDate } = useGlobalJourneyProvider();
  const { journey, loadingJourney, sidebarIsOpen, selectedContact, setSelectedContact, closeSideBar } =
    useJourneyDetailProvider();
  const [tableView, setTableView] = useState('steps');

  useEffect(() => {
    if (navigateFrom) {
      const today = getDateString(); // get today's date in the format we need ("YYYY-M-D")
      const dueDate = navigateFrom?.current_due_date || today;
      if (dueDate > today) {
        setSelectedDueDate(dueDate);
      }
    }
  }, [navigateFrom, setSelectedDueDate]);

  return loadingJourney ? (
    <Loading />
  ) : (
    <>
      <JourneyDetailBanner journey={journey} />
      <JourneyPauseContainer />
      <JourneyDetailTableToggle tableView={tableView} setTableView={setTableView} />
      <JourneyDetailTable journey={journey} setTableView={setTableView} tableView={tableView} />
      <SideBar
        start={sidebarIsOpen}
        closeSideBar={closeSideBar}
        speed={1000}
        mountOnEnter
        unmountOnExit
        enableEnterAnimations
        enableExitAnimations
        enableAppearAnimations
        onExited={() => setSelectedContact(null)}
      >
        <ContactSummary contactFromGql={selectedContact} />
      </SideBar>
    </>
  );
};

export default JourneyDetailController;
