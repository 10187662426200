import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { LinkButton } from '@evertrue/et-components';
import { useFluxStore } from '@evertrue/et-flux';
import ListActivityStore from 'apps/lists/stores/list-activity-store';
import ListActivitySource from 'apps/lists/sources/list-activity-source';
import CommentTextarea from 'apps/profile/components/interactions/comment-textarea';
import Module from 'components/module';
import CreatedActivity from './activity-items/created-activity';
import CollaboratorActivity from './activity-items/collaborator-activity';
import ContactActivity from './activity-items/contact-activity';
import CommentActivity from './activity-items/comment-activity';
import { useIdentity } from 'base/identity-resolver';

const useListActivityStore = (listId) =>
  useFluxStore(ListActivityStore, () => ({
    feed: ListActivityStore.getActivity(listId),
    hasMoreToLoad: ListActivityStore.hasMoreToLoad(listId),
    paging: ListActivityStore.getPaging(listId),
  }));

const ListActivity = ({ list_id }) => {
  const [comment, setComment] = useState({});
  const { feed, hasMoreToLoad, paging } = useListActivityStore(list_id);
  const { user } = useIdentity();

  useEffect(() => {
    ListActivitySource.fetch(list_id);
  }, [list_id]);

  const handleSave = (comment) => {
    ListActivitySource.saveComment(list_id, comment);
    setComment({});
  };

  const handleDelete = (comment_id) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      ListActivitySource.deleteComment(list_id, comment_id);
    }
  };

  const handlePaginate = () => {
    ListActivitySource.fetch(list_id, paging);
  };

  const handleMention = (user) => {
    setComment({
      text: `<@user:${user.id}:${_.classify(user.name)}> `,
      mentions: [{ alias: _.classify(user.name), user_id: user.id, type: 'user' }],
    });
  };

  return (
    <Module
      className="list-activity--module"
      header={() => <div className="list-activity--header">List Activity</div>}
      noMargin
    >
      <div className="list-activity">
        <CommentTextarea
          className="list-activity--comment-box list-activity--comment-box--new"
          comment={comment}
          onSave={handleSave}
          onCancel={() => setComment({})}
          hideAvatar
        />

        <ul className="list-activity--feed">
          {feed.map((item) => {
            if (item.target === 'CREATED') {
              return <CreatedActivity key={item.id} item={item} currentUser={user.id} />;
            }
            if (item.target === 'COLLABORATOR') {
              return <CollaboratorActivity key={item.id} item={item} currentUser={user.id} onMention={handleMention} />;
            }
            if (item.target === 'CONTACT') {
              return <ContactActivity key={item.id} item={item} currentUser={user.id} />;
            }
            if (item.interaction_type === 'EverTrue Comment') {
              return (
                <CommentActivity
                  key={item.id}
                  item={item}
                  currentUser={user.id}
                  onDelete={handleDelete}
                  onUpdate={handleSave}
                />
              );
            }
            return null;
          })}
        </ul>

        {hasMoreToLoad && (
          <div className="list-activity--paging">
            <LinkButton title="view more list activities" onClick={handlePaginate}>
              View More
            </LinkButton>
          </div>
        )}
      </div>
    </Module>
  );
};

ListActivity.propTypes = {
  list_id: PropTypes.any.isRequired,
};

export default ListActivity;
