module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   UrlSource = require("apps/layout/sources/url-source")
   FilterSource = require("apps/filters/sources/filter-source")
   FilterStore = require("apps/filters/stores/filter-store")
   WindowStore = require("apps/layout/stores/window-store").default
   {div, ul, li, span} = ReactLibs.DOMFactories
   SegmentFilterRow = require("apps/filters/components/segment-filter-row")
   AndOrToggle = require("apps/filters/components/and-or-toggle").default
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)
   {getAllFocusable} = require("base/accessibility-utils")
   FocusLock = createFactory(require("react-focus-lock").default)

   createComponent "SegmentFilterDrawer",
      propTypes:
         visible: ReactLibs.PropTypes.bool
         operator: ReactLibs.PropTypes.string
         onApply: ReactLibs.PropTypes.func
         onDockChange: ReactLibs.PropTypes.func

      getInitialState: ->
         dock_to_footer: false
         drawer_height: undefined

      registerStores: ->
         @on FilterStore, ->
            operator: FilterStore.getOperator()
            filters: FilterStore.getCurrentFilters()
            active_filters: FilterStore.getActiveFilters()
            has_selected_filter: FilterStore.hasSelectedFilter()
            has_unapplied_filters: FilterStore.hasUnappliedFilters()
            can_or: FilterStore.canSelectOperator()

         @on WindowStore, ->
            window_height: WindowStore.getHeight()

      componentDidUpdate: (prevProps) ->
         @checkDocking()

         # focus on drawer for acessibility when it opens
         if (prevProps.visible != @props.visible) && @props.visible
            elements = getAllFocusable(this.drawer)
            elements[0]?.focus()

         if !@props.visible && prevProps.visible
            @setState
               dock_to_footer: false
               drawer_height: undefined

      checkDocking: ->
         if @props.visible
            header_height = 0
            drawer_height = @state.drawer_height || this.drawer.offsetHeight || 0
            offset = $(ReactDOM.findDOMNode(@)).offset().top
            footer = 50

            if (offset + header_height + drawer_height) >= @state.window_height - footer
               if !@state.dock_to_footer && @depracated_is_mounted
                  @setState {dock_to_footer: true, drawer_height: drawer_height}
                  @props.onDockChange?(true)
            else if @state.dock_to_footer && @depracated_is_mounted
               @setState {dock_to_footer: false, drawer_height: undefined}
               @props.onDockChange?(false)

      handleAddRow: ->
         if @state.has_selected_filter || @props.visible
            FilterSource.add()

      handleRemoveRow: ->
         @setState
            dock_to_footer: false
            drawer_height: undefined
         @props.onDockChange?(false)

      handleClear: ->
         FilterSource.clear()
         UrlSource.updateSegment()

         @setState
            dock_to_footer: false
            drawer_height: undefined

      handleOperatorChange: (key) ->
         FilterSource.updateOperator(key)

      handleApplyFilter: ->
         @props.onApply?(@state.filters)

      handleCancel: ->
         FilterSource.cancel()
         @props.onCancel?()

      validateFilter: (filter) ->
         check = false
         if filter.value?.parameter
            if filter.value.parameter.value.includes("is")
               check = true
            else
               check = _.omit(filter.value, "parameter")
         else
            check = filter.value
         filter.filter_id && _.isBoolean(check) || !_.isEmpty(check)

      # for filters with component_type = "date_operator" and "date_operator_v2" in filter-config
      validateRollingDateInput: (filter) ->
         check = true
         if filter.value?.in
            if filter.value.in.includes("null")
               check = false
         check
       

      setDrawerRef: (element) ->
         this.drawer = element

      render: ->
         filters = @state.filters
         valid_rolling_date = _.all filters, @validateRollingDateInput
         has_empty_filters = !_.all filters, @validateFilter
         can_apply = (!has_empty_filters || !@state.has_selected_filter) && @state.has_unapplied_filters && valid_rolling_date

         FocusLock disabled: !@props.visible,
            div
               ref: @setDrawerRef
               className: classNames "segment-filters--drawer",
                  "is-open": @props.visible,
                  "is-docked": @state.dock_to_footer && @props.visible

               div className: classNames("segment-filters--content", "is-single": filters.length == 1),
                  ul className: "segment-filters--content-list",
                     _.map filters, (data, index) =>
                        li
                           className: "segment-filters--content-list-row"
                           key: _.toNumber(data.filter_row_id)
                           if index != filters.length - 1
                              div className: classNames(
                                 "segment-filters--content-list-row-connector"
                                 "is-initial": index == 0
                              )
                           if filters.length > 1
                              if index >= 1
                                 AndOrToggle
                                    edit: index == 1 && @state.can_or
                                    value: if data.filter_id == "0.0" then "and" else @props.operator
                                    onChange: @handleOperatorChange
                              else
                                 span className: "segment-filter-rows--initial"

                           SegmentFilterRow
                              filter_row_id: _.toNumber(data.filter_row_id)
                              filter_id: data.filter_id
                              value: data.value
                              onRemove: @handleRemoveRow

               # Footer Add & Apply & Remove All
               div className: "segment-filters--footer",
                  div className: classNames("segment-filters--inside-actions", {"is-indented": filters.length > 1}),
                     Button
                        type: "secondary"
                        disabled: has_empty_filters
                        title: "Add another filter"
                        onClick: => @handleAddRow() unless has_empty_filters
                        "Add another filter"

                     if @state.has_selected_filter
                        Button
                           type: "simple"
                           onClick: @handleClear
                           className: classNames("segment-filters--clear")
                           title: "Clear all filters"
                           "Clear all filters"

                  div className: "segment-filters--apply",
                     Button
                        type: "simple"
                        onClick: @handleCancel
                        "Cancel"

                     Button
                        className: "segment-filters--apply-button"
                        disabled: !can_apply
                        onClick: => if can_apply then @handleApplyFilter()
                        "Apply Filters"

               div null, @props.children
