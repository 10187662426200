import { Button } from '@evertrue/et-components';
import { getMomentObjectInTimezone } from 'base/new-utils';
import { getTaskCountForToday } from 'apps/home/utils/api-requests/home-page';
import { useEffect, useState } from 'react';
import AddTaskButton from 'apps/tasks/components/create/add-task-button';
import EverTrue from 'app';
import HomepageCard from '../homepage-card';
import JourneyTaskCount from './journey-task-count';
import OtherTaskCount from './other-task-count';
import PropTypes from 'prop-types';
import TripsAndMeetings from './trips-meetings';

const ACTION_TYPE_FIELDS = {
  CALL: 'Call',
  EMAIL: 'Email',
  LINKEDIN: 'LinkedIn',
  RESEARCH: 'Research',
  PRINT_MAIL: 'Print Mail',
  TEXT: 'Text',
  THANK_VIEW: 'ThankView',
  OTHER: 'Other',
};

const TodayContent = ({ user = {} }) => {
  const [counts, setCounts] = useState([{}, {}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAndSetJourneyCounts = async () => {
      try {
        setLoading(true);
        const counts = await getTaskCountForToday();
        setCounts(counts);
      } catch (e) {
        console.error('Error fetching today data', e);
      } finally {
        setLoading(false);
      }
    };

    getAndSetJourneyCounts();
  }, [user.id]);

  const addTaskToCount = (task = {}) => {
    if (!task || !(typeof task === 'object')) return;

    const { action_type = '' } = task;
    const mapped = ACTION_TYPE_FIELDS[action_type] || 'Other';
    const taskCountCopy = [...counts];
    const [other, journey] = taskCountCopy;
    if (task?.journey_task_id) journey[mapped] += 1;
    else other[mapped] += 1;
    taskCountCopy['total'] += 1;

    setCounts(taskCountCopy);
  };

  const optionalStateManagementFunction = (type, args = {}) => {
    switch (type) {
      case 'addTask':
        addTaskToCount(args.task);
        break;
      default:
        break;
    }
  };

  return (
    <HomepageCard
      leftHeading={'Today'}
      rightHeading={getMomentObjectInTimezone().format('dddd, MMMM DD, YYYY')}
      body={
        <>
          <OtherTaskCount todayTaskCount={counts[0]} loadingTasksTodayCount={loading} />
          <div className="homepage-today-other-tasks">
            <div className="homepage-today-other-tasks--view">
              <Button
                onClick={() => {
                  EverTrue.track.set('home_screen', { type: 'view_task' });
                  EverTrue.UrlManager.navigate('/tasks', true, false);
                }}
              >
                View Tasks
              </Button>
            </div>
            <div className="homepage-today-other-tasks--add">
              <AddTaskButton
                buttonType={'secondary'}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            </div>
          </div>
          <JourneyTaskCount todayJourneyCount={counts[1]} loadingJourneysTodayCount={loading} />
          <div className="homepage-today-cadence-steps--view">
            <Button
              onClick={() => {
                EverTrue.track.set('home_screen', { type: 'cadences' });
                EverTrue.UrlManager.navigate('/cadences', true, false);
              }}
            >
              View Cadences
            </Button>
          </div>
          <hr></hr>
          <TripsAndMeetings />
        </>
      }
    />
  );
};
TodayContent.propTypes = {
  user: PropTypes.object,
};

export default TodayContent;
