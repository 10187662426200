import { PropTypes } from 'base/react-libs';
import { Button, Loading } from '@evertrue/et-components';

const CadenceCategoryList = ({
  selectedCadenceCategory,
  loadingCategories,
  cadenceCategories,
  handleCadenceCategoryChange,
  handleShowAllCadence,
}) => {
  return (
    <>
      <div style={{ borderRight: '1px solid #f6f6f6' }}>
        <div style={{ fontWeight: '500', padding: '5px' }}>Category</div>
        {loadingCategories ? (
          <Loading spinner_size="small" position="center" />
        ) : (
          <ul className="cadence-category-list">
            {cadenceCategories &&
              cadenceCategories.map((category, index) => {
                return (
                  <li
                    className={`cadence-group-list-item ${
                      category.value === selectedCadenceCategory.value ? 'active' : ''
                    }`}
                    key={category.value}
                    onClick={() => {
                      handleCadenceCategoryChange(category);
                    }}
                  >
                    {category.label}
                  </li>
                );
              })}
          </ul>
        )}
        <hr style={{ margin: '10px 0px' }} />
        <Button className="all-cadences-btn" type="simple" onClick={handleShowAllCadence}>
          All Cadences
        </Button>
      </div>
    </>
  );
};

CadenceCategoryList.propTypes = {
  selectedCadenceCategory: PropTypes.object,
  loadingCategories: PropTypes.bool,
  cadenceCategories: PropTypes.array,
  handleCadenceCategoryChange: PropTypes.func,
  handleShowAllCadence: PropTypes.func,
};

export default CadenceCategoryList;
