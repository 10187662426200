import { useContext } from "react";
import _ from "underscore";
import Api from "entities/helpers/api";
import EverTrue from "app";
import PropTypes from "prop-types";
import { Icon, ActionsMenu, Modal } from "@evertrue/et-components";
import AddProspectsToTeamModal from "apps/portfolio-performance/components/add-prospects-to-team-modal";
import { useGate } from "components/is-gated";
import AddSolicitorGoalsModal from "apps/portfolio-performance/components/add-solicitor-goals-modal";
import RemoveSolicitorModal from "apps/portfolio-performance/components/remove-solicitor-modal";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const SolicitorActionMenu = ({ solicitor, user, refreshData }) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const has_match = !_.isEmpty(user);
  const [is_everhub_gate_enabled] = useGate("everhub");

  return (
    <ActionsMenu
      wrapperClassName="team-mgmt-row--action-menu"
      trigger={({ is_open }) => (
        <Icon title="Open solicitor action menu" icon="more-horiz" className="team-mgmt-row--actions-icon" />
      )}
      options={[
        {
          id: 1,
          icon: "work",
          label: "Go to Portfolio",
          onClick: () => {
            // load teams for solicitor
            Api.VOLUNTEER.MEMBERSHIP.get({
              urlArgs: {
                contact_id: solicitor.id,
                type: "solicitor",
              },
              params: { pool_type: assignmentType },
            })
              .then((resp) => {
                const team_id = resp[0] ? resp[0].pool_id : null;
                EverTrue.UrlManager.navigate(
                  `/portfolio-performance/portfolios/${team_id}/solicitor/${solicitor.id}`,
                  true
                );
              })
              .catch(() => {
                EverTrue.UrlManager.navigate(`/portfolio-performance/portfolios/`, true);
              });
          },
        },
        {
          id: 2,
          icon: "clipboard-text",
          label: "Assign Prospects",
           
          mountOnClick: ({ is_open, close }) => (
            <Modal isOpen={is_open} closeModal={close}>
              <AddProspectsToTeamModal close={close} solicitor={solicitor} refreshData={refreshData} />
            </Modal>
          ),
        },
        ...(has_match && is_everhub_gate_enabled
          ? [
            {
              id: 3,
              icon: "goals",
              label: "Solicitor Goals",
               
              mountOnClick: ({ is_open, close }) => (
                <Modal isOpen={is_open} closeModal={close}>
                  <AddSolicitorGoalsModal close={close} contact={solicitor} />
                </Modal>
              ),
            },
          ]
          : []),
        {
          id: 4,
          icon: "minus-circle",
          label: "Remove",
           
          mountOnClick: ({ is_open, close }) => (
            <Modal isOpen={is_open} closeModal={close}>
              <RemoveSolicitorModal close={close} solicitor={solicitor} refreshData={refreshData} />
            </Modal>
          ),
        },
      ]}
    />
  );
};

SolicitorActionMenu.propTypes = {
  user: PropTypes.object,
  solicitor: PropTypes.object,
  refreshData: PropTypes.func,
};

SolicitorActionMenu.defaultProps = {
  user: {},
  solicitor: {},
};

export default SolicitorActionMenu;
