import PropTypes from 'prop-types';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { bulkReassignTask } from 'apps/journeys/utils/api-requests/modal-actions';
import ErrorLogger from 'entities/helpers/error-log-helper';
import AddUser from 'apps/search/components/add-user';
import { useState } from 'react';
import { trackActionOnTask } from 'apps/journeys/utils/helpers/mixpanel';

const BulkTaskReassignModal = ({ isOpen, onClose, allSelectedTasks, optionalStateManagementFunction = () => {} }) => {
  const [assignee, setAssignee] = useState(null);

  const filterAllSelectedTask = allSelectedTasks.filter(
    (taskObj) => !taskObj.contact_journey_id && !taskObj.journey_task_id
  );

  const handleBulkReassign = async () => {
    try {
      const taskIds = filterAllSelectedTask.map((taskObj) => taskObj.taskId || taskObj.id);
      const payload = {
        task_ids: taskIds,
      };
      await bulkReassignTask(assignee.value, payload);
      trackActionOnTask('bulk_task_reassign');
      onClose();
    } catch (error) {
      ErrorLogger.error('Error occurred during bulk reassign', {
        extra: { error, page_name: 'BulkReassignModal' },
        tags: { taskIds: allSelectedTasks.map((taskObj) => taskObj.taskId) },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Reassign Tasks"
        closeModal={onClose}
        submitLabel={'Save'}
        onSubmit={handleBulkReassign}
        disableSubmit={assignee === null}
      />
      <ModalBody scrollable={false}>
        <div>
          <span className="task-reassign-label">{filterAllSelectedTask?.length} Non-Cadence Tasks Selected</span>
          {allSelectedTasks.length !== filterAllSelectedTask.length ? (
            <div className="bulk-tasks-ressign--message">Cadence steps will not be reassigned.</div>
          ) : (
            <div className="bulk-tasks-ressign--message">The assignee will be updated for all selected tasks.</div>
          )}
          <span className="task-reassign-label">Update Assignee</span>
          <AddUser
            handleOnChange={(value) => {
              setAssignee(value);
            }}
            value={assignee}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

BulkTaskReassignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allSelectedTasks: PropTypes.array,
  optionalStateManagementFunction: PropTypes.func,
};

export default BulkTaskReassignModal;
