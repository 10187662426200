import { Icon } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";

const DIALER_OPTIONS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "*",
  "0",
  "#",
  "dialerButton",
  "blank",
  "backspace",
];

const DialerNumPad = ({
  handleNumPadPress,
  phoneNumber,
  extension,
  contactId,
  onCallDisconnect,
  DialerButton,
  ...callDetails
}) => {
  return (
    <div className="dialer--grid-container">
      {DIALER_OPTIONS.map((option) => {
        switch (option) {
          case "dialerButton":
            return (
              <div className="dialer--grid-item" key={option}>
                <DialerButton
                  phoneNumber={phoneNumber}
                  extension={extension}
                  contactId={contactId}
                  onCallDisconnect={() => onCallDisconnect(callDetails)}
                />
              </div>
            );
          case "blank":
            return <div className="dialer--grid-item" key={option} />;
          case "backspace":
            return (
              <div key={option} className="dialer--grid-item">
                <Icon icon="backspace" iconKit="gt2" onClick={() => handleNumPadPress(null, true)} />
              </div>
            );
          default:
            return (
              <div className="dialer--grid-item" key={option} onClick={() => handleNumPadPress(option, true)}>
                {option}
              </div>
            );
        }
      })}
    </div>
  );
};

DialerNumPad.propTypes = {
  handleNumPadPress: PropTypes.func,
  phoneNumber: PropTypes.string,
  extension: PropTypes.string,
  contactId: PropTypes.number,
  onCallDisconnect: PropTypes.func,
  DialerButton: PropTypes.any,
};

export default DialerNumPad;
