import ProfileEmailerController from "apps/settings/email-settings/profile-emailer-controller";
import CallSettings from "apps/settings/phone-settings/phone-settings-form";

const OutreachSettingsController = () => {
  return (
    <>
      <ProfileEmailerController />
      <CallSettings />
    </>
  );
};

export default OutreachSettingsController;