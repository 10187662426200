const REACTIONS = [
  {
    label: 'Facebook Pages',
    items: [
      { value: 'comment', label: 'Comment', icon: '/images/fb-reactions-comment.png' },
      { value: 'like', label: 'Like', icon: '/images/fb-reactions-like.png' },
      { value: 'love', label: 'Love', icon: '/images/fb-reactions-love.png' },
      { value: 'wow', label: 'Wow', icon: '/images/fb-reactions-wow.png' },
      { value: 'sad', label: 'Sad', icon: '/images/fb-reactions-sad.png' },
      { value: 'haha', label: 'Haha', icon: '/images/fb-reactions-haha.png' },
      { value: 'angry', label: 'Angry', icon: '/images/fb-reactions-angry.png' },
      { value: 'thankful', label: 'Thankful', icon: '/images/fb-reactions-thankful.png' },
    ],
  },
];

const EVENT_ACTIONS = [
  {
    label: 'Facebook Events',
    items: [
      { value: 'attending', label: 'Going', icon: '/images/event-engagement-attending.png' },
      { value: 'unsure', label: 'Interested', icon: '/images/event-engagement-maybe.png' },
      { value: 'not_replied', label: 'Invited', icon: '/images/event-engagement-invited.png' },
      { value: 'declined', label: "Can't Go", icon: '/images/event-engagement-declined.png' },
    ],
  },
];

const EngagementTypesUtils = {
  getAll() {
    return REACTIONS.concat(EVENT_ACTIONS);
  },

  getReactions() {
    return REACTIONS;
  },

  getReactionValues() {
    return REACTIONS[0].items;
  },

  getEventActions() {
    return EVENT_ACTIONS;
  },

  getEventActionValues() {
    return EVENT_ACTIONS[0].items;
  },
};

export default EngagementTypesUtils;
