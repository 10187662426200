import PropTypes from 'prop-types';
import OutreachCard from './outreach-card';

const OutreachDraftCard = ({ draft, selectedDrafts, setSelectedDrafts, discard, handleOpenDraft }) => {
  return (
    <OutreachCard
      onClick={handleOpenDraft}
      outreachEmail={draft}
      selectedOutreaches={selectedDrafts}
      setSelectedOutreaches={setSelectedDrafts}
      outreachType="Draft"
      discard={discard}
    />
  );
};

OutreachDraftCard.propTypes = {
  draft: PropTypes.object.isRequired,
  setSelectedDrafts: PropTypes.func,
  selectedDrafts: PropTypes.array,
  discard: PropTypes.func,
  handleOpenDraft: PropTypes.func,
};

export default OutreachDraftCard;
