module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   EverTrue = require("app")
   {createFactory} = require("base/new-utils")
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   Decorator = require("clientDecorator")
   Avatar = require("components/elements/avatar")
   {div, strong, span} = ReactLibs.DOMFactories
   Link = createFactory(require("@evertrue/et-components").Link)

   ReactLibs.createClass
      displayName:  "ContactCard"
      propTypes:
         avatar: ReactLibs.PropTypes.func
         contact: (props, propName, componentName) ->
            EverTrue.PropTypes.contactObject(props.contact)
         name: ReactLibs.PropTypes.func
         link: ReactLibs.PropTypes.string
         align: ReactLibs.PropTypes.string
         avatarSize: ReactLibs.PropTypes.number
         showClassYear: ReactLibs.PropTypes.bool
         showRemoteId: ReactLibs.PropTypes.bool
         showNameLink: ReactLibs.PropTypes.bool
         referrer: ReactLibs.PropTypes.string
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onClick: ReactLibs.PropTypes.func
         isStacked: ReactLibs.PropTypes.bool
         target: ReactLibs.PropTypes.oneOf(["_blank", "_self"])

      getDefaultProps: ->
         showClassYear: true
         showNameLink: true
         showRemoteId: false
         includeMiddle: false
         avatarSize: 40
         align: "middle"
         target: "_self"

      renderLink: (component, name) ->
         link = @props.link || "/contact/#{@props.contact?.id}"

         if !@props.showNameLink || !@props.contact?.id then component
         else Link href: link, target: @props.target, title: "#{name}'s Profile", "data-refer": @props.referrer, component

      renderNameFromGql: (contact) -> 
         return contact?.contact_attributes?.name_full

      render: ->
         # contact.name and contact.avatar may be on the contact object if the "Contact"
         # came from somewhere besides the contacts api such as graphql
         contact = @props.contact || {}
         if @props.includeMiddle
            formatted_name = Decorator.Contacts.getDetailedName(contact, {include_middle: true}) || contact.name || @renderNameFromGql(contact)
         else
            formatted_name = Decorator.Contacts.getDetailedName(contact) || contact.name || @renderNameFromGql(contact)

         is_deceased = contact.properties?.deceased?.value
         avatar = Decorator.Profile.getAvatar(contact, contact.lids) || contact.avatar

         div
            className: classNames("new-contact-card", @props.className)
            onClick: @props.onClick
            onMouseOver: @props.onMouseOver
            onMouseOut: @props.onMouseOut

            div className: classNames("new-contact-card--body", "is-stacked" : @props.isStacked),
               if @props.avatar && _.isFunction(@props.avatar)
                  @props.avatar(contact.avatar, @props.avatarSize, "new-contact-card--avatar fs-exclude-data")
               else
                  @renderLink(
                     Avatar
                        className: "new-contact-card--avatar fs-exclude-data"
                        name: formatted_name
                        url: avatar
                        width: @props.avatarSize,
                     formatted_name
                  )
               div
                  className: "new-contact-card--content"
                  style:
                     width: "calc(100% - #{@props.avatarSize}px)"
                     verticalAlign: @props.align

                  div className: classNames("new-contact-card--name", "is-deceased": is_deceased && !@props.name),
                     if @props.name then @props.name(formatted_name, @renderLink(strong(className: "fs-exclude-data", formatted_name)))
                     else @renderLink(strong(className: "fs-exclude-data", formatted_name), formatted_name)

                     if is_deceased && !@props.name
                        span className: "new-contact-card--deceased fs-exclude-data", "Deceased"
                  if @props.showClassYear
                     EllipsisOverflow className: "new-contact-card--class fs-exclude-data",
                        Decorator.Profile.getRoleWithYear(contact)

                  if @props.showRemoteId
                     remote_id = _.find(contact.identities, {type: "remote_id"})?.value
                     if remote_id
                        "Remote ID: #{remote_id}"



                  if @props.children
                     div className: "new-contact-card--children",
                        @props.children
