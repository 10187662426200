import { Formik } from 'formik';
import { PropTypes } from 'base/react-libs';
import { UserSettingsSchema } from 'apps/home/utils/schemas/user-settings-schema';
import { Checkbox, NumericInput } from '@evertrue/et-components';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import FormField from 'components/forms/form-field';

const RecommendedActionUserSettingsModal = ({
  isOpen = () => {},
  onClose = () => {},
  initialSettings = {},
  submitUserSettings = () => {},
}) => {
  const onSubmit = async (values) => {
    await submitUserSettings(values);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="medium">
      <Formik
        initialValues={initialSettings}
        validationSchema={UserSettingsSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, dirty, isValid }) => {
          return (
            <>
              <ModalHeaderWithActions
                title="Recommended Actions Settings"
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid || !dirty}
              />
              <ModalBody scrollable={false}>
                <div className="recommendation-modal-subtitle">
                  Select which constituents to feature in the Recommended Actions section
                </div>
                <Checkbox
                  label="Made a recent gift"
                  value="enableGiftSection"
                  checked={!!values.enableGiftSection}
                  onChange={(v) => setFieldValue('enableGiftSection', !!v)}
                >
                  Made a recent gift
                </Checkbox>
                <div style={{ paddingLeft: '31px' }}>
                  <FormField
                    inlineLabel={false}
                    label="Minimum Gift Amount"
                    required={true}
                    disabledRequired={!values.enableGiftSection}
                    className="title-subtitle-400-14"
                  >
                    <NumericInput
                      disabled={!values.enableGiftSection}
                      className="recommendation-modal-gift-amount-field"
                      integerLimit={10}
                      allowDecimal={false}
                      value={values.giftAmount}
                      placeholder={0}
                      maskType="currency"
                      onChange={handleChange('giftAmount')}
                      showIcon={true}
                    />
                    {errors.giftAmount && <div className="formik-error-message">{errors.giftAmount}</div>}
                  </FormField>
                  <FormField
                    inlineLabel={false}
                    label="In the last X days"
                    required={true}
                    disabledRequired={!values.enableGiftSection}
                    className="title-subtitle-400-14"
                  >
                    <NumericInput
                      disabled={!values.enableGiftSection}
                      className="recommendation-modal-gift-amount-field"
                      integerLimit={10}
                      allowDecimal={false}
                      value={values.daysSinceLastGift}
                      placeholder={1}
                      allowZero={false}
                      maskType="number"
                      onChange={handleChange('daysSinceLastGift')}
                      showIcon={true}
                    />
                    {errors.daysSinceLastGift && <div className="formik-error-message">{errors.daysSinceLastGift}</div>}
                    <div className="journey-modal--alert">
                      {`Your assigned constituents who make a gift amount of at least $${values.giftAmount || 0} 
                        in the last ${values.daysSinceLastGift || 1} 
                        ${(values.daysSinceLastGift || 1) === 1 ? 'day' : 'days'} 
                        will appear in the Recommended Actions section.`}
                    </div>
                  </FormField>
                </div>
                <Checkbox
                  label="High Donor Potential Score"
                  value="enableDPS"
                  checked={!!values.enableDPS}
                  onChange={(v) => setFieldValue('enableDPS', !!v)}
                >
                  High Donor Potential Score ({'>='}70) without recent interaction
                </Checkbox>
                <div style={{ paddingLeft: '31px' }}>
                  <FormField
                    inlineLabel={false}
                    label="Number of months since last recorded interaction"
                    required={true}
                    disabledRequired={!values.enableDPS}
                    className="title-subtitle-400-14"
                    disabled={!values.enableDPS}
                  >
                    <NumericInput
                      className="recommendation-modal-gift-amount-field"
                      integerLimit={10}
                      allowDecimal={false}
                      value={values.monthsSinceLastInteraction}
                      placeholder="X Months"
                      maskType="number"
                      onChange={handleChange('monthsSinceLastInteraction')}
                      showIcon={true}
                      disabled={!values.enableDPS}
                    />
                    {errors.monthsSinceLastInteraction && (
                      <div className="formik-error-message">{errors.monthsSinceLastInteraction}</div>
                    )}
                    <div className="journey-modal--alert">
                      {`Any assigned constituents with a Donor Potential Score greater than 70 with whom you have
                              not recorded an interaction in the last ${values.monthsSinceLastInteraction || 0} 
                              ${values.monthsSinceLastInteraction === 1 ? 'month' : 'months'} 
                              will appear in the Recommended Actions section. Learn more about the DPS `}
                      <a 
                        href="https://help.evertrue.com/t/g9h9l4h/all-about-donor-potential-score" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        here
                      </a>.
                    </div>
                  </FormField>
                </div>
                <Checkbox
                  className="recommended-action-user-settings-form-input"
                  label="Scheduled meeting without contact report logged in the last 45 days"
                  value="enableTripMeeting"
                  checked={!!values.enableTripMeeting}
                  onChange={(v) => setFieldValue('enableTripMeeting', !!v)}
                >
                  Scheduled meeting without contact report logged in the last 45 days
                </Checkbox>
                <Checkbox
                  className="recommended-action-user-settings-form-input"
                  label="Career Moves in past 3 months"
                  value="enableCareerMovesSection"
                  checked={!!values.enableCareerMovesSection}
                  onChange={(v) => setFieldValue('enableCareerMovesSection', !!v)}
                >
                  Career Moves in past 3 months
                </Checkbox>
                <Checkbox
                  className="recommended-action-user-settings-form-input"
                  label="Upcoming gift anniversary in the next 30 days"
                  value="enableAnniversarySection"
                  checked={!!values.enableAnniversarySection}
                  onChange={(v) => setFieldValue('enableAnniversarySection', !!v)}
                >
                  Upcoming gift anniversary in the next 30 days
                </Checkbox>
              </ModalBody>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

RecommendedActionUserSettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitUserSettings: PropTypes.func,
  initialSettings: PropTypes.object,
};

export default RecommendedActionUserSettingsModal;
