import PropTypes from 'prop-types';
import EverTrue from 'app';
import ShowHideAnimate from 'components/show-hide-animate';
import { useOpenClose } from '@evertrue/et-components';

const ActivityCardGroup = ({ items = [], component: ItemComponent, className }) => {
  const [isOpen, openGroup, closeGroup] = useOpenClose();

  if (!items?.length) return null;

  const iconClass = EverTrue.classSet('fa fa-fw', 'activity-card-group--toggle', {
    'fa-angle-down': !isOpen,
    'fa-angle-up': isOpen,
  });

  const handleToggle = () => {
    isOpen ? closeGroup() : openGroup();
  };

  return (
    <div
      className={EverTrue.classSet('activity-card-group', className, {
        'is-closed': !isOpen && items.length > 1,
      })}
    >
      <div>
        <div className="activity-card-group--item">
          <ItemComponent {...items[0]} />
        </div>

        <ShowHideAnimate show={isOpen} speed={100}>
          {items.slice(1).map((item) => (
            <div className="activity-card-group--item" key={item.id}>
              <ItemComponent {...item} />
            </div>
          ))}
        </ShowHideAnimate>
      </div>

      {items.length > 1 && <i className={iconClass} onClick={handleToggle} />}
    </div>
  );
};

ActivityCardGroup.propTypes = {
  items: PropTypes.array,
  component: PropTypes.func,
  className: PropTypes.string,
};

export default ActivityCardGroup;
