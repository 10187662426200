module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   OrgSource = require("base/org/org-source").default
   SessionSource = require("base/session/session-source")
   {h3, div} = ReactLibs.DOMFactories
   OrgSelector = require("apps/login/components/org-selector")
   LoginHeader = require("apps/login/components/login-header").default
   Loading = require("components/elements/loading")

   createComponent "LoginSelectionController",
      getInitialState: ->
         current_org: EverTrue.store.org?.toJSON() || {}
         user: EverTrue.store.user?.toJSON() || {}
         affiliations: EverTrue.store.user?.getGivingTreeAffiliations() || []
         super_user:  EverTrue.store.user?.isSuperUser() || false
         changing_orgs: false

      componentDidMount: ->
         { super_user, affiliations, current_org } = @state

         if current_org.id
            # if we aready have an org, just go there
            @attemptOrgChange(current_org.id)

         else if !super_user and _.size(affiliations) == 1
            # if we're a regular user and there's only one org to go to, let's get on with it
            new_org = affiliations[0]?.organization
            if new_org?.id
               @attemptOrgChange(new_org.id)

      attemptOrgChange: (oid) ->
         @setState changing_orgs: true
         SessionSource.promise
            .renewSession(null, { oid })
            .then (session) =>
               @setState changing_orgs: false
               { type, oid, organization } = session
               if type != "SCOPED" or !oid
                  EverTrue.Alert.error "Problem Switching to Organization"
               else
                  # this is patching into the old org-store behaviors
                  OrgSource.select(organization)

            .catch (error = {}) =>
               @setState changing_orgs: false
               EverTrue.Alert.error error.message || "Problem Switching to Organization"

      handleSubmit: (org)->
         oid = org.id

         if oid
            @attemptOrgChange(oid)
         else
            EverTrue.Alert.info "Please select an organization"

      render: ->
         { changing_orgs } = @state

         div className: "login-selectors loading-container",
            LoginHeader()

            if changing_orgs then Loading()

            div className: "login-selectors--container",

               h3 null, "Welcome!"

               div className: "login-selectors--org loading-container",
                  OrgSelector
                     user: @state.user
                     onChange: @handleSubmit
