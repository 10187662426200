import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';
import { getMomentObjectInTimezone } from 'base/new-utils';
import ReactHtmlParser from 'react-html-parser';

const ThreadBody = ({ email, isLastEmail }) => {
  const [isExpanded, setIsExpanded] = useState(isLastEmail);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      <div onClick={toggleExpansion} className="thread-body-container">
        <div className="thread-detail-view--body">
          <div style={{ marginTop: '0px' }}>
            <div style={{ paddingBottom: '10px', fontSize: 'medium' }}>
              <Icon
                icon="email-received-outreach"
                className="email-thread-icon"
                iconKit="gt2"
                style={{ marginRight: '5px' }}
              />
              {email.email_recipients?.[0]?.email_address || 'Unknown Sender'}
            </div>
            <div className="outreach-card-recipient-name" style={{ marginBottom: '13px' }}>
              <span style={{ color: '#2C2C2C', fontWeight: '500' }}>To: </span>
              {email.email_recipients?.map((recipient) => recipient.email_address).join(', ') || 'Unknown Recipient'}
            </div>
            {isExpanded ? (
              <span>{ReactHtmlParser(email.body)}</span>
            ) : (
              <span>{email.parsed_body.slice(0, 130)}...</span>
            )}
          </div>
        </div>
        <div className="outreach-card-date-container" style={{ minWidth: 'max-content' }}>
          <p>{getMomentObjectInTimezone(email.sent_at * 1000)?.format('MMM D, YYYY [at] h:mm A')}</p>
        </div>
      </div>

      {!isLastEmail && <hr />}
    </div>
  );
};

ThreadBody.propTypes = {
  email: PropTypes.object,
  isLastEmail: PropTypes.bool,
};

export default ThreadBody;
