import Loading from "components/elements/loading";
import AiIcon from "../../../../../src/images/ai-icon.png";
import IconButton from "components/elements/icon-button";
import PropTypes from "prop-types";
import EverTrue from "app";

const AISummary = ({ isStreaming, summary, requestId, onRefresh }) => {
  const copySummaryToClipboard = () => {
    if (summary) {
      navigator.clipboard
        .writeText(summary)
        .then(() => {
          EverTrue.Alert.success("Summary copied to clipboard");
        })
        .catch((err) => {
          console.error(err);
          EverTrue.Alert.error("Failed to copy summary");
        });
    }
  };

  return (
    <div className="profile-ai-summary fs-exclude-data">
      <div className="profile-summary-pill">
        <img src={AiIcon} alt="AI Icon" className="profile-summary-ai-icon" />
        AI-generated Summary
      </div>
      <div className="profile-ai-summary-content">
        <div className="profile-ai-summary-text">
          {isStreaming && !summary ? (
            <div className="loader-div">
              <Loading spinner_size="small" position="center" />
            </div>
          ) : (
            <div>
              <p>{summary}</p>
              {!isStreaming && (
                <p>
                  <small>{`Request ID: ${requestId}`}</small>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="profile-ai-summary-actions">
          <span
            className={`profile-ai-summary-refresh ${isStreaming ? "disabled" : ""}`}
            onClick={!isStreaming ? onRefresh : null}
            role="button"
          >
            <IconButton icon="refresh" title="Regenerate" disabled={isStreaming} />
            Regenerate
          </span>
          <span
            className={`profile-ai-summary-copy ${isStreaming || !summary ? "disabled" : ""}`}
            onClick={!isStreaming && summary ? copySummaryToClipboard : null}
            role="button"
          >
            <IconButton icon="clipboard-text" title="Copy" disabled={isStreaming} />
            Copy
          </span>
        </div>
      </div>
    </div>
  );
};

AISummary.propTypes = {
  isStreaming: PropTypes.bool,
  summary: PropTypes.string,
  requestId: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
};

export default AISummary;
