import PropTypes from 'prop-types';
import { Checkbox, AdvancedCombobox } from '@evertrue/et-components';
import EngagementTypesUtils from 'apps/profile/components/engagement-type-utils';
import { useGate } from 'components/is-gated';

const EngagementTypeDropdown = ({ value, onChange, placeholder, multiple = true }) => {
  const [eventsEnabled] = useGate('events');

  const formatItem = (item, isSelected) => (
    <div className="facebook-reactions-dropdown--item">
      <div>
        <Checkbox className="et--checkbox-small" checked={isSelected} value={item?.value} />
        <span className="facebook-reactions-dropdown--label">{item.label}</span>
      </div>
      <img className="facebook-reactions-dropdown--icon" src={item.icon} alt={item.label} />
    </div>
  );

  return (
    <div className="facebook-reactions-dropdown">
      <AdvancedCombobox
        options={eventsEnabled ? EngagementTypesUtils.getAll() : EngagementTypesUtils.getReactions()}
        value={value?.map((val) => Object.assign({}, val))}
        multiple={multiple}
        placeholder={placeholder || 'Select Options...'}
        onChange={onChange}
        formatItem={formatItem}
        searchable={true}
        allowChangeAll={true}
        grouped={true}
        allowUnselectAll={multiple}
      />
    </div>
  );
};

EngagementTypeDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
};

export default EngagementTypeDropdown;
