import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProfileSettings from './components/profile-settings';
import PhotoSettings from './components/photo-settings';
import EverTrue from 'app';
import _ from 'underscore';
import { useIdentity } from 'base/identity-resolver';
import { usePromiseResolver } from '@evertrue/promise-resolver';
import API from 'entities/helpers/api';
import Module from 'components/module';
import { Loading } from '@evertrue/et-components';
import EmptyStateMessage from 'components/lists/empty-state-message';

const ProfileSettingsController = ({ affiliation }) => {
  const { user } = useIdentity();

  const [userData, loading] = usePromiseResolver(
    () => affiliation && API.AUTH.PROFILE.get({ urlArgs: { affiliation_id: affiliation.id } }),
    [affiliation]
  );
  const [userInfo, setUserInfo] = useState(userData);

  useEffect(() => {
    setUserInfo(userData);
  }, [setUserInfo, userData]);

  const handleSubmit = (data) => {
    API.AUTH.PROFILE.put({
      urlArgs: { affiliation_id: affiliation.id },
      data: _.jsonStringify(_.compactObject(data)),
    })
      .then((resp) => {
        EverTrue.Alert.success('Profile updated successfully');
        if (resp.email !== userData.current_email) {
          EverTrue.Alert.success('Confirmation email has been sent');
        }
        setUserInfo(resp);
      })
      .catch(() => {
        EverTrue.Alert.error('There was a problem updating your profile');
      });
  };

  return loading ? (
    <Loading />
  ) : userInfo ? (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="module-photo-information">
        <Module header="Photo">
          <PhotoSettings
            org_id={affiliation.organization.id}
            affiliation_id={affiliation.id}
            contact_id={user.id}
            user_photo={userData.user_profile_picture_url || user.avatar}
          />
        </Module>
      </div>
      <div className="module-personal-information">
        <Module header="Account Info">
          <ProfileSettings
            {...{ userInfo }}
            onSubmit={handleSubmit}
            emailDisclaimer={userInfo.email !== userInfo.current_email}
          />
        </Module>
      </div>
    </div>
  ) : (
    <EmptyStateMessage
      className="page-error-boundary"
      actionLabel="Contact EverTrue Support"
      size="page"
      icon="alert-circle"
      text="You must be affiliated with this organization to use this feature."
    />
  );
};

ProfileSettingsController.propTypes = {
  affiliation: PropTypes.object,
};
export default ProfileSettingsController;
