import { Button, Icon } from '@evertrue/et-components';
import EverTrue from 'app';
import { PropTypes } from 'base/react-libs';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';

const ActiveJourney = ({ journey = {}, contactName = '', style }) => {
  const { journey_name, journey_id, day_number, step_number, owner, current_due_date } = journey;
  const { setNavigateFrom } = useGlobalJourneyProvider();
  const activeCadenceObj = { journey_name, step_number, day_number, current_due_date, owner, contact_name: contactName };
  const ownerName = owner ? owner.name : 'EverTrue';

  return (
    <div className="active-journey" style={style}>
      <Icon icon="cadences" iconKit="gt2" />
      <div>
        <Button
          type="simple"
          onClick={() => {
            setNavigateFrom(activeCadenceObj);
            EverTrue.Navigator(`/cadences/${journey_id}`, true);
          }}
        >
          {journey_name}
        </Button>
        <p>
          Step {step_number}: Day {day_number}
        </p>
        <p>{ownerName}</p>
      </div>
    </div>
  );
};

ActiveJourney.propTypes = {
  journey: PropTypes.object,
  contactName: PropTypes.string,
  style: PropTypes.object,
};

export default ActiveJourney;
