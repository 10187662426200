import { getDateInTimezone } from 'base/new-utils';
import * as Yup from 'yup';

export const PauseFormSchema = Yup.object()
  .shape({
    startDate: Yup.date()
      .min(new Date(new Date().setHours(0, 0, 0, 0)), 'You may not start a pause in the past')
      .required('Please select a Start Date'),
    endDate: Yup.date()
      .when('startDate', (startDate, schema) => {
        if (startDate) {
          const currentDay = getDateInTimezone(startDate);
          const currentDayPlusOne = new Date(currentDay);
          currentDayPlusOne.setDate(currentDay.getDate() + 1);
          return schema.min(currentDayPlusOne, 'Your pause must be at least one day long');
        } else {
          return schema;
        }
      })
      .required('Please select an End Date'),
  })
  .required('Start Date and End Date are both required');
