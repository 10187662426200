import PropTypes from 'prop-types';
import numeral from 'numeral';
import { isEmpty } from 'underscore';
import { LinkButton, Link } from '@evertrue/et-components';
import ContactFormatMixin from 'mixins/contact-format-mixin';
import LoadingMixin from 'mixins/loading-mixin';
import EverTrue from 'app';

const CompanyProfileCard = ({ matched = 0, link, data = {} }) => {
  const handleConstituentsClick = () => {
    EverTrue.UrlManager.clear({ silent: true });
    EverTrue.Navigator(`/contact?${link}`, true);
  };

  // This is all basically dead -- we are not fetching industries at the moment
  const industries = () => {
    return data?.industries?.map((industry) => (
      <Link
        href={`industry${ContactFormatMixin.industry_link(industry.name)}`}
        title={`industry ${industry.name}`}
        key={industry.name}
        data-refer="$last"
      >
        {industry.name}
      </Link>
    ));
  };

  const location = () => {
    const locations = data?.locations?.[0];
    const state = locations?.address?.state;
    let city = locations?.address?.city;
    if (city && state) {
      return `${city}, ${state}`;
    } else if (!city) {
      return state || '';
    }
  };

  const matchedText =
    matched === 1 ? '(1 Matched Constituent)' : `(${numeral(matched).format('0,0')} Matched Constituents)`;

  const url = data?.website_url;
  const websiteUrl = url && /^http/.test(url) ? url : `http://${url}`;
  
  return (
    <div className="company-header loading-container">
      {LoadingMixin.loading(isEmpty(data))}
      <div className="clearfix">
        <div className="company-header--name">
          <h3>{data.name}</h3>

          <div>
            <span>{industries()}</span>
            <span className="company-header--divide">
              {data.industries && data?.locations?.[0]?.address?.state ? ' | ' : ''}
            </span>
            <span>{location()}</span>
          </div>

          {matched ? (
            <div>
              <LinkButton
                className="company-header--matched"
                onClick={handleConstituentsClick}
                title="View matched constituent"
              >
                {matchedText}
              </LinkButton>
            </div>
          ) : null}
        </div>

        <div className="company-header--links">
          {url ? (
            <div className="block">
              <Link href={websiteUrl} title="company website" target="_blank" data-bypass="true">
                <span>Visit Website</span>
                <i className="fa fa-fw fa-info-circle" />
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CompanyProfileCard.propTypes = {
  matched: PropTypes.number,
  link: PropTypes.string,
  data: PropTypes.object,
};

export default CompanyProfileCard;
