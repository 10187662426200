import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@evertrue/et-components';
import { useRecorder } from './hooks/recorder-context';

const DeviceSelector = ({ devices, selectedDevice, onDeviceChange, label }) => {
  const { handleDeviceChange } = useRecorder();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectDevice = async (deviceId) => {
    const type = label.toLowerCase().includes('camera') ? 'video' : 'audio';
    await handleDeviceChange(deviceId, type);
    onDeviceChange(deviceId);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="device-selector" ref={dropdownRef}>
      <div className="device-selector-container">
        <button type="button" onClick={toggleDropdown} className="device-selector-trigger">
          <Icon icon={label.toLowerCase() === 'microphone' ? 'microphone-solid' : 'tv-video'} iconKit="tv" />
          <Icon icon="chevron-down" iconKit="tv" className={`dropdown-arrow ${isOpen ? 'open' : ''}`} />
        </button>
        {isOpen && (
          <ul className="device-selector-dropdown">
            {devices.map((device) => (
              <li
                key={device.deviceId}
                onClick={async () => {
                  await handleSelectDevice(device.deviceId);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSelectDevice(device.deviceId);
                  }
                }}
                role="button"
                tabIndex={0}
                className={device.deviceId === selectedDevice ? 'selected' : ''}
              >
                {device.label || `${label} ${devices.indexOf(device) + 1}`}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

DeviceSelector.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedDevice: PropTypes.string.isRequired,
  onDeviceChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default DeviceSelector;
