import { useIdentity } from 'base/identity-resolver';
import { useEffect } from 'react';
import { fetchStepsByJourneyId } from 'apps/journeys/utils/api-requests/journey-details';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import StepTableBody from './step-table-body';
import StepTableHeader from './step-table-header';
import Loading from 'components/elements/loading';

const StepTable = () => {
  const { user = {} } = useIdentity();
  const { selectedDueDate } = useGlobalJourneyProvider();
  const {
    journey = {},
    steps = [],
    setSteps = () => {},
    loadingSteps = false,
    setLoadingSteps = () => {},
    actionTypeFilters = [],
  } = useJourneyDetailProvider();

  // Fetch Steps by Journey ID
  useEffect(() => {
    const fetchStepsFromGql = async () => {
      try {
        setLoadingSteps(true);
        if (journey?.id && user?.id && selectedDueDate) {
          const response = await fetchStepsByJourneyId(journey?.id, user?.id, selectedDueDate, actionTypeFilters);
          setSteps(response);
        }
      } catch (error) {
        console.error('Error fetching steps', error);
      } finally {
        setLoadingSteps(false);
      }
    };
    if (journey && journey?.id) fetchStepsFromGql();
  }, [journey, user, selectedDueDate, actionTypeFilters, setLoadingSteps, setSteps]);

  return (
    <div className="journey-detail-table--container">
      {loadingSteps ? (
        <Loading overlay={false} />
      ) : (
        <>
          <StepTableHeader steps={steps} />
          <StepTableBody steps={steps} />
        </>
      )}
    </div>
  );
};

export default StepTable;
