import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'underscore';
import Decorator from 'clientDecorator';
import CommentListItem from 'apps/contact/components/profile/comments/comments-list-item';

const CommentActivity = ({ item, currentUser, onDelete, onUpdate }) => (
  <li className="list-activity--feed-item">
    <div className="list-activity--label">
      <strong
        className={classNames({
          'is-empty': _.isEmpty(item.creator),
          'text-removed-user': item.creator?.removed,
        })}
      >
        {Decorator.User.getName(item.creator || {}, currentUser) || ''}
      </strong>
      <span> added a note on {moment(item.created_at).format('MMM D')}</span>
    </div>
    <ul>
      <CommentListItem key={`comment-${item.id}`} comment={item} onDelete={onDelete} onUpdate={onUpdate} />
    </ul>
  </li>
);

CommentActivity.propTypes = {
  item: PropTypes.object.isRequired,
  currentUser: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CommentActivity;
