import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeaderCustom, Button } from "@evertrue/et-components";

const MissingInfoModal = ({ isOpen, close, handleSubmit, missingFields, title, secondaryButtonLabel, isSendEmail }) => {
  return (
    <>
      <Modal isOpen={isOpen} closeModal={close} closeOnOutsideClick={true} size="medium">
        <ModalHeaderCustom>
          <div style={{ fontSize: "20px", fontWeight: "400" }}>{title}</div>
          <div style={{ display: "flex" }}>
            <Button
              label={secondaryButtonLabel}
              onClick={() => {
                handleSubmit();
              }}
              type="secondary"
            >
              {secondaryButtonLabel}
            </Button>

            <Button label="Review" onClick={close}>
              Review
            </Button>
          </div>
        </ModalHeaderCustom>

        <ModalBody>
          <div style={{ marginTop: "20px", marginBottom: "24px" }}>
            {isSendEmail ? (
              <>The following fields are missing or not supported for the message you're trying to send:</>
            ) : (
              <>
                It looks like some of the Dynamic Email fields in your email don't match the available dynamic fields.
                This might result in blank fields for the recipient.
              </>
            )}
          </div>
          <div style={{ fontWeight: "bold" }}>Missing Fields or Unsupported Fields:</div>
          <div style={{ padding: "8px" }}>
            {missingFields.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

MissingInfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  missingFields: PropTypes.array.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  isSendEmail: PropTypes.bool,
};

export default MissingInfoModal;
