import PropTypes from 'prop-types';
import EmptyStateMessage from 'components/lists/empty-state-message';

const StepTableEmptyState = ({ hasFilters = false }) => {
  const generateEmptyStateMessage = () => {
    if (hasFilters) {
      return 'There are no Cadence Steps that meet the filtered criteria.';
    } else return 'This Cadence does not have any steps yet.';
  };

  return <EmptyStateMessage size="page" text={generateEmptyStateMessage()} />;
};

StepTableEmptyState.propTypes = {
  hasFilters: PropTypes.bool,
};

export default StepTableEmptyState;
