import { Icon } from '@evertrue/et-components';

export const getCounterIconComponent = (iconName) => {
  let icon = '';
  let iconKit = 'gt';

  //Checking for icon names for corresponding counters
  switch (iconName) {
    case 'Emails':
      icon = 'email';
      break;
    case 'Calls':
      icon = 'call';
      break;
    case 'Other':
      icon = 'other-tasks-cadences';
      iconKit = 'gt2';
      break;
    default:
      return iconName; //Handling regression scenario in case an invalid task name comes in the counter
  }

  return <Icon icon={icon} className="journeys-task-table--task-icon" iconKit={iconKit} />;
};

export const getStepIconComponent = (actionType) => {
  let iconName = '';
  let iconKit = 'gt';

  switch (actionType) {
    case 'EMAIL':
      iconName = 'email';
      break;
    case 'CALL':
      iconName = 'call';
      break;
    default:
      iconName = 'other-tasks-cadences';
      iconKit = 'gt2';
  }

  return <Icon icon={iconName} className="journeys-task-table--task-icon" iconKit={iconKit} />;
};
