import { useOpenClose } from '@evertrue/et-components';
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SignalVideoModalContext = createContext();

export const SignalVideoModalProvider = ({ children }) => {
  const [modalView, setModalView] = useState('recorder');
  const [isThankviewModalOpen, openThankviewModal, closeThankviewModal] = useOpenClose();

  const handleChangeModalView = (view) => {
    if (view === 'prev') {
      setModalView({ view: view.prev || 'library', prev: view.view });
    } else {
      setModalView({ view, prev: view.view || '' });
    }
  };

  const handleCloseThankviewModal = (onClose = () => {}) => {
    closeThankviewModal();
    onClose();
  };

  const handleOpenThankviewModal = (view) => {
    if (view) {
      handleChangeModalView(view);
    }
    openThankviewModal();
  };

  const value = {
    modalView,
    handleChangeModalView,
    isThankviewModalOpen,
    handleOpenThankviewModal,
    handleCloseThankviewModal,
  };

  return <SignalVideoModalContext.Provider value={value}>{children}</SignalVideoModalContext.Provider>;
};

export const useSignalVideoModalContext = () => {
  return useContext(SignalVideoModalContext);
};

SignalVideoModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useSignalVideoModalContext;
