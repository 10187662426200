import PropTypes from 'prop-types';
import Icon from 'components/elements/icon';
import { Link } from '@evertrue/et-components';
import ContactFormatMixin from 'mixins/contact-format-mixin';

const Career = ({ data }) => {
  const career = data || {};

  if (!(career.name || career.title || career.function || career.industry)) {
    return (
      <div className="career-template">
        <Icon icon="career" className="career-template--icon" />
        <div className="career-template--data is-empty">Not provided</div>
      </div>
    );
  }

  return (
    <div className="career-template">
      <Icon icon="career" className="career-template--icon" />

      <div className="career-template--data">
        <Link
          className="career-template--name fs-exclude-data"
          href={`/company${ContactFormatMixin.company_link(null, career)}`}
          title={career.name}
          data-refer="person"
        >
          {career.name}
        </Link>

        <div className="career-template--title">
          <span className="fs-exclude-data">{career.title}</span>
        </div>
        <div className="career-template--function">
          <span className="fs-exclude-data">{career.function}</span>
        </div>

        <div>
          <Link
            className="fs-exclude-data"
            href={`/industry${ContactFormatMixin.industry_link(career.industry)}`}
            title={career.industry}
            data-refer="person"
          >
            {career.industry}
          </Link>
        </div>
      </div>
    </div>
  );
};

Career.propTypes = {
  data: PropTypes.object,
};

export default Career;
