import EverTrue from 'app';
import BaseRouter from 'base/base-router';
import JourneyDetailController from './controllers/journey-detail-controller';
import JourneyIndexController from './controllers/journey-index-controller';
import IsFlaggedController from 'components/flags/is-flagged-controller';
import { JourneyDetailProvider } from './context/journey-detail-context';

class JourneysRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      'cadences(/)': 'index',
      'cadences/:id': 'journey',
    };
  }

  // ALL Journeys
  async index() {
    EverTrue.track.set('cadences', { type: 'left_nav' });
    EverTrue.execute('setBrowserTitle', 'Cadences');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <JourneyIndexController />
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
  // ONE Journey
  journey(id) {
    EverTrue.track.set('cadences', { type: 'left_nav' });
    EverTrue.execute('setBrowserTitle', 'Cadences');
    this.currentView = (
      <IsFlaggedController flag="signal">
        <JourneyDetailProvider journeyId={id}>
          <JourneyDetailController journeyId={id} />
        </JourneyDetailProvider>
      </IsFlaggedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

JourneysRouter.initClass();
export default JourneysRouter;
