import { PropTypes } from 'base/react-libs';
import { Button } from '@evertrue/et-components';
import { useState } from 'react';
import { getCounterIconComponent } from './helpers/icon-fetch';
import { useJourneyDetailProvider } from '../context/journey-detail-context';
import classNames from 'classnames';

const JourneyActionTypeFilterButton = ({ name, count }) => {
  const { actionTypes = [], actionTypeFilters, setActionTypeFilters } = useJourneyDetailProvider();

  const getFilterArray = () => {
    if (name === 'Other') return actionTypes;
    else {
      return [name.substring(0, name.length - 1).toUpperCase()];
    }
  };

  let checkSubset = (parentArray, subsetArray) => {
    if (!subsetArray?.length && !parentArray?.length) return false;
    return subsetArray.every((el) => {
      return parentArray.includes(el);
    });
  };
  const [toggle, setToggle] = useState(checkSubset(actionTypeFilters, getFilterArray()));

  const handleClick = () => {
    if (!toggle) {
      setActionTypeFilters([...actionTypeFilters, ...getFilterArray()]);
    } else {
      setActionTypeFilters(actionTypeFilters.filter((el) => !getFilterArray().includes(el)));
    }
    setToggle(!toggle);
  };

  return (
    <div className={classNames('task-counter-icon', { 'is-toggled': toggle })}>
      <Button
        type="simple"
        className={classNames('task-counter-icon-toggle', { 'is-toggled': toggle })}
        onClick={handleClick}
      >
        {getCounterIconComponent(name)}
        <strong>{count}</strong>
      </Button>
    </div>
  );
};

JourneyActionTypeFilterButton.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
};

export default JourneyActionTypeFilterButton;
