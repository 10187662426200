import _ from "underscore";
import EverTrue from "app";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import LocalApi from "entities/helpers/local-api";
import User from "entities/auth/user";
import Cookies from "entities/helpers/cookies-helper";

const _requireOrg = function requireOrg(org) {
  return this.require(_.isObject(org) || _.isUndefined(org), "org should be object or undefined");
};

export default createSource("OrgSource", {
  actions: {
    set: _requireOrg,
    changed: _requireOrg,
    logout: _requireOrg,
    newOrg: _requireOrg,
    loading: true,
    fetched: _requireOrg,
    fetchedAll(orgs) {
      return this.require(_.isArray(orgs), "orgs should be an array");
    },
    fetchedForUser(orgs) {
      return this.require(_.isArray(orgs), "orgs should be an array");
    },
  },

  setOrgData() {
    EverTrue.track.setOrgData(EverTrue.store.org, EverTrue.store.user);
    EverTrue.vent.trigger("org:initialized");
    EverTrue.UrlManager.unset("oid");
  },

  startOrgChange() {
    if (EverTrue.store.search != null) {
      EverTrue.store.search.clear({ silent: true });
    }
    EverTrue.store.push("facets", {});
    EverTrue.UrlManager.clear();
    if (typeof localStorage !== "undefined" && localStorage !== null) {
      localStorage.removeItem("backboneCache");
      localStorage.removeItem("ET_Launchpad_Team_ID");
      localStorage.removeItem("ET_Launchpad_Main_TeamID_Default");
      localStorage.removeItem("launchpad_solicitor_team");
      localStorage.removeItem("EvertrueProposalTableSettings");
      localStorage.removeItem("recent_filters");
      localStorage.removeItem("contact_table_settings");
      localStorage.removeItem("suggested_search_table_settings");
    }
  },

  api: {
    set(org) {
      this.saveToStore(org);
      this.setOrgData();
      this.actions.set(org);
      this.fetch(org.id);
    },

    change(org) {
      this.saveToStore(org);
      this.startOrgChange();
      this.actions.changed(org);
      this.fetch(org.id);
      EverTrue.vent.trigger("storeReset");
      EverTrue.execute("switchOrg");
    },

    // This doesn't need to call actions because enterApp gets called
    select(org) {
      this.saveToStore(org);
      this.setOrgData();
      EverTrue.execute("goToInitialPage");
    },

    reset(org) {
      this.actions.newOrg(org);
    },

    saveToStore(data) {
      Api.set({ oid: data.id });
      LocalApi.set({ oid: data.id })
      const org = new EverTrue.OrgClass(data);
      Cookies.set("gt_oid", org.get("id") || "");
      EverTrue.store.push("org", org, { cache: true });
    },

    clear() {
      EverTrue.store.org.clear();
      EverTrue.store.destroy("org");
      LocalApi.set({ oid: undefined });
      Api.set({ oid: undefined });
    },

    clearAll() {
      this.clear();
      this.actions.logout();
    },

    fetch(id) {
      if (EverTrue.store.session.get("type") === "SCOPED") {
        const org = EverTrue.store.session.get("organization");
        _.defer(() => this.actions.fetched(org));
      } else {
        console.warn("fetched org but session wasn't SCOPED");
        Api.AUTH.ORGS.get({ urlExtend: `/${id}`, success: (resp) => this.actions.fetched(resp) });
      }
    },

    fetchAll() {
      this.actions.loading(true);
      Api.AUTH.ORGS.get({
        success: (resp) => {
          this.actions.fetchedAll(resp);
          this.actions.loading(false);
        },
      });
    },

    fetchForUser(user) {
      const theUser = user ? new User(user) : EverTrue.store.user;
      if (theUser.isSuperUser()) {
        this.fetchAll();
      } else {
        this.actions.fetchedForUser(theUser.getOrganizations());
      }
    },
  },
});
