import { Card } from '@evertrue/et-components';
import PropTypes from 'prop-types';

const HomepageCard = ({ leftHeading, rightHeading = '', body, headingRow = true }) => {
  return (
    <Card
      className="homepage-card"
      header={
        <div className={headingRow ? 'homepage-card-heading-row' : 'homepage-card-heading-column'}>
          <h1 className="title-subtitle-500-20">{leftHeading}</h1>
          <span className="title-subtitle-400-14">{rightHeading}</span>
        </div>
      }
      cardSize="default"
      padding="20px"
    >
      {body}
    </Card>
  );
};

HomepageCard.propTypes = {
  leftHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  body: PropTypes.object,
  headingRow: PropTypes.bool,
};

export default HomepageCard;
