import PropTypes from 'prop-types';

const OutreachThankviewIndicator = ({ thankviewVideos }) => {
  return (
    <div className="outreach-card-tab-group--item outreach-custom-signal-video">
      <span className="tab-text">
        <i
          role="img"
          aria-label="icon: signal-video"
          className="gt2-icon gt2-icon-signal-video base-nav-sub-item--icon gt-icon-fw outreach-card-tab-group-icon-video"
          alt="signal-video"
          aria-hidden="true"
        />
        &nbsp; Signal Video
      </span>
    </div>
  );
};

OutreachThankviewIndicator.propTypes = {
  thankviewVideos: PropTypes.array,
};

export default OutreachThankviewIndicator;
