module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   _getNameQuery = (value) ->
      return unless value
      value_parts = value.split(" ")
      prop_array = [Property("name", value, {type: "wildcard"})]
      _.each value_parts, (part) ->
         prop_array.push(Property "name", part, {type: "wildcard"})
      Query([ Parameter "should", prop_array ]).toJSON()


   createSource "ProfileMatchingSource",
      actions:
         matched: true
         unmatched: true
         reset: true
         setAnalytics: true
         loading: true
         fetchedSocialProfile: true
         fetchedFacebookProfile: true

      api:
         match: (contact_id, network, uid, page_id, referrer) ->
            return unless contact_id && network && uid
            @actions.loading(true)
            Api.LIDS.MATCHES.post
               urlArgs: {contact_id: contact_id, page_id: page_id}
               data: _.jsonStringify
                  network: network
                  uid: uid
                  page_id: page_id
               statusCode:
                  201: => @actions.matched(contact_id, network, uid, referrer)
               success: =>
                  @actions.matched(contact_id, network, uid, referrer)
                  @actions.loading(false)
               error: (xhr, statusCode, error) =>
                  @actions.loading(false)
                  if xhr.status is 409
                     id = xhr?.responseJSON?.errors?.current_matched_contact_id
                     EverTrue.Alert.error "The Profile is already matched to another constituent.",
                        className: "toast-action-lg"
                        timeout: 10000
                        header: "Profile Match Conflict"
                        actionLabel: if id then "View Constituent Profile"
                        onAction: if id then (-> _.defer(-> EverTrue.Navigator("/contact/#{id}")))


         unmatch: (contact_id, network, uid, is_retry) ->
            return unless contact_id && network && uid
            @actions.loading(true)
            Api.LIDS.MATCHES.post
               urlExtend: "/blacklist"
               urlArgs: {contact_id: contact_id}
               data: _.jsonStringify
                  network: network
                  uid: uid
               disableAlerts: true
               success: =>
                  @actions.unmatched(contact_id, network)
                  @actions.loading(false)
               error: (xhr, textStatus, error) =>
                  @actions.loading(false)
                  response = _.jsonParse(xhr.responseText, {})
                  is_lock_error = xhr.status == 504
                  if is_lock_error && !is_retry # If user gets an acquire lock error, retry once
                     _.wait(300, => @unmatch(contact_id, network, uid, true))
                  else
                     if is_lock_error || !response.message
                        error_message = "We've encountered an issue trying to unmatch
                        this constituent. Please try again in a few minutes."
                     else error_message = response.message
                     EverTrue.Alert.error(error_message)

         setAnalytics: (key) ->
            @actions.setAnalytics(key)

         reset: ->
            @actions.reset()
