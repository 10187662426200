import { useFluxStore } from '@evertrue/et-flux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProfileStore from 'apps/profile/stores/profile-store';
import ProfileSource from 'apps/profile/sources/profile-source';
import ProfileDetailHeader from 'apps/profile/components/profile-detail-header';
import ProfileGiftsFeedController from 'apps/profile/controllers/profile-gifts-feed-controller';
import FeatureStore from 'apps/layout/stores/feature-store';

const ProfileGiftDetailController = ({ id, section, title }) => {
  const contact = useFluxStore(ProfileStore, () => ProfileStore.getProfile(id));
  const hasHybridGiving = useFluxStore(FeatureStore, () => FeatureStore.hasFeature('hybrid_giving'));

  useEffect(() => {
    ProfileSource.fetch(id);
    ProfileSource.recordProfileView(id);
  }, [id]);

  if (!hasHybridGiving) {
    return null;
  }

  return (
    <div className="contact-profile contact-profile-details contact-profile-v2">
      <ProfileDetailHeader contact={contact} section={section} title={title} />
      <div className="contact-profile-details--content">
        <ProfileGiftsFeedController contact={contact} limit={15} />
      </div>
    </div>
  );
};

ProfileGiftDetailController.propTypes = {
  id: PropTypes.any,
  section: PropTypes.string,
  title: PropTypes.string,
};

export default ProfileGiftDetailController;
