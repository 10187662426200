const HARD_CODED_LINKS = {
  thankView: [
    { setBusinessLogoUrl: 'https://thankview.com/api/signal/personal/index' },
    { setDefaultLogoOptionsUrl: 'https://thankview.com/api/signal/personal/setDefaultLogoOptions' },
    { setDefaultButtonOptionsUrl: 'https://thankview.com/api/signal/personal/setDefaultBtnOptions' },
    { bulkDeleteUrl: 'https://thankview.com/api/signal/personal/bulkDelete' },
    { copyVideoPathUrl: 'https://thankview.com/video/signal/' },
    { authenticateUserUrl: 'https://thankview.com/api/signal/register' },
    { uploadVideo: 'https://thankview.com/api/signal/personal/upload' },
  ],
  everTrue: [
    { privacyPolicyUrl: 'https://www.evertrue.com/privacy-policy/' },
    { termsAndConditionsUrl: 'https://www.evertrue.com/terms-and-conditions/' },
    { helpCenterUrl: 'https://help.evertrue.com/t/g9y6jxx' },
    { tasksHelpCenterUrl: 'https://help.evertrue.com/t/35y34y7/all-about-signal-collaborative-tasks' },
    { listsHelpCenterUrl: 'https://help.evertrue.com/t/y4hct1a/creating-and-viewing-dynamic-lists' },
    {
      emailsHelpCenterUrl: 'https://help.evertrue.com/t/60y6jx8/using-signal-email-templates-and-signatures',
    },
    { launchpadHelpCenterUrl: 'https://help.evertrue.com/t/m1hwg8h/all-about-launchpad-widgets' },
    { eventBriteUrl: 'https://help.evertrue.com/article/463-add-eventbrite-to-a-facebook-event' },
    { blogUrl: 'https://www.evertrue.com/blog/' },
    { podcastUrl: 'https://www.evertrue.com/podcast' },
    { forumUrl: 'https://help.evertrue.com/' },
    { statusUrl: 'http://status.evertrue.com/' },
  ],
  calendarTemplates: [
    { googleCalendarUrl: 'https://calendar.google.com/calendar/render?action=TEMPLATE&' },
    { microsoftCalendarUrl: 'https://outlook.office.com/calendar/action/compose?' },
  ],
  amazonS3: [
    { ssoBulkInvitesUrl: 'https://evertrue-prod-public.s3.amazonaws.com/sample_sso_bulk_invites.csv' },
    { sampleBulkInvitesUrl: 'https://evertrue-prod-public.s3.amazonaws.com/sample_bulk_invites.csv' },
  ],
};

export const getLinkUrl = (category, name) => {
  return HARD_CODED_LINKS?.[category]?.find((link) => link[name])?.[name] || '';
};
