import { useState } from 'react';
import PropTypes from 'prop-types';
import DateQuery from './DateQuery';
import TextQuery from './TextQuery';

const RenderableQuery = ({ renderableQuery, setRenderableQuery }) => {
  const [editingField, setEditingField] = useState(null);

  const handleInputSubmit = (index, inputValue) => {
    setRenderableQuery((prevQuery) =>
      prevQuery.map((part, index) =>
        index === editingField ? { ...part, value: { ...part.value, value: inputValue } } : part
      )
    );
    setEditingField(null);
  };

  const handleDateChange = (date) => {
    setRenderableQuery((prevQuery) =>
      prevQuery.map((part, index) =>
        index === editingField ? { ...part, value: { ...part.value, value: date } } : part
      )
    );
    setEditingField(null);
  };

  return (
    <div className="renderable-query">
      {renderableQuery.map((part, index) =>
        part.type === 'text' ? (
          <span key={part.value}>{part.value}</span>
        ) : part.value.type === 'date' ? (
          <DateQuery 
            part={part} 
            index={index} 
            handleDateChange={handleDateChange} 
            setEditingField={setEditingField} 
          />
        ) : (
          <TextQuery
            part={part}
            index={index}
            handleInputSubmit={handleInputSubmit}
            setEditingField={setEditingField}
          />
        )
      )}
    </div>
  );
};

RenderableQuery.propTypes = {
  renderableQuery: PropTypes.object,
  setRenderableQuery: PropTypes.func,
};

export default RenderableQuery;