import { useState, useEffect } from "react";
import { PropTypes } from "base/react-libs";
import BioItem from "./bio-item";
import { prioritizeBioItems } from "apps/profile/components/enrichment/enrichment-utils";

const BioItems = ({ names, locations, editMode = "", onMutation }) => {
  const [locationsToDisplay, setLocationsToDisplay] = useState([]);
  const [namesToDisplay, setNamesToDisplay] = useState([]);

  useEffect(() => {
    const sortedNames = prioritizeBioItems(names);

    if(sortedNames && sortedNames.length > 0) {
      if(editMode === "") {
        setNamesToDisplay([sortedNames[0]])
      } else {
        setNamesToDisplay(sortedNames);
      }
    }
  }, [editMode, names]);

  useEffect(() => {
    const sortedLocations = prioritizeBioItems(locations);

    if(sortedLocations && sortedLocations.length > 0) {
      if(editMode === "") {
        setLocationsToDisplay([sortedLocations[0]])
      } else {
        setLocationsToDisplay(sortedLocations)
      }
    }
  }, [editMode, locations]);

  const renderBioItems = (bioItems, itemType) => (
    <div className="profile--enrichment-group-data">
    {bioItems.map((name) => (
      <BioItem key={name} editMode={editMode} item={name} itemType={itemType} mutationFunc={onMutation}/>
    ))}
    </div>
  );

  return (
    <>
      {namesToDisplay.length > 0 || editMode !== "restore" ? (
        <div className="profile--enrichment-group">
        <div className="profile--enrichment-group-title">Name</div>
          {namesToDisplay.length > 0 ? (
            renderBioItems(namesToDisplay, "name")
          ) : (<div className="profile-enrichment--item">No enrichment data available</div>)}
        </div>
      ) : null}

      {locationsToDisplay.length > 0 || editMode !== "restore" ? (
        <div className="profile--enrichment-group">
        <div className="profile--enrichment-group-title">Location</div>
          {locationsToDisplay.length > 0 ? (
            renderBioItems(locationsToDisplay, "location")
          ) : (<div className="profile-enrichment--item">No enrichment data available</div>)}
        </div>
        ) : null}
    </>
  );
};

BioItems.propTypes = {
  names: PropTypes.array,
  locations: PropTypes.array,
  editMode: PropTypes.string,
  onMutation: PropTypes.func,
};
export default BioItems;
