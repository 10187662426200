import $ from 'jquery';
import Decorator from 'clientDecorator';
import EverTrue from 'app';
import { Link } from '@evertrue/et-components';

const GoogleSearch = ({ contact }) => {
  const handleTrack = () => {
    EverTrue.track.set('contact_action', {
      type: 'profile_google_search',
      referrer: 'person',
    });
  };

  const getSearches = () => {
    let location;
    const name_string = Decorator.Contacts.getFullName(contact);
    const primary_work = Decorator.Work.getCurrentCompany(contact);
    const organization = EverTrue.store.org.get('name');

    const primary_address = Decorator.Address.getPrimary(contact);
    if (primary_address) {
      location = [primary_address.city?.value, primary_address.state?.value].filter(Boolean).join(', ');
    }

    return {
      name_only: `${name_string}`,
      name_with_location: location ? `${name_string} in ${location}` : undefined,
      name_with_company: primary_work?.name ? `${name_string} at ${primary_work.name}` : undefined,
      name_with_org: organization ? `${name_string} at ${organization}` : undefined,
    };
  };

  const searches = getSearches();

  return (
    <div className="google-search">
      {Object.values(searches).map((search) => {
        if (!search) return null;

        const query = $.param({ q: search });
        return (
          <Link
            key={search}
            title="search this constituent on Google"
            href={`https://www.google.com/search?${query}`}
            className="google-search--suggestion clearfix"
            target="_BLANK"
            onClick={handleTrack}
          >
            <span className="google-search--icon" />
            <span className="google-search--label">
              Google "<span className="fs-exclude-data">{search}</span>"
            </span>
          </Link>
        );
      })}
    </div>
  );
};

GoogleSearch.propTypes = {
  contact: ReactLibs.PropTypes.object,
};

export default GoogleSearch;
