import { PropTypes } from 'base/react-libs';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from '@evertrue/et-components';

const CommonDeleteModal = ({ isOpen, onClose, handleDelete, title, body, buttonLabel }) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeader title={title} />
      <ModalBody>{body}</ModalBody>
      <div className="bookmark-delete-modal-actions">
        <Button type="simple" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ margin: '0 20px' }}>
          <Button type="destroy" onClick={() => handleDelete(setSubmitting)} disabled={submitting}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CommonDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default CommonDeleteModal;
