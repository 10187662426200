import PropTypes from "prop-types";
import EverTrue from "app";

import { CachedContactsProvider } from "@evertrue/vms-insights";
import { fetchContactsById } from "apps/volunteers/vms-queries";
import { GlobalDrawerProvider } from "components/drawer/context/global-drawer-context";
import { GlobalContactSearchProvider } from "apps/layout/controllers/global-contact-search-context";
import { FiltersDataProvider } from "apps/query-builder/contexts/filters-data-context";
import { ListDataProvider } from "apps/layout/controllers/list-data-context";
import { GlobalPortfolioProvider } from "apps/portfolio-performance/contexts/global-portfolio-context";
import { GlobalJourneyProvider } from "apps/journeys/context/global-journey-context";
import { TasksNotificationProvider } from "apps/tasks/hooks/tasks-notification-context.js";
import { ContactModalProvider } from "apps/profile/contexts/contact-modal-context";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { GlobalOutreachProvider } from "apps/outreach/contexts/global-outreach-context";

// Make sure this state really needs to be global before adding another Provider!
const GlobalProviderController = ({ user = {}, ...props }) => {
  const OID = EverTrue.store.org.get("id");

  const organizationContext = {
    kind: "organization",
    key: OID,
  };

  const userContext = {
    kind: "user",
    key: user.id,
    name: user.name,
    email: user.email,
  };

  const platformContext = {
    kind: "platform",
    key: "web",
  };

  const multiContext = {
    kind: "multi",
    user: userContext,
    organization: organizationContext,
    platform: platformContext,
  };

  return (
    <LDProvider
      clientSideID={EverTrue.config.launch_darkly_token} // change for stage / prod
      context={multiContext}
      // https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
    >
      <CachedContactsProvider fetchContactsById={fetchContactsById}>
        <GlobalOutreachProvider>
          <GlobalDrawerProvider>
            <GlobalJourneyProvider>
              <GlobalContactSearchProvider>
                <GlobalPortfolioProvider>
                  <ListDataProvider>
                    <FiltersDataProvider>
                      <ContactModalProvider>
                        <TasksNotificationProvider>{props.children}</TasksNotificationProvider>
                      </ContactModalProvider>
                    </FiltersDataProvider>
                  </ListDataProvider>
                </GlobalPortfolioProvider>
              </GlobalContactSearchProvider>
            </GlobalJourneyProvider>
          </GlobalDrawerProvider>
        </GlobalOutreachProvider>
      </CachedContactsProvider>
    </LDProvider>
  );
};

GlobalProviderController.propTypes = {
  user: PropTypes.object,
  children: PropTypes.any,
};

export default GlobalProviderController;
