import PropTypes from 'prop-types';
import ThreadHeader from './thread-header';
import ThreadList from './thread-list';
import ThreadDetailsActions from './thread-detail-actions';

const ThreadDetailedView = ({ id, subject, threadReplies }) => {
  return (
    <>
      <ThreadHeader subject={subject} />
      <ThreadList id={id} threadReplies={threadReplies} />
      {threadReplies?.items?.length > 0 && <ThreadDetailsActions id={id} threadReplies={threadReplies} />}
    </>
  );
};

ThreadDetailedView.propTypes = {
  id: PropTypes.string,
  subject: PropTypes.string,
  threadReplies: PropTypes.object,
};

export default ThreadDetailedView;
