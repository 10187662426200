import PropTypes from 'prop-types';
import { Work, Profile } from 'clientDecorator';
import Career from 'apps/profile/components/templates/career';
import Education from 'apps/profile/components/templates/education';
import { getAddresses } from 'mixins/address-mixin';
import BioContactInfo from 'apps/profile/components/bio-contact-info';
import ProfileCardSubtitle from 'apps/profile/components/profile-card-subtitle';

const BioInfoAll = ({ contact = {}, close = () => {} }) => {
  let positions = Work.getAllPositions(contact);
  let addresses = getAddresses(contact);
  let educations = Profile.getParsedEducation(contact);
  const contactInfo = Profile.getContactInfo(contact);

  if (positions.length === 0) positions = [{}];
  if (addresses.length === 0) addresses = [{}];
  if (educations.length === 0) educations = [{}];
  if (contactInfo?.phones?.length === 0) contactInfo && (contactInfo.phones = [{}]);
  if (contactInfo?.emails?.length === 0) contactInfo && (contactInfo.emails = [{}]);

  return (
    <div className="bio-info">
      <div className="bio-info--section">
        <ProfileCardSubtitle label="Career" />
        {positions.map((work, i) => (
          <Career data={work} key={`${work.name}`} />
        ))}
      </div>
      <div className="bio-info--section">
        <ProfileCardSubtitle label="Education" />
        {educations.map((school, i) => (
          <Education data={school} key={`${school.school_name}-${school.year}`} />
        ))}
      </div>
      <BioContactInfo contact={contact} />
    </div>
  );
};

BioInfoAll.propTypes = {
  contact: PropTypes.object,
  close: PropTypes.func,
};

export default BioInfoAll;
