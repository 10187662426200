import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';
import EverTrue from 'app';

const TOP_FIELDS_SETTINGS_KEY = 'GT_profile_fields';

const TopFieldsSource = createSource('TopFieldsSource', {
  actions: {
    fetchedTopFields: true,
    updatedTopFields: true,
  },

  api: {
    fetchTopFields() {
      return Api.DNA.SETTING.get({
        urlArgs: { setting_key: TOP_FIELDS_SETTINGS_KEY },
        disableAlerts: true,
        params: { user_id: 'me' },
        success: (response) => {
          const fields = response?.settings[TOP_FIELDS_SETTINGS_KEY];
          return this.actions.fetchedTopFields(fields?.value);
        },
        error: () => {
          return this.actions.fetchedTopFields();
        },
      });
    },

    updateTopFields(newFields) {
      // Optimistically update fields before dealing with DNA
      // DNA saving can be behind the scenes & w/o UI display
      this.actions.updatedTopFields(newFields);

      return Api.DNA.SETTING.put({
        urlArgs: { setting_key: TOP_FIELDS_SETTINGS_KEY },
        disableAlerts: true,
        params: { user_id: 'me' },
        data: JSON.stringify({ value: newFields }),
        success(_response) {
          return EverTrue.Alert.success('Successfully updated top fields');
        },
      });
    },
  },
});

export default TopFieldsSource;
