import { getMomentObjectInTimezone } from 'base/new-utils';
import { Icon, Link } from '@evertrue/et-components';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import colors from 'base/colors';
import HomepageCard from '../homepage-card';
import TripsAndMeetings from './trips-meetings';

const PausedToday = () => {
  return (
    <HomepageCard
      leftHeading={'Today'}
      rightHeading={getMomentObjectInTimezone().format('dddd, MMMM DD, YYYY')}
      body={
        <div className="et--card--main">
          <div className="task-counter-home--title alternate">Tasks</div>
          <div className="journeys-pause-container alternate">
            <div className="pause-message-wrapper">
              <div className="icon-background">
                <Icon icon="pause" size={2} style={{ color: colors.gray150 }} />
              </div>
              <span>
                Enjoy your day! Your tasks are currently paused, with nothing due today. Ready to jump back in? You can
                elect to resume your cadences and tasks at any point{' '}
                <Link
                  title="edit cadence pause"
                  href="settings/cadences"
                  onClick={() => {
                    trackJourneysAction('edit_cadence_pause');
                  }}
                >
                  here
                </Link>
                .
              </span>
            </div>
          </div>
          <TripsAndMeetings />
        </div>
      }
    />
  );
};

export default PausedToday;
