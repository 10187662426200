import { ActionsMenu } from '@evertrue/et-components';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import PropTypes from 'prop-types';
import JourneyModal from '../modals/journey-modal';
import BulkSkipModal from '../modals/bulk-move/bulk-skip-modal';
// import SkipToModal from "../modals/bulk-move/skip-to-modal";
import BulkRemoveModal from '../modals/bulk-move/bulk-remove-modal';
import BulkDeleteModal from '../modals/bulk-move/bulk-delete-modal';
import JourneyTaskModal from '../modals/journey-task-modal';
import DeleteJourneyModal from '../modals/delete-journey-modal';
import BulkTaskCompleteModal from '../modals/bulk-move/bulk-task-complete-modal';
import UpdateTaskDueDateModal from '../modals/bulk-move/update-task-due-date-modal';
import BulkMoveToJourneyModal from '../modals/bulk-move/bulk-move-to-journey-modal';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';
import { useGlobalJourneyProvider } from 'apps/journeys/context/global-journey-context';

const JourneyActionMenu = ({ journey = {} }) => {
  const {
    steps = [],
    selectedTasks = [],
    userIsCreator = false,
    updateJourneyState = () => {},
    optionalStateManagementFunction = () => {},
  } = useJourneyDetailProvider();

  const { selectedDueDate } = useGlobalJourneyProvider();

  const selectedTaskIds = selectedTasks?.map((t) => t?.id) || [];
  const isCreatorOptions = [
    {
      id: 1,
      icon: 'add-step-icon',
      iconKit: 'gt2',
      label: 'Add Step',
      mountOnClick: ({ is_open, close }) => {
        return (
          <JourneyTaskModal
            isOpen={is_open}
            onClose={close}
            steps={steps}
            journeyId={journey?.id}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 2,
      icon: 'edit',
      label: 'Edit Cadence',
      mountOnClick: ({ is_open, close }) => {
        return (
          <JourneyModal
            isOpen={is_open}
            onClose={close}
            existingJourney={journey}
            optionalStateManagementFunction={updateJourneyState}
          />
        );
      },
    },
    {
      id: 3,
      icon: 'trash',
      label: 'Delete Cadence',
      mountOnClick: ({ is_open, close }) => {
        return <DeleteJourneyModal isOpen={is_open} onClose={close} journey={journey} />;
      },
    },
  ];

  const bulkMoveOptions = [
    {
      id: 4,
      label: 'Mark as Complete',
      icon: 'success-circle',
      iconKit: 'gt',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkTaskCompleteModal
            isOpen={is_open}
            onClose={close}
            isComplete={true}
            tasks={selectedTasks}
            label="Constituents (Tasks) Selected"
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 5,
      label: 'Mark as Incomplete',
      icon: 'incomplete-task',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkTaskCompleteModal
            isOpen={is_open}
            onClose={close}
            tasks={selectedTasks}
            label="Constituents (Tasks) Selected"
            trackAction={trackJourneysAction}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 6,
      label: 'Update Due Date',
      icon: 'update-due-date',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <UpdateTaskDueDateModal
            isOpen={is_open}
            onClose={close}
            tasks={selectedTasks}
            trackAction={trackJourneysAction}
            selectedDueDate={selectedDueDate}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    // {
    //   id: 7,
    //   label: "Skip to Step",
    //   icon: "todolistcircle",
    //   iconKit: "gt2",
    //   mountOnClick: ({ is_open, close }) => {
    //     return (
    //       <SkipToModal
    //         isOpen={is_open}
    //         onClose={close}
    //         journey={journey}
    //         selectedTaskIds={selectedTaskIds}
    //         optionalStateManagementFunction={optionalStateManagementFunction}
    //       />
    //     );
    //   },
    // },
    {
      id: 8,
      label: 'Skip Current Step',
      icon: 'skip',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkSkipModal
            isOpen={is_open}
            onClose={close}
            journey={journey}
            steps={steps}
            selectedTaskIds={selectedTaskIds}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 9,
      label: 'Remove from Cadence',
      icon: 'cancel-circle',
      iconKit: 'gt',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkRemoveModal
            isOpen={is_open}
            onClose={close}
            journey={journey}
            selectedTasks={selectedTasks}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 10,
      label: 'Move to New Cadence',
      icon: 'swap-horizontal',
      iconKit: 'gt2',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkMoveToJourneyModal
            isOpen={is_open}
            onClose={close}
            journey={journey}
            selectedTasks={selectedTasks}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
    {
      id: 11,
      label: 'Delete Tasks',
      icon: 'trash',
      iconKit: 'gt',
      mountOnClick: ({ is_open, close }) => {
        return (
          <BulkDeleteModal
            isOpen={is_open}
            onClose={close}
            selectedTaskIds={selectedTaskIds}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        );
      },
    },
  ];

  // build drop down options based on permissions and feature flags
  const getOptions = () => {
    const options = [];

    // Check if user has creator permissions
    if (userIsCreator) {
      options.push({
        id: 'creator-options',
        items: isCreatorOptions,
      });
    }
    const selectedTaskCount = selectedTasks?.length || 0;
    options.push({
      id: 'bulk-move-options',
      heading: `Actions on ${selectedTaskCount} Constituents (Tasks) Selected`,
      items: bulkMoveOptions,
    });
    return options;
  };

  return <ActionsMenu options={getOptions()} />;
};

JourneyActionMenu.propTypes = {
  journey: PropTypes.object.isRequired,
};

export default JourneyActionMenu;
