module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   GlobalAutocompleteSource = require("apps/layout/sources/global-autocomplete-source")
   FilterSource = require("apps/filters/sources/filter-source")
   GateSource = require("apps/layout/sources/gate-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   CoreSearchConfig = require("apps/core-search/utils/core-search-config")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   UrlSource = require("apps/layout/sources/url-source")


   createStore "GlobalAutocompleteStore",
      getInitialState: ->
         results: {}
         loading: false
         query_string: undefined
         registered_queries: []
         typeahead_queries: []

      registerActions: ->
         @on GlobalAutocompleteSource.actions.loading, @respondToLoading
         @on GlobalAutocompleteSource.actions.search, @respondToSearch
         @on GlobalAutocompleteSource.actions.fetched, @respondToFetched
         @on GlobalAutocompleteSource.actions.viewAll, @respondToViewAll

         @on GlobalAutocompleteSource.actions.registerQuery, @respondToRegister
         @on GlobalAutocompleteSource.actions.unregisterQuery, @respondToUnregister

         @on GateSource.actions.fetchedGates, @respondToGates

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToSearch: (query_string) ->
         registered_queries = @getState("registered_queries")
         typeahead_queries = _.cloneData @getState("typeahead_queries")
         if _.isEmpty(query_string) then typeahead_queries = []
         else typeahead_queries.push(query_string)

         results = _.pick _.cloneData(@getState("results")), registered_queries.concat(typeahead_queries)

         if _.isEmpty(query_string)
            @setState {results: results, typeahead_queries: typeahead_queries}
         else
            @setState {results: results, typeahead_queries: typeahead_queries}
            if FeatureStore.hasFeaturesLoaded()
               _.each CoreSearchConfig, (config, key) ->
                  if config.query_fn && (!config.is_available || config.is_available())
                     GlobalAutocompleteSource.fetch(config.query_fn(query_string)?.toJSON(), query_string, key)

      respondToFetched: (type, query_string, response) ->
         results = _.cloneData @getState("results")
         results[query_string] ?= {}
         results[query_string][type] = response
         results[query_string][type]?.items = CoreSearchConfig[type]?.formatter?(results[query_string][type]?.items)

         CoreSearchConfig[type].parser?(results[query_string][type]?.items)
         @setState {results: results}

      respondToViewAll: (type, query, options) ->
         pathname = window.location.pathname
         UrlSource.updateSegment()
         FilterSource.updateOperator("and")
         FilterSource.set([
            filter_id: "0.015"
            filter_row_id: 0
            value: {value: query, type: type}
         ])

         CoreSearchSource.query(type)
         route = CoreSearchConfig[type]?.route
         nav = !pathname.match(///^\#{route}(\?)?[^\/]*$///)
         EverTrue.UrlManager.navigate(route, nav, false)

         if options.referrer
            _.defer (-> EverTrue.track.setReferrer(options.referrer))

      respondToRegister: (query) ->
         registered_queries = _.cloneData @getState("registered_queries")
         registered_queries.push(query)
         @setState {registered_queries: registered_queries}

      respondToUnregister: (query) ->
         registered_queries = _.cloneData @getState("registered_queries")
         registered_queries = _.without(registered_queries, query)
         @setState {registered_queries: registered_queries}

      respondToGates: ->
         if FeatureStore.hasFeaturesLoaded()
            typeahead_queries = @getState("typeahead_queries")
            unless _.isEmpty(typeahead_queries)
               @respondToSearch(_.last(typeahead_queries))

      api:
         getTypeaheadResults: ->
            typeahead_queries = _.clone(@getState("typeahead_queries")).reverse()
            results = @getState("results")
            query_with_results = _.find typeahead_queries, (query) -> !!results[query]?.contacts
            @getResultsByQuery(query_with_results, 3)

         getResultsByQuery: (query, limit=5) ->
            results = @getState("results")[query]
            formatted = _.compact _.flatten _.map results, (data, key) ->
               if data.items?.length
                  config = CoreSearchConfig[key]
                  section_items = [{type: "header", key: key, total: data.total, label: config?.label, sort: config?.sort}]
                  _.each _.first(data.items, limit), (item, index) ->
                     section_items.push _.extend item, type: key, sort: parseFloat(config?.sort + ".#{index}")
                  section_items
            _.sortBy formatted, "sort"

         getCounts: ->
            _.mapObject @getState("results"), (val, key) -> val.length

         getLoading: ->
            @getState("loading")
