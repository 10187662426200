import { PropTypes } from 'base/react-libs';
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from '@evertrue/et-components';
import DatePickerDropdown from 'components/calendar/date-picker-dropdown';
import JourneyContactCard from 'apps/journeys/components/journey-contact-card';
import FormField from 'components/forms/form-field';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import useTasks from 'apps/tasks/hooks/use-tasks';
import { Formik } from 'formik';
import CharacterCounter from 'apps/interactions/components/character-counter';
import { EditTaskDueDateSchema } from './schema/edit-task-due-date-schema';
import { getDateString } from 'base/new-utils';
import { editTaskReason } from 'apps/tasks/utils/utils';

const options = [
  {
    label: 'Timing is not appropriate for given constituent',
    value: 'Timing is not appropriate for given constituent',
  },
  {
    label: 'Constituent requested communication for later date',
    value: 'Constituent requested communication for later date',
  },
  { label: 'Ran out of time to complete today', value: 'Ran out of time to complete today' },
  { label: 'Too late for communication due to time zone', value: 'Too late for communication due to time zone' },
  {
    label: 'Waiting for additional follow up from campus stakeholder',
    value: 'Waiting for additional follow up from campus stakeholder',
  },
  { label: 'Other', value: 'Other' },
];

const EditTaskDueDateModal = ({
  isOpen,
  onClose,
  task = {},
  step = {},
  optionalStateManagementFunction = () => {},
}) => {
  const { updateTask, getTaskById } = useTasks();

  const initialValues = {
    dueDate: task?.current_due_date || '',
    reason: null,
    reasonDetails: '',
  };

  const handleSubmit = async (values) => {
    const newDate = getDateString(values.dueDate);
    const taskObj = await getTaskById(task?.id);
    // get the edit task reason
    const reason = editTaskReason(values?.reason, values?.reasonDetails);
    let payload = { ...taskObj, current_due_date: newDate };
    updateTask(payload, taskObj, reason);

    const todayDate = getDateString();
    if (newDate > todayDate) optionalStateManagementFunction('removeTask', { task, step });
    else optionalStateManagementFunction('updateTask', { task, step });

    trackJourneysAction('edit_due_date');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={EditTaskDueDateSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched, isValid, isSubmitting }) => (
          <>
            <ModalHeaderWithActions
              title="Edit Due Date"
              closeModal={onClose}
              onSubmit={handleSubmit}
              disableSubmit={!isValid}
            />
            <ModalBody scrollable={false}>
              <p className="journey-modal--warning">
                The due date for the sequential cadence steps will adjust accordingly based on the new due date you set.
              </p>

              <JourneyContactCard contact={task.contact} avatarSize={60} />

              <FormField required={false} label="Current Step" inlineLabel={false} boldLabel={true}>
                <div>
                  Step {step?.step_number} | Day {step?.day_number}
                </div>
                <div>{step?.title}</div>
              </FormField>

              <FormField label="Due Date" inlineLabel={false}>
                <DatePickerDropdown
                  date={values.dueDate}
                  onDayClick={(date) => setFieldValue('dueDate', date)}
                  triggerClassName={'task-edit-date-picker'}
                  chevronClassName={'date-picker-chevron'}
                  allowClear={false}
                />
                {touched.dueDate && errors.dueDate && <div className="error-message">{errors.dueDate}</div>}
              </FormField>

              <FormField label="Reason" required={true} inlineLabel={false}>
                <ListboxSelect
                  size="medium"
                  options={options}
                  onChange={(newReason) => {
                    setFieldValue('reason', newReason);
                    if (newReason.value !== 'Other') {
                      setFieldValue('reasonDetails', '');
                    }
                  }}
                  value={values.reason}
                  emptyValue="Select Reason"
                  label="Select Reason"
                />
                {touched.reason && errors.reason && <div className="error-message">{errors.reason}</div>}
              </FormField>

              {values.reason && values.reason.value === 'Other' && (
                <FormField label="Details" required={true} inlineLabel={false}>
                  <textarea
                    type="text"
                    style={{ resize: 'none' }}
                    value={values.reasonDetails}
                    onChange={handleChange('reasonDetails')}
                  />
                  <CharacterCounter limit={150} count={values.reasonDetails.length} />
                  {touched.reasonDetails && errors.reasonDetails && (
                    <div className="error-message">{errors.reasonDetails}</div>
                  )}
                </FormField>
              )}
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

EditTaskDueDateModal.propTypes = {
  task: PropTypes.object,
  step: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default EditTaskDueDateModal;
