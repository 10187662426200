import { PropTypes } from 'base/react-libs';
import { daysFromNow, formattedDate } from 'apps/tasks/utils/utils';

const TaskStatusMessage = ({ task = {} }) => {
  const overdueMessage = (task) => {
    const { current_due_date } = task;
    if (!current_due_date) return null;
    const due = daysFromNow(current_due_date);
    if (due.overdue) return <div style={due.style}>{due.date}</div>;
  };

  const completedMessage = (task) => {
    const { completed_at } = task;
    let time = completed_at;
    if (typeof completed_at === 'string') {
      time = new Date(completed_at).getTime() / 1000;
    }
    if (!completed_at) return null;
    const due = formattedDate(time);
    if (due) return <div className="journeys-task-table-row--details--completed-date-text">Completed on {due}</div>;
  };

  return <div>{task.completed_at ? <div>{completedMessage(task)}</div> : <div>{overdueMessage(task)}</div>}</div>;
};

TaskStatusMessage.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskStatusMessage;
