import _ from 'underscore';
import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

// Map google zoom position to query precision
const ZOOM_PRECISION_MAP = {
  2: 1,
  3: 1,
  4: 2,
  5: 2,
  6: 3,
  7: 3,
  8: 3,
  9: 4,
  10: 4,
  11: 5,
  12: 5,
  13: 5,
  14: 6,
  15: 6,
  16: 7,
  17: 8,
};

let throttleRequest;

export default createSource('MapSource', {
  actions: {
    loadingClusters: true,
    clearClusters: true,
    fetchedClusters(zoom, data) {
      this.require(_.isNumber(zoom), 'zoom level should be number');
      this.require(_.isPagedObject(data), 'data is paged object');
    },
    changedZoom(level) {
      this.require(_.isNumber(level), 'zoom level should be number');
    },
    initializedMap(mapObject, zoom) {
      this.require(_.isObject(mapObject), 'mapObject should be an object');
      this.require(_.isNumber(zoom), 'zoom level should be number');
    },
    setBoundingBox(box) {
      this.require(_.isObject(box) || _.isUndefined(box), 'box should be object');
    },
    highlightCluster(clusterKey) {
      this.require(
        _.isString(clusterKey) || _.isArray(clusterKey) || _.isUndefined(clusterKey),
        'clusterKey should be string, array or undefined'
      );
    },
  },

  api: {
    fetchClusters(zoom, data) {
      if (_.isEmpty(data?.query?.must)) {
        console.error('MapSource.fetchClusters() needs data.query to contain a must clause');
        return;
      }

      throttleRequest?.abort();
      this.actions.loadingClusters(true);

      const { query } = data;
      query.geo_cluster = [{ field: 'addresses.location', precision: ZOOM_PRECISION_MAP[zoom] }];

      return Api.CONTACTS.SEARCH.post({
        data: _.jsonStringify(query),
        params: data.params,
        beforeSend: (xhr) => {
          throttleRequest = xhr;
        },
        error: () => {
          this.actions.loadingClusters(false);
        },
        success: (resp) => {
          this.actions.loadingClusters(false);
          this.actions.fetchedClusters(zoom, resp);
        },
      });
    },

    clearClusters() {
      this.actions.clearClusters();
    },

    zoom(zoomLevel) {
      this.actions.changedZoom(zoomLevel);
    },

    increasePrecision(zoom) {
      let zoomLevel = zoom + 1;
      const currentPrecision = ZOOM_PRECISION_MAP[zoom];

      if (ZOOM_PRECISION_MAP[zoomLevel] > currentPrecision) {
        this.zoom(zoomLevel);
      } else {
        zoomLevel = _.findKey(ZOOM_PRECISION_MAP, (precision, key) => precision > currentPrecision);
        if (zoomLevel) {
          this.zoom(_.toNumber(zoomLevel));
        }
      }
    },

    initializeMap(mapObject, zoom) {
      this.actions.initializedMap(mapObject, _.toNumber(zoom));
    },

    setBoundingBox(box) {
      this.actions.setBoundingBox(box);
    },

    highlightCluster(clusterKey) {
      this.actions.highlightCluster(clusterKey);
    },
  },
});
