import { bulkDeleteTasks } from 'apps/journeys/utils/api-requests/modal-actions';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import ErrorLogger from 'entities/helpers/error-log-helper';
import EverTrue from 'app';
import FormField from 'components/forms/form-field';
import { trackActionOnTask } from 'apps/journeys/utils/helpers/mixpanel';

/**
 * The BulkDeleteModal component renders a modal for confirming the bulk deletion of tasks from a journey.
 *
 * @param {Object} props - The props for the component.
 * @param {function} props.onClose - Callback function that closes the modal.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {Array} props.selectedTaskIds - Array of IDs for tasks selected for deletion.
 * @param {function} [props.optionalStateManagementFunction] - Optional function for managing external state, e.g., updating the parent component after deletion.
 */
const BulkDeleteModal = ({ onClose, isOpen, selectedTaskIds, optionalStateManagementFunction, fromTasks }) => {
  const handleBulkDelete = async (selectedTaskIds) => {
    try {
      const payload = { task_ids: selectedTaskIds };
      await bulkDeleteTasks(payload);
      if (!fromTasks) {
        optionalStateManagementFunction('bulkRemoveTasks', { taskIds: selectedTaskIds });
        trackJourneysAction('bulk_delete_step');
      }
      onClose();
      if (fromTasks) {
        EverTrue.Alert.success(`Delete request successful. Please refresh the page to continue.`);
        trackActionOnTask('bulk_delete_task');
      }
    } catch (error) {
      ErrorLogger.error('Error occurred during bulk delete', {
        extra: { error, page_name: 'BulkDeleteModal' },
        tags: { taskIds: JSON.stringify(selectedTaskIds) },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Delete Tasks"
        closeModal={onClose}
        onSubmit={() => {
          handleBulkDelete(selectedTaskIds);
        }}
        disableSubmit={false}
      />
      <ModalBody>
        <FormField
          label={
            fromTasks
              ? `${selectedTaskIds?.length} Tasks Selected`
              : `${selectedTaskIds?.length} Constituents (Tasks) Selected`
          }
          boldLabel={true}
          inlineLabel={false}
        >
          <span>Are you sure you want to delete the selected tasks?</span>
        </FormField>
      </ModalBody>
    </Modal>
  );
};
BulkDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedTaskIds: PropTypes.array.isRequired,
  optionalStateManagementFunction: PropTypes.func,
  fromTasks: PropTypes.bool,
};

export default BulkDeleteModal;
