import { useState, useEffect } from 'react';
import { PropTypes } from 'base/react-libs';

const SORT = {
  total: 0,
  Email: 1,
  Call: 2,
  Text: 3,
  Research: 4,
  LinkedIn: 5,
  'Print Mail': 6,
  ThankView: 7,
  Other: 8,
  completed: 9,
};

const TaskCountTable = ({ data }) => {
  const [counts, setCounts] = useState();
  const [labels, setLabels] = useState();

  useEffect(() => {
    const sorted = [];

    Object.entries(data).forEach((entry) => sorted.push(entry));
    sorted.sort((a, b) => SORT[a[0]] - SORT[b[0]]);

    const countVals = sorted.map(([name, count]) => {
      return (
        <td key={'td' + name} className="bold-text">
          {count}
        </td>
      );
    });

    const labelVals = sorted.map(([name, _]) => {
      const label = name.charAt(0).toUpperCase() + name.slice(1);
      if (label === 'Email' || label === 'Call') {
        return <td key={name}>{label + 's'}</td>;
      }
      return <td key={name}>{label}</td>;
    });

    setCounts(countVals);
    setLabels(labelVals);
  }, [data]);

  return (
    <table className="task-counter-card--details">
      <tbody>
        <tr>{counts}</tr>
        <tr>{labels}</tr>
      </tbody>
    </table>
  );
};

TaskCountTable.propTypes = {
  data: PropTypes.object,
};

export default TaskCountTable;
