import { useCallback, useRef, useState } from 'react';
import { setBusinessLogo } from '../../../entities/helpers/thankview-helper';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@evertrue/et-components';
import EverTrue from 'app';
import PropTypes from 'prop-types';

export const FileUploadWithPreview = ({ businessId, clientId, clientSecret, onFileSelect, initialImageUrl }) => {
  const [preview, setPreview] = useState(initialImageUrl || null);
  const fileInputRef = useRef(null);

  const handleFile = useCallback(
    (file) => {
      if (file) {
        onFileSelect(file);
        const reader = new FileReader();
        reader.onload = async (e) => {
          setPreview(e.target.result);
          try {
            const formData = new FormData();
            formData.append('logo_image', file);
            await setBusinessLogo(businessId, formData, clientId, clientSecret);
            EverTrue.Alert.success('Logo updated successfully');
          } catch (error) {
            EverTrue.Alert.error('Failed to update logo');
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [businessId, clientId, clientSecret, onFileSelect]
  );

  const onDrop = useCallback((acceptedFiles) => handleFile(acceptedFiles[0]), [handleFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.svg'] },
    maxSize: 5 * 1024 * 1024, // 5MB
  });

  const handleRemove = (e) => {
    e.stopPropagation();
    setPreview(null);
    onFileSelect(null);
  };

  return (
    <div className="upload-container" {...getRootProps()} tabIndex="0" role="button" aria-label="Upload logo file">
      <input {...getInputProps()} ref={fileInputRef} aria-label="File input" />
      {preview ? (
        <div className="preview-container">
          <img className="preview-image" src={preview} alt="Logo preview" />
          <button className="remove-button" onClick={handleRemove} aria-label="Remove uploaded file">
            ✖
          </button>
        </div>
      ) : (
        <>
          <Icon className="upload-icon" aria-hidden="true" iconKit="tv" icon="uploadvector" />
          <p className="upload-text">
            {isDragActive ? 'Drop the file here' : 'Drag and Drop file here or Choose file'}
          </p>
        </>
      )}
      <p className="supported-formats">Supported formats: jpg, png, svg | Maximum size: 5MB</p>
    </div>
  );
};

FileUploadWithPreview.propTypes = {
  businessId: PropTypes.number.isRequired,
  clientId: PropTypes.number,
  clientSecret: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
  initialImageUrl: PropTypes.string,
};
