import PropTypes from 'prop-types';
import MyPortfolioProspects from 'apps/major-gifts/components/my-portfolio-prospects';

const MyPortfolioController = ({ type, subtype }) => {
  if (type === 'prospects') {
    const viewType = subtype || 'stage';
    return (
      <div>
        <MyPortfolioProspects type={viewType} />
      </div>
    );
  }
  return <div />;
};

MyPortfolioController.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
};

export default MyPortfolioController;
