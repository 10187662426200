import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDefaultSettings } from 'entities/helpers/thankview-helper';
import useUserAuthentication from './useUserAuthentication';

const ThankviewSettingsContext = createContext();

export const ThankviewSettingsProvider = ({ children }) => {
  const [defaultUserSettings, setDefaultUserSettings] = useState({
    button_enabled: false,
    show_in_email: false,
    button_text: '',
    button_url: '',
    logo_enabled: false,
    logo_url: '',
  });
  const { clientId = '' } = useUserAuthentication();
  const [loadingDefaultSettings, setLoading] = useState(true);

  useEffect(() => {
    const getAndSetUserSettings = async () => {
      if (clientId) {
        setLoading(true);
        const { data } = await getUserDefaultSettings(clientId.data.client_id, clientId.data.client_secret);
        setDefaultUserSettings({
          updated_at: data.updated_at,
          created_at: data.created_at,
          button_enabled: data?.personal_video_default_button_enabled,
          show_in_email: data?.personal_video_default_show_in_email,
          button_text: data?.personal_video_default_button_text,
          button_url: data?.personal_video_default_button_url,
          logo_enabled: data?.personal_video_default_logo_enabled,
          logo_url: data?.personal_video_default_logo_path,
        });
      }
      setLoading(false);
    };
    getAndSetUserSettings();
  }, [clientId]);

  return (
    <ThankviewSettingsContext.Provider value={{ defaultUserSettings, loadingDefaultSettings, setDefaultUserSettings }}>
      {children}
    </ThankviewSettingsContext.Provider>
  );
};

export const useThankviewSettingsContext = () => useContext(ThankviewSettingsContext);

ThankviewSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
