import EverTrue from 'app';
import EmailForm from 'components/emailer/email-form';
import { Loading, Button } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import useEmailer from 'components/emailer/hooks/use-emailer';
import GoogleDisclosure from 'components/emailer/google-disclosure';
import { EmailTemplatesProvider } from 'apps/outreach/email/templates/hooks/email-templates-context';
import { createDraft } from 'apps/outreach/utils/draft-email-api';
import { useGlobalOutreachProvider } from 'apps/outreach/contexts/global-outreach-context';
import { updateDraft } from 'apps/outreach/utils/draft-email-api';
import { useRef } from 'react';

const EmailerController = ({
  taskId,
  name,
  outreachType,
  recipients = [],
  handleClose,
  setDrawerTitle,
  contact,
  outreach,
  closeDrawer = () => {},
}) => {
  const { getAllDrafts, deleteDraft, deleteScheduledSendById } = useGlobalOutreachProvider();
  const { loading, emailerIsConnected, handleConnectEmail } = useEmailer();
  const isSavingDraftRef = useRef(true);

  const handleSaveOrUpdateDraft = async (formData) => {

    if (outreach?.id) {
      await updateDraft(formData, outreach.id);
      if (getAllDrafts) getAllDrafts();
    } else {
      await createDraft(formData);
      if (getAllDrafts) getAllDrafts();
    }
  };

  const confirmDeletion = async (headline, content) => {
    return new Promise((resolve) => {
      EverTrue.Alert.confirm(
        {
          headline,
          content,
          buttonLabel: 'Discard',
        },
        (confirmation) => resolve(confirmation)
      );
    });
  };

  const handleDelete = async (id, handleDelete, successMessage, showConfirmationModal, headline, content) => {
    if (showConfirmationModal) {
      const confirmed = await confirmDeletion(headline, content);
      if (!confirmed) return;
    }
    await handleDelete(id);
    closeDrawer();
    if (showConfirmationModal) {
      EverTrue.Alert.success(successMessage);
    }
  };

  const handleDeleteOutreachEmail = async (id, outreachType, showConfirmationModal = true) => {
    if (outreachType === 'Draft') {
      await handleDelete(
        id,
        deleteDraft,
        'Draft deleted successfully.',
        showConfirmationModal,
        'Discard Draft',
        'Are you sure you want to discard the draft?'
      );
      isSavingDraftRef.current = false;
    } else if (outreachType === 'Scheduled') {
      await handleDelete(
        id,
        deleteScheduledSendById,
        'Scheduled Send deleted successfully.',
        showConfirmationModal,
        'Discard Scheduled Email',
        'Are you sure you want to discard the scheduled email?'
      );
    }
  };

  if (loading) return <Loading spinner_size="small" />;

  if (emailerIsConnected)
    return (
      <EmailTemplatesProvider>
        <EmailForm
          taskId={taskId}
          closeDrawer={closeDrawer}
          name={name}
          outreachType={outreachType}
          contact={contact}
          outreach={outreach}
          recipients={outreach?.email_recipients || recipients}
          handleClose={handleClose}
          setDrawerTitle={setDrawerTitle}
          handleSaveOrUpdateDraft={handleSaveOrUpdateDraft}
          handleDeleteOutreachEmail={handleDeleteOutreachEmail}
          isSavingDraftRef={isSavingDraftRef}
        />
      </EmailTemplatesProvider>
    );
  if (emailerIsConnected !== null)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="oauth-controller">
          <h1>Connect Your Account</h1>
          <p>Link your account to start using email services here</p>
          <div className="email-disconnect-alert">
            After successfully connecting your email account for the first time, your account will be disconnected every
            31 days for security reasons. Please reconnect to continue using your email in EverTrue.
          </div>
          <div>
            <Button className="oauth-provider--btn" onClick={handleConnectEmail}>
              Connect
            </Button>
          </div>
        </div>
        <GoogleDisclosure style={{ textAlign: 'center', padding: '0 45px 50px' }} />
      </div>
    );
  return (
    <div className="oauth-controller">
      <h2 style={{ textAlign: 'center' }}>There was a problem fetching your credentials.</h2>
      <p>Please contact genius@evertrue.com if the problem persists.</p>
    </div>
  );
};

EmailerController.propTypes = {
  taskId: PropTypes.number,
  recipients: PropTypes.array,
  outreachType: PropTypes.string,
  outreach: PropTypes.object,
  name: PropTypes.string,
  handleClose: PropTypes.func,
  setDrawerTitle: PropTypes.func,
  contact: PropTypes.object,
  closeDrawer: PropTypes.func,
};

export default EmailerController;
