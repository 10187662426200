export const updateEmailBody = (emailBody) => {
  let result = emailBody
    .replace(/<p(.*?)>/gi, (match, p1) => {
      if (p1.includes('style')) {
        return `<div${p1.replace(/style="(.*?)"/i, (match, styles) => {
          let updatedStyles = styles.replace(/text-align:\s*justify;?/i, '').trim();

          updatedStyles = updatedStyles.replace(/;\s*$/, '');
          return `style="${updatedStyles ? updatedStyles + ';' : ''}"`;
        })}>`;
      } else {
        return `<div${p1}>`;
      }
    }) // p tags contain a default margin in most email clients, 'text-align: justify;' from the style attribute introduces extra spacing between letters in email clients
    .replaceAll('</p>', '</div>')
    .replace(/<h2(.*?)>/gi, (match, p1) => {
      if (p1.includes('style')) {
        return `<div${p1.replace(/style="(.*?)"/i, (match, styles) => {
          return `style="${styles.trim().endsWith(';') ? styles : styles + ';'} font-size:19px;"`;
        })}>`;
      } else {
        return `<div${p1} style="font-size:19px;">`;
      }
    });
  return result;
};
