import { AdvancedCombobox } from '@evertrue/et-components';
import { useClarityProvider } from '../controllers/clarity-context';
import ClarityCard from './clarity-card';
import ClarityEmpty from './clarity-empty';
/**
 * Displays a filterable grid of dashboard cards organized by category
 *
 * Features:
 * - Category-based filtering via dropdown
 * - Grouped display of dashboards
 * - Empty states for no dashboards or empty categories
 *
 * Uses ClarityContext for dashboard data
 */
const ClarityList = () => {
  const { dashboardCollection, selectedCategory, setSelectedCategory, filteredCategoryOptions } = useClarityProvider();

  return (
    <>
      <div className="clarity-filter">
        <AdvancedCombobox
          onChange={setSelectedCategory}
          value={selectedCategory}
          options={filteredCategoryOptions}
          multiple={false}
        />
      </div>

      {Object.keys(dashboardCollection).length ? (
        <div className="clarity-list">
          {Object.entries(dashboardCollection).map(([sectionTitle, items]) => (
            <div key={sectionTitle} className="clarity-section">
              <h3>{sectionTitle}</h3>
              <div className="clarity-cards">
                {items.length > 0 ? (
                  items.map((item) => <ClarityCard board={item} key={item.name} />)
                ) : (
                  <div className="clarity-empty-category">
                    <p>
                      You don't have access to any dashboards in this category. Please reach out to
                      customersuccess@evertrue.com or your CSM to learn more.
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ClarityEmpty />
      )}
    </>
  );
};

export default ClarityList;
