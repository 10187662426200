import EverTrue from 'app';
import PropTypes from 'prop-types';
import ProfileStore from 'apps/profile/stores/profile-store';
import ProfileSource from 'apps/profile/sources/profile-source';
import { ModalTrigger2, Link, Icon, ButtonTooltip, Popover2 } from '@evertrue/et-components';
import GoogleSearch from 'apps/profile/components/google-search';
import EventbriteEmailsModal from 'apps/contact/components/profile/eventbrite-emails-modal';
import EventbriteMatchFetcher from 'apps/profile/fetchers/eventbrite-match-fetcher';
import IsGated from 'components/is-gated';
import Decorator from 'clientDecorator';
import Loading from 'components/elements/loading';
import FacebookMatchManage from 'apps/facebook/components/facebook-match-manage';
import { useFluxStore } from '@evertrue/et-flux';

const activeColumnMapState = () => ({ loading_lids: ProfileStore.getLoadingLids() });

const SocialActions = ({ contact }) => {
  const { has_facebook_match } = useFluxStore(ProfileStore, () => ({
    has_facebook_match: ProfileStore.hasFacebookMatch(contact.id),
  }));
  const { loading_lids } = useFluxStore(ProfileStore, activeColumnMapState);

  const handleActionClick = () => {
    EverTrue.track.setReferrer('profile_social');
  };

  const renderLinkedinSearchPopover = (name) => {
    const link = `https://www.linkedin.com/search/results/all/?keywords=${name.replace(' ', '%20')}`;
    return (
      <div className="social-actions--popover">
        <Link title={`Search for ${name} on LinkedIn`} href={link} target="_blank">
          {`Search for "${name}" on LinkedIn`}
        </Link>
      </div>
    );
  };

  const renderFacebookManageButton = () => (
    <div className="social-actions--button" role="button">
      <Icon icon={has_facebook_match ? 'facebook-box icon-facebook-brand' : 'facebook-box'} />
      Manage Facebook Page Match
    </div>
  );

  const name = Decorator.Contacts.getFullName(contact);

  return (
    <div className="social-actions loading-container">
      {loading_lids && <Loading />}

      <div onClick={handleActionClick}>
        {/* GOOGLE */}
        <Popover2
          trigger={
            <div className="social-actions--button">
              <span className="google-search--icon" />
              Google "<span className="fs-exclude-data">{name}</span>
              ..."
              <Icon icon="chevron-down" className="social-actions--button-arrow" />
            </div>
          }
        >
          <GoogleSearch contact={contact} />
        </Popover2>

        {/* LINKEDIN */}
        <Popover2
          trigger={
            <div className="social-actions--button">
              <Icon icon="linkedin-box icon-linkedin-brand" />
              Find on LinkedIn
              <Icon icon="chevron-down" className="social-actions--button-arrow" />
            </div>
          }
        >
          {renderLinkedinSearchPopover(name)}
        </Popover2>

        {/* FACEBOOK */}
        {!has_facebook_match ? (
          <ButtonTooltip
            triggerClassName="facebook-no-match-button"
            children={
              <div className="facebook-no-match">
                This person isn't matched to any Facebook Pages. Visit the Facebook tab to manually match engagers.
              </div>
            }
            trigger={renderFacebookManageButton()}
          />
        ) : (
          <ModalTrigger2
            closeOnOutsideClick={false}
            trigger={({ open }) => (
              <div className="social-actions--button" role="button" onClick={open}>
                <Icon icon={has_facebook_match ? 'facebook-box icon-facebook-brand' : 'facebook-box'} />
                Manage Facebook Page Match
              </div>
            )}
            children={({ close }) => {
              return (
                <div style={{ width: '568px' }}>
                  <FacebookMatchManage contact={contact} requestHide={close} />
                </div>
              );
            }}
          />
        )}

        {/* EVENTBRITE */}
        <IsGated feature="events_eventbrite">
          <EventbriteMatchFetcher has_eventbrite={true} contact_id={Number(contact.id)}>
            {(eventBriteProps) => (
              <ModalTrigger2
                closeOnOutsideClick={false}
                trigger={({ open }) => (
                  <div className="social-actions--button" onClick={open}>
                    <Icon
                      icon="eventbrite icon-eventbrite-brand"
                      className={!eventBriteProps.has_eventbrite_match ? 'unmatched' : ''}
                    />
                    Manage Eventbrite Emails
                  </div>
                )}
                children={({ close }) => {
                  const { refresh, engagementStatsByEmail, profiles, error } = eventBriteProps;
                  return (
                    <EventbriteEmailsModal
                      close={close}
                      contact={contact}
                      engagementStatsByEmail={engagementStatsByEmail}
                      profiles={profiles}
                      error={error}
                      refreshProfiles={() => {
                        refresh();
                        return ProfileSource.fetch(contact.id);
                      }}
                    />
                  );
                }}
              />
            )}
          </EventbriteMatchFetcher>
        </IsGated>
      </div>
    </div>
  );
};

SocialActions.propTypes = {
  contact: PropTypes.object,
};

export default SocialActions;
