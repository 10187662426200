const _ = require("underscore").default;
const FeatureStore = require("apps/layout/stores/feature-store").default;

const TIME_PERIOD_OPTIONS = [
  { label: "Yesterday", value: 1 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Custom Date Range", value: "custom" },
];

// Used to render and query each type of data export
// label - Displayed in the UI as the customer friendly name
// job - Chronometer Job Name to save and process scheduled exports
// socket - name of websocket to subscribe to for progress
// gate - name or function of a gate if this should be gated
// getQuery - returns data that will be send to the export request.

// DON'T FORGET -> Add action for socket to socket-source!

const ExportTypeConfig = {
  interaction: {
    label: "Interactions",
    job: "com.evertrue.chronometer.jobs.InteractionExportJob",
    socket: "interaction_export_progress",
    export_options: TIME_PERIOD_OPTIONS,
    gate() {
      return FeatureStore.hasInteractionExports();
    },
    getQuery(start_date, end_date) {
      const date_query = _.compactObject({ gt: start_date, lt: end_date });
      return {
        params: { include_deleted: true },
        query: {
          name: "interactions",
          search: {
            must: [{ updated_at: date_query }, { update_source: { match: "EVERTRUE" } }],
            must_not: [
              { "interaction_type.untouched": { match: "EverTrue Comment" } },
              { "interaction_type.untouched": { match: "DXO Ask" } },
            ],
          },
        },
      };
    },
  },

  volunteer_interaction: {
    label: "Volunteer Interactions",
    url_export_type: "interaction", // we want the manual export url to use interaction not volunteer_interaction
    job: "com.evertrue.chronometer.jobs.VolunteerInteractionExportJob",
    socket: "interaction_export_progress",
    export_options: TIME_PERIOD_OPTIONS,
    gate() {
      return FeatureStore.hasInteractionExports() && FeatureStore.hasFeature("volunteers");
    },
    getQuery(start_date, end_date) {
      const date_query = _.compactObject({ gt: start_date, lt: end_date });
      return {
        params: { include_deleted: true, type: "volunteer_interaction" },
        query: {
          name: "volunteer interactions",
          search: {
            must: [{ updated_at: date_query }, { update_source: { match: "EVERTRUE" } }],
            must_not: [
              { "interaction_type.untouched": { match: "EverTrue Comment" } },
              { "interaction_type.untouched": { match: "DXO Ask" } },
            ],
          },
        },
      };
    },
  },

  prospect_status: {
    label: "Prospect Moves",
    job: "com.evertrue.chronometer.jobs.ProspectMovesExportJob",
    socket: "prospect_status_export_progress",
    export_options: TIME_PERIOD_OPTIONS,
    gate() {
      // should only show when "write_assignee" is on and RM assignments is NOT on
      return FeatureStore.hasFeature("write_assignee") && !FeatureStore.hasFeature("rm_assignments");
    },
    getQuery(start_date) {
      return { params: { since: start_date }, query: { name: "prospect_moves" } };
    },
  },

  assignment: {
    label: "Volunteer Assignments",
    job: "com.evertrue.chronometer.jobs.AssignmentExportJob",
    socket: "assignment_export_progress",
    gate: "volunteers",
    export_options: [{ value: "full", label: "Full Export" }].concat(TIME_PERIOD_OPTIONS),
    getQuery(start_date, end_date) {
      const date_query = _.compactObject({ gt: start_date, lt: end_date });
      return {
        query: {
          name: "assignments",
          search: {
            must: [{ updated_at: date_query }],
          },
        },
      };
    },
  },

  assignment_team: {
    label: "Relationship Management Assignments",
    job: "com.evertrue.chronometer.jobs.AssignmentTeamExportJob",
    // socket is same as vol assignments, so should be okay but could get weird if there is a vol assignments and rm assignments running at same time
    socket: "assignment_export_progress",
    gate: "rm_assignments",
    url_export_type: "assignment",
    export_options: [{ value: "full", label: "Full Export" }].concat(TIME_PERIOD_OPTIONS),
    getQuery(start_date, end_date) {
      const date_query = _.compactObject({ gt: start_date, lt: end_date });
      return {
        params: {
          type: "assignment_team",
        },
        query: {
          name: "team_assignments",
          search: {
            must: [{ updated_at: date_query }],
          },
        },
      };
    },
  },

  proposal: {
    label: "Proposals",
    job: "com.evertrue.chronometer.jobs.ProposalExportJob",
    socket: "proposal_export_progress",
    export_options: TIME_PERIOD_OPTIONS,
    gate() {
      return FeatureStore.hasFeature("proposal_writes");
    },
    getQuery(start_date, end_date) {
      const date_query = _.compactObject({ gt: start_date, lt: end_date });
      return {
        params: { include_deleted: true },
        query: {
          name: "proposals",
          search: {
            must: [{ updated_at: date_query }, { update_source: { match: "EVERTRUE" } }],
          },
        },
      };
    },
  },
  task: {
    label: "Tasks",
    job: "com.evertrue.chronometer.jobs.TaskExportJob",
    socket: "",
    export_options: [{ value: "full", label: "Full Export" }],
    getQuery(
      journey_task_only = false,
      include_completed = true, 
      include_overdue = true, 
      include_skipped = false) {
      return {
        params: { 
          journey_task_only,
          include_completed,
          include_overdue,
          include_skipped,
        },
        query: {
          name: "tasks",
          search: {
            must: [],
          },
        },
      }
    },
  },
};
export default _.mapObject(ExportTypeConfig, (item, key) => _.extend({}, item, { key }));
