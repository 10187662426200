import { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@evertrue/et-components";

class SelectedItemsToolbar extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    partial: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
  };
  render() {
    const { checked, onChange, partial, children, disabled, style, className } = this.props;
    return (
      <div className="selected-items-toolbar" style={style}>
        <Checkbox
          label="All items in table"
          checked={checked}
          onChange={onChange}
          partial={partial}
          disabled={disabled}
          className={className || ""} 
        />
        {children}
      </div>
    );
  }
}

export default SelectedItemsToolbar;
