import { createStore } from '@evertrue/et-flux';
import FullContactSource from 'apps/contact/sources/full-contact-source';

const FullContactStore = createStore('FullContactStore', {
  getInitialState() {
    return {
      full_contacts: {},
      is_loading: false,
    };
  },

  registerActions() {
    this.on(FullContactSource.actions.fetchedFullContact, this.respondToFetchedFullContact);
    this.on(FullContactSource.actions.loading, (loading) => {
      this.setState({ is_loading: loading });
    });
  },

  respondToFetchedFullContact(contact) {
    const full_contacts = { ...this.state.full_contacts };
    full_contacts[contact.id] = contact;
    this.setState({ full_contacts });
  },

  api: {
    getFullContact(id) {
      return this.state.full_contacts?.[id];
    },

    getIsLoading() {
      return this.state.is_loading;
    },
  },
});

export default FullContactStore;
