import _ from 'underscore';
import classNames from 'classnames';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Icon, Link } from '@evertrue/et-components';
import ActionMenu2 from 'components/controls/action-menu2';

class PageHeader extends Component {
  static propTypes = {
    image: PropTypes.any,
    title: PropTypes.any,
    subtitle: PropTypes.node,
    actions: PropTypes.node,
    showBackButton: PropTypes.bool,
    backButtonHref: PropTypes.string,
    disableActions: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
  };

  handleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      EverTrue.Navigator('/contact', true);
    }
  };

  render() {
    const show_back = this.props.showBackButton || this.props.backButtonHref;

    return (
      <div className={classNames('page-header clearfix', this.props.className)}>
        <div className="page-header--wrapper">
          {show_back && (
            <Link
              href={this.props?.backButtonHref}
              title="Go back"
              className={'page-header--back'}
              onClick={!this.props.backButtonHref ? this.handleBack : _.noop}
            >
              <Icon icon="chevron-left" size={2} />
            </Link>
          )}

          <div style={{ display: 'flex' }} className={classNames('page-header--label', { 'has-back': show_back })}>
            {this.props.image && <div className="page-header--image">{this.props.image}</div>}
            <div>
              <h1 className="page-header--heading" tabIndex={-1}>
                {this.props.title}
              </h1>
              {this.props.subtitle && <div className="page-header--subtitle">{this.props.subtitle}</div>}
            </div>
          </div>

          {this.props.actions && (
            <div className="page-header--state">
              <ActionMenu2 disabled={this.props.disableActions}>{this.props.actions}</ActionMenu2>
            </div>
          )}

          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PageHeader;
