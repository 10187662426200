import * as Yup from 'yup';

export const AddToFolderSchema = Yup.object().shape({
  action: Yup.string().required('Please select an action'),
  folderName: Yup.string().when('action', {
    is: 'create',
    then: (schema) =>
      schema.required('Folder name is required').max(30, 'Folder name must be 30 characters or less').trim(),
  }),
  selectedFolders: Yup.array().when('action', {
    is: 'existing',
    then: (schema) => schema.min(1, 'Please select at least one folder').required('Please select folders'),
  }),
});
