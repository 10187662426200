import moment from 'moment';
import { useFluxStore } from '@evertrue/et-flux';
import ProfileCardHeader from 'apps/profile/components/profile-card-header';
import ProposalSearchSource from 'apps/proposals/sources/proposal-search-source';
import ProposalSearchStore from 'apps/proposals/stores/proposal-search-store';
import Loading from 'components/elements/loading';
import { Link } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const proposalStoreMapState = () => ({
  proposals: ProposalSearchStore.getProposals(),
  loading: ProposalSearchStore.getLoading(),
});

const ProposalSummary = ({ contact = {} }) => {
  const { proposals, loading } = useFluxStore(ProposalSearchStore, proposalStoreMapState);

  useEffect(() => {
    if (contact?.id) {
      ProposalSearchSource.sort('created_date', true, true);
      ProposalSearchSource.queryForContact(contact.id);
    }
    return () => {
      ProposalSearchSource.setContact();
    };
  }, [contact?.id]);

  const proposalItems = proposals?.items;
  const proposal = proposalItems?.[0] || {};
  const proposal_amount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    proposal.ask_amount
  );

  return (
    <div className="proposal-summary">
      <ProfileCardHeader title="Recent Proposal" className="contact-profile--card-header-small">
        {proposalItems?.length && (
          <Link title="view all proposals for this contact" href={`/contact/${contact.id}/proposals`}>
            View All
          </Link>
        )}
      </ProfileCardHeader>

      <div className="proposal-summary--wrapper">
        {loading ? (
          <Loading />
        ) : Object.keys(proposal).length ? (
          <div>
            <div className="proposal-summary--amount">{proposal_amount}</div>
            <div className="text-label">
              Ask Amount {proposal.ask_date && `(${moment(proposal.ask_date).format('MMM, DD YYYY')})`}
            </div>
            <div>
              <Link title={`proposal ${proposal.title}`} href={`proposal/${proposal.id}`}>
                {proposal.title}
              </Link>
            </div>
            {proposal.status}
          </div>
        ) : (
          <div className="proposal-summary--empty">No current proposals</div>
        )}
      </div>
    </div>
  );
};

ProposalSummary.propTypes = {
  contact: PropTypes.object,
};

export default ProposalSummary;
