import _ from 'underscore';
import Decorator from 'clientDecorator';
import CommentText from 'apps/interactions/components/lazy-comment-text';
import InteractionIcon from 'apps/interactions/components/interaction-icon';
import InteractionSubstantiveLabel from 'apps/interactions/components/interaction-substantive-label';
import HighlightText from 'components/formatting/highlight-text';
import InteractionUtils from 'apps/interactions/interaction-utils';
import { Link, Icon } from '@evertrue/et-components';
import CommentMixin from 'mixins/comment-mixin';
import PropTypes from 'prop-types';
import ThankViewInteractionsDropdown from 'apps/interactions/components/interaction-thankview-dropdown';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const Interaction = ({ flags, props, children }) => {
  const {
    interaction: {
      text = '',
      interaction_type = '',
      label = '',
      solicitor = {},
      summary = '',
      secondary_target = [],
      target_id = 0,
      contact = {},
      mentions = [],
      pinned,
      substantive,
      contact_id = 0,
    } = {},
    interaction,
    showSummary = true,
    showFullText,
    showContact = true,
    showMessage = true,
    highlight,
    contactId,
    className,
    limit,
  } = props;

  const is_gt_comment = interaction_type === 'EverTrue Comment';
  const is_tv_interaction = interaction_type === 'ThankView - Video';
  let tvMessage = '';
  if (is_tv_interaction) tvMessage = interaction.text.split('\r\n\r\n\r\n\r\n')[0];
  const isEmptyContent = _.isEmpty(label) && _.isEmpty(solicitor) && _.isEmpty(summary) && _.isEmpty(text.trim());
  const secondaries = !!secondary_target.length;
  const isSecondaryInteraction = !showContact && target_id !== contactId;
  const fullName = Decorator.Contacts.getFullName(contact);

  return (
    <div className={('interaction', className)}>
      <div className="interaction--image">
        <InteractionIcon isSecondary={isSecondaryInteraction} interaction={interaction} />
      </div>
      <div className={`interaction--content ${!showFullText && 'is-snippet'}`}>
        <div className="interaction--main-content">
          <div className={`interaction--body", ${is_gt_comment && 'is-gt-comment'} ${isEmptyContent && 'is-empty'}`}>
            {pinned && (
              <div className="interaction-pinned-tag">
                <Icon icon="pin-icon" />
                <span>&nbsp;Pinned to the profile</span>
              </div>
            )}
            <div className="interaction--header clearfix">
              <span className="interaction--details">
                {substantive && <InteractionSubstantiveLabel />}
                <span className="interaction-meta--type fs-exclude-data">{interaction_type}</span>
                {!_.isEmpty(contact) && showContact && (
                  <span>
                    <strong className="text-muted"> / </strong>
                    <Link className="fs-exclude-data" href={`/contact/${contact_id}`} title={`profile of ${fullName}`}>
                      {fullName}
                    </Link>
                    {!!secondaries && <span> +{secondaries}</span>}
                  </span>
                )}
                {isSecondaryInteraction && <span style={{ fontStyle: 'italic' }}> Non-primary</span>}
              </span>
              {children && children}
            </div>
            {showSummary && summary && (
              <div className="interaction--summary">
                <HighlightText className="fs-exclude-data" string={summary} query={highlight} />
              </div>
            )}
            {showMessage && (
              <div className="interaction--text fs-exclude-data">
                {is_gt_comment ? (
                  <CommentText
                    text={InteractionUtils.renderText(
                      interaction,
                      showFullText,
                      limit,
                      flags.interactionDescriptionRichTextEditor
                    )}
                    highlight={highlight}
                    mentions={mentions}
                  />
                ) : is_tv_interaction && window.location.pathname === '/interaction' ? (
                  CommentMixin.getSnippet(tvMessage, 200)
                ) : is_tv_interaction ? (
                  <ThankViewInteractionsDropdown interaction={interaction} />
                ) : (
                  <HighlightText
                    string={InteractionUtils.renderText(
                      interaction,
                      showFullText,
                      limit,
                      flags.interactionDescriptionRichTextEditor
                    )}
                    query={highlight}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Interaction.propTypes = {
  interaction: PropTypes.object,
  highlight: PropTypes.string,
  showSummary: PropTypes.bool,
  showContact: PropTypes.bool,
  showMessage: PropTypes.bool,
  showFullText: PropTypes.bool,
  contactId: PropTypes.number,
  props: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  limit: PropTypes.number,
  flags: PropTypes.object,
};

export default withLDConsumer()(Interaction);
