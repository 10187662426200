import { formatDateToLocaleTimeString } from 'apps/journeys/utils/helpers/dates';
import { PropTypes } from 'base/react-libs';

export const meetingPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  start_at: PropTypes.string,
  end_at: PropTypes.string,
  name: PropTypes.string,
  trip_name: PropTypes.string,
  contact_details: PropTypes.string,
});

// Extract meeting card component
const TodaysMeetingCard = ({ meeting }) => (
  <div key={meeting.id} className="meetings-details">
    <div className="meeting-time-name">
      <div className="meeting-time">
        <div className="meeting-start-time">{formatDateToLocaleTimeString(meeting.start_at)}</div>
        <div className="meeting-end-time">
          <span>&nbsp;-&nbsp;</span>
          {formatDateToLocaleTimeString(meeting.end_at)}
        </div>
      </div>
      <div className="meeting-name">{meeting.name}</div>
    </div>
    <div className="meeting-participants">{meeting.contact_details}</div>
    <div className="meeting-trip-name">{meeting.trip_name}</div>
  </div>
);

TodaysMeetingCard.propTypes = {
  meeting: meetingPropType.isRequired,
};

export default TodaysMeetingCard;
