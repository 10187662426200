module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   MapStore = require('apps/map/stores/map-store').default
   MapSource = require("apps/map/sources/map-source").default
   {getQueryFromBox} = require("apps/map/map-utils")
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   {createFactory} = require("base/new-utils")
   MapWithClusters = createFactory require("apps/map/components/map-with-clusters").default
   ClusterContactSource = require("apps/map/sources/cluster-contact-source").default
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   MapAddressFilter = createFactory(require("apps/map/components/map-address-filter").default)
   Decorator = require("clientDecorator")

   createComponent "ProspectMapController",
      propTypes:
         mapped_prospects: ReactLibs.PropTypes.object
         address_types: ReactLibs.PropTypes.array
         user_id: ReactLibs.PropTypes.number

      registerStores: ->
         @on MapStore, ->
            zoom: MapStore.getZoom()
            clusters: MapStore.getClusters()
            loading_clusters: MapStore.getLoadingClusters()
            bounding_box: MapStore.getBoundingBox()

      componentWillUnmount: ->
         SelectedContactsSource.clear()

      componentDidMount: ->
         @loadMap()

      componentDidUpdate: (prevProps, prevState) ->
         if (!_.isEqual(prevProps.user_id, @props.user_id)) or
            (!_.isEqual(prevProps.prospect_query_param, @props.prospect_query_param)) or
            (!prevState.bounding_box and !_.isEmpty(@state.bounding_box))
               @loadMap()

      handleAddressFilter: (filters) ->
         address_types = _.pluck filters[0]?.value, "value"
         MapSource.fetchClusters(@state.zoom, {query: @getQuery(@state.bounding_box, address_types)})
         @handleContactFilter(@state.bounding_box, address_types)

      handleMapInit: (bounds) ->
         MapSource.setBoundingBox(bounds)

      handleMapChange: (zoom, bounds) ->
         MapSource.fetchClusters(zoom, {query: @getQuery(bounds, @props.address_types)})
         MapSource.setBoundingBox(bounds)
         @handleContactFilter(bounds)

      handleContactFilter: (bounds, address_types) ->
         address_types = address_types || @props.address_types
         SelectedContactsSource.clear()
         MyPortfolioSource.startFetchForMappedProspects({bounds: bounds, address_types: address_types})

      handleClusterOpen: (key, cluster) ->
         ClusterContactSource.fetch(key, @getQuery(cluster.geobox, @props.address_types))

      getQuery: (bounds, address_types) ->
         data =
            bounds: getQueryFromBox(bounds)
            address_types: address_types
            all_columns: @props.all_columns
            active_columns: @props.active_columns
         Decorator.MyPortfolio.getMapQuery(@props.user_id, data)

      loadMap: ->
         return if !@state.bounding_box
         @handleContactFilter(@state.bounding_box)
         MapSource.fetchClusters(@state.zoom, {query: @getQuery(@state.bounding_box, @props.address_types)})

      render: ->
         div className: "map-search-controller",
            MapWithClusters
               clusters: @state.clusters
               loading: @state.loading_clusters
               defaultBounds: @state.bounding_box
               onMapInit: @handleMapInit
               onMapChange: @handleMapChange
               onClusterOpen: @handleClusterOpen

               MapAddressFilter
                  show_filter_text: true
                  selected_types: @props.address_types
                  onApply: (filters) =>
                     @handleAddressFilter(filters)
                  onSelectType: (address_types) ->
                     MyPortfolioSource.setAddressTypes(address_types)
