import { useState, useEffect } from 'react';
import { fetchFromGraphql } from 'entities/helpers/gqlCall';

const solicitorQuery = `
  query {
    allSolicitors {
    name
    contact_id
    }
  }
`;

const useFetchProposalSolicitor = () => {
  const [solicitorsData, setSolicitorsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllProposalSolicitors = async () => {
      setLoading(true);
      const params = {};
      const { allSolicitors } = await fetchFromGraphql(solicitorQuery, params, 'allSolicitors');
      setSolicitorsData(allSolicitors);
      setLoading(false);
    };

    fetchAllProposalSolicitors();
  }, []);

  return {
    loading,
    solicitorsData,
  };
};

export default useFetchProposalSolicitor;
