import { useState, useEffect, useCallback } from 'react';
import Api from 'entities/helpers/api';
import _ from 'underscore';
import { SAVED_SEARCH_SEGMENT_FILTERS } from 'apps/filters/featured-segments-default';
import EverTrue from 'app';

export const useSegments = () => {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [groupedSegments, setGroupedSegments] = useState([]);

  // Load segments from the API
  const loadSegments = useCallback(() => {
    setLoading(true);
    Api.CONTACTS.LISTS.get({
      params: { limit: 1000 },
    }).then((resp) => {
      const loadedSegments = _.where(resp.items, { type: 'dynamic' });
      setSegments(loadedSegments);
      setLoading(false);
    });
  }, []);

  // Convert loadPreferences to useCallback
  const loadPreferences = useCallback(async () => {
    const data = await Api.EMS.PREFS.get();
    const prefs = data?.preferences || [];
    setPreferences(prefs);
  }, []);

  // Update the useEffect to include both loadPreferences and loadSegments
  useEffect(() => {
    loadPreferences();
    loadSegments();
  }, [loadPreferences, loadSegments]);

  // Fetch recently viewed segments
  const loadRecentlyViewed = useCallback(() => {
    Api.DNA.SETTING.get({
      urlArgs: { setting_key: 'GT_recent_lists' },
      disableAlerts: true,
      params: { user_id: 'me', oid: null },
    }).then((resp) => {
      const recent = _.uniq(resp.settings.GT_recent_lists.value.segments);
      const grouped = groupSegments(segments);
      setGroupedSegments(grouped);
      let ordered;
      if (!_.isEmpty(recent)) {
        ordered = _.compact(
          _.map(recent, (id) => {
            return _.findWhere(grouped, { value: id });
          })
        );
        if (ordered.length < 4) {
          let remaining = _.filter(grouped, (seg) => {
            return !_.contains(recent, seg.value);
          });
          remaining = _.sortBy(remaining, 'label');
          remaining = _.first(remaining, 4 - ordered.length);
          ordered = _.compact(ordered.concat(remaining));
        }
        setRecentlyViewed(ordered);
      } else {
        setRecentlyViewed(_.sortBy(grouped, 'label'));
      }
    });
  }, [segments]);

  // Effect to load recently viewed segments
  useEffect(() => {
    loadRecentlyViewed();
  }, [segments, loadRecentlyViewed]);

  // Handle value change for segment preferences
  const handleValueChange = (value, type, segment) => {
    console.log('inside handle value change');
    let data = {};
    if (value.value === 'never') data[type + '_enabled'] = false;
    else {
      data[type + '_enabled'] = true;
      data[type + '_frequency'] = value.value;
    }

    return Api.EMS.PREFS_BY_KEY.put({
      urlArgs: { key: `list:${segment.id}:` },
      data: _.jsonStringify(data),
    }).then(() => {
      loadPreferences(); // Reload preferences after updating preferences
    });
  };

  // Helper function to group segments
  const groupSegments = (segments) => {
    let segmentsArray = _.map(segments, (seg) => ({
      label: seg.name,
      value: seg.id,
      type: 'saved_search',
    }));

    _.each(SAVED_SEARCH_SEGMENT_FILTERS, (group) =>
      _.each(group.segments, (seg) =>
        segmentsArray.push({
          label: seg.title,
          value: seg.id,
          type: 'featured_search',
        })
      )
    );
    return segmentsArray;
  };

  const updateSegementsAfterDeletion = (segmentIds) => {
    setSegments((prevSegments) => prevSegments.filter((segment) => !segmentIds.includes(segment.id)));
  };

  // Delete a segment
  const deleteSegment = (segmentId, showRefreshToast = false, hasFolders = false) => {
    const segementName = segments.find((segement) => segement.id === segmentId)?.name || 'Saved Search';
    if (segmentId) {
      Api.CONTACTS.LIST.delete({
        urlArgs: { list_id: segmentId },
      })
        .then(() => {
          // Call the delete API for preferences
          return Api.EMS.PREFS.delete({
            urlExtend: `/list:${segmentId}:`,
            disableAlerts: true,
          });
        })
        .then(() => {
          if (showRefreshToast && hasFolders)
            EverTrue.Alert.success(`${segementName} deleted. Please refresh the page to continue.`);
          else EverTrue.Alert.success(`${segementName} deleted`);
        })
        .catch(() => {
          EverTrue.Alert.error(`Error while deleting Saved Search ${segementName}`);
        });
    }
  };

  //Delete Multiple Segments
  const deleteSegments = (segmentIds) => {
    const deletePromises = segmentIds.map((segmentId) =>
      Api.CONTACTS.LIST.delete({
        urlArgs: { list_id: segmentId },
      }).then(() => {
        // Call the delete API for preferences
        return Api.EMS.PREFS.delete({
          urlExtend: `/list:${segmentId}:`,
          disableAlerts: true,
        });
      })
    );

    Promise.all(deletePromises)
      .then(() => {
        EverTrue.Alert.success(`Selected Saved Searches deleted`);
      })
      .catch(() => {
        EverTrue.Alert.error('Error while deleting selected Saved Searches.');
      });
  };

  return {
    segments,
    loading,
    preferences,
    recentlyViewed,
    groupedSegments,
    loadSegments,
    deleteSegment,
    deleteSegments,
    handleValueChange,
    updateSegementsAfterDeletion,
  };
};
