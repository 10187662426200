import $ from 'jquery';
import { useEffect } from 'react';
import { useFluxStore } from '@evertrue/et-flux';
import EverTrue from 'app';
import PropTypes from 'prop-types';
import CompanyStore from 'apps/companies/stores/company-store';
import CompanySource from 'apps/companies/sources/company-source';
import CompanyProfileCard from 'apps/companies/components/company-profile-card';
import CompanyProfileBody from 'apps/companies/components/company-profile-body';
import MatchingConstituents from 'apps/companies/components/matching-constituents';

const CompanyStoreMap = () => ({
  data: CompanyStore.getData(),
  stats: CompanyStore.getStats(),
  constituents: CompanyStore.getConstituents(),
});

const CompanyProfileController = ({ type = 'company' }) => {
  const { data = {}, stats = {}, constituents = {} } = useFluxStore(CompanyStore, CompanyStoreMap);

  useEffect(() => {
    if (data?.name) {
      EverTrue.execute('setBrowserTitle', data.name);
    }
  }, [data.name]);

  // Unmount
  useEffect(() => {
    return () => CompanySource.resetData();
  }, []);

  const isCompany = type === 'company';
  const header = isCompany ? 'Constituents at Company' : 'Constituents in this Industry';
  const helpKey = isCompany ? 'company_constituents' : 'industry_constituents';
  const people_referrer = isCompany ? 'company_people' : 'industry_people';
  const params = isCompany ? { company: data?.name } : { industry: data?.name };
  const link = $.param({ filters: params });
  const linkText = isCompany ? 'View All Constituents at Company' : 'View All Constituents in this Industry';

  return (
    <div className="company-profile fixed-page-wrapper">
      <div className="profile">
        <CompanyProfileCard data={data} matched={constituents?.total} link={link} />
        <div className="company-profile--body">
          <div className="company-profile--main-column">
            <CompanyProfileBody data={data} stats={stats} type={type} />
          </div>
          <div className="company-profile--side-column">
            <MatchingConstituents
              link={link}
              linkText={linkText}
              limit={5}
              header={header}
              helpKey={helpKey}
              helpPosition="left"
              referrer={people_referrer}
              constituents={constituents?.items || []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyProfileController.propTypes = {
  type: PropTypes.string,
};

export default CompanyProfileController;
