import Api from 'entities/helpers/api';
import EverTrue from 'app';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const moveConstituentToJourney = async (
  oldJourneyId,
  contactId,
  userId,
  oid,
  newJourneyId,
  newJourneyLabel,
  reason = null
) => {
  const payload = {
    contact_id: contactId,
    user_id: userId,
    oid: oid,
  };
  if (newJourneyId) payload.new_journey = { id: newJourneyId };
  if (oldJourneyId)
    payload.old_journey = {
      id: oldJourneyId,
      reason: newJourneyId ? 'SKIPPED_MOVED_JOURNEY' : 'SKIPPED_REMOVED_FROM_JOURNEY',
    };
  await Api.JOURNEYS.CONTACT_JOURNEYS.post({
    params: { reason: reason },
    data: JSON.stringify(payload),
    success: () => {
      if (payload.new_journey) {
        return EverTrue.Alert.success(`Successfully moved constituent into ${newJourneyLabel} cadence.`);
      }
      return EverTrue.Alert.success('Successfully removed constituent from cadence.');
    },
    error: (e) => {
      ErrorLogger.error('Error moving constituent', { extra: { error: e, page_name: 'Cadences' } });
    },
  });
};

// bulk add endpoint
export const bulkAddConstituentsToJourney = async (contactIds, userId, oid, newJourneyId, label) => {
  if (contactIds.length > 0) {
    const payload = contactIds.map((contactId) => ({
      contact_id: contactId,
      user_id: userId,
      oid: oid,
      new_journey: { id: newJourneyId },
    }));
    await Api.JOURNEYS.BULK_CONTACT_JOURNEYS.post({
      data: JSON.stringify(payload),
      success: () => {
        return EverTrue.Alert.success(`Successfully moved constituents into ${label} cadence.`);
      },
      error: (e) => {
        EverTrue.Alert.error(`Unable to move constituents into ${label} cadence.`);
        ErrorLogger.error('Error adding constituents', { extra: { error: e, page_name: 'JourneySelector' } });
      },
    });
  } else {
    EverTrue.Alert.error('No constituent is selected, Please select atleast 1 constituent');
  }
};

export const createJourney = async (payload, sort_order) => {
  const data = {
    ...payload,
    sort_order: sort_order,
    duration_in_days: 0,
    number_of_steps: 0,
    active: true,
  };

  return await Api.JOURNEYS.JOURNEYS.post({
    data: JSON.stringify(data),
    success: (response) => {
      EverTrue.Alert.success('Cadence created successfully.');
      return response;
    },
    error: (e) => {
      ErrorLogger.error('Error creating cadence', { extra: { error: e, page_name: 'Cadences' } });
    },
  });
};

const getJourneyById = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY.get({
    urlArgs: { id: journeyId },
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error('Cadence could not be fetched. Please try again.');
    },
  });
};

export const editJourney = async (payload) => {
  const existingJourney = await getJourneyById(payload.id);
  const data = { ...existingJourney, ...payload };
  return await Api.JOURNEYS.JOURNEY.put({
    data: JSON.stringify(data),
    urlArgs: { id: existingJourney.id },
    success: (response) => {
      EverTrue.Alert.success('Cadence updated successfully.');
      return response;
    },
    error: (e) => {
      ErrorLogger.error('Error updating cadence', { extra: { error: e, page_name: 'Cadences' } });
    },
  });
};

export const deactivateJourney = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY.delete({
    urlArgs: { id: journeyId },
    success: (response) => {
      EverTrue.Alert.success('Cadence has been deleted');
      return response;
    },
    error(e) {
      ErrorLogger.error('Delete Cadence Error', { extra: { error: e, page_name: 'Cadence' } });
      return EverTrue.Alert.error('Error Deleting Cadence, try again later');
    },
  });
};

export const completeContactJourney = async (payload) => {
  try {
    return await Api.JOURNEYS.CONTACT_JOURNEYS.post({
      data: JSON.stringify(payload),
    });
  } catch (e) {
    ErrorLogger.error('Error Completing Contact Journey', { extra: { error: e, page_name: 'Active Constituents' } });
    throw e;
  }
};

// bulk remove endpoint for removing constituents from journey
// payload is an array of objects with contact_id, user_id, oid, old_journey
export const bulkRemoveConstituentsFromJourney = async (contactIds, userId, oid, oldJourneyId, label) => {
  if (!contactIds || contactIds.length === 0) return;

  try {
    const payload = contactIds.map((contactId) => ({
      contact_id: contactId,
      user_id: userId,
      oid: oid,
      old_journey: { id: oldJourneyId, reason: 'SKIPPED_REMOVED_FROM_JOURNEY' },
    }));
    await Api.JOURNEYS.BULK_CONTACT_JOURNEYS.post({
      data: JSON.stringify(payload),
      success: (res) => {
        EverTrue.Alert.success(`Successfully removed ${contactIds.length} from ${label} cadence.`);
        return res;
      },
    });
  } catch (error) {
    EverTrue.Alert.error(`Unable to remove constituents from ${label} cadence.`);
    ErrorLogger.error('Error removing constituents', {
      extra: { error, page_name: 'BulkRemoveConstituentsFromJourney' },
    });
  }
};

// this is the endpoint for bulk ship and skipping to a specific step
// payload is an array of selected task ids and an optional step id to skip to
export const bulkSkipTasks = async (payload, to) => {
  try {
    const body = {
      data: JSON.stringify(payload),
    };
    const reason = 'BulkSkip';
    if (to) {
      body.params = { to, reason };
    }
    return await Api.JOURNEYS.BULK_SKIP_TASKS.post(body);
  } catch (e) {
    ErrorLogger.error('Error Skipping Constituents', { extra: { error: e, page_name: 'BulkSkipTasks' } });
  }
};

// bulk delete endpoint for deleting constituents from journey
// payload is an array of selected task ids
export const bulkDeleteTasks = async (payload) => {
  try {
    return await Api.JOURNEYS.BULK_DELETE_TASKS.delete({
      data: JSON.stringify(payload),
    });
  } catch (e) {
    ErrorLogger.error('Error Deleting Constituents', { extra: { error: e, page_name: 'BulkDeleteTasks' } });
  }
};

export const bulkUpdateTaskDueDates = async (newDueDate, payload) => {
  try {
    return await Api.JOURNEYS.BULK_UPDATE_TASKS.post({
      params: { due_date: newDueDate },
      data: JSON.stringify(payload),
    });
  } catch (e) {
    ErrorLogger.error('Error updating due date', { extra: { error: e, page_name: 'bulkUpdateTaskDueDates' } });
  }
};

export const bulkTaskCompletion = async (isComplete, payload) => {
  try {
    return await Api.JOURNEYS.BULK_COMPLETE_TASKS.post({
      params: { complete: isComplete },
      data: JSON.stringify(payload),
    });
  } catch (e) {
    ErrorLogger.error('Error bulk completing tasks', {
      extra: { error: e, page_name: 'bulkTaskCompletion' },
    });
  }
};

export const bulkReassignTask = async (newUserId, payload) => {
  return await Api.JOURNEYS.BULK_REASSIGN_TASKS.post({
    params: { new_owner_user_id: newUserId },
    data: JSON.stringify(payload),
    success: (response) => {
      EverTrue.Alert.success(`Your tasks have been reassigned. Please refresh the page to continue.`);
      return response;
    },
    error(e) {
      ErrorLogger.error('Error bulk reassign tasks', {
        extra: { error: e, page_name: 'bulkTaskReassign' },
      });
      return EverTrue.Alert.error('Unable to reassign task. Please try again.');
    },
  });
};

export const bulkMoveConstituentsToJourney = async (
  oldJourneyId,
  contactIds,
  userId,
  oid,
  newJourneyId,
  newJourneyLabel
) => {
  const payloads = contactIds.map((contactId) => ({
    user_id: userId,
    oid: oid,
    new_journey: { id: newJourneyId },
    old_journey: {
      id: oldJourneyId,
      reason: 'SKIPPED_MOVED_JOURNEY',
    },
    contact_id: contactId,
  }));

  try {
    await Api.JOURNEYS.BULK_CONTACT_JOURNEYS.post({
      data: JSON.stringify(payloads),
      success: () => {
        EverTrue.Alert.success(`Successfully moved ${contactIds.length} constituents into ${newJourneyLabel} cadence.`);
      },
    });
  } catch (error) {
    ErrorLogger.error('Error in moving constituents to journey:', {
      extra: { error, page_name: 'bulkMoveConstituentsToJourney' },
    });
  }
};
