// This query is used for enrichment data being fed into the contacts table
const enrichmentQuery = () => {
  const gql = String.raw;

  return gql`
    query($ids: [BigInt!]) {
      contacts(ids: $ids) {
        id
        et_score {
          dds_score
        }
        enrichment {
          employments {
            company_name
            title
            start_month
            start_year
            start_date
            updated_at
            source
            status
            rejected
          }
          contact_locations {
            city
            state_code
            state_full
            metro_area
            country
            country_abbrev
            updated_at
          }
          wealth_attributes {
            net_worth
          }
          wealth_physical_assets {
            type
          }
          wealth_indicators {
            type
          }
          wealth_life_events {
            type
          }
          social_profiles {
            type
            followers
            url
          }
          employment_events {
            type
            source
            recorded_at
            updated_at
          }
        }
      }
    }
  `;
};

export default enrichmentQuery;
