import RichTextEditor from 'components/emailer/rich-text-editor';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'apps/portfolio-performance/portfolio-utils';

const EmailSignaturesForm = ({
  selectedSignature = {},
  updateSignature,
  isSaved,
  setIsSaved,
  contact,
}) => {
  const { signature_body, id } = selectedSignature;
  const [body, setSignatureBody] = useState(signature_body);
  const [isEdited, setIsEdited] = useState(false);
  const debouncedBody = useDebounce(body, 1000);

  /**
   * loads the selected signature into the editor.
   * - Runs whenever `signature_body` changes, typically when a new signature is selected.
   * - Resets `isEdited` to `false` to avoid auto-saving on signature switches.
   */
  useEffect(() => {
    setSignatureBody(signature_body);
    setIsEdited(false);
  }, [signature_body]);

  /**
   * auto-saves the signature after user has stopped typing for 1 second.
   * - Runs only if `isEdited` is `true` and `debouncedBody` differs from `signature_body`.
   * - Calls `updateSignature` to save the current content, then resets `isEdited` to `false`.
   */
  useEffect(() => {
    if (isEdited && debouncedBody !== signature_body) {
      const updateSignatureAsync = async () => {
        setIsSaved(false);
        await updateSignature(id, { signature_body: debouncedBody });
        setIsSaved(true);
        setIsEdited(false);
      };
      updateSignatureAsync();
    }
  }, [debouncedBody, id, signature_body, updateSignature, isEdited, setIsSaved]);

  const quillRef = useRef(null);

  // Handles user input in the text editor.
  const handleTextChange = (newBody) => {
    setSignatureBody(newBody);
    setIsSaved(false);
    setIsEdited(true);
  };

  return (
    <div className="email-signature-form">
      <p>
        <strong>Customize Your Signature</strong>
      </p>
      <div>
        <>
          <RichTextEditor
            quillRef={quillRef}
            className="email-signature--editor"
            placeholderText="Add Signature..."
            editorBody={body}
            setEditorBody={handleTextChange}
            onTextChange={() => setIsSaved(false)}
            isSignatureForm={true}
            contact={contact}
          />

          <div className="email-signature-body-footer">
            <div>
              {isSaved ? (
                <span className="saved-text">&#x2713; Saved</span>
              ) : (
                <span className="saving-text">Saving...</span>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

EmailSignaturesForm.propTypes = {
  selectedSignature: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  updateSignature: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  setIsSaved: PropTypes.func.isRequired,
  contact: PropTypes.object,
};

export default EmailSignaturesForm;
