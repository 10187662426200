import { useState } from 'react';
import { PropTypes } from 'base/react-libs';
import { Modal, ModalHeaderWithActions, ModalBody } from '@evertrue/et-components';
import FormField from 'components/forms/form-field';
import JourneyContactCard from '../../journey-contact-card';
import SelectCadence from './selectCadence';

// This is the "base" component for moving a contact into a new journey. It is used in multiple places in the app.
// Extracted the shared piece of the modal into a separate component to reduce duplication.
const MoveModal = ({
  title = 'Add to Cadence', // Default to "Add to Cadence"
  isOpen,
  onClose = () => {},
  handleSubmit = () => {},
  contact = {},
  options = [],
  activeJourneys = [],
}) => {
  const [selected, setSelected] = useState(null);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={title}
        closeModal={onClose}
        onSubmit={() => handleSubmit(selected)}
        disableSubmit={!selected}
      />
      <ModalBody>
        <JourneyContactCard padding={'-8px 8px'} contact={contact} avatarSize={60} />
        <FormField required={false} label="Current Cadences (Max 5)" inlineLabel={false} boldLabel={true}>
          <div className="max-cadence-alert">You can add a constituent to up to five cadences.</div>
          {!!activeJourneys.length ? (
            activeJourneys
              .filter((j) => !!j)
              .map(({ journey_name, journey_id }, index) => (
                <div key={journey_id} style={{ padding: '3px' }}>
                  {index + 1}. {journey_name}
                </div>
              ))
          ) : (
            <div style={{ padding: '3px' }}>No Active Cadences</div>
          )}
        </FormField>
        <FormField label="Select New Cadence" boldLabel={true} inlineLabel={false}>
          <hr style={{ margin: '10px 0px' }} />
          <SelectCadence selected={selected} setSelected={setSelected} />
        </FormField>
      </ModalBody>
    </Modal>
  );
};

MoveModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array,
  contact: PropTypes.object,
  activeJourneys: PropTypes.array,
};

export default MoveModal;
