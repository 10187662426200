import { useState } from "react";
import Timer from "components/timer";

const useTimer = () => {
  const [timerStart, setTimerStart] = useState(null);

  const startTimer = (date) => {
    setTimerStart(date);
  };

  const stopTimer = () => {
    setTimerStart(null);
  };

  const TimerWithContext = (props) => <Timer startTime={timerStart} {...props} />;

  return {
    startTimer,
    stopTimer,
    timerIsRunning: !!timerStart,
    Timer: TimerWithContext,
  };
};

export default useTimer;
