import { Quill } from "react-quill";

const Clipboard = Quill.import("modules/clipboard");
class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData("text/html");
    const text = clipboardData.getData("text");

    const quillEditor = this.quill;
    const range = quillEditor.getSelection();

    if (html) {
      // Insert the raw HTML directly to preserve formatting
      quillEditor.clipboard.dangerouslyPasteHTML(range.index, html);
    } else if (text) {
      // Fallback for plain text
      quillEditor.insertText(range.index, text);
    }

    quillEditor.setSelection(range.index + (html ? html.length : text.length));
  }
}

export default CustomClipboard;
