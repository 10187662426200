import PropTypes from 'prop-types';
import RichTextEditor from '../../../../../components/emailer/rich-text-editor';

const EmailTemplateForm = ({
  templateName,
  setTemplateName,
  templateSubject,
  setTemplateSubject,
  templateBody,
  setTemplateBody,
  quillRef,
  contact,
}) => {
  const handleOutsideQuillClick = (e) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const text = quill.getText();
      quill.setSelection({ index: text.length, length: 0 });
      quill.blur();
    }
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="templates-library--container" style={{ width: '100%', border: 'none' }}>
      <div className="email-form">
        <div style={{ maxHeight: '550px', display: 'grid' }}>
          <div className="email-form-header">
            <div
              className="email-form-header-field"
              onClick={handleOutsideQuillClick}
              onChange={handleOutsideQuillClick}
            >
              <label className="email-form--label">
                <strong className="email-form-header-label">Name:</strong>
                <input
                  className="email-form--input-field"
                  style={{ marginLeft: '5px', padding: '10px 0px 10px 10px' }}
                  value={templateName}
                  placeholder={'Write template name here...'}
                  onChange={({ target: { value } }) => setTemplateName(value)}
                  data-testid="subject-field"
                />
              </label>
              <hr className="email-form-header-hr" />
            </div>
            <div
              className="email-form-header-field email-subject"
              onClick={handleOutsideQuillClick}
              onChange={handleOutsideQuillClick}
            >
              <label className="space-between email-form--label">
                <strong>Subject:</strong>
                <input
                  className="email-form--input-field"
                  style={{ marginLeft: '5px' }}
                  value={templateSubject}
                  placeholder={'Write subject...'}
                  onChange={({ target: { value } }) => setTemplateSubject(value)}
                  data-testid="subject-field"
                />
              </label>
              <hr className="email-form-header-hr" />
            </div>
          </div>
          <div className="email-form--body" data-testid="body-field">
            <RichTextEditor
              contact={contact}
              editorBody={templateBody}
              setEditorBody={setTemplateBody}
              quillRef={quillRef}
              enableDynamicFields={true}
              isTemplateBody={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EmailTemplateForm.propTypes = {
  templateName: PropTypes.string,
  setTemplateName: PropTypes.func,
  templateSubject: PropTypes.string,
  setTemplateSubject: PropTypes.func,
  templateBody: PropTypes.string,
  setTemplateBody: PropTypes.func,
  quillRef: PropTypes.object,
  contact: PropTypes.object,
};

export default EmailTemplateForm;
