import { bulkMoveConstituentsToJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { fetchAllJourneysForAllJourneyGroups } from 'apps/journeys/utils/api-requests/journey-index';
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from '@evertrue/et-components';
import { PropTypes } from 'base/react-libs';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useIdentity } from 'base/identity-resolver';
import { useState, useEffect } from 'react';
import ErrorLogger from 'entities/helpers/error-log-helper';
import FormField from 'components/forms/form-field';

/**
 * The BulkMoveToJourneyModal component renders a modal for moving selected tasks to a different journey.
 *
 * @param {Object} props - The props for the component.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {Object} props.journey - The current journey object, providing context for the tasks being moved.
 * @param {Array} props.selectedTasks - Array of task objects selected for moving, each containing a `taskId` and `contactId`.
 * @param {function} [props.optionalStateManagementFunction] - Optional function for managing external state, such as updating the parent component after tasks are moved.
 */
const BulkMoveToJourneyModal = ({ isOpen, onClose, journey, selectedTasks = [], optionalStateManagementFunction }) => {
  const taskCount = selectedTasks?.length || 0;
  const { user, org } = useIdentity();
  const [journeysList, setJourneysList] = useState([]);
  const [selected, setSelected] = useState(null);

  // Fetch all journeys for all journey groups
  useEffect(() => {
    const getAndSetJourneys = async () => {
      try {
        const journeysListResponse = await fetchAllJourneysForAllJourneyGroups();
        // Map the response to a list of options for the ListboxSelect component
        setJourneysList(journeysListResponse.map((j) => ({ label: j.name, value: j.id })));
      } catch (error) {
        ErrorLogger.error('error occurred fetching journeys', {
          extra: { error, page_name: 'BulkMoveModal' },
        });
      }
    };

    if (isOpen) getAndSetJourneys();
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!selectedTasks?.length) return;
    const contactIds = [...new Set(selectedTasks.map((taskObj) => taskObj?.target_contact_id || taskObj?.contact?.id))];

    try {
      if (selected) {
        await bulkMoveConstituentsToJourney(journey.id, contactIds, user.id, org.id, selected.value, selected.label);
        optionalStateManagementFunction('bulkRemoveContacts', { contactIdsToRemove: contactIds });
        trackJourneysAction('bulk_move');
        onClose();
      }
    } catch (error) {
      ErrorLogger.error('Error occurred in bulk move modal', {
        extra: { error, page_name: 'BulkMoveModal' },
        tags: { journeyId: journey.id, contactIds: JSON.stringify(contactIds) },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Move to New Cadence"
        closeModal={onClose}
        onSubmit={handleSubmit}
        disableSubmit={!selected || taskCount === 0}
      />
      <ModalBody>
        <FormField label={`${taskCount} Constituents (Tasks) Selected`} boldLabel={true} inlineLabel={false}>
          <div className="modal-alert--message">
            You are taking action on all&nbsp;<strong>{taskCount} Constituents (Tasks)</strong>&nbsp;selected within the
            same step.
          </div>
        </FormField>
        <FormField required={false} label="Current Cadences (Max 5)" inlineLabel={false} boldLabel={true}>
          <div>{journey.name}</div>
        </FormField>
        <FormField label="Select New Cadence" boldLabel={true} inlineLabel={false}>
          <ListboxSelect
            options={journeysList}
            size="medium"
            onChange={setSelected}
            value={selected}
            emptyValue="Select New Cadence"
            label=""
          />
        </FormField>
      </ModalBody>
    </Modal>
  );
};

BulkMoveToJourneyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  journey: PropTypes.object.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default BulkMoveToJourneyModal;
