import { PropTypes } from 'base/react-libs';
import { Modal, ModalHeader, ModalBody, Button } from '@evertrue/et-components';

// TODO make this a generic component and use it for bookmarks and dynamic lists

const DeleteModal = ({ isOpen, onClose, onSubmit, title, body, isDisabled = false, closeOnSubmit = true }) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeader title={title} />
      <ModalBody>{body}</ModalBody>
      <div className="bookmark-delete-modal-actions">
        <Button type="simple" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ margin: '0 20px' }}>
          <Button
            type="destroy"
            onClick={() => {
              onSubmit();
              if (closeOnSubmit) onClose();
            }}
            disabled={isDisabled}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  closeOnSubmit: PropTypes.bool,
};

export default DeleteModal;
