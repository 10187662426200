module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   moment = require("moment")
   EverTrue = require("app")
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   FilterParser = require("apps/filters/utils/filter-parser-utils")
   PropertySource = require("apps/contact/sources/property-source")
   FilterConfig = require("apps/filters/filter-config")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   GateSource = require("apps/layout/sources/gate-source")
   DNASource = require("base/dna/dna-source")
   DNAStore = require("base/dna/dna-store").default
   FeatureStore = require("apps/layout/stores/feature-store").default
   CustomFieldsSource = require("apps/interactions/sources/custom-fields-source").default
   ChildDoc = require("entities/search/query-parts/child-doc-query")

   CUSTOM_GROUP_ID = "99"

   INTERACTION_CUSTOM_GROUP_ID = "100"

   UPDATED_FILTERS = [
      "lifetime_giving",
      "largest_gift",
      "last_gift",
      "total_pledge_balance",
      "interaction_date_range",
      "education_year",
      "year",
      "address_city",
      "address_state",
      "address_country",
      "zipcode"

   ]

   _groups = [
      "Capacity"
      "Engagement"
      "Giving History"
      "Career"
      "Donor Status"
      "Education"
      "Location"
      "Donor Info"
      "Interactions"
      "Online Giving"
      "Proposals"
      "Volunteers"
      "Graduway"
      "General Enrichment Data"
      "Wealth Enrichment"
      "Career Enrichment (Career Moves)"
      "Interactions: Custom Fields"
      "Constituents: Custom Fields"
   ]

   _type_map =
      string:
         query_type: "contains"
         component: "multi_select_with_not"
      number:
         query_type: "coerce"
         component: "range"
      datetime:
         query_type: "contains"
         component: "date_range"
      date_string:
         query_type: "contains"
         component: "date_range"
      boolean:
         query_type: "equals"
         component: "boolean"
      currency:
         query_type: "coerce"
         component: "currency_range"

   _interaction_custom_fields_type_map =
      STRING:
         query_type: "contains"
         component: "operator_component"
      BOOLEAN:
         query_type: "equals"
         component: "boolean"
      LONG:
         query_type: "object"
         component: "number_operator"
      DOUBLE:
         query_type: "object"
         component: "currency_operator"
      DATE:
         query_type:"object"
         component:"date_operator"
      LONG:
         query_type:"object"
         component:"year_operator"

   _custom_date_facets =
      type: "local"
      fields: [
         {value: "custom_date_control", type: "control"}
         {value: "custom_date_time", type: "time"}
      ]

   _getCustomFields = (schema) ->
      data =
         app_key: EverTrue.config.app_keys.givingtree
         roles: _.pluck(EverTrue.store.user?.getRoles(), "id")
         oid: EverTrue.store.org?.get("id")
         super_user: EverTrue.store.user?.isSuperUser()
      Decorator.Schema.getCustomFields(schema, data)
   
   _getQueryObjectForDateOperator = (object) ->
      # expects object == {parameter: {}, from: "...", to: "...", in: "...", date: "..."}
      current_date = new Date()
      DATE_FORMAT = "YYYY-MM-DD"
      CURRENT_DATE = moment(current_date).format(DATE_FORMAT)
      FROM = moment(object.from).format(DATE_FORMAT)
      TO = moment(object.to).format(DATE_FORMAT) 
      if object.in
         NUMBER = Number(object.in.split("-")[0])
         STRING = object.in.split("-")[1]
         IN_LAST_DATE = moment(current_date).subtract(NUMBER,STRING).format(DATE_FORMAT)
      if object.date then OBJECT_DATE = moment(object.date).format(DATE_FORMAT) else OBJECT_DATE = CURRENT_DATE 
      if object.parameter.label.includes("between")
         val = {gte: FROM, lte: TO}
      else if  object.parameter.label.includes("last")
         if object.parameter.value.split(":")[0] == "must" then val = {gte : IN_LAST_DATE} else val = {gt: IN_LAST_DATE}
      else if object.parameter.value.includes("match")  # is on/is not on
         val = {gte: OBJECT_DATE, lte: OBJECT_DATE}
      else
         if object.parameter.label.includes("after") then val = {gt: OBJECT_DATE} else val = {lt: OBJECT_DATE}

   getUpdated = (key) ->
      if key in UPDATED_FILTERS
         return "gt2-icon gt2-icon-updated"
      else return

   createStore "FilterConfigStore",
      getInitialState: ->
         config: FilterConfig
         schema: undefined
         interaction_custom_fields: undefined
         loading: false

      registerActions: ->
         @on GateSource.actions.fetchedGates, @respondToFetchedGates
         @on DNASource.actions.fetched, @respondToFetchedGates
         @on PropertySource.actions.fetchedSchema, @respondToFetchedSchema
         @on CustomFieldsSource.actions.fetchedFields, @respondToFetchedInteractionCustomFields

      # Filter out gated filter configs
      respondToFetchedGates: ->
         @respondToFetchedSchema(@getState("schema"))

      # Add in custom field filters
      respondToFetchedSchema: (schema) ->
         @fetchAndSetInteractionCustomFields()
         config = _.extend(@getGatedConfig(), @configureFlexFields(schema), @configureInteractionFlexFields())
         @setState
            schema: schema
            config: @formatConfig(config)

      fetchAndSetInteractionCustomFields: () ->
         CustomFieldsSource.actions.fetchIfNotCached()

      respondToFetchedInteractionCustomFields: (interactionCustomFields) ->
         @setState
            interaction_custom_fields: interactionCustomFields

      configureFlexFields: () ->
         config = {}
         _.each _getCustomFields(@getState("schema")), (field) =>
            return if !field.filterable
            types = _type_map[field.data_type]
            is_select = types?.component == "multi_select_with_not"
            is_date = types?.component == "date_range"
            prop_name = @formatPropName(field)

            config["custom:#{field.name}"] ?=
               filter_id: CUSTOM_GROUP_ID + "." + field.name
               label: field.description
               group: _.last(_groups)
               component_type: types?.component
               facets: if is_select then {fields: [{value: prop_name}]} else if is_date then _custom_date_facets
               query_fn: (value) ->
                  param = "must"
                  if is_select
                     param = value?.parameter?.value || "must"
                     value = _.map value.value, (item) -> item.value
                  else if is_date
                     param = value?.parameter?.value || "must"
                     if value.value == "range" && value.range
                        val = _.clone(value.range)
                        val.gte = moment(val.gte).format("YYYY-MM-DD") if val.gte
                        val.lte = moment(val.lte).format("YYYY-MM-DD") if val.lte
                     else
                        val = {gte: value?.value}
                     value = val

                  Query [
                     Parameter param, [
                        Property(prop_name, value, {type: info?.query_type})
                     ]
                  ]
         config

      configureInteractionFlexFields: () ->
         config = {}
         _.each @getState("interaction_custom_fields"), (field) =>
            return if field.deleted 
            types = _interaction_custom_fields_type_map[field.dataType]
            is_select = types?.component == "operator_component"
            is_boolean = types?.component == "boolean"
            is_number = types?.component == "number_operator"
            is_currency = types?.component == "currency_operator"
            is_date = types?.component == "date_operator"
            is_year = types?.component == "year_operator"
            prop_name = @formatInteractionCustomFieldPropName(field)
            formattedFieldName = field.displayName.toLowerCase().replace(/\s+/g, "_")

            config["interaction_custom:#{formattedFieldName}"] ?=
               filter_id: INTERACTION_CUSTOM_GROUP_ID + "." + formattedFieldName
               label: field.displayName
               group: _groups.find((group) -> group == "Interactions: Custom Fields");
               component_type: types?.component
               facets: if is_select then {
                  type: "contact_note"
                  fields: [{value: prop_name}]}

               query_fn: (value) ->
                  if is_select
                     if value?.parameter?.value?.includes("is")
                        param = "must"
                        value = value.parameter.value == "is_set" ? true : false
                        type = "exists"
                     else
                        param = value?.parameter?.value || "must"
                        value = _.map value.value, (item) -> item.value
                        type = "contains"
                  else if is_boolean
                     param = "must"
                     value = if value and value == "true" || value is true then true else false
                     type = types?.query_type
                  else if is_number || is_currency
                     if value?.parameter?.value?.includes("is")
                        param = "must"
                        value = value.parameter.value == "is_set"
                        type = "exists"
                     else if value?.parameter?.label.includes("between")
                        param = value.parameter.value.split(":")[0]
                        value = {gte: value.gte, lte: value.lte}
                        type = types?.query_type
                     else if value?.parameter?.label.includes("greater")
                        param = value.parameter.value.split(":")[0]
                        value = {gt: value.value}
                        type = types?.query_type
                     else if value?.parameter?.label.includes("less")
                        param = value.parameter.value.split(":")[0]
                        value = {lt: value.value}
                        type = types?.query_type
                     else
                        param = value?.parameter?.value || "must"
                        value = value.value
                        type = "equals"
                  else if is_date
                        param = value.parameter.value.split(":")[0]
                        value = _getQueryObjectForDateOperator(value)
                        type = types?.query_type
                  else if is_year
                     if value?.parameter?.label.includes("between")
                        param = value.parameter.value.split(":")[0]
                        value = {gte: value.gte, lte: value.lte}
                        type = types?.query_type
                     else if value?.parameter?.label.includes("greater")
                        param = value.parameter.value.split(":")[0]
                        value = {gt: value.value}
                        type = types?.query_type
                     else if value?.parameter?.label.includes("less")
                        param = value.parameter.value.split(":")[0]
                        value = {lt: value.value}
                        type = types?.query_type
                     else
                        param = value?.parameter?.value || "must"
                        value = value.value
                        type = "equals"
                     
                  Query [
                     ChildDoc "contact_note", Query [
                        Parameter param, [
                           Property prop_name, value, {type: type}
                        ]
                        Parameter "must", [Property "target_id", true, {type: "exists"}]
                     ]
                  ]
         config

      getGatedConfig: ->
         filtered_config = {}
         _.each FilterConfig, (config, key) =>
            @validateConfig(config, key)
            if !config?.gated || @shouldShowFilter(config)
               filtered_config[key] = config
         _.extend {}, filtered_config

      shouldShowFilter: (config) ->
         return false if !config
         should_show = true
         _.each config.gated, (gate_obj) ->
            if key = gate_obj.hasFeature
               return should_show = false if !FeatureStore.hasFeature(key)
            else if key = gate_obj.doesntHaveFeature
               return should_show = false if FeatureStore.hasFeature(key)
            else if gate_obj.hasCommunity
               return should_show = false if !DNAStore.hasCommunity()
         should_show

      formatPropName: (prop) ->
         name = prop.list_name + "." + prop.name
         if prop.data_type == "string"
            name += ".untouched"
         name

      formatInteractionCustomFieldPropName: (prop) ->
         name = prop.esField
         if prop.dataType == "STRING"
            name += ".untouched"
         name

      formatConfig: (config) ->
         _.mapObject config, (conf, key) ->
            _.extend {}, conf,
               key: key
               group: conf.group || _groups[_.toNumber(conf.order) - 1]

      # For development use only: warns if filter-configs are not setup properly
      validateConfig: (config, key) ->
         _.each ["filter_id", "query_fn"], (required_key) ->
            if !config[required_key]
               console.error("FilterConfig (#{key}): #{required_key} is required")

         if !(config.component || config.component_type)
            console.error("FilterConfig #{key}: should have `component` or `component_type`")

         if config.component && !config.parse_fn
            console.error("FilterConfig #{key}: needs a `parse_fn` to work with saved / shared segments")
         else if config.component_type
            if !config.parse_fn && config.component_type != "read_only"
               FilterParser.getComponentParser(config.component_type)

      api:
         getConfig: ->
            @getState("config")

         hasLoaded: ->
            !!@getState("schema")

         getFilterOptions: ->
            options = []
            _.each @getState("config"), (conf) =>
               if conf.component_type != "read_only" && (!conf.gated || @shouldShowFilter(conf))
                  options.push _.extend {
                     group: _groups[_.toNumber(conf.order) - 1]
                  }, _.omit(conf, "query_fn")
            _.sortBy(options, "order")

         getAllFilterData: ->
            @getState("config")

         getOptionById: (filter_id) ->
            config = @getConfigById(filter_id)
            if config?.component_type == "read_only" then config
            else _.findWhere @getFilterOptions(), {filter_id: "" + filter_id}

         getConfigById: (filter_id) ->
            _.findWhere @getState("config"), {filter_id: "" + filter_id}

         getConfigByKey: (key) ->
            @getState("config")[key]

         getCustomFields: ->
            _getCustomFields(@getState("schema"))

         getGroupedOptions: (filter_id) ->
            grouped_filters = _.groupBy @getFilterOptions(), "group"
            _.map grouped_filters, (filter_group, key) ->
               label: key
               value: key
               items: _.map filter_group, (filter) ->
                  label: filter.label
                  meta_search: filter.meta_search
                  value: filter.filter_id
                  disabled: (filter.filter_id != filter_id) && filter.is_selected
                  icon: getUpdated(filter.key)

         getParamsFor: (object) ->
            index = -1
            $.param filters: _.map object, (value, key) =>
               index += 1
               conf = @getConfigByKey(key)
               return unless conf
               {filter_id: conf.filter_id, value: FilterParser.parse(conf, value), filter_row_id: index}