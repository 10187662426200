import PropTypes from 'prop-types';
import OutreachTemplateIndicator from './outreach-template-indicator';
import OutreachThankviewIndicator from './outreach-thankview-indicator';
import OutreachCadenceIndicator from './outreach-cadence-indicator';
import OutreachThreadIndicator from './outreach-thread-indicator';

const OutreachIndicatorGroup = ({ threadCount, emailTemplateId, taskId, thankviewVideos }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="outreach-card-tab-group">
        {threadCount > 0 && (
          <>
            <OutreachThreadIndicator threadCount={threadCount} />
            {(taskId || thankviewVideos?.length > 0 || emailTemplateId) && (
              <span className="outreach-card-tab-group-border"> | </span>
            )}
          </>
        )}
        {taskId && (
          <>
            <OutreachCadenceIndicator taskId={taskId} />
            {(thankviewVideos?.length > 0 || emailTemplateId) && (
              <span className="outreach-card-tab-group-border"> | </span>
            )}
          </>
        )}
        {thankviewVideos.length > 0 && (
          <>
            <OutreachThankviewIndicator thankviewVideos={thankviewVideos} />
            {emailTemplateId && <span className="outreach-card-tab-group-border"> | </span>}
          </>
        )}
        {emailTemplateId && <OutreachTemplateIndicator emailTemplateId={emailTemplateId} />}
      </div>
    </div>
  );
};

OutreachIndicatorGroup.propTypes = {
  threadCount: PropTypes.number,
  emailTemplateId: PropTypes.number,
  taskId: PropTypes.number,
  thankviewVideos: PropTypes.array,
};

export default OutreachIndicatorGroup;
