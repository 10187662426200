import PropTypes from 'prop-types';
import { Link } from '@evertrue/et-components';
import Icon from 'components/elements/icon';
import { formatMapsLink, formatLastLn } from 'mixins/address-mixin';

const Address = ({ data: address = {}, hideIcon, onClick }) => {
  const mapsLink = address && formatMapsLink(address);

  return (
    <div className="address-template">
      {hideIcon ? (
        <div className="address-template--no-icon" />
      ) : (
        <Icon icon="location" className="address-template--icon" />
      )}

      {Object.keys(address).length === 0 ? (
        <div className="address-template--data is-empty">Not provided</div>
      ) : (
        <div className="address-template--data">
          <div className="address-template--label">
            {address?.type?.value || 'Other'}
            {address?.primary?.value && ' (Primary)'}
          </div>

          <div className="fs-exclude-data">
            <Link title="address" href={mapsLink} target="_BLANK" onClick={onClick}>
              <div>{address?.address_1?.value}</div>
              <div>{address?.address_2?.value}</div>
              <div>{address?.address_3?.value}</div>
              <div>{formatLastLn(address)}</div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

Address.propTypes = {
  data: PropTypes.object,
  hideIcon: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Address;
