import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";
import colors from 'base/colors';

const ChatInputField = ({ userInput, handleInput, onSubmit, leftIcon, leftIconKit, placeholder }) => {
  const textareaRef = useRef(null);
  const [inputHeight, setInputHeight] = useState("auto");
  const maxAutoHeightCharacters = 500;
  const isInputEmptyOrWhitespace = !userInput.trim();

  useEffect(() => {
    if (textareaRef.current) {
      const currentCharacters = userInput.length;
      const currentScrollHeight = textareaRef.current.scrollHeight;

      if (currentCharacters === 0) {
        // Reset height when input is cleared
        setInputHeight("auto");
      } else if (currentCharacters <= maxAutoHeightCharacters && currentScrollHeight < 202) {
        // Limit auto height increase after a certain number of characters
        setInputHeight(`${currentScrollHeight}px`);
      } else if (currentCharacters > maxAutoHeightCharacters && inputHeight !== ("202px" || "auto")) {
        // When more than 500 char gets pasted limit to 202px
        setInputHeight(`${202}px`);
      }

      // show or hide scroll
      if (currentScrollHeight < 202) {
        textareaRef.current.style.overflowY = "hidden";
      } else {
        textareaRef.current.style.overflowY = "auto";
      }
    }
  }, [userInput, inputHeight]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      if (userInput.trim() !== "") {
        onSubmit();
      }
    }
  };

  return (
    <div className="chat-input-field">
      {leftIcon && leftIconKit && (
        <div className="left-icon">
          <Icon icon={leftIcon} iconKit={leftIconKit} style={{ color: colors.purple500 }} />
        </div>
      )}
      <textarea
        ref={textareaRef}
        style={{ height: inputHeight, resize: 'none' }}
        placeholder={placeholder}
        value={userInput}
        onChange={(e) => handleInput(e.target.value)}
        onKeyDown={handleKeyDown}
        rows="1"
        data-testid="chat-input-field"
      />
      <div
        className={`chat-submit-button ${isInputEmptyOrWhitespace ? 'disabled' : ''}`}
        onClick={!isInputEmptyOrWhitespace ? onSubmit : undefined}
        data-testid="submit-button"
      >
        <Icon
          icon="arrow-up"
          iconKit="gt"
          style={{ fontSize: '22px', width: '1.2em', height: '1.09em', textAlign: 'center' }}
        />
      </div>
    </div>
  );
};

ChatInputField.propTypes = {
  userInput: PropTypes.string,
  handleInput: PropTypes.func,
  onSubmit: PropTypes.func,
  leftIcon: PropTypes.string,
  leftIconKit: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ChatInputField;
