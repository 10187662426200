import EverTrue from 'app';
import Api from 'entities/helpers/api';
import ErrorLogger from 'entities/helpers/error-log-helper';

export const trackOutreachAction = (type) => {
  const track = (type) => {
    EverTrue.track.set('outreach_action', { type });
  };
  const { pathname } = window.location;
  switch (type) {
    case 'open_dialer':
      if (pathname === '/cadences') {
        track('open_dialer_from_cadences');
      } else if (pathname.includes('/contact')) {
        track('open_dialer_from_profile');
      }
      break;
    case 'open_emailer':
      if (pathname === '/cadences') {
        track('open_emailer_from_cadences');
      } else if (pathname.includes('/contact')) {
        track('open_emailer_from_profile');
      }
      break;
    default:
      track(type);
      break;
  }
};

export const isDynamicTemplate = (template) => {
  const regex = /\{\{.*?\}\}/g;
  return regex.test(template);
};

export const compileTemplate = async (template, contactId) => {
  try {
    const res = await Api.OUTREACH.COMPILE_TEMPLATE.post({
      data: JSON.stringify({ body: template, contact_id: contactId }),
    });
    return res;
  } catch (e) {
    ErrorLogger.error('Error compiling template', { extra: { error: e, page_name: 'Email Templates Modal' } });
    EverTrue.alert.error('There was a problem replacing your dynamic fields.');
  }
};
