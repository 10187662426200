module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {span, input} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   ReactLibs.createClass
      displayName: "SearchBox"
      propTypes:
         name: ReactLibs.PropTypes.string
         placeholder: ReactLibs.PropTypes.string
         defaultValue: ReactLibs.PropTypes.any
         hideSearchIcon: ReactLibs.PropTypes.bool
         label: ReactLibs.PropTypes.string
         onKeyUp: ReactLibs.PropTypes.func
         onChange: ReactLibs.PropTypes.func
         onFocus: ReactLibs.PropTypes.func
         onBlur: ReactLibs.PropTypes.func

      getInitialState: ->
         {focus: false, has_value: false}

      componentDidMount: ->
         @setState {has_value: true} if @props.defaultValue or @search.value and @is_mounted
         @is_mounted = true

      componentWillUnmount: ->
         @is_mounted = false

      UNSAFE_componentWillReceiveProps: (nextProps) ->
         node = @search
         if not node?.value and nextProps.defaultValue? and nextProps.defaultValue isnt @props.defaultValue
            @setState {has_value: true} if nextProps.defaultValue
            $(node).val(nextProps.defaultValue)

      focus: ->
         @search?.focus()

      updateHasValue: ->
         @setState {has_value: !!@search.value} if @is_mounted

      handleClear: (e) ->
         node = @search
         $(node).val(null)
         @updateHasValue()
         if e
            @handleKeyUp(e)
            @handleChange(e)
         @focusOnCancelIcon()

      handleKeyUp: (e) ->
         @updateHasValue()
         if _.isFunction @props.onKeyUp
            @props.onKeyUp(@search.value, e)

      handleChange: (e) ->
         @updateHasValue()
         if _.isFunction @props.onChange
            @props.onChange(@search.value, e)

      focusOnCancelIcon: ->
         cancelIcon = document.querySelector(".search-box--clear")
         cancelIcon?.focus()

      render: ->
         span
            className: classNames("search-box", @props.className)

            Icon className: "search-box--search-icon", icon: "search"

            input
               "aria-label": @props.placeholder || @props.name
               type: "search"
               ref: ((elem) => @search = elem)
               className: "search-box--input" # Used in jasmine tests
               label: @props.label || @props.placeholder
               name: @props.name
               defaultValue: @props.defaultValue
               placeholder: @props.placeholder
               onFocus: @props.onFocus
               onBlur: @props.onBlur
               onKeyUp: @handleKeyUp
               onChange: @handleChange
            
            if @state.has_value
               LinkButton title: "Clear search box", onClick: @handleClear, tabIndex: -1,
                  Icon className: "search-box--clear", icon: "cancel", tabIndex: 0, role: "button", ariaLabel: "Clear search", onFocus: @focusIcon

      focusIcon: (e) ->
         e.preventDefault()
         e.target.focus()
