import Api from 'entities/helpers/api';
import EverTrue from 'app';

export const getVoipUserSettingsByOwnerUserId = async (userId) => {
  return (
    (await Api.OUTREACH.VOIP_USER_SETTINGS_BY_USER_ID.get({
      urlArgs: { owner_user_id: userId },
      success: (resp) => {
        return resp;
      },
    })) || {}
  );
};

export const validateUserPhoneNumber = async (phone, extension) => {
  const params = { phone };
  if (!!extension) params.extension = extension;
  return await Api.OUTREACH.VALIDATE_PHONE.post({
    params: params,
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error(`Unable to validate phone number ${phone}. please try again`);
    },
  });
};

export const validationNumberRequest = async (callSid) => {
  return await Api.OUTREACH.VALIDATION_REQUEST.get({
    urlArgs: { callSid },
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error('Phone number validation failed, please try again');
    },
  });
};

export const getVoipOrgSettings = async () => {
  return await Api.OUTREACH.VOIP_ORG_SETTINGS.get({
    success: (resp) => {
      return resp;
    },
  });
};
