import { getLinkUrl } from 'entities/helpers/hardcodedLinks';
import { useOpenClose } from '@evertrue/et-components';
import PropTypes from 'prop-types';
import JourneyTaskModal from 'apps/journeys/components/modals/journey-task-modal';
import EmptyStateMessage from 'components/lists/empty-state-message';
import { useJourneyDetailProvider } from 'apps/journeys/context/journey-detail-context';

const EmptyStateSubtext = () => (
  <div className="journey-empty-state-message">
    <strong className="journey-empty-state-message-title">Next steps to make it impactful:</strong>
    <ul>
      <li className="journey-empty-state-message-list">
        Think about the frequency of your outreach to ensure that your organization remains top of mind for potential
        donors.
      </li>
      <li className="journey-empty-state-message-list">
        Adds steps to guide your engagement. Remember that you can add steps for preparatory or internal work to help
        keep you on track!
      </li>
      <li className="journey-empty-state-message-list">Add descriptions to clarity actions for your team.</li>
    </ul>
    <p>
      For inspiration and proven strategies, check out the latest articles on the{' '}
      <a href={getLinkUrl('everTrue', 'blogUrl')} target="_blank" rel="noreferrer">
        EverTrue blog.
      </a>
    </p>
  </div>
);

const StepTableCreatorEmptyState = ({ steps = [], journeyId }) => {
  const [isOpen, openModal, closeModal] = useOpenClose();
  const { optionalStateManagementFunction } = useJourneyDetailProvider();

  return (
    <>
      <EmptyStateMessage
        className="journeys-task-table--empty-state"
        iconSize={0}
        icon="cadences"
        iconKit="gt2"
        text="You’ve successfully created a Cadence!"
        actionLabel="Add Step"
        onAction={isOpen ? closeModal : openModal}
      >
        <EmptyStateSubtext />
      </EmptyStateMessage>
      <JourneyTaskModal
        isOpen={isOpen}
        onClose={closeModal}
        steps={steps}
        journeyId={journeyId}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    </>
  );
};

StepTableCreatorEmptyState.propTypes = {
  steps: PropTypes.array,
  journeyId: PropTypes.number,
};

export default StepTableCreatorEmptyState;
