import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';

const FullContactSource = createSource('FullContactSource', {
  actions: {
    loading(bool) {
      if (typeof bool !== 'boolean') {
        throw new Error('bool should be true or false');
      }
      return bool;
    },
    fetchedFullContact(contact) {
      if (typeof contact !== 'object' || contact === null) {
        throw new Error('contact should be an object');
      }
      return contact;
    },
  },

  api: {
    getFullContact(id) {
      this.actions.loading(true);
      return Api.CONTACTS.CONTACT.get({
        urlArgs: { id },
        success: (contact) => {
          this.actions.fetchedFullContact(contact);
          this.actions.loading(false);
        },
        error: () => {
          this.actions.loading(false);
        },
      });
    },
  },
});

export default FullContactSource;
