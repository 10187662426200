import { Button } from '@evertrue/et-components';
import { Formik } from 'formik';
import { PauseFormSchema } from './pause-form-schema';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';
import { useTasksNotificationContext } from 'apps/tasks/hooks/tasks-notification-context.js';
import { getMomentObjectInTimezone, getDateString, getDateInTimezone } from 'base/new-utils';
import DateRangePicker from 'components/calendar/date-range-picker';
import useJourneyTimeoutsApi from 'apps/journeys/hooks/use-journey-timeouts-api';
import ResumePauseModalButton from './resume-pause-modal-button';
import CancelPauseModalButton from './cancel-pause-modal-button';

const PauseForm = () => {
  const {
    holidays = [],
    inProgressJourneyTimeout,
    futureJourneyTimeout,
    handleCreate,
    handleUpdate,
    handleResume,
    handleCancel,
  } = useJourneyTimeoutsApi();

  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();

  const onSubmit = async (values) => {
    const { startDate, endDate } = values;
    const start = getDateString(startDate);
    const end = getDateString(endDate);
    if (!!inProgressJourneyTimeout) {
      await handleUpdate(start, end, inProgressJourneyTimeout);
    } else if (!!futureJourneyTimeout) {
      await handleUpdate(start, end, futureJourneyTimeout);
    } else {
      trackJourneysAction('create_cadence_pause');
      await handleCreate(start, end);
      getAndSetGlobalTasksDueCount();
    }
  };

  const initialValues = () => {
    let startDate = getDateInTimezone();
    let endDate = getDateInTimezone();
    if (!!inProgressJourneyTimeout) {
      startDate = getDateInTimezone(inProgressJourneyTimeout?.starts_on);
      endDate = getDateInTimezone(inProgressJourneyTimeout?.ends_on);
    } else if (!!futureJourneyTimeout) {
      startDate = getDateInTimezone(futureJourneyTimeout?.starts_on);
      endDate = getDateInTimezone(futureJourneyTimeout?.ends_on);
    }

    return {
      startDate,
      endDate,
    };
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues()}
        validationSchema={PauseFormSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors, dirty, isValid, touched }) => {
          return (
            <>
              {!!inProgressJourneyTimeout && (
                <div className="journeys-pause-container">
                  <div>
                    <h5 className="bold-text">
                      {`Your cadences and tasks are currently paused until ${getMomentObjectInTimezone(
                        inProgressJourneyTimeout?.ends_on
                      ).format('dddd, MMMM DD, YYYY')}.`}
                    </h5>
                    <span>You can modify or resume this pause anytime.</span>
                  </div>
                </div>
              )}
              {!!futureJourneyTimeout && (
                <div className="journeys-pause-container">
                  <div>
                    <h5 className="bold-text">
                      {`Your cadences and tasks are scheduled to be paused from ${getMomentObjectInTimezone(
                        futureJourneyTimeout?.starts_on
                      ).format('dddd, MMMM DD, YYYY')} until ${getMomentObjectInTimezone(
                        futureJourneyTimeout?.ends_on
                      ).format('dddd, MMMM DD, YYYY')}.`}
                    </h5>
                    <span>You can modify or cancel this pause anytime.</span>
                  </div>
                </div>
              )}
              {/** todo: add messaging for users based on selection  */}
              <div className="pause-journey-date-container" data-testid="date-picker-dropdown">
                <DateRangePicker
                  range={{ from: values.startDate, to: values.endDate }}
                  onDayClick={({ from, to }) => {
                    touched.startDate = true;
                    touched.endDate = true;
                    setFieldValue('startDate', getDateInTimezone(from));
                    setFieldValue('endDate', getDateInTimezone(to));
                  }}
                  numberOfMonths={1}
                  disableWeekends
                  disabledDays={[...holidays, { before: new Date() }]}
                />
                {values.endDate && <div>{`The first day you will have tasks due will be ${getMomentObjectInTimezone(
                        values.endDate
                      ).format('dddd, MMMM DD, YYYY')}.`}</div>}
              </div>
              {touched.startDate && errors.startDate && <div className="formik-error-message">{errors.startDate}</div>}
              {touched.endDate && errors.endDate && <div className="formik-error-message">{errors.endDate}</div>}
              <div className="pause-journey-buttons">
                {!!inProgressJourneyTimeout && (
                  <ResumePauseModalButton handleResume={handleResume} updateTaskCount={getAndSetGlobalTasksDueCount} />
                )}
                {!!futureJourneyTimeout && <CancelPauseModalButton handleCancel={handleCancel} />}
                <Button onClick={() => handleSubmit()} disabled={!isValid}>
                  Save
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PauseForm;
