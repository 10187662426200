import { createSource } from '@evertrue/et-flux';
import Api from 'entities/helpers/api';
import Decorator from 'clientDecorator';

const ClusterContactSource = createSource('ClusterContactSource', {
  actions: {
    loadingClusterContacts: true,
    fetchedClusterContacts(key, contacts) {
      this.require(typeof key === 'string', 'key should be string');
      this.require('items' in contacts, 'contacts should be paged object');
    },
  },

  api: {
    fetch(key, query) {
      this.actions.loadingClusterContacts(true);
      Api.CONTACTS.SEARCH.post({
        data: JSON.stringify(query),
        params: { limit: 50 },
        success: (resp) => {
          resp.items = resp.items.map((contact) => Decorator.Contacts.parse(contact));
          this.actions.loadingClusterContacts(false);
          this.actions.fetchedClusterContacts(key, resp);
        },
        error: () => {
          this.actions.loadingClusterContacts(false);
        },
      });
    },
  },
});

export default ClusterContactSource;
