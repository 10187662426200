module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   ProposalSearchSource = require("apps/proposals/sources/proposal-search-source")
   FilterStore = require("apps/filters/stores/filter-store")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   SortUtils = require("mixins/sort-utils")
   QueryContext = require("entities/search/query-parts/query-context")
   ParentDoc = require("entities/search/query-parts/parent-doc-query")
   Query = require("entities/search/query-parts/query")
   PaginationUtils = require("mixins/pagination-utils")

   # This handles doing the sort, pagination and filtering for
   # Both core search and the profile since most of the logic is the same
   createStore "ProposalSearchStore",
      getInitialState: ->
         proposals: {}
         loading: false
         page: 1
         sort:
            sortProp: "ask_amount"
            sortReverse: true
         contact_id: undefined
         profile_filter: "all"
         query_type: undefined

      registerActions: ->
         @on ProposalSearchSource.actions.queryWithFilters, @respondToQuery
         @on ProposalSearchSource.actions.queryForContact, @respondToQueryContact
         @on ProposalSearchSource.actions.setContact, (contact_id) =>
            @setState({ contact_id: contact_id })
         @on ProposalSearchSource.actions.loading, @respondToLoading
         @on ProposalSearchSource.actions.sort, @respondToSort
         @on ProposalSearchSource.actions.paginate, @respondToPaginate
         @on ProposalSearchSource.actions.fetchedProposals, @respondToFetched

         @on ProposalSearchSource.actions.setProfileFilter, @respondToChangeProfileFilter

         @on CoreSearchSource.actions.query, (type) ->
            if type == "proposals" then @respondToQuery()

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetched: (proposal_data) ->
         @setState {proposals: proposal_data}
         CoreSearchSource.setResultCount("proposals", proposal_data.total)

      respondToQuery: ->
         @setState {page: 1, query_type: "filter"}
         @queryProposals()

      respondToQueryContact: (contact_id) ->
         @setState
            page: 1
            query_type: "contact"
            contact_id: contact_id
         @queryProposals()

      respondToSort: (key, reverse, silent) ->
         @setState
            page: 1
            sort: {sortProp: key, sortReverse: reverse}
            query_type: "sort"
         @queryProposals() unless silent

      respondToPaginate: (page) ->
         @setState {page: page, query_type: "page"}
         @queryProposals()

      respondToChangeProfileFilter: (filter) ->
         @setState {profile_filter: filter}
         @queryProposals()

      getFilterQuery: ->
         # This logic was copied from InteractionSearchStore, consider abstracting further
         query = {}
         unless FilterStore.getLoading()
            if !_.isEmpty(FilterStore.getActiveFilters())
               # This currently works because you cannot have a has_child and "or" search
               if FilterStore.getOperator() == "or"
                  query = has_parent: [{
                     type: "contact"
                     query: FilterStore.getActiveFiltersQuery()
                  }]
               else
                  query = QueryContext("proposal", Query [
                     ParentDoc "contact", FilterStore.getActiveFiltersQueryObject()
                  ]).toJSON()
         query

      queryProposals: ->
         # if a contact_id has been set then we need to query for proposals for that
         # specific contact
         if @getState("contact_id")
            query = must: [{"contacts.contact_id": in: [@getState("contact_id")]}]
            profile_filter = @getState("profile_filter")
            if profile_filter != "all"
               query.must.push {active: {match: profile_filter == "active"}}
         else
            query = @getFilterQuery()

         sort = @getState("sort")
         query.sort = SortUtils.getQuery(sort.sortProp, sort.sortReverse)

         page_data = _.extend
            page: @getState("page")
            limit: 10
         , @getState("proposals")

         ProposalSearchSource.fetch
            query: query
            params: PaginationUtils.getParams(page_data)

      api:
         getProposals: ->
            _.extend {
               page: @getState("page")
            }, @getState("sort"), @getState("proposals")

         getLoading: ->
            @getState("loading")

         getProfileFilter: ->
            @getState("profile_filter") || "all"

