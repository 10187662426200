import PropTypes from "prop-types";
import EverTrue from "app";
import { useEffect, useRef } from "react";
import SuggestedSearchList from "./suggested-search-list";

const ChatMessages = ({
  userMessageRequests,
  aiResponses,
  isStreaming,
  suggestedSearches,
  suggestedSearchesHeading, // prop for the suggested searches heading node
  showSuggestedSearchesHeading, // prop to enable or disable the heading
  handleSuggestedSearchClick,
}) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Scroll whenever there's a new response
  useEffect(() => {
    scrollToBottom();
  }, [aiResponses]);

  const handleCopyToClipboard = async (text, successMessage, errorMessage) => {
    try {
      await navigator.clipboard.writeText(text);
      EverTrue.Alert.success(successMessage);
    } catch {
      EverTrue.Alert.error(errorMessage);
    }
  };

  const handleResponseCopy = (responseText) => {
    const fullResponse = responseText.join('');
    handleCopyToClipboard(
      fullResponse,
      'AI response copied to clipboard!',
      'There was a problem copying the response. Please manually copy it.'
    );
  };

  const handleRequestMessageCopy = (requestMessage) => {
    handleCopyToClipboard(
      requestMessage,
      'AI response copied to clipboard!',
      'There was a problem copying the response. Please manually copy it.'
    );
  };

  return (
    <div className={`chat-messages${suggestedSearches && userMessageRequests.length === 0 ? '-centered' : ''}`}>
      {/* rendered list of suggested searches only if userMessageRequests is empty */}
      {suggestedSearches && userMessageRequests.length === 0 && (
        <div className="suggested-searches">
          {showSuggestedSearchesHeading && suggestedSearchesHeading}
          <SuggestedSearchList
            suggestedSearches={suggestedSearches}
            handleSuggestedSearchClick={handleSuggestedSearchClick}
          />
        </div>
      )}
      {userMessageRequests.map(({ content: message, index: messageId }, i) => {
        const aiResponse = aiResponses[i];
        const currentResponseIndex = userMessageRequests.length - 1;

        const isTyping =
          isStreaming && // Check if streaming is active
          messageId === currentResponseIndex && // Check if the messageId matches the current response index
          (!aiResponses[currentResponseIndex] || // Check if there's no response yet
            aiResponses[currentResponseIndex].length < 2); // Check if the response length<2 (considered typing)

        return (
          <div key={messageId}>
            <div className="message-container request">
              <div className="message request" data-index={messageId}>
                  <div className="message-body">{message}</div>
                  <div className="request-copy-icon" onClick={() => handleRequestMessageCopy(message)} >
                    {message && <i className="fa fa-fw fa-clone"/>}
                    <span>Copy</span>
                </div>
              </div>
            </div>
            <div className="message-container response">
              <div className="message response" data-response-index={messageId} style={{ whiteSpace: 'pre-wrap' }}>
                <img src="/images/floating-ai-spark.png" alt="AI Spark" className="ai-spark-icon" />
                {isTyping ? <img src="/images/ai-chat-ellipses.gif" alt="Typing..." className="ai-chat-ellipses"/> : aiResponse}
              </div>
              {(!isStreaming || messageId !== currentResponseIndex) && (
                <div className="response-copy-icon" onClick={() => handleResponseCopy(aiResponse)}>
                  {aiResponse && <i className="fa fa-fw fa-clone"/>}
                  <span>Copy</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatMessages.propTypes = {
  userMessageRequests: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      index: PropTypes.number,
    })
  ),
  aiResponses: PropTypes.object,
  isStreaming: PropTypes.bool,
  suggestedSearches: PropTypes.array,
  handleSuggestedSearchClick: PropTypes.func,
  suggestedSearchesHeading: PropTypes.node,
  showSuggestedSearchesHeading: PropTypes.bool,
};

export default ChatMessages;
