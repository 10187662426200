import EverTrue from 'app';
import PropTypes from 'prop-types';
import { deactivateJourney } from 'apps/journeys/utils/api-requests/modal-actions';
import { Modal, ModalHeader, ModalBody, Button } from '@evertrue/et-components';
import { trackJourneysAction } from 'apps/journeys/utils/helpers/mixpanel';

const DeleteJourneyModal = ({ isOpen, onClose, journey }) => {
  const { name, active_constituents } = journey;

  const deleteJourney = async () => {
    await deactivateJourney(journey.id);
    trackJourneysAction('delete_custom_cadence');
    onClose();
    EverTrue.Navigator('/cadences/', true, false);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeader title={'Delete Cadence'} closeModal={onClose} />
      <ModalBody scrollable={false}>
        {active_constituents && active_constituents > 0 ? (
          <div className="delete-modal-warning">
            <span>You cannot delete the cadence "{name}" because there are currently </span>
            <strong>{active_constituents}</strong> constituents in this cadence.
          </div>
        ) : (
          <div>
            <div className="delete-modal-header">Are you sure you want to delete the cadence "{name}" ?</div>
            <div className="delete-modal-button--group">
              <Button type="simple" onClick={onClose}>
                Cancel
              </Button>
              <div className="delete-modal-button--delete">
                <Button type="destroy" onClick={deleteJourney}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

DeleteJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journey: PropTypes.object.isRequired,
};

export default DeleteJourneyModal;
