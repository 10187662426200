import moment from 'moment';
import PropTypes from 'prop-types';

const EmmaListTimestamp = ({ groups = [], accounts = [] }) => {
  if (!groups?.length) return null;

  const group = groups.reduce((max, curr) => (curr.last_pushed > max.last_pushed ? curr : max));
  const { last_pushed, account_id } = group;
  const name = accounts.find((a) => a.id === account_id)?.name || 'Unknown Account';
  const date = moment(last_pushed || '-').format('MM/DD/YY h:mma');

  return (
    <span className="emma-list-timestamp">
      <span className="emma-list--spacer">|</span>
      Last sent to <strong>Emma: </strong>
      {name}
      {last_pushed && <span> on {date}</span>}
    </span>
  );
};

EmmaListTimestamp.propTypes = {
  groups: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
};

export default EmmaListTimestamp;
