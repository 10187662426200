module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   UrlSource = require("apps/layout/sources/url-source")
   ContactSource = require("apps/contact/sources/contact-source")
   MapSource = require("apps/map/sources/map-source").default
   ListContactSource = require("apps/lists/sources/list-contact-source")
   ContactQueryStore = require("apps/contact/stores/contact-query-store")
   FilterStore = require("apps/filters/stores/filter-store")
   MapStore = require('apps/map/stores/map-store').default
   {createFactory} = require("base/new-utils")
   ContactFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   MapController = createFactory require("apps/map/controllers/map-controller").default
   MapContacts = createFactory require("apps/map/controllers/map-contacts-controller").default
   SortUtils = require("mixins/sort-utils")
   CoreSearchSource = require("apps/core-search/sources/core-search-source").default
   CoreSearchStore = require("apps/core-search/stores/core-search-store")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   Button = createFactory(require("@evertrue/et-components").Button)
   Icon = createFactory (require("components/elements/icon").default)
   {div} = ReactLibs.DOMFactories

   TOOLBAR_HEIGHT = 67
   SELECTED_INDICATOR_HEIGHT = 20

   createComponent "AllConstitutentsController",
      propTypes:
         view: ReactLibs.PropTypes.string
         hash_id: ReactLibs.PropTypes.string
         totalNumber: ReactLibs.PropTypes.number

      getInitialState: ->
         table_top_position: null
         is_contact_list_focused: false
         enrichmentLoading: true

      getDefaultProps: ->
         view: "table"

      registerStores: ->
         @on ContactQueryStore, ->
            contacts: ContactQueryStore.getContacts()
            loading: ContactQueryStore.getLoading()

         @on FilterStore, ->
            loading_filters: FilterStore.getLoading()
            active_global_search: FilterStore.getActiveFilterByKey("global_search")?.value
            filter_params: FilterStore.getActiveFiltersQuery()

         @on SelectedContactsStore, ->
            show_selected_indicator: SelectedContactsStore.getShowSelectedIndicator()

         @on CoreSearchStore, ->
            active_results_count: CoreSearchStore.getActiveResultsCount(),

      componentDidMount: ->
         unless @props.hash_id
            _.defer (-> ContactSource.initQuery())

         # Sets the event type for Adding to list since we track
         # this from various parts of the app differently
         ListContactSource.setEvent("search_action")

      componentDidUpdate: (prevProps, prevState) ->
         if !_.isEqual(prevProps, @props)
            _.defer (-> ContactSource.initQuery())

         if @state.contacts?.total != prevState.contacts?.total
            CoreSearchSource.setResultCount("contacts", @state.contacts.total)
            EverTrue.track.setReferrer("contact")

         if @state.active_global_search
            @highlightQuery(@state.active_global_search)

      componentWillUnmount: ->
         @setState({ is_contact_list_focused: false })

      highlightQuery: (text) ->
         elements = $(@refs.constituents).find(".contact-card--name strong")
         words = _.map text.trim().split(" "), (str) -> str.trim()
         elements.highlight(words, "text-highlight")

      handleSort: (sort_key, reverse, silent) ->
         if (sort_key == "proximity_sort") || (sort_key == "addresses.location")
            sort_param_query = MapStore.getProximitySort(reverse)
         else
            sort_param_query = SortUtils.getQuery(sort_key, reverse)[0]
         UrlSource.updateSort(sort_param_query, silent)

      setEnrichmentLoading: (bool) ->
         @setState({enrichmentLoading: bool})

      handlePage: (page) ->
         @setEnrichmentLoading(true)
         UrlSource.updatePage(page)


      render: ->
         div
            className: "all-constituents-controller fixed-page-wrapper"
            style: @props.style

            ContactTableToolbar
               contacts: @state.contacts
               onPage: @handlePage
               allowAddAll: true
               totalNumber: @props.totalNumber
               filterParams: @state.filter_params
               totalResultsCount: @state.active_results_count

               div className: "contact-table--map-toggle",
                  if @props.view == "table"
                     Button
                        type: "simple"
                        onClick: (value) =>
                           @setState({ is_contact_list_focused: false })
                           MapSource.clearClusters()
                           url_data = EverTrue.UrlManager.toJSON()
                           route = "/contact/map"
                           EverTrue.Navigator route, {trigger: true}
                           EverTrue.UrlManager.set(url_data)
                           return undefined # fix for react warning
                        Icon icon: "map gt-icon-lg"
                        "Map"
                  else
                     Button
                        type: "simple"
                        onClick: (value) =>
                           @setState({ is_contact_list_focused: false })
                           MapSource.clearClusters()
                           url_data = EverTrue.UrlManager.toJSON()
                           route = "/contact"
                           EverTrue.Navigator route, {trigger: true}
                           EverTrue.UrlManager.set(url_data)
                           return undefined # fix for react warning
                        Icon icon: "table-large gt-icon-lg"
                        "Table"

            div
               ref: "constituents"
               className: "fixed-page-wrapper"
               style: {top: TOOLBAR_HEIGHT + if @state.show_selected_indicator then SELECTED_INDICATOR_HEIGHT else 0 }

               if @props.view == "table"
                  ContactFixedTable
                     contacts: @state.contacts
                     loading: @state.loading
                     onPage: @handlePage
                     onSort: @handleSort
                     topPosition: if @state.show_selected_indicator then SELECTED_INDICATOR_HEIGHT else null
                     setEnrichmentLoading: @setEnrichmentLoading
                     enrichmentLoading: @state.enrichmentLoading
               else
                  div null,
                     MapContacts
                        contacts: @state.contacts
                        loading: @state.loading
                        onSort: @handleSort
                        hasActiveFocus: @state.is_contact_list_focused

                     unless @state.loading_filters
                        MapController
                           onSendContactsFocus: => this.setState({ is_contact_list_focused: true })
                           onMapChange: =>
                              if @state.contacts?.sortProp == "addresses.location"
                                 UrlSource.updateSort(MapStore.getProximitySort(@state.contacts?.sortReverse))
                              ContactSource.query()
